<template>
    <v-container fluid :dark="$store.getters.getColorPalette().isDark">
        <!-- Info alert -->
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />


        <!-- form section -->
        <v-form ref="widgetForm" :dark="$store.getters.getColorPalette().isDark" v-model="widgetValid">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
                flat outlined class="px-2 mb-2">
                <v-card-title v-if="edit" class="sub-heading">{{
                    $store.getters.getTextMap().edit_consolidated_daywise_machine_status_widget }}</v-card-title>
                <v-card-title v-else class="sub-heading">{{
                    $store.getters.getTextMap().add_consolidated_daywise_machine_status_widget }}</v-card-title>

                <v-row class="px-8">

                    <!-- widtget label -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="40"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label"
                            required></v-text-field>
                    </v-col>

                    <!-- widget width -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions" :rules="[rules.required]"
                            :label="$store.getters.getTextMap().widget_width" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>

                    <!-- widget height -->
                    <v-col cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select v-if="!loading" v-model="widgetForm.height" :items="heightOptions"
                            :label="$store.getters.getTextMap().widget_height" dense outlined item-text="label"
                            item-value="value" :rules="[rules.required]"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    </v-col>

                    <!-- widget background -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select v-if="!loading" v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color" dense
                            outlined item-text="label" item-value="value" clearable
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }">
                            <template v-slot:selection="{ item }">
                                <div :style="{ 'background-color': item.value }">{{ item.label }}</div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div :style="{ 'background-color': item.value }">{{ item.label }}</div>
                            </template>
                        </v-select>
                    </v-col>

                    <!-- widget timeframe -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="widgetForm.time_frame" :items="tdOptions"
                            :label="$store.getters.getTextMap().timeFrame" dense outlined :rules="[rules.required]"
                            item-text="label" item-value="value"></v-select>
                    </v-col>

                    <!-- Graph type  -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="graphType" :items="graphTypeOptions"
                            :label="$store.getters.getTextMap().default_graph_type" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>

                    <!-- graph mode  -->
                    <v-col v-if="!loading && graphType == 2" align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                            v-if="!loading && graphType == 2" v-model="barmode" :items="barmodeOptions"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().bar_mode" dense outlined
                            item-text="label" item-value="value"></v-select>
                    </v-col>

                    <!-- widget parm seq -->
                    <v-col v-if="edit" align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field v-if="!loading" v-model="widgetForm.seq" :counter="4"
                            :label="$store.getters.getTextMap().sequence" dense outlined></v-text-field>
                    </v-col>

                    <!-- x-axis label -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="xaxisLabel" :label="$store.getters.getTextMap().xaxis_label" dense outlined
                            item-text="label" item-value="value" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <!-- y-axis label -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="yaxisLabel" :rules="[rules.required]"
                            :label="$store.getters.getTextMap().yaxis_label" dense outlined item-text="label"
                            item-value="value"></v-text-field>
                    </v-col>
                </v-row>

            </v-card>
        </v-form>

        <v-form ref="usageForm" v-model="valid">

            <v-card :dark="$store.getters.getColorPalette().isDark"
                :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
                flat outlined class="px-2 pb-4 ">
                <v-card-title class="sub-heading">{{
                    $store.getters.getTextMap().add_machines }}</v-card-title>
                <v-row class="px-6">
                    <!-- Workspace Options -->
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="workspace" :items="workspaceOptions" :label="$store.getters.getTextMap().workspace"
                            outlined dense item-text="label" item-value="value" required
                            :rules="objectRules"></v-select>
                    </v-col>

                    <!-- Machine Options based on the workspaces -->
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="machine" :items="machineOptions" :label="$store.getters.getTextMap().machine"
                            outlined dense item-text="label" item-value="value" required
                            :rules="objectRules"></v-autocomplete>
                    </v-col>

                    <v-col class="text-center">
                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined
                            @click="addToList">{{
                                $store.getters.getTextMap().add_machine }}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().btnborderColorCode"
                                small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-form>


        <v-card v-if="tableData.length !== 0" :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
            flat outlined class="px-2 pb-4 mt-5 ">
            <v-card-title class="sub-heading">{{
                $store.getters.getTextMap().added_machines }}</v-card-title>

            <v-row class="pa-2" v-if="!loading">
                <v-col>
                    <v-simple-table height="200px"
                        :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left"><strong>{{ $store.getters.getTextMap().workspace }}</strong>
                                    </th>
                                    <th class="text-left"> <strong>{{ $store.getters.getTextMap().machine }}</strong>
                                    </th>
                                    <th class="text-left">{{ $store.getters.getTextMap().delete }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Deleting parameter from the list -->
                                <tr :key="item.label" v-for="item in tableData">
                                    <td>{{ item.workspace_name }}</td>
                                    <td>{{ item.machine_name }}</td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteFromTable(item)"
                                            title="Delete Confirmation"
                                            description="Are you sure you want to delete this?">
                                            <v-icon small color="red">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>




            <v-row no-gutters>
                <v-spacer />
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" small filled
                        @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                    <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor" small
                        @click="onReset">{{ $store.getters.getTextMap().reset }}</v-btn>
                </v-col>
            </v-row>

        </v-card>



    </v-container>
</template>


<script>
import InfoAlert from '@/components/InfoAlert';
import FormRules from '@/utillities/FormRules'
import axios from 'axios';
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name: "AddConsolidatedDayWiseMachineStatusWidget",
    components: {
        InfoAlert,
        DeleteConfirmation
    },
    props: ['widget', 'seq'],
    mounted() {
        this.init()
    },

    data() {
        return {

            showDismissibleAlert: false,
            loading: false,
            info: "",
            widgetValid: false,
            edit: false,
            api: 'createWidgetForView',
            rules: FormRules.rules,
            widgetForm: {},
            barmode: 'group',
            yaxisLabel: null,
            xaxisLabel: null,
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                { label: '3/4 screen', value: 9 },
                { label: 'Auto', value: null }
            ],
            barmodeOptions: [
                { label: 'Stacked', value: 'stack' },
                { label: 'Grouped', value: 'group' }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            tdOptions: [
                // {label:'Current Day', value:'0'},
                { label: 'yesterday', value: '1' },
                { label: 'Last 7 days', value: '7' },
                { label: 'last 30 days', value: '30' },
                { label: 'last 60 days', value: '60' },
                { label: 'last 90 days', value: '90' },
                { label: 'MTD', value: 'mtd' },
            ],
            graphType: 2,
            graphTypeOptions: [
                { label: 'Bar', value: 2 },
                { label: 'Line', value: 1 },
            ],
            workspace: null,
            machine: null,
            objectRules: [
                v => !!v || 'Required',
            ],
            valid: false,
            tableData: [],
            machineOptions: [],
        }
    },
    computed: {
        // Workspaces input fiels data
        workspaceOptions() {
            let op = []
            let g = this.$store.state.workspaces
            for (let i of g) {
                op.push({ value: i.workspace_id, label: i.name })
            }
            return op
        },
    },
    methods: {

        init() {

            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true
                this.widgetForm = Object.assign({}, this.widget)
                this.api = 'updateWidget'
                if (!(this.widgetForm.meta)) {
                    this.widgetForm.meta = { data: [] }
                    this.tableData = []
                } else {
                    // console.log("else");
                    this.tableData = this.widgetForm.meta.data
                    this.widgetForm.time_frame = this.widgetForm.meta.time_frame
                    this.graphType = this.widgetForm.meta.graphType
                    this.widgetForm.meta.legend = true
                    this.barmode = this.widgetForm.meta.barmode
                    this.yaxisLabel = this.widgetForm.meta.yaxis_label
                    this.xaxisLabel = this.widgetForm.meta.xaxis_label

                }
            } else {
                // There is no widget for view then this will initiate
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView'
                    this.widgetForm = Object.assign({}, this.widget)
                    this.widgetForm.meta = { data: [] }
                    this.widgetForm.seq = this.seq ? this.seq : 0
                    this.tableData = []
                }
            }

        },

        getMachineList() {
            let op = []
            if (this.workspace) {
                let payload = {
                    workspace_id: this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        console.log(response);

                        if (response.data.status == "success") {
                            let machines = response.data.machines
                            for (let i of machines) {
                                if (i && i.name) {
                                    op.push({ value: i.machine_id, label: i.name })
                                } else {
                                    op.push({ value: i.machine_id, label: i.machine_id })
                                }
                            }
                        } else {
                            console.log(response.data.msg)
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error)
                        this.info = error;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    });
            }
            this.machineOptions = op
            console.log(this.machineOptions);

        },

        deleteFromTable(data) {
            this.tableData.forEach((item, i) => {
                if (item.machine_name === data.machine_name) {
                    this.tableData.pop(i)
                }
            })

        },
        addToList() {
            this.$refs.usageForm.validate()
            if (this.valid) {
                this.loading = true
                let machine = this.machineOptions.find(machine => machine.value === this.machine)
                let workspace = this.workspaceOptions.find(item => item.value === this.workspace)

                machine = {
                    machine_name: machine.label,
                    machine_id: machine.value
                }

                workspace = {
                    workspace_name: workspace.label,
                    workspace_id: workspace.value
                }


                const machineExists = this.tableData.some(item => item.machine_id === this.machine);

                if (machineExists) {
                    alert('Machine ID already exists in the list!');
                } else {
                    this.tableData.push({ ...machine, ...workspace });
                }
            }

            console.log(this.tableData);
            this.loading = false

        },
        onSubmit() {
            this.$refs.widgetForm.validate()
            if (this.widgetValid) {
                this.loading = true
                this.widgetForm.meta.data = this.tableData
                this.widgetForm.meta.time_frame = this.widgetForm.time_frame
                this.widgetForm.meta.graphType = this.graphType
                this.widgetForm.meta.legend = true
                this.widgetForm.meta.barmode = this.barmode
                this.widgetForm.meta.yaxis_label = this.yaxisLabel
                this.widgetForm.meta.xaxis_label = this.xaxisLabel

                console.log(this.widgetForm);

                axios.post(this.$store.state.api + this.api, this.widgetForm, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        console.log("called");
                        
                        console.log(response);

                        if (response.data.status === 'success') {
                            this.loading = false
                            this.$emit('success')
                            this.$emit('close')
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.info = err;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    });

            }

        },
        onReset() {
            this.$refs.widgetForm.reset()
            this.$refs.usageForm.reset()

        }


    },
    watch: {
        workspace() {
            this.getMachineList()
        }
    }

}
</script>

<style></style>
<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid :style="{background:'#000000'}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row align="center" justify="center">
            <v-col >
                <v-card class="eula-card" flat outlined :dark="true">
                    <!-- <v-divider class="divider"></v-divider> -->
                <header class="eula-header">END-USER LICENSE AGREEMENT</header>
                <v-divider class="divider"></v-divider>
                <v-card-text>
                    <div class="eula-section">
                    <p class="eula-text">
                    This End User License Agreement (“Agreement”) is entered into on the date and time on which You specify your consent to the terms of this Agreement by clicking on “I agree” button at the bottom of this Agreement (the “Effective Date”).
                    This Agreement is between Nebeskie Labs Private Limited with a place of business at AWFIS OMR, 273a, Rajiv Gandhi Salai, Perungudi, Chennai – 600096, Tamil Nadu, India (“Company”), and the Customer (“Customer / You”).
                    References to You, your etc. shall be construed accordingly.
                    </p>
                    <p class="eula-text">
                        This Agreement is being entered into through electronic means and shall be valid and binding upon You and the Company in accordance with applicable laws.  
                    </p>
                    <p class="eula-text">
                        You / your organization/your employer has purchased a license to use the Company SAAS product from an authorized Company partner (“Partner”) by way of an order form / agreement (“Purchase Agreement”); a copy of which can be accessed from your organization / your employer.
                        Terms capitalized here but not defined herein shall have the meanings assigned to them in the Purchase Agreement. The term “Services” as used herein shall mean the Company SAAS product described in detail in the Purchase Agreement.  
                    </p>
                </div>
                </v-card-text>
                <v-divider class="divider"></v-divider>
                <header class="eula-header">TERMS AND CONDITIONS </header>
                <v-divider class="divider"></v-divider>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">1. SAAS SERVICES AND SUPPORT</header>
                    <p class="eula-text">
                        1.1 Subject to the terms of this Agreement and the Purchase Agreement, Company will use commercially reasonable efforts to provide Customer / You the Services. As part of the registration process, Customer / You will identify an administrative user-name and password for Customer’s entity account. 
                        Company reserves the right to refuse registration of, or cancel passwords it deems inappropriate. You agree that you shall keep such user-name and password confidential and shall not share it with any person not so authorized under this Agreement or by your organization / your employer.
                    </p>
                    <p class="eula-text">
                        1.2 Subject to the terms hereof, Company will provide Customer with reasonable technical support services in accordance with the terms set forth in Exhibit A. The Customer shall not have recourse against the Company for any technical support services not specifically covered in Exhibit A. 
                        This shall not in any way affect any service obligations of the Partner towards the Customer as set out in the Purchase Agreement.
                    </p>
                    <p class="eula-text">
                        1.3 The Services provision, including duration of the Service’s provision, price, service capacity etc., shall be governed by the Purchase Agreement subject to the terms of this Agreement.
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">2. RESTRICTIONS AND RESPONSIBILITIES </header>
                    <p class="eula-text">
                        2.1 Customer will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (“Software”); 
                        modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for time sharing or service bureau purposes or otherwise for the benefit of a third party; or remove any proprietary notices or labels.  
                    </p>
                    <p class="eula-text">
                        2.2 With respect to any Software that is distributed or provided to Customer for use on Customer premises or devices, Company hereby authorizes the grant to Customer a non-exclusive, non-transferable, non-sublicensable license to use such Software during the Term only in connection with the Services (“License”).  
                    </p>
                    <p class="eula-text">
                        2.3 Under this License, Customer shall subject to the Purchase Agreement have the right to: (i) use the Software in its commercial activity, in compliance with Company’s standard published policies then in effect (“Policy”) and all applicable laws and regulations. Customer may use the Software by ways indicated herein without limitation in terms of area, namely worldwide. 
                    </p>
                    <p class="eula-text">
                        2.4 Customer shall not use this License in an illegal manner, including, but not limited to any practice that contains, promotes, or has links to any illegal content, spyware, viruses, phishing, malware, online casinos and/or gambling in jurisdictions where such activity is prohibited, weapons and/or ammunition, illegal drugs. 
                        Whether or not the factual use of the Software by Customer conforms to the requirements of paragraphs hereof shall at any time be determined solely by Company. In order to do this, Company shall have the right to request from Customer relevant information, reports, documentation and other evidence during normal working hours provided that any such information, reports, 
                        documentation and other evidence shall be treated as strictly confidential by the Company. 
                    </p>
                    <p class="eula-text">
                        2.5 Customer hereby agrees to indemnify and hold harmless Company against any damages, losses, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees) in connection with any claim or action that arises from an alleged violation of Customer’s use of the Service 
                    </p>
                    <p class="eula-text">
                        2.6 Customer represents, covenants, and warrants that Customer will use the Services only in compliance with Company’s Policy and all applicable laws and regulations.   
                    </p>
                    <p class="eula-text">
                        2.7Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Equipment”).  
                        Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer’s knowledge or consent. 
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">3. CONFIDENTIALITY; PROPRIETARY RIGHTS  </header>
                    <p class="eula-text">
                        3.1 Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose confidential and/or proprietary business, technical, or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party).  
                        Propriety Information shall include but not be limited to all information clearly identified as confidential at the time of disclosure, and shall include, but is not limited to such information of the Disclosing Party and/or its affiliates as: business affairs, operations, products, projects, concepts, prototypes, processes, methodologies, formulae, blueprints, plans, inventions, projections, know-how, technical knowledge, drawings, product developments, documentation, trade secrets, market opportunities, suppliers, customers, marketing activities, sales, software, telecommunications and security systems, counteragents, employees, subcontractors, salaries, bonuses, fees, and other forms of remuneration, lists of current and potential customers, financial information (including income, profit, costs, expenses), price calculations, business structure (including ownership, interest, control, and representation), offers and proposals, market analysis, statistical data, contracts, negotiations, communication, any personal information, as well as all Intellectual Property of a Disclosing Party. Proprietary Information of Company includes non-public information regarding features, functionality, and performance of the Service.  Proprietary Information of Customer includes non-public data provided by Customer to Company to enable the provision of the Services (“Customer Data”). 
                        Customer will use its best efforts not to disclose to Company any personal information of third parties, if it is not necessary for the Services provision. The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.  
                        Disclosing Party agrees that the foregoing shall not apply with respect to any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.
                    </p>
                    <p class="eula-text">
                        Customer shall own all right, title and interest in and to the Customer Data, as well as any data that is based on or derived from the Customer Data and provided to Customer as part of the Services provided however, the Customer shall only have a limited right to use or view any data generated by the Software and stored / displayed on the Equipment during the Term of this Agreement. 
                        The Customer agrees that it is not authorized to and shall not make any attempts to download, make copies of or store in any other medium such data or access such data on the expiry of the License. Subject to the above, Company shall own and retain all right, title and interest in and to (a) the Services and Software, all improvements, enhancements or modifications thereto, (b) any software, applications, 
                        inventions or other technology developed in connection with implementation of Services or support, and (c) all intellectual property rights related to any of the foregoing (including without limitation all patent, trademark, copyright, trade dress, trade secrets, database rights and all other intellectual property rights). 
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">4. PAYMENT OF FEES   </header>
                    <p class="eula-text">
                        4.1 Customer will pay the then applicable fees described in the Purchase Agreement for the Services in accordance with the terms therein (“Fees”).
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">5. TERM AND TERMINATION    </header>
                    <p class="eula-text">
                        5.1 The term of this Agreement shall be co-terminus with the License Period as specified in the Purchase Agreement subject to the Customer complying with the terms of this Agreement. 
                    </p>
                    <p class="eula-text">
                        5.2 Notwithstanding anything contained in the Purchase Agreement, in addition to any other remedies it may have, either party may also terminate this Agreement upon thirty (30) days’ notice (or without notice in the case of nonpayment), if the other party materially breaches any of the terms or conditions of this Agreement. Customer will pay in full for the Services up to and including the last day on which the Services are provided in accordance with the Purchase Agreement. 
                        Notwithstanding anything contained in the Purchase Agreement, a breach of Clause 2 or Clause 3 of this Agreement by the Customer shall be considered to be a ‘material breach’ under this Agreement. 
                    </p>
                    <p class="eula-text">
                        5.3 All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.  
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">6. WARRANTY AND DISCLAIMER     </header>
                    <p class="eula-text">
                        6.1 Company shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimizes errors and interruptions in the Services and shall perform the technical support services listed in Exhibit A in a professional and workmanlike manner.  Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company’s reasonable control, 
                        but Company shall mandatorily provide advance notice in writing or by e-mail of any scheduled service disruption.  
                        However, Company does not warrant that the Services will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use of the Services.  Except as expressly set forth in this Section, the Services and Implementation Services are provided “as is”, and Company disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">7. LIABILITY      </header>
                    <p class="eula-text">
                        7.1 Notwithstanding anything to the contrary, Company and its suppliers (including but not limited to all equipment and technology suppliers), officers, affiliates, representatives, contractors, and employees, specifically the Partner, shall not be responsible or liable with respect to any subject matter of this agreement or terms and conditions related thereto under any contract, negligence, strict liability, or other theory:
                            (a) for error or interruption of use or for loss or inaccuracy or corruption of data or cost of procurement of substitute goods, services, or technology or loss of business;
                            (b) for any indirect, exemplary, incidental, special, or consequential damages;
                            (c) for any matter beyond Company’s reasonable control; or
                            (d) for any amounts that, together with amounts associated with all other claims, exceed the fees paid by Customer under the Purchase Agreement for the Services under this agreement in the 12 months prior to the act that gave rise to the liability,
                            in each case, whether or not Company has been advised of the possibility of such damages.
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">8. FORCE MAJEURE</header>
                    <p class="eula-text">
                        8.1 Neither Party shall be deemed to be in breach or default of this Agreement as the result of any delay or non-performance caused by circumstances beyond the Party’s reasonable control, including without limitation flood, fire, storm, earthquake, or other Act of God, war acts of the public enemy, invasion, riot, civil war or disturbance, strike, terrorist act, lockout, labor dispute, nationalization, annexes, governmental sanction, embargo, power failure, or equipment failure (“Force Majeure”). 
                        However, this shall not apply where the delayed or non-performing Party is under a duty under this Agreement to take certain backup, business continuity or disaster recovery measures but has failed to take such measures. 
                    </p>
                    <p class="eula-text">
                        8.2 The affected Party shall inform the other Party in writing about the Force Majeure events and their influence as soon as reasonably possible. Should non-performance of or a delay in the provision of the Services due to Force Majeure persist for more than 60 (sixty) calendar days, either Party shall have the right to terminate the Agreement. 
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">9. MISCELLANEOUS </header>
                    <p class="eula-text">
                        9.1 If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.  
                    </p>
                    <p class="eula-text">
                        9.2 This Agreement is not assignable, transferable or sublicensable by Customer except with Company’s prior written consent.  Company may transfer and assign any of its rights and obligations under this Agreement without consent. 
                    </p>
                    <p class="eula-text">
                        9.3 This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein. 
                    </p>
                    <p class="eula-text">
                        9.4 No agency, partnership, joint venture, or employment is created as a result of this Agreement and Customer does not have any authority of any kind to bind Company in any respect whatsoever. 
                    </p>
                    <p class="eula-text">
                        9.5 In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys’ fees.  
                    </p>
                    <p class="eula-text">
                        9.6 All notices under this Agreement will be in writing and will be deemed to have been duly given:
                        When received, if personally delivered.
                        When receipt is electronically confirmed, if transmitted by facsimile or e-mail.
                        The day after it is sent, if sent for next day delivery by recognized overnight delivery service.
                        Upon receipt, if sent by certified or registered mail, return receipt requested.
                    </p>
                    <p class="eula-text">
                        9.7 This Agreement shall be governed by the laws of India and any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of courts at Chennai, Tamil Nadu, India  without regard to its conflict of laws provisions. It is clarified that notwithstanding anything contained herein, any disputes between the Customer and the Partner without involvement of the Company shall be governed by the relevant dispute resolution provisions contained in the Purchase Agreement and in case such provisions are absent, this clause 9.7 shall mutatis mutandis apply to such disputes as well.
                    </p>
                    </div>
                </v-card-text>
                <v-divider class="divider"></v-divider>
                <header class="eula-header">EXHIBIT A </header>
                <header class="eula-header">TECHNICAL SUPPORT SERVICES AND RELEVANT SERVICE LEVELS </header>
                <v-divider class="divider"></v-divider>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">1. Service Level Agreement </header>
                    <p class="eula-text">
                        The Software will achieve System Availability (as defined below) of at least 99% during each calendar year of the Subscription Term. “System Availability” means the number of minutes in a year that the key components of the Software are operational as a percentage of the total number of minutes in such year, excluding downtime resulting from (a) scheduled maintenance, (b) events of Force Majeure in the Agreement, (c) malicious attacks on the system, (d) issues associated with the Client’s computing devices, local area networks or internet service provider connections, or (e) inability to deliver the Services because of acts or omissions of or any Permitted User. Company reserves the right to take the Software offline for scheduled maintenance for which the Client has been provided reasonable notice and Company reserves the right to change its maintenance window upon prior notice to the Client.  
                    </p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="eula-section">
                    <header class="eula-subheader">2. Maintenance and Support  </header>
                    <p class="eula-text">
                        Support and maintenance services for the Software are included in the subscription to the Services provided by Company under this Agreement and entitles the Client to the following: 
                    </p>
                    <p class="eula-text">
                        (a) Electronic support through email and telephone to help Client locate and correct Errors with the Software during business hours.  
                    </p>
                    <p class="eula-text">
                        (b) Bug fixes and Error corrections to any Software malfunctions under Company Scope 
                    </p>
                    <p class="eula-text">
                        (c) Provision of Enhancements as and when made available by the Company to all its customers. (Updates to the cloud application and over the air updates to Edge computing software) 
                    </p>
                    <p class="eula-text">
                        (d) System Health Check –  
                        <ul>
                            <li><span class="eula-subtext">Remote online health check will be done monthly to ensure system stability & ideal</span></li>
                            <li><span class="eula-subtext">Site visit for offline health check on a quarterly basis to be done by Company for both Software and hardware (applicable while within warranty)</span></li>
                        </ul>
                    </p>
                    <p class="eula-text">
                        Business Hours: 10am-6pm IST, Monday through Friday, except holidays. 
                    </p>
                    </div>
                </v-card-text>
                <v-col cols="auto" class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">I Agree</v-btn>
                </v-col>
            </v-card>
        </v-col>
    </v-row>
    <v-row   align="center" justify="center">
        <v-col>
            <header class="eula-agree">By clicking on “I Agree”, you specify your consent to the terms above. </header>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'EulaAgreement',
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                eula_signed:false
            },
            api:'agreeEula',
            title:'End User License Agreement',
            loading:false,
            info:null,
            infoMessage:"",
            showDismissibleAlert:false,
            valid:false,
        }
    },
    mounted(){
    },
    methods:{
        onSubmit(){
                this.form.eula_signed=true
              this.loading = true;
              axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response => {
                if (response.data.status == "success") {
                    this.refresh()
                    this.$emit('close')
                } else {
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                }
                this.loading = false;
              })
              .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
              });
        },
        refresh(){
            this.$store.dispatch('refresh')
            setTimeout(function () { this.refresh() }.bind(this), 1800000);
    },
    }
}
</script>
<style scoped>
.eula-card {
  max-height: 520px;
  padding: 20px;
  overflow-y: auto;
  text-align: left;/* Update with your desired background color */
}

.eula-header {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  /* margin-bottom: 20px; */
}
.eula-subheader {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color:#1DE9B6;
  text-align: left;
}
.eula-agree {
    text-align: center;
  font-size: 20px;
  font-weight: bold;
  color:#8C9EFF;
}

.eula-section {
  padding: 20px;
  background-color: black;
  border-radius: 15px;
  /* margin-bottom: 10px; */
}

.eula-text {
  font-size: 16px;
  line-height: 1.6;
}

.divider {
  margin: 20px 0;
}

</style>
<template>
      <v-container fluid :style="{background: $store.getters.getColorPalette().background2ColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters>
            <v-col>
               
                 <div ref="graph"></div>
           
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import InfoAlert from '@/components/InfoAlert'
export default {
 name: 'Multiparam',
 props: ['params','dimensions'],
 components:{
    InfoAlert,
 },
  data(){
        return {
             x:0,
             y:0,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            tempData:{},
            graphData:[],
            // params:[],
            from_date:null,
            to_date:null,
             layout : {
                  font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                 
                plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',

                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,//this.$store.getters.getColorPalette().background2ColorCode,
                  showlegend:true,
                  autosize: true,
                  xaxis: {
                      tickangle: -12,
                      zeroline: false,
                      showgrid: true,
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },       
                    yaxis: {
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },
                    uniformtext: {
                      "mode": "hide",
                      "minsize": 1
                    }
                  } ,
        }
  },
 mounted(){
      this.getmultiData()
    },
  methods:{
        show (e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    init(){
        this.graphData={}
        for(let i of this.params){
            this.graphData[i.param_id]={
                        x:[],
                        y:[],
                        fill: 'tonexty',
                        type: 'scatter', 
                        name:i.name
            }
           }
    },
       async  getmultiData(){
            this.init()
                this.loading=true
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
                if(this.dimensions){
                  this.layout.height=this.dimensions[0]['height']
                  // this.layout.width=this.dimensions[1]['width'] 
                  // this.layout.height=50
                  // this.layout.width=400
                  this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
                  this.layout.margin={
                    t:40,
                    b:40,
                    l:80,
                    r:100
                }
                }

            try{
                //let temp={}
                 //console.log(temp,'tp')
                for(let i of this.params){
                  if(!i['param_id']){
                    continue
                  }
                    let payload={
                      param_id: i['param_id'] , 
                      from_timestamp:moment().subtract(1,'day').unix(), 
                      to_timestamp:moment().unix()
                    }
                let temp_response = await  axios.post(this.$store.state.api+'getUsageDataByParamBetweenTimestamp',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                   }})
                   //console.log(temp_response,'tp2')
                   if(temp_response.data.status==='success'){
                    for(let i of temp_response.data.data){
                this.graphData[i.param_id]['x'].push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                this.graphData[i.param_id]['y'].push(i.data)
            }
            if(this.$refs.graph){
              Plotly.newPlot(this.$refs.graph,Object.values(this.graphData),this.layout,{displaylogo:false, responsive: true})
            }
            
                    } else{
                    
                      this.info=temp_response.data.msg
                      this.showDismissibleAlert=true
                  }
                      this.loading=false
                }
                }catch(error){
                    console.error(error)
            }
            
       }  
  },
  watch:{
        params:{
            handler(){
                this.getmultiData()
            },
            deep:true
        }
    }
  }          
            

</script>
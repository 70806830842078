<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            @click="dialog=true"
            v-if="$store.state.settingMode && edit"
        >
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="showEdit=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon>
                         
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                      
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="showEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showEdit = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{this.widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items><v-btn :dark="$store.getters.getColorPalette().isDark"  text @click="showEdit = false">{{$store.getters.getTextMap().close}}</v-btn></v-toolbar-items>
                </v-toolbar>
                <AddBusBarLiveMonitoringWidget :widget="widget" v-on:close="showEdit=false" v-on:success="$emit('update')"/>
            </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row justify="center" no-gutters>
            <v-col cols="12" align="center">
                <div v-if="widget.meta.data" ref="graph" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().backgroundColor2Code,'height':'175px',}:{}]"></div>
                <div v-else :style="[widget.height==='fixed' ? {'height':'175px'}:{}]">
                    <v-icon small>mdi-hail</v-icon>
                    <h5>{{$store.getters.getTextMap().not_configured}}</h5>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {io} from 'socket.io-client'
import InfoAlert from '@/components/InfoAlert'

import AddBusBarLiveMonitoringWidget from '@/components/crud_components/widgets/AddBusBarLiveMonitoringWidget'
import axios from 'axios'
import moment from 'moment'
import Plotly from "plotly.js"
// import DeleteConfirmation from '@/components/DeleteConfirmation'

export default {
name: "BusBarLiveMonitoring",
components:{
    AddBusBarLiveMonitoringWidget,
        InfoAlert,
        // DeleteConfirmation
    },
props:['widget','edit'],
data() {
    return {
             showDialog:false,
            entureModule:{
                module_id:null
            },
            tempData:{},
            streamData:[],
            param_lists:[],
            baseLayout:{
                showlegend:true,
                //colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
                font:{
                    color:this.$store.getters.getColorPalette().accentCode
                },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                xaxis:{
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode
                },       
                yaxis:{
                    showticklabels: true,
                    color:"FFF"
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            },
            layout:{
                showlegend:true,
                //colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
                font:{
                    color:this.$store.getters.getColorPalette().accentCode
                },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                xaxis:{
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode
                },       
                yaxis:{
                    showticklabels: true,
                     color:this.$store.getters.getColorPalette().accentCode
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            },
            x:0,
            y:0,
            showEdit:false,
            loading:false,
            showMenu:false,
            stream:null,
            val:null,
            info:'',
            showDismissibleAlert:false,
    };
  },
created(){
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
},
beforeDestroy() {
    this.stream.close()
},

mounted() {

    this.init()
    this.init2()
  },
computed:{

        },
methods: {
    init(){
        if(this.widget.meta && this.widget.meta.data && this.widget.meta.data[0].busbar_module_id){
        this.entureModule.module_id = this.widget.meta.data[0].busbar_module_id
        this.param_lists = this.widget.meta.data[0].parameters
        this.tempData={}
        for(let h of this.param_lists){
            this.tempData[h]={
                x:[],y:[],name:h
            }
        }
         }
    },
    init2(){
        if(this.widget.meta.data){
            if(this.widget.height==='fixed'){
              this.layout.height=170
            //   this.layout.width=this.widget.width*97
              this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
              this.layout.margin={
                t:40,
                b:40,
                l:40,
                r:40
              }
          }else{
            this.layout=Object.assign({},this.baseLayout)
          }
            this.stream.on("connect_error", (err) => {
                console.log(err.message); // prints the message associated with the error
            });
            this.stream.on('error',data=>{
                console.error(data)
            })
            this.stream.on('success',data=>{
                console.log(data)
            })
            this.stream.on('moduleData',data=>{
            this.streamData.push(data)
            if(this.streamData.length>50){
                this.streamData.shift()
            }
            for(let h of this.param_lists){
                if(h in data){
                    this.tempData[h]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                    this.tempData[h]['y'].push(Number(data[h]))
                    if(this.tempData[h]['x'].length>100){
                    this.tempData[h]['x'].shift()
                    this.tempData[h]['y'].shift()
                    }
                }
            }
            this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
            Plotly.newPlot(this.$refs.graph,Object.values(this.tempData),this.layout,{displaylogo:false})
            })
            if(this.entureModule.module_id){
                this.getModule()
                this.stream.emit('getModuleData',this.entureModule.module_id)
            }
        }
    },
    getModule(){
        if(this.entureModule.module_id){
            this.loading=true
            axios.post(this.$store.state.api+'getBusbarTempModuleByID',{module_id:this.entureModule.module_id},{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status=='success'){
                    // this.edit=false
                    this.busbarTempModule=response.data.module     
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                    this.loading=false
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        }
    },
    show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
    },
    deleteWidget(){
    axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }}).then(response=>{
        if(response.data.status==='success'){
            // this.$store.dispatch("refreshDashboardGauges")
            //this.onReset()
            this.dialog=false
                this.loading=false
                //this.dialog=false
                //this.$emit('close')
                this.$emit('update')
        }
        })
        .catch(err=>{console.log(err)});
    },
      cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
  },
    watch:{
        widget:{
            handler(){
                if(this.stream && this.entureModule.module_id){
                    this.stream.emit('removeModuleData',this.entureModule.module_id)
                }
                this.init()
                this.init2()
            },
            deep:true
        }
    }
};
</script>

<style scoped>
.gauge-title span {
  display: inline;
  text-align: center;
}
</style>
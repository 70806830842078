<template>
  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
    <v-form ref="writeForm" v-model="valid">
      <v-row>
        <v-card-title><strong>Write</strong></v-card-title>
        <v-col align="right" v-if="isCoil">
          <span><v-btn small class="mx-1" text outlined :color="$store.getters.getColorPalette().deletebtnColor"  :dark="$store.getters.getColorPalette().isDark" @click="writeCoil(0)">Off</v-btn><v-btn :dark="$store.getters.getColorPalette().isDark" small outlined text color="green" @click="writeCoil(1)">On</v-btn></span>
        </v-col>
      </v-row>
      <v-row >
        <v-col align-self="center">
          <v-text-field  
            :dark="$store.getters.getColorPalette().isDark" 
            :loading="loading"
            v-model="writeVal"
            :counter="12"
            dense
            outlined
            :rules="[rules.required,rules.num]"
            :label="$store.getters.getTextMap().data"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="writeParam()">{{$store.getters.getTextMap().write_data}}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'ParamWritePanel',
    props:['isCoil','param_id'],
    components:{
        InfoAlert
    },
    data(){
        return {
          form:{},
          writeVal:null,
          loading:false,
          info:null,
          showDismissibleAlert:false,
          valid:false,
          rules:FormRules.rules
          }
        },
        methods:{
        writeCoil(state){
          this.writeVal=state
          let payload={
                value:state,
                parameter:this.param_id
              }
          this.loading=true
          axios.post(this.$store.state.api+'writeParam',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
          .then(response=>{
            if(response.data.status==='success'){
              this.loading=false
              this.info="Command Sent"
              this.showDismissibleAlert=true
              this.$emit('success')
            }else{
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading=false
            }})
            .catch(err=>{console.log(err)
              this.info=err
              this.loading=false
              this.showDismissibleAlert=true
            });
        },
        writeParam(){
          this.$refs.writeForm.validate()
          if(this.valid){
            this.loading = true;
            let payload={
                  value:this.writeVal,
                  parameter:this.param_id
                }
            axios.post(this.$store.state.api+'writeParam',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
              this.loading=false
              if(response.data.status==='success'){
                this.info="Command Sent"
                this.showDismissibleAlert=true
              }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
              }})
            .catch(err=>{console.log(err)
            this.info=err
            this.loading=false
            this.showDismissibleAlert=true
            });
          }
        },
        onReset(){
          this.$refs.orgForm.reset()
        }
    }
}
</script>
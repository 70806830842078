<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-col>
                <v-simple-table class="pb-5" :style="{background: $store.getters.getColorPalette().backgrounColorCode}" >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    <strong>{{$store.getters.getTextMap().tag_type}}</strong>
                                </th>
                                <th class="text-left">
                                    <strong>{{$store.getters.getTextMap().operator}}</strong>
                                </th>
                                <th class="text-left">
                                    <strong>{{$store.getters.getTextMap().operand}}</strong>
                                </th>
                                <th class="text-left">
                                    <strong>{{$store.getters.getTextMap().action}}</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(item, index) in filteredRules">
                                <td>{{ item.tag_type }}</td>
                                <td>{{ item.operator }}</td>
                                <td>{{ item.operand }}</td>
                                <td>
                                    <DeleteConfirmation v-on:confirm="deleteRule(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this rule?">    
                                        <v-btn class="mr-1" small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor">Delete Rule<v-icon small  :color="$store.getters.getColorPalette().accentCode">mdi-delete</v-icon></v-btn>
                                    </DeleteConfirmation>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
export default {
    name:'DeletemachineStatusRules',
    props:['ruleDetails','machine'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            api:"deleteMachineStatusRule",
        }
    },
    computed: {
        filteredRules() {
        const { rules, status } = this.ruleDetails;
            return rules.filter(item => item.machine_status === status);
        }
    },
    methods:{
        deleteRule(item){
            this.loading=true
            let payload={
                machine_id:this.machine.machine_id,
                tag_type:item.tag_type,
                machine_status:this.ruleDetails.status,
                operator:item.operator,
                operand:item.operand
            }
            axios.post(this.$store.state.api+this.api,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.loading=false
                    this.$emit('delete')
                }else{
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
            }).catch(err=>{
                console.error(err)
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            });
        },
    }
}
</script>
import BinaryMathOperations from "./BinaryMathOperations";

export default{
    name:'RuleProcessingUtillity',
    evaluateRule:(value, rules, defaultFallback={'label':'unknown'})=>{
        let rule=rules.find((d)=>{
            return BinaryMathOperations.performOperation(value,d.operator,d.operand)
        })

        return rule?rule:defaultFallback

    }
}


const securityConfig = {
    "device":0,
    "report":1,
    "node":2,
    "scheduling":3,
    "ebill":4,
    "datasource":5,
    "engineeraccess":6,
    "network":7,
    "uam":8,
    "hub":9,
    "parameter":10,
    "maintainer":11,
    "logs":12,
    "betaaccess": 13,
    "write":14,
    "alphaaccess":15
};
const accessOp=[
    {label:"Enture Device Access", value:"device"},
    {label:"Report Access", value:"report"},
    {label:"Control Node Access", value:"node"},
    {label:"Scheduling Access", value:"scheduling"},
    {label:"Ebill Access", value:"ebill"},
    {label:"DataSource Access", value:"datasource"},
    {label:"Engineer's Access", value:"engineeraccess"},
    {label:"Network Access", value:"network"},
    {label:"User Access Management", value:"uam"},
    {label:"Hub Access", value:"hub"},
    {label:"Device Parameter Access", value:"parameter"},
    {label:"Maintainer Access", value:"maintainer"},
    {label:"Log Access", value:"logs"},
    {label:"Beta Access", value:"betaaccess"},
    {label:"write Access", value:"write"},
    {label:"Alpha Access", value:"alphaaccess"},
]
const getAccessListByUserLevel=(userLevel)=>{
    let list=[]
    for(let i of Object.keys(securityConfig)){
        if(BitwiseOperationUtillity.getBitState(securityConfig[i],userLevel)){
            list.push(i)
        }
    }

    return list
}
import BitwiseOperationUtillity from '@/utillities/BitwiseOperationUtillity'
export default{
    name:'SecurityUtillity',
securityConfig:securityConfig,
accessOp:accessOp,
accessOptions:accessOp,
getUserLevel:(levels)=>{
    let userLevel=0
    if(levels && levels.length>0){
        return levels.reduce((accumulator, currentValue) => { return BitwiseOperationUtillity.setBitState(securityConfig[currentValue.toLowerCase()],accumulator)},0)
    }else{
        return userLevel
    }
},
getAccessListByUserLevel:getAccessListByUserLevel,
authorise:(level,userLevel)=>{
    level=level.toLowerCase()
    if(level in securityConfig){
        //console.log(level)
        //console.log(securityConfig[level])
        //console.log(userLevel)
        return BitwiseOperationUtillity.getBitState(securityConfig[level],userLevel)
    }else{
        return false
    }
},
getLabels:(userLevel)=>{
    return getAccessListByUserLevel(userLevel).map(x=>{return accessOp.find(y=>y.value==x).label})
},
getLabel:(permission)=>{
    accessOp.find(y=>y.value==permission).label
}
/*
checkAccess:(levels,user_level)=>{
    if( levels && levels.length>0){

        for(let level of levels){
            if(!this.authorise(level,user_level)){
                return false
            }
        }
    }
        return true
    
}
*/
}
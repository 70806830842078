<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="consolidatedViewForm" v-model="valid">
          <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" class="ma-2 pa-2">
            <v-card-title class="headline">Choose views to consolidate</v-card-title>
            <v-divider></v-divider>
                <v-row class="mt-2" no-gutters>
                  <v-col>
                    <v-autocomplete
                      v-if="!loading"
                      v-model="viewData"
                      :items="entureViewOptions"
                      :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                      outlined
                      dense
                      :label="$store.getters.getTextMap().select_views"
                      item-text="label"
                      item-value="value"
                      multiple    
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-btn class="mx-2" small outlined :color="$store.getters.getColorPalette().accentCode" text  @click="addViews">{{ $store.getters.getTextMap().add }}
                      <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="d-flex">
                      <v-switch
                          small
                          inset
                          style="transform: scale(0.8);"
                          color="success"
                          v-model="showFilter"
                          :label="$store.getters.getTextMap().show_filter"
                      ></v-switch>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat class="ma-2">
                      <v-simple-table height="200px" :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}"  v-if="  data && data.views &&  data.views.length>0">
                        <template v-slot:default >
                          <thead>
                            <tr>
                              <th class="text-left">
                                <strong>{{$store.getters.getTextMap().label}}</strong>
                              </th>
                              <th class="text-left">
                                <strong>{{$store.getters.getTextMap().view_id}}</strong>
                              </th>
                              <th class="text-left">
                                <strong>{{$store.getters.getTextMap().move_up}}</strong>
                              </th>
                              <th class="text-left">
                                <strong>{{$store.getters.getTextMap().move_down}}</strong>
                              </th>
                              <th class="text-left">
                                <strong>{{$store.getters.getTextMap().action}}</strong>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr :key="item.view_id" v-for="(item, index) in data.views">
                                <td>{{ item.name}}</td> 
                                <td>{{ item.view_id}}</td> 
                                <td><v-icon small color="blue" @click="moveRowUp(index)">mdi-arrow-up-thin</v-icon></td>
                                <td><v-icon small color="blue" @click="moveRowDown(index)">mdi-arrow-down-thin</v-icon></td>
                                <td><v-icon small color="red" @click="deleteViewFromTable(item)">mdi-delete</v-icon></td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>   
                    </v-col>
                  </v-row>
                <v-card-actions>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col align="right">
                      <v-btn class="mx-1" small outlined :color="$store.getters.getColorPalette().accentCode" text   @click="submitView">{{$store.getters.getTextMap().submit}}</v-btn>
                      <v-btn small outlined :color="$store.getters.getColorPalette().deletebtnColor" text   @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
   props:['view','seq'],
    data() {
      return {
        dialog:false,
        valid: true,
        form:{},
        data: {views: [] },
        viewData:[],
        loading:false,
        showFilter:true
      };
    },
    mounted(){
      this.init()
    },
    computed:{
      entureViewOptions(){
        let op=[]
        let g=this.$store.state.views
        for(let i of g){
          if (i.view_type !== "consolidatedView"){
            op.push( {value:{view_id:i.view_id,name:i.name,view_type:i.view_type,data:i.data}, label: i.name })
          }
        }
        return op
      },
    },
    methods: {
      init(){
        if(this.view && this.view.data && this.view.data.length>0){
          this.data.views=this.view.data
          this.viewData=this.view.data
          this.showFilter=this.view.meta && this.view.meta.showFilter?this.view.meta.showFilter:false
        }
      },
      submitView() {
        this.$refs.consolidatedViewForm.validate()
        if(this.valid){
          let meta={}
          meta['showFilter']=this.showFilter
          let payload={
            view_id: this.view.view_id,
            data:this.data.views,
            meta
          }
          axios.post(this.$store.state.api+'updateView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
          .then(response=>{
            if(response.data.status=='success'){
              this.loading=false
              this.dialog=false
              this.$store.dispatch('refreshViews')
              this.$refs.consolidatedViewForm.reset()
              this.$emit('value',this.form)
              this.$emit('close')
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
          }).catch(err=>{
              this.loading=false
              this.info=err
              this.showDismissibleAlert=true
          })
        }
      },
      addViews(){
        if(!this.data.views){
          this.data.views = []
        }
        for (const i of this.viewData) {
          const viewList = this.data.views.some((view) => view.view_id === i.view_id);
          if (!viewList) {
            this.data.views.push({
            name: i.name,
            view_id: i.view_id,
            view_type: i.view_type,
            data:i.data
            });
          }
        }
      },
      deleteViewFromTable(item){
        this.data.views=this.data.views.filter(x=>x.view_id!=item.view_id)
        this.data.views = [...this.data.views];
      },
      moveRowUp(index) {
        if (index > 0) {
          const temp = this.data.views[index];
          this.data.views.splice(index, 1);
          this.data.views.splice(index - 1, 0, temp);
        }
      },
     moveRowDown(index) {
      if (index < this.data.views.length - 1) {
        const temp = this.data.views[index];
        this.data.views.splice(index, 1);
        this.data.views.splice(index + 1, 0, temp);
      }
    },
    onReset(){
      this.dialog=false,
      this.$emit('close')
    }
  },
}
</script>
  
  
<template>
    <span>
        <v-menu :max-width="300"  bottom left>
            <template v-slot:activator="{ on, attrs }">

                <v-btn v-if="$store.state.user && $store.state.user.name" icon small :color="$store.getters.getColorPalette().accentCode" v-bind="attrs" v-on="on">
                    <v-avatar size="22" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().accentCode}" :class="$store.getters.getColorPalette().avatarColorName " small>
                        {{ $store.state.user.name.charAt(0) }}</v-avatar>
                    </v-btn>
                <v-btn v-else icon small :color="$store.getters.getColorPalette().accentCode" v-bind="attrs" v-on="on"><v-icon :color="$store.getters.getColorPalette().topBarAccent">mdi-dots-horizontal</v-icon></v-btn>
            </template>

            
            <v-toolbar style="width:300px;" dense flat :dark="$store.getters.getColorPalette().isDarkTheme" :color="$store.getters.getColorPalette().background2ColorCode">
                <!-- <span :style="{color:$store.getters.getColorPalette().accentCode}"><v-avatar :size="18" :dark="$store.getters.getColorPalette().isDarkTheme" :color="$store.getters.getColorPalette().backgroundColor"><v-icon small>mdi-account</v-icon></v-avatar>{{ $store.state.user?$store.state.user.name:'' }}</span> -->
                <!-- <v-avatar :size="20" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="$store.getters.getColorPalette().backgroundColorName +' mx-1 px-0'"><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-account</v-icon></v-avatar><span :style="{color:$store.getters.getColorPalette().accentCode}"><h3>Welcome {{ $store.state.user?$store.state.user.name:'' }}</h3></span> -->
                <span :style="{color:$store.getters.getColorPalette().accentCode}"><h3>{{$store.getters.getTextMap().welcome}} {{ $store.state.user?$store.state.user.name:'' }}</h3></span>
            </v-toolbar>
            <v-list :color="$store.getters.getColorPalette().backgroundColorCode"
               :dark="$store.getters.getColorPalette().isDark">
                <!-- <v-list-item @click="toggleUser">
                    <v-spacer></v-spacer>Turn {{$store.state.settingMode?'Off':'On'}} Setting View  <v-icon class="pl-1 pb-1" :color="$store.state.settingMode?'red':'teal'" small :dark="$store.getters.getColorPalette().isDark">
                        mdi-{{$store.state.settingMode?'pencil-off':'pencil'}}</v-icon>
                </v-list-item> -->
                <span><v-subheader>{{$store.getters.getTextMap().accounts}}</v-subheader></span>
                <v-list-item v-for="item in userMenuLinks" :key="item.link" router :to="item.link" :active-class="$store.getters.getColorPalette().foregroundColorName " >
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item v-if="$store.getters.getAccessByLevels(['betaaccess'])"  @click="dialog=!dialog">
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-lock-reset</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">Update Password</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item link target="_blank" rel="noopener noreferrer" :href="'https://help.nebeskie.com'">
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode" small :dark="$store.getters.getColorPalette().isDark">mdi-help</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$store.getters.getTextMap().help}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <span><v-subheader>{{$store.getters.getTextMap().organisation}}</v-subheader></span>
                <v-list-item v-for="item in orgMenuLinks" :key="item.link" router :to="item.link" :active-class="$store.getters.getColorPalette().foregroundColorName " >
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item   @click="logout">
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode">mdi-exit-to-app</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().logout}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-menu>
        <v-row>
          <v-col>
            <v-dialog v-model="dialog" max-width="600">
                <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <ChangePassword v-on:close="onClose" />
                </v-card>
            </v-dialog>
          </v-col>
        </v-row>
    </span>
</template>

<script>
//import ToggleUserView from '@/components/ToggleUserView'
import ChangePassword from '@/components/account_components/ChangePassword'
export default {
 name: 'MenuList',
 data () {
      return {
        dialog: false
      }
    },
 components: {
    //ToggleUserView,
    // InfoComponent 
    ChangePassword
  },
 methods: {
    logout(){
      this.$store.dispatch('logOut')
      this.$router.push('/');
    },
    toggleUser(){
            this.loading=true
            //this.$store.commit("toggleEngineeringAccess")
            this.$store.commit("toggleSettingMode")
            this.loading=false
        },
    onClose(){
            this.dialog=false
        },
        getFirstLetterAfterSpace(name) {
      const firstSpaceIndex = name.indexOf(' ');
      if (firstSpaceIndex !== -1 && firstSpaceIndex < name.length - 1) {
        return name.charAt(firstSpaceIndex + 1);
      }
      return '';
    }

},
computed:{
    userMenuLinks(){
      let op=[]
      if (this.$store.state.loggedIn){
        // for(let i of this.$store.getters.getUserMenuLinks()){
        //   if(this.$store.getters.getAccessByLevels(i.levels)){
        //     op.push(i)
        //   }
        // }
        return this.$store.getters.getUserMenuLinks()
      }

        return op
      
    },
    orgMenuLinks(){
      let op=[]
      if (this.$store.state.loggedIn){
        // for(let i of this.$store.getters.getOrganisationMenuLinks()){
        //   if(this.$store.getters.getAccessByLevels(i.levels)){
        //     op.push(i)
        //   }
        // }
        // console.log(this.$store.getters.getOrganisationMenuLinks())
        return this.$store.getters.getOrganisationMenuLinks()
      }

        return op
      
    },
    configuartionMenuLinks(){
      let op=[]
      if (this.$store.state.loggedIn){
        // for(let i of this.$store.getters.configurationMenuLinks()){
        //   if(this.$store.getters.getAccessByLevels(i.levels)){
        //     op.push(i)
        //   }
        // }
        this.$store.getters.configurationMenuLinks()
      }

        return op
      
    },
   
  },
}
</script>
<style>
.settings{
    border-color: black;
}
</style>
<template>
       <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">  
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
        <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-list-item @click="update=true">
              <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="deleteWidget">
              <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
              <v-list-item-icon>
                  
                <v-icon color="red" small>mdi-trash-can</v-icon>
                 
              </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{widget.label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <AddTableWidget :widget="widget" :disableTimeframe="disableTimeframe"  v-on:close="update=false" v-on:success="$emit('update')" /> 
        </v-card>
      </v-dialog>
      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'175px',}:{}]" >
          <v-card-title class="ma-0 pa-1">

            {{ widget && widget.label?widget.label:'' }}
          </v-card-title>
          
              <v-simple-table
            :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}" 
  >
    <template v-slot:default>
      <thead>
        <tr>
        <th :class="'white--text text-center  text-subtitle-1 text-capitalize '+$store.getters.getColorPalette().foregroundColorName"></th>
            <th v-for="(item,index) in widget.meta.data.headers" :key="index"
            :class="$store.getters.getColorPalette().foregroundColorName" :color="$store.getters.getColorPalette().accentCode">
                <strong>{{item}}</strong>
                </th>


           <th :class="'white--text text-center  text-subtitle-1 text-capitalize '+$store.getters.getColorPalette().foregroundColorName">
          <strong> </strong>
           </th>
        </tr>
      </thead>
      <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <tr  v-for="(item,ind) in widget.meta.data.params"    :key="ind"   :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"
       >
       
          <td>{{item.name}}</td>
          <td v-for="param in item.param"  :key="param" >
            <span v-if="(widget.meta && widget.meta.time_frame)!='rtd'">
              {{columnData[param]}}
            </span>
            <span v-else>{{ isNaN(streamData[param]) ? '-' : streamData[param] }}</span>

          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
     
        </div>

        <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
          <v-icon small>mdi-hail</v-icon>
          <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>
<script>
import axios from 'axios'
import {io} from 'socket.io-client'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import AddTableWidget from '@/components/crud_components/widgets/AddTableWidget'
export default {
    name:'TableViewPage',
      props:['widget','edit','disableTimeframe','timeframe'],
    components:{
      
       
        InfoAlert,

        AddTableWidget
    },
 mounted(){
  this.stream.on("connect_error", (err) => {
            console.log(err.message); // prints the message associated with the error
        });
            this.stream.on('error',data=>{
            console.error(data)
        })
        this.stream.on('param',data=>{
            //console.log(typeof data)
            if(data.constructor==String){
            data=JSON.parse(data)
        }
        if(!('param_id' in data)){
            data.param_id=data.parameter
        }
        //console.log(typeof data)
        let temp=Object.assign({},this.streamData)
        temp[data.param_id]=Math.round(Number(data.data)*1000)/1000
        this.streamData=temp
        //console.log(temp)
        })
        

    if(this.widget && this.widget.meta && this.widget.meta.data ){
           this.getData()
    }

 },
 created(){
        //this.socket=io('https://localhost:80');
        this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
    },
    beforeDestroy() {
        //this.socket.close()
        //console.log('before destroy')
        this.stream.close()
    },
    data(){
        return {
            update:false,
            showMenu:false,
             x:0,
             y:0,
            showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            stream:null,
            streamData:{},
           // edit:false,
            cache:{},
            form:{

            },
            columnData:{},
        }
    },

    methods:{
              show (e) {
                e.preventDefault()
                  this.showMenu = false
                  this.x = e.clientX
                  this.y = e.clientY
                  if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
          },
          configureParamStream(){
            for(let i of Object.keys(this.streamData)){
                this.stream.emit('removeParam',i)
            }
            let params=new Set()
            for(let i of this.widget.meta.data.params){
                for(let p of i.param){
                    params.add(p)
                }
            }
            for (let s of params){
                this.stream.emit('getParam',s)
            }
        },

          getData(){
             let from_date;
             if(this.timeframe && this.disableTimeframe){
          switch (this.timeframe) {
                
                case '7':
                  from_date = moment().subtract(7,'days').format('YYYYMMDD');
                  break;
                  
                case '30':
                  from_date = moment().subtract(30,'days').format('YYYYMMDD');
                  break;
                  
                  case '60':
                    from_date = moment().subtract(60,'days').format('YYYYMMDD');
                    break;
                    
                    case '90':
                      from_date = moment().subtract(90,'days').format('YYYYMMDD');
                      break;
                      
                      case '180':
                        from_date = moment().subtract(180,'days').format('YYYYMMDD');
                        break;
                        
                        case '365':
                          from_date = moment().subtract(365,'days').format('YYYYMMDD');
                      break;
              
                    case 'yesterday':
                    from_date = moment().subtract(1,'days').format('YYYYMMDD');
                      break;
                    case 'ytd':
                      from_date = moment().startOf('year').format('YYYYMMDD');
                      break;

                    default:
                      from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                      break;
                  }
        }else{
          if(this.widget.meta && this.widget.meta.time_frame &&  this.widget.meta.time_frame=='rtd'){
            this.configureParamStream() 
            return
          }

          switch(this.widget.meta && this.widget.meta.time_frame){
             case '7days': 
                   from_date=moment().subtract(7,'days').format('YYYYMMDD');
                    break;
             case '30days': 
                   from_date=moment().subtract(30,'days').format('YYYYMMDD');
                    break;
             case '60days':
                     from_date= moment().subtract(60,'days').format('YYYYMMDD');
                      break;
             case '180days':
                     from_date= moment().subtract(180,'days').format('YYYYMMDD');
                     break;
             case 'mtd':
                      from_date= (moment().subtract(1,'day').startOf('month')).format('YYYYMMDD');
                      break;
             case 'ytd':       
                         from_date= (moment().subtract(1,'day').startOf('year')).format('YYYYMMDD')
                         break;
            default:
                      from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                      break;
                
        }
        }
              let to_date=moment().format('YYYYMMDD')       
                  if(this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.data.params){
                         //console.log(this.widget.meta.data,"meta")
                         let paramList=[]
                        //  console.log(this.widget.meta.data.params)
                         for(let p of this.widget.meta.data.params){
                            paramList=[...paramList,...p['param']]

                         }
                        //  console.log(paramList,"paramList")
                         this.columnData={}
                        
                         for(let i of paramList){
                            let payload={
                            // data:this.tableData,
                             parameters:[i],
                             from_date:from_date,
                              to_date:to_date,
                      }
                      let param=this.$store.getters.getParamById(i)
                      //console.log(payload,"payload")
                      //console.log(this.widget.meta)
                      axios.post(this.$store.state.api+ 'getTotalParamUsage',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                          let temp=Object.assign({},this.columnData)
                          if(param){
                            if(param['processing']=='difference'){
                            temp[i] = Math.round(Number(response.data.data['usage'])*100)/100
                          }else{
                              temp[i] = Math.round(Number(response.data.data['avg'])*100)/100
                            }
                          }else{
                            temp[i]='--'
                          }
                          
                            //console.log(this.columnData[i],"col")
                            this.loading=false
                            // this.edit=false
                            this.columnData=temp
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true     
                        console.log('here')
                    })

                         }    
    }
    },  
       deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }

    },
    watch:{
        widget:{
            handler(){
                this.getData()
            },
            deep:true
        },
        timeframe:{
              handler(){
                    this.getData()
                },
                deep:true
            },
    }
}
</script>
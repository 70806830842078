<template>
  <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">  
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
      <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-list-item @click="update=true">
            <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteWidget">
            <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
            <v-list-item-icon>
                    
              <v-icon color="red" small>mdi-trash-can</v-icon>

        
            </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    
    <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
          <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{widget.label}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :dark="$store.getters.getColorPalette().isDark"  text @click="update = false" >{{$store.getters.getTextMap().close}}
</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <AddBarTrendTDWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
      </v-card>
    </v-dialog>

    
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
      <v-row v-if="widget.meta.data && showGraphType" no-gutters>
            <v-col  align="right">
                <v-btn-toggle
             :dark="$store.getters.getColorPalette().isDark"
                v-model="graphType"
                mandatory
        >
          <v-btn small>
            {{$store.getters.getTextMap().bar}}

          </v-btn>
          <v-btn small>
            {{$store.getters.getTextMap().line}}

          </v-btn>
              </v-btn-toggle>
            </v-col>
</v-row>
      <div v-if="widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'148px',}:{}]" >
          
      </div>
     
      <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
        <v-icon small>mdi-hail</v-icon>
        <h5>{{$store.getters.getTextMap().not_configured}}</h5>
      </div>
     
  </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddBarTrendTDWidget from '@/components/crud_components/widgets/AddBarTrendTDWidget'
// import ColorUtility from '@/utillities/ColorUtility'

import axios from 'axios'
export default {
name:"TrendBarWidget",
components:{
      AddBarTrendTDWidget,
      InfoAlert,
      // DeleteConfirmation
  },
props:['widget','edit'],
data(){
      return {
         showDialog:false,
        //  colorCode:ColorUtility.colorTypesCodeArray,
          x:0,
          y:0,
          showMenu:false,
          loading:false,
          info:'',
          showDismissibleAlert:false,
          update:false,
          graphDataTemp:[],
          tempData:{},
          tempDataFinale:[],
          graphData:{},
          graphType:'bar',
           showGraphType:{
            type:Boolean,
            default:true
        },
          layout : {
                  //height: 300,
                  //width: 500,
                  // coloraxis:{
                  //   colorbar:{
                  //     bordercolor:'#FFF',
                  //     outlinecolor:'#FFF',
                  //     tickcolor:'#FFF'
                  //   }
                  // },
                  barmode: 'group',
                  font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                  plot_bgcolor:this.widget.background_color?this.widget.background_color:this.$store.getters.getColorPalette().background2ColorCode,
                  paper_bgcolor:this.widget.background_color?this.widget.background_color:this.$store.getters.getColorPalette().background2ColorCode,
                  showlegend:true,
                  autosize: true,
                  xaxis: {
                   
                      tickangle: -12,
                      zeroline: false,
                      showgrid: true,
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },       
                    yaxis: {
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },
                    uniformtext: {
                      "mode": "hide",
                      "minsize": 1
                    }
                  }
      }
  },
  mounted(){
 this.getData()

  },
  methods:{
      show (e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
    },
    init(){
            if(this.widget && this.widget.meta && this.widget.meta.data){
         let type=this.graphType==1?'line':'bar'
        this.graphData={}
          for(let i of this.widget.meta.data ){
            this.graphData[i.label]={
              x: [],
              y: [],
              name:i.label,
              type: type,
              //width:0.1,
              opacity:0.9,
              marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
            }
          }

       
      }
    },
      createChart(){
        this.init()
          this.layout['title']= this.widget.label
          //let textInfo='label+percent'

          if(this.widget.meta && this.widget.meta.yaxis_label){
          this.layout.yaxis['title'] = this.widget.meta.yaxis_label
          }

          if(this.widget.meta && this.widget.meta.legend){
            this.layout['showlegend']=true
            //textInfo='percent'
          }
          // this.layout={barmode: 'group'}
          // let data = [];
          for(let i of this.tempDataFinale){
            //console.log(i,'i')
            if(!(i.label in this.graphData)){
            //   console.log('Upcateing label',i.label)
              let type=this.graphType==1?'line':'bar'
              this.graphData[i.label]={
              x: [],
              y: [],
              name:i.label,
              type: type,
              //width:0.9,
              opacity:0.9,
              marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
            }
            }
            //console.log(moment(i.date,'YYYYMMDD'))
            this.graphData[i.label]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
            //this.graphData[i.label]['x'].push(i.date)
            this.graphData[i.label]['y'].push(i.usage)
          }
          Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
      },
      getData(){
        if(this.widget.meta.graphType){
            this.graphType=this.widget.meta.graphType
        }
          if(this.widget.height==='fixed'){
            this.layout.height=160
            // this.layout.width=this.widget.width*97
            this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
            this.layout.margin={t:40,b:40,l:40,r:40}
          }
          this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
          this.graphData={}
          let from_date=moment().subtract(1,'days')
          
          if(this.widget.meta && this.widget.meta.time_frame=='ytd'){
              from_date=from_date.startOf('year').format('YYYYMMDD')
          }else{
              from_date=from_date.startOf('month').format('YYYYMMDD')
          }
          let to_date=moment().format('YYYYMMDD')
          
          
          if(this.widget && this.widget.meta && this.widget.meta.data){
              this.tempDataFinale=[]
              
              
                
                
                this.tempData={}
                let payload={
                          parameters:[],
                          from_date:from_date,
                          to_date:to_date
                      }
                      let labelMap={}
                for(let i of this.widget.meta.data){
                      payload.parameters.push(i.param)
                      labelMap[i.param]=i.label
                }
                  axios.post(this.$store.state.api+'getParamGroupedUsageDataByTimeframe',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                    // console.log(response.data)
                  if(response.data.status==='success'){
                  
                      this.tempDataFinale=response.data.data
                      this.tempDataFinale.map(x=>{
                        x.label=labelMap[x.param_id]?labelMap[x.param_id]:'Label Not defined'
                      })
                      this.createChart()
                  }else{
                      this.info=response.data.msg
                      this.showDismissibleAlert=true
                  }
                      this.loading=false
                  })
                  .catch(err=>{
                      console.error(err)
                      this.loading=false});
                }
                
          
      
          
        
        
      },
      deleteWidget(){
          this.loading=true
          axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
              Authorization: 'Bearer '+ this.$store.state.jwt
              }}).then(response=>{
              if(response.data.status==='success'){
                  
                  //this.onReset()
                  this.dialog=false
                      this.loading=false
                      //this.dialog=false
                      this.$emit('update')
              }
              })
              .catch(err=>{
                  console.error(err)
              this.loading=false});
      },
      
  },
  watch:{
      widget:{
          handler(){
              this.getData()
          },
          deep:true
      },
       graphType: function (){
            // this.init()
            this.createChart()
        }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div ref="vis"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'WeeklyConsumptionComparison',
  props: ['param', 'tariff', 'time'],

  mounted() {
    this.fetchWeeklyData();
  },

  methods: {
    fetchWeeklyData() {
      const thisMonthDataPromise = this.fetchDataForWeeks(moment().format('YYYYMMDD'), 4);
      const lastMonthDataPromise = this.fetchDataForWeeks(moment().subtract(1, 'month').format('YYYYMMDD'), 4);

      Promise.all([thisMonthDataPromise, lastMonthDataPromise]).then(([thisMonthData, lastMonthData]) => {
        this.createChart(thisMonthData, lastMonthData);
      });
    },

    fetchDataForWeeks(date, weeks) {
      const data = [];
      const promises = [];

      for (let i = 0; i < weeks; i++) {
        const from_date = moment(date).subtract(i, 'weeks').startOf('isoweek').format('YYYYMMDD');
        const to_date = moment(date).subtract(i, 'weeks').endOf('isoweek').format('YYYYMMDD');

        const payload = {
          parameters: [this.param],
          from_date: from_date,
          to_date: to_date,
        };

        const promise = axios.post(this.$store.state.api + 'getTotalParamUsage', payload, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt,
          },
        }).then(response => {
          if (response.data.status === 'success') {
            data.push(Number(response.data.data['usage']));
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            data.push(0);
          }
        }).catch(err => {
          console.error(err);
          data.push(0);
        });

        promises.push(promise);
      }

      return Promise.all(promises).then(() => data);
    },

    createChart(thisMonthData, lastMonthData) {
      const data = [
        {
          type: 'bar',
          x: thisMonthData,
          y: ['Week 4', 'Week 3', 'Week 2', 'Week 1'],
          orientation: 'h',
          name: 'This Month',
          legendgroup: 'group1', // Add legendgroup for the first trace
              marker: {
        color: '#64FFDA', // teal accent-1
      },
        },
        {
          type: 'bar',
          x: lastMonthData,
          y: ['Week 4', 'Week 3', 'Week 2', 'Week 1'],
          orientation: 'h',
          name: 'Last Month',
          legendgroup: 'group2', // Add legendgroup for the second trace
                   marker: {
        color: '#B388FF', //deep purple accent-1
      },
        },
      ];

      const layout = {
        barmode: 'group',
        title: '',
        xaxis: {
              title: 'Kwh',
              tickangle: -12,
              zeroline: false,
              showgrid: true,
              showticklabels: true,
              color: this.$store.getters.getColorPalette().colorTypesCodeArray,
              
        },
        yaxis: {
              title: '',
              showticklabels: true,
              color: this.$store.getters.getColorPalette().colorTypesCodeArray,
        },
        legend: {
          x: 0.8,
          y: 1.0,
          orientation: 'h',
        },
        height: 300, // Set the height of the chart
        // width: 400, 
        margin: {
          t: 25,  // Top margin
          b: 40,  // Bottom margin
          // Left margin
          // Right margin
        },
        font:{
         color:this.$store.getters.getColorPalette().accentCode,
        },
                 
                plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',

                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,//this.$store.getters.getColorPalette().background2ColorCode,
      };

      Plotly.newPlot(this.$refs.vis, data, layout, { displaylogo: false });
    },
  },
};
</script>

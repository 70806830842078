<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().background2ColorCode}" >  
        <div ref="vis"></div>
    </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'

export default {
    name:'SingleParamPeakDemand',
    props:['param','label'],
    components:{
    },
    data(){
        return {
            graphData:{},
            week:[],
            usage:[],
            z_axis_data:null,
            week_x_axis:[],
            objectRules:[
                v => !!v || 'Required',
            ],
            layout : {
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -60,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
        }
    },
    mounted(){
        if(this.param && this.label){
            // console.log(this.param)
            this.getData()
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      createPowerPeakGraph(){
            // console.log(this.week_x_axis)
            this.layout['title']= this.label
            let data = [{
            y:this.week,
            x: this.week_x_axis,
            type: 'scatter',
            mode:'line',
            fill:'tozeroy',
            fillcolor: this.$store.getters.getColorPalette().colorTypesCodeArrayHeatMap,
            colorscale:this.$store.getters.getColorPalette().colorTypesCodeArrayHeatMap,
            // colorscale:colorscaleCodeHeatMap,
            text: this.week,
            textfont:{
                color:'#FFF'
            }
            }];
            Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
        },
        async getData(){
        //   if(this.widget.height==='fixed'){
        //   }
        this.layout.height=170
        // this.layout.width=600
        this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
        this.layout.margin={
                t:30,
                b:70,
                l:40,
                r:40
        }
        this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
        let from_date=moment().subtract(1,'days')
        from_date=from_date.startOf('month').format('YYYYMMDD')
        let to_date = moment().subtract(1,'days').format('YYYYMMDD')
        this.usage = []
        let payload={
                parameter:this.param,
                from_date:from_date,
                to_date:to_date,
                }
        try{
            let temp_response= await axios.post(this.$store.state.api+'getParameterUsageDataBetweenDate',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            if(temp_response.data.status==='success'){
                for(let i of temp_response.data.data){
                    this.usage.push({'date':i.date,'max':i.max})
                    
                }
            }else{
                this.info=temp_response.data.msg
                this.showDismissibleAlert=true
            }
            }catch(err){
                console.error(err)
            }  
        this.week_x_axis = []
        this.week= []
        
        for(let i of this.usage){
            this.week_x_axis.push(moment(i['date'],'YYYYMMDD').format("YYYY-MM-DD"))
            this.week.push(i['max'])
        }
            this.createPowerPeakGraph()
        },
    },
    watch:{
        widget:{
            handler(){
                this.getData()
            },
            deep:true
        },
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
</style>
<template>
    <div class="text-center">
        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="dialog = !dialog">
            {{$store.getters.getTextMap().add_logged_parameter}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-code-array </v-icon>
        </v-btn>
        <v-bottom-sheet v-model="dialog">
            <v-sheet class="text-center" >
                <CreateLoggedParameter :machine="machine" v-on:close="dialog=false"/>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
<script>
  import CreateLoggedParameter from '@/components/CreateLoggedParameter'
  export default {
      name:'CreateLoggedParameterBottomSheet',
      props:['machine'],
      components:{
        CreateLoggedParameter  
      },
      data(){
          return {
              dialog:false
          }
      },
      methods:{
          
      }
  }
  </script>
  
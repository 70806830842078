<template>
    <span>
        <v-menu :max-width="500" left>
            <template v-slot:activator="{ on, attrs }">
                    <v-badge :content="notificationCount" :dark="$store.getters.getColorPalette().isDark" :color="notificationCount?'red':''" overlap>
                        <v-btn icon small :color="$store.getters.getColorPalette().accentCode" v-bind="attrs" v-on="on" @click="markRead"><v-icon small>mdi-bell-outline</v-icon></v-btn>
                    </v-badge>
                </template>
                <v-toolbar dense flat :dark="$store.getters.getColorPalette().isDarkTheme" :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-row style="margin-top:10px;">
                        <v-col>
                        <span :style="{color:$store.getters.getColorPalette().accentCode}"><h3>{{$store.getters.getTextMap().notifications}}
</h3></span>
                    </v-col>
                    
                    <v-col align="right">
                        <v-icon v-if="$store.state.loggedIn && $store.state.user &&  $store.getters.getAccessByLevels(['betaaccess'])" small :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDarkTheme">mdi-open-in-new</v-icon>
                    </v-col>
                </v-row>
            </v-toolbar>
            
                <v-list :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <span><v-subheader>{{$store.getters.getTextMap().latest}}- {{ notificationCount }}{{$store.getters.getTextMap().unread}}
</v-subheader></span>
                    <v-divider></v-divider>
                    <div class="listContent">
                    <v-list-item :key="index" v-for="(item,index) in notifications">
                        <v-list-item-content>
                        <v-row>
                            
                            <v-col align-self="center">
                                <h5>{{item['type']}}:{{item?item['name']:''}}. {{$store.getters.getParamById(item.id)?$store.getters.getParamById(item.id)['name']:''}} {{$store.getters.getTextMap().value_is}}
 {{item.value}}. {{$store.getters.getTextMap().please_take_necessary_action}}
.</h5>
                                <span class="text-caption">{{ displayTime(item['timestamp']) }}</span>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </v-list-item-content>
                </v-list-item>
            </div>
            </v-list>
        </v-menu>
    </span>
</template>
<script>
import moment from 'moment'
export default {
    name: 'NotificationList',
    data() {
    return {
      toggleValueWithLabels: false,
      dummyList:[1,2,3,4],


    };
  },
  computed:{
    notifications(){
        return this.$store.state.notifications
    },
    notificationCount(){
        return this.notifications.length
    }
  },
  methods:{
    markRead(){
        console.log('mark read')
    },
    displayTime(timestamp){
        if(timestamp){
            return moment(timestamp)
        }
        return null
    }
  }
}
</script>
<style scoped>
.listContent {
    overflow-y: scroll;
    max-height: 400px;
}

</style>
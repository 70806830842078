import English from "@/utillities/languages/English";

export default {
    ...English,
    "accept": "ಸ್ವೀಕರಿಸಿ",
"reject": "ನಿರಾಕರಿಸಿ",
"move_to_next_stage": "ಮುಂದಿನ ಹಂತಕ್ಕೆ ಹೋಗಿ",
"save_as_draft": "ಕರಡು ರೂಪದಲ್ಲಿ ಉಳಿಸಿ",
"save_and_submit": "ಉಳಿಸಿ ಮತ್ತು ಸಲ್ಲಿಸಿ",
"closure": "ಮುಕ್ತಾಯ",
"duration": "ಾವಧಿ",
"refill_request": "ಹೆಚ್ಚುವರಿ ವಿನಂತಿ",
"is_be_cancelled": "ರದ್ದು ಮಾಡಲಾಗುವುದು",
"delete_this": "ಈದುಡಿಸು",
"event": "ಘಟನೆ",

    "for": "ಕಾಗೆ",
    "of": "ಆದ",
    "downtime": "ಡೌನ್‌ಟೈಮ್",

    "metrics": "ಮೆಟ್ರಿಕ್ಸ್",
    "oee_by_day": "ಒಂದು ದಿನಕ್ಕೆ OEE",

    "ideal_cycle_time": "ಆದರ್ಶ ಚಕ್ರದ ಸಮಯ",
    
    "minutes": "ನಿಮಿಷಗಳು",
    "production_time": "ಉತ್ಪಾದನೆ ಸಮಯ",
    "back": "ಹಿಂದೆ",
    "down_times": "ಡೌನ್ ಟೈಮ್ಸ್",

    "top_5_reasons": "ಟಾಪ್ 5 ಕಾರಣಗಳು",
    "top_5_categories": "ಟಾಪ್ 5 ವರ್ಗಗಳು",
    "enter_apparent_energy_manually": "ಸ್ಪಷ್ಟವಾದ ಶಕ್ತಿಯನ್ನು ಕೈಯಿಂದ ನಮೂದಿಸಿ",

    "oee": "OEE",
    "reason": "ಕಾರಣ",
    "add_downtime_reason": "ಡೌntimeನ ಕಾರಣವನ್ನು ಸೇರಿಸಿ",

    "default_target": "ಡಿಫಾಲ್ಟ್ ಗುರಿ",

    "enter_in_minutes": "ನಿಮಿಷಗಳಲ್ಲಿ ನಮೂದಿಸಿ",
    "custome_fields": "ಕಸ್ಟಮ್ ಕ್ಷೇತ್ರಗಳು",
    "enter_data_in_minutes": "ನಿಮಿಷಗಳಲ್ಲಿ ಡೇಟಾವನ್ನು ನಮೂದಿಸಿ",
    "enter_data_in_hours": "ಗಂಟೆಗಳಲ್ಲಿ ಡೇಟಾವನ್ನು ನಮೂದಿಸಿ",
    "create_tod_billing_widget": "ಟಾಡ್ ಬಿಲ್ಲಿಂಗ್ ವಿಜೆಟ್ ರಚಿಸಿ",
    "edit_tod_billing_widget": "ಟಾಡ್ ಬಿಲ್ಲಿಂಗ್ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "choose_from_machine_groups": "ಯಂತ್ರ ಗುಂಪುಗಳಿಂದ ಆಯ್ಕೆಮಾಡಿ",
    "tod_billing": "ಟಾಡ್ ಬಿಲ್ಲಿಂಗ್",
    "choose": "ಆರಿಸು",
    "create_shift_based_machine_usage_log": "ಯಂತ್ರ ಬಳಕೆಯ ಲಾಗ್ ರಚಿಸಿ",
    "shift_based_machine_usage_log": "ಯಂತ್ರ ಬಳಕೆ ಲಾಗ್",
    "rejected": "ತಿರಸ್ಕರಿಸಿದ",
    "machine_operator": "ಯಂತ್ರ ನಿರ್ವಾಹಕ",
    "production_end_timestamp": "ಉತ್ಪಾದನಾ ಅಂತಿಮ ಸಮಯ",
    "production_start_timestamp": "ಉತ್ಪಾದನಾ ಪ್ರಾರಂಭ ಸಮಯ",
    "running_time": "ಚಾಲನೆಯಲ್ಲಿರುವ ಸಮಯ",
    "idle_time": "ಜಡ ಸಮಯ",
    "down_time": "ಕೆಳಗಡೆ",
    "date_row_index": "ದಿನಾಂಕದ ಸಾಲು",
    "date_position": "ದಿನಾಂಕದ ಸ್ಥಾನ",
    "save": "ಉಳಿಸು",
    "discard": "ತರಿಸು",
    "adjust": "ಸರಿಹೊಂದಿಸು",
    "safety_incident_sheet": "ಸುರಕ್ಷತಾ ಘಟನೆ ಹಾಳೆ",
    "safety_inspection_sheet": "ಸುರಕ್ಷತಾ ತಪಾಸಣೆ ಹಾಳೆ",
    "ghg_emission_logs": "GHG ಹೊರಸೂಸುವಿಕೆ ದಾಖಲೆಗಳು",
    "add_ghg_emission_data": "GHG ಹೊರಸೂಸುವಿಕೆ ಲಾಗ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
    "ghg_scope_conversion": "Ghg ಸ್ಕೋಪ್ ಪರಿವರ್ತನೆ",
    "create_ghg_scope_conversion": "GHG ಸ್ಕೋಪ್ ಪರಿವರ್ತನೆ ರಚಿಸಿ",
    "ghg_data_logs": "GHG ಹೊರಸೂಸುವಿಕೆ ಡೇಟಾ ಲಾಗ್‌ಗಳು",
    "choose_inspection_type_optional": "ತಪಾಸಣೆ ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ (ಐಚ್ al ಿಕ)",
    "supplier_code": "ಸರಬರಾಜುದಾರರ ಸಂಹಿತೆ",
    "contact": "ಸಂಪರ್ಕ",
    "point_of_contact": "ಸಂಪರ್ಕದ ಬಿಂದು",
    "quantity_supplied": "ಪ್ರಮಾಣವನ್ನು ಸರಬರಾಜು ಮಾಡಲಾಗಿದೆ",
    "create_product": "ಉತ್ಪನ್ನವನ್ನು ರಚಿಸಿ",
    "products": "ಉತ್ಪನ್ನಗಳು",
    "data": "ದತ್ತ",
    "timezone": "ಸಮಯ ವಲಯ",
    "tariffs": "ಸುಂಕ",
    "days_delta": "ಡೇಸ್ ಡೆಲ್ಟಾ",
    "machine_groups": "ಯಂತ್ರ ಗುಂಪುಗಳು",
    "param_groups": "ಪಾರ್ಮೀಟರ್ ಗುಂಪುಗಳು",
    "user_groups": "ಬಳಕೆದಾರರ ಗುಂಪುಗಳು",
    "topic_prefix": "ವಿಷಯದ ಪೂರ್ವಪ್ರತ್ಯಯ",
    "message_direction": "ಸಂದೇಶದ ದಿಕ್ಕು",
    "safety_inspection": "ಸುರಕ್ಷತಾ ಪರಿಶೀಲನೆ",
    "write_inspection_log": "ತಪಾಸಣೆ ಲಾಗ್ ಬರೆಯಿರಿ",
    "inspection_id": "ತಪಾಸಣಾ ಐಡಿ",
    "inspector_id": "ಇನ್ಸ್ಪೆಕ್ಟರ್ ಐಡಿ",
    "inspection_date": "ತಪಾಸಣೆ ದಿನಾಂಕ",
    "inspection_type": "ತಪಾಸಣಾ ಪ್ರಕಾರ",
    "findings": "ಆವಿಷ್ಕಾರಗಳು",
    "actions_required": "ಕ್ರಮಗಳು ಅಗತ್ಯವಿದೆ",
    "followup_date": "ದಿನಾಂಕವನ್ನು ಅನುಸರಿಸಿ",
    "status": "ಸ್ಥಾನಮಾನ",
    "safety_incident": "ಸುರಕ್ಷತಾ ಘಟನೆ",
    "write_incident": "ಘಟನೆ ಬರೆಯಿರಿ",
    "incident_description": "ಘಟನೆ ವಿವರಣೆ",
    "severity": "ತೀವ್ರತೆ",
    "accident": "ಅಪಘಾತ",
    "accident_with_loss": "ನಷ್ಟದೊಂದಿಗೆ ಅಪಘಾತ",
    "closed": "ಮುಚ್ಚಿದ",
    "corrective_action": "ಸರಿಪಡಿಸುವ ಕ್ರಿಯೆ",
    "employee_id": "ನೌಕರರ ID",
    "apply": "ಅನ್ವಯಿಸು",
    "choose_location_optional": "ಸ್ಥಳವನ್ನು ಆರಿಸಿ (ಐಚ್ al ಿಕ)",
    "locations": "ಸ್ಥಳಗಳು",
    "locations_event_types": "ಸ್ಥಳಗಳು ಈವೆಂಟ್ ಪ್ರಕಾರಗಳು",
    "create_tariff": "ಸುಂಕವನ್ನು ರಚಿಸಿ",
    "default_tariff_val": "ಡೀಫಾಲ್ಟ್ ಸುಂಕದ ಮೌಲ್ಯ",
    "add_tariff_data": "ಸುಂಕ ಡೇಟಾವನ್ನು ಸೇರಿಸಿ",
    "edit_tariff": "ಸುಂಕವನ್ನು ಸಂಪಾದಿಸಿ",
    "delete_tariff": "ಸುಂಕವನ್ನು ಅಳಿಸಿ",
    "tariff_value": "ಸುಂಕ ಮೌಲ್ಯ",
    "machine_status_timeline": "ಯಂತ್ರ ಸ್ಥಿತಿ ಟೈಮ್‌ಲೈನ್",
    "total_duration": "ಒಟ್ಟು ಅವಧಿ",
    "generate": "ಉತ್ಪಾದಿಸು",
    "remove_machine": "ಯಂತ್ರವನ್ನು ತೆಗೆದುಹಾಕಿ",
    "edit_machine_status_timeline_widget": "ಯಂತ್ರ ಸ್ಥಿತಿ ಟೈಮ್‌ಲೈನ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_machine_Status__timeline_widget": "ಯಂತ್ರ ಸ್ಥಿತಿ ಟೈಮ್‌ಲೈನ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "select_timeframe": "ಟೈಮ್‌ಫ್ರೇಮ್ ಆಯ್ಕೆಮಾಡಿ",
    "timeframe_type_shift": "ಸ್ಥಳಾಂತರ",
    "timeframe_type_custom": "ರೂ customಿ",
    "timeframe_type_yesterday": "ನಿನ್ನೆ",
    "edit_report_details": "ವರದಿ ವಿವರಗಳನ್ನು ಸಂಪಾದಿಸಿ",
    "download_current_template": "ಪ್ರಸ್ತುತ ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    "date_column_index": "ದಿನಾಂಕ ಕಾಲಮ್ (ಸೂಚ್ಯಂಕ)",
    "select_area": "ಆಯ್ದ ಪ್ರದೇಶ",
    "select_shift": "ಶಿಫ್ಟ್ ಆಯ್ಕೆಮಾಡಿ",
    "dashboard_heading": "ಬಿರಡೆದ ಹಲಗೆ",
    "production_params": "ಉತ್ಪಾದನಾ ಪ್ಯಾರಮ್‌ಗಳು",
    "no_modules_configured": "ಯಾವುದೇ ಮಾಡ್ಯೂಲ್‌ಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ",
    "dashboard_panel": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಫಲಕ",
    "choose_type": "ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ",
    "name_is_required": "ಹೆಸರು ಅಗತ್ಯವಿದೆ",
    "description_is_required": "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
    "required": "ಅಗತ್ಯ",
    "you_have_to_select_something": "ನೀವು ಏನನ್ನಾದರೂ ಆರಿಸಬೇಕು",
    "name_must_be_less_than_30_characters": "ಹೆಸರು 30 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "name_must_be_less_than_25_characters": "ಹೆಸರು 25 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "name_must_be_less_than_50_characters": "ಹೆಸರು 50 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "description_must_be_less_than_35_characters": "ವಿವರಣೆಯು 35 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "description_must_be_less_than_55_characters": "ವಿವರಣೆಯು 55 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "number_must_be_less_than_6_digits": "ಸಂಖ್ಯೆ 6 ಅಂಕೆಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "number_must_be_less_than_8_digits": "ಸಂಖ್ಯೆ 8 ಅಂಕೆಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "number_must_be_less_than_7_digits": "ಸಂಖ್ಯೆ 7 ಅಂಕೆಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "panel": "ಫಲಕ",
    "gauge": "ಮಾಪಕ",
    "param": "ವಕ್ರ",
    "machine": "ಯಂತ್ರ",
    "add_panel_to_dashboard": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗೆ ಫಲಕವನ್ನು ಸೇರಿಸಿ",
    "edit_dashboard_panel": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಫಲಕವನ್ನು ಸಂಪಾದಿಸಿ",
    "param_type": "ಪ್ರೌ param",
    "workspace": "ಕಾರ್ಯಕ್ಷೇತ್ರ",
    "device": "ಸಾಧನ",
    "name": "ಹೆಸರು",
    "language": "ಭಾಷೆ",
    "time_duration": "ಸಮಯದ ಅವಧಿ",
    "submit": "ಸಲ್ಲಿಸು",
    "cancel": "ರದ್ದುಮಾಡು",
    "close": "ಮುಚ್ಚಿಡು",
    "usage": "ಬಳಕೆ",
    "peak_value": "ಗರಿಷ್ಠ ಮೌಲ್ಯ",
    "current_data": "ಪ್ರಸ್ತುತ ಡೇಟಾ",
    "parameter": "ನಿಯತಾಂಕ",
    "computed_param": "ಕಂಪ್ಯೂಟೆಡ್ ರಾಮರಸ",
    "derived_param": "ಪಡೆದ ಪ್ಯಾರಮ್",
    "t4_days": "4 ದಿನಗಳು",
    "last_7_days": "ಕೊನೆಯ 7 ದಿನಗಳು",
    "last_30_days": "ಕೊನೆಯ 30 ದಿನಗಳು",
    "last_60_days": "ಕೊನೆಯ 60 ದಿನಗಳು",
    "last_90_days": "ಕೊನೆಯ 90 ದಿನಗಳು",
    "last_180_days": "ಕೊನೆಯ 180 ದಿನಗಳು",
    "last_365_days": "ಲ್ಯಾಟ್ 365 ದಿನಗಳು",
    "last_1_hour": "ಕೊನೆಯ 1 ಗಂಟೆ",
    "last_3_hours": "ಕೊನೆಯ 3 ಗಂಟೆಗಳು",
    "last_6_hours": "ಕೊನೆಯ 6 ಗಂಟೆಗಳು",
    "reorder_views": "ಪುನಃ ಕೋರಿಕೆ",
    "create_view": "ರಚಿಸು",
    "clear_view_name_filter": "ಫಿಲ್ಟರ್‌ಗಳನ್ನು ತೆರವುಗೊಳಿಸಿ",
    "view_type": "ವೀಕ್ಷಿಸಿ ಪ್ರಕಾರ",
    "description": "ವಿವರಣೆ",
    "table_view": "ಮೇಜು ವೀಕ್ಷಣೆ",
    "widget_view": "ವಿಜೆಟ್ ವೀಕ್ಷಣೆ",
    "dynamic_timeframed_widget_view": "ಡೈನಾಮಿಕ್ ಟೈಮ್‌ಫ್ರಾಮ್ಡ್ ವಿಜೆಟ್ ವೀಕ್ಷಣೆ",
    "diagram_view": "ರೇಖಾಚಿತ್ರ ವೀಕ್ಷಣೆ",
    "energy_usage_view": "ಶಕ್ತಿ ಬಳಕೆಯ ನೋಟ",
    "machine_scroll_view": "ಯಂತ್ರ ಸ್ಕ್ರಾಲ್ ನೋಟ",
    "param_scroll_view": "ಪ್ಯಾರಮ್ ಸ್ಕ್ರಾಲ್ ವ್ಯೂ",
    "consolidated_view": "ಏಕೀಕೃತ ನೋಟ",
    "air_quality_view": "ವಾಯು ಗುಣಮಟ್ಟ",
    "view_auto_change": "ಸ್ವಯಂ ಬದಲಾವಣೆಯನ್ನು ವೀಕ್ಷಿಸಿ",
    "no_views_configured": "ಯಾವುದೇ ವೀಕ್ಷಣೆಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ",
    "add_to_dashboard": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗೆ ಸೇರಿಸಿ",
    "edit_view_name": "ವೀಕ್ಷಣೆ ಹೆಸರನ್ನು ಸಂಪಾದಿಸಿ",
    "edit_view": "ವೀಕ್ಷಣೆ ವೀಕ್ಷಣೆ",
    "refresh": "ಬಿಚ್ಚು",
    "create_machine": "ಯಂತ್ರವನ್ನು ರಚಿಸಿ",
    "add_machine": "ಯಂತ್ರ ಸೇರಿಸಿ",
    "sequence": "ಅನುಕ್ರಮ",
    "machine_type": "ಯಂತ್ರ ಪ್ರಕಾರ",
    "physical_machine": "ಭೌತಿಕ ಯಂತ್ರ",
    "virtual_machine": "ವರ್ಚುವ ಯಂತ್ರ",
    "positive_workspace": "ಸಕಾರಾತ್ಮಕ ಕಾರ್ಯಕ್ಷೇತ್ರ",
    "positive_machine": "ಧನಾತ್ಮಕ ಯಂತ್ರ",
    "negative_workspace": "ನಕಾರಾತ್ಮಕ ಕಾರ್ಯಕ್ಷೇತ್ರ",
    "negative_machine": "ನಕಾರಾತ್ಮಕ ಯಂತ್ರ",
    "label": "ಲೇಪಿಸು",
    "positive_machine_Ids": "ಧನಾತ್ಮಕ ಯಂತ್ರ ID ಗಳು",
    "negative_machine_Ids": "ನಕಾರಾತ್ಮಕ ಯಂತ್ರ ID ಗಳು",
    "trend_analysis_tools": "ಟ್ರೆಂಡ್ ಅನಾಲಿಸಿಸ್ ಪರಿಕರಗಳು",
    "energy_consumption_by_machines": "ಯಂತ್ರಗಳಿಂದ ಶಕ್ತಿಯ ಬಳಕೆ",
    "Create_report": "ವರದಿ ರಚಿಸಿ",
    "view_report": "ವರದಿ ವೀಕ್ಷಿಸಿ",
    "tarrif_on": "ಸುಂಕ",
    "active_energy": "ಸಕ್ರಿಯ ಶಕ್ತಿ",
    "apparent_energy": "ಸ್ಪಷ್ಟ ಶಕ್ತಿ",
    "automate_report": "ವರದಿಯನ್ನು ಸ್ವಯಂಚಾಲಿತಗೊಳಿಸಿ",
    "add_root_machine": "ರೂಟ್ ಯಂತ್ರ ಸೇರಿಸಿ",
    "add_common_machine": "ಸಾಮಾನ್ಯ ಯಂತ್ರವನ್ನು ಸೇರಿಸಿ",
    "notification_groups": "ಅಧಿಸೂಚನೆ ಗುಂಪುಗಳು",
    "add_machines_to_list": "ಪಟ್ಟಿಗೆ ಯಂತ್ರಗಳನ್ನು ಸೇರಿಸಿ",
    "lists": "ಪಟ್ಟಿಗಳು",
    "machine_list": "ಯಂತ್ರ ಪಟ್ಟಿ",
    "data_historian": "ದತ್ತಾಂಶ ಇತಿಹಾಸಕಾರ",
    "timeframed_trends": "ಟೈಮ್‌ಫ್ರಾಮ್ಡ್ ಟ್ರೆಂಡ್‌ಗಳು",
    "dual_axis_trend_comparison": "ಡ್ಯುಯಲ್ ಆಕ್ಸಿಸ್ ಟ್ರೆಂಡ್ ಹೋಲಿಕೆ",
    "granular24_hrs_trend": "ಗ್ರ್ಯಾನ್ಯುಲರ್ 24 ಗಂ ಪ್ರವೃತ್ತಿ",
    "machinewise_trend": "ಯಂತ್ರ ಬುದ್ಧಿವಂತ ಪ್ರವೃತ್ತಿ",
    "basic_statistical_trends": "ಮೂಲ ಸಂಖ್ಯಾಶಾಸ್ತ್ರೀಯ ಪ್ರವೃತ್ತಿಗಳು",
    "log_analysis": "ಲಾಗ್ ವಿಶ್ಲೇಷಣೆ",
    "aqi_comparison_panel": "ಎಕ್ಯೂಐ ಹೋಲಿಕೆ ಫಲಕ",
    "time_of_day_trend": "ದಿನದ ಪ್ರವೃತ್ತಿಯ ಸಮಯ",
    "param_historical_comparisons": "ಪ್ಯಾರಮ್ ಐತಿಹಾಸಿಕ ಹೋಲಿಕೆಗಳು",
    "energy_usage_trends": "ಶಕ್ತಿ ಬಳಕೆಯ ಪ್ರವೃತ್ತಿಗಳು",
    "machine_name": "ಯಂತ್ರದ ಹೆಸರು",
    "param_name": "ಪ್ಯಾರಮ್ ಹೆಸರು",
    "trend_view": "ಪ್ರವೃತ್ತಿ ವೀಕ್ಷಣೆ",
    "raw": "ಕಚ್ಚಾ",
    "trend": "ಪ್ರವೃತ್ತಿ",
    "normalised": "ಸಾಮಾನ್ಯನಾದ",
    "from_date": "ದಿನಾಂಕದಿಂದ",
    "to_date": "ಇಲ್ಲಿಯವರೆಗೆ",
    "from": "ನಿಂದ",
    "to": "ಗಾಗಿ",
    "hour": "ಗಂಟೆ",
    "minute": "ನಿಮಿಷ",
    "generate_report": "ವರದಿಯನ್ನು ರಚಿಸಿ",
    "time_range": "ಸಮಯ ವ್ಯಾಪ್ತಿ",
    "no_data_available": "ಯಾವುದೇ ಡೇಟಾ ಲಭ್ಯವಿಲ್ಲ",
    "daily": "ದೈನಂದಿನ",
    "monthly": "ಮಾಸಿಕ",
    "weekly": "ವಾರಕ್ಕೆ",
    "week": "ವಾರ",
    "yearly": "ವಾರ್ಷಿಕ",
    "device_name": "ಸಾಧನದ ಹೆಸರು",
    "machine_wise_comparison": "ಯಂತ್ರ ಬುದ್ಧಿವಂತ ಹೋಲಿಕೆ",
    "stats_detailed_report": "ಅಂಕಿಅಂಶಗಳು ವಿವರವಾದ ವರದಿ",
    "log_type": "ಲಾಗ್ ಪ್ರಕಾರ",
    "choose_Log": "ಲಾಗ್ ಆಯ್ಕೆಮಾಡಿ",
    "area": "ಪ್ರದೇಶ",
    "aq_device": "ಎಕ್ಯೂ ಸಾಧನ",
    "nebeskie_labs_office": "ನೆಬೆಸ್ಕಿ ಲ್ಯಾಬ್ಸ್ ಕಚೇರಿ",
    "date_range": "ದಿನಾಂಕ_ರೇಂಜ್",
    "this_year": "ಈ ವರ್ಷ",
    "last 6 months": "ಕೊನೆಯ 6 ತಿಂಗಳುಗಳು",
    "last quater": "ಕಳೆದ ತ್ರೈಮಾಸಿಕ",
    "mtd": "ಎಂಟಿಡಿ",
    "ytd": "Y",
    "yesterday": "ನಿನ್ನೆ",
    "custom": "ರೂ customಿ",
    "run_report": "ವರದಿ ಮಾಡಿ",
    "create_report": "ವರದಿ ರಚಿಸಿ",
    "usage_report": "ಬಳಕೆಯ ವರದಿ",
    "consolidated_report": "ಏಕೀಕೃತ ವರದಿ",
    "dynamic_excel_report": "ಡೈನಾಮಿಕ್ ಎಕ್ಸೆಲ್ ವರದಿ",
    "energy_usage_report": "ಶಕ್ತಿ ಬಳಕೆಯ ವರದಿ",
    "create_action": "ಕ್ರಿಯೆಯನ್ನು ರಚಿಸಿ",
    "action_type": "ಕ್ರಿಯಾಶೀಲ ಪ್ರಕಾರ",
    "notification_group": "ಅಧಿಸೂಚನೆ ಗುಂಪು",
    "address": "ಭಾಷಣ",
    "number_of_registers": "ರೆಜಿಸ್ಟರ್‌ಗಳ ಸಂಖ್ಯೆ",
    "value": "ಮೌಲ್ಯ",
    "send_notification": "ಅಧಿಸೂಚನೆಯನ್ನು ಕಳುಹಿಸಿ",
    "write_parameter": "ನಿಯತಾಂಕವನ್ನು ಬರೆಯಿರಿ",
    "relays": "ಪ್ರಸಾರ",
    "relay_1": "ರಿಲೇ 1",
    "relay_2": "ರಿಲೇ 2",
    "relay_3": "ರಿಲೇ 3",
    "relay_4": "ರಿಲೇ 4",
    "email_must_be_valid": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿರಬೇಕು",
    "it_should_be_a_number": "ಅದು ಒಂದು ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು",
    "it_should_be_a_valid_number": "ಅದು ಮಾನ್ಯ ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು",
    "operator": "ಆಯೋಜಾರ",
    "operand": "ದತತ",
    "targets": "ಗುರಿಗಳು",
    "target_value": "ಗುರಿ ಮೌಲ್ಯ",
    "wait_time_seconds": "ಸಮಯ ಸಮಯ (ಸೆಕೆಂಡುಗಳು)",
    "greater_than": "ಗಿಂತ ದೊಡ್ಡ",
    "modulus_greater_than": "ಮಾಡ್ಯುಲಸ್ ಹೆಚ್ಚು",
    "lesser_than": "ಕಡಿಮೆ",
    "modulus_lesser_than": "ಮಾಡ್ಯುಲಸ್ ಕಡಿಮೆ",
    "equal_to": "ಸಮಾನ",
    "not_equal_to": "ಇದಕ್ಕೆ ಸಮನಾಗಿಲ್ಲ",
    "modulus_equal_to": "ಮಾಡ್ಯುಲಸ್ ಸಮಾನ",
    "greater_than_equal_to": "ಸಮಾನಕ್ಕಿಂತ ದೊಡ್ಡದು",
    "modulus_greater_than_equal_to": "ಮಾಡ್ಯುಲಸ್ ಸಮನಾಗಿರುವುದಕ್ಕಿಂತ ಹೆಚ್ಚಿನದು",
    "lesser_than_equal_to": "ಕಡಿಮೆ ಸಮಾನಕ್ಕಿಂತ ಕಡಿಮೆ",
    "modulus_lesser_than_equal_to": "ಮಾಡ್ಯುಲಸ್ ಕಡಿಮೆ ಸಮಾನವಾಗಿರುತ್ತದೆ",
    "bit_high": "ಬಿಟ್ ಹೈ",
    "bit_low": "ಬಿಟ್ ಕಡಿಮೆ",
    "it_should_be_a_positive_whole_number": "ಇದು ಸಕಾರಾತ್ಮಕ ಸಂಪೂರ್ಣ ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು",
    "it_should_be_a_positive": "ಇದು ಸಕಾರಾತ್ಮಕವಾಗಿರಬೇಕು",
    "create_process": "ಪ್ರಕ್ರಿಯೆಯನ್ನು ರಚಿಸಿ",
    "edit_process": "ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸಂಪಾದಿಸಿ",
    "status_rule": "ಸ್ಥಾನಮಾನ",
    "create_process_status_rule": "ಪ್ರಕ್ರಿಯೆಯ ಸ್ಥಿತಿ ನಿಯಮವನ್ನು ರಚಿಸಿ",
    "tag_type": "Tagರಿನ ಪ್ರಕಾರ",
    "process_status": "ಪ್ರಕ್ರಿಯೆಯ ಸ್ಥಿತಿ",
    "machines": "ಯಂತ್ರಗಳು",
    "running": "ಓಟ",
    "idling": "ಜಡತ್ವ",
    "offline": "ಹೊರಟ",
    "no_machines_configured": "ಯಾವುದೇ ಯಂತ್ರಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ",
    "delete_process": "ಪ್ರಕ್ರಿಯೆ ಅಳಿಸಿ",
    "add_machine_to_process": "ಪ್ರಕ್ರಿಯೆಗೆ ಯಂತ್ರವನ್ನು ಸೇರಿಸಿ",
    "delete_premise_area": "ಪ್ರಮೇಯ ಪ್ರದೇಶವನ್ನು ಅಳಿಸಿ",
    "add": "ಸೇರಿಸು",
    "add_to_list": "ಪಟ್ಟಿಗೆ ಸೇರಿಸಿ",
    "search": "ಶೋಧನೆ",
    "switch_to": "ಗೆ ಬದಲಾಯಿಸಿ",
    "switch_to_base_org": "ಬೇಸ್ ಆರ್ಗ್‌ಗೆ ಬದಲಾಯಿಸಿ",
    "switch": "ತಿರುಗಿಸು",
    "create_new_organisation": "ಹೊಸ ಸಂಸ್ಥೆಯನ್ನು ರಚಿಸಿ",
    "organisation_name": "ಸಂಘಟನೆಯ ಹೆಸರು",
    "organisation_type": "ಸಂಸ್ಥೆಯ ಪ್ರಕಾರ",
    "country_code": "ದೇಶ ಸಂಹಿತೆ",
    "country": "ದೇಶ",
    "state": "ರಾಜ್ಯ",
    "clear": "ಸ್ಪಷ್ಟ",
    "change_organisation": "ಸಂಘಟನೆಯನ್ನು ಬದಲಾಯಿಸಿ",
    "organisation_id": "ಸಂಸ್ಥೆ ಐಡಿ",
    "update_your_oem_logo": "ನಿಮ್ಮ OEM ಲೋಗೊವನ್ನು ನವೀಕರಿಸಿ",
    "select_a_logo_to_upload": "ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಲೋಗೋ ಆಯ್ಕೆಮಾಡಿ",
    "submit_logo": "ಲೋಗೋವನ್ನು ಸಲ್ಲಿಸಿ",
    "create_workspace": "ಕಾರ್ಯಕ್ಷೇತ್ರವನ್ನು ರಚಿಸಿ",
    "hub_id": "ಹಬ್ ಐಡಿ",
    "name_must_be_less_than_45_characters": "ಹೆಸರು 45 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "id_is_required": "ID ಅಗತ್ಯವಿದೆ",
    "no_space_allowed": "ಯಾವುದೇ ಜಾಗವನ್ನು ಅನುಮತಿಸಲಾಗುವುದಿಲ್ಲ",
    "id_must_be_less_than_6_characters": "ಐಡಿ 6 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "edit_workspace": "ಕಾರ್ಯಕ್ಷೇತ್ರವನ್ನು ಸಂಪಾದಿಸಿ",
    "delete_workspace": "ಕಾರ್ಯಕ್ಷೇತ್ರವನ್ನು ಅಳಿಸಿ",
    "add_modbus_device": "ಮೊಡ್‌ಬಸ್ ಸಾಧನವನ್ನು ಸೇರಿಸಿ",
    "add_device": "ಸಾಧನ ಸೇರಿಸಿ",
    "oops": "ಓಹ್ !!",
    "ok": "ಸರಿ",
    "slave_id": "ಗುಲಾಮ ಐಡಿ",
    "device_type": "ಸಾಧನ ಪ್ರಕಾರ",
    "connection_type": "ಸಂಪರ್ಕ ಪ್ರಕಾರ",
    "mGate": "ಮಿನುಗು",
    "make": "ರೂಪಿಸು",
    "model": "ಮಾದರಿ",
    "name_must_be_less_than_33_characters": "ಹೆಸರು 33 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "name_must_be_less_than_11_characters": "ಹೆಸರು 11 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "it_should_be_a_valid_slave_id": "ಅದು ಮಾನ್ಯ ಗುಲಾಮರ ID ಆಗಿರಬೇಕು",
    "name_must_be_less_than_4_digits": "ಹೆಸರು 4 ಅಂಕೆಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "modbus_tcp": "ಮೊಡ್‌ಬಸ್ ಟಿಸಿಪಿ",
    "modbus_rtu": "ಮೊಡ್‌ಬಸ್ ಆರ್‌ಟಿಯು",
    "add_virtual_device": "ವರ್ಚುವಲ್ ಸಾಧನವನ್ನು ಸೇರಿಸಿ",
    "add_mqtt_device": "MQTT ಸಾಧನವನ್ನು ಸೇರಿಸಿ",
    "topic": "ವಿಷಯ",
    "identifier": "ಗುರುತಿಸಿಕೊಳ್ಳುವಿಕೆ",
    "identifier_value": "ಗುರುತಿಸುವಿಕೆ ಮೌಲ್ಯ",
    "name_must_be_less_than_20_characters": "ಹೆಸರು 20 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "no_special_characters_or_space_allowed": "ಯಾವುದೇ ವಿಶೇಷ ಪಾತ್ರಗಳು ಅಥವಾ ಸ್ಥಳವನ್ನು ಅನುಮತಿಸಲಾಗುವುದಿಲ್ಲ",
    "must_be_less_than_15_characters": "15 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "create_role": "ಪಾತ್ರವನ್ನು ರಚಿಸಿ",
    "role": "ಪಾತ್ರದ ಪಾತ್ರ",
    "role_is_required": "ಪಾತ್ರದ ಅಗತ್ಯವಿದೆ",
    "role_must_be_less_than_40_characters": "ಪಾತ್ರವು 40 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "role_should_not_contain_white_characters": "ಪಾತ್ರವು ಬಿಳಿ ಅಕ್ಷರಗಳನ್ನು ಹೊಂದಿರಬಾರದು",
    "comparison": "ಹೋಲಿಕೆ",
    "historical_time_of_day_comparison_parameter": "ದಿನದ ಹೋಲಿಕೆಯ ಇತಿಹಾಸದ ಸಮಯ - ನಿಯತಾಂಕ",
    "device_parameter": "ಸಾಧನ ನಿಯತಾಂಕ",
    "date_1": "ದಿನಾಂಕ 1",
    "date_2": "ದಿನಾಂಕ 2",
    "tod_comparison_report": "ಟಾಡ್ ಹೋಲಿಕೆ ವರದಿ",
    "time_frame": "ಸಮಯೋಚಿತ",
    "download_table": "ಡೌನ್‌ಲೋಡ್ ಟೇಬಲ್",
    "table": "ಮೇಜು",
    "showMinMax_values": "ಕನಿಷ್ಠ ಮತ್ತು ಗರಿಷ್ಠ ಮೌಲ್ಯಗಳನ್ನು ತೋರಿಸಿ",
    "usage_or_average": "ಬಳಕೆ ಅಥವಾ ಸರಾಸರಿ",
    "min_value": "ಕನಿಷ್ಠ ಮೌಲ್ಯ",
    "last_12_weeks": "ಕೊನೆಯ 12 ವಾರಗಳು",
    "last_6_months": "ಕೊನೆಯ 6 ತಿಂಗಳುಗಳು",
    "last_12_months": "ಕೊನೆಯ 12 ತಿಂಗಳುಗಳು",
    "year_5": "5 ವರ್ಷಗಳು",
    "machinewise_comparison": "ಯಂತ್ರ ಬುದ್ಧಿವಂತ ಹೋಲಿಕೆ",
    "timeFrame": "ಕಾಲಮರಿ",
    "it_should_be_a_date": "ಅದು ದಿನಾಂಕವಾಗಿರಬೇಕು",
    "time_of_day_parameter": "ದಿನದ ಸಮಯ- ನಿಯತಾಂಕ",
    "tod_report": "ಟಾಡ್ - ವರದಿ",
    "in_this_timeframe": "ಈ ಕಾಲಮಿತಿಯಲ್ಲಿ",
    "average": "ಸರಾಸರಿ",
    "maximum": "ಗರಿಷ್ಠ",
    "minimum": "ಕನಿಷ್ಠ",
    "energy_usage_trend": "ಶಕ್ತಿ ಬಳಕೆಯ ಪ್ರವೃತ್ತಿ",
    "standard_deviation": "ಪ್ರಮಾಣಿತ ವಿಚಲನ",
    "statistical_mode": "ಸಂಖ್ಯಾಶಾಸ್ತ್ರೀಯ ಕ್ರಮ",
    "production_logs": "ಉತ್ಪಾದನಾ ದಾಖಲೆಗಳು",
    "capacity_logs": "ಸಾಮರ್ಥ್ಯದ ಲಾಗ್‌ಗಳು",
    "dynamic_logs": "ಡೈನಾಮಿಕ್ ಲಾಗ್‌ಗಳು",
    "pollutants": "ಮಾಲಿನ್ಯಕಾರಕ",
    "group_by": "ಗುಂಪು",
    "generate_grouped_trends": "ಗುಂಪು ಮಾಡಿದ ಪ್ರವೃತ್ತಿಗಳನ್ನು ರಚಿಸಿ",
    "tCo2": "TCO2",
    "scope_1": "ವ್ಯಾಪ್ತಿ 1",
    "scope_2": "ವ್ಯಾಪ್ತಿ 2",
    "scope_3": "ವ್ಯಾಪ್ತಿ 3",
    "source": "ಮೂಲ",
    "type": "ವಿಧ",
    "user_access_log": "ಬಳಕೆದಾರ ಪ್ರವೇಶ ಲಾಗ್",
    "user_logs": "ಬಳಕೆದಾರರ ದಾಖಲೆಗಳು",
    "fetch_logs": "ಲಾಗ್‌ಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
    "update_profile": "ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ",
    "lang": "ಜಂಬದ",
    "theme": "ವಿಷಯ",
    "phone": "ದೂರವಾಣಿ",
    "update": "ನವೀಕರಿಸು",
    "update_password": "ಪಾಸ್ವರ್ಡ್ ನವೀಕರಿಸಿ",
    "change_password": "ಪಾಸ್ವರ್ಡ್ ಬದಲಾಯಿಸಿ",
    "old_password": "ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್",
    "new_password": "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್",
    "confirm_password": "ಪಾಸ್ವರ್ಡ್ ದೃ irm ೀಕರಿಸಿ",
    "password_must_be_more_than_8_characters": "ಪಾಸ್ವರ್ಡ್ 8 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚು ಇರಬೇಕು",
    "passwords_do_not_match": "ಪಾಸ್ವರ್ಡ್ಗಳು ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ",
    "add_hub": "ಹಬ್ ಸೇರಿಸಿ",
    "id_must_be_less_than_4_characters": "ಐಡಿ 4 ಅಕ್ಷರಗಳಿಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
    "add_maintainer": "ನಿರ್ವಹಿಸುವವರನ್ನು ಸೇರಿಸಿ",
    "add_parent_org": "ಪೋಷಕ ಆರ್ಗ್ ಸೇರಿಸಿ",
    "add_parent_to_organisation": "ಸಂಸ್ಥೆಗೆ ಪೋಷಕರನ್ನು ಸೇರಿಸಿ",
    "parent_org_id": "ಪೋಷಕ ಆರ್ಗ್ ಐಡಿ",
    "add_maintainer_to_organization": "ಸಂಸ್ಥೆಗೆ ನಿರ್ವಹಿಸುವವರನ್ನು ಸೇರಿಸಿ",
    "maintainer_id": "ನಿರ್ವಹಕ ಐಡಿ",
    "update_organisation_details": "ಸಂಸ್ಥೆಯ ವಿವರಗಳನ್ನು ನವೀಕರಿಸಿ",
    "update_your_organization_details": "ನಿಮ್ಮ ಸಂಸ್ಥೆಯ ವಿವರಗಳನ್ನು ನವೀಕರಿಸಿ",
    "default_email": "ಡೀಫಾಲ್ಟ್ ಇಮೇಲ್",
    "picker_in_menu": "ಮೆನುವಿನಲ್ಲಿ ಪಿಕ್ಕರ್",
    "param_diagnostics": "ಪ್ಯಾರಮ್ ಡಯಾಗ್ನೋಸ್ಟಿಕ್ಸ್",
    "show_logo": "ಲೋಗೋ ತೋರಿಸಿ",
    "show_oem_logo": "ಒಇಎಂ ಲೋಗೊ ತೋರಿಸಿ",
    "organisation_alias": "ಸಂಘಟನಾ ಅಲಿಯಾಸ್",
    "update_organisation_alias": "ಸಂಸ್ಥೆ ಅಲಿಯಾಸ್ ಅನ್ನು ನವೀಕರಿಸಿ",
    "update_org_alias": "ಆರ್ಗ್ ಅಲಿಯಾಸ್ ಅನ್ನು ನವೀಕರಿಸಿ",
    "update_your_organization_logo": "ನಿಮ್ಮ ಸಂಸ್ಥೆ ಲೋಗೊವನ್ನು ನವೀಕರಿಸಿ",
    "select_a_logo": "ಲೋಗೋ ಆಯ್ಕೆಮಾಡಿ",
    "update_logo": "ಲೋಗೋ ನವೀಕರಿಸಿ",
    "create_uam_user": "UAM ಬಳಕೆದಾರರನ್ನು ರಚಿಸಿ",
    "overview": "ಅವಧಿ",
    "batches": "ಬಿರಡೆ",
    "track_batch": "ಟ್ರ್ಯಾಕ್ ಬ್ಯಾಚ್",
    "batch_movement": "ಬ್ಯಾಚ್ ಚಲನೆ",
    "production_planner": "ಉತ್ಪಾದಕ ಯೋಜಕ",
    "show_unshipped_only": "ರವಾನೆಯಾದ ಯುಎನ್ ಅನ್ನು ತೋರಿಸಿ",
    "view_logs": "ಲಾಗ್‌ಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
    "params": "ನಾರಿನ",
    "add_filter": "ಫಿಲ್ಟರ್ ಸೇರಿಸಿ",
    "filters": "ಕಸಾಯಿಖಾನೆ",
    "batch_id": "ಬ್ಯಾಚ್_ಐಡಿ",
    "show_details": "ವಿವರಗಳನ್ನು ತೋರಿಸಿ",
    "tracing_logs": "ಲಾಗ್‌ಗಳನ್ನು ಪತ್ತೆಹಚ್ಚುವುದು",
    "quantity": "ಪ್ರಮಾಣ",
    "location_event_type": "ಸ್ಥಳ ಈವೆಂಟ್ ಪ್ರಕಾರ",
    "fetch_plans": "ಯೋಜನೆಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
    "sku": "ಒಂದು ಬಗೆಯ",
    "skus": "ಸ್ಟಾಕ್ ಕೀಪಿಂಗ್ ಘಟಕಗಳು",
    "code": "ಸಂಹಿತೆ",
    "add_production_plans": "ಉತ್ಪಾದನಾ ಯೋಜನೆಗಳನ್ನು ಸೇರಿಸಿ",
    "date": "ದಿನಾಂಕ",
    "from_time": "ಸಮಯದಿಂದ",
    "to_time": "ಸಮಯಕ್ಕೆ",
    "activities": "ಚಟುವಟಿಕೆಗಳು",
    "task_name": "ಕಾರ್ಯ ಹೆಸರು",
    "task_details": "ಕಾರ್ಯ ವಿವರಗಳು",
    "start_date": "ಪ್ರಾರಂಭದ ದಿನಾಂಕ",
    "end_date": "ಅಂತಿಮ ದಿನಾಂಕ",
    "assignee": "ನಿಯೋಜಿಸುವವನು",
    "manager": "ವ್ಯವಸ್ಥಾಪಕ",
    "activity": "ಚಟುವಟಿಕೆ",
    "activity_type": "ಚಟುವಟಿಕೆ ಪ್ರಕಾರ",
    "escalation_matrix": "ಉಲ್ಬಣ",
    "maintenance_activity": "ನಿರ್ವಹಣೆ ಚಟುವಟಿಕೆ",
    "em_level": "ಇಎಮ್ ಮಟ್ಟ",
    "delay": "ವಿಳಂಬ",
    "user_group": "ಬಳಕೆದಾರರ ಗುಂಪು",
    "mins_5": "5 ನಿಮಿಷಗಳು",
    "mins_10": "10 ನಿಮಿಷಗಳು",
    "mins_15": "15 ನಿಮಿಷಗಳು",
    "mins_30": "30 ನಿಮಿಷಗಳು",
    "hour_1": "1 ಗಂಟೆ",
    "hour_6": "6 ಗಂಟೆ",
    "day_1": "1 ದಿನ",
    "day": "ದಿನ",
    "day_3": "3 ದಿನ",
    "insights": "ಒಳನೋಟ",
    "create_emailer_group": "ಇಮೇಲ್ ಮಾಡುವ ಗುಂಪನ್ನು ರಚಿಸಿ",
    "notification_type": "ಅಧಿಸೂಚನೆ ಪ್ರಕಾರ",
    "email": "ಇಮೇಲ್ ಕಳುಹಿಸು",
    "sms": "ಎಸ್‌ಎಂಎಸ್",
    "create_machine_group": "ಯಂತ್ರ ಗುಂಪು ರಚಿಸಿ",
    "create_param_group": "ಪ್ಯಾರಮ್ ಗ್ರೂಪ್ ರಚಿಸಿ",
    "create_user_group": "ಬಳಕೆದಾರರ ಗುಂಪನ್ನು ರಚಿಸಿ",
    "create_module": "ಮಾಡ್ಯೂಲ್ ರಚಿಸಿ",
    "busbar_temp_module": "ಬಸ್‌ಬಾರ್ ಟೆಂಪ್ ಮಾಡ್ಯೂಲ್",
    "power_quality_module": "ವಿದ್ಯುತ್ ಗುಣಮಟ್ಟದ ಮಾಡ್ಯೂಲ್",
    "esg_module": "ಇಎಸ್ಜಿ ಮಾಡ್ಯೂಲ್",
    "edge_hub": "ಅಂಚಿನ ಹಾಯ",
    "tolerance": "ತಾಳ್ಮೆ",
    "ambient_temp_input_type": "ಸುತ್ತುವರಿದ ಟೆಂಪ್ ಇನ್ಪುಟ್ ಪ್ರಕಾರ",
    "ambient_temp": "ಸುತ್ತುವರಿದ ಟೆಂಪ್",
    "workspace_ambient_temp": "ಕಾರ್ಯಕ್ಷೇತ್ರದ ಸುತ್ತುವರಿದ ತಾತ್ಕಾಲಿಕ",
    "device_ambient_temp": "ಸಾಧನ ಸುತ್ತುವರಿದ ಟೆಂಪ್",
    "r_temperature": "ಆರ್ ತಾಪಮಾನ",
    "y_temperature": "Y ತಾಪಮಾನ",
    "b_temperature": "ಬಿ ತಾಪಮಾನ",
    "n_temperature": "N ತಾಪಮಾನ",
    "rated_current": "ರೇಟ್ ಮಾಡಲಾದ ಪ್ರವಾಹ",
    "no_load_current": "ಲೋಡ್ ಕರೆಂಟ್ ಇಲ್ಲ",
    "r_current": "R ಕರೆಂಟ್",
    "y_current": "Y ಕರೆಂಟ್",
    "b_current": "ಬಿ ಕರೆಂಟ್",
    "n_current": "ಎನ್ ಕರೆಂಟ್",
    "delete_activity": "ಚಟುವಟಿಕೆಯನ್ನು ಅಳಿಸಿ",
    "substation_voltage_kv": "ಸಬ್ಸ್ಟೇಷನ್ ವೋಲ್ಟೇಜ್ (ಕೆವಿ)",
    "transformer_rating_mva": "ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮರ್ ರೇಟಿಂಗ್ (ಎಂವಿಎ)",
    "percentage_impedance_of_the_transformer": "ಟ್ರಾನ್ಸ್‌ಫಾರ್ಮರ್‌ನ % ಪ್ರತಿರೋಧ",
    "voltage_rv_y": "ವೋಲ್ಟೇಜ್ ಆರ್ವಿ (ವೈ)",
    "voltage_yb_v": "ವೋಲ್ಟೇಜ್ ವೈಬಿ (ವಿ)",
    "voltage_br_v": "ವೋಲ್ಟೇಜ್ ಬಿಆರ್ (ವಿ)",
    "current_r_a": "ಪ್ರಸ್ತುತ ಆರ್ (ಎ)",
    "current_y_a": "ಪ್ರಸ್ತುತ ವೈ (ಎ)",
    "current_b_a": "ಪ್ರಸ್ತುತ ಬಿ (ಎ)",
    "thd_i": "Thd i",
    "thd_v": "Thd v",
    "thd_i1": "Thd i1",
    "active_power_kw": "ಸಕ್ರಿಯ ಶಕ್ತಿ (ಕೆಡಬ್ಲ್ಯೂ)",
    "apparent_power_kva": "ಸ್ಪಷ್ಟ ಶಕ್ತಿ (ಕೆವಿಎ)",
    "reactive_power_kvar": "ಪ್ರತಿಕ್ರಿಯಾತ್ಮಕ ಶಕ್ತಿ (ಕ್ವಾರ್)",
    "active_energy_kwh": "ಸಕ್ರಿಯ ಶಕ್ತಿ (kWh)",
    "apparent_energy_kvah": "ಸ್ಪಷ್ಟ ಶಕ್ತಿ (ಕೆವಿಎಹೆಚ್)",
    "pf": "ಪಿಎಫ್",
    "text": "ಪಠ್ಯ",
    "energy_source": "ಶಕ್ತಿ ಮೂಲ",
    "scope": "ವ್ಯಾಪ್ತಿ",
    "system_logs": "ಸಿಸ್ಟಮ್ ಲಾಗ್‌ಗಳು",
    "system_logbook": "ಸಿಸ್ಟಮ್ ಲಾಗ್ ಪುಸ್ತಕ",
    "esg_data_log": "ಇಎಸ್ಜಿ ಡೇಟಾ ಲಾಗ್",
    "add_esg_data": "ಇಎಸ್ಜಿ ಡೇಟಾವನ್ನು ಸೇರಿಸಿ",
    "add_esg_data_log": "ಇಎಸ್ಜಿ ಡೇಟಾ ಲಾಗ್ ಸೇರಿಸಿ",
    "source_type": "ಮೂಲ ಪ್ರಕಾರ",
    "unit_of_measurement": "ಅಳತೆಯ ಘಟಕ",
    "from_date_optional": "ದಿನಾಂಕದಿಂದ (ಐಚ್ al ಿಕ)",
    "emission_factor": "ಹೊರಸೂಸುವ ಅಂಶ (ಕೆಜಿಸಿಒ 2)",
    "create_dynamic_logger": "ಡೈನಾಮಿಕ್ ಲಾಗರ್ ರಚಿಸಿ",
    "dynamic_log": "ಡೈನಾಮಿಕ್ ಲಾಗ್",
    "primary_field_label": "ಪ್ರಾಥಮಿಕ ಕ್ಷೇತ್ರ ಲೇಬಲ್",
    "primary_field_type": "ಪ್ರಾಥಮಿಕ ಕ್ಷೇತ್ರದ ಪ್ರಕಾರ",
    "primary": "ಪ್ರಾಥಮಿಕ",
    "additional_fields": "ಹೆಚ್ಚುವರಿ ಕ್ಷೇತ್ರಗಳು",
    "field_label": "ಕ್ಷೇತ್ರ ಲೇಬಲ್",
    "field_type": "ಕ್ಷೇತ್ರ ಪ್ರಕಾರ",
    "choose_list": "ಪಟ್ಟಿಯನ್ನು ಆರಿಸಿ",
    "list": "ಪಟ್ಟಿ",
    "create_capacity_logger": "ಸಾಮರ್ಥ್ಯ ಲಾಗರ್ ರಚಿಸಿ",
    "capacity_log": "ಸಾಮರ್ಥ್ಯ ಲಾಗ್",
    "create": "ರಚಿಸು",
    "create_production_logger": "ಉತ್ಪಾದನಾ ಲಾಗರ್ ರಚಿಸಿ",
    "production_log": "ಉತ್ಪಾದಕ ಲಾಗ್",
    "production_logger": "ಉತ್ಪಾದನೆ ಲಾಗರ್",
    "input_type": "ಇನ್ಪುಟ್ ಪ್ರಕಾರ",
    "input": "ಒಳಕ್ಕೆ",
    "input_name": "ಇನ್ಪುಟ್ ಹೆಸರು",
    "output_type": "Output ಟ್‌ಪುಟ್ ಪ್ರಕಾರ",
    "output": "ಉತ್ಪಾದನೆ",
    "output_name": "Output ಟ್‌ಪುಟ್ ಹೆಸರು",
    "output_params": "Put ಟ್‌ಪುಟ್ ಪ್ಯಾರಮ್‌ಗಳು",
    "compute_production": "ಉತ್ಪಾದನೆಯನ್ನು ಲೆಕ್ಕಾಚಾರ ಮಾಡಿ",
    "compute_energy": "ಶಕ್ತಿಯನ್ನು ಲೆಕ್ಕಾಚಾರ ಮಾಡಿ",
    "energy_param_type": "ಶಕ್ತಿ ರೂಪ",
    "energy_device": "ಶಕ್ತಿ ಸಾಧನ",
    "energy_param": "ಶಕ್ತಿ",
    "production_operator": "ಉತ್ಪಾದಕ ನಿರ್ವಾಹ",
    "production_correction_factor": "ಉತ್ಪಾದನಾ ತಿದ್ದುಪಡಿ ಅಂಶ",
    "output_operator": "Output ಟ್ಪುಟ್ ನಿರ್ವಾಹಕ",
    "output_correction_factor": "Output ಟ್ಪುಟ್ ತಿದ್ದುಪಡಿ ಅಂಶ",
    "input_operator": "ಇನ್ಪುಟ್ ಆಪರೇಟರ್",
    "input_correction_factor": "Output ಟ್ಪುಟ್ ತಿದ್ದುಪಡಿ ಅಂಶ",
    "energy_unit": "ಶಕ್ತಿ ಘಟಕ",
    "production_unit": "ಉತ್ಪಾದಕ ಘಟಕ",
    "production": "ಉತ್ಪಾದಿಸು",
    "create_data_logger": "ಡೇಟಾ ಲಾಗರ್ ರಚಿಸಿ",
    "data_log": "ದತ್ತಾಂಶ ಲಾಗ್",
    "add_param": "ಪ್ಯಾರಮ್ ಸೇರಿಸಿ",
    "param_id": "ಪ್ರಚಾರ ಐಡಿ",
    "create_batch_production_logger": "ಬ್ಯಾಚ್ ಉತ್ಪಾದನಾ ಲಾಗರ್ ಅನ್ನು ರಚಿಸಿ",
    "batch_tracing_log": "ಬ್ಯಾಚ್ ಟ್ರೇಸಿಂಗ್ ಲಾಗ್",
    "log_name": "ಲಾಗ್ ಹೆಸರು",
    "location": "ಸ್ಥಳ",
    "info": "ಮಾಹಿತಿ",
    "remarks": "ಟೀಕೆಗಳು",
    "code_will_be_available_to_log_by_default": "ಪೂರ್ವನಿಯೋಜಿತವಾಗಿ ಲಾಗ್ ಇನ್ ಮಾಡಲು ಕೋಡ್ ಲಭ್ಯವಿರುತ್ತದೆ",
    "fields": "ಸಮಾಧಿ",
    "additional_info": "ಹೆಚ್ಚುವರಿ ಮಾಹಿತಿ",
    "action": "ಕ್ರಿಯೆ",
    "report_type": "ವರದಿ ಪ್ರಕಾರ",
    "create_parameter_report": "ಪ್ಯಾರಾಮೀಟರ್ ವರದಿಯನ್ನು ರಚಿಸಿ",
    "data_processing": "ದತ್ತಾಂಶ ಸಂಸ್ಕರಣೆ",
    "addition": "ಸೇರ್ಪಡೆ",
    "singular": "ಏಕ",
    "report": "ವರದಿ",
    "energy_parameters_kwh": "ಶಕ್ತಿ ನಿಯತಾಂಕಗಳು (kWh)",
    "apparent_energy_KVAh": "ಸ್ಪಷ್ಟ ಶಕ್ತಿ kvah",
    "maximum_demand_MD": "ಗರಿಷ್ಠ ಬೇಡಿಕೆ (ಎಂಡಿ)",
    "excel_frequency": "ಬಾವಲ ಆವರ್ತನ",
    "multiplier": "ಗುಣಕ",
    "single": "ಏಕಮಾತ್ರ",
    "incomers": "ಆಪಾರ",
    "sections": "ವಿಭಾಗಗಳು",
    "energy_distribution_report": "ಶಕ್ತಿ ವಿತರಣಾ ವರದಿ",
    "root_machine": "ಮೂಲ ಯಂತ್ರ",
    "tariff_on": "ಸುಂಕ",
    "add_root_common_machine": "ರೂಟ್ ಮತ್ತು ಸಾಮಾನ್ಯ ಯಂತ್ರವನ್ನು ಸೇರಿಸಿ",
    "common_machine": "ಸಾಮಾನ್ಯ ಯಂತ್ರ",
    "energy_bill_distribution_report": "ಶಕ್ತಿ ಬಿಲ್ ವಿತರಣಾ ವರದಿ",
    "create_consolidated": "ಏಕೀಕೃತ ರಚಿಸಿ",
    "choose_report": "ವರದಿಯನ್ನು ಆರಿಸಿ",
    "id": "ಕುಡಲು",
    "timeframed_sec": "ಸಮಯ ಚೌಕಟ್ಟಿನ ಸೆ.",
    "create_dynamic_excel_report": "ಡೈನಾಮಿಕ್ ಎಕ್ಸೆಲ್ ವರದಿಯನ್ನು ರಚಿಸಿ",
    "update_dynamic_excel_report": "ಡೈನಾಮಿಕ್ ಎಕ್ಸೆಲ್ ವರದಿಯನ್ನು ನವೀಕರಿಸಿ",
    "add_sheet": "ಶೀಟ್ ಸೇರಿಸಿ",
    "sheet_name": "ಹಾಳಾದ",
    "timestamp": "ಸಮಯ ಮಾನ್ಯತೆ",
    "sheet": "ಹಾಳೆ",
    "sheets": "ಹಾಳೆಗಳು",
    "sheet_id": "Sheet ID",
    "delete_sheet": "ಹಾಳೆ ಅಳಿಸಿ",
    "report_name": "ವರದಿ ಹೆಸರು",
    "update_details": "ವಿವರಗಳನ್ನು ನವೀಕರಿಸಿ",
    "unit": "ಘಟಕ",
    "parameters": "ನಿಯತಾಂಕಗಳು",
    "send_excel_file": "ಎಕ್ಸೆಲ್ ಫೈಲ್ ಕಳುಹಿಸಿ",
    "update_excel_template_based_report": "ಎಕ್ಸೆಲ್ ಟೆಂಪ್ಲೇಟ್ ಆಧಾರಿತ ವರದಿಯನ್ನು ನವೀಕರಿಸಿ",
    "create_excel_template_based_report": "ಎಕ್ಸೆಲ್ ಟೆಂಪ್ಲೇಟ್ ಆಧಾರಿತ ವರದಿಯನ್ನು ರಚಿಸಿ",
    "upload_excel_template_xlsx": "ಎಕ್ಸೆಲ್ ಟೆಂಪ್ಲೇಟ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ (ಎಕ್ಸ್‌ಎಲ್‌ಎಸ್‌ಎಕ್ಸ್)",
    "param_equation": "ಮಹತ್ವ",
    "param_equations": "ನಿಯತಾಂಕ ಸಮೀಕರಣಗಳು",
    "edit_sheets": "ಹಾಳೆಗಳನ್ನು ಸಂಪಾದಿಸಿ",
    "row_no": "ಸಾಲು ಇಲ್ಲ",
    "column_no": "ಕಾಲಮ್ ಇಲ್ಲ",
    "row_number": "ಸಾಲು ಸಂಖ್ಯೆ",
    "column_number": "ಅಂಕಣ ಸಂಖ್ಯೆ",
    "colummn_number": "ಅಂಕಣ ಸಂಖ್ಯೆ",
    "no_sheets_added": "ಯಾವುದೇ ಹಾಳೆಗಳನ್ನು ಸೇರಿಸಲಾಗಿದೆ",
    "process_based_usage_sheet": "ಪ್ರಕ್ರಿಯೆ ಆಧಾರಿತ ಬಳಕೆ ಹಾಳೆ",
    "process": "ಪ್ರಕ್ರಿಯೆಗೊಳಿಸು",
    "process_label": "ಪ್ರಕ್ರಿಯೆ ಲೇಬಲ್",
    "add_tag_types": "ಟ್ಯಾಗ್ ಪ್ರಕಾರಗಳನ್ನು ಸೇರಿಸಿ",
    "add_process_to_list": "ಪಟ್ಟಿಗೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸೇರಿಸಿ",
    "machine_tag_based_daily_data_sheet": "ಮೆಷಿನ್ ಟ್ಯಾಗ್ ಆಧಾರಿತ ದೈನಂದಿನ ಡೇಟಾ ಶೀಟ್",
    "machine_label": "ಯಂತ್ರ ಲೇಬಲ್",
    "add_machine_to_list": "ಪಟ್ಟಿಗೆ ಯಂತ್ರವನ್ನು ಸೇರಿಸಿ",
    "add_tag_type_to_list": "ಪಟ್ಟಿಗೆ ಟ್ಯಾಗ್ ಪ್ರಕಾರವನ್ನು ಸೇರಿಸಿ",
    "shift_based_usage_sheet": "ಶಿಫ್ಟ್ ಆಧಾರಿತ ಬಳಕೆಯ ಹಾಳೆ",
    "status_for_process": "ಪ್ರಕ್ರಿಯೆಗೊಳಿಸು",
    "status_for_machine": "ಯಂತ್ರ",
    "esg_summary_item": "ಸಂಕ್ಷಿಪ್ತ",
    "esg_details_item": "ವಿವರಗಳು",
    "esg_scope1_label": "ವ್ಯಾಪ್ತಿ 1",
    "esg_scope2_label": "ವ್ಯಾಪ್ತಿ 2",
    "esg_scope3_label": "ವ್ಯಾಪ್ತಿ 3",
    "esg_scopes_list_label": "ಸ್ಕೋಪ್",
    "transpose": "ಪೋಯಿಲು",
    "process_based_timeframed_data_sheet": "ಪ್ರಕ್ರಿಯೆ ಆಧಾರಿತ ಸಮಯಫ್ರಾಮ್ಡ್ ಡೇಟಾ ಶೀಟ್",
    "add_pocess_to_list": "ಪಟ್ಟಿಗೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸೇರಿಸಿ",
    "tag_types": "ಟ್ಯಾಗ್ ವಿಧಗಳು",
    " consolidated_shift_based_usage_data_sheet": "ಏಕೀಕೃತ ಶಿಫ್ಟ್ ಆಧಾರಿತ ಬಳಕೆಯ ಡೇಟಾ ಶೀಟ್",
    "date_format": "ದಿನಾಂಕ ಸ್ವರೂಪ",
    "include_process_total": "ಪ್ರಕ್ರಿಯೆಯ ಒಟ್ಟು ಸೇರಿಸಿ",
    "machine_group_based_usage_sheet": "ಯಂತ್ರ ಗುಂಪು ಆಧಾರಿತ ಬಳಕೆ ಹಾಳೆ",
    "machine_group_label": "ಯಂತ್ರ ಗುಂಪು ಲೇಬಲ್",
    "machine_group": "ಯಂತ್ರ ಗುಂಪು",
    "add_machine_group_to_list": "ಪಟ್ಟಿಗೆ ಯಂತ್ರ ಗುಂಪನ್ನು ಸೇರಿಸಿ",
    "parameter_based_usage_data_sheet": "ನಿಯತಾಂಕ ಆಧಾರಿತ ಬಳಕೆಯ ಡೇಟಾ ಶೀಟ್",
    "show_month_start&end_data": "ತಿಂಗಳ ಪ್ರಾರಂಭ ಮತ್ತು ಅಂತ್ಯದ ಡೇಟಾವನ್ನು ತೋರಿಸಿ",
    "statistics": "ಅಂಕಿಅಂಶಗಳ",
    "add_param_to_list": "ಪಟ್ಟಿಗೆ ಪ್ಯಾರಮ್ ಸೇರಿಸಿ",
    "add_timestamp_to_list": "ಪಟ್ಟಿಗೆ ಟೈಮ್‌ಸ್ಟ್ಯಾಂಪ್ ಸೇರಿಸಿ",
    "parameter_group_based_timeframed": "ಪ್ಯಾರಾಮೀಟರ್ ಗುಂಪು ಆಧಾರಿತ ಸಮಯ ಮೀರಿದೆ",
    "param_group_label": "ಪ್ಯಾರಮ್ ಗ್ರೂಪ್ ಲೇಬಲ್",
    "param_group": "ಸಮಗ್ರ ಗುಂಪು",
    "add_param_group_to_list": "ಪಟ್ಟಿಗೆ ಪ್ಯಾರಮ್ ಗುಂಪನ್ನು ಸೇರಿಸಿ",
    "dynamic_logger_based_data_sheet": "ಡೈನಾಮಿಕ್ ಲಾಗರ್ ಆಧಾರಿತ ಡೇಟಾ ಶೀಟ್",
    "dynamic_log_label": "ಡೈನಾಮಿಕ್ ಲಾಗ್ ಲೇಬಲ್",
    "parameter_equation_based_sheet": "ನಿಯತಾಂಕ ಸಮೀಕರಣ ಆಧಾರಿತ ಹಾಳೆ",
    "data_source": "ದತ್ತಾಂಶ ಮೂಲ",
    "equation_label": "ಸಮೀಕರಣ ಲೇಬಲ್",
    "add_equation_to_list": "ಪಟ್ಟಿಗೆ ಸಮೀಕರಣವನ್ನು ಸೇರಿಸಿ",
    "absolute_values": "ಸಂಪೂರ್ಣ ಮೌಲ್ಯಗಳು",
    "consumption_based_sheet": "ಬಳಕೆ ಆಧಾರಿತ ಹಾಳೆ",
    "parameter_name": "ನಿಯತಾಂಕ ಹೆಸರು",
    "statistical_analysis_based_sheet": "ಸಂಖ್ಯಾಶಾಸ್ತ್ರೀಯ ವಿಶ್ಲೇಷಣೆ ಆಧಾರಿತ ಹಾಳೆ",
    "parameter_instantaneous_data_sheet": "ಪ್ಯಾರಾಮೀಟರ್ ತತ್ಕ್ಷಣದ ಡೇಟಾ ಶೀಟ್",
    "create_sheet_column": "ಶೀಟ್ ಕಾಲಮ್ ರಚಿಸಿ",
    "column_label": "ಕಾಲಮ್ ಲೇಬಲ್",
    "machine_id": "ಯಂತ್ರ ಐಡಿ",
    "create_machine_list": "ಯಂತ್ರ ಪಟ್ಟಿಯನ್ನು ರಚಿಸಿ",
    "create_param_list": "ಪ್ಯಾರಾಮೀಟರ್ ಪಟ್ಟಿಯನ್ನು ರಚಿಸಿ",
    "create_sheets": "ಹಾಳೆಗಳನ್ನು ರಚಿಸಿ",
    "create_shift": "ಶಿಫ್ಟ್ ರಚಿಸಿ",
    "description_optional": "ವಿವರಣೆ (ಐಚ್ al ಿಕ)",
    "create_sku": "SKU ರಚಿಸಿ",
    "create_location": "ಸ್ಥಳವನ್ನು ರಚಿಸಿ",
    "location_type": "ಸ್ಥಳದ ಪ್ರಕಾರ",
    "from_location": "ಸ್ಥಳದಿಂದ",
    "to_location": "ಸ್ಥಳಕ್ಕೆ",
    "event_type": "ಘಟನೆ ಪ್ರಕಾರ",
    "create_location_event_type": "ಸ್ಥಳ ಈವೆಂಟ್ ಪ್ರಕಾರವನ್ನು ರಚಿಸಿ",
    "create_list": "ಪಟ್ಟಿಯನ್ನು ರಚಿಸಿ",
    "create_param_equation": "ಪ್ಯಾರಮ್ ಸಮೀಕರಣವನ್ನು ರಚಿಸಿ",
    "positive_params": "ಧನಾತ್ಮಕ ಪ್ಯಾರಮ್‌ಗಳು",
    "params_to_be_substracted": "ಕಳೆಯಬೇಕಾದ ಪ್ಯಾರಮ್‌ಗಳು",
    "create_combo_param": "ಕಾಂಬೊ ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "combo_params": "ಸಂಯೋಜಿತ ನಿಯತಾಂಕಗಳು",
    "calculated_params": "ಲೆಕ್ಕಹಾಕಿದ ನಿಯತಾಂಕಗಳು",
    "inter_param_operator": "ಇಂಟರ್ ಪ್ಯಾರಾಮೆ ಆಪರೇಟರ್",
    "correction_operator": "ತಿದ್ದುಪಡಿ ನಿರ್ವಾಹ",
    "correction_operand": "ತಿದ್ದುಪಡಿ ಕಾರ್ಯಾಚರಣೆ",
    "create_calculated_param": "ಲೆಕ್ಕಹಾಕಿದ ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "calculated_param": "ಲೆಕ್ಕಹಾಕಿದ ಪ್ಯಾರಮ್",
    "correction_factor": "ತಿದ್ದುಪಡಿ ಅಂಶ",
    "param1_type": "ಪ್ಯಾರಮ್ 1 ಪ್ರಕಾರ",
    "param1": "ಪ್ಯಾರಮ್ 1",
    "param1_operator": "ಪ್ಯಾರಮ್ 1 ಆಪರೇಟರ್",
    "param1_operand": "ಪ್ಯಾರಮ್ 1 ಒಪೆರಾಂಡ್",
    "param2_type": "ಪ್ಯಾರಮ್ 2 ಪ್ರಕಾರ",
    "param2": "ಪ್ಯಾರಮ್ 2",
    "param2_operator": "ಪ್ಯಾರಮ್ 2 ಆಪರೇಟರ್",
    "param2_operand": "ಪ್ಯಾರಮ್ 2 ಒಪೆರಾಂಡ್",
    "create_esg_scope_conversion": "ಇಎಸ್ಜಿ ಸ್ಕೋಪ್ ಪರಿವರ್ತನೆ ರಚಿಸಿ",
    "category": "ವರ್ಗ",
    "esg_scope_logging": "ಇಎಸ್ಜಿ ಸ್ಕೋಪ್ ಲಾಗಿಂಗ್",
    "get_from_param": "ಪ್ಯಾರಮ್‌ನಿಂದ ಪಡೆಯಿರಿ",
    "choose_param": "ಪ್ಯಾರಮ್ ಆಯ್ಕೆಮಾಡಿ",
    "create_target": "ಗುರಿಯನ್ನು ರಚಿಸಿ",
    "create_word": "ಪದವನ್ನು ರಚಿಸಿ",
    "words": "ಪದಗಳು",
    "choose_OEM": "OEM ಆಯ್ಕೆಮಾಡಿ",
    "get_the_models": "ಮಾದರಿಗಳನ್ನು ಪಡೆಯಿರಿ",
    "get_oem_devices": "OEM ಸಾಧನಗಳನ್ನು ಪಡೆಯಿರಿ",
    "oem_devices": "ಒಇಎಂ ಸಾಧನಗಳು",
    "model_number": "ಮಾದರಿ ಸಂಖ್ಯೆ",
    "register": "ನೋಂದಾಯಿಸು",
    "add_modbus_gateway": "ಮೊಡ್‌ಬಸ್ ಗೇಟ್‌ವೇ ಸೇರಿಸಿ",
    " add_modbus_TCP_gateway": "ಮೊಡ್‌ಬಸ್ ಟಿಸಿಪಿ ಗೇಟ್‌ವೇ ಸೇರಿಸಿ",
    "IPAddress": "ಐಪಿ ವಿಳಾಸ",
    "port_number": "ಪೋರ್ಟ್ ಸಂಖ್ಯೆ",
    "HubID": "ಹಬ್ ಐಡಿ",
    "baud_rate": "ಬೌಡ್ ದರ",
    "parity": "ಸಮಾನತೆ",
    "stop_bit": "ಬಿಟ್ ನಿಲ್ಲಿಸಿ",
    "update_wifi": "ವೈಫೈ ನವೀಕರಿಸಿ",
    "SSID": "ಸಸಿಹಳಿ",
    "password": "ಸಂದಳಕಾಯಿ",
    "restart_enture": "ಎಂಟ್ ಅನ್ನು ಮರುಪ್ರಾರಂಭಿಸಿ",
    "force_update_configs": "ಫೋರ್ಸ್ ನವೀಕರಣ ಸಂರಚನೆಗಳು",
    "activity_name": "ಚಟುವಟಿಕೆ ಹೆಸರು",
    "board": "ಮಂಡಲಿ",
    "calender": "ಕ್ಯಾಲೆಂಡರ್",
    "maintenance": "ನಿರ್ವಹಣೆ",
    "welcome": "ಸುಧನ",
    "logout": "Logೇದನ",
    "help": "ಸಹಾಯ ಮಾಡು",
    "accounts": "ಆಜ್ಞೆಗಳು",
    "organisation": "ಸಂಸ್ಥೆ",
    "pointer_color_optional": "ಪಾಯಿಂಟರ್ ಬಣ್ಣ (ಐಚ್ al ಿಕ)",
    "user_id": "ಬಳಕೆದಾರರ ಐಡಿ",
    "confirm_new_password": "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ದೃ irm ೀಕರಿಸಿ",
    "service": "ಸೇವ",
    "send_grid": "ಗಡಿಬಿಡಿ",
    "default_enture_view": "ಡೀಫಾಲ್ಟ್ ಪಡೆಯುವ ನೋಟ",
    "permissions": "ಅನುಮತಿಸುವಿಕೆ",
    "areas": "ಈ ಪ್ರದೇಶಗಳು",
    "remark": "ಟೀಕಿಸು",
    "days": "ದೆವ್ವ",
    "yesterdays_aqi": "ನಿನ್ನೆ ಎಕ್ಯೂಐ",
    "aqi": "ಒಂದು",
    "choose_air_quality_device": "ಗಾಳಿಯ ಗುಣಮಟ್ಟದ ಸಾಧನವನ್ನು ಆರಿಸಿ",
    "delete_this_device": "ಈ ಸಾಧನವನ್ನು ಅಳಿಸಿ",
    "air_quality_label": "ವಾಯು ಗುಣಮಟ್ಟದ ಲೇಬಲ್",
    "humidity": "ತಾತ್ಕಾಲಿಕತೆ",
    "temperature": "ಉಷ್ಣ",
    "watcher": "ವೀಕ್ಷಕ",
    "area_reference": "ಪ್ರದೇಶ ಉಲ್ಲೇಖ",
    "write_data": "ಡೇಟಾ ಬರೆಯಿರಿ",
    "day_of_week": "ವಾರದ ದಿನ",
    "month": "ತಿಂಗಳ",
    "diagnostics_notification_groups": "ರೋಗನಿರ್ಣಯ ಅಧಿಸೂಚನೆ ಗುಂಪುಗಳು",
    "hub_name": "ಹಬ್ ಹೆಸರು",
    "hub_model": "ಕೇಂದ್ರ",
    "hub_type": "ಹಪಬ್ ಪ್ರಕಾರ",
    "hub": "ಹಠ",
    "update_your_MQTT_bridge_config": "ನಿಮ್ಮ MQTT ಸೇತುವೆ ಸಂರಚನೆಯನ್ನು ನವೀಕರಿಸಿ",
    "MQTT_bridge_mode": "MQTT ಸೇತುವೆ ಮೋಡ್",
    "broker_address": "ಬ್ರೋಕರ್ ವಿಳಾಸ",
    "update_your_OPC_config": "ನಿಮ್ಮ ಒಪಿಸಿ ಸಂರಚನೆಯನ್ನು ನವೀಕರಿಸಿ",
    "OPC_client_mode": "ಒಪಿಸಿ ಕ್ಲೈಂಟ್ ಮೋಡ್",
    "OPC_server_URL": "ಒಪಿಸಿ ಸರ್ವರ್ URL",
    "user_auth": "ಬಳಕೆದಾರ ದೃ auth",
    "mode": "ಕ್ರಮ",
    "security_policy": "ಭದ್ರತಾ ನೀತಿ",
    "Update_your_Edge_Hub_restart_Schedule": "ನಿಮ್ಮ ಎಡ್ಜ್ ಹಬ್ ಮರುಪ್ರಾರಂಭದ ವೇಳಾಪಟ್ಟಿಯನ್ನು ನವೀಕರಿಸಿ",
    "auto_restart": "ಸ್ವಯಂ ಮರುಪ್ರಾರಂಭಿಸಿ",
    "time_24Hrs": "ಸಮಯ (24 ಗಂಟೆ)",
    "modbus_ping_latency": "ಮೊಡ್‌ಬಸ್ ಪಿಂಗ್ ಲೇಟೆನ್ಸಿ",
    "modbus_unhealthy_wait": "ಮೊಡ್ಬಸ್ ಅನಾರೋಗ್ಯಕರ ಕಾಯುವಿಕೆ",
    "edit_written_log_book": "ಲಿಖಿತ ಲಾಗ್ ಪುಸ್ತಕವನ್ನು ಸಂಪಾದಿಸಿ",
    "field_cant_change": "ಈ ಕ್ಷೇತ್ರವು ಬದಲಾಗುವುದಿಲ್ಲ",
    "re_write": "ಪುನಃ ಬರೆಯಿಸು",
    "write_in_logbook": "ಲಾಗ್‌ಬುಕ್‌ನಲ್ಲಿ ಬರೆಯಿರಿ",
    "write": "ಬರೆಯಿಸು",
    "download_template": "ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    "write_in_logbook_csv": ".CSV ಯಿಂದ ಲಾಗ್ ಪುಸ್ತಕದಲ್ಲಿ ಬರೆಯಿರಿ",
    "upload_file": "ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    "batch": "ದಡ",
    "move_batch": "ಬ್ಯಾಚ್ ಅನ್ನು ಸರಿಸಿ",
    "on": "ಮೇಲೆ",
    "off": "ತಟ್ಟಿಸು",
    "fast_mode": "ವೇಗದ ಕ್ರಮ",
    "batch_shipping_details": "ಬ್ಯಾಚ್ ಶಿಪ್ಪಿಂಗ್ ವಿವರಗಳು",
    "vehicle_no": "ವಾಹನ ಸಂಖ್ಯೆ",
    "net_weight": "ನಿವ್ವಳ",
    "actual_weight": "ನಿಜವಾದ ತೂಕ",
    "vendor": "ಮಾರಾಟಗಾರ",
    "loaded_by": "ನಿಂದ ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
    "verified_by": "ಮೂಲಕ ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
    "document_no": "ಡಾಕ್ಯುಮೆಂಟ್ ಇಲ್ಲ",
    "multiple_machine_param": "ಬಹು ಯಂತ್ರ ಮತ್ತು ಪ್ಯಾರಮ್‌ಗಳು",
    "map_paramid_paramequation_to_row": "ಪ್ಯಾರಮ್ ಐಡಿಗಳು ಅಥವಾ ಪ್ಯಾರಮ್ ಸಮೀಕರಣಗಳನ್ನು ಸಾಲಿಗೆ ನಕ್ಷೆ ಮಾಡಿ",
    "add_current_date_to_sheet": "ಪ್ರಸ್ತುತ ದಿನಾಂಕವನ್ನು ಶೀಟ್‌ಗೆ ಸೇರಿಸಿ",
    "create_parameter_from_template": "ಟೆಂಪ್ಲೇಟ್‌ನಿಂದ ನಿಯತಾಂಕವನ್ನು ರಚಿಸಿ",
    "selected_parameters_count": "ಆಯ್ದ ನಿಯತಾಂಕಗಳ ಎಣಿಕೆ",
    "restricted_user_access": "ನಿರ್ಬಂಧಿತ ಬಳಕೆದಾರ ಪ್ರವೇಶ",
    "select_all_parameters": "ಎಲ್ಲಾ ನಿಯತಾಂಕಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "edit_angular_gauge_widget": "ಕೋನೀಯ ಗೇಜ್ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "add_angular_gauge_widget": "ಕೋನೀಯ ಗೇಜ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "widget_label": "ವಿಜೆಟ್ ಲೇಬಲ್",
    "widget_width": "ವಿಜೆಟ್ ಅಗಲ",
    "widget_height": "ವಿಜೆಟ್ಟು",
    "param_label": "ಆಚರಣ",
    "choose_parameter": "ನಿಯತಾಂಕವನ್ನು ಆರಿಸಿ",
    "processing": "ಸಂಸ್ಕರಣೆ",
    "edit_banner_widget": "ಬ್ಯಾನರ್ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "add_banner_widget": "ಬ್ಯಾನರ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "edit_bartd_widget": "ಬಾರ್ ಟಿಡಿ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_bar_widget": "ಬಾರ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "yaxis_label": "ವೈ - ಆಕ್ಸಿಸ್ ಲೇಬಲ್",
    "xaxis_label": "X - ಅಕ್ಷದ ಲೇಬಲ್",
    "y1axis_label": "ವೈ 1 - ಆಕ್ಸಿಸ್ ಲೇಬಲ್",
    "y2axis_label": "ವೈ 2 - ಆಕ್ಸಿಸ್ ಲೇಬಲ್",
    "edit_dual_axis_bar_trend_widget": "ಡ್ಯುಯಲ್ ಆಕ್ಸಿಸ್ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_dual_axis_bar_trend_widget": "ಡ್ಯುಯಲ್ ಆಕ್ಸಿಸ್ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "y1_graph_type": "ವೈ 1 ಅಕ್ಷದ ಗ್ರಾಫ್ ಪ್ರಕಾರ",
    "y2_graph_type": "ವೈ 2 ಅಕ್ಷದ ಗ್ರಾಫ್ ಪ್ರಕಾರ",
    "idealValue": "ಆದರ್ಶ ಮೌಲ್ಯ",
    "idealLabel": "ಆದರ್ಶ ಲೇಬಲ್",
    "add_parameters": "ನಿಯತಾಂಕಗಳನ್ನು ಸೇರಿಸಿ",
    "add_equation": "ಸಮೀಕರಣವನ್ನು ಸೇರಿಸಿ",
    "add_params": "ಪ್ಯಾರಮ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
    "enter_of_machines": "ಯಂತ್ರಗಳನ್ನು ನಮೂದಿಸಿ",
    "edit_bar_trend_td_widget": "ಬಾರ್ ಟ್ರೆಂಡ್ ಟಿಡಿ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_bar_trend_td_widget": "ಬಾರ್ ಟ್ರೆಂಡ್ ಟಿಡಿ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "graph_type": "ಗ್ರಾಮದ ಪ್ರಕಾರ",
    "aggregation": "ಒಟ್ಟುಗೂಡಿಸುವಿಕೆ",
    "default_graph_type": "ಡೀಫಾಲ್ಟ್ ಗ್ರಾಫ್ ಪ್ರಕಾರ",
    "bar_mode": "ಗಡಿ ಕ್ರಮ",
    "show_ideal_line": "ಆದರ್ಶ ರೇಖೆಯನ್ನು ತೋರಿಸಿ",
    "equations": "ಸಮೀಕರಣಗಳು",
    "edit_bitwise_word_widget": "ಬಿಟ್‌ವೈಸ್ ವರ್ಡ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_bitwise_word_widget": "ಬಿಟ್‌ವೈಸ್ ವರ್ಡ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "show_bit_status": "ಬಿಟ್ ಸ್ಥಿತಿಯನ್ನು ತೋರಿಸಿ",
    "edit_bullet_gauge_widget": "ಬುಲೆಟ್ ಗೇಜ್ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "add_bullet_gauge_widget": "ಬುಲೆಟ್ ಗೇಜ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "reference_value_for": "ಗಾಗಿ ಉಲ್ಲೇಖ ಮೌಲ್ಯ",
    "gauge_type": "ಗೇಜ್ ಪ್ರಕಾರ",
    "indicator_color": "ಸೂಚಕ ಬಣ್ಣ",
    "busbar_temperature_graph_widget": "ಬಸ್ ಬಾರ್ ತಾಪಮಾನ ಗ್ರಾಫ್ ವಿಜೆಟ್",
    "busbar_module": "ಬಸ್ ಬಾರ್ ಮಾಡ್ಯೂಲ್",
    "edit_consumption_gauge_widget": "ಬಳಕೆ ಗೇಜ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_consumption_gauge_widget": "ಬಳಕೆ ಗೇಜ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "linearGauge": "ರೇಖಾ ಮಾಪಕ",
    "show_target": "ಗುರಿ ತೋರಿಸಿ",
    "healthy_on_below_target": "ಕೆಳಗಿನ ಗುರಿಯಲ್ಲಿ ಆರೋಗ್ಯಕರ",
    "edit_consumption_pie_widget": "ಬಳಕೆ ಪೈ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_consumption_pie_widget": "ಬಳಕೆ ಪೈ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "add_color_break_point": "ಬಣ್ಣ ಬ್ರೇಕ್ ಪಾಯಿಂಟ್ ಸೇರಿಸಿ",
    "custom_color": "ಕಸ್ಟಮ್ ಬಣ್ಣ",
    "color": "ಬಣ್ಣ",
    "edit_heat_map_widget": "ಶಾಖ ನಕ್ಷೆ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_heat_map__widget": "ಶಾಖ ನಕ್ಷೆ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "choose_equation": "ಸಮೀಕರಣವನ್ನು ಆರಿಸಿ",
    "label_star": "ಲೇಬಲ್*",
    "color_star": "ಬಣ್ಣ*",
    "operator1_star": "ಆಪರೇಟರ್ 1*",
    "value1_star": "ಮೌಲ್ಯ 1*",
    "operator2_star": "ಆಪರೇಟರ್ 2*",
    "actions": "ಕ್ರಿಯೆಗಳು",
    "condition": "ಷರತ್ತುಗಳು",
    "status_color": "ಸ್ಥಾನಮಾನದ ಬಣ್ಣ",
    "text_view": "ಪಠ್ಯ ವೀಕ್ಷಣೆ",
    "edit_process_Status_widget": "ಪ್ರಕ್ರಿಯೆಯ ಸ್ಥಿತಿ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_process_Status_widget": "ಪ್ರಕ್ರಿಯೆಯ ಸ್ಥಿತಿ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "edit_machine_Status_widget": "ಯಂತ್ರ ಸ್ಥಿತಿ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_machine_Status_widget": "ಯಂತ್ರ ಸ್ಥಿತಿ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "choose_process": "ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಆರಿಸಿ",
    "choose_machine": "ಯಂತ್ರಗಳನ್ನು ಆರಿಸಿ",
    "table_header_label": "ಮೇಜಿನ ಹೆಡರ್ ಲೇಬಲ್",
    "edit_table_TD_widget": "ಟೇಬಲ್ ಟಿಡಿ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "add_table_TD_widget": "ಟೇಬಲ್ ಟಿಡಿ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "configure_table": "ಕೋಷ್ಟಕವನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿ",
    "edit_target_based_trend_widget": "ಗುರಿ ಆಧಾರಿತ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_target_based_trend_widget": "ಗುರಿ ಆಧಾರಿತ ಪ್ರವೃತ್ತಿ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "target": "ಗುರಿ",
    "target_line_label": "ಟಾರ್ಗೆಟ್ ಲೈನ್ ಲೇಬಲ್",
    "edit_usage_score_card": "ಬಳಕೆಯ ಸ್ಕೋರ್‌ಕಾರ್ಡ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_usage_score_card": "ಬಳಕೆಯ ಸ್ಕೋರ್‌ಕಾರ್ಡ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "compare_to": "ಗೆ ಹೋಲಿಕೆ ಮಾಡಿ",
    "assign_color": "ಬಣ್ಣವನ್ನು ನಿಯೋಜಿಸಿ",
    "enter": "ಪ್ರವೇಶಿಸು",
    "gauge_to_dashboard": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗೆ ಗೇಜ್ ಮಾಡಿ",
    "widget_types": "ವಿಜೆಟ್ ಪ್ರಕಾರಗಳು",
    "from_value": "ಮೌಲ್ಯದಿಂದ",
    "to_value": "ಮೌಲ್ಯಕ್ಕೆ",
    "machine_status": "ಯಂತ್ರಪ್ರಮಾಣ",
    "deviceA_type": "ಸಾಧನವು ಒಂದು ಪ್ರಕಾರ",
    "templates": "ಟೆಂಪ್ಲೇಟು",
    "add_triggers_to_parameter": "ನಿಯತಾಂಕಕ್ಕೆ ಪ್ರಚೋದಕಗಳನ್ನು ಸೇರಿಸಿ",
    "triggers": "ಪ್ರಚೋದಕ",
    "show_filter": "ಫಿಲ್ಟರ್ ತೋರಿಸಿ",
    "view_id": "ಐಡಿ ವೀಕ್ಷಿಸಿ",
    "move_up": "ಮೇಲಕ್ಕೆ ಸರಿಸಿ",
    "move_down": "ಕೆಳಗೆ ಸರಿಸಿ",
    "multiplier_types": "Mulplier_types",
    "energy_report": "ಶಕ್ತಿ ವರದಿ",
    "add_machine_param": "ಯಂತ್ರ ಪ್ಯಾರಮ್ ಸೇರಿಸಿ",
    "aliasing": "ಅಲಿಯಾಸೆ",
    "no_zero_read": "ಶೂನ್ಯ ಓದುವುದಿಲ್ಲ",
    "remove_negative_entropy": "ನಕಾರಾತ್ಮಕ ಎಂಟ್ರೊಪಿಯನ್ನು ತೆಗೆದುಹಾಕಿ",
    "calculate_consumption": "ಬಳಕೆಯನ್ನು ಲೆಕ್ಕಹಾಕಿ",
    "disabled": "ನಿಷ್ಕ್ರಿಯ",
    "limiter_value": "ಮಿತಿಯ ಮೌಲ್ಯ",
    "machine_scroll_view_page": "ಯಂತ್ರ ಸ್ಕ್ರಾಲ್ ವೀಕ್ಷಣೆ ಪುಟ",
    "create_mannual_parameter": "ಮಾನವೀಯ ನಿಯತಾಂಕವನ್ನು ರಚಿಸಿ",
    "restrict_user_access": "ಬಳಕೆದಾರ ಪ್ರವೇಶವನ್ನು ನಿರ್ಬಂಧಿಸಿ",
    "add_device_parameter": "ಸಾಧನ ನಿಯತಾಂಕವನ್ನು ಸೇರಿಸಿ",
    "entropy": "ಎಳೆಯ",
    "idle_latency": "ಜಡ ಲೇಟೆತನ",
    "add_OPC_parameter": "ಒಪಿಸಿ ನಿಯತಾಂಕವನ್ನು ಸೇರಿಸಿ",
    "node_id": "ನೋಡ್ ಐಡಿ",
    "byte_format": "ಬೈಟ್ ಸ್ವರೂಪ",
    "param_scroll_view_page": "ಪ್ಯಾರಮ್ ಸ್ಕ್ರಾಲ್ ವ್ಯೂ ಪುಟ",
    "timeframed_sec_report": "ಸಮಯ ಚೌಕಟ್ಟಿನ ಎಸ್‌ಇಸಿ ವರದಿ",
    "prod_unit": "ಪ್ರೋಡೆಂಟ್ ಘಟಕ",
    "SEC_unit": "ಸೆಕೆಂಡು ಘಟಕ",
    "energy_parameters": "ಶಕ್ತಿ ನಿಯತಾಂಕಗಳು",
    "prod_param_type": "ಪ್ಯಾರಮ್ ಪ್ರಕಾರ",
    "production_parameters": "ಉತ್ಪಾದನಾ ನಿಯತಾಂಕಗಳು",
    "no_of_intervals": "ಮಧ್ಯಂತರಗಳು ಇಲ್ಲ",
    "granular_data": "ಹರಳುಮರತೆ",
    "csv": "ಸಿಎಸ್ವಿ",
    "delete_rule": "ನಿಯಮವನ್ನು ಅಳಿಸಿ",
    "enable_disable_all_parameters": "ಎಲ್ಲಾ ನಿಯತಾಂಕಗಳನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ/ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ",
    "disable": "ನಿಷ್ಪಲಿತಿ",
    "enable": "ಸಕ್ರಿಯಗೊಳಿಸು",
    "edit_computed_parameter": "ಕಂಪ್ಯೂಟೆಡ್ ನಿಯತಾಂಕವನ್ನು ಸಂಪಾದಿಸಿ",
    "quantization": "ಪ್ರಮಾಣೀಕರಣ",
    "command": "ಸ ೦ ತಾನು",
    "register_size": "ನೋಂದಣಿ ಗಾತ್ರ",
    "consumer_ID": "ಗ್ರಾಹಕ ಐಡಿ",
    "site_id": "ಸೈಟ್ ಐಡಿ",
    "get_data": "ಡೇಟಾ ಪಡೆಯಿರಿ",
    "update_param_type": "ಪ್ಯಾರಮ್ ಪ್ರಕಾರವನ್ನು ನವೀಕರಿಸಿ",
    "write_in_data_logbook": "ಡೇಟಾ ಲಾಗ್‌ಬುಕ್‌ನಲ್ಲಿ ಬರೆಯಿರಿ",
    "daily_data": "ದೈನಂದಿನ ಡೇಟಾ",
    "log_entry": "ಲಾಗ್ ಪ್ರವೇಶ",
    "enable_Scroll_mode": "ಸ್ಕ್ರಾಲ್ ಮೋಡ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
    "multiple_areas": "ಬಹು ಪ್ರದೇಶಗಳು",
    "time_of_day": "ದಿನದ ಸಮಯ",
    "capacity_logger": "ಸಾಮರ್ಥ್ಯ ಲಾಗರ್",
    "provider": "ಒದಗಿಸುವವನು",
    "total_sites_received_bills_MTD": "ಒಟ್ಟು ಸೈಟ್‌ಗಳು ಎಂಟಿಡಿ ಬಿಲ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸಿದವು",
    "sites_yet_to_receive_bills": "ಸೈಟ್‌ಗಳು ಇನ್ನೂ ಬಿಲ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸಲಿಲ್ಲ",
    "total_sites": "ಒಟ್ಟು ಸೈಟ್‌ಗಳು",
    "sites_received_bills_MTD": "%ಸೈಟ್‌ಗಳು ಎಂಟಿಡಿ ಬಿಲ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸಿದವು",
    "total": "ಒಟ್ಟು",
    "total_bills_received_mtd": "ಒಟ್ಟು ಮಸೂದೆಗಳು ಎಂಟಿಡಿ ಸ್ವೀಕರಿಸಿದವು",
    "arrear_1": "ಬಾಕಿ 1",
    "arrear_2": "ಬಾಕಿ 2",
    "no_reading": "ಓದುವಿಕೆ ಇಲ್ಲ",
    "bills_received_in_5_days": "ಕಳೆದ 5 ದಿನಗಳಲ್ಲಿ ಮಸೂದೆಗಳನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ",
    "site_wise_status": "ಸರಿ ಸ್ಥಿತಿಯನ್ನು ಹೊರತುಪಡಿಸಿ ಇತರ ಸೈಟ್ ಬುದ್ಧಿವಂತ ಸ್ಥಿತಿ",
    "site_wise_final_summary": "ಸೈಟ್ ಬುದ್ಧಿವಂತ ಅಂತಿಮ ಸಾರಾಂಶ",
    "source_link": "ಮೂಲ ಲಿಂಕ್",
    "data_type": "ದತ್ತಾಂಶಗಳ ಪ್ರಕಾರ",
    "select_an_image_to_upload": "ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಚಿತ್ರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "user_group_Id": "ಬಳಕೆದಾರರ ಗುಂಪು ID",
    "user_group_name": "ಬಳಕೆದಾರರ ಗುಂಪು ಹೆಸರು",
    "delay_in_mins": "(ನಿಮಿಷಗಳು) ವಿಳಂಬ",
    "delete_task": "ಕಾರ್ಯವನ್ನು ಅಳಿಸಿ",
    "watchers": "ವೀಕ್ಷಕ",
    "comments": "ಪ್ರತಿಕ್ರಿಯೆ",
    "asset_under_maintenance": "ನಿರ್ವಹಣೆಯ ಅಡಿಯಲ್ಲಿ ಆಸ್ತಿ",
    "start_time": "ಪ್ರಾರಂಭ ಸಮಯ",
    "end_time": "ಅಂತಿಮ ಸಮಯ",
    "download_latest_usage_table": "ಇತ್ತೀಚಿನ ಬಳಕೆಯ ಕೋಷ್ಟಕವನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    "tag": "ತ ೦ ತ್ರ",
    "add_target_data": "ಗುರಿ ಡೇಟಾವನ್ನು ಸೇರಿಸಿ",
    "edit_target": "ಗುರಿಯನ್ನು ಸಂಪಾದಿಸಿ",
    "delete_target": "ಗುರಿಯನ್ನು ಅಳಿಸಿ",
    "prop": "ಬ ೦ ದಿಸು",
    "excel_template_based_report": "ಎಕ್ಸೆಲ್ ಟೆಂಪ್ಲೇಟ್ ಆಧಾರಿತ ವರದಿ",
    "configured_hub": "ಕಾನ್ಫಿಗರ್ ಮಾಡಿದ ಹಬ್",
    "configured_relay": "ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾದ ರಿಲೇ",
    "desired_state": "ಅಪೇಕ್ಷಿತ ರಾಜ್ಯ",
    "configured_notification_group": "ಅಧಿಸೂಚನೆ ಗುಂಪು",
    "email_ids": "ಇಮೇಲ್ IDS",
    "logs": "ಪದರಗಳು",
    "unit_id": "ಯುನಿಟ್ ಐಡಿ",
    "download_report": "ವರದಿ ವರದಿ ಮಾಡಿ",
    "frequency": "ಆವರ್ತನ",
    "ip": "ಐಪಿ",
    "edit_wifi": "ವೈಫೈ ಸಂಪಾದಿಸಿ",
    "details": "ವಿವರಗಳು",
    "connected_to": "ಗೆ ಸಂಪರ್ಕಿಸಲಾಗಿದೆ",
    "software_version": "ಸಾಫ್ಟ್‌ವೇರ್ ಆವೃತ್ತಿಯ",
    "mac_address": "MAC ವಿಳಾಸ",
    "opc_client_enabled": "ಒಪಿಸಿ ಕ್ಲೈಂಟ್ ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
    "MQTT_bridge_enabled": "MQTT ಸೇತುವೆ ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
    "hub_specification": "ಹಬ್ ವಿವರಣೆ",
    "hub_details": "ಹಬ್ ವಿವರಗಳು",
    "restart_schedule": "ವೇಳಾಪಟ್ಟಿಯನ್ನು ಮರುಪ್ರಾರಂಭಿಸಿ",
    "restart_device": "ಸಾಧನವನ್ನು ಮರುಪ್ರಾರಂಭಿಸಿ",
    "set_up_restart_time": "ಮರುಪ್ರಾರಂಭಿಸಿ ಸಮಯವನ್ನು ಹೊಂದಿಸಿ",
    "connectivity_logs": "ಸಂಪರ್ಕ ದಾಖಲೆಗಳು",
    "internal_cache_size": "ಆಂತರಿಕ ಸಂಗ್ರಹ ಗಾತ್ರ",
    "default": "ಡಕ್ಟರ",
    "configs": "ಸಂರಚನೆಗಳು",
    "processed": "ಸಂಸ್ಕರಿಸಿದ",
    "space_available": "ಸ್ಥಳಾವಕಾಶ ಲಭ್ಯವಿದೆ",
    "kb": "ಕೆಬ",
    "mb": "ಎಂಬಿ",
    "last_ping": "ಕೊನೆಯ ಪಿಂಗ್",
    "toggle": "ಹಗೆರಿಸು",
    "configured_creds": "ಕಾನ್ಫಿಗರ್ ಮಾಡಿದ ಸಾಲಗಳು",
    "psk": "ಪಿಎಸಿಕೆ",
    "set_value": "ನಿಗದಿಪಡಿಸಿದ ಮೌಲ್ಯ",
    "time_current_time": "ಸಮಯ (ಪ್ರಸ್ತಾಪಿಸದಿದ್ದರೆ ಪ್ರಸ್ತುತ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲಾಗುತ್ತದೆ)",
    "Set": "ನಿಗದಿ",
    "health": "ಆರೋಗ್ಯ",
    "port": "ಬಂದರು",
    "mqtt_params": "Mqtt-params",
    "delete_device": "ಸಾಧನವನ್ನು ಅಳಿಸಿ",
    "view_details": "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
    "parameter_id": "ನಿಯತಾಂಕ ID",
    "email_address": "ಇಮೇಲ್ ವಿಳಾಸ",
    "attached_triggers": "ಲಗತ್ತಿಸಲಾದ ಪ್ರಚೋದಕಗಳು",
    "parameter_details": "ನಿಯತಾಂಕ ವಿವರಗಳು",
    "enable_write": "ಬರೆಯಲು ಸಕ್ರಿಯಗೊಳಿಸಿ",
    "disable_write": "ಬರೆಯಲು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ",
    "offset": "ಸರಿದೂಗಿಸು",
    "no_of_parameters": "ನಿಯತಾಂಕಗಳಿಲ್ಲ",
    "creation_time": "ಸೃಷ್ಟಿ ಸಮಯ",
    "not_configured": "ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ",
    "add_rule": "ನಿಯಮ ಸೇರಿಸಿ",
    "sl_no": "ಎಸ್ಎಲ್ ಇಲ್ಲ",
    "targeted_by_value": "ಮೌಲ್ಯದಿಂದ ಗುರಿಯಾಗಿದೆ",
    "configured_action": "ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾದ ಕ್ರಿಯೆ",
    "add_action": "ಕ್ರಿಯೆಯನ್ನು ಸೇರಿಸಿ",
    "action_id": "ಆಕ್ಷನ್ ಐಡಿ",
    "delete_user": "ಬಳಕೆದಾರರನ್ನು ಅಳಿಸಿ",
    "bit": "ಸ್ವಲ್ಪ",
    "low": "ಕಡಿಮೆ ಪ್ರಮಾಣದ",
    "high": "ಎತ್ತರದ",
    "esg_scope_conversion": "ಇಎಸ್ಜಿ ಸ್ಕೋಪ್ ಪರಿವರ್ತನೆ",
    "batch_production_logs": "ಬ್ಯಾಚ್ ಉತ್ಪಾದನಾ ದಾಖಲೆಗಳು",
    "esg_data_logs": "ಇಎಸ್ಜಿ ಡೇಟಾ ಲಾಗ್‌ಗಳು",
    "remove_from_dashboard": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ನಿಂದ ತೆಗೆದುಹಾಕಿ",
    "data_logs": "ಡೇಟಾ ಲಾಗ್‌ಗಳು",
    "select_device": "ಸಾಧನವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "fetch_connectivity_logs": "ಸಂಪರ್ಕ ಲಾಗ್‌ಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
    "hub_internet_connectivity_logs": "ಹಬ್ ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕ ಲಾಗ್‌ಗಳು",
    "modbus_gateways": "ಮೊಡ್‌ಬಸ್ ಗೇಟ್‌ವೇಸ್",
    "device_switched_on": "ಸಾಧನವನ್ನು ಆನ್ ಮಾಡಲಾಗಿದೆ",
    "device_switched_off": "ಸಾಧನವನ್ನು ಸ್ವಿಚ್ ಆಫ್ ಮಾಡಲಾಗಿದೆ",
    "parameter_report": "ನಿಯತಾಂಕ ವರದಿ",
    "timeframed_specific_energy_consumption_report": "ಟೈಮ್‌ಫ್ರಾಮ್ ನಿರ್ದಿಷ್ಟ ಶಕ್ತಿ ಬಳಕೆ ವರದಿ",
    "ebill_distribution_report": "ಇ-ಬಿಲ್ ವಿತರಣಾ ವರದಿ",
    "root_user_management": "ರೂಟ್ ಬಳಕೆದಾರ ನಿರ್ವಹಣೆ",
    "user_acces_management": "ಬಳಕೆದಾರ ಪ್ರವೇಶ ನಿರ್ವಹಣೆ",
    "inference": "ತೀರ್ಮಾನಿಸುವುದು",
    "turn_off": "ಆಫ್ ಮಾಡಿ",
    "turn_on": "ಆನ್ ಮಾಡಿ",
    "diagram_pallette": "ರೇಖಾಚಿತ್ರ ಪ್ಯಾಲೆಟ್",
    "add_component": "ಘಟಕವನ್ನು ಸೇರಿಸಿ",
    "components": "ಘಟಕಗಳು",
    "add_text": "ಪಠ್ಯವನ್ನು ಸೇರಿಸಿ",
    "add_realtime_data": "ನೈಜ ಸಮಯದ ಡೇಟಾವನ್ನು ಸೇರಿಸಿ",
    "shapes": "ಆಕಾರ",
    "lines": "ಮಾರ್ಗ",
    "text_realtime_component": "ಪಠ್ಯ ರಿಯಲ್ಟೈಮ್ ಘಟಕ",
    "shapes_pallette": "ಆಕಾರದ ಪ್ಯಾಲೆಟ್",
    "lines_pallette": "ರೇಖೆಗಳು",
    "click_choose": "ಎಸ್‌ಎಲ್‌ಡಿಗೆ ಸೇರಿಸಲು ಆಕಾರಗಳನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಆರಿಸಿ",
    "choose_line": "ಎಸ್‌ಎಲ್‌ಡಿಗೆ ಸೇರಿಸಲು ಸಾಲನ್ನು ಆರಿಸಿ",
    "choose_image": "ಚಿತ್ರವನ್ನು ಆರಿಸಿ",
    "upload_image_to_add": "ಎಸ್‌ಎಲ್‌ಡಿಗೆ ಸೇರಿಸಲು ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    "add_realtime_textdata": "ಎಸ್‌ಎಲ್‌ಡಿಗೆ ನೈಜ ಸಮಯ ಅಥವಾ ಪಠ್ಯ ಡೇಟಾವನ್ನು ಸೇರಿಸಿ",
    "update_user": "ಬಳಕೆದಾರರನ್ನು ನವೀಕರಿಸಿ",
    "add_watcher": "ವಾಚರ್ ಸೇರಿಸಿ",
    "premise_area": "ಪ್ರಮೇಯ ಪ್ರದೇಶ",
    "create_computed_param": "ಕಂಪ್ಯೂಟೆಡ್ ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "create_derived_param": "ಪಡೆದ ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "proceed_to_ship": "ಸಾಗಿಸಲು ಮುಂದುವರಿಯಿರಿ",
    "esg_data_collection": "ಇಎಸ್ಜಿ ಡೇಟಾ ಸಂಗ್ರಹಣೆ",
    "esg_scope_details": "ಇಎಸ್ಜಿ ವ್ಯಾಪ್ತಿ ವಿವರಗಳು",
    "select_custom_date_range": "ಕಸ್ಟಮ್ ದಿನಾಂಕ ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "days_365": "365 ದಿನಗಳು",
    "days_180": "180 ದಿನಗಳು",
    "days_30": "30 ದಿನಗಳು",
    "create_user_for_SSO": "SSO ಗಾಗಿ ಬಳಕೆದಾರರನ್ನು ರಚಿಸಿ",
    "add_widget": "ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "add_machine_status_rules": "ಯಂತ್ರ ಸ್ಥಿತಿ ನಿಯಮಗಳನ್ನು ಸೇರಿಸಿ",
    "add_trigger": "ಪ್ರಚೋದಕವನ್ನು ಸೇರಿಸಿ",
    "add_logged_parameter": "ಲಾಗ್ ಮಾಡಲಾದ ನಿಯತಾಂಕವನ್ನು ಸೇರಿಸಿ",
    "edit_logged_parameter": "ಲಾಗ್ ಮಾಡಲಾದ ನಿಯತಾಂಕವನ್ನು ಸಂಪಾದಿಸಿ",
    "create_mannual_param": "ಮ್ಯಾನ್ನುವಲ್ ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "add_opc_param": "ಒಪಿಸಿ ಪ್ಯಾರಮ್ ಸೇರಿಸಿ",
    "create_write_block": "ರೈಟ್ ಬ್ಲಾಕ್ ರಚಿಸಿ",
    "all_parameters": "ಎಲ್ಲಾ ನಿಯತಾಂಕಗಳು",
    "edit_device": "ಸಾಧನ ಸಂಪಾದಿಸಿ",
    "edit_modbus_device": "ಮೊಡ್‌ಬಸ್ ಸಾಧನವನ್ನು ಸಂಪಾದಿಸಿ",
    "edit_mqtt_device": "MQTT ಸಾಧನವನ್ನು ಸಂಪಾದಿಸಿ",
    "limit_entropy": "ಎಂಟ್ರೊಪಿಯನ್ನು ಮಿತಿಗೊಳಿಸಿ",
    "emails": "ಇಮೇಲ್‌ಗಳು",
    "power_factor": "ಪವರ್‌ಫ್ಯಾಕ್ಟರ್ (ಪಿಎಫ್)",
    "tarrif_rate": "ಸುಂಕ",
    "apparent_power": "ಸ್ಪಷ್ಟ ಶಕ್ತಿ",
    "reactive_power": "ಪ್ರತಿಕ್ರಿಯಾತ್ಮಕ ಶಕ್ತಿ",
    "active_power": "ಸಕ್ರಿಯ ಶಕ್ತಿ",
    "energy_usage_view_page": "ಶಕ್ತಿ ಬಳಕೆ ವೀಕ್ಷಣೆ ಪುಟ",
    "switch_organisation": "ಸ್ವಿಚ್ ಸಂಸ್ಥೆ",
    "wireless_creds": "ವೈರ್‌ಲೆಸ್ ಕ್ರೆಡಿಟ್‌ಗಳು",
    "latest": "ಇತ್ತೀಚಿನ",
    "value_is": "ಮೌಲ್ಯ",
    "please_take_necessary_action": "ದಯವಿಟ್ಟು ಅಗತ್ಯ ಕ್ರಮ ತೆಗೆದುಕೊಳ್ಳಿ",
    "edit_role": "ಪಾತ್ರವನ್ನು ಸಂಪಾದಿಸಿ",
    "delete_role": "ಪಾತ್ರವನ್ನು ಅಳಿಸಿ",
    "today": "ಇಂದು",
    "days_4": "4 ದಿನಗಳು",
    "due": "ಕಾರಣವಾದ",
    "move_to_done": "ಮುಗಿದಿದೆ",
    "power_system_fault": "ಪವರ್ ಸಿಸ್ಟಮ್ ಫಾಲ್ಟ್ ಎಣಿಕೆ (ಕಳೆದ ವಾರ)",
    "alarms": "ಎಚ್ಚರಿಕೆ",
    "electricity_consumption": "ವಿದ್ಯುತ್ ಬಳಕೆ (ಇಲ್ಲಿಯವರೆಗೆ ತಿಂಗಳು)",
    "average_demand": "ಸರಾಸರಿ ಬೇಡಿಕೆ",
    "water_use_intensity": "ನೀರಿನ ಬಳಕೆಯ ತೀವ್ರತೆ (ಡಬ್ಲ್ಯುಯುಐ)",
    "average_water_cost": "ಸರಾಸರಿ ನೀರಿನ ವೆಚ್ಚ (ಮಾಸಿಕ)",
    "water_use_efficiency": "ನೀರು ಬಳಕೆಯ ದಕ್ಷತೆ (WUE)",
    "batch_logs": "ಬ್ಯಾಚ್ ಲಾಗ್‌ಗಳು",
    "edit_log": "ಲಾಗ್ ಸಂಪಾದಿಸಿ",
    "delete_log": "ಲಾಗ್ ಅಳಿಸಿ",
    "dates": "ದಿನಾಂಕಗಳು",
    "log_details": "ಲಾಗ್ ವಿವರಗಳು",
    "view": "ನೋಟ",
    "scope1_emission": "ವ್ಯಾಪ್ತಿ 1 ಹೊರಸೂಸುವಿಕೆ",
    "scope2_emission": "ಸ್ಕೋಪ್ 2 ಹೊರಸೂಸುವಿಕೆ",
    "lmtd": "ಎಲ್ಎಂಟಿಡಿ",
    "company_data": "ಕಂಪನಿ ದತ್ತಾಂಶ",
    "historian": "ಇತಿಹಾಸಕಾರ",
    "from_optional": "(ಐಚ್ al ಿಕ)",
    "to_optional": "ಗೆ (ಐಚ್ al ಿಕ)",
    "trends": "ಪ್ರವೃತ್ತಿಗಳು",
    "generate_trend": "ಪ್ರವೃತ್ತಿಯನ್ನು ಉತ್ಪಾದಿಸಿ",
    "by": "ಯ ೦ ದ",
    "check_logs": "ಲಾಗ್‌ಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
    "workspaces": "ಕಾರ್ಯಕ್ಷೇತ್ರಗಳು",
    "types_of_nodes_connected": "ನೋಡ್‌ಗಳ ಪ್ರಕಾರಗಳು ಸಂಪರ್ಕಗೊಂಡಿವೆ",
    "types_of_modbus_device_connected": "ಮೋಡ್‌ಬಸ್ ಸಾಧನಗಳ ಪ್ರಕಾರಗಳು ಸಂಪರ್ಕಗೊಂಡಿವೆ",
    "no_of_registers": "ರೆಜಿಸ್ಟರ್‌ಗಳ ಇಲ್ಲ",
    "write_register": "ರಿಜಿಸ್ಟರ್ ಬರೆಯಿರಿ",
    "setting_view": "ಹೊಂದಿಸುವ ನೋಟ",
    "sign_up": "ಸೈನ್ ಅಪ್",
    "reset": "ಮರುಹೊಂದಿಸು",
    "mobile_number": "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
    "nebeskie_labs": "ನೆಬೆಸ್ಕಿ ಲ್ಯಾಬ್ಸ್",
    "please_register_and_send_otp": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ನೋಂದಾಯಿತ ಇಮೇಲ್ ID ಅನ್ನು ನಮೂದಿಸಿ ಮತ್ತು OTP ಕಳುಹಿಸು ಕ್ಲಿಕ್ ಮಾಡಿ.",
    "reset_password": "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ",
    "back_to_login": "ಲಾಗಿನ್‌ಗೆ ಹಿಂತಿರುಗಿ",
    "otp": "ಒತ್ತು",
    "send_otp": "ಒಟಿಪಿ ಕಳುಹಿಸಿ",
    "org_alias": "ಮೊಟ್ಟೆಯಿಡೆ",
    "please_stand_by": "ದಯವಿಟ್ಟು ನಿಂತುಕೊಳ್ಳಿ",
    "total_usage_this_month": "ಈ ತಿಂಗಳು ಒಟ್ಟು ಬಳಕೆ",
    "total_usage_last_month": "ಕಳೆದ ತಿಂಗಳು ಒಟ್ಟು ಬಳಕೆ",
    "generate_report_manually": "ವರದಿಯನ್ನು ಹಸ್ತಚಾಲಿತವಾಗಿ ರಚಿಸಿ",
    "energy_distribution_detailed_report": "ಶಕ್ತಿ ವಿತರಣೆ ವಿವರವಾದ ವರದಿ",
    "the_report_is_processing": "ವರದಿಯು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತಿದೆ",
    "enter_active_energy_manually": "ಸಕ್ರಿಯ ಶಕ್ತಿಯನ್ನು ಹಸ್ತಚಾಲಿತವಾಗಿ ನಮೂದಿಸಿ",
    "include_others": "ಇತರರನ್ನು ಸೇರಿಸಿ",
    "bill_amount": "ಬಿಲ್ ಮೊತ್ತ",
    "yes_delete_it": "ಹೌದು, ಅದನ್ನು ಅಳಿಸಿ",
    "create_write_param": "ಬರೆಯುವ ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "report_list": "ವರದಿ ಪಟ್ಟಿ",
    "plant_id": "ಪ್ಲಾಂಟ್ ಐಡಿ",
    "plant_name": "ಸಸ್ಯ ಹೆಸರು",
    "plant_address": "ಸಸ್ಯ ವಿಳಾಸ",
    "add_selected": "ಆಯ್ದ ಸೇರಿಸಿ",
    "clear_selection": "ಸ್ಪಷ್ಟ ಆಯ್ಕೆ",
    "real_time_value": "ನೈಜ ಸಮಯದ ಮೌಲ್ಯ",
    "default_status": "ಡೀಫಾಲ್ಟ್ ಸ್ಥಿತಿ",
    "default_label": "ಡೀಫಾಲ್ಟ್ ಲೇಬಲ್",
    "default_color_optional": "ಡೀಫಾಲ್ಟ್ ಬಣ್ಣ ಐಚ್ al ಿಕ",
    "rule_based_status": "ನಿಯಮ ಆಧಾರಿತ ಸ್ಥಿತಿ",
    "show_label_only": "ಲೇಬಲ್ ಅನ್ನು ಮಾತ್ರ ತೋರಿಸಿ",
    "show_unit": "ಪ್ರದರ್ಶನ ಘಟಕ",
    "status_rules": "ಸ್ಥಾನಮಾನದ ನಿಯಮಗಳು",
    "configure": "ಸಂರಚಿಸು",
    "monthly_usage": "ಮಾಸಿಕ ಬಳಕೆ",
    "meter_consumption_kwh": "ಮೀಟರ್ ಬಳಕೆ (kWh)",
    "total_unit": "ಒಟ್ಟು ಘಟಕ",
    "region": "ಪ್ರದೇಶ",
    "average_energy_consumed": "ಸರಾಸರಿ ಶಕ್ತಿಯನ್ನು ಸೇವಿಸಲಾಗುತ್ತದೆ",
    "average_production": "ಸರಾಸರಿ ಉತ್ಪಾದನೆ",
    "average_sec": "ಸರಾಸರಿ ಸೆ.",
    "total_production": "ಒಟ್ಟು ಉತ್ಪಾದನೆ",
    "total_energy_consumed": "ಒಟ್ಟು ಶಕ್ತಿಯನ್ನು ಸೇವಿಸಲಾಗುತ್ತದೆ",
    "max_sec": "ಗರಿಷ್ಠ ಸೆ.",
    "device_1": "ಸಾಧನ 1",
    "device_parameter_1": "ಸಾಧನ ನಿಯತಾಂಕ 1",
    "device_2": "ಸಾಧನ 2",
    "device_parameter_2": "ಸಾಧನ ನಿಯತಾಂಕ 2",
    "create_param": "ಪ್ಯಾರಮ್ ರಚಿಸಿ",
    "update_tags": "ಟ್ಯಾಗ್‌ಗಳನ್ನು ನವೀಕರಿಸಿ",
    "remove_from_process": "ಪ್ರಕ್ರಿಯೆಯಿಂದ ತೆಗೆದುಹಾಕಿ",
    "machine_parameters": "ಯಂತ್ರ ನಿಯತಾಂಕಗಳು",
    "energy": "ಶಕ್ತಿ",
    "power": "ಅಧಿಕಾರ",
    "remove_from_featured": "ವೈಶಿಷ್ಟ್ಯದಿಂದ ತೆಗೆದುಹಾಕಿ",
    "other_parameters": "ಇತರ ನಿಯತಾಂಕಗಳು",
    "add_to_featured": "ವೈಶಿಷ್ಟ್ಯವನ್ನು ಸೇರಿಸಿ",
    "logged_parameters": "ಲಾಗ್ ಮಾಡಲಾದ ನಿಯತಾಂಕಗಳು",
    "tags": "ತಗ್ಗು",
    "machine_status_rules": "ಯಂತ್ರ ಸ್ಥಿತಿ ನಿಯಮಗಳು",
    "connected_communication_devices": "ಸಂಪರ್ಕಿತ ಸಂವಹನ ಸಾಧನಗಳು",
    "mqtt_devices": "MQTT ಸಾಧನಗಳು",
    "modbus_devices": "ಮೊಡ್‌ಬಸ್ ಸಾಧನಗಳು",
    "total_no_of_params": "ಒಟ್ಟು ಪ್ಯಾರಮ್‌ಗಳ ಇಲ್ಲ",
    "edge_devices": "ಅಂಚಿನ ಸಾಧನಗಳು",
    "machines_configured": "ಯಂತ್ರಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿದೆ",
    "reports_configured": "ವರದಿಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿದೆ",
    "views_configured": "ವೀಕ್ಷಣೆಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿದೆ",
    "send_email_notification": "ಇಮೇಲ್ ಅಧಿಸೂಚನೆಯನ್ನು ಕಳುಹಿಸಿ",
    "enture_send_email_notification": "ಆಯ್ದ ಬಳಕೆದಾರ ಗುಂಪಿಗೆ ಇಮೇಲ್ ಅಧಿಸೂಚನೆಯನ್ನು ಕಳುಹಿಸೋಣ",
    "agree_and_send": "ಒಪ್ಪುತ್ತೇನೆ ಮತ್ತು ಕಳುಹಿಸಿ",
    "ebill": "ಉಪಾಯ",
    "monthly_trend": "ಮಾಸಿಕ ಪ್ರವೃತ್ತಿ",
    "param_view": "ಪ್ಯಾರಮ್ ವ್ಯೂ",
    "calories": "ಕಲಿ",
    "param_count_view": "ಪ್ಯಾರಮ್ ಎಣಿಕೆ ವೀಕ್ಷಣೆ",
    "created": "ರಚಿಸಿದ",
    "api_data_count_view": "API ಡೇಟಾ ಎಣಿಕೆ ವೀಕ್ಷಣೆ",
    "diesel_emissions": "ಡೀಸೆಲ್ ಹೊರಸೂಸುವಿಕೆ",
    "eb_emissions": "ಇಬಿ ಹೊರಸೂಸುವಿಕೆ",
    "emissions_kg_co2": "ಹೊರಸೂಸುವಿಕೆ ಕೆಜಿ-ಕೋ 2",
    "kpis": "ಕೆಪಿಐಗಳು",
    "jan_23": "ಜನವರಿ -23",
    "feb_23": "ಫೆಬ್ರವರಿ -23",
    "mar_23": "ಮಾರ್ಚ್ -23",
    "apr_23": "ಏಪ್ರಿಲ್ -23",
    "may_23": "ಮೇ -23",
    "jun_23": "ಜೂನ್ -23",
    "lpg_emissions": "ಎಲ್ಪಿಜಿ ಹೊರಸೂಸುವಿಕೆ",
    "petrol_emissions": "ಪೆಟ್ರೋಲ್ ಹೊರಸೂಸುವಿಕೆ",
    "bullet_gauge": "ಗುಂಡು -ಮಾಪಕ",
    "equipment": "ಉಪಕರಣ",
    "enter_no_of_machines": "ಯಂತ್ರಗಳನ್ನು ನಮೂದಿಸಿ",
    "shift": "ಸ್ಥಳಾಂತರ",
    "upload": "ಉರುಳಿಸು",
    "image_upload": "ಚಿತ್ರ ಅಪ್‌ಲೋಡ್",
    "derived_parameter": "ಪಡೆದ ನಿಯತಾಂಕ",
    "value_type": "Value_type",
    "enable_entropy": "ಎಂಟ್ರೊಪಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
    "remove_decimal": "ದಶಮಾಂಶ ತೆಗೆದುಹಾಕಿ",
    "limiter_entropy": "ಮಿತಿಮೀರಿದ ಎಂಟ್ರೊಪಿ",
    "show_sku_id": "SKU ID ತೋರಿಸಿ",
    "production_start_date": "ಉತ್ಪಾದನಾ ಪ್ರಾರಂಭ ದಿನಾಂಕ (ಐಚ್ al ಿಕ)",
    "production_start_time": "ಉತ್ಪಾದನಾ ಪ್ರಾರಂಭ ಸಮಯ (ಐಚ್ al ಿಕ)",
    "time": "ಕಾಲ",
    "temperature_c": "ತಾಪಮಾನ (ಸಿ)",
    "set_temperature_c": "ತಾಪಮಾನವನ್ನು ಹೊಂದಿಸಿ (ಸಿ)",
    "no_of_people": "ಜನರ ಇಲ್ಲ",
    "co2_level": "CO2 ಮಟ್ಟ",
    "aqi_optional": "ಎಕ್ಯೂಐ ಐಚ್ al ಿಕ",
    "delete_sheets": "ಹಾಳೆಗಳನ್ನು ಅಳಿಸಿ",
    "root_login": "ರೂಟ್ ಲಾಗಿನ್",
    "uam_login": "ಯುಎಎಂ ಲಾಗಿನ್",
    "login": "ಲಗಾಟಿಸು",
    "add_oem_logo": "ಒಇಎಂ ಲೋಗೋ ಸೇರಿಸಿ",
    "devices": "ಸಾಧನಗಳು",
    "derived_data_units": "ಪಡೆದ/ಡೇಟಾ ಘಟಕಗಳು",
    "roles": "ಪಾತ್ರಗಳು",
    "engineering": "ಎಂಜಿನಿಯರಿಂಗ್",
    "control_panel": "ನಿಯಂತ್ರಣ ಫಲಕ",
    "views": "ವೀಕ್ಷಣೆಗಳು",
    "reports_analysis": "ವರದಿಗಳು ಮತ್ತು ವಿಶ್ಲೇಷಣೆ",
    "actions_triggers": "ಕ್ರಿಯೆಗಳು ಮತ್ತು ಪ್ರಚೋದಕಗಳು",
    "modules": "ರೂಪಾಂತರ",
    "processes": "ಈಡಂದು",
    "air_quality": "ಗಾಳಿಯ ಗುಣಮಟ್ಟ",
    "manufacturing": "ಉತ್ಪಾದನೆ",
    "esg": "ಇಎಸ್ಜಿ",
    "task_managment": "ಕಾರ್ಯ ನಿರ್ವಹಣೆ",
    "sustainability": "ಸುಸ್ಥಿರತೆ",
    "groups": "ಗುಂಪುಗಳು",
    "log_book": "ಲಾಗ್ ಪುಸ್ತಕ",
    "monitor": "ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡು",
    "enture_settings": "ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಪಡೆಯಿರಿ",
    "configuration": "ಸಂರಚನೆ",
    "bar": "ಪಟ್ಟು",
    "line": "ರೇಖೆ",
    "s7_device": "ಎಸ್ 7 ಸಾಧನ",
    "rack": "Rackರು",
    "slot": "ಸ್ಲ",
    "db_number": "ಡಿಬಿ ಸಂಖ್ಯೆ",
    "byte_offset": "ಬೈಟ್ ಆಫ್‌ಸೆಟ್",
    "bit_offset": "ಬಿಟ್ ಆಫ್‌ಸೆಟ್",
    "data_length": "ದತ್ತಾಂಶ ಉದ್ದ",
    "val_type": "ಮೌಲ್ಯಮಾಪೆ",
    "create_s7_device": "ಎಸ್ 7 ಸಾಧನವನ್ನು ಸೇರಿಸಿ",
    "scope_label": "ಸ್ಕೋಪ್",
    "client_safety": "ಗ್ರಾಹಕ ಸುರಕ್ಷತೆ",
    "edit": "ಸಂಪಾದಿಸು",
    "delete": "ಅಳಿಸು",
    "choose_param_to_monitor": "ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಪ್ಯಾರಮ್ ಆಯ್ಕೆಮಾಡಿ",
    "rules": "ನಿಯಮಗಳು",
    "add_to_row": "ಸಾಲಿಗೆ ಸೇರಿಸಿ",
    "add_usage": "ಬಳಕೆಯನ್ನು ಸೇರಿಸಿ",
    "notifications": "ಅಧಿಸೂಚನೆಗಳು",
    "organisation_details": "ಸಂಸ್ಥೆಯ ವಿವರಗಳು",
    "profile": "ಪ್ರಚಾರ",
    "user_access": "ಬಳಕೆದಾರರ ಪ್ರವೇಶ",
    "admin": "ನಿರ್ವಾಹಕ",
    "shifts": "ವರ್ಗಾವಣೆ",
    "add_pie_TD_widget": "ಪೈ ಟಿಡಿ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "edit_pie_TD_widget": "ಪೈ ಟಿಡಿ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "legend": "ದಂತಕಥೆ",
    "pie": "ಹಳ್ಳ",
    "edit_bar_trend_widget": "ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಸಂಪಾದಿಸಿ",
    "add_bar_trend_widget": "ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "edit_horizantal_bar_trend_widget": "ಸಮತಲ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_horizantal_bar_trend_widget": "ಸಮತಲ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "too_long": "ತುಂಬಾ ಉದ್ದವಾಗಿದೆ",
    "trigger_logs": "ಲಾಗ್‌ಗಳನ್ನು ಪ್ರಚೋದಿಸಿ",
    "create_trigger": "ಪ್ರಚೋದಕವನ್ನು ರಚಿಸಿ",
    "right_click_to_configure": "ಕಾನ್ಫಿಗರ್ ಮಾಡಲು ಬಲ ಕ್ಲಿಕ್ ಮಾಡಿ",
    "width": "ಅಗಲ",
    "height": "ಎತ್ತರ",
    "hide_value": "ಮೌಲ್ಯವನ್ನು ಮರೆಮಾಡಿ",
    "edit_machine": "ಯಂತ್ರ ಸಂಪಾದಿಸಿ",
    "wait_time": "ಸಮಯ ಸಮಯ",
    "production_date": "ಉತ್ಪಾದಾ ದಿನ",
    "connectivity_status": "ಸಂಪರ್ಕದ ಸ್ಥಿತಿ",
    "production_plans": "ಉತ್ಪಾದನಾ ಯೋಜನೆಗಳು",
    "production_pending": "ಉತ್ಪಾದನೆ ಬಾಕಿ ಉಳಿದಿದೆ",
    "pending_shipment": "ಸಾಗಣೆ ಬಾಕಿ ಉಳಿದಿದೆ",
    "trace_logs": "ಲಾಗ್‌ಗಳನ್ನು ಪತ್ತೆಹಚ್ಚಿ",
    "movement_events": "ಚಲನೆಯ ಘಟನೆಗಳು",
    "kgco2": "ಕೆಜಿಸಿಒ 2",
    "entropy_threshold": "ಎಂಟ್ರೊಪಿ ಮಿತಿ",
    "threshold_value": "ಮಿತಿ ಮೌಲ್ಯ",
    "pending_dispatch": "ರವಾನೆ ಬಾಕಿ ಉಳಿದಿದೆ",
    "dispatched": "ರವಾನಿಸಿದ",
    "dispatch_details": "ವಿವರಗಳನ್ನು ರವಾನಿಸಿ",
    "dispatch": "ರವಾನಿಸು",
    "raw_materials": "ಕಚ್ಚಾ ವಸ್ತುಗಳು",
    "pour_qty": "ಪ್ರಮಾಣವನ್ನು ಸುರಿಯಿರಿ",
    "summary": "ಸಂಕ್ಷಿಪ್ತ",
    "optional": "ಐಚ್alಿಕ",
    "param_mqtt_map": "PARM MQTT ನಕ್ಷೆ",
    "machine_status_mqtt_map": "ಯಂತ್ರ ಸ್ಥಿತಿ MQTT ನಕ್ಷೆ",
    "edit_trigger_count_table_widget": "ಪ್ರಚೋದಕ ಎಣಿಕೆ ಟೇಬಲ್ ವಿಜೆಟ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    "add_trigger_count_table_widget": "ಪ್ರಚೋದಕ ಎಣಿಕೆ ಟೇಬಲ್ ವಿಜೆಟ್ ಸೇರಿಸಿ",
    "count": "ಎಣಿಸು",
    "real_time_consumption": "ನೈಜ ಸಮಯದ ಬಳಕೆ",
    "search_view": "ಹುಡುಕಾಟ ವೀಕ್ಷಣೆ",
    "multiple_entries": "ಬಹು ನಮೂದುಗಳು",
    "combined_parameter": "ಸಂಯೋಜಿತ ನಿಯತಾಂಕ",
    "background_color": "ಹಿನ್ನೆಲೆ",
    "resize": "ಮರುಗೋಲು",
    "remove": "ತೆಗೆದುಹಾಕು",
    "select_views": "ವೀಕ್ಷಣೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "difference": "ವ್ಯತ್ಯಾಸ",
    "sum": "ಮೊತ್ತ",
    "set_param_type_for_machine": "ಯಂತ್ರಕ್ಕಾಗಿ ಪ್ಯಾರಮ್ ಪ್ರಕಾರವನ್ನು ಹೊಂದಿಸಿ",
    "y1axis": "ವೈ 1 ಅಕ್ಷ",
    "y2axis": "ವೈ 2 ಅಕ್ಷ",
    "temperature_parameters": "ತಾಪಮಾನದ ನಿಯತಾಂಕಗಳು",
    "area_name": "ಪ್ರದೇಶದ ಹೆಸರು",
    "maintenance_due_on": "ನಿರ್ವಹಣೆ ಬಾಕಿ",
    "days_left": "ದಿನಗಳು ಉಳಿದಿವೆ",
    "enture_insights": "ಸಾಹಸೋದ್ಯಮ ಒಳನೋಟಗಳು",
    "reports": "ವರದಿಗಳು",
    "till_date_pie_chart": "ದಿನಾಂಕದವರೆಗೆ ಪೈ ಚಾರ್ಟ್",
    "till_date_bar_chart": "ದಿನಾಂಕ ಬಾರ್ ಚಾರ್ಟ್ ವರೆಗೆ",
    "tod_billing_widget": "ಟಾಡ್ ಬಿಲ್ಲಿಂಗ್ ವಿಜೆಟ್",
    "bar_trend_widget": "ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್",
    "target_based_bar_trend_widget": "ಟಾರ್ಗೆಟ್ ಆಧಾರಿತ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್",
    "gauge_widget": "ಗೇಜ್ ವಿಜೆಟ್",
    "consumption_pie_widget": "ಬಳಕೆ ಪೈ ವಿಜೆಟ್",
    "consumption_gauge_widget": "ಬಳಕೆ ಗೇಜ್ ವಿಜೆಟ್",
    "angular_gauge_widget": "ಕೋನೀಯ ಗೇಜ್ ವಿಜೆಟ್",
    "table_widget": "ಮೇಜಿನ ವಿಜೆಟ್",
    "banner_widget": "ಬ್ಯಾನರ್ ವಿಜೆಟ್",
    "param_equation_banner_widget": "ಪ್ಯಾರಮ್ ಸಮೀಕರಣ ಬ್ಯಾನರ್ ವಿಜೆಟ್",
    "shift_based_usage_table": "ಶಿಫ್ಟ್ ಆಧಾರಿತ ಬಳಕೆ ಕೋಷ್ಟಕ",
    "bitwise_word_widget": "ಬಿಟ್ವೈಸ್ ವರ್ಡ್ ವಿಜೆಟ್",
    "dynamic_log_widget": "ಡೈನಾಮಿಕ್ ಲಾಗ್ ವಿಜೆಟ್",
    "machine_status_timeline_widget": "ಯಂತ್ರ ಸ್ಥಿತಿ ಟೈಮ್‌ಲೈನ್ ವಿಜೆಟ್",
    "process_or_machine_status_widget": "ಪ್ರಕ್ರಿಯೆ/ಯಂತ್ರ ಸ್ಥಿತಿ ವಿಜೆಟ್",
    "parameter_status_based_usage_status": "ಪ್ಯಾರೈಟರ್‌ಸ್ಟಾಟಸ್ಬೇಸ್ಡ್ಜೆಟ್",
    "parameter_insights_widget": "ನಿಯತಾಂಕ ಒಳನೋಟಗಳು ವಿಜೆಟ್",
    "heat_map": "ಉಷ್ಣ ನಕ್ಷೆ",
    "usage_score_card_widget": "ಬಳಕೆಯ ಸ್ಕೋರ್ ಕಾರ್ಡ್ ವಿಜೆಟ್",
    "busbar_live_monitoring": "ಬಸ್ಬಾರ್ ಲೈವ್ ಮಾನಿಟರಿಂಗ್",
    "bullet_or_angular_guage_widget": "ಬುಲೆಟ್/ಕೋನೀಯ ಗೇಜ್ ವಿಜೆಟ್",
    "dual_axis_bar_trend_widget": "ಡ್ಯುಯಲ್ ಆಕ್ಸಿಸ್ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್",
    "trigger_count_table_widget": "ಎಣಿಕೆ ಟೇಬಲ್ ವಿಜೆಟ್ ಅನ್ನು ಪ್ರಚೋದಿಸಿ",
    "bar_trend_td_widget": "ಬಾರ್ ಟ್ರೆಂಡ್ ಟಿಡಿ ವಿಜೆಟ್",
    "horizantal_bar_trend_widget": "ಸಮತಲ ಬಾರ್ ಟ್ರೆಂಡ್ ವಿಜೆಟ್",
    "choose_from_templates": "ಆಯ್ಕೆ_ಫ್ರಾಮ್_ಟೆಂಪ್ಲೇಟ್‌ಗಳು",
    "shift_timeframed_trends": "ಶಿಫ್ಟ್ ಆಧಾರಿತ ಟೈಮ್‌ಫ್ರೇಮ್ಡ್ ಟ್ರೆಂಡ್‌ಗಳು",
    "candlestick_trends": "ಕ್ಯಾಂಡಲ್ ಸ್ಟಿಕ್ ಪ್ರವೃತ್ತಿಗಳು",
    "free_ram": "Free RAM",
    "item": "Item",
    "item_code": "Item Code",
    "department": "Department",
    "departments": "Departments",
    "issue_vouchers": "Issue Vouchers",
    "issue_voucher": "Issue Voucher",
    "to_be_approved": "To Be Approved",
    "to_be_issued": "To Be Issued",
    "intended": "Intended",
    "approved": "Approved",
    "received": "Received",
    "receiver": "Receiver",
    "material": "material",
    "material_group": "material group",
    "material_desc": "Material Description",
    "uom": "Unit Of Measurement",
    "create_item_code": "Create Item Code",
    "create_department": "Create Department",
    "users": "Users",
    "items": "Items",
    "users_in_department": "Users in Department",
    "authorisers_in_department": "Authorisers in Department",
    "issuers_in_department": "Issuers in Department",
    "item_issuer_list": "Item Issuer List",
    "add_item_issuer": "Add Item Issuer",
    "store": "Store",
    "create_issue_voucher": "Create Issue Voucher",
    "voucher_requests": "Voucher Requests",
    "intended_qty": "Intended Qty",
    "issued_qty": "Issued Qty",
    "issue_items": "Issue Items",
    "voucher_receipt": "Voucher Receipt",
    "add_stock": "Add Stock",
    "attached_targets": "Attached Targets",
    "attach_target": "Attach Target",
    "add_target_to_parameter": "Add Target to Parameter",
    "empty": "Empty",
    "add_day_wise_target": "Add Day wise Target",
    "day_wise_targets": "Day Wise Targets",
    "fetch_targets": "Fetch Targets",
    "day_wise_machine_status_widget": "ದಿನನೀತಿ ಯಂತ್ರ ಸ್ಥಿತಿ ವಿಶೆಟ್",
    "add_day_wise_machine_status_widget": "ದಿನನೀತಿ ಯಂತ್ರ ಸ್ಥಿತಿ ವಿಶೆಟ್ ಸೇರಿಸು",
    "edit_day_wise_machine_status_widget": "ದಿನನೀತಿ ಯಂತ್ರ ಸ್ಥಿತಿ ವಿಶೆಟ್ ಸಂಪಾದಿಸು",
    "add_machines": "ಯಂತ್ರಗಳನ್ನು ಸೇರಿಸು",
    "added_machines": "ಸೇರಿಸಿದ ಯಂತ್ರಗಳು",
    "consumption_start_time":"Consumption Start time",



}
<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <v-card flat  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-card-title class="headline">
          {{dashboardPanel? $store.getters.getTextMap().add_panel_to_dashboard :$store.getters.getTextMap().edit_dashboard_panel}}
        </v-card-title>
        <v-card-text>
            <v-form
            ref="panelForm"
            v-model="valid"           
        >
        <v-row >
                  <v-col >
                        <v-select
                            v-if="!loading"
                            v-model="param_type"
                            :items="param_types"
                            :label="$store.getters.getTextMap().param_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                  <v-col v-if="param_type!='combo'">
                    <v-select
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                  </v-col>
                    <v-col v-if="form.param_type!='combo'">
                        <v-select
                            v-if="!loading"
                            v-model="dev"
                            :items="deviceOptions"
                            :label="$store.getters.getTextMap().device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-if="!loading"
                            v-model="form.param"
                            :items="paramOptions"
                            :label="$store.getters.getTextMap().param"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :counter="30"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="param_type=='parameter'">
                        <v-select
                            v-if="!loading"
                            v-model="form.type"
                            :items="types"
                            :label="$store.getters.getTextMap().type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.type!='current'">
                        <v-select
                            v-if="!loading"
                            v-model="form.delta"
                            :items="durationOptions"
                            :label="$store.getters.getTextMap().time_duration"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-form>
                </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().submit }}
          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().cancelbtnColor"
        
            @click="close"
          >
          {{ $store.getters.getTextMap().close }}
          </v-btn>
        </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'AddPanelToDashboard',
    props:['dashboardPanel'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.dashboardPanel){
            this.edit=true
            this.param_type=this.dashboardPanel.param_type
            this.form=Object.assign({},this.dashboardPanel)
            //this.dev=this.$store.getters.getParameterById()
            let g=null
            if(this.param_type=='parameter'){
              g=this.$store.getters.getParameterById(this.form.param)
              
              }else if(this.param_type=='computed'){
                  g=this.$store.getters.getComputedParameterById(this.form.param)
                  
              }
              //console.log(g)
              this.dev=this.$store.getters.getDeviceById(g.device_id)
              this.workspace=this.$store.getters.getWorkspaceById(this.dev.workspace_id)
            this.api='updateDashboardPanel'
        }else{
            this.api='addPanelToDashboard'
        }
    },
    data(){
        return {
            edit:false,
             showDismissibleAlert:false,
            info:'',
            loading:false,
            workspace:null,
            dev:null,
            valid:false,
            param_type:null,
            form:{
                name:null,
                param_type:null,
                type:'usage',
                delta:null,
                sequence:0
            },
            api:'addPanelToDashboard',
            types:[
                {label:this.$store.getters.getTextMap().usage, value:'usage'},
                {label:this.$store.getters.getTextMap().peak_value, value:'max'},
                {label:this.$store.getters.getTextMap().current_data, value:'current'}
            ],
            param_types:[
                {label:this.$store.getters.getTextMap().parameter,value:'parameter'},
                {label:this.$store.getters.getTextMap().computed_param,value:'computed'},
            ],
            nameRules: [
                v => !!v || this.$store.getters.getTextMap().name_is_required,
                v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
                v => (v && v.length <= 30 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_30_characters,
            ],
            objectRules:[
                v => !!v || this.$store.getters.getTextMap().required,
            ],
        }
    },
    computed:{
        durationOptions(){
            if(this.form.type!='current'){
                return   [
                {label:this.$store.getters.getTextMap().last_7_days,value:7},
                {label:this.$store.getters.getTextMap().last_30_days,value:30},
                {label:this.$store.getters.getTextMap().last_90_days,value:90},
            ]
            }else{
                return [
                {label:this.$store.getters.getTextMap().last_1_hour,value:1*60*60},
                {label:this.$store.getters.getTextMap().last_3_hours,value:3*60*60},
                {label:this.$store.getters.getTextMap().last_6_hours,value:6*60*60},
                ]
            }
        },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.dev && this.dev.device_id ){
              let g=[]
              if(this.param_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.dev.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.param_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.dev.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
              
          }
          return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.panelForm.validate()
            if(this.valid){
                axios.post(this.$store.state.api+this.api,this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$store.dispatch('refreshDashboardPanels')
          this.loading=false
          this.close()
      }else{
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }

        },
        close(){
            //this.$refs.panelForm.reset()
            this.dialog=false
            this.loading=false
            this.form.type='usage'
            this.$emit('close')
            
            
        }
    },
    watch:{
        param_type:  function (v){
            if(!this.edit){
            this.form.param_type=v
           this.form.type='usage'
           this.form.param=null
           this.form.delta=null
            }else{
                this.edit=false
            }
         
        }
    }
}
</script>
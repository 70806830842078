<template>
    <v-dialog
      v-model="show"
      
      max-width="80%"
    >
    <v-form
    ref="groupForm"
    v-model="valid"
    
  >
  <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-card-text>
      <v-container fluid>
          <v-col>
              
                <v-select
                
              v-model="workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          outlined
          item-text="label"
          item-value="value"
          required
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            :rules="objectRules"
        ></v-select>
            </v-col>
            
            <v-col>
              
                <v-autocomplete
                
              v-model="machine"
          :items="machineOptions"
          :label="$store.getters.getTextMap().machine"
          outlined
          item-text="label"
          item-value="value"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          required
          
            :rules="objectRules"
        ></v-autocomplete>
            </v-col>
            <v-col>
              
                <v-autocomplete
                
              v-model="param"
          :items="paramOptions"
          :label="$store.getters.getTextMap().parameters"
          outlined
          item-text="label"
          item-value="value"
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          required
          :multiple="multiple"
            :rules="objectRules"
        ></v-autocomplete>
            </v-col>
            <v-row v-if="multiple">
                <v-col v-bind:key="p" v-for="p in param">
                    <v-chip
                        class="ma-2"
                        color="teal"
                        text-color="white"
                        close
                        small
                        @click:close="removeFromList(p)"
                        >
                        {{$store.getters.getNameByParamId(p)}}
                      </v-chip>
                </v-col>
            </v-row>
      </v-container>
      </v-card-text>
    <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}
          </v-btn>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
  </v-card>
    </v-form>
    </v-dialog>
</template>
<script>
import axios from 'axios'
export default {
    name:'GetParameterModal',
    props:['show','multiple','params'],
    data(){
        return {
            valid:false,
            machine:null,
            workspace:null,
            param:null,
            machineOptions:[],
            paramOptions:[],
            form:{},
            objectRules:[
                v => !!v || 'Required',
              ],
        }
    },
    mounted(){
        if(this.params){
            this.param=this.params
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        
        
    },
    methods:{
        removeFromList(param){
            this.param=this.param.filter(data=>{ return data != param })
        },
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                this.$emit('value',this.param)
                if(!this.params){
                    this.param=null
                }
                //this.param=null
                this.$emit('close')
            }

        },
        onReset(){
            this.$emit('close')
        },
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        
    }
}
</script>
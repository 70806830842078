<template>
    <v-container fluid>  
        <div :style="[type==='active_energy'||type==='apparent_energy'||type==='pf'||type==='active_power'||type==='reactive_power'||type==='tariff' ? {'font-size':font_size,'color':fontColor}:{}]" class="usage"><span>{{ usage }}</span> <span class="unit">{{ unit }}</span></div>
    </v-container>
</template>

<script>
// import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'

export default {
    name:'SingleParamTDUsage',
    props:['param','time','type','meta','tariff','color'],
    components:{
    },
    data(){
        return {
            graphData:{},
            week:{},
            fontColor:'lightgreen',
            font_size:'150%',
            unit:'',
            usage:0,
            z_axis_data:null,
            week_x_axis:[],
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    mounted(){
        this.init()
        
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      init(){

        if(this.param){
            switch(this.type){
                case 'active_energy':
                    this.unit = 'kWH'
                    this.fontColor=this.color?this.color:this.$store.getters.getColorPalette().accentCode
                    break;
                case 'apparent_energy':
                    this.unit = 'kVAH'
                    this.fontColor=this.color?this.color:this.$store.getters.getColorPalette().accentCode
                    break;
                case 'pf':
                    this.unit = ''
                    this.fontColor=this.color?this.color:this.$store.getters.getColorPalette().accentCode
                    break;
                case 'active_power':
                    this.unit = 'kW'
                    this.fontColor=this.color?this.color:this.$store.getters.getColorPalette().accentCode
                    break;
                case 'reactive_power':
                    this.unit = 'kVAR'
                    this.fontColor=this.color?this.color:this.$store.getters.getColorPalette().accentCode
                    break;
                case 'tariff':
                    this.unit = '₹'
                    this.font_size='300%'
                    this.fontColor='#00E676'
                    break;
                default:
                    this.unit='kWH'
            }
            this.getData()
        }
      },
        async getData(){
        //   if(this.widget.height==='fixed'){
        //   }
        if(this.meta){
            this.font_size=this.meta[0]['font_size']
            this.fontColor=this.meta[1]['color']
        }
        // let to_date=moment().subtract(1,'day')
        // let from_date = to_date.startOf('month')
        let to_date = null
        let from_date = null
        if(this.time==='mtd'){
            to_date=(moment().subtract(1,'day')).format('YYYYMMDD')
            from_date=(moment().subtract(1,'day').startOf('month')).format('YYYYMMDD')
            // console.log(from_date,'from')
            // console.log(to_date,'to')
        }else{
            // to_date = to_date.subtract(1, 'month').format('YYYYMMDD');
            // from_date = from_date.subtract(1, 'month').format('YYYYMMDD');
            to_date = ((moment().subtract(1,'day')).subtract(1, 'month').endOf('month')).format('YYYYMMDD');
            from_date = (moment(to_date).startOf('month')).format('YYYYMMDD');
            // console.log(from_date,'from')
            // console.log(to_date,'to')
        }
        this.usage = 0
        let payload={
            
            parameters:[this.param],
                from_date:from_date,
                to_date:to_date,
                type:this.type
                }
                axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {
                      Authorization: 'Bearer '+ this.$store.state.jwt
                      }}).then(response=>{
                      if(response.data.status==='success'){
                        switch(this.type){
                            case 'active_energy':
                                this.usage= Number(response.data.data['usage']).toFixed(2)
                                break;
                            case 'apparent_energy':
                                this.usage= Number(response.data.data['usage']).toFixed(2)
                                break;
                            case 'pf':
                                this.usage= Number(response.data.data['avg']).toFixed(2)
                                break;
                            case 'active_power':
                                this.usage= Number(response.data.data['avg']).toFixed(2)
                                break;
                            case 'reactive_power':
                                this.usage= Number(response.data.data['avg']).toFixed(2)
                                break;
                            case 'tariff':
                                this.usage= (Number(response.data.data['usage'])*this.tariff).toFixed(2)
                                break;
                        }
                      }else{
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                      this.loading=false
                      }).catch(err=>{
                      console.error(err)
                      this.loading=false});  
        },
    },
    watch:{
        param:{
            handler(){
                this.getData()
            },
            deep:true
        },
        time:{
            handler(){
                this.getData()
            },
            deep:true
        },
        meta:{
            handler(){
                this.getData()
            }
        }
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
.usage {
    color: #FDD835;
    font-size: 300%;
    font-weight: bold;
}
.unit{
    font-size: 0.6em;
}
</style>
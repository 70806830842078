<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row no-gutters>
            <v-col>
                <v-card-title v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Parameter Insights Widget</v-card-title>
                <v-card-title v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Parameter Insights Widget</v-card-title>
            </v-col>
        </v-row> -->
        <!-- widget form -->
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="pa-3">
                <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_parameter_insights_widget}}</v-card-title>
                <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_parameter_insights_widget}}</v-card-title> 
                <v-row>
                    <!--   Label -->
                    <v-col>
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-text-field 
                            dense 
                            outlined 
                            v-if="!loading" 
                            v-model="widgetForm.label" 
                            :counter="30"
                            :rules="[rules.required]" 
                            :label="$store.getters.getTextMap().widget_label">
                        </v-text-field>
                    </v-col>
                    <!-- widget width -->
                    <v-col>
                        <v-select 
                            dense outlined :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions" :label="$store.getters.getTextMap().widget_width" item-text="label"
                            item-value="value" :rules="[rules.required]">
                        </v-select>
                    </v-col>
                    <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <!-- widget swq -->
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <!-- widget height -->
                    <v-col>
                        <v-select 
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark:$store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.height" :items="heightOptions" item-text="label" item-value="value"
                            :label="$store.getters.getTextMap().widget_height" :rules="[rules.required]" dense outlined>
                        </v-select>
                    </v-col>
                    <!-- TImeframe Option -->
                    <v-col v-if="!disableTimeframe" align-self="center">
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="widgetForm.meta.timeFrame"
                            :items="tdOptions"
                            :label="$store.getters.getTextMap().timeFrame"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"   
                        ></v-select>  
                    </v-col>
                </v-row>
            </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="my-2 pa-3">
                <v-row no-gutters>
                    <v-col align-self="center">
                        <v-card-title class="sub-heading">{{$store.getters.getTextMap().choose_parameter}}</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex" align="right" v-if="$store.state.user">
                        <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                            <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                            <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                <v-row no-gutters>
                    <!-- {{Parameter Options}} -->
                    <v-col v-if="formType==='deviceForm'">
                        <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col v-if="formType==='machineForm'">
                        <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-row v-if="!loading && widgetForm && widgetForm.meta && widgetForm.meta.param_list" no-gutters class="mt-2">
                <v-col >
                    <v-simple-table maxHeight="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">                        <template v-slot:default>
                            <thead >
                                <tr>
                                    <th class="text-left"><strong>{{$store.getters.getTextMap().label}}</strong></th>
                                    <th class="text-left"><strong>{{$store.getters.getTextMap().params}}</strong></th>
                                    <th class="text-left"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.label" v-for="item in widgetForm.meta.param_list">
                                    <td>{{ item.label }}</td>
                                    <td>{{ item.parameter }}</td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Parameter?">
                                            <v-icon small color="red">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            </v-card>
        </v-form>
        <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="pa-3">
            <v-form ref="ruleForm" v-model="ruleValid">
                <v-card-title class="sub-heading"> {{ $store.getters.getTextMap().insights}}</v-card-title>
                <v-row no-gutters class="mt-2">
                    <v-col>
                        <v-text-field
                            v-model="ruleForm.label"
                            :rules="objectRules"
                            :label="$store.getters.getTextMap().label_star"
                            dense
                            outlined
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                    <v-col class="ml-2">
                        <v-select
                            v-model="ruleForm.color"
                            :items="colorOptions"
                            required
                            clearable
                            :label="$store.getters.getTextMap().color_star"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            :rules="objectRules"
                        >
                            <template v-slot:selection="{ item }">
                                <div :style="{'background-color':item.value, 'padding':'3px','border-radius':'5px'}">{{ item.label }}</div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div :style="{'background-color':item.value, 'padding':'3px 5px 5px','border-radius':'5px'}">{{ item.label }}</div>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-autocomplete
                            v-model="operator1"
                            :items="operatorOptions"
                            required
                            clearable
                            :label="$store.getters.getTextMap().operator1_star"
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            :rules="objectRules"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="ml-2">
                        <v-text-field
                            v-model="operand1"
                            :rules="[rules.required,rules.noSpace,rules.num]"
                            :label="$store.getters.getTextMap().value1_star"
                            dense
                            outlined
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-autocomplete
                            v-model="operator2"
                            :items="operatorOptions"
                            required
                            clearable
                            :label="$store.getters.getTextMap().operator2_star"
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="ml-2">
                        <v-text-field
                            v-model="operand2"
                            :label="$store.getters.getTextMap().value2"
                            dense
                            outlined
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-btn class="mb-6" text outlined  small @click="addRule">{{$store.getters.getTextMap().add}}<v-icon  color="blue">mdi-plus</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-row no-gutters class="mt-2" v-if="insights && insightsKeys.length>0">
                    <v-col>
                        <v-simple-table maxHeight="200px"  class="mt-3" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">{{$store.getters.getTextMap().label}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().color}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().condition}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().actions}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(rule,index) in insightsKeys" :key="index">
                                        <td>{{rule}}</td>
                                        <td><div :style="{'background-color':insights[rule].color, 'padding':'3px','border-radius':'5px'}">{{ insights[rule].color }}</div></td>
                                        <td>{{`Param value ${insights[rule].operator1} ${insights[rule].operand1}`}}{{insights[rule].operator2?` and Param value ${insights[rule].operator2} ${insights[rule].operand2}`:""  }}</td>
                                        <td>
                                            <!-- <v-icon @click="moveUp(index)" :disabled="index === 0" color="blue">mdi-arrow-up-thin</v-icon>
                                            <v-icon @click="moveDown(index)" :disabled="index === widgetForm.meta.insights.length - 1" color="blue">mdi-arrow-down-thin</v-icon> -->
                                            <!-- <v-icon @click="removeRule(rule)" color="red">mdi-delete</v-icon> -->
                                            <DeleteConfirmation v-on:confirm="removeRule(rule)"  title="Delete Confirmation"  description="Are you sure you want to delete this rule?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="d-flex mt-2" align="right" align-self="center">
                <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{ $store.getters.getTextMap().cancel }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import InfoAlert from '@/components/InfoAlert';
    import FormRules from '@/utillities/FormRules'
    import EntureStaticLists from '@/utillities/EntureStaticLists'
    import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
    import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
    import DeleteConfirmation from '@/components/DeleteConfirmation'
    import axios from 'axios';
    export default {
        name: 'AddParameterInsightsWidget',
        props: ['widget', 'seq','disableTimeframe'],
        components: {
            InfoAlert,
            WorkspaceDeviceParamForm,
            WorkspaceMachineParamForm,
            DeleteConfirmation
        },
        data() {
            return {
                edit: false,
                widgetValid: false,
                ruleValid: false,
                api: 'createWidgetForView',
                showDismissibleAlert: false,
                info: '',
                loading: false,
                formType:'machineForm',
                operator1:null,
                operand1:null,
                operator2:null,
                operand2:null,
                insights:{},
                insightsKeys:[],
                widthOptions: [
                    { label: 'Full screen', value: 12 },
                    { label: 'Half screen', value: 6 },
                    { label: '1/3 screen', value: 4 },
                    { label: 'Quarter screen', value: 3 },
                    {label:'3/4 screen', value:9},
                    { label: 'Auto', value: null }
                ],
                heightOptions: [
                    { label: 'Fixed', value: 'fixed' },
                    { label: 'Flex', value: 'flex' },
                ],
                tdOptions:[
                    {label:'last 7 days', value:'7'},
                    {label:'last 30 days', value:'30'},
                    {label:'last 60 days', value:'60'},
                    {label:'last 90 days', value:'90'},
                    {label:'last 180 days', value:'180'},
                    {label:'last 365 days', value:'365'},
                    {label:'MTD', value:'mtd'},
                    {label:'YTD', value:'ytd'},
                ],
                operatorOptions:EntureStaticLists.comparisonOperators,
                colorOptions: EntureStaticLists.colorOptions,
                meta: {},
                ruleForm:{},
                widgetForm: {
                    meta:{
                        param_list: [],
                        insights:{},
                        timeFrame:'7'
                    }
                },
                rules:FormRules.rules,
                objectRules: [
                    FormRules.rules.required
                ],
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                if (this.widget && this.widget.widget_id && this.widget.view_id) {
                    this.edit = true
                    this.widgetForm = Object.assign({}, this.widget)
                    this.api = 'updateWidget'
                    this.widgetLabel = this.widgetForm.label
                    if (!(this.widgetForm.meta)) {
                        this.insights={}
                        this.widgetForm.meta = {
                            param_list: [],
                            insights:{},
                            timeFrame:'7'
                        }
                    }else{
                        this.insights=this.widget.meta.insights?this.widget.meta.insights:{}
                        this.insightsKeys=Object.keys(this.insights)
                    }
                } else {
                    if (this.widget.view_id) {
                        this.api = 'createWidgetForView'
                        this.widgetForm = Object.assign({}, this.widget)
                        this.widgetForm.meta =  {
                            param_list: [],
                            insights:{},
                            timeFrame:'7'
                        }
                        this.insights={}
                        this.widgetForm.seq = this.seq ? this.seq : 0
                    }
                }
            },
            updateParamList(value){
                if(value && value.parameter && value.parameter.param_id){
                    this.widgetForm.meta.param_list.push({label:value.parameter.name,parameter:value.parameter.param_id})
                }
            },
            moveUp(index) {
                if (index > 0) {
                    const temp = this.insights[index];
                    this.$set(this.insights, index, this.insights[index - 1]);
                    this.$set(this.insights, index - 1, temp);
                    this.insightsKeys=Object.keys(this.insights)
                }
            },
            moveDown(index) {
                if (index < this.insights.length - 1) {
                    const temp = this.insights[index];
                    this.$set(this.insights, index, this.insights[index + 1]);
                    this.$set(this.insights, index + 1, temp);
                    this.insightsKeys=Object.keys(this.insights)
                }
            },
            addRule() {
                this.$refs.ruleForm.validate()
                    if(this.ruleValid){
                        this.loading=true
                        if(!this.insights[this.ruleForm['label']]){
                            this.insights[this.ruleForm['label']]={}
                        }
                        this.insights[this.ruleForm['label']]={
                            color: this.ruleForm['color'],
                            operator1: this.operator1,
                            operand1: Number(this.operand1),
                            operator2: this.operator2,
                            operand2: Number(this.operand2),
                        }
                        this.loading=false
                    }
                    // this.operator1=null
                    // this.operand1=null
                    // this.operator2=null
                    // this.operand2=null
                    this.insightsKeys=Object.keys(this.insights)
                    // this.init()
                },
            removeRule(rule) {
                this.loading=true
                if (rule in this.insights) {
                    delete this.insights[rule];
                } 
                this.loading=false
                this.insightsKeys=Object.keys(this.insights)
            },
            deleteFromTable(item){
                this.widgetForm.meta.param_list=this.widgetForm.meta.param_list.filter(x=>x.label!=item.label)
            },
            onSubmit() {
                this.$refs.widgetForm.validate()
                if (this.widgetValid) {
                    this.widgetForm['meta']['insights']=this.insights
                    axios.post(this.$store.state.api + this.api, this.widgetForm, {
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.jwt
                        }
                    }).then(res => {
                        if (res.data.status === 'success') {
                            this.info = "Widget Created";
                            this.loading = false
                            this.showDismissibleAlert = true
                            this.$refs.widgetForm.reset()
                            this.$refs.ruleForm.reset()
                            this.$emit('success')
                            this.$emit('close')
                        }
                    })
                }
            },
            onClose() {
                this.$refs.widgetForm.reset()
                this.$refs.ruleForm.reset()
            }
        },
        watch: {
            widget() {
                this.init()
            }
        }
    }
</script>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row dense>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
            v-model="workspace"
            :items="workspaceOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().workspace"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>

            </v-col>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
            v-model="device"
            :items="deviceOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().device_name"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-if="multiple">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            multiple
            :label="$store.getters.getTextMap().parameters"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-else>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().parameters"
            item-text="label"
                    item-value="value"
                    :rules="objectRules"
          ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="multiple">
            <v-col>
              
              <v-chip
                class="ma-2"
                close
                small
                color="teal"
                text-color="white"
                @click:close="remove(param)" 
                v-bind:key="param.parameter" v-for="param in parameter"
              >
              {{param.name}}
              </v-chip>
                      </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
export default {
name:'WorkspaceDeviceParamForm',
props:['multiple','exsistingDevice','exsistingWorkspace','exsistingParameter'],
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        workspace:null,
        device:null,
        parameter:null,
        form:{},
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
mounted(){
    if(this.exsistingParameter){
        this.parameter=this.exsistingParameter
    }
    if(this.exsistingWorkspace){
        this.workspace=this.exsistingWorkspace
    }
    if(this.exsistingDevice){
        this.device=this.exsistingDevice
    }
},
computed:{
    workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
            for(let i of g){
                op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
            }
        return op
    },
    deviceOptions(){
        let op=[]
        if(this.workspace && this.workspace.workspace_id ){
            let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
            for(let i of g){
                if(i && i.name ){
                    op.push({value:i, label: i.name })
                }else{
                    op.push({value:i, label: i.device_id })
                 }
            }
        }
        return op
    },
    paramOptions(){ 
        let op=[]
        if(this.device && this.device.device_id ){
            let g=this.$store.getters.getParametersByDeviceID(this.device.device_id)
            for(let i of g){
                if(i && i.name ){
                    if(i.disabled!= true ){
                        op.push({value:{device_name:this.device.name,param_id:i.param_id,name:this.device.name+' - '+i.name,processing:i.processing,meta:i.meta}, label: i.name })
                    }
                }else{
                    op.push({value:i, label: i.parameter,processing:i.processing,meta:i.meta })
                }
            }
        }
        return op
    },
},
methods:{
    remove(param){
      this.parameter=this.parameter.filter(x=>x.param_id!=param.param_id)
    },
    send(){
        this.form.parameter=this.parameter
        this.form.device=this.device
        this.form.workspace=this.workspace
        this.$emit('value',this.form)
    }
},
watch:{
    parameter:{
        handler(){
            this.send()
        },
        deep:true
    }
}
}
</script>
<template>
    <v-container fluid :style="{background:'#263238' }">
        <v-row align="center" justify="center" >
            
            <v-col cols="12" color="#000000">
            <LoginComponent/>
            </v-col>
        </v-row>
       
       <v-row>
           <v-col>
               <v-divider></v-divider>
           </v-col>
           <v-col align="center"    :color="$store.getters.getColorPalette().accentCode" justify="center" cols="1"><Strong :style="{color:$store.getters.getColorPalette().accentCode}">OR</Strong></v-col>
           <!-- <v-col align="center"    :color="$store.getters.getColorPalette().accentCode" justify="center" cols="1"><Strong>OR</Strong></v-col> -->
           <v-col>
               <v-divider></v-divider>
           </v-col>
       </v-row>
       <v-row>
           <v-col :style="{color:$store.getters.getColorPalette().accentCode}" align="center" justify="center">
               New User?
           </v-col>
       </v-row>
        <v-row align="center" justify="center" >
            
            <v-col align="center" justify="center" >
                <v-spacer></v-spacer>
            <v-btn :dark="$store.getters.getColorPalette().isDark" outlined class="px-15 py-2" @click="dialog = true">Sign up <v-icon
        right
     :dark="true"
      >
        mdi-cloud-upload
      </v-icon></v-btn>
            </v-col>
            
        </v-row>
        <v-row>
          <v-col align="left">
            <a target="_blank" rel="noopener noreferrer" :href="$store.state.api+'privacyPolicy'" ><span class="text-caption">Privacy policy</span></a>
          </v-col>
          <v-col align="right">
            <a target="_blank" rel="noopener noreferrer" :href="$store.state.api+'termsOfUsage'" ><span class="text-caption">Terms Of Usage</span></a>
          </v-col>
        </v-row>
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :dark="true" color="#263238">
    <!-- <v-card :dark="true" :color="$store.getters.getColorPalette().backgroundColorCode"> -->
    <v-toolbar
      :dark="true"
          class="teal lighten-2"
        >
    <!-- <v-toolbar
      :dark="true"
          :class="$store.getters.getColorPalette().foregroundColorName"
        > -->
          <v-btn
            icon
        :dark="true"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Sign Up</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            :dark="true"
              text
              @click="dialog = false"
            >
              close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <SignUpComponent v-on:close="dialog = false" />
    </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import LoginComponent from '@/components/LoginComponent';
import SignUpComponent from '@/components/SignUpComponent';

export default {
    name:'LoginScreen',
    components:{
        LoginComponent,
        SignUpComponent
    },
    mounted(){
      document.title='Login'
    },
    data(){
        return {
            dialog:false
        }
    }
}
</script>
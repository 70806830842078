<template>
<v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
    <v-row no-gutters :dense="true" v-if="params && params.length>0">
        <v-col align-self="start" dense cols="12" xs="12" sm="6" md="2" lg="2" xl="1" class="py-0 my-0"   v-bind:key="parameter.param_id" v-for="parameter in params">
            <Param class="" :param="parameter" v-on:deleteParam="deleteParameter(parameter)"/>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Param from '@/components/items/Param'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name: 'DashboardParamList',
    components:{
        Param,
        InfoAlert
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:''
        }
    },
    computed:{
        params(){
            let p = []
            if(this.$store.state.dashboardParams){
                for(let i of this.$store.state.dashboardParams){
                    p.push(this.$store.getters.getParameterById(i.param_id))
                }
            }
            return p
        }
    },
    methods:{
        deleteParameter(parameter){
          if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
               //this.$store.dispatch('deleteParameter',this.parameter)
               //this.$emit('deleteParameter',parameter)
               this.loading=true
            //    let payload={param_id:parameter.parameter}
            console.log(parameter)
          axios.post(this.$store.state.api+'deleteParamFromDashboard',parameter,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //this.$store.dispatch('refreshParameters')
        this.$store.dispatch('refreshDashboardParams')
        console.log("Parameter delete")
        
        this.loading=false
        this.info="Parameter removed"
        this.showDismissibleAlert=true
            //this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });

           }else{
               this.info='You do not have permission to do this'
               this.showDismissibleAlert=true

           }
          
        },
    }
}
</script>
<template>
    <span class="tooltip">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="grey"
        :dark="$store.getters.getColorPalette().isDark"
          small
          v-bind="attrs"
          v-on="on"
        >
        mdi-information
        </v-icon>
      </template>
      <span>{{hint}}</span>
    </v-tooltip>
    </span>
</template>

<script>
export default {
    name:"InfoComponent",
    props:{
        hint:{
            type:String,
            default: () => "Hover me"

        }
    }
}
</script>

<style>
.tooltip .tooltiptext {
  visibility: hidden;
  width: 20%;
  background-color: #1d1b1b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: small;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
</style>


import BitwiseOperationUtillity from "./BitwiseOperationUtillity";

export default {
    performOperation:(value1,operator,value2)=>{
        switch(operator){
            case '>':
                return value1>value2
            case '|>':
                return Math.abs(value1) > value2;
            case '<':
                return value1<value2
            case '|<':
                return Math.abs(value1)<value2
            case '==':
                return value1==value2
            case '|==':
                return Math.abs(value1)==value2
            case '!=':
                return value1!=value2
            case '>=':
                return value1>=value2
            case '|>=':
                return Math.abs(value1)>=value2
            case '<=':
                return value1<=value2
            case '|<=':
                return Math.abs(value1)<=value2
            case 'bitHigh':
                return BitwiseOperationUtillity.getBitState(value1,value2)
            case 'bitLow':
                return !BitwiseOperationUtillity.getBitState(value1,value2)
            default:
                return
            

        }
    }
}
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
            <v-row no-gutters> 
                <v-card-title><strong>{{  $store.getters.getTextMap().machine_status_mqtt_map }}</strong></v-card-title>
                <v-spacer></v-spacer>
                <v-col  cols="auto" class="d-flex" align="right" align-self=center>
                    <span>
                        <!-- <v-icon color="gray" small @click="getMap">mdi-refresh</v-icon> -->
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="getMap">
                            <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small>
                                mdi-refresh
                            </v-icon>
                        </v-btn>
                        <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="edit=!edit">
                          {{  $store.getters.getTextMap().edit }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
                        </v-btn>            
                        <DeleteConfirmation  v-on:confirm="deleteMap()"  title="Delete Confirmation"  description="Are you sure you want to delete this">
                            <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined> {{  $store.getters.getTextMap().delete }}
                                <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-delete</v-icon>
                            </v-btn>
                        </DeleteConfirmation>
                    </span>
                </v-col>
            </v-row>
            <!-- <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> -->
            <v-card-subtitle>

                <v-card  
                flat
                :color="$store.getters.getColorPalette().backgroundColorCode"
                :dark="$store.getters.getColorPalette().isDark"
                class="px-5 py-6"

                :style="{border:`1px solid ${$store.getters.getColorPalette().accentCode}`}"
                >
                <v-form ref="mqttForm" v-model="valid">
                    <v-divider v-if="!edit" :dark="$store.getters.getColorPalette().isDark"></v-divider>

                    <v-row v-if="edit">
    
                        <v-col >
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field
                                dense
                                outlined
                                v-model="form.topic" 
                                :rules="[rules.required, rules.noSpace]"
                                :counter="100"
                                :loading="loading"
                                :label="$store.getters.getTextMap().topic"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col v-else>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <span v-else><strong>Topic:</strong> {{paramMQTTMap.topic?paramMQTTMap.topic:'_'}}</span>
                        </v-col> -->
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field
                                dense
                                outlined
                                v-model="form.identifier" 
                                :rules="[ rules.noSpace]"
                                :counter="10"
                                :loading="loading"
                                :label="$store.getters.getTextMap().identifier"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col v-else>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <span v-else><strong>Identifier:</strong> {{paramMQTTMap.identifier?paramMQTTMap.identifier:'_'}}</span>
                        </v-col> -->
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field
                                dense
                                outlined
                                v-model="form.identifier_val" 
                                :rules="[ rules.noSpace]"
                                :counter="100"
                                :loading="loading"
                                :label="$store.getters.getTextMap().identifier_value"
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col v-else>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <span v-else><strong>Identifier Value:</strong> {{paramMQTTMap.identifier_val?paramMQTTMap.identifier_val:'_'}}</span>
                        </v-col> -->
        
                    </v-row>
                    <v-row class="pa-3" v-else>
                        <v-col align="center" align-self="center">
                            <h4 class="count-header">{{$store.getters.getTextMap().topic}}</h4>
                            <span class="count">{{paramMQTTMap.topic?paramMQTTMap.topic:'_'}}</span>
                        </v-col>
                        <v-divider vertical/>
                        <v-col align="center" align-self="center">
                            <h4  class="count-header">{{$store.getters.getTextMap().identifier}}</h4>
                            <span class="count">{{paramMQTTMap.identifier?paramMQTTMap.identifier:'_'}}</span>
                        </v-col>
                        <v-divider vertical/>
                        <v-col align="center" align-self="center">
                            <h4  class="count-header">{{$store.getters.getTextMap().identifier_value}}</h4>
                            <span class="count">{{paramMQTTMap.identifier_val?paramMQTTMap.identifier_val:'_'}}</span>
                        </v-col>
                    </v-row>
                    <v-divider v-if="!edit" :dark="$store.getters.getColorPalette().isDark"></v-divider>
                    <v-row v-if="edit" justify="end">
                        <v-col cols="auto" class="d-flex" align="right">
                            <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined  v-if="!loading" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                            <v-btn  :color="$store.getters.getColorPalette().deletebtnColor" small text outlined v-if="!loading" @click="edit=false">{{$store.getters.getTextMap().cancel}}</v-btn>
                            <v-progress-circular indeterminate color="primary" v-if="loading" ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
            </v-card-subtitle>
    </v-container>
</template>
<script>
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'

export default {
    name:'MachineStatusMQTTMap',
    props:['machine_id'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
            showDialog:false,
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            form:{},
            paramMQTTMap:{},
            rules:FormRules.rules,
        }
    },
    mounted(){
        if(this.machine_id){
            this.form={machine_id:this.machine_id}
            this.getMap()
        }
    },
    computed:{},
    methods:{
        getMap(){
            if(this.machine_id){
                this.loading = true;
            axios
                .post(this.$store.state.api + "getMachineStatusMQTTMap", {machine_id:this.machine_id},{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
                if (response.data.status == "success") {
                    
                    //console.log(response.data.jwt);
                    //this.$store.dispatch("refreshHubs");
                    this.paramMQTTMap=response.data.data
                    this.form=Object.assign({},response.data.data)
                    //this.onReset()
                    this.edit=false
                    //this.$emit('close')
                } else {
                    this.paramMQTTMap={}
                    this.form={machine_id:this.machine_id}
                    //this.info = response.data.msg;
                    //this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
            })
            .catch(error => {
            //this.onReset()
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
            }
        },
        onSubmit(){
            this.$refs.mqttForm.validate()
            if(this.valid){
            this.loading = true;
            this.form['machine_id']=this.machine_id
            axios.post(this.$store.state.api + "mapMachineStatusMQTT", this.form,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response => {
                if (response.data.status == "success") {
                    
                    //console.log(response.data.jwt);
                    this.getMap()
        
                    this.info = 'Settings Updated';
                    this.showDismissibleAlert = true;
                    //this.onReset()
                    
                    this.edit=false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                //this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
        },

        deleteMap(){
            this.loading=true
            axios.post(this.$store.state.api + "deleteMachineStatusMQTTMap", {machine_id:this.machine_id},{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response => {
                if (response.data.status == "success") {
                    
                    //console.log(response.data.jwt);
                    this.getMap()
        
                    this.info = 'Settings deleted';
                    this.showDismissibleAlert = true;
                    //this.onReset()
                    
                    this.edit=false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                //this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }

    }
}
</script>
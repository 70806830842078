<template>
  <v-app>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card color='#000000' :dark="true">
        <EulaAgreement v-on:close="dialog=false"/>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
    :width="185"
      app
     :dark="$store.getters.getColorPalette().isDark"
     permanent
     :mini-variant="mini"
     :color="$store.getters.getColorPalette().navigation"
      
      v-if="$store.state.loggedIn && drawer && !dialog"
    >
      <v-list dense >
       <v-list-item class="px-2"   :color="$store.getters.getColorPalette().tab">
        <v-spacer v-if="!mini"></v-spacer>
        <!-- <v-btn
          icon
          small
          @click.stop="mini = !mini"
         
        >
        
          <v-icon  :style="{color:$store.getters.getColorPalette().navigation_icon}" v-if="mini">mdi-chevron-right</v-icon>
          <v-icon :style="{color:$store.getters.getColorPalette().navigation_icon}" v-else>mdi-chevron-left</v-icon>
        </v-btn> -->
         </v-list-item>

        <!-- Show Logo added here   -->

          <div v-if="!$store.state.clientSafety && $store.state.organisation && $store.state.organisation.show_logo" class="mt-n8 py-3" align="center">
             <v-img 
             :src="$store.state.organisation.logo" 
             alt="Organisation Logo" 
             class="logo" 
             max-width="100px"
             max-height="100px"
             contain/>
          </div>

        <v-list-item v-for="item in menuLinks" :key="item.link" router :to="item.link" :active-class="$store.getters.getColorPalette().foregroundColorName " >
          
          <v-list-item-action>
            <v-icon :style="{color:$store.getters.getColorPalette().navigation_icon}">mdi-{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title :style="{color:$store.getters.getColorPalette().navigation_icon}">{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <!-- <v-list-item v-if="$store.state.user  && $store.getters.getAccessByLevels(['device']) "  @click="$store.dispatch('refresh')" link>
          <v-list-item-action>
           
            <v-icon :style="{color:$store.getters.getColorPalette().navigation_icon}" >mdi-atom-variant
             
            </v-icon>
          </v-list-item-action>
          <v-list-item-content >
            <v-list-item-title :style="{color:$store.getters.getColorPalette().navigation_icon}">Refresh</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    
      <v-row no-gutters dense>
        <v-col align="centre">
             <div v-if="$store.state.organisation && $store.state.organisation.showoem_logo" class="mt-n8 py-8" align="center">
             <v-img 
             :src="$store.state.organisation.oem_logo" 
             alt="OEM Logo" 
             class="logo" 
             max-width="100px"/>
          </div>
          </v-col>
          </v-row>

      <v-row no-gutters dense>
        <v-col align="center" class="mt-n8 py-8" >
        <p :style="{color:$store.getters.getColorPalette().navigation_icon+'44', fontSize:'10px',position:'fixed',bottom:'1px', left:'25%'}">Powered By Enture<br>Copyright © 2020</p>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-app-bar
      app
     :dark="$store.getters.getColorPalette().isDark"
      dense
      flat
      :color="$store.getters.getColorPalette().topBar"
      :style="{'background-color': $store.getters.getColorPalette().topBar, color: $store.getters.getColorPalette().topBarAccent}"
      v-if="$store.state.loggedIn && !dialog"
    >
    
    
    <v-app-bar-nav-icon @click="drawer = !drawer" :color="$store.getters.getColorPalette().topBarAccent"></v-app-bar-nav-icon>
      <v-toolbar-title><span v-if="!$store.state.clientSafety">{{$store.getters.getOrganisationName()}} </span><span v-else>Enture</span></v-toolbar-title>

      <v-spacer></v-spacer>
      
      <ToggleEditMode class="mx-4"/>
      <NotificationList v-if="$store.state.loggedIn && $store.state.user " />
      <SettingList class="mx-4"/>
      <!-- <router-link to="/settings"><v-icon :color="$store.getters.getColorPalette().topBarAccent">mdi-cog</v-icon></router-link> -->
      <MenuList />
      <PopupNotification/>
    
    
    
    </v-app-bar>
    
      <v-main v-if="!dialog" :class="$store.getters.getColorPalette().backgroundColorName" >
        <PaymentDueReminder/>
      <router-view></router-view>
    </v-main>

    
 
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import ToggleEditMode from '@/components/ToggleEditMode'
import MenuList from '@/components/menu/MenuList'
import SettingList from '@/components/menu/SettingList.vue';
import EulaAgreement from '@/components/account_components/EulaAgreement.vue';
import PopupNotification from '@/components/notifications/PopupNotification'
import NotificationList from '@/components/notifications/NotificationList'

import PaymentDueReminder from '@/components/account_components/PaymentDueReminder.vue';

//import ColorUtility from '@/utillities/ColorUtility'
// import InfoComponent from '@/components/InfoComponent'
export default {
  name: 'App',
  components: {
    MenuList,
    ToggleEditMode,
    SettingList,
    EulaAgreement,
    PopupNotification,
    NotificationList,
    PaymentDueReminder,
    // InfoComponent ,
  
  },
  created() {
    this.refresh()
    //setInterval(function(){ this.refresh(); }.bind(this), 300000);
  },
  mounted() {
    this.checkEula()
    //document.title='Enture'
    /*if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }*/
  },
  computed:{
    menuLinks(){
      let op=[]
      if (this.$store.state.loggedIn){
        for(let i of this.$store.getters.getMenuLinks()){
          if(this.$store.getters.getAccessByLevels(i.levels)){
            op.push(i)
          }
        }

      }

        return op
      
    },
   
  },

  data: () => ({
    //
    drawer:true,
    mini: false,
    dialog:false,
    // showLogo: false,
    //colorUtil:ColorUtility
    //  logoUrl: '' ,
  }),
 
  methods: {
    checkEula(){
      this.dialog=false
      if(this.$store.state && this.$store.state.loggedIn && this.$store.state.user && !this.$store.state.user.eula_signed && (this.$store.state.user.root_org==this.$store.state.user.organisation_id)){
        this.dialog=true
      }
    },
    logout(){
      this.$store.dispatch('logOut')
      this.$router.push('/');
    },
    refresh(){
      // console.log("refresh")
      this.$store.dispatch('refresh')
      // setTimeout(function () { this.refresh() }.bind(this), 1800000);
    },
    // updateLogoUrl(data) {
    //   console.log('Received update event from ShowLogoToggle:', data);
    // },
  },
  watch: {
    '$store.state.user': {
      handler() {
        // Do something when $store.state.organisation changes
        this.checkEula();
      },
      deep: true, // Set to true if organisation is an object/array
      immediate: true // Trigger the handler immediately after the component is created
    }
  }
};
</script>
<style >
::-webkit-scrollbar {
  width: 8px;
  background: #424242;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(13, 14, 13); 
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
  background: #c7c7cbcc;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7cbcc; 
  /* background: #e4e4e6cc;  */
  /* border-top: 3px solid white ;
  border-bottom: 3px solid white; */
  border-radius: 10px;
}

html{
  font-weight:300;
  font-size: 14px;
}
 .v-list--dense .v-list-item{
  min-height: 37px;
 }
.v-icon.v-icon{
  font-size: 18px;
  line-height: 0;
}
.v-btn--icon.v-size--small {
    height: 21px;
    width: 21px;
}
/* .v-main__wrap{
  background-color:#263238;
} */

</style>
<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <v-card flat :dark="$store.getters.getColorPalette().isDark" :class="$store.getters.getColorPalette().backgroundColorName">
            <v-card-title class="headline">
          {{dashboardGauge?'Edit':'Add'}} {{$store.getters.getTextMap().gauge_to_dashboard}}
        </v-card-title>
        <v-card-text>
            <v-form
            ref="panelForm"
            v-model="valid"           
        >
        <v-row >
                  <v-col >
                        <v-select
                            v-if="!loading"
                            v-model="param_type"
                            :items="param_types"
                            :label="$store.getters.getTextMap().param_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                  <v-col >
                    <v-select
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                  </v-col>
                  
                    <v-col >
                        <v-select
                            v-if="!loading"
                            v-model="dev"
                            :items="deviceOptions"
                            :label="$store.getters.getTextMap().device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col>
                        <v-select
                            v-if="!loading"
                            v-model="form.param"
                            :items="paramOptions"
                            :label="$store.getters.getTextMap().param"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :counter="30"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                        ></v-text-field>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                    
                </v-row>
            
                <v-row v-if="!loading && form.color_meta && form.color_meta.length>0 ">
                  <v-col cols="12">
                    <v-simple-table height="150px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().from_value}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().to_value}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().color}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :key="item.min" v-for="item in form.color_meta"
        >
          <td>{{ item.min }}</td>
          <td>{{ item.max }}</td>
          <td><span :style="'background-color:'+item.strokeStyle">{{item.strokeStyle}}</span></td>
          <td>
          <DeleteConfirmation  v-on:confirm="deleteColorFromList(item)" title="Delete Confirmation"  description="Are you sure you want to delete this gauge?">
          <v-icon small color="red">mdi-delete</v-icon>
          </DeleteConfirmation>
      </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
                  </v-col>
                </v-row>
                </v-form>
                <v-form
            ref="colorForm"
            v-model="colorValid"           
        >
                <v-row>
                  <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                    v-model="min"
                    :counter="8"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().from"
                    required>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                    v-model="max"
                    :counter="8"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().to"
                    required>
                    </v-text-field>
                  </v-col>
                  <v-col>
                     <v-menu
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="col"
            :label="$store.getters.getTextMap().color"
            
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-color-picker
          v-model="color"
            dot-size="25"
            swatches-max-height="200"
          ></v-color-picker>
      </v-menu>
                  </v-col>
                  
                  <v-col>
                    <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="addColorPoint"
          >
          {{ $store.getters.getTextMap().add_color_break_point}}
          </v-btn>
                  </v-col>
                </v-row>
            </v-form>
                </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().submit}}
          </v-btn>
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="close"
          >
          {{ $store.getters.getTextMap().cancel}}
          </v-btn>
          <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
        </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'

export default {
    name:'AddGaugeToDashboard',
    props:['dashboardGauge'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
      if(this.dashboardGauge){
        this.edit=true
        this.form=Object.assign({},this.dashboardGauge)
        this.form.color_meta=JSON.parse(this.dashboardGauge.color_meta)
        this.param_type=this.dashboardGauge.param_type
        let g=null
            if(this.param_type=='parameter'){
              g=this.$store.getters.getParameterById(this.form.param)
              
              }else if(this.param_type=='computed'){
                  g=this.$store.getters.getComputedParameterById(this.form.param)
                  
              }
              //console.log(g)
              this.dev=this.$store.getters.getDeviceById(g.device_id)
              this.workspace=this.$store.getters.getWorkspaceById(this.dev.workspace_id)
        this.api='updateDashboardGauge'

      }else{
        this.api='addGaugeToDashboard'
      }
    },
    data(){
        return {
          showDialog:false,
          edit:false,
          api:'addGaugeToDashboard',
          color:null,
             showDismissibleAlert:false,
            info:'',
            menu:null,
            loading:false,
            workspace:null,
            dev:null,
            valid:false,
            colorValid:false,
            param_type:null,
            min:null,
            max:null,
            col:null,
            form:{
                name:null,
                param_type:null,
                color_meta:[]
            },
            param_types:[
                {label:'Parameter',value:'parameter'},
                //{label:'Derived Param',value:'derived'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => ( !isNaN(v))|| 'It should be a valid number',
                v => (!!v && v.length <= 8 && v.length > 0) || 'Number must be less than 8 characters',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.dev && this.dev.device_id ){
              let g=[]
              if(this.param_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.dev.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.param_type=='derived'){
                  g=this.$store.getters.getDerivedParamsByDeviceID(this.dev.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
              
          }
          return op
        },
    },
    methods:{
      addColorPoint(){
        this.$refs.colorForm.validate()
            if(this.colorValid && this.color){
              this.form.color_meta.push({
                strokeStyle: this.color.hexa, min: this.min, max: this.max
              })
            }
      },
      deleteColorFromList(item){
        this.form.color_meta=this.form.color_meta.filter(x=>x.min!=item.min)
      },
        onSubmit(){
            this.$refs.panelForm.validate()
            if(this.valid){
              this.loading=true
                axios.post(this.$store.state.api+this.api,this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$store.dispatch('refreshDashboardGauges')
          this.loading=false
          this.close()
      }else{
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }

        },
        close(){
            //this.$refs.panelForm.reset()
            this.$refs.colorForm.reset()
            this.dialog=false
            this.loading=false
            //this.form.type='usage'
            this.$emit('close')
            
            
        },
         cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }
    },
    watch:{
        param_type:  function (v){
          if(!this.edit){
            this.form.param_type=v
           //this.form.type='usage'
           this.form.param=null
          }
         
        },
        color:  function (){
            if(this.color && this.color.hexa){
              this.col=this.color.hexa
            }else{
              this.col=null
            }
         
        },
    }
}
</script>
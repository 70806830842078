<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-row>
            <v-col>
                <span>{{$store.getters.getTextMap().add_machine}}</span>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>        
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          outlined          
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.machine_id"
          :items="machineOptions"
          :label="$store.getters.getTextMap().machine"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
            <v-col v-if="!loading" align-self="center">
                <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            outlined
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().add_machine}}
          </v-btn>
          
            </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'AddParamToDashboard',
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            workspace:null,
            form:{
            },
            machineOptions:[],
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        
    },
    methods:{
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                console.log(payload)
                this.loading = true;
      axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.param_id=this.form.parameter
            axios.post(this.$store.state.api+'addDashboardMachine',this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        
        this.$store.dispatch('refreshDashboardMachines')
        
            this.loading=false
            //this.dialog=false
            //this.$emit('close')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.usageForm.reset()
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        }
    }
}
</script>
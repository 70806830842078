export default {
    svgAssets: [
        {
            // "name": "HVAC",
            "path": require("@/assets/svg/hvac-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/industrial_process.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/tank_sublike.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/tank1.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/coolingtower.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/rotaryfurnace.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/conveyor.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/industrialoven_2.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/blower2.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/blower4.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/duct_rounded_corner.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/duct_simple_corner.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/duct_simple_3way.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/duct_simple_4way.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/duct.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/diffuser_1.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/diffuser_round.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/pipe_elbow.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/pipe_tjunction.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/centralairunit.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/heatexchanger.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/variableairvolumeunit.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/filter.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/commtower.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/cpu.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/pcmonitor.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/freezer_smallfront.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/arrow_straight1.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/flammable.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/radiation.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/highvoltage.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/uv.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/toxic.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/warning2.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/biohazard.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/irritant.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name": "industrial_process",
            "path": require("@/assets/svg/pipe_hvac.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },

        {
            // "name": "Air Conditioner",
            "path": require("@/assets/svg/air-conditioner-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name": "Bearing",
            "path": require("@/assets/svg/bearing-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"conveyor",
            "path": require("@/assets/svg/conveyor-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"clientServer",
            "path": require("@/assets/svg/client-server-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"DashboardReport",
            "path": require("@/assets/svg/dashboard-report-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "textY": 100,
            "x": 1,
            "y": 1
        },

        {
            // "name":"Geyser",
            "path": require("@/assets/svg/geyser-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "textY": 100,
            "x": 1,
            "y": 1
        },

        {
            // "name":"Gearbox Engine",
            "path": require("@/assets/svg/gearbox-engine-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },

        {
            // "name":"Factory",
            "path": require("@/assets/svg/factory-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Internet Router",
            "path": require("@/assets/svg/internet-router-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Manufacturing",
            "path": require("@/assets/svg/manufacturing-icon.svg"),
            "width": 100,
            "height": 50,
            "padding": 20,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Mechanic",
            "path": require("@/assets/svg/mechanic-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Mechanical Arm",
            "path": require("@/assets/svg/mechanical-arm-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Motor Pump",
            "path": require("@/assets/svg/motor-pump-solid-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Pipeline",
            "path": require("@/assets/svg/pipeline-outline-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"PowerPlant",
            "path": require("@/assets/svg/power-plant-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Sewer",
            "path": require("@/assets/svg/sewer-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Tape",
            "path": require("@/assets/svg/tape-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1

        },
        {
            // "name":"Ventilation Fan Blower",
            "path": require("@/assets/svg/ventilation-fan-blower-icon.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"AC Motor",
            "path": require("@/assets/svg/AC_Motor.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"Earth-Ground",
            "path": require("@/assets/svg/earth_ground.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"Flow Meter",
            "path": require("@/assets/svg/filled_block.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"Generator",
            "path": require("@/assets/svg/generator.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"Load_resistor",
            "path": require("@/assets/svg/Load_resistor.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"normally open contact",
            "path": require("@/assets/svg/normally_open_contact.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        // {
        // // "name":"PLC",
        // "path": require("@/assets/svg/PLC.svg"),
        // "width": 100,
        // "height": 50,
        // "translateX": 0,
        // "translateY": 10,
        // "x":1,
        // "y":1
        // },
        {
            // "name":"transformer 3p",
            "path": require("@/assets/svg/transformer_3p.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
            // "name":"Meter",
            "path": require("@/assets/svg/watt_hour_meter.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
            // "name":"Meter",
            "path": require("@/assets/svg/block.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
            // "name":"Capacitor",
            "path": require("@/assets/svg/capacitor.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
            // "name":"Circle",
            "path": require("@/assets/svg/circle.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
            // "name":"filled_Circle",
            "path": require("@/assets/svg/filled_circle.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },


        {
            // "name":"Cylinder",
            "path": require("@/assets/svg/cylinder.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },


        {
            // "name":"transformer",
            "path": require("@/assets/svg/transformer.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
        
            "path": require("@/assets/svg/cooler.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
        
            "path": require("@/assets/svg/pipe.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/pipe_with_opener.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/tank.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/pipes_with_relay.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/pipe_with_oil_valve.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/funnel.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },

        {
        
            "path": require("@/assets/svg/pipe_left.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/pipe_output.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        
        {
        
            "path": require("@/assets/svg/pipe_right_rotate.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        {
        
            "path": require("@/assets/svg/pipe_top.svg"),
            "width": 100,
            "height": 50,
            "translateX": 0,
            "translateY": 10,
            "x": 1,
            "y": 1
        },
        




    ]

}
<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />

        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog = true" v-if="$store.state.settingMode && edit">

            <v-list :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">

                <v-list-item @click="update = true">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
                    <v-list-item-icon class="pt-2">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>

                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().delete }}
                        </strong></v-list-item-title>
                    <v-list-item-icon class="pt-2">
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                </v-list-item>

            </v-list>
        </v-menu>


        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false">{{
                            $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddDualAxisBarTrendWidget :widget="widget" v-on:close="update = false"
                    v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>

        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>

        <v-row>
            <v-col>
                <div id="chart" ref="vis"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import AddDualAxisBarTrendWidget from '@/components/crud_components/widgets/AddDualAxisBarTrendWidget.vue'
import { io } from 'socket.io-client'
import moment from 'moment'
import Plotly from 'plotly.js'




export default {
    name: 'DualAxisBarTrendWidget',
    components: {
        InfoAlert,
        AddDualAxisBarTrendWidget
    },

    props: {
        widget: {
            type: Object,
            required: true
        },
        timeframe: {
            type: String,
        },
        edit: {
            type: Boolean,
            required: true
        },
        disableTimeframe: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            info: "",
            showDismissibleAlert: false,
            showMenu: false,
            dialog: false,
            update: false,
            x: 0,
            y: 0,
            loading: false,
            api: null,
            inputData: null,
            tempDataFinale: {},
            rtdtempDataFinale: {},
            layout: {
                font: { color: this.$store.getters.getColorPalette().accentCode },
                plot_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                hoverlabel: { namelength: -1, },
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    tickformat: '%d %b %Y',
                    showgrid: true,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                    title: 'Date'
                },
                yaxis: {
                    range: [0, this.benchmarkYValue + 100],
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                    side: 'left'
                },
                yaxis2: {
                    range: [0, this.benchmarkYValue + 100],
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                    overlaying: 'y',
                    side: 'right'
                },

                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                },
                paramDetails: null,
                dataType: null,
                y1_params_name: [],
                y2_params_name: [],
                y1_params_id: null,
                y2_params_id: null,
                graph_type: null,
                graphData: null,
                rtdData: [],
                mtdData: [],
                ytdData: []
            }

        }
    },
    created() {
        this.stream = io(this.$store.state.streamApi, { withCredentials: true, auth: { token: this.$store.state.jwt } })


    },
    beforeDestroy() {
        this.stream.close()
    },

    mounted() {
        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.datatype) {
            this.dataType = this.widget.meta.datatype
            if (this.dataType == "raw") {
                this.api = "getRawDataByParamsBetweenTimestamp"
            } else if (this.dataType == "normalised") {
                this.api = "getNormalisedDataByParamsBetweenTimestamp"
            } else if (this.dataType == 'trend') {
                this.api = 'getUsageDataByParamBetweenTimestamp'
            }
        } else {
            this.api = "getParamGroupedUsageDataByTimeframe"
        }

        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.graph_type) {
            this.graph_type = this.widget.meta.graph_type
        }

        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data) {
            this.createInputData()
        }

    },
    methods: {
        init() {
            this.getData()
            this.stream.on("connect_error", (err) => { console.error(err.message) });
            this.stream.on('error', data => { console.error(data) })
            this.stream.on('param', data => {
                if (data.constructor == String) {
                    data = JSON.parse(data)
                    this.updateChartWithData(data);
                }
            })
        },
        updateChartWithData(data) {
            let y1_params_details = this.inputData.y1_param_details
            let y2_params_details = this.inputData.y2_param_details
            let params_details = [...y1_params_details, ...y2_params_details]
            this.graphData.forEach((item, index) => {
                if (item.marker.name == params_details[index.name]) {
                    item['param_id'] = params_details[index].param_id
                }
            })
            this.graphData.forEach(item => {
                if (item.param_id == data.param_id) {
                    item.x.push(data.timestamp)
                    item.y.push(data.usage)
                }
            })
        },


        timestampConversion(timestamp) {
            if (timestamp) {
                let dt = timestamp.toString()
                let fromTimeStamp = Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
                return fromTimeStamp
            } else {
                return null
            }
        },
        async getData() {
            try {
                if (this.widget.height === 'fixed') {
                    this.layout.height = 160;
                    this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode };
                    this.layout.margin = { t: 40, b: 40, l: 40, r: 40 };
                }
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray;
                let from_date = moment().subtract(1, 'days');


                if (this.widget.meta && this.widget.meta.time_frame != 'rtd' && this.widget.meta.time_frame != 'yesterday') {
                    
                    if (this.widget.meta.time_frame == 'mtd') {
                        this.mtdData = {}
                        let y1_params = []
                        this.y1_params_name = []
                        for (let i of this.inputData.y1_param_details) {
                            y1_params.push(i.param_id)
                            this.y1_params_name.push(i.name)
                        }
                        let y2_params = []
                        this.y2_params_name = []
                        for (let i of this.inputData.y2_param_details) {
                            y2_params.push(i.param_id)
                            this.y2_params_name.push(i.name)
                        }
                        let payload = {
                            from_date: moment().subtract(1, 'days').startOf('month')
                        }


                        let y1_param_data = await this.getDataFromDb(payload, y1_params)
                        let y2_param_data = await this.getDataFromDb(payload, y2_params)


                        this.tempDataFinale['y1_param_data'] = y1_param_data
                        this.tempDataFinale['y2_param_data'] = y2_param_data
                        this.structuringGraphData()
                    }


                    if (this.widget.meta.time_frame == 'ytd') {
                        this.mtdData = {}
                        let y1_params = []
                        this.y1_params_name = []
                        for (let i of this.inputData.y1_param_details) {
                            y1_params.push(i.param_id)
                            this.y1_params_name.push(i.name)
                        }
                        let y2_params = []
                        this.y2_params_name = []
                        for (let i of this.inputData.y2_param_details) {
                            y2_params.push(i.param_id)
                            this.y2_params_name.push(i.name)
                        }
                        let payload = {
                            from_date: moment().startOf('year')
                        }


                        let y1_param_data = await this.getDataFromDb(payload, y1_params)
                        let y2_param_data = await this.getDataFromDb(payload, y2_params)

                        this.tempDataFinale['y1_param_data'] = y1_param_data
                        this.tempDataFinale['y2_param_data'] = y2_param_data
                        this.structuringGraphData()
                    }

                    from_date = this.getFromDate('YYYYMMDD');
                    if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData) {
                        this.tempDataFinale = {}
                        let y1_params = []
                        this.y1_params_name = []
                        for (let i of this.inputData.y1_param_details) {
                            y1_params.push(i.param_id)
                            this.y1_params_name.push(i.name)
                        }
                        let y2_params = []
                        this.y2_params_name = []
                        for (let i of this.inputData.y2_param_details) {
                            y2_params.push(i.param_id)
                            this.y2_params_name.push(i.name)
                        }

                        let payload = {
                            from_date: from_date
                        };


                        let y1_param_data = await this.getDataFromDb(payload, y1_params)
                        let y2_param_data = await this.getDataFromDb(payload, y2_params)


                        this.tempDataFinale['y1_param_data'] = y1_param_data
                        this.tempDataFinale['y2_param_data'] = y2_param_data
                        this.structuringGraphData()
                    }





                }
                else {
                    if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData) {
                        this.rtdtempDataFinale = {};
                        let y1_params = []
                        this.y1_params_name = []
                        for (let i of this.inputData.y1_param_details) {
                            y1_params.push(i.param_id)
                            this.y1_params_name.push(i.name)
                        }
                        let y2_params = []
                        this.y2_params_name = []
                        for (let i of this.inputData.y2_param_details) {
                            y2_params.push(i.param_id)
                            this.y2_params_name.push(i.name)
                        }
                        let payload
                        // moment().subtract(1, 'days').startOf('day').unix();
                        if (this.widget.meta && this.widget.meta.time_frame == 'rtd') {
                            payload = {
                                params: [],
                                from_timestamp: moment().startOf('day').unix(),
                            };
                        }
                        if (this.widget.meta && this.widget.meta.time_frame == 'yesterday') {
                            payload = {
                                params: [],
                                from_timestamp: moment().subtract(1, 'days').startOf('day').unix(),
                            };
                        }
                        let y1_param_data = await this.getRealTimeDataFromDb(payload, y1_params)
                        let y2_param_data = await this.getRealTimeDataFromDb(payload, y2_params)

                        this.rtdtempDataFinale['y1_param_data'] = y1_param_data
                        this.rtdtempDataFinale['y2_param_data'] = y2_param_data
                        this.structuringRTDGraphData()
                    }
                }
            } catch (err) {
                console.log(err);
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            }
        },

        structuringGraphData() {
            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame != 'rtd' && this.inputData) {
                let dateSet = new Set()
                let y1 = this.tempDataFinale.y1_param_data
                let y2 = this.tempDataFinale.y2_param_data

                for (let i of y1.flat()) {
                    dateSet.add(moment(i.date, "YYYYMMDD").format("YYYY-MM-DD"))
                }
                let Y1 = this.inputData.y1_param_details
                let Y2 = this.inputData.y2_param_details

                let y1_graphdata = this.formatingData(Y1, y1)
                let y2_graphdata = this.formatingData(Y2, y2)
                this.createChart(y1_graphdata, y2_graphdata, dateSet)

            }
        },

        structuringRTDGraphData() {
            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame === 'rtd' && this.inputData) {
                let time = []
                let y1 = this.rtdtempDataFinale.y1_param_data
                let y2 = this.rtdtempDataFinale.y2_param_data



                if (this.dataType == 'raw') {
                    for (let i of y1.flat()) {
                        time.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                    }
                } else if (this.dataType == 'normalised') {
                    for (let i of y1.flat()) {
                        time.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                    }
                } else if (this.dataType == 'trend') {
                    for (let i of y1.flat()) {
                        time.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                    }
                }
                let Y1 = this.inputData.y1_param_details
                let Y2 = this.inputData.y2_param_details
                let y1_graphdata = this.formatingRtdData(Y1, y1)
                let y2_graphdata = this.formatingRtdData(Y2, y2)

                this.createChart(y1_graphdata, y2_graphdata, time)
            }
            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame === 'yesterday' && this.inputData) {
                let time = []
                let y1 = this.rtdtempDataFinale.y1_param_data
                let y2 = this.rtdtempDataFinale.y2_param_data

                for (let i of y1.flat()) {
                    time.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                }
                let Y1 = this.inputData.y1_param_details
                let Y2 = this.inputData.y2_param_details
                let y1_graphdata = this.formatingRtdData(Y1, y1)
                let y2_graphdata = this.formatingRtdData(Y2, y2)

                this.createChart(y1_graphdata, y2_graphdata, time)
            }
        },
        formatingData(Y, y) {
            const paramToName = Y.reduce((acc, item) => {
                acc[item.param_id] = item.name;
                return acc;
            }, {});
            const result = Y.reduce((acc, item) => {
                acc[item.name] = [];
                return acc;
            }, {});
            y.forEach(sublist => {
                sublist.forEach(entry => {
                    const paramId = entry.param_id;
                    if (paramToName[paramId]) {
                        const name = paramToName[paramId];
                        result[name].push(entry.usage);
                    }
                });
            });
            return result
        },

        formatingRtdData(Y, y) {
            const paramToName = Y.reduce((acc, item) => {
                acc[item.param_id] = item.name;
                return acc;
            }, {});
            const result = Y.reduce((acc, item) => {
                acc[item.name] = [];
                return acc;
            }, {});

            y.forEach(sublist => {
                sublist.forEach(entry => {
                    const paramId = entry.param_id;
                    if (paramToName[paramId]) {
                        const name = paramToName[paramId];
                        result[name].push(entry.data);
                    }
                });
            });
            return result
        },
        createChart(y1_usage, y2_usage, dateSet) {
            this.subscribeStream()
            this.graphData = []
            if (!this.dataType) {
                const trace1 = this.y1_params_name.map((param) => ({
                    x: [...dateSet],
                    y: y1_usage[param],
                    name: param,
                    type: this.widget.meta.y1graph_type == '2' ? 'bar' : 'line',
                    marker: {
                        line: {
                            width: 1
                        },
                    },
                    yaxis: 'y1',

                }));
                const trace2 = this.y2_params_name.map((param) => ({
                    x: [...dateSet],
                    y: y2_usage[param],
                    name: param,
                    type: this.widget.meta.y2graph_type == '2' ? 'bar' : 'line',
                    marker: {
                        line: {
                            width: 1,
                        },
                    },
                    yaxis: 'y2',
                    barcornerradius: 15
                }))
                this.layout['title'] = this.widget.label
                this.layout.yaxis['title'] = this.widget.meta.y1axis_label
                this.layout.yaxis2['title'] = this.widget.meta.y2axis_label
                this.graphData = [...trace1, ...trace2];
                Plotly.newPlot(this.$refs.vis, this.graphData, this.layout);
            } else {
                const trace1 = this.y1_params_name.map((param) => ({
                    x: [...dateSet],
                    y: y1_usage[param],
                    name: param,
                    type: this.widget.meta.y1graph_type == '2' ? 'bar' : 'line',
                    barcornerradius: 15,
                    marker: {
                        line: {
                            width: 1
                        },
                    },
                    yaxis: 'y1'
                }));
                const trace2 = this.y2_params_name.map((param) => ({
                    x: [...dateSet],
                    y: y2_usage[param],
                    name: param,
                    type: this.widget.meta.y2graph_type == '2' ? 'bar' : 'line',
                    marker: {
                        line: {
                            width: 1,
                        },

                    },
                    yaxis: 'y2',

                }))
                this.layout['title'] = this.widget.label
                this.layout.yaxis['title'] = this.widget.meta.y1axis_label
                this.layout.yaxis2['title'] = this.widget.meta.y2axis_label
                this.graphData = [...trace1, ...trace2];
                Plotly.newPlot(this.$refs.vis, this.graphData, this.layout);
            }
        },
        subscribeStream() {
            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame === 'rtd' && this.inputData) {
                for (let i of this.inputData.y1_param_details) {
                    this.stream.emit("getParam", i.param_id)
                }
                for (let i of this.inputData.y2_param_details) {
                    this.stream.emit("getParam", i.param_id)
                }
            } else {
                for (let i of this.inputData.y1_param_details) {
                    this.unsubscribeParam(i.param_id)
                }

                for (let i of this.inputData.y2_param_details) {
                    this.unsubscribeParam(i.param_id)
                }

            }

        },
        unsubscribeParam(param) {
            this.stream.emit('removeParam', param)
        },
        async getRealTimeDataFromDb(payload, parameters) {


            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame === 'rtd') {
                if (this.dataType == "raw" || this.dataType == "normalised") {
                    payload['params'] = [...parameters];
                    try {
                        let response = await axios.post(this.$store.state.api + this.api, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                        if (response.data.status == "success") {
                            let data = response.data.data
                            let d = this.separatingIndividualParamData(payload.params, data)
                            return d

                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        this.loading = false;
                        this.showDismissibleAlert = true;
                    }
                } else {
                    payload['to_timestamp'] = moment().endOf('day').unix();
                    let temp = []
                    for (let i of parameters) {
                        payload['parameter'] = i
                        try {
                            let response = await axios.post(this.$store.state.api + this.api, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                            if (response.data.status == "success") {
                                let data = response.data.data
                                data.forEach((item) => {
                                    temp.push(item)
                                })
                            } else {
                                this.loading = false;
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                            }
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            this.loading = false;
                            this.showDismissibleAlert = true;
                        }


                    }

                    let d = this.separatingIndividualParamData(parameters, temp)
                    return d





                }
            }
            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame === 'yesterday') {
                if (this.dataType == "raw" || this.dataType == "normalised") {
                    payload['params'] = [...parameters];
                    try {
                        let response = await axios.post(this.$store.state.api + this.api, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                        if (response.data.status == "success") {
                            let data = response.data.data
                            let d = this.separatingIndividualParamData(payload.params, data)
                            return d
                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        this.loading = false;
                        this.showDismissibleAlert = true;
                    }
                } else {
                    payload['to_timestamp'] = moment().endOf('day').unix();
                    let temp = []
                    for (let i of parameters) {
                        payload['parameter'] = i
                        try {
                            let response = await axios.post(this.$store.state.api + this.api, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                            if (response.data.status == "success") {
                                let data = response.data.data
                                data.forEach((item) => {
                                    temp.push(item)
                                })
                            } else {
                                this.loading = false;
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                            }
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            this.loading = false;
                            this.showDismissibleAlert = true;
                        }
                    }

                    let d = this.separatingIndividualParamData(parameters, temp)
                    return d
                }
            }
        },

        separatingIndividualParamData(params, data) {
            let finalData = []
            for (let i = 0; i < params.length; i++) {
                let param = params[i]
                let temp = []
                for (let j of data) {
                    if (param == j.param_id) {
                        temp.push(j)
                    }
                }
                finalData.push(temp)
            }
            return finalData;
        },

        async getDataFromDb(payload, parameters) {
            let responses = [];
            for (let index = 0; index < parameters.length; index++) {
                let item = parameters[index];

                payload['parameters'] = [item]

                if (this.widget && this.widget.meta && this.widget.meta.time_frame) {
                    if (this.widget.meta.time_frame !== 'rtd' && this.widget.meta.time_frame !== 'yesterday') {
                        payload['timeframe'] = this.widget.meta.time_range;
                    }
                }


                if (payload.parameters) {
                    try {
                        let response = await axios.post(this.$store.state.api + this.api, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                        if (response.data.status == "success") {
                            responses.push(response.data.data);
                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                            responses.push(this.info);
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        this.loading = false;
                        this.showDismissibleAlert = true;
                        responses.push(error.message);
                    }
                }
            }
            return responses;
        },

        getFromDate(date_format) {
            let from_date = moment();

            if (this.timeframe && this.disableTimeframe) {
                if (this.timeframe !== 'rtd') {
                    switch (this.timeframe) {
                        case '7':
                            from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                            break;
                        case '30':
                            from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                            break;
                        case '60':
                            from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                            break;
                        case '90':
                            from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                            break;
                        case '180':
                            from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                            break;
                        case '365':
                            from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                            break;
                        case 'yesterday':
                            from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                            break;
                        case 'ytd':
                            from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                            break;
                        default:
                            from_date = date_format ? moment().subtract(1, 'days').startOf('month').format(date_format) : moment().startOf('month');
                            break;
                    }


                }
            } else {

                if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.time_frame) {
                    if (this.widget.meta.time_frame !== 'rtd') {

                        switch (this.widget.meta.time_frame) {
                            case '7':
                                from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                                break;
                            case '30':
                                from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                                break;
                            case '60':
                                from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                                break;
                            case '90':
                                from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                                break;
                            case '180':
                                from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                                break;
                            case '365':
                                from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                                break;
                            case 'yesterday':
                                from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                                break;
                            case 'ytd':
                                from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                                break;
                            default:
                                from_date = date_format ? moment().subtract(1, 'days').startOf('month').format(date_format) : moment().startOf('month');
                                break;
                        }

                    } else {
                        return from_date
                    }
                }
            }
            return from_date
        },


        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        deleteWidget() {
            this.loading = true
            axios.post(this.$store.state.api + 'deleteWidget', this.widget, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.$emit('update')
                    }
                }).catch(err => {
                    console.error(err)
                    this.loading = false
                });
        },


        createInputData() {
            this.inputData = {}
            if (this.widget.meta.data && this.widget.meta.data.y1_axis_data && this.widget.meta.data.y2_axis_data) {
                this.inputData['y1_param_details'] = this.widget.meta.data.y1_axis_data
                this.inputData['y2_param_details'] = this.widget.meta.data.y2_axis_data
            }
            this.y1_params_id = []
            this.y2_params_id = []
            this.inputData.y1_param_details.forEach(item => {
                this.y1_params_id.push(item.param_id)
            })
            this.inputData.y2_param_details.forEach(item => {
                this.y2_params_id.push(item.param_id)
            })


        }
    },
    watch: {

        inputData() {
            this.init()
        }
    }
}
</script>

<style></style>
<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
         <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
         <v-card  :color="$store.getters.getColorPalette().background2ColorCode"  :dark="$store.getters.getColorPalette().isDark">
             <v-card-title>
             <v-icon  color="yellow"  small></v-icon>&nbsp;<strong style="align-self:centre" :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().upload_image_to_add }}</strong>
           </v-card-title>
     <v-row>
       <v-col>
       <v-file-input
         v-model="fileToUpload"
         :label="$store.getters.getTextMap().select_an_image_to_upload"
         accept="image/*"
         @change="uploadLogo"
       ></v-file-input>
       </v-col>
   
         <!-- Display the selected image -->
         <v-col>
       <v-img
         v-if="logoUrl"
         :src="logoUrl"
         alt="Uploaded Image"
         width="200"
       ></v-img>
       </v-col>
        <v-col>
       
     </v-col>
     </v-row>
     <v-row>
      
     <v-col><v-btn :color="$store.getters.getColorPalette().submitbtnColor" v-if="!loading" @click="submitLogo()">{{$store.getters.getTextMap().submit}}</v-btn></v-col>
     <v-col><v-btn :color="$store.getters.getColorPalette().cancelbtnColor" v-if="!loading" @click="onClose()">{{$store.getters.getTextMap().cancel}}</v-btn></v-col>
                       
     </v-row>
         </v-card>
    </v-container>
   </template>
   
   <script>
   //import axios from 'axios';
   import InfoAlert from '@/components/InfoAlert'
   
   export default {
       name:'ImageUploadForm',
       props:{'compression':{
        type:Boolean,
        default:false
       }},
       components:{
   InfoAlert,
   
       },
     data() {
       return {
         logoUrl: null,
         fileToUpload: null,
         showDismissibleAlert:false,
         info:'',
         loading:false,
         logoUploaded: false,
       };
     },
     methods: {
      uploadLogo() {
     if (this.fileToUpload) {
       const reader = new FileReader();
       reader.onload = (e) => {
         this.logoUrl = e.target.result;
       };
   
       reader.readAsDataURL(this.fileToUpload);
     }
   },
   
   onClose(){
               
               this.$emit('close')
           },
   
   
   submitLogo() {
       if (!this.fileToUpload) {
         this.info = 'Please select a file before submitting.';
         this.showDismissibleAlert = true;
         return;
       }
     if (this.fileToUpload) {
       console.log("Submitting img...");
       this.loading = true;
       const reader = new FileReader();
   
       reader.onload = (e) => {
       //   console.log("File read successfully.");
   
         const originalSizeBytes = e.target.result.length;
         const originalSizeKB = originalSizeBytes / 1024; 
         console.log("Original Image Size: " + originalSizeKB + " KB");
         if(originalSizeKB>1024){
          this.info="The Image Should be less than 1MB"
          this.showDismissibleAlert=true
          return
         }
   
         const img = new Image();
         img.src = e.target.result;
   
         img.onload = () => {
           const canvas = document.createElement("canvas");
           
           let width = img.width;
           let height = img.height;
   
           if(this.compression){
            const maxWidth = 800; 
           const maxHeight = 800; 
            if (width > height) {
             if (width > maxWidth) {
               height *= maxWidth / width;
               width = maxWidth;
             }
           } else {
             if (height > maxHeight) {
               width *= maxHeight / height;
               height = maxHeight;
             }
           }
           }
           
   
           canvas.width = width;
           canvas.height = height;
   
           const ctx = canvas.getContext("2d");
           ctx.drawImage(img, 0, 0, width, height);
   
           const compressedBase64 = canvas.toDataURL("image/jpeg", 0.8); 
   
         
           const compressedSizeBytes = compressedBase64.length;
           const compressedSizeKB = compressedSizeBytes / 1024; 
           console.log("Compressed Image Size: " + compressedSizeKB + " KB");
   
        //    const requestData = {
        //      oem_logo: compressedBase64,
        //    };
   
           // console.log("Sending request data:", requestData);
           this.logoUrl = e.target.result;
                 this.logoUploaded = true;
                //  this.$emit('logo-updated', e.target.result);
                let temp={
                    image:compressedBase64,
                    width:width,
                    height:height
                }
                 this.$emit('image', temp);
   
           
         };
       };
   
       reader.readAsDataURL(this.fileToUpload);
     }
   }
   
   
     },
   };
   </script>
   
   <style scoped>
   .round-logo-frame {
     width: 150px;
     height: 150px;
     border-radius: 50%;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #f0f0f0; 
   }
   
   .round-logo {
     width: 100%;
     height: 100%;
      object-fit: contain; 
   }
   </style>
   
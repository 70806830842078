<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  class="mx-1 my-1" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
        
        <v-container fluid @contextmenu="show" >
            
            <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      @click="dialog=true"
    >
      <v-list>
        
        <v-list-item @click="update=true">
            <v-list-item-title><strong> {{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
            <v-list-item-icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteDashboardPanel">
            <v-list-item-title><strong> {{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
            <v-list-item-icon>
              
            <v-icon color="red" small>mdi-trash-can</v-icon>
            
      
          </v-list-item-icon>
        </v-list-item>
        
      </v-list>
    </v-menu>
    <v-dialog
      v-model="update"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
    <v-toolbar
           :dark="$store.getters.getColorPalette().isDark" 
          :class="$store.getters.getColorPalette().foregroundColorName"
        >
          <v-btn
            icon
             :dark="$store.getters.getColorPalette().isDark" 
            @click="update = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{dashboardPanel.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
               :dark="$store.getters.getColorPalette().isDark" 
              text
              @click="update = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      <AddPanelToDashboard :dashboardPanel="dashboardPanel" v-on:close="update=false"/>
    </v-card>
</v-dialog>
    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            <v-row v-if="!loading">
                <v-col v-if="dashboardPanel.type!='current'" cols="12">
                <BarGraphByDate :label="dashboardPanel.name" :graphData="graphData" :dataKey="dashboardPanel.type" :unit="unit" :hideDownloadPDF="true" />
            </v-col>
            <v-col v-if="dashboardPanel.type=='current'">
                <LineGraphByTimeStamp :label="dashboardPanel.name" :graphData="streamData" dataKey="data" />
            </v-col>
            </v-row>
        </v-container>
        
    </v-card>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import BarGraphByDate from '@/components/graphs/BarGraphByDate'
import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp'
import AddPanelToDashboard from '@/components/crud_components/AddPanelToDashboard'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import {io} from 'socket.io-client'
export default {
    name:'DashboardPanel',
    props:['dashboardPanel'],
    components:{
        BarGraphByDate,
        LineGraphByTimeStamp,
        AddPanelToDashboard,
        // DeleteConfirmation
    },
    created(){
        // console.log(this.dashboardPanel.type)
        if(this.dashboardPanel.type=="current"){
        this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
        }
    },
    beforeDestroy() {
    if(this.dashboardPanel.type=="current"){
        this.stream.close()
        }
    
  },
    mounted(){
        this.fetchData()
        if(this.dashboardPanel.type=="current"){
            this.stream.on("connect_error", (err) => {
  console.error(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.error(data)
      
    })
    this.stream.on('parameter',data=>{
      //console.log(data)
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      this.val=data.data
      this.streamData.push(data)
      //console.log(data)
      if(this.streamData.length>50){
        this.streamData.shift()
      }
    })
    this.stream.emit('getParam',this.dashboardPanel.param)
        }
    },
    data(){
        return {
            showDialog:false,
            graphData:[],
            fromTime:null,
            loading:false,
            x: 0,
            y: 0,
            stream:null,
            showMenu:false,
            update:false,
            streamData:[]
        }
    },
    computed:{
        currentData(){
            let op=[]
            if(this.dashboardPanel.type=="current"){
                op=this.$store.getters.getCurrentParameterDataById(this.dashboardPanel.param)
                
            }
                return op
            
        },
        timeUnit(){
          if( this.currentData.length>0 && (Number(this.currentData[this.currentData.length-1]['timestamp']) - Number(this.currentData[0]['timestamp']))>(3600*3)){
            return 'hour'
          }
          return 'minute'
        },
        unit(){
            if(this.dashboardPanel.param_type=='parameter'){
                let p=this.$store.getters.getParameterById(this.dashboardPanel.param)
                if(p && p.unit){
                    return p.unit
                }
            }
            return null
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
        fetchData(){
            this.fromTime=moment().subtract(Number(this.dashboardPanel.delta),'day').format("YYYYMMDD")
            let d={}
            let api=""
            if(this.dashboardPanel.type!='current'){
                d['from_date']=moment().subtract(Number(this.dashboardPanel.delta),'day').format("YYYYMMDD")
                d['parameter']=this.dashboardPanel.param
                api='getParameterUsageDataBetweenDate'
                this.loading=true
                axios.post(this.$store.state.api+api,d,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    this.graphData=response.data.data
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        //this.$emit('close')
                }else{
                  console.error(response.data.msg)
                }
                })
                .catch(err=>{
                    console.log(err)
                this.loading=false});
            }
    
        },
        deleteDashboardPanel(){
            axios.post(this.$store.state.api+'deleteDashboardPanel',this.dashboardPanel,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    this.$store.dispatch("refreshDashboardPanels")
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        //this.$emit('close')
                }
                })
                .catch(err=>{console.log(err)});
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    }
}
</script>
<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :color=$store.getters.getColorPalette().background2ColorCode outlined class="mx-1 my-1" flat>
        
        <v-container fluid @contextmenu="show" >
            
            <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      @click="dialog=true"
    >
      <v-list>
        <v-list-item @click="showEdit=true">
            <v-list-item-title><strong>Edit</strong></v-list-item-title>
            <v-list-item-icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-list-item-icon>
        </v-list-item>
        
        <v-list-item @click="deleteDashboardGauge">
          
             <v-list-item-title><strong>Delete</strong></v-list-item-title>
            <v-list-item-icon>
           
            <v-icon color="red" small>mdi-trash-can</v-icon>
            
      
          </v-list-item-icon>
        
        </v-list-item>
      </v-list>
    </v-menu>
        <v-dialog
      v-model="showEdit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :class="$store.getters.getColorPalette().backgroundColorCode">
    <v-toolbar
          :dark="$store.getters.getColorPalette().isDark" 
    :class="$store.getters.getColorPalette().foregroundColorName"
        >
          <v-btn
            icon
            :dark="$store.getters.getColorPalette().isDark" 
            @click="showEdit = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{dashboardGauge.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :dark="$store.getters.getColorPalette().isDark" 
              text
              @click="showEdit = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      <AddGaugeToDashboard :dashboardGauge="dashboardGauge" v-on:close="showEdit=false"/>
    </v-card>
</v-dialog>
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color=$store.getters.getColorPalette().background2ColorCode  flat>
      <span class="text-capitalize text-h6"><strong>{{title}}:</strong> {{currentValue}} {{unit}}</span>
        
        <v-card-subtitle>
    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            <v-row v-if="!loading" no-gutters>
                <v-col cols="12" align="center" align-self="end">
                
          <SingleParamStreamGauge :value="currentValue" width="350px"  :options="options" :maxValue="maxValue" :minValue="minValue"  />
          </v-col>
            </v-row>
        </v-card-subtitle>
    </v-card>
        </v-container>
        
    </v-card>
</template>
<script>
import axios from 'axios'
//import VGauge from 'vgauge';
import SingleParamStreamGauge from '@/components/panels/SingleParamStreamGauge'
import {io} from 'socket.io-client'
import AddGaugeToDashboard from '@/components/crud_components/AddGaugeToDashboard'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'DashboardGauge',
    props:['dashboardGauge'],
    components:{
      SingleParamStreamGauge,
        AddGaugeToDashboard,
        // DeleteConfirmation
    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
    mounted(){
      //this.streamData=this.currentData
      this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
this.stream.on('error',data=>{
      console.log(data)
      
    })
      if(this.dashboardGauge.param_type=="parameter"){
      
      
    this.stream.on('parameter',data=>{
      //console.log(data)
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      this.val=data.data
      //this.streamData.push(data)
    })
    this.stream.emit('getParam',this.dashboardGauge.param)
      }
    },
    data(){
        return {
            showDialog:false,
            showEdit:false,
            loading:false,
            x: 0,
            y: 0,
            showMenu:false,
            stream:null,
            val:null
        }
    },
    computed:{
        currentValue(){
            
            if(this.dashboardGauge.param_type=="parameter"){
                
                
                if(this.val && !isNaN(this.val)){
                    return Math.round(Number(this.val)*100)/100
                }else{
                    let op=this.$store.getters.getCurrentParameterDataById(this.dashboardGauge.param)
                if(op && op.length>0){
                    return Math.round(Number(op[op.length-1]['data'])*100)/100
                }
                }
                
                
            }
                return null
            
        },
        maxValue(){
            if(this.dashboardGauge.color_meta && JSON.parse(this.dashboardGauge.color_meta).length >0){
                let max=0
                for(let i of JSON.parse(this.dashboardGauge.color_meta)){
                    if(i.max>max){
                        max=Number(i.max)
                    }
                }
                return max
            }else if(this.dashboardGauge.max_val && Number(this.dashboardGauge.max_val)){
                return Number(this.dashboardGauge.max_val)
            }else{
                let c=Number(this.currentValue)
                if(c){
                    return c+(c*0.2)
                }else{
                    return 1
                }
            }
            
        },
        minValue(){
            if(this.dashboardGauge.color_meta && JSON.parse(this.dashboardGauge.color_meta).length >0){
                let min=0
                for(let i of JSON.parse(this.dashboardGauge.color_meta)){
                    if(i.min<min){
                        min=Number(i.min)
                    }
                }
                return min
            }else if(this.dashboardGauge.min_val && Number(this.dashboardGauge.min_val)){
                return Number(this.dashboardGauge.min_val)
            }else{
                let c=Number(this.currentValue)
                if(c){
                    return c-(c*0.2)
                }else{
                    return 0
                }
            }
            
        },
        title(){
            return this.dashboardGauge?.name
        },
        options(){
            let op={
          angle: 0, // The span of the gauge arc
  lineWidth: 0.1, // The line thickness
  radiusScale: 1, // Relative radius
  pointer: {
    length: 0.49, // // Relative to gauge radius
    strokeWidth: 0.010, // The thickness
    color: this.$store.getters.getColorPalette().accentCode// Fill color
  },
  limitMax: false,     // If false, max value increases automatically if value > maxValue
  limitMin: false,     // If true, the min value of the gauge will be fixed
  //colorStart: '#6FADCF',   // Colors
  //colorStop: '#8FC0DA',    // just experiment with them
  strokeColor: '#E0E0E0',  // to see which ones work best for you
  generateGradient: true,
  highDpiSupport: true,  // High resolution support
  percentColors : [[0.0, "#a9d70b" ], [0.50, "#f9c802"], [1.0, "#ff0000"]],
        }
        if(this.dashboardGauge.color_meta && JSON.parse(this.dashboardGauge.color_meta).length>0){
            
            op['staticZones']=JSON.parse(this.dashboardGauge.color_meta)
        }
        return op
        },
        
        unit(){
            if(this.dashboardGauge.param_type=='parameter'){
                let p=this.$store.getters.getParameterById(this.dashboardGauge.param)
                if(p && p.unit){
                    return p.unit
                }
            }
            return null
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
        deleteDashboardGauge(){
            axios.post(this.$store.state.api+'deleteDashboardGauge',this.dashboardGauge,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    this.$store.dispatch("refreshDashboardGauges")
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        //this.$emit('close')
                }
                })
                .catch(err=>{console.log(err)});
        },
         cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    }
}
</script>
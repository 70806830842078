<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="valid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 mb-2">
                <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_machine_status_timeline_widget}}</v-card-title>
                <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_machine_Status__timeline_widget}}</v-card-title> 
                <v-row>
                    <!--   Label -->
                    <v-col>
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-text-field 
                            dense 
                            outlined 
                            v-if="!loading" 
                            v-model="widgetForm.label" 
                            :counter="30"
                            :rules="[rules.required]" 
                            :label="$store.getters.getTextMap().widget_label">
                        </v-text-field>
                    </v-col>
                    <!-- widget width -->
                    <!-- <v-col>
                        <v-select 
                            dense outlined :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions"  :label="$store.getters.getTextMap().widget_width" item-text="label"
                            item-value="value" :rules="[rules.required]">
                        </v-select>
                    </v-col> -->
                    <!-- widget seq -->
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <!-- widget height -->
                    <!-- <v-col>
                        <v-select 
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark:$store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.height" :items="heightOptions" item-text="label" item-value="value"
                            :label="$store.getters.getTextMap().widget_height" :rules="[rules.required]" dense outlined>
                        </v-select>
                    </v-col> -->
                    <v-col align-self="center">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                </v-row>
            </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 mb-2">
                <v-row class="px-3">
                    <v-card-title v-if="timeframeType==='custom'" class="sub-heading">{{$store.getters.getTextMap().select_timeframe}}</v-card-title>
                    <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().select_shift}}</v-card-title>
                    <v-spacer/>
                    <v-col cols="auto" class="d-flex" align="right">
                        <v-radio-group v-model="timeframeType" row :dark="$store.getters.getColorPalette().isDark">
                            <v-radio :label="$store.getters.getTextMap().timeframe_type_shift" value="shift"></v-radio>
                            <v-radio :label="$store.getters.getTextMap().timeframe_type_custom"  value="custom"></v-radio>
                            <!-- <v-radio :label="$store.getters.getTextMap().timeframe_type_yesterday"  value="yesterday"></v-radio> -->
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-row v-if="timeframeType==='custom'" no-gutters>
                    <v-col>
                        <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().from }}</div>
                        <TimePicker v-model="from_time"/>
                    </v-col>
                    <v-col>
                        <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().to }}</div>
                        <TimePicker v-model="to_time"/>
                    </v-col>
                    <v-col cols="auto" class="d-flex">
                        <v-btn  v-if="!loading" class="mt-9 mx-1"  color="gray darken-1" small outlined text @click="addTimestampToList">{{$store.getters.getTextMap().add}}</v-btn>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col>
                        <v-select  
                            v-if="!loading"
                            v-model="shift"
                            :items="shiftOptions"
                            :label="$store.getters.getTextMap().shift"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select>
                    </v-col>
                </v-row>
                <v-divider v-if="timeframeType==='shift'" :dark="$store.getters.getColorPalette().isDark"/>
                <v-row class="pa-3" v-if="timeframeType==='shift' && widgetForm && widgetForm.meta && widgetForm.meta.shiftDetails && widgetForm.meta.shiftDetails.shift_id">
                    <v-col  align="center" align-self="center">
                        <h4 class="count-header">{{$store.getters.getTextMap().id}}</h4>
                        <span class="count">{{widgetForm.meta.shiftDetails.shift_id}}</span>
                    </v-col>
                    <v-divider vertical :dark="$store.getters.getColorPalette().isDark"/>
                    <v-col align="center" align-self="center">
                        <h4  class="count-header">{{$store.getters.getTextMap().name}}</h4>
                        <span class="count">{{widgetForm.meta.shiftDetails.name ?widgetForm.meta.shiftDetails.name : widgetForm.meta.shiftDetails.shift_id}}</span>
                    </v-col>
                </v-row>
                <v-divider v-if="timeframeType==='shift'" :dark="$store.getters.getColorPalette().isDark"/>
                <v-row v-if="!loading && widgetForm && widgetForm.meta && widgetForm.meta.timestamps" no-gutters class="mb-1 mt-2">
                    <v-col >
                        <v-simple-table maxHeight="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">                        
                            <template v-slot:default>
                                <thead >
                                    <tr>
                                        <th class="text-left"><strong>{{  $store.getters.getTextMap().start_time }}</strong></th>
                                        <th class="text-left"><strong>{{  $store.getters.getTextMap().end_time }}</strong></th>
                                        <th class="text-left" v-if="timeframeType==='custom'"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="(item,index) in widgetForm.meta.timestamps">
                                        <td>{{ item.start_time}}</td>
                                        <td>{{ item.end_time}}</td>
                                        <td v-if="timeframeType==='custom'">
                                            <DeleteConfirmation v-on:confirm="deleteTimestampFromTable(item,index)"  title="Delete Confirmation"  description="Are you sure you want to delete this Timestamp?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 pb-2 mb-2">
                <v-row class="px-3 mt-1">
                    <v-card-title class="sub-heading">{{$store.getters.getTextMap().choose_machine}}</v-card-title>
                    <v-spacer/>
                    <v-col v-if="widgetForm && widgetForm.meta && widgetForm.meta.machineList && widgetForm.meta.machineList['machine_id']" cols="auto" class="d-flex mt-1" align="right">
                        <v-btn v-if="!loading" small outlined text :color="$store.getters.getColorPalette().accentCode"  @click="clearMachineDetails">
                            {{$store.getters.getTextMap().remove_machine}}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col align-self="center">
                        <WorkspaceMachineForm :multiple="false" v-on:value="updateMachineList"/>
                    </v-col>
                </v-row>
                <v-row v-if="timeframeType==='custom'" class="mx-3" no-gutters>
                    <v-col align-self="center">
                        <v-select  
                            v-if="!loading"
                            v-model=" widgetForm.meta.days_delta"
                            :items="daysDeltaOptions"
                            :label="$store.getters.getTextMap().days_delta"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="!loading && widgetForm && widgetForm.meta && widgetForm.meta.machineList" no-gutters class="mb-1">
                    <v-col >
                        <v-simple-table maxHeight="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">                        
                            <template v-slot:default>
                                <thead >
                                    <tr>
                                        <th class="text-left"><strong>Machine</strong></th>
                                        <th class="text-left"><strong>ID</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="item in widgetForm.meta.machineList">
                                        <td>{{ item.machine_name}}</td>
                                        <td>{{ item.machine_id}}</td>
                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteMachineFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Machine?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-row no-gutters>
                <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm.vue'
import TimePicker from '@/components/inputs/TimePicker'
import axios from 'axios'
import moment from 'moment'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'AddMachineStatusTimelineWidget',
    props:['widget','seq'],
    components:{
        InfoAlert,
        WorkspaceMachineForm,
        TimePicker,
        DeleteConfirmation
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            edit: false,
            api: 'createWidgetForView',
            timeframeType:'shift',
            from_time:null,
            to_time:null,
            shiftOptions:[],
            shift:null,
            widgetForm: {
                meta:{
                    machineList:[],
                    timestamps:[],
                    timeframeType:'shift',
                    shiftDetails:{}
                }
            },
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                {label:'3/4 screen', value:9},
                { label: 'Auto', value: null }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            daysDeltaOptions: [
                { label: '1 Days', value: 1 },
                { label: '2 Days', value: 2 },
                { label: '3 Days', value: 3 },
            ],
            rules: FormRules.rules,
        }
    },
    mounted() {
        this.getShifts()
        this.init()
    },
    computed:{
        shiftData(){
            if(this.shift && this.shift.shift_id){
                return this.$store.getters.getShiftDataById(this.shift.shift_id)
            }
            return null
        },
    },
    methods: {
        init() {
            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true
                this.widgetForm = Object.assign({}, this.widget)
                this.api = 'updateWidget'
                this.widgetLabel = this.widgetForm.label
                if (!(this.widgetForm.meta)) {
                    this.widgetForm.meta = {timeframeType:'shift', machineList: [],timestamps:[],shiftDetails:{},days_delta:1}
                    this.timeframeType="shift"
                }else{
                    this.timeframeType=this.widgetForm.meta.timeframeType?this.widgetForm.meta.timeframeType:"shift"
                }
            } else {
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView'
                    this.widgetForm = Object.assign({}, this.widget)
                    this.widgetForm.meta = {timeframeType:'shift', machineList:[],timestamps:[],shiftDetails:{},days_delta:1}
                    this.widgetForm.seq = this.seq ? this.seq : 0
                }
                this.timeframeType="shift"
            }
        },
        getTimestamp(time){
            let dt=moment().subtract(1, 'days').format("YYYYMMDD")
            if(time){
                dt+=' '+time.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let shifts=response.data.data
            for(let i of shifts){
                op.push({value:i, label: i.name })
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
          this.shiftOptions = op
        },
        getFormattedDate(timestamp){
            if(timestamp){
                return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
            }
            return null
        },
        updateMachineList(value){
            if(!(this.widgetForm && this.widgetForm.meta && this.widgetForm.meta && this.widgetForm.meta.machineList && this.widgetForm.meta.machineList.length>0)){
                this.widgetForm.meta.machineList=[]
            }
            if(!this.widgetForm.meta.machineList.some(x=>x.machine_id ===value.machine.machine_id)){
                this.widgetForm.meta.machineList.push(value.machine)
            }
        //    this.widgetForm.meta.machineList.push(value.machine)
        },
        addTimestampToList(){
            let timestamps=[]
            timestamps=this.widgetForm.meta.timestamps
            timestamps.push({start_time:this.from_time?this.from_time:'00:00',end_time:this.to_time})
            this.widgetForm.meta.timestamps=timestamps
            timestamps=[]
        },
        addShiftToList(){
            let timestamps=[]
            let shiftDetails=this.shiftData
            this.widgetForm.meta.shiftDetails=this.shift
            for(let i of shiftDetails){
                timestamps.push({start_time:i.from_time,end_time:i.to_time})
            }
            this.widgetForm.meta.timestamps=timestamps
        },
        onSubmit() {
            this.$refs.widgetForm.validate()
            if (this.valid) {
                if(this.timeframeType==='shift'){
                    this.widgetForm.meta.days_delta=1
                }
                this.widgetForm.width=12
                this.widgetForm.height='flex'
                this.widgetForm.meta['timeframeType']=this.timeframeType
                axios.post(this.$store.state.api + this.api, this.widgetForm, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.info = "Widget Created";
                        this.loading = false
                        this.showDismissibleAlert = true
                        this.$refs.widgetForm.reset()
                        this.$emit('success')
                        this.$emit('close')
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                    }
                }).catch(err=>{
                    console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        deleteTimestampFromTable(item,index){
            this.widgetForm.meta.timestamps = this.widgetForm.meta.timestamps.filter((x, i) => i !== index);
        },
        deleteMachineFromTable(item){
            this.widgetForm.meta.machineList=this.widgetForm.meta.machineList.filter(x=>x.machine_id!=item.machine_id)
        },
        clearMachineDetails(){
            this.widgetForm.meta.machineList={}
        },
        onClose() {
            this.$refs.widgetForm.reset()
        }
    },
    watch:{
        shift(){
            this.addShiftToList()
        }
    }
}
</script>
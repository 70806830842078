
export default{
    name:'BitwiseOperationUtillity',

  dec2bin:(dec)=>{
    return (dec >>> 0).toString(2);
},
bin2dec:(bin)=>{
  return parseInt(bin, 2).toString(10);
},
getBitState:(bitNumber,num)=>{
    let b=(1<<bitNumber)
    return (b&num)==b
},
setBitState:(bitNumber,num)=>{
    let b=(1<<bitNumber)
    return (b|num)
}

}

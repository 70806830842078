<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Bitwise Word Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Bitwise Word Widget</span>
            </v-col>
        </v-row> -->
        <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="widgetForm" v-model="valid">
            <v-card class="px-2 mb-2"   :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined> 
                <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_bitwise_word_widget}}</v-card-title>
                <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_bitwise_word_widget}}</v-card-title> 
                <v-row>
                    <v-col align-self="center">
                        <v-text-field dense outlined v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="widgetForm.width"
                            :items="widthOptions"
                            :label="$store.getters.getTextMap().widget_width"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                    <v-col align-self="center">
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="widgetForm.height"
                            :items="heightOptions"
                            :label="$store.getters.getTextMap().widget_height"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                    <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <v-col v-if="edit"  align-self="center">
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card> 
            <v-card class="px-2"  :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
                <v-row no-gutters>
                    <v-card-title class="sub-heading">{{$store.getters.getTextMap().choose_words}}</v-card-title>
                    <v-spacer/>
                    <v-col cols="auto" class="d-flex mt-2" align="right" v-if=" $store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
                        <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                            <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                            <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="workspace && workspace.workspace_id && edit">
                    <v-col v-if="formType==='deviceForm'">
                        <WorkspaceDeviceParamForm :multiple="false" :exsistingWorkspace="workspace" :exsistingDevice="device" :exsistingParameter="parameter" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col v-else>
                        <WorkspaceMachineParamForm :multiple="false" :exsistingWorkspace="workspace" :exsistingMachine="machine" :exsistingParameter="parameter" v-on:value="updateParamList"/>
                    </v-col>
                </v-row>
                <v-row no-gutters v-else>
                    <v-col v-if="formType==='deviceForm'">
                        <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col v-else>
                        <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col align-self="center" cols="auto">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="word"
                            :items="wordsOption"
                            :label="$store.getters.getTextMap().words"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            :rules="[rules.required]" 
                        ></v-select>
                    </v-col>
                </v-row>
                <!-- <v-row> -->
                    <!-- <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="highStatusColor"
                            :items="colorOptions"
                            label="High Status Color"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="lowStatusColor"
                            :items="colorOptions"
                            label="Low Status Color"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                        ></v-select>
                    </v-col> -->
                <!-- </v-row> -->
                <v-row no-gutters>
                    <v-col>
                        <v-checkbox
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="showWordStatus"
                            :label="$store.getters.getTextMap().show_bit_status"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
            <v-row>
                <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
export default {
    name:"AddBitwiseWordWidget",
    props:['widget','seq'],
    components:{
        InfoAlert,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm
    },
    data(){
        return {
            showDismissibleAlert:false,
            info:'',
            edit:false,
            loading:false,
            valid:false,
            api:'createWidgetForView',
            formType:'machineForm',
            parameter:null,
            machine:null,
            workspace:null,
            device:null,
            word:null,
            highStatusColor:null,
            lowStatusColor:null,
            showWordStatus:false,
            form:{},
            widgetForm:{},
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            colorOptions:[
                {label:"Red",value:"red"},
                {label:"Green",value:"#81C784"},
            ],
            rules:FormRules.rules,
        }
    },
    mounted(){
        this.init()
    },
    computed:{
        wordsOption(){
            let op=[]
            let g=this.$store.getters.getWords()
            for(let i of g){
                op.push( {value:i.word_id, label: i.name })
            }
            return op
        }
    },
    methods:{
        init(){
            if(this.widget && this.widget.widget_id && this.widget.view_id){
                this.edit=true
                this.widgetForm=Object.assign({},this.widget)
                this.api='updateWidget'
                if(!(this.widgetForm.meta)){
                    this.widgetForm.meta={data:{}}
                }else{
                    this.parameter=this.widgetForm.meta.data.parameter
                    this.workspace=this.widgetForm.meta.data.workspace
                    this.machine=this.widgetForm.meta.data.machine
                    this.device=this.widgetForm.meta.data.device
                    this.word=this.widgetForm.meta.data.word
                    this.highStatusColor=this.widgetForm.meta.data.highStatusColor
                    this.lowStatusColor=this.widgetForm.meta.data.lowStatusColor
                    this.showWordStatus=this.widgetForm.meta.data.showWordStatus
                }
            }else{
                if(this.widget.view_id){
                    this.api='createWidgetForView'
                    this.widgetForm=Object.assign({},this.widget)
                    this.widgetForm.meta={data:{}}
                    this.widgetForm.seq=this.seq?this.seq:0
                }
            }
        },
        updateParamList(value){
            this.parameter=value.parameter
            this.workspace=value.workspace
            if(this.formType=='machineForm'){
                this.machine=value.machine
            }else{
                this.device=value.device
            }
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.valid){
                this.loading=true
                this.form['parameter']=this.parameter
                this.form['workspace']=this.workspace
                this.form['machine']=this.machine
                this.form['device']=this.device
                this.form['word']=this.word
                this.form['highStatusColor']=this.highStatusColor
                this.form['lowStatusColor']=this.lowStatusColor
                this.form['showWordStatus']=this.showWordStatus
                this.widgetForm.meta.data=this.form
                axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                            this.loading=false
                            this.$emit('success')
                            this.$emit('close')
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                    }})
                .catch(err=>{
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onClose(){
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
    },
    watch:{
        parameter(){
            if(this.parameter.meta){
                this.word=this.parameter.meta
            }
        }
    }
}
</script>
<template>
  <span>
      <v-menu :max-width="300"  bottom left>
          <template v-slot:activator="{ on, attrs }">
   <v-btn small  fab :dark="$store.getters.getColorPalette().isDark" v-bind="attrs" v-on="on" :color="$store.getters.getColorPalette().foregroundColorCode" align="right"><v-icon>mdi-plus</v-icon></v-btn>
  </template>
          <v-toolbar style="width:300px;" dense flat :dark="$store.getters.getColorPalette().isDarkTheme" :color="$store.getters.getColorPalette().background2ColorCode">
              <span :style="{color:$store.getters.getColorPalette().accentCode}"><h3>{{$store.getters.getTextMap().diagram_pallette}}</h3></span>
          </v-toolbar>
          <v-list :color="$store.getters.getColorPalette().backgroundColorCode"
             :dark="$store.getters.getColorPalette().isDark">
              <span><v-subheader>{{$store.getters.getTextMap().add_component}}</v-subheader></span>
              <v-list-item>
                  <v-list-item-action>
                      <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-atom-variant
                      </v-icon>
                  </v-list-item-action>

                  <v-list-item-content >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-title :color="$store.getters.getColorPalette().accentCode" v-bind="attrs" v-on="on">
            {{$store.getters.getTextMap().components}}
          </v-list-item-title>
        </template>
        <v-list>
          <v-list-item @click="addTextBox">
            <v-list-item-title> {{$store.getters.getTextMap().add_text}}</v-list-item-title>
          </v-list-item>
            <v-list-item @click="addRTDTextBox">
            <v-list-item-title>{{$store.getters.getTextMap().add_realtime_data}}</v-list-item-title>
          </v-list-item>
            
      
        </v-list>
      </v-menu>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item  @click="dialog1 =!dialog1">
                  <v-list-item-action>
                      <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-atom-variant</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().shapes}}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

          <v-list-item @click="dialog2 =!dialog2">
                  <v-list-item-action>
                      <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-atom-variant
                      </v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().lines}}</v-list-item-title>
                  </v-list-item-content>   
              </v-list-item>
          <v-list-item @click="dialog3 =!dialog3">
                  <v-list-item-action>
                      <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-atom-variant
                      </v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().image_upload}}</v-list-item-title>
                  </v-list-item-content>   
              </v-list-item>
          <v-list-item @click="dialog4 =!dialog4">
                  <v-list-item-action>
                      <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-atom-variant
                      </v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().text_realtime_component}}</v-list-item-title>
                  </v-list-item-content>   
              </v-list-item>
          </v-list>

          <!-- Shapes -->
      </v-menu>
        <v-dialog
    v-model="dialog1"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
  <v-card  :dark="$store.getters.getColorPalette().isDark">
      
  <v-toolbar
       :dark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().foregroundColorCode"
      >
        <v-btn
          icon
        :dark="$store.getters.getColorPalette().isDark"
          @click="dialog1 = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$store.getters.getTextMap().shapes_pallette}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :dark="$store.getters.getColorPalette().isDark"
            text
            @click="dialog1 = false"
          >
          {{$store.getters.getTextMap().add}}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
       <v-card-title>{{$store.getters.getTextMap().click_choose}}</v-card-title>
        <v-card-subtitle></v-card-subtitle>
        <ShapesPallette v-on:value="emitImageList" :dialog1="dialog1" v-on:closeDialog="closeDialog1"/>
       
  </v-card>
  </v-dialog>




<!-- Lines  -->
            <v-dialog
    v-model="dialog2"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
  <v-card  :dark="$store.getters.getColorPalette().isDark">
      
  <v-toolbar
       :dark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().foregroundColorCode"
      >
        <v-btn
          icon
        :dark="$store.getters.getColorPalette().isDark"
          @click="dialog2 = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$store.getters.getTextMap().lines_pallette}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :dark="$store.getters.getColorPalette().isDark"
            text
            @click="dialog2 = false"
          >
          {{$store.getters.getTextMap().close}}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
       <v-card-title>{{$store.getters.getTextMap().choose_line}}</v-card-title>
        <LinesPallette v-on:lineSelect="handleSelectedLines" :dialog2="dialog2" v-on:closeDialog="closeDialog2"/>
  </v-card>
  </v-dialog>

  <!-- image -->
            <v-dialog
    v-model="dialog3"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
  <v-card  :dark="$store.getters.getColorPalette().isDark">
      
  <v-toolbar
       :dark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().foregroundColorCode"
      >
        <v-btn
          icon
        :dark="$store.getters.getColorPalette().isDark"
          @click="dialog3 = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$store.getters.getTextMap().choose_image}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :dark="$store.getters.getColorPalette().isDark"
            text
            @click="dialog3 = false"
          >
          {{$store.getters.getTextMap().close}}
           </v-btn>
         </v-toolbar-items>
       </v-toolbar>
        <v-card-title>{{$store.getters.getTextMap().upload_image_to_add}}</v-card-title>
        
         <ImageUploadForm v-on:image="handleuploadedImage" v-on:close="dialog3=false"/>

         <!-- <v-img
       v-if="temp"
       :src="temp"
       alt="Uploaded Image"
     ></v-img> -->
  </v-card>
  </v-dialog>

  <!-- Real time component -->
            <v-dialog
    v-model="dialog4"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
  <v-card  :dark="$store.getters.getColorPalette().isDark">
      
  <v-toolbar
       :dark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().foregroundColorCode"
      >
        <v-btn
          icon
        :dark="$store.getters.getColorPalette().isDark"
          @click="dialog4 = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$store.getters.getTextMap().choose_parameter}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :dark="$store.getters.getColorPalette().isDark"
            text
            @click="dialog4 = false"
          >{{$store.getters.getTextMap().close}}
           </v-btn>
         </v-toolbar-items>
       </v-toolbar>
        <v-card-title>{{$store.getters.getTextMap().add_realtime_textdata}}</v-card-title>
        
         <RealTimeStatusForm  v-on:close="dialog4=false" v-on:value="addTextBoxFrom"/>

         <!-- <v-img
       v-if="temp"
       :src="temp"
       alt="Uploaded Image"
     ></v-img> -->
  </v-card>
  </v-dialog>
  </span>
</template>

<script>
import ShapesPallette from "@/components/sld/ShapesPallette"
import LinesPallette from "@/components/sld/LinesPallette"
import ImageUploadForm from "@/components/forms/ImageUploadForm"
import RealTimeStatusForm from "@/components/sld/RealtimeStatusForm"




export default {
name: 'MenuList',
data () {
    return {
      dialog1: false,
      dialog2:false,
      dialog3:false,
      dialog4:false,
      temp:null,
      // imageList:[]
    }
  },
components: {
  ShapesPallette,
  LinesPallette,
 ImageUploadForm,
 RealTimeStatusForm,

},
methods:{
addTextBox(){
  this.$emit('addTextBox',{label:'Text Box',type:'text',x:10,y:10})

},
addRTDTextBox(){
  this.$emit('addTextBox',{label:'Real time Text',type:'rtd',x:10,y:100,param_id:null})

},
addTextBoxFrom(item){

  this.$emit('addTextBox',{x:10,y:10,...item})
  console.log({x:10,y:10,...item})

},
emitImageList(value){
     this.$emit('value',value)
  //console.log( this.imageList)

},


handleSelectedLines(selectedLines) {
  this.$emit('lineSelect', selectedLines);
  // this.imageList = [];
  // selectedLines.forEach(line => {
  //   let temp = { 
  //     name: line.name,         
  //     x: 0,                   
  //     y: 0,                    
  //   };
  //   this.imageList.push(temp);
  // });
  // localStorage.setItem('imageList', JSON.stringify(this.imageList));
},
handleuploadedImage(image) {
  this.$emit('imageUpload', image);
  this.dialog3=false
  // this.temp=image
  
},
 closeDialog1() {
    this.dialog1 = false;
  },
   closeDialog2() {
    this.dialog2 = false;
  },
}
}
</script>
<style scoped>





</style>
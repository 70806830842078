<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col cols="4">
                <v-card class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <!-- <v-icon large left>mdi-gas-station</v-icon> -->
                        {{$store.getters.getTextMap().water_use_intensity}}
  
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8">
                                <h2>40.2</h2><h3>ltr/ft2</h3>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <!-- <v-icon large left>mdi-currency-rupee</v-icon> -->
                        {{$store.getters.getTextMap().average_water_cost}}

                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8">
                                <h2>329957</h2><h3>₹</h3>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <!-- <v-icon large left>mdi-gas-station</v-icon> -->
                        {{$store.getters.getTextMap().water_use_efficiency}}
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8">
                                <h2>1475</h2><h3>Kg/ltr</h3>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-card flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-row>
                        <v-col  align="right" class="mx-1">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                                <v-btn small>PM</v-btn>
                                <v-btn small>AM</v-btn>
                            </v-btn-toggle>
                        </v-col>
                     </v-row>
                    <div ref="barvis"></div>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-row>
                        <v-col  align="right" class="mx-1">
                            <!-- <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                                <v-btn small>PM</v-btn>
                                <v-btn small>AM</v-btn>
                            </v-btn-toggle> -->
                        </v-col>
                     </v-row>
                    <div ref="heatvis"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import Plotly from 'plotly.js'
export default {
    name:'ESGModuleInsights',
    components:{
        InfoAlert,
    },
    data(){
        return {
            graphType:'pm',
            info:'',
            loading:false,
            showDismissibleAlert:false,
            
            
        }
    },
    computed:{
        theme(){
            return this.$store.state.theme
        },
        layout(){
            return {
                title:'Water Usage Time of The Day',
                colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode+'00',
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode+'00',
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        // showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
        },
        layoutHeat(){
            return {
                        title: 'Water Usage Heat Map',
                        font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'00',
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode+'00',
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -60,
                        zeroline: false,
                        // showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }

                    }
        }
    },
    mounted(){
        this.CreateWaterUsageChart()
        this.createWaterUsageHeatMap()
    },
    methods:{
        CreateWaterUsageChart(){
            // this.layout['title']= 'Water Usage Time of The Day'
            // this.layout['yaxis']['title']='%'
            var data = []
            if(this.graphType===0){
                data = [
                {
                    x: ['12','1','2','3','4','5','6','7','8','9','10','11'],
                    y:[3,3,4,10,17,22,40,50,58,70,77,81],
                    type: 'bar'
                }
                ];
            }else{
                data = [
                {
                    x: ['12','1','2','3','4','5','6','7','8','9','10','11'],
                    y:[8,3,16,10,27,12,40,50,38,70,77,81],
                    type: 'bar'
                }
                ];
            }
            Plotly.newPlot(this.$refs.barvis, data, this.layout, {displaylogo: false});
        },
        createWaterUsageHeatMap(){
            // this.layoutHeat['title']= 'Water Usage Heat Map'

            var data = [
                {
                    z: [[87, 15, 34, 46, 78, 69, 53, 41, 62, 37, 83, 30, 23, 60, 12, 45, 95, 72, 28, 90, 6, 77, 39, 64, 52, 19, 96, 1, 97, 89]
                    ,[71, 99, 84, 26, 85, 57, 3, 9, 54, 16, 59, 94, 25, 7, 56, 40, 92, 20, 80, 44, 70, 50, 74, 33, 73, 61, 43, 63, 10, 38]
                    ,[65, 67, 81, 76, 13, 79, 31, 68, 91, 5, 47, 11, 35, 29, 27, 32, 51, 75, 86, 88, 4, 2, 48, 93, 21, 36, 17, 98, 49, 42]
                    ,[87, 15, 34, 46, 78, 69, 53, 41, 62, 37, 83, 30, 23, 60, 12, 45, 95, 72, 28, 90, 6, 77, 39, 64, 52, 19, 96, 1, 97, 89]
                    ,[65, 67, 81, 76, 13, 79, 31, 68, 91, 5, 47, 11, 35, 29, 27, 32, 51, 75, 86, 88, 4, 2, 48, 93, 21, 36, 17, 98, 49, 42]
                    ,[71, 99, 84, 26, 85, 57, 3, 9, 54, 16, 59, 94, 25, 7, 56, 40, 92, 20, 80, 44, 70, 50, 74, 33, 73, 61, 43, 63, 10, 38]
                    ,[71, 99, 84, 26, 85, 57, 3, 9, 54, 16, 59, 94, 25, 7, 56, 40, 92, 20, 80, 44, 70, 50, 74, 33, 73, 61, 43, 63, 10, 38]],
                    x:["w1","w2","w3","W4","w5","w6","w7","w8","w9","w10",'w11','w12','w13','w14','w15','w16','w17','w18','w19','w20','w21','w22','w23','w24','w25','w26','w27','w28','w29','w30'],
                    y:["Mon","Tue","Wed","Thur","Fri","Sat","Sun"],
                    type: 'heatmap'
                }
            ];
//             var data = [
//   {
//     z: [[1, null, 30, 50, 1], [20, 1, 60, 80, 30], [30, 60, 1, -10, 20]],
//     x: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
//     y: ['Morning', 'Afternoon', 'Evening'],
//     type: 'heatmap',
//     hoverongaps: false
//   }
// ];
            Plotly.newPlot(this.$refs.heatvis, data,this.layoutHeat, {displaylogo: false});
        }
    },
    watch:{
        graphType: function (){
            this.CreateWaterUsageChart()
        },
        theme(){
            console.log('theme update')
                this.CreateWaterUsageChart()
                this.createWaterUsageHeatMap()
            },
           
    }
}
</script>

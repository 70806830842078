<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon color="red" small>mdi-trash-can</v-icon></v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text  @click="update = false">{{$store.getters.getTextMap().close}}
</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddProcessStatusBasedWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta.process_ids" >
            <v-row>
                <v-col>
                    <v-card-title>{{ widget.label }}</v-card-title>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-switch
                        small
                        inset
                        style="transform: scale(0.8);"
                        color="success"
                        v-model="showTable"
                        :label="$store.getters.getTextMap().show_details"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-row class="pa-3">
                    <v-col align="center" align-self="center" @click="showStatusByProcesses('running')">
                        <h4 class="count-header">{{$store.getters.getTextMap().running}}</h4>
                        <span class="count">{{ count['running'] }}</span>
                    </v-col>
                    <v-divider vertical/>
                    <v-col align="center" align-self="center" @click="showStatusByProcesses('idling')">
                        <h4  class="count-header">{{$store.getters.getTextMap().idling}}</h4>
                        <span class="count">{{ count['idling'] }}</span>
                    </v-col>
                    <v-divider vertical/>
                    <v-col align="center" align-self="center"  @click="showStatusByProcesses('offline')">
                        <h4  class="count-header">{{$store.getters.getTextMap().offline}}</h4>
                        <span class="count">{{ count['offline'] }}</span>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="mt-2" v-if="showTable" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-row>
                    <v-col>
                        <div>
                            <v-simple-table height="200px" :style="{background: 'transparent'}" >
                            <template v-slot:default v-if="this.widget && this.widget.meta && ((this.widget.meta.statusFor && this.widget.meta.statusFor==='machine') ||(this.widget.meta.machine_ids && this.widget.meta.machine_ids.length>0))">
                                    <thead>
                                        <tr >
                                            <th :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-left"><strong>{{ widget.meta.header_label?widget.meta.header_label:'Process/Machine' }}</strong></th>
                                            <th :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong>{{$store.getters.getTextMap().status}}</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="item" v-for="item in widget.meta.machine_ids">
                                            <td ><strong>{{getMachineNameById(item)}}</strong></td>
                                            <td :class="' white--text  pa-1 text-center text-subtitle-2'" ><span class="statusHighlight" :style="{ 'color': machineStatusColor }"><getMachineStatus :machine="{machine_id:item}" :display_msg="' '" v-on:status="createCountOfProcessesByStatus" v-on:color="changeMachineStatusColor"/></span></td>
                                        </tr>
                                    </tbody>
                                </template> 
                            <template v-slot:default v-else>
                                    <thead>
                                        <tr >
                                            <th :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-left"><strong>{{ widget.meta.header_label?widget.meta.header_label:'Process' }}</strong></th>
                                            <th :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong>{{$store.getters.getTextMap().status}}</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="item" v-for="item in Object.keys(tableData)">
                                            <td ><strong>{{ getProcessNameById(item)}}</strong></td>
                                            <td :class="' white--text  pa-1 text-center text-subtitle-2'" ><strong><ProcessStatusComponent :rawData="tableData[item]" v-on:status="createCountOfProcessesByStatus"/></strong></td>
                                        </tr>
                                    </tbody>
                                </template> 
                            </v-simple-table>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div v-else
            :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
            <v-icon small>mdi-hail</v-icon>
            <h5>
{{$store.getters.getTextMap().not_configured}}
</h5>
        </div>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import DateUtils from '@/utillities/DateUtils';
import axios from 'axios';
import AddProcessStatusBasedWidget from '@/components/crud_components/widgets/AddProcessStatusBasedWidget';
import ProcessStatusComponent from '@/components/ProcessStatusComponent';
import getMachineStatus from '@/components/getMachineStatus.vue';
export default
    {
    name: 'ProcessStatusBasedWidget',
    props: ['widget','edit'],
    components: {
            AddProcessStatusBasedWidget,
            ProcessStatusComponent,
            getMachineStatus,
            InfoAlert
        },
        data() {
            return {
                title: 'Process Based Widget',
                dateUtils: DateUtils,
                processDetails:[],
                dbData: [],
                tableData: {},
                showTable:true,
                loading: false,
                status:null,
                statusFilter:'all',
                x:0,
                y:0,
                info: '',
                dialog: false,
                showDismissibleAlert: false,
                update: false,
                count:{
                    offline:0,
                    running:0,
                    idling:0,
                },
                showMenu: false,
                machineStatusColor:this.$store.getters.getColorPalette().background2ColorCode
            }
        },
        mounted() {
            if(this.widget && this.widget.meta && ((this.widget.meta.statusFor && this.widget.meta.statusFor==='process') ||(this.widget.meta.process_ids && this.widget.meta.process_ids.length>0)))
                this.getProcessDetailsById()
                this.getWidgetData()
        },
        methods: {
            changeMachineStatusColor(value){
                if(value){
                    this.machineStatusColor=value
                }
            },
            show(e) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
            },
            showStatusByProcesses(status){
                this.statusFilter=status
            },
            createCountOfProcessesByStatus(value) {
                if (value.newStatus !== 'loading') {
                    // Initialize count if not exists
                    this.count = this.count || {};

                    // Initialize count for each status if not exists
                    this.count[value.newStatus] = this.count[value.newStatus] || 0;
    

                    this.count[value.newStatus]+=1
                    if(value.oldStatus!=='loading'){
                        this.count[value.oldStatus]-=1
                    }

                    // // Update count based on the provided value
                    // this.count['running'] += value === 'running' ? 1 : 0;
                    // this.count['offline'] += value === 'offline' ? 1 : 0;
                    // this.count['idling'] += value === 'idling' ? 1 : 0;

                    // Ensure counts are non-negative
                    this.count[value.newStatus] = Math.max(0, this.count[value.newStatus]);
                }
            },
            getProcessNameById(process_id){
                let name=''
                if(process_id){
                    for(let i of this.processDetails){
                        if(i.process_id==process_id){
                            name=i.name
                        }
                    }
                }
                return name
            },
            getMachineNameById(machine_id){
                let name=machine_id
                if(machine_id){
                    let machineDetails = this.$store.getters.getMachineById(machine_id)
                    name=machineDetails.name
                }
                return name
            },
            createTableData(){
                this.tableData={}
                for(let i of Object.keys(this.dbData)){
                    for(let j of this.dbData[i]){
                        if(!this.tableData[j.process_id]){
                            this.tableData[j.process_id]=[]
                        }
                        this.tableData[j.process_id].push({'process_status':j.process_status,'machine_id':j.machine_id,'tag_type':j.tag_type,'operator':j.operator,'operand':j.operand})
                    }
                }
            },
            getProcessDetailsById(){
                if (this.widget.meta && this.widget.meta.process_ids && this.widget.meta.process_ids.length>0) {
                    let process_ids=[]
                    let process_id_set=new Set([])
                    for(let i of this.widget.meta.process_ids){
                        process_id_set.add(i)
                    }
                    process_ids=[...process_id_set]
                    axios.post(this.$store.state.api + 'getProcessDetailsByProcessIds', {process_ids}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.processDetails=response.data.details
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.loading = false
                    });
                }
            },
            async getWidgetData() {
                if (this.widget.meta && this.widget.meta.process_ids && this.widget.meta.process_ids.length>0) {
                    this.loading = true
                    this.dbData = {}
                    let temp = []
                    try {
                        let payload = {};
                        for(let i of this.widget.meta.process_ids){
                            payload['process_id']=i
                            const response = await axios.post(this.$store.state.api + "getProcessStatusRulesByProcessId", payload, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}});
                            if (response.data.status === 'success') {
                                temp[i] = response.data.rules
                                this.loading = false
                            } else {
                                this.info = response.data.msg
                                this.showDismissibleAlert = true
                                this.loading = false
                            }
                        }
                        this.dbData=temp
                    } catch (err) {
                        this.info = err,
                        this.showDismissibleAlert = true
                        this.loading = false
                    }
                    this.createTableData()
                    // console.log(this.dbData)
                }
            },
            deleteWidget() {
                this.loading = true
                axios.post(this.$store.state.api + 'deleteWidget', this.widget, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.info = "Widget Deleted"
                        this.$emit('update')
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                });
            },
        },
        watch:{
            showTable(){
                if(this.showTable){
                    this.count['running']=0
                    this.count['idling']=0
                    this.count['offline']=0
                }
            }
        }
    }
</script>
<style scoped>
.count{
    font-size:30px;
    margin:30px;
    font-weight:bolder;
}
.statusHighlight{
    border-radius:3px;
}
</style>

import English from "@/utillities/languages/English";

export default {
  ...English,
  "accept": "قبول",
"reject": "رفض",
"move_to_next_stage": "الانتقال إلى المرحلة التالية",
"save_as_draft": "حفظ كمسودة",
"save_and_submit": "حفظ وإرسال",
"closure": "إغلاق",
"duration": "مدة",
"refill_request": "طلب إعادة تعبئة",
"is_be_cancelled": "سيتم إلغاؤه",
"delete_this": "حذف هذا",
"event": "حدث",

    "for": "لـ",
    "of": "من",
    "downtime": "وقت التوقف",

    "metrics": "مقاييس",
    "oee_by_day": "OEE حسب اليوم",

    "ideal_cycle_time": "الوقت المثالي للدورة",
    
    "minutes": "الدقائق",
"production_time": "وقت الإنتاج",
"back": "عودة",
    "down_times": "أوقات التوقف",

    "top_5_reasons": "أهم 5 أسباب",
    "top_5_categories": "أهم 5 فئات",
    "enter_apparent_energy_manually": "أدخل الطاقة الظاهرة يدويًا",

    "oee": "OEE",
    "reason": "السبب",
    "add_downtime_reason": "إضافة سبب التوقف",

    "default_target": "الهدف الافتراضي",
    
    "enter_in_minutes": "أدخل في دقائق",
    "custome_fields": "الحقول المخصصة",
    "enter_data_in_minutes": "أدخل البيانات في دقائق",
    "enter_data_in_hours": "أدخل البيانات في ساعات",
    "create_tod_billing_widget": "إنشاء أداة فوترة TOD",
    "edit_tod_billing_widget": "تحرير أداة الفواتير TOD",
    "choose_from_machine_groups": "اختر من مجموعات الآلات",
    "tod_billing": "تود الفواتير",
    "choose": "يختار",
    "create_shift_based_machine_usage_log": "إنشاء سجل استخدام الجهاز",
    "shift_based_machine_usage_log": "سجل استخدام الماكينة",
    "rejected": "مرفوض",
    "machine_operator": "عامل الجهاز",
    "production_end_timestamp": "وقت نهاية الإنتاج",
    "production_start_timestamp": "وقت بدء الإنتاج",
    "running_time": "وقت التشغيل",
    "idle_time": "وقت الخمول",
    "down_time": "الوقت لأسفل",
    "date_row_index": "صف التاريخ",
    "date_position": "موقف التاريخ",
    "save": "يحفظ",
    "discard": "ينبذ",
    "adjust": "يُعدِّل",
    "safety_incident_sheet": "ورقة حادثة السلامة",
    "safety_inspection_sheet": "ورقة فحص السلامة",
    "ghg_emission_logs": "سجلات انبعاثات غازات الدفيئة",
    "add_ghg_emission_data": "أضف سجلات انبعاثات غازات الدفيئة",
    "ghg_scope_conversion": "تحويل نطاق غازات الدفيئة",
    "create_ghg_scope_conversion": "إنشاء تحويل نطاق غازات الدفيئة",
    "ghg_data_logs": "سجلات بيانات انبعاثات غازات الدفيئة",
    "choose_inspection_type_optional": "اختر نوع التفتيش (اختياري)",
    "supplier_code": "رمز المورد",
    "contact": "اتصال",
    "point_of_contact": "نقطة الاتصال",
    "quantity_supplied": "الكمية المقدمة",
    "create_product": "إنشاء المنتج",
    "products": "منتجات",
    "data": "بيانات",
    "timezone": "المنطقة الزمنية",
    "tariffs": "التعريفات",
    "days_delta": "أيام دلتا",
    "machine_groups": "مجموعات الآلات",
    "param_groups": "مجموعات البارميتر",
    "user_groups": "مجموعات المستخدمين",
    "topic_prefix": "بادئة الموضوع",
    "message_direction": "اتجاه الرسالة",
    "safety_inspection": "فحص السلامة",
    "write_inspection_log": "اكتب سجل التفتيش",
    "inspection_id": "معرف التفتيش",
    "inspector_id": "معرف المفتش",
    "inspection_date": "تاريخ التفتيش",
    "inspection_type": "نوع التفتيش",
    "findings": "النتائج",
    "actions_required": "الإجراءات المطلوبة",
    "followup_date": "تاريخ المتابعة",
    "status": "حالة",
    "safety_incident": "حادثة السلامة",
    "write_incident": "اكتب الحادث",
    "incident_description": "وصف الحادث",
    "severity": "خطورة",
    "accident": "حادثة",
    "accident_with_loss": "حادث مع الخسارة",
    "closed": "مغلق",
    "corrective_action": "الإجراء التصحيحي",
    "employee_id": "معرف الموظف",
    "apply": "يتقدم",
    "choose_location_optional": "اختر الموقع (اختياري)",
    "locations": "المواقع",
    "locations_event_types": "مواقع أنواع الأحداث",
    "create_tariff": "إنشاء تعريفة",
    "default_tariff_val": "قيمة التعريفة الافتراضية",
    "add_tariff_data": "إضافة بيانات التعريفة",
    "edit_tariff": "تحرير التعريفة",
    "delete_tariff": "حذف التعريفة",
    "tariff_value": "قيمة التعريفة",
    "machine_status_timeline": "الجدول الزمني لحالة الماكينة",
    "total_duration": "المدة الإجمالية",
    "generate": "يولد",
    "remove_machine": "إزالة الجهاز",
    "edit_machine_status_timeline_widget": "تحرير أداة الجدول الزمني لحالة الجهاز",
    "add_machine_Status__timeline_widget": "أضف أداة الجدول الزمني لحالة الجهاز",
    "select_timeframe": "حدد الإطار الزمني",
    "timeframe_type_shift": "يحول",
    "timeframe_type_custom": "مخصص",
    "timeframe_type_yesterday": "أمس",
    "edit_report_details": "تحرير تفاصيل التقرير",
    "download_current_template": "تنزيل القالب الحالي",
    "date_column_index": "عمود التاريخ (الفهرس)",
    "select_area": "حدد المنطقة",
    "select_shift": "حدد التحول",
    "dashboard_heading": "لوحة القيادة",
    "production_params": "المعاملات الإنتاج",
    "no_modules_configured": "لم يتم تكوين وحدات",
    "dashboard_panel": "لوحة القيادة",
    "choose_type": "اختر النوع",
    "name_is_required": "الاسم مطلوب",
    "description_is_required": "الوصف مطلوب",
    "required": "مطلوب",
    "you_have_to_select_something": "عليك اختيار شيء ما",
    "name_must_be_less_than_30_characters": "يجب أن يكون الاسم أقل من 30 حرفًا",
    "name_must_be_less_than_25_characters": "يجب أن يكون الاسم أقل من 25 حرفًا",
    "name_must_be_less_than_50_characters": "يجب أن يكون الاسم أقل من 50 حرفًا",
    "description_must_be_less_than_35_characters": "يجب أن يكون الوصف أقل من 35 حرفًا",
    "description_must_be_less_than_55_characters": "يجب أن يكون الوصف أقل من 55 حرفًا",
    "number_must_be_less_than_6_digits": "يجب أن يكون الرقم أقل من 6 أرقام",
    "number_must_be_less_than_8_digits": "يجب أن يكون الرقم أقل من 8 أرقام",
    "number_must_be_less_than_7_digits": "يجب أن يكون الرقم أقل من 7 أرقام",
    "panel": "لوحة",
    "gauge": "كَيّل",
    "param": "بارام",
    "machine": "آلة",
    "add_panel_to_dashboard": "إضافة لوحة إلى لوحة القيادة",
    "edit_dashboard_panel": "تحرير لوحة لوحة القيادة",
    "param_type": "نوع بارام",
    "workspace": "مساحة العمل",
    "device": "جهاز",
    "name": "اسم",
    "language": "لغة",
    "time_duration": "مدة الوقت",
    "submit": "يُقدِّم",
    "cancel": "يلغي",
    "close": "يغلق",
    "usage": "الاستخدام",
    "peak_value": "قيمة الذروة",
    "current_data": "البيانات الحالية",
    "parameter": "المعلمة",
    "computed_param": "param محسوبة",
    "derived_param": "بارام مشتق",
    "t4_days": "4 أيام",
    "last_7_days": "آخر 7 أيام",
    "last_30_days": "آخر 30 يومًا",
    "last_60_days": "آخر 60 يومًا",
    "last_90_days": "آخر 90 يومًا",
    "last_180_days": "آخر 180 يومًا",
    "last_365_days": "LAT 365 يومًا",
    "last_1_hour": "آخر ساعة",
    "last_3_hours": "آخر 3 ساعات",
    "last_6_hours": "آخر 6 ساعات",
    "reorder_views": "إعادة ترتيب",
    "create_view": "يخلق",
    "clear_view_name_filter": "مرشحات واضحة",
    "view_type": "عرض النوع",
    "description": "وصف",
    "table_view": "عرض الجدول",
    "widget_view": "عرض القطعة",
    "dynamic_timeframed_widget_view": "عرض عنصر واجهة مستخدم ديناميكي",
    "diagram_view": "عرض الرسم البياني",
    "energy_usage_view": "عرض استخدام الطاقة",
    "machine_scroll_view": "عرض تمرير الجهاز",
    "param_scroll_view": "بارام عرض التمرير",
    "consolidated_view": "عرض موحد",
    "air_quality_view": "عرض جودة الهواء",
    "view_auto_change": "عرض تغيير السيارات",
    "no_views_configured": "لم يتم تكوين طرق عرض",
    "add_to_dashboard": "أضف إلى لوحة القيادة",
    "edit_view_name": "تحرير اسم العرض",
    "edit_view": "تحرير العرض",
    "refresh": "ينعش",
    "create_machine": "إنشاء آلة",
    "add_machine": "إضافة آلة",
    "sequence": "تسلسل",
    "machine_type": "نوع الآلة",
    "physical_machine": "آلة المادية",
    "virtual_machine": "الجهاز الظاهري",
    "positive_workspace": "مساحة عمل إيجابية",
    "positive_machine": "آلة إيجابية",
    "negative_workspace": "مساحة العمل السلبية",
    "negative_machine": "آلة سلبية",
    "label": "ملصق",
    "positive_machine_Ids": "معرف الجهاز الإيجابي",
    "negative_machine_Ids": "معرف الجهاز السلبي",
    "trend_analysis_tools": "أدوات تحليل الاتجاه",
    "energy_consumption_by_machines": "استهلاك الطاقة بواسطة الآلات",
    "Create_report": "إنشاء تقرير",
    "view_report": "عرض التقرير",
    "tarrif_on": "تعريفة على",
    "active_energy": "الطاقة النشطة",
    "apparent_energy": "طاقة واضحة",
    "automate_report": "أتمتة التقرير",
    "add_root_machine": "أضف آلة الجذر",
    "add_common_machine": "إضافة آلة شائعة",
    "notification_groups": "مجموعات الإخطار",
    "add_machines_to_list": "أضف آلات إلى القائمة",
    "lists": "قوائم",
    "machine_list": "قائمة الآلات",
    "data_historian": "مؤرخ البيانات",
    "timeframed_trends": "الاتجاهات متفق عليها",
    "dual_axis_trend_comparison": "مقارنة اتجاه المحور المزدوج",
    "granular24_hrs_trend": "الحبيبية 24 ساعة الاتجاه",
    "machinewise_trend": "الآلة الحكيمة الاتجاه",
    "basic_statistical_trends": "الاتجاهات الإحصائية الأساسية",
    "log_analysis": "تحليل السجل",
    "aqi_comparison_panel": "لوحة المقارنة AQI",
    "time_of_day_trend": "اتجاه الوقت من اليوم",
    "param_historical_comparisons": "بارام مقارنات تاريخية",
    "energy_usage_trends": "اتجاهات استخدام الطاقة",
    "machine_name": "اسم الجهاز",
    "param_name": "اسم بارام",
    "trend_view": "عرض الاتجاه",
    "raw": "خام",
    "trend": "اتجاه",
    "normalised": "تطبيع",
    "from_date": "من التاريخ",
    "to_date": "حتى الآن",
    "from": "من",
    "to": "ل",
    "hour": "ساعة",
    "minute": "دقيقة",
    "generate_report": "توليد تقرير",
    "time_range": "نطاق الوقت",
    "no_data_available": "لا توجد بيانات متاحة",
    "daily": "يوميًا",
    "monthly": "شهريا",
    "weekly": "أسبوعي",
    "week": "أسبوع",
    "yearly": "سنوي",
    "device_name": "اسم الجهاز",
    "machine_wise_comparison": "مقارنة الحكمة الآلة",
    "stats_detailed_report": "الإحصائيات تقرير مفصل",
    "log_type": "نوع السجل",
    "choose_Log": "اختر السجل",
    "area": "منطقة",
    "aq_device": "جهاز AQ",
    "nebeskie_labs_office": "مكتب Nebeskie Labs",
    "date_range": "Date_range",
    "this_year": "هذا العام",
    "last 6 months": "آخر 6 أشهر",
    "last quater": "الربع الأخير",
    "mtd": "mtd",
    "ytd": "YTD",
    "yesterday": "أمس",
    "custom": "مخصص",
    "run_report": "التقرير تشغيل",
    "create_report": "إنشاء تقرير",
    "usage_report": "تقرير الاستخدام",
    "consolidated_report": "تقرير موحد",
    "dynamic_excel_report": "تقرير Excel الديناميكي",
    "energy_usage_report": "تقرير استخدام الطاقة",
    "create_action": "إنشاء إجراء",
    "action_type": "نوع العمل",
    "notification_group": "مجموعة الإخطار",
    "address": "عنوان",
    "number_of_registers": "عدد السجلات",
    "value": "قيمة",
    "send_notification": "إرسال إشعار",
    "write_parameter": "اكتب المعلمة",
    "relays": "مرحلات",
    "relay_1": "ترحيل 1",
    "relay_2": "ترحيل 2",
    "relay_3": "ترحيل 3",
    "relay_4": "ترحيل 4",
    "email_must_be_valid": "يجب أن يكون البريد الإلكتروني صالحًا",
    "it_should_be_a_number": "يجب أن يكون رقمًا",
    "it_should_be_a_valid_number": "يجب أن يكون رقمًا صالحًا",
    "operator": "المشغل",
    "operand": "المعامل",
    "targets": "الأهداف",
    "target_value": "القيمة المستهدفة",
    "wait_time_seconds": "وقت الانتظار (ثانية)",
    "greater_than": "أكبر من",
    "modulus_greater_than": "معامل أكبر من",
    "lesser_than": "أقل من",
    "modulus_lesser_than": "معامل أقل من",
    "equal_to": "يساوي",
    "not_equal_to": "لا تساوي",
    "modulus_equal_to": "معامل يساوي",
    "greater_than_equal_to": "أكبر من المتساوية",
    "modulus_greater_than_equal_to": "معامل أكبر من تساوي",
    "lesser_than_equal_to": "أقل من يساوي",
    "modulus_lesser_than_equal_to": "معامل أقل من يساوي",
    "bit_high": "ارتفاع قليلا",
    "bit_low": "قليلا منخفضة",
    "it_should_be_a_positive_whole_number": "يجب أن يكون رقمًا كاملاً إيجابيًا",
    "it_should_be_a_positive": "يجب أن تكون إيجابية",
    "create_process": "إنشاء العملية",
    "edit_process": "تحرير العملية",
    "status_rule": "قاعدة الحالة",
    "create_process_status_rule": "إنشاء قاعدة حالة العملية",
    "tag_type": "نوع العلامة",
    "process_status": "حالة العملية",
    "machines": "الآلات",
    "running": "جري",
    "idling": "تسكع",
    "offline": "دون اتصال",
    "no_machines_configured": "لم يتم تكوين آلات",
    "delete_process": "حذف العملية",
    "add_machine_to_process": "أضف آلة للمعالجة",
    "delete_premise_area": "حذف منطقة الفرضية",
    "add": "يضيف",
    "add_to_list": "إضافة إلى القائمة",
    "search": "يبحث",
    "switch_to": "التبديل إلى",
    "switch_to_base_org": "التبديل إلى org base",
    "switch": "يُحوّل",
    "create_new_organisation": "إنشاء منظمة جديدة",
    "organisation_name": "اسم المنظمة",
    "organisation_type": "نوع المنظمة",
    "country_code": "رمز البلد",
    "country": "دولة",
    "state": "ولاية",
    "clear": "واضح",
    "change_organisation": "تغيير المنظمة",
    "organisation_id": "معرف المنظمة",
    "update_your_oem_logo": "تحديث شعار OEM الخاص بك",
    "select_a_logo_to_upload": "حدد شعارًا للتحميل",
    "submit_logo": "إرسال الشعار",
    "create_workspace": "إنشاء مساحة عمل",
    "hub_id": "معرف HUB",
    "name_must_be_less_than_45_characters": "يجب أن يكون الاسم أقل من 45 حرفًا",
    "id_is_required": "المعرف مطلوب",
    "no_space_allowed": "لا يوجد مساحة مسموح بها",
    "id_must_be_less_than_6_characters": "يجب أن يكون المعرف أقل من 6 أحرف",
    "edit_workspace": "تحرير مساحة العمل",
    "delete_workspace": "حذف مساحة العمل",
    "add_modbus_device": "أضف جهاز Modbus",
    "add_device": "أضف الجهاز",
    "oops": "Ooops !!",
    "ok": "نعم",
    "slave_id": "معرف الرقيق",
    "device_type": "نوع الجهاز",
    "connection_type": "نوع الاتصال",
    "mGate": "Mgate",
    "make": "يصنع",
    "model": "نموذج",
    "name_must_be_less_than_33_characters": "يجب أن يكون الاسم أقل من 33 حرفًا",
    "name_must_be_less_than_11_characters": "يجب أن يكون الاسم أقل من 11 حرفًا",
    "it_should_be_a_valid_slave_id": "يجب أن يكون معرف العبيد صالح",
    "name_must_be_less_than_4_digits": "يجب أن يكون الاسم أقل من 4 أرقام",
    "modbus_tcp": "Modbus TCP",
    "modbus_rtu": "Modbus RTU",
    "add_virtual_device": "أضف الجهاز الظاهري",
    "add_mqtt_device": "أضف جهاز MQTT",
    "topic": "عنوان",
    "identifier": "المعرف",
    "identifier_value": "قيمة المعرف",
    "name_must_be_less_than_20_characters": "يجب أن يكون الاسم أقل من 20 حرفًا",
    "no_special_characters_or_space_allowed": "لا يسمح بأحرف خاصة أو مساحة",
    "must_be_less_than_15_characters": "يجب أن يكون أقل من 15 حرفًا",
    "create_role": "إنشاء دور",
    "role": "دور",
    "role_is_required": "الدور مطلوب",
    "role_must_be_less_than_40_characters": "يجب أن يكون الدور أقل من 40 حرفًا",
    "role_should_not_contain_white_characters": "يجب ألا يحتوي الدور على أحرف بيضاء",
    "comparison": "مقارنة",
    "historical_time_of_day_comparison_parameter": "مقارنة وقت تاريخ اليوم - المعلمة",
    "device_parameter": "معلمة الجهاز",
    "date_1": "التاريخ 1",
    "date_2": "التاريخ 2",
    "tod_comparison_report": "تقرير المقارنة تود",
    "time_frame": "الإطار الزمني",
    "download_table": "جدول التنزيل",
    "table": "طاولة",
    "showMinMax_values": "إظهار قيم Min و Max",
    "usage_or_average": "الاستخدام أو المتوسط",
    "min_value": "قيمة دقيقة",
    "last_12_weeks": "آخر 12 أسبوعا",
    "last_6_months": "آخر 6 أشهر",
    "last_12_months": "آخر 12 شهرًا",
    "year_5": "5 سنوات",
    "machinewise_comparison": "مقارنة الحكمة الآلة",
    "timeFrame": "الإطار الزمني",
    "it_should_be_a_date": "يجب أن يكون موعدًا",
    "time_of_day_parameter": "وقت اليوم- المعلمة",
    "tod_report": "تود - تقرير",
    "in_this_timeframe": "في هذا الإطار الزمني",
    "average": "متوسط",
    "maximum": "الحد الأقصى",
    "minimum": "الحد الأدنى",
    "energy_usage_trend": "اتجاه استخدام الطاقة",
    "standard_deviation": "الانحراف المعياري",
    "statistical_mode": "الوضع الإحصائي",
    "production_logs": "سجلات الإنتاج",
    "capacity_logs": "سجلات السعة",
    "dynamic_logs": "سجلات ديناميكية",
    "pollutants": "الملوثات",
    "group_by": "مجموعة بواسطة",
    "generate_grouped_trends": "توليد اتجاهات مجمعة",
    "tCo2": "TCO2",
    "scope_1": "النطاق 1",
    "scope_2": "النطاق 2",
    "scope_3": "النطاق 3",
    "source": "مصدر",
    "type": "يكتب",
    "user_access_log": "سجل وصول المستخدم",
    "user_logs": "سجلات المستخدم",
    "fetch_logs": "جلب سجلات",
    "update_profile": "تحديث الملف الشخصي",
    "lang": "لانغ",
    "theme": "سمة",
    "phone": "هاتف",
    "update": "تحديث",
    "update_password": "تحديث كلمة المرور",
    "change_password": "تغيير كلمة المرور",
    "old_password": "كلمة المرور القديمة",
    "new_password": "كلمة المرور الجديدة",
    "confirm_password": "تأكيد كلمة المرور",
    "password_must_be_more_than_8_characters": "يجب أن تكون كلمة المرور أكثر من 8 أحرف",
    "passwords_do_not_match": "كلمات المرور لا تتطابق",
    "add_hub": "إضافة محور",
    "id_must_be_less_than_4_characters": "يجب أن يكون المعرف أقل من 4 أحرف",
    "add_maintainer": "أضف كاتب المشرف",
    "add_parent_org": "أضف Org Parent",
    "add_parent_to_organisation": "أضف الوالد إلى المنظمة",
    "parent_org_id": "معرف الوالدين",
    "add_maintainer_to_organization": "أضف مشرفًا إلى المنظمة",
    "maintainer_id": "معرف المشرف",
    "update_organisation_details": "تحديث تفاصيل المنظمة",
    "update_your_organization_details": "تحديث تفاصيل مؤسستك",
    "default_email": "البريد الإلكتروني الافتراضي",
    "picker_in_menu": "منتقي في القائمة",
    "param_diagnostics": "Param Diagnostics",
    "show_logo": "عرض شعار",
    "show_oem_logo": "عرض شعار OEM",
    "organisation_alias": "الاسم المستعار منظمة",
    "update_organisation_alias": "تحديث الاسم المستعار منظمة",
    "update_org_alias": "تحديث org الاسم المستعار",
    "update_your_organization_logo": "تحديث شعار مؤسستك",
    "select_a_logo": "حدد شعار",
    "update_logo": "تحديث شعار",
    "create_uam_user": "إنشاء مستخدم UAM",
    "overview": "ملخص",
    "batches": "دفعات",
    "track_batch": "تتبع دفعة",
    "batch_movement": "حركة الدُفعات",
    "production_planner": "مخطط الإنتاج",
    "show_unshipped_only": "تظهر شحن UN فقط",
    "view_logs": "عرض سجلات",
    "params": "params",
    "add_filter": "إضافة مرشح",
    "filters": "المرشحات",
    "batch_id": "batch_id",
    "show_details": "عرض التفاصيل",
    "tracing_logs": "سجلات تتبع",
    "quantity": "كمية",
    "location_event_type": "نوع الحدث الموقع",
    "fetch_plans": "إحضار الخطط",
    "sku": "SKU",
    "skus": "وحدات حفظ الأسهم",
    "code": "شفرة",
    "add_production_plans": "إضافة خطط الإنتاج",
    "date": "تاريخ",
    "from_time": "من الوقت",
    "to_time": "إلى الوقت",
    "activities": "أنشطة",
    "task_name": "اسم المهمة",
    "task_details": "تفاصيل المهمة",
    "start_date": "تاريخ البدء",
    "end_date": "تاريخ الانتهاء",
    "assignee": "المحاليل",
    "manager": "مدير",
    "activity": "نشاط",
    "activity_type": "نوع النشاط",
    "escalation_matrix": "مصفوفة التصعيد",
    "maintenance_activity": "نشاط الصيانة",
    "em_level": "em مستوى",
    "delay": "تأخير",
    "user_group": "مجموعة المستخدمين",
    "mins_5": "5 دقائق",
    "mins_10": "10 دقائق",
    "mins_15": "15 دقيقة",
    "mins_30": "30 دقيقة",
    "hour_1": "1 ساعة",
    "hour_6": "6 ساعات",
    "day_1": "يوم واحد",
    "day": "يوم",
    "day_3": "3 أيام",
    "insights": "رؤى",
    "create_emailer_group": "إنشاء مجموعة البريد الإلكتروني",
    "notification_type": "نوع الإخطار",
    "email": "بريد إلكتروني",
    "sms": "رسالة قصيرة",
    "create_machine_group": "إنشاء مجموعة الآلة",
    "create_param_group": "إنشاء مجموعة بارام",
    "create_user_group": "إنشاء مجموعة مستخدم",
    "create_module": "إنشاء الوحدة النمطية",
    "busbar_temp_module": "وحدة بوسار درجة الحرارة",
    "power_quality_module": "وحدة جودة الطاقة",
    "esg_module": "وحدة ESG",
    "edge_hub": "محور الحافة",
    "tolerance": "تسامح",
    "ambient_temp_input_type": "نوع إدخال درجة الحرارة المحيط",
    "ambient_temp": "درجة الحرارة المحيطة",
    "workspace_ambient_temp": "مساحة العمل درجة الحرارة المحيطة",
    "device_ambient_temp": "الجهاز درجة الحرارة المحيطة",
    "r_temperature": "ص درجة الحرارة",
    "y_temperature": "درجة الحرارة",
    "b_temperature": "درجة حرارة ب",
    "n_temperature": "درجة الحرارة",
    "rated_current": "تصنيف التيار",
    "no_load_current": "لا حمولة تيار",
    "r_current": "ص تيار",
    "y_current": "y الحالي",
    "b_current": "B الحالي",
    "n_current": "N الحالي",
    "delete_activity": "حذف النشاط",
    "substation_voltage_kv": "جهد الفرعية (KV)",
    "transformer_rating_mva": "تصنيف المحولات (MVA)",
    "percentage_impedance_of_the_transformer": "٪ مقاومة للمحول",
    "voltage_rv_y": "الجهد RV (Y)",
    "voltage_yb_v": "الجهد YB (V)",
    "voltage_br_v": "الجهد BR (V)",
    "current_r_a": "R (أ) الحالي (أ)",
    "current_y_a": "y الحالي (أ)",
    "current_b_a": "الحالي ب (أ)",
    "thd_i": "thd أنا",
    "thd_v": "thd v",
    "thd_i1": "thd i1",
    "active_power_kw": "القوة النشطة (KW)",
    "apparent_power_kva": "القوة الظاهرة (KVA)",
    "reactive_power_kvar": "القوة التفاعلية (KVAR)",
    "active_energy_kwh": "الطاقة النشطة (KWH)",
    "apparent_energy_kvah": "الطاقة الظاهرة (كيفاه)",
    "pf": "PF",
    "text": "نص",
    "energy_source": "مصدر الطاقة",
    "scope": "نِطَاق",
    "system_logs": "سجلات النظام",
    "system_logbook": "دفتر سجل النظام",
    "esg_data_log": "سجل بيانات ESG",
    "add_esg_data": "إضافة بيانات ESG",
    "add_esg_data_log": "إضافة سجل بيانات ESG",
    "source_type": "نوع المصدر",
    "unit_of_measurement": "وحدة القياس",
    "from_date_optional": "من التاريخ (اختياري)",
    "emission_factor": "عامل الانبعاث (KGCO2)",
    "create_dynamic_logger": "إنشاء مسجل ديناميكي",
    "dynamic_log": "سجل ديناميكي",
    "primary_field_label": "تسمية الحقل الأولية",
    "primary_field_type": "نوع الحقل الأساسي",
    "primary": "أساسي",
    "additional_fields": "حقول إضافية",
    "field_label": "تسمية الحقل",
    "field_type": "نوع الحقل",
    "choose_list": "اختر القائمة",
    "list": "قائمة",
    "create_capacity_logger": "إنشاء سجل السعة",
    "capacity_log": "سجل السعة",
    "create": "يخلق",
    "create_production_logger": "إنشاء سجل الإنتاج",
    "production_log": "سجل الإنتاج",
    "production_logger": "سجل الإنتاج",
    "input_type": "نوع الإدخال",
    "input": "مدخل",
    "input_name": "اسم الإدخال",
    "output_type": "نوع الإخراج",
    "output": "الإخراج",
    "output_name": "اسم الإخراج",
    "output_params": "الإخراج params",
    "compute_production": "حساب الإنتاج",
    "compute_energy": "حساب الطاقة",
    "energy_param_type": "نوع param الطاقة",
    "energy_device": "جهاز الطاقة",
    "energy_param": "طاقة بارام",
    "production_operator": "مشغل الإنتاج",
    "production_correction_factor": "عامل تصحيح الإنتاج",
    "output_operator": "مشغل الإخراج",
    "output_correction_factor": "عامل تصحيح الإخراج",
    "input_operator": "مشغل الإدخال",
    "input_correction_factor": "عامل تصحيح الإخراج",
    "energy_unit": "وحدة الطاقة",
    "production_unit": "وحدة الإنتاج",
    "production": "إنتاج",
    "create_data_logger": "إنشاء مسجل بيانات",
    "data_log": "سجل البيانات",
    "add_param": "أضف بارام",
    "param_id": "معرف بارام",
    "create_batch_production_logger": "إنشاء سجل إنتاج الدُفعات",
    "batch_tracing_log": "سجل تتبع الدُفعات",
    "log_name": "اسم السجل",
    "location": "موقع",
    "info": "معلومات",
    "remarks": "ملاحظات",
    "code_will_be_available_to_log_by_default": "سيكون الرمز متاحًا للتسجيل افتراضيًا",
    "fields": "الحقول",
    "additional_info": "معلومات إضافية",
    "action": "فعل",
    "report_type": "نوع التقرير",
    "create_parameter_report": "إنشاء تقرير المعلمة",
    "data_processing": "معالجة البيانات",
    "addition": "إضافة",
    "singular": "المفرد",
    "report": "تقرير",
    "energy_parameters_kwh": "معلمات الطاقة (KWH)",
    "apparent_energy_KVAh": "الطاقة الظاهرة كفاه",
    "maximum_demand_MD": "الحد الأقصى للطلب (MD)",
    "excel_frequency": "تكرار التفوق",
    "multiplier": "مضاعف",
    "single": "أعزب",
    "incomers": "incomers",
    "sections": "المقاطع",
    "energy_distribution_report": "تقرير توزيع الطاقة",
    "root_machine": "آلة الجذر",
    "tariff_on": "تعريفة على",
    "add_root_common_machine": "أضف الجذر والماكينة الشائعة",
    "common_machine": "آلة مشتركة",
    "energy_bill_distribution_report": "تقرير توزيع فاتورة الطاقة",
    "create_consolidated": "إنشاء موحدة",
    "choose_report": "اختر التقرير",
    "id": "بطاقة تعريف",
    "timeframed_sec": "الوقت مؤطر ثانية",
    "create_dynamic_excel_report": "إنشاء تقرير Excel الديناميكي",
    "update_dynamic_excel_report": "تحديث تقرير Excel الديناميكي",
    "add_sheet": "أضف ورقة",
    "sheet_name": "اسم الورقة",
    "timestamp": "الطابع الزمني",
    "sheet": "ملزمة",
    "sheets": "أوراق",
    "sheet_id": "معرف ورقة",
    "delete_sheet": "حذف ورقة",
    "report_name": "اسم التقرير",
    "update_details": "تحديث التفاصيل",
    "unit": "وحدة",
    "parameters": "حدود",
    "send_excel_file": "إرسال ملف Excel",
    "update_excel_template_based_report": "تحديث التقرير القائم على قالب Excel",
    "create_excel_template_based_report": "إنشاء تقرير قائم على قالب Excel",
    "upload_excel_template_xlsx": "تحميل قالب Excel (XLSX)",
    "param_equation": "معادلة بار",
    "param_equations": "معادلات المعلمة",
    "edit_sheets": "تحرير الأوراق",
    "row_no": "صف لا",
    "column_no": "العمود رقم",
    "row_number": "رقم الصف",
    "column_number": "رقم العمود",
    "colummn_number": "رقم العمود",
    "no_sheets_added": "لا توجد أوراق",
    "process_based_usage_sheet": "ورقة الاستخدام القائمة على العملية",
    "process": "عملية",
    "process_label": "تسمية العملية",
    "add_tag_types": "إضافة أنواع العلامات",
    "add_process_to_list": "أضف عملية إلى قائمة",
    "machine_tag_based_daily_data_sheet": "ورقة البيانات اليومية المستندة إلى علامة الجهاز",
    "machine_label": "ملصق الآلة",
    "add_machine_to_list": "أضف آلة إلى قائمة",
    "add_tag_type_to_list": "أضف نوع العلامة إلى القائمة",
    "shift_based_usage_sheet": "ورقة الاستخدام القائمة على التحول",
    "status_for_process": "عملية",
    "status_for_machine": "آلة",
    "esg_summary_item": "ملخص",
    "esg_details_item": "تفاصيل",
    "esg_scope1_label": "النطاق 1",
    "esg_scope2_label": "النطاق 2",
    "esg_scope3_label": "النطاق 3",
    "esg_scopes_list_label": "نطاقات",
    "transpose": "تبديل",
    "process_based_timeframed_data_sheet": "ورقة بيانات مرور الوقت تستند إلى العملية",
    "add_pocess_to_list": "أضف عملية إلى قائمة",
    "tag_types": "أنواع العلامات",
    " consolidated_shift_based_usage_data_sheet": "ورقة بيانات الاستخدام القائمة على التحول الموحد",
    "date_format": "تنسيق التاريخ",
    "include_process_total": "تشمل إجمالي العملية",
    "machine_group_based_usage_sheet": "ورقة الاستخدام المستندة إلى مجموعة الآلات",
    "machine_group_label": "ملصق مجموعة الآلات",
    "machine_group": "مجموعة الآلات",
    "add_machine_group_to_list": "أضف مجموعة الآلات إلى القائمة",
    "parameter_based_usage_data_sheet": "ورقة بيانات الاستخدام القائمة على المعلمة",
    "show_month_start&end_data": "إظهار شهر البدء والنهاية بيانات",
    "statistics": "إحصائيات",
    "add_param_to_list": "أضف param إلى قائمة",
    "add_timestamp_to_list": "أضف الطابع الزمني إلى القائمة",
    "parameter_group_based_timeframed": "مجموعة المعلمات القائمة على الوقت",
    "param_group_label": "Param Group Label",
    "param_group": "مجموعة بارام",
    "add_param_group_to_list": "أضف Param Group إلى القائمة",
    "dynamic_logger_based_data_sheet": "ورقة بيانات قائمة على المسجل الديناميكي",
    "dynamic_log_label": "علامة السجل الديناميكي",
    "parameter_equation_based_sheet": "ورقة معادلة المعلمة",
    "data_source": "مصدر البيانات",
    "equation_label": "تسمية المعادلة",
    "add_equation_to_list": "إضافة معادلة إلى القائمة",
    "absolute_values": "القيم المطلقة",
    "consumption_based_sheet": "ورقة القائمة على الاستهلاك",
    "parameter_name": "اسم المعلمة",
    "statistical_analysis_based_sheet": "الورقة الإحصائية القائمة على التحليل الإحصائي",
    "parameter_instantaneous_data_sheet": "ورقة البيانات الفورية للمعلمة",
    "create_sheet_column": "إنشاء عمود ورقة",
    "column_label": "تسمية العمود",
    "machine_id": "معرف الجهاز",
    "create_machine_list": "إنشاء قائمة الجهاز",
    "create_param_list": "إنشاء قائمة المعلمات",
    "create_sheets": "إنشاء ملاءات",
    "create_shift": "إنشاء التحول",
    "description_optional": "الوصف (اختياري)",
    "create_sku": "إنشاء SKU",
    "create_location": "إنشاء الموقع",
    "location_type": "نوع الموقع",
    "from_location": "من الموقع",
    "to_location": "إلى الموقع",
    "event_type": "نوع الحدث",
    "create_location_event_type": "إنشاء نوع حدث الموقع",
    "create_list": "إنشاء قائمة",
    "create_param_equation": "إنشاء معادلة بارام",
    "positive_params": "معاملات إيجابية",
    "params_to_be_substracted": "يتم طرح params",
    "create_combo_param": "إنشاء Combo Param",
    "combo_params": "المعلمات مجتمعة",
    "calculated_params": "المعلمات المحسوبة",
    "inter_param_operator": "inter param عامل",
    "correction_operator": "عامل تصحيح",
    "correction_operand": "تصحيح المعامل",
    "create_calculated_param": "إنشاء param المحسوب",
    "calculated_param": "param المحسوب",
    "correction_factor": "عامل التصحيح",
    "param1_type": "param 1 type",
    "param1": "بارام 1",
    "param1_operator": "Param 1 عامل",
    "param1_operand": "param 1 operand",
    "param2_type": "param 2 type",
    "param2": "بارام 2",
    "param2_operator": "Param 2 عامل",
    "param2_operand": "بارام 2 المعامل",
    "create_esg_scope_conversion": "إنشاء تحويل نطاق ESG",
    "category": "فئة",
    "esg_scope_logging": "تسجيل نطاق ESG",
    "get_from_param": "الحصول على من بارام",
    "choose_param": "اختر param",
    "create_target": "إنشاء الهدف",
    "create_word": "إنشاء كلمة",
    "words": "كلمات",
    "choose_OEM": "اختر OEM",
    "get_the_models": "احصل على النماذج",
    "get_oem_devices": "احصل على أجهزة OEM",
    "oem_devices": "أجهزة OEM",
    "model_number": "رقم النموذج",
    "register": "يسجل",
    "add_modbus_gateway": "أضف بوابة modbus",
    " add_modbus_TCP_gateway": "أضف بوابة Modbus TCP",
    "IPAddress": "عنوان IP",
    "port_number": "رقم المنفذ",
    "HubID": "معرف HUB",
    "baud_rate": "معدل باود",
    "parity": "التكافؤ",
    "stop_bit": "توقف قليلا",
    "update_wifi": "تحديث wifi",
    "SSID": "SSID",
    "password": "كلمة المرور",
    "restart_enture": "إعادة التشغيل",
    "force_update_configs": "قوة تحديث التكوينات",
    "activity_name": "اسم النشاط",
    "board": "سبورة",
    "calender": "تقويم",
    "maintenance": "صيانة",
    "welcome": "مرحباً",
    "logout": "تسجيل الخروج",
    "help": "يساعد",
    "accounts": "حسابات",
    "organisation": "منظمة",
    "pointer_color_optional": "لون المؤشر (اختياري)",
    "user_id": "معرف المستخدم",
    "confirm_new_password": "تأكيد كلمة مرور جديدة",
    "service": "خدمة",
    "send_grid": "SendGrid",
    "default_enture_view": "الافتراضي Enture View",
    "permissions": "أذونات",
    "areas": "المناطق",
    "remark": "ملاحظة",
    "days": "أيام",
    "yesterdays_aqi": "الأمس AQI",
    "aqi": "AQI",
    "choose_air_quality_device": "اختر جهاز جودة الهواء",
    "delete_this_device": "حذف هذا الجهاز",
    "air_quality_label": "ملصق جودة الهواء",
    "humidity": "رطوبة",
    "temperature": "درجة حرارة",
    "watcher": "مراقب",
    "area_reference": "مرجع المنطقة",
    "write_data": "اكتب البيانات",
    "day_of_week": "يوم الأسبوع",
    "month": "شهر",
    "diagnostics_notification_groups": "مجموعات إشعار التشخيص",
    "hub_name": "اسم المحور",
    "hub_model": "نموذج المحور",
    "hub_type": "نوع المحور",
    "hub": "مَركَز",
    "update_your_MQTT_bridge_config": "قم بتحديث تكوين جسر MQTT الخاص بك",
    "MQTT_bridge_mode": "وضع جسر MQTT",
    "broker_address": "عنوان الوسيط",
    "update_your_OPC_config": "قم بتحديث تكوين OPC الخاص بك",
    "OPC_client_mode": "وضع عميل OPC",
    "OPC_server_URL": "OPC Server URL",
    "user_auth": "مصادقة المستخدم",
    "mode": "وضع",
    "security_policy": "السياسة الأمنية",
    "Update_your_Edge_Hub_restart_Schedule": "قم بتحديث جدول إعادة تشغيل Hub Edge الخاص بك",
    "auto_restart": "إعادة التشغيل التلقائي",
    "time_24Hrs": "الوقت (24 ساعة)",
    "modbus_ping_latency": "Modbus ping الكمون",
    "modbus_unhealthy_wait": "مودبوس غير صحي الانتظار",
    "edit_written_log_book": "تحرير دفتر السجل المكتوب",
    "field_cant_change": "لا يمكن أن يتغير هذا المجال",
    "re_write": "أعد كتابة",
    "write_in_logbook": "اكتب في سجل",
    "write": "يكتب",
    "download_template": "تنزيل قالب",
    "write_in_logbook_csv": "اكتب في دفتر السجل بواسطة .csv",
    "upload_file": "تحميل ملف",
    "batch": "حزمة",
    "move_batch": "تحرك الدفعة",
    "on": "على",
    "off": "عن",
    "fast_mode": "الوضع السريع",
    "batch_shipping_details": "تفاصيل الشحن دفعة",
    "vehicle_no": "مركبة لا",
    "net_weight": "وزن صافي",
    "actual_weight": "الوزن الفعلي",
    "vendor": "بائع",
    "loaded_by": "بواسطة",
    "verified_by": "تم التحقق من قبل",
    "document_no": "الوثيقة لا",
    "multiple_machine_param": "آلة وبرامز متعددة",
    "map_paramid_paramequation_to_row": "MAP Param ID's أو Param Equations to Row",
    "add_current_date_to_sheet": "أضف التاريخ الحالي إلى الورقة",
    "create_parameter_from_template": "إنشاء معلمة من القالب",
    "selected_parameters_count": "عدد المعلمات المحددة",
    "restricted_user_access": "وصول المستخدم المقيد",
    "select_all_parameters": "حدد جميع المعلمات",
    "edit_angular_gauge_widget": "تحرير عنصر واجهة المستخدم الزاوي",
    "add_angular_gauge_widget": "أضف أداة قياس الزاوية",
    "widget_label": "ملصق القطعة",
    "widget_width": "عرض القطعة",
    "widget_height": "ارتفاع عنصر واجهة المستخدم",
    "param_label": "Param Label",
    "choose_parameter": "اختر المعلمة",
    "processing": "يعالج",
    "edit_banner_widget": "تحرير عنصر واجهة المستخدم",
    "add_banner_widget": "أضف اللافتة أداة",
    "edit_bartd_widget": "تحرير عنصر واجهة المستخدم TD",
    "add_bar_widget": "إضافة شريط عنصر واجهة المستخدم",
    "yaxis_label": "y - تسمية المحور",
    "xaxis_label": "س - تسمية المحور",
    "y1axis_label": "Y1 - تسمية المحور",
    "y2axis_label": "Y2 - تسمية المحور",
    "edit_dual_axis_bar_trend_widget": "تحرير عنصر واجهة مستخدم شريط المحور المزدوج",
    "add_dual_axis_bar_trend_widget": "أضف عنصر واجهة عنصر شريط محور مزدوج",
    "y1_graph_type": "نوع الرسم البياني المحور Y1",
    "y2_graph_type": "نوع الرسم البياني للمحور Y2",
    "idealValue": "مثالية",
    "idealLabel": "مثالية",
    "add_parameters": "إضافة المعلمات",
    "add_equation": "إضافة المعادلة",
    "add_params": "أضف المعلمة",
    "enter_of_machines": "أدخل أي آلات",
    "edit_bar_trend_td_widget": "تحرير شريط TD TREND TD",
    "add_bar_trend_td_widget": "أضف شريط TD Trend Tdget",
    "graph_type": "نوع الرسم البياني",
    "aggregation": "تجميع",
    "default_graph_type": "نوع الرسم البياني الافتراضي",
    "bar_mode": "وضع البار",
    "show_ideal_line": "إظهار الخط المثالي",
    "equations": "المعادلات",
    "edit_bitwise_word_widget": "تحرير عنصر واجهة المستخدم",
    "add_bitwise_word_widget": "أضف عنصر واجهة المستخدم",
    "show_bit_status": "عرض حالة بت",
    "edit_bullet_gauge_widget": "تحرير أداة قياس الرصاصة",
    "add_bullet_gauge_widget": "أضف أداة قياس الرصاصة",
    "reference_value_for": "القيمة المرجعية ل",
    "gauge_type": "نوع المقياس",
    "indicator_color": "لون المؤشر",
    "busbar_temperature_graph_widget": "شريط درجة حرارة شريط الحافلة عنصر واجهة مستخدم",
    "busbar_module": "وحدة شريط الحافلات",
    "edit_consumption_gauge_widget": "تحرير أداة قياس الاستهلاك",
    "add_consumption_gauge_widget": "أضف أداة قياس الاستهلاك",
    "linearGauge": "مقياس خطي",
    "show_target": "عرض الهدف",
    "healthy_on_below_target": "صحية على الهدف أدناه",
    "edit_consumption_pie_widget": "تحرير أداة فطيرة الاستهلاك",
    "add_consumption_pie_widget": "أضف أداة فطيرة الاستهلاك",
    "add_color_break_point": "أضف نقطة كسر اللون",
    "custom_color": "لون مخصص",
    "color": "لون",
    "edit_heat_map_widget": "تحرير أداة خريطة الحرارة",
    "add_heat_map__widget": "أضف أداة خريطة الحرارة",
    "choose_equation": "اختر المعادلة",
    "label_star": "ملصق*",
    "color_star": "لون*",
    "operator1_star": "المشغل 1*",
    "value1_star": "القيمة 1*",
    "operator2_star": "المشغل 2*",
    "actions": "الإجراءات",
    "condition": "شروط",
    "status_color": "لون الحالة",
    "text_view": "عرض النص",
    "edit_process_Status_widget": "تحرير أداة حالة العملية",
    "add_process_Status_widget": "أضف أداة الحالة",
    "edit_machine_Status_widget": "تحرير أداة حالة الجهاز",
    "add_machine_Status_widget": "أضف عنصر واجهة جهاز الجهاز",
    "choose_process": "اختر العمليات",
    "choose_machine": "اختر الآلات",
    "table_header_label": "علامة رأس الجدول",
    "edit_table_TD_widget": "تحرير أداة TD THE TDGET",
    "add_table_TD_widget": "أضف أداة TD TD Widget",
    "configure_table": "تكوين الجدول",
    "edit_target_based_trend_widget": "تحرير أداة الاتجاه المستند إلى الهدف",
    "add_target_based_trend_widget": "أضف عنصر واجهة مستهدفة للاتجاه المستهدف",
    "target": "هدف",
    "target_line_label": "تسمية الخط المستهدف",
    "edit_usage_score_card": "تحرير أداة بطاقة الأداء",
    "add_usage_score_card": "أضف عنصر واجهة المستخدم لسكور الاستخدام",
    "compare_to": "قارن",
    "assign_color": "تعيين اللون",
    "enter": "يدخل",
    "gauge_to_dashboard": "مقياس إلى لوحة القيادة",
    "widget_types": "أنواع القطعة",
    "from_value": "من القيمة",
    "to_value": "لقيمة",
    "machine_status": "حالة الجهاز",
    "deviceA_type": "الجهاز نوع",
    "templates": "قوالب",
    "add_triggers_to_parameter": "إضافة المشغلات إلى المعلمة",
    "triggers": "المشغلات",
    "show_filter": "عرض مرشح",
    "view_id": "عرض المعرف",
    "move_up": "تحرك",
    "move_down": "تحرك لأسفل",
    "multiplier_types": "multiplier_types",
    "energy_report": "تقرير الطاقة",
    "add_machine_param": "إضافة الماكينة",
    "aliasing": "الاسم المستعار",
    "no_zero_read": "لا صفر قراءة",
    "remove_negative_entropy": "إزالة الانتروبيا السلبية",
    "calculate_consumption": "حساب الاستهلاك",
    "disabled": "عاجز",
    "limiter_value": "قيمة المحدد",
    "machine_scroll_view_page": "صفحة عرض تمرير الجهاز",
    "create_mannual_parameter": "إنشاء المعلمة المنطقية",
    "restrict_user_access": "تقييد وصول المستخدم",
    "add_device_parameter": "أضف معلمة الجهاز",
    "entropy": "إنتروبيا",
    "idle_latency": "الكمون الخمول",
    "add_OPC_parameter": "إضافة معلمة OPC",
    "node_id": "معرف العقدة",
    "byte_format": "تنسيق البايت",
    "param_scroll_view_page": "Param Scroll View Page",
    "timeframed_sec_report": "تقرير المجلس الأعلى للتعليم الإطار الزمني",
    "prod_unit": "وحدة Prod",
    "SEC_unit": "وحدة ثانية",
    "energy_parameters": "معلمات الطاقة",
    "prod_param_type": "prod param type",
    "production_parameters": "معلمات الإنتاج",
    "no_of_intervals": "لا من الفواصل الزمنية",
    "granular_data": "البيانات الحبيبية",
    "csv": "CSV",
    "delete_rule": "حذف القاعدة",
    "enable_disable_all_parameters": "تمكين/تعطيل جميع المعلمات",
    "disable": "إبطال",
    "enable": "يُمكَِن",
    "edit_computed_parameter": "تحرير المعلمة المحسوبة",
    "quantization": "الكمية",
    "command": "يأمر",
    "register_size": "حجم التسجيل",
    "consumer_ID": "معرف المستهلك",
    "site_id": "معرف الموقع",
    "get_data": "الحصول على البيانات",
    "update_param_type": "تحديث نوع بارام",
    "write_in_data_logbook": "اكتب في سجل البيانات",
    "daily_data": "البيانات اليومية",
    "log_entry": "دخول السجل",
    "enable_Scroll_mode": "تمكين وضع التمرير",
    "multiple_areas": "مناطق متعددة",
    "time_of_day": "الوقت من اليوم",
    "capacity_logger": "مساحات السعة",
    "provider": "مزود",
    "total_sites_received_bills_MTD": "تلقى إجمالي المواقع الفواتير mtd",
    "sites_yet_to_receive_bills": "مواقع حتى الآن تتلقى الفواتير",
    "total_sites": "إجمالي المواقع",
    "sites_received_bills_MTD": "٪ مواقع تلقى الفواتير mtd",
    "total": "المجموع",
    "total_bills_received_mtd": "تلقى إجمالي الفواتير MTD",
    "arrear_1": "المتأخرة 1",
    "arrear_2": "تستعد 2",
    "no_reading": "لا قراءة",
    "bills_received_in_5_days": "تم استلام الفواتير في آخر 5 أيام",
    "site_wise_status": "حالة الموقع الحكيمة بخلاف حالة موافق",
    "site_wise_final_summary": "ملخص نهائي الموقع",
    "source_link": "رابط المصدر",
    "data_type": "نوع البيانات",
    "select_an_image_to_upload": "حدد صورة للتحميل",
    "user_group_Id": "معرف مجموعة المستخدم",
    "user_group_name": "اسم مجموعة المستخدم",
    "delay_in_mins": "تأخير في (دقائق)",
    "delete_task": "حذف المهمة",
    "watchers": "المراقبين",
    "comments": "تعليقات",
    "asset_under_maintenance": "الأصول تحت الصيانة",
    "start_time": "وقت البدء",
    "end_time": "وقت الانتهاء",
    "download_latest_usage_table": "قم بتنزيل أحدث جدول استخدام",
    "tag": "علامة",
    "add_target_data": "أضف البيانات المستهدفة",
    "edit_target": "تحرير الهدف",
    "delete_target": "حذف الهدف",
    "prop": "دعم",
    "excel_template_based_report": "تقرير قائم على القالب Excel",
    "configured_hub": "محور تكوين",
    "configured_relay": "ترحيل تكوين",
    "desired_state": "الدولة المطلوبة",
    "configured_notification_group": "مجموعة الإخطار التي تم تكوينها",
    "email_ids": "معرفات البريد الإلكتروني",
    "logs": "سجلات",
    "unit_id": "معرف الوحدة",
    "download_report": "تنزيل تقرير",
    "frequency": "تكرار",
    "ip": "IP",
    "edit_wifi": "تحرير wifi",
    "details": "تفاصيل",
    "connected_to": "متصلة",
    "software_version": "إصدار البرنامج",
    "mac_address": "عنوان MAC",
    "opc_client_enabled": "تمكين عميل OPC",
    "MQTT_bridge_enabled": "تم تمكين جسر MQTT",
    "hub_specification": "مواصفات HUB",
    "hub_details": "تفاصيل HUB",
    "restart_schedule": "الجدول الزمني لإعادة التشغيل",
    "restart_device": "أعد تشغيل الجهاز",
    "set_up_restart_time": "قم بإعداد وقت إعادة التشغيل",
    "connectivity_logs": "سجلات الاتصال",
    "internal_cache_size": "حجم ذاكرة التخزين المؤقت الداخلية",
    "default": "تقصير",
    "configs": "التكوينات",
    "processed": "معالجة",
    "space_available": "المساحة المتاحة",
    "kb": "كيلو بايت",
    "mb": "ميغابايت",
    "last_ping": "آخر ping",
    "toggle": "تبديل",
    "configured_creds": "تردد تكوين",
    "psk": "PSK",
    "set_value": "ضبط القيمة",
    "time_current_time": "الوقت (سيتم أخذ الوقت الحالي إذا لم يذكر)",
    "Set": "تعيين",
    "health": "صحة",
    "port": "ميناء",
    "mqtt_params": "MQTT-Params",
    "delete_device": "حذف الجهاز",
    "view_details": "عرض التفاصيل",
    "parameter_id": "معرف المعلمة",
    "email_address": "عنوان البريد الإلكتروني",
    "attached_triggers": "المشغلات المرفقة",
    "parameter_details": "تفاصيل المعلمة",
    "enable_write": "تمكين الكتابة",
    "disable_write": "تعطيل الكتابة",
    "offset": "الإزاحة",
    "no_of_parameters": "لا من المعلمات",
    "creation_time": "وقت الخلق",
    "not_configured": "لم يتم تكوينه",
    "add_rule": "أضف القاعدة",
    "sl_no": "SL لا",
    "targeted_by_value": "المستهدفة بالقيمة",
    "configured_action": "الإجراء المكون",
    "add_action": "أضف الإجراء",
    "action_id": "معرف العمل",
    "delete_user": "حذف المستخدم",
    "bit": "قليل",
    "low": "قليل",
    "high": "عالي",
    "esg_scope_conversion": "تحويل نطاق ESG",
    "batch_production_logs": "سجلات إنتاج الدُفعات",
    "esg_data_logs": "سجلات بيانات ESG",
    "remove_from_dashboard": "ترفع من لوحة القيادة",
    "data_logs": "سجلات البيانات",
    "select_device": "حدد الجهاز",
    "fetch_connectivity_logs": "جلب سجلات الاتصال",
    "hub_internet_connectivity_logs": "سجلات اتصال الإنترنت HUB",
    "modbus_gateways": "بوابات Modbus",
    "device_switched_on": "يتم تشغيل الجهاز",
    "device_switched_off": "يتم إيقاف تشغيل الجهاز",
    "parameter_report": "تقرير المعلمة",
    "timeframed_specific_energy_consumption_report": "تقرير استهلاك الطاقة المحدد من الوقت",
    "ebill_distribution_report": "تقرير توزيع الإلكترونية",
    "root_user_management": "إدارة المستخدم الجذر",
    "user_acces_management": "إدارة وصول المستخدم",
    "inference": "الاستدلال",
    "turn_off": "إيقاف تشغيل",
    "turn_on": "تشغيل",
    "diagram_pallette": "لوحة الرسم البياني",
    "add_component": "إضافة مكون",
    "components": "عناصر",
    "add_text": "أضف النص",
    "add_realtime_data": "إضافة بيانات الوقت الحقيقي",
    "shapes": "الأشكال",
    "lines": "خطوط",
    "text_realtime_component": "نص مكون الوقت الحقيقي",
    "shapes_pallette": "لوحة الشكل",
    "lines_pallette": "خطوط لوحة",
    "click_choose": "انقر واختر الأشكال لإضافتها إلى SLD",
    "choose_line": "اختر الخط لإضافته إلى SLD",
    "choose_image": "اختر الصورة",
    "upload_image_to_add": "تحميل الصورة لإضافتها إلى SLD",
    "add_realtime_textdata": "أضف الوقت الحقيقي أو البيانات النصية إلى SLD",
    "update_user": "تحديث المستخدم",
    "add_watcher": "أضف مراقب",
    "premise_area": "منطقة فرضية",
    "create_computed_param": "إنشاء param محسوبة",
    "create_derived_param": "إنشاء param المشتق",
    "proceed_to_ship": "انتقل إلى السفينة",
    "esg_data_collection": "جمع بيانات ESG",
    "esg_scope_details": "تفاصيل نطاق ESG",
    "select_custom_date_range": "حدد نطاق تاريخ مخصص",
    "days_365": "365 يومًا",
    "days_180": "180 يومًا",
    "days_30": "30 يومًا",
    "create_user_for_SSO": "قم بإنشاء مستخدم لـ SSO",
    "add_widget": "أضف القطعة",
    "add_machine_status_rules": "إضافة قواعد حالة الجهاز",
    "add_trigger": "إضافة الزناد",
    "add_logged_parameter": "إضافة المعلمة تسجيل",
    "edit_logged_parameter": "تحرير المعلمة تسجيل",
    "create_mannual_param": "إنشاء param المنطقية",
    "add_opc_param": "إضافة OPC param",
    "create_write_block": "إنشاء كتلة الكتابة",
    "all_parameters": "جميع المعلمات",
    "edit_device": "تحرير الجهاز",
    "edit_modbus_device": "تحرير جهاز Modbus",
    "edit_mqtt_device": "تحرير جهاز MQTT",
    "limit_entropy": "الحد من الانتروبيا",
    "emails": "رسائل البريد الإلكتروني",
    "power_factor": "PowerFactor (PF)",
    "tarrif_rate": "معدل التعريفة",
    "apparent_power": "قوة واضحة",
    "reactive_power": "القوة التفاعلية",
    "active_power": "قوة نشطة",
    "energy_usage_view_page": "صفحة عرض استخدام الطاقة",
    "switch_organisation": "تبديل المنظمة",
    "wireless_creds": "الائتمانات اللاسلكية",
    "latest": "أحدث",
    "value_is": "القيمة",
    "please_take_necessary_action": "يرجى اتخاذ الإجراءات اللازمة",
    "edit_role": "تحرير الدور",
    "delete_role": "حذف الدور",
    "today": "اليوم",
    "days_4": "4 أيام",
    "due": "حق",
    "move_to_done": "انتقل إلى القيام به",
    "power_system_fault": "عدد أخطاء نظام الطاقة (الأسبوع الماضي)",
    "alarms": "إنذار",
    "electricity_consumption": "استهلاك الكهرباء (شهر حتى الآن)",
    "average_demand": "متوسط ​​الطلب",
    "water_use_intensity": "شدة استخدام الماء (WUI)",
    "average_water_cost": "متوسط ​​تكلفة المياه (شهريًا)",
    "water_use_efficiency": "كفاءة استخدام المياه (WUE)",
    "batch_logs": "سجلات الدُفعات",
    "edit_log": "تحرير السجل",
    "delete_log": "حذف السجل",
    "dates": "بلح",
    "log_details": "تفاصيل السجل",
    "view": "منظر",
    "scope1_emission": "النطاق 1 الانبعاث",
    "scope2_emission": "النطاق 2 الانبعاثات",
    "lmtd": "LMTD",
    "company_data": "بيانات الشركة",
    "historian": "مؤرخ",
    "from_optional": "من (اختياري)",
    "to_optional": "إلى (اختياري)",
    "trends": "الاتجاهات",
    "generate_trend": "توليد الاتجاه",
    "by": "بواسطة",
    "check_logs": "تحقق من السجلات",
    "workspaces": "مساحات العمل",
    "types_of_nodes_connected": "أنواع العقد المتصلة",
    "types_of_modbus_device_connected": "أنواع أجهزة modbus متصلة",
    "no_of_registers": "لا من السجلات",
    "write_register": "اكتب السجل",
    "setting_view": "إعداد العرض",
    "sign_up": "اشتراك",
    "reset": "إعادة ضبط",
    "mobile_number": "رقم الهاتف المحمول",
    "nebeskie_labs": "مختبرات نيبسكي",
    "please_register_and_send_otp": "الرجاء إدخال معرف البريد الإلكتروني المسجل وانقر فوق إرسال OTP.",
    "reset_password": "إعادة تعيين كلمة المرور",
    "back_to_login": "العودة إلى تسجيل الدخول",
    "otp": "OTP",
    "send_otp": "إرسال OTP",
    "org_alias": "org الاسم المستعار",
    "please_stand_by": "من فضلك قف إلى جانب",
    "total_usage_this_month": "إجمالي الاستخدام هذا الشهر",
    "total_usage_last_month": "الاستخدام الكلي الشهر الماضي",
    "generate_report_manually": "توليد تقرير يدويًا",
    "energy_distribution_detailed_report": "تقرير توزيع الطاقة مفصل",
    "the_report_is_processing": "التقرير معالجة",
    "enter_active_energy_manually": "أدخل الطاقة النشطة يدويًا",
    "include_others": "تشمل الآخرين",
    "bill_amount": "مبلغ فاتورة",
    "yes_delete_it": "نعم ، احذفه",
    "create_write_param": "قم بإنشاء Param Write",
    "report_list": "قائمة التقارير",
    "plant_id": "معرف النبات",
    "plant_name": "اسم النبات",
    "plant_address": "عنوان النبات",
    "add_selected": "إضافة محدد",
    "clear_selection": "اختيار واضح",
    "real_time_value": "قيمة الوقت الحقيقي",
    "default_status": "الحالة الافتراضية",
    "default_label": "التسمية الافتراضية",
    "default_color_optional": "اللون الافتراضي اختياري",
    "rule_based_status": "الحالة القائمة على القاعدة",
    "show_label_only": "عرض التسمية فقط",
    "show_unit": "وحدة العرض",
    "status_rules": "قواعد الحالة",
    "configure": "تكوين",
    "monthly_usage": "الاستخدام الشهري",
    "meter_consumption_kwh": "استهلاك متر (KWH)",
    "total_unit": "إجمالي الوحدة",
    "region": "منطقة",
    "average_energy_consumed": "متوسط ​​الطاقة المستهلكة",
    "average_production": "متوسط ​​الإنتاج",
    "average_sec": "متوسط ​​ثانية",
    "total_production": "الإنتاج الكلي",
    "total_energy_consumed": "إجمالي الطاقة المستهلكة",
    "max_sec": "ماكس ثانية",
    "device_1": "الجهاز 1",
    "device_parameter_1": "معلمة الجهاز 1",
    "device_2": "الجهاز 2",
    "device_parameter_2": "معلمة الجهاز 2",
    "create_param": "إنشاء param",
    "update_tags": "تحديث العلامات",
    "remove_from_process": "ترفع من العملية",
    "machine_parameters": "معلمات الآلة",
    "energy": "طاقة",
    "power": "قوة",
    "remove_from_featured": "ترفع من المميز",
    "other_parameters": "معلمات أخرى",
    "add_to_featured": "إضافة إلى المميز",
    "logged_parameters": "المعلمات تسجيل",
    "tags": "العلامات",
    "machine_status_rules": "قواعد حالة الجهاز",
    "connected_communication_devices": "أجهزة الاتصال المتصلة",
    "mqtt_devices": "أجهزة MQTT",
    "modbus_devices": "أجهزة Modbus",
    "total_no_of_params": "إجمالي عدد المعلمات",
    "edge_devices": "أجهزة الحافة",
    "machines_configured": "الآلات التي تم تكوينها",
    "reports_configured": "تم تكوين التقارير",
    "views_configured": "طرق العرض التي تم تكوينها",
    "send_email_notification": "إرسال إشعار البريد الإلكتروني",
    "enture_send_email_notification": "دعنا ننتقل إرسال إشعار البريد الإلكتروني إلى مجموعة المستخدمين المحددة",
    "agree_and_send": "أوافق وأرسل",
    "ebill": "ebill",
    "monthly_trend": "الاتجاه الشهري",
    "param_view": "عرض بارام",
    "calories": "سعرات حرارية",
    "param_count_view": "بارام عرض العد",
    "created": "مخلوق",
    "api_data_count_view": "طريقة عرض بيانات API",
    "diesel_emissions": "انبعاثات الديزل",
    "eb_emissions": "انبعاثات eB",
    "emissions_kg_co2": "الانبعاثات kg-co2",
    "kpis": "مؤشرات الأداء الرئيسية",
    "jan_23": "23 يناير",
    "feb_23": "23 فبراير",
    "mar_23": "MAR-23",
    "apr_23": "23 أبريل",
    "may_23": "23 مايو",
    "jun_23": "23 يونيو",
    "lpg_emissions": "انبعاثات غاز البترول المسال",
    "petrol_emissions": "انبعاثات البنزين",
    "bullet_gauge": "مقياس رصاصة",
    "equipment": "معدات",
    "enter_no_of_machines": "أدخل أي آلات",
    "shift": "يحول",
    "upload": "رفع",
    "image_upload": "تحميل الصورة",
    "derived_parameter": "المعلمة المشتقة",
    "value_type": "value_type",
    "enable_entropy": "تمكين الانتروبيا",
    "remove_decimal": "إزالة العشرية",
    "limiter_entropy": "محدد الانتروبيا",
    "show_sku_id": "عرض معرف SKU",
    "production_start_date": "تاريخ بدء الإنتاج (اختياري)",
    "production_start_time": "وقت بدء الإنتاج (اختياري)",
    "time": "وقت",
    "temperature_c": "درجة الحرارة (ج)",
    "set_temperature_c": "ضبط درجة الحرارة (ج)",
    "no_of_people": "لا من الناس",
    "co2_level": "مستوى ثاني أكسيد الكربون",
    "aqi_optional": "AQI اختياري",
    "delete_sheets": "حذف الأوراق",
    "root_login": "تسجيل الدخول الجذر",
    "uam_login": "تسجيل الدخول UAM",
    "login": "تسجيل الدخول",
    "add_oem_logo": "أضف شعار OEM",
    "devices": "الأجهزة",
    "derived_data_units": "وحدات البيانات المشتقة",
    "roles": "أدوار",
    "engineering": "هندسة",
    "control_panel": "لوحة التحكم",
    "views": "وجهات النظر",
    "reports_analysis": "التقارير والتحليل",
    "actions_triggers": "الإجراءات والمشغلات",
    "modules": "الوحدات النمطية",
    "processes": "العمليات",
    "air_quality": "جودة الهواء",
    "manufacturing": "تصنيع",
    "esg": "ESG",
    "task_managment": "إدارة المهام",
    "sustainability": "الاستدامة",
    "groups": "مجموعات",
    "log_book": "سجل السجل",
    "monitor": "شاشة",
    "enture_settings": "الاستيلاء على الإعدادات",
    "configuration": "إعدادات",
    "bar": "حاجِز",
    "line": "خط",
    "s7_device": "جهاز S7",
    "rack": "رف",
    "slot": "فتحة",
    "db_number": "رقم ديسيبل",
    "byte_offset": "بايت إزاحة",
    "bit_offset": "تعويض بت",
    "data_length": "طول البيانات",
    "val_type": "نوع القيمة",
    "create_s7_device": "أضف جهاز S7",
    "scope_label": "نطاقات",
    "client_safety": "سلامة العميل",
    "edit": "يحرر",
    "delete": "يمسح",
    "choose_param_to_monitor": "اختر param لمراقبة",
    "rules": "قواعد",
    "add_to_row": "أضف إلى الصف",
    "add_usage": "إضافة الاستخدام",
    "notifications": "إشعارات",
    "organisation_details": "تفاصيل المنظمة",
    "profile": "حساب تعريفي",
    "user_access": "وصول المستخدم",
    "admin": "مسؤل",
    "shifts": "التحولات",
    "add_pie_TD_widget": "أضف أداة فطيرة TD",
    "edit_pie_TD_widget": "تحرير عنصر واجهة مستخدم فطيرة TD",
    "legend": "أسطورة",
    "pie": "فطيرة",
    "edit_bar_trend_widget": "تحرير عنصر واجهة المستخدم توجيه الشريط",
    "add_bar_trend_widget": "أضف عنصر واجهة المستخدم توجيه الشريط",
    "edit_horizantal_bar_trend_widget": "تحرير عنصر واجهة مستخدم شريط الشريط الأفقي",
    "add_horizantal_bar_trend_widget": "أضف عنصر واجهة مستخدم شريط أفقي",
    "too_long": "طويل جدا",
    "trigger_logs": "سجلات الزناد",
    "create_trigger": "إنشاء الزناد",
    "right_click_to_configure": "انقر بزر الماوس الأيمن لتكوين",
    "width": "عرض",
    "height": "ارتفاع",
    "hide_value": "إخفاء القيمة",
    "edit_machine": "تحرير آلة",
    "wait_time": "وقت الانتظار",
    "production_date": "تاريخ الإنتاج",
    "connectivity_status": "حالة الاتصال",
    "production_plans": "خطط الإنتاج",
    "production_pending": "الإنتاج المعلق",
    "pending_shipment": "شحنة معلقة",
    "trace_logs": "سجلات التتبع",
    "movement_events": "أحداث الحركة",
    "kgco2": "KGCO2",
    "entropy_threshold": "عتبة الانتروبيا",
    "threshold_value": "قيمة العتبة",
    "pending_dispatch": "الإرسال المعلق",
    "dispatched": "مُرسَل",
    "dispatch_details": "تفاصيل الإرسال",
    "dispatch": "إرسال",
    "raw_materials": "مواد خام",
    "pour_qty": "صب الكمية",
    "summary": "ملخص",
    "optional": "خياري",
    "param_mqtt_map": "بارام MQTT خريطة",
    "machine_status_mqtt_map": "خريطة حالة الجهاز MQTT",
    "edit_trigger_count_table_widget": "تحرير عنصر تحرير أداة تعداد الجدول",
    "add_trigger_count_table_widget": "أضف عنصر واجهة المستخدم لعد الزناد",
    "count": "عدد",
    "real_time_consumption": "استهلاك الوقت الحقيقي",
    "search_view": "عرض البحث",
    "multiple_entries": "إدخالات متعددة",
    "combined_parameter": "معلمة مجتمعة",
    "background_color": "لون الخلفية",
    "resize": "تغيير الحجم",
    "remove": "يزيل",
    "select_views": "حدد طرق العرض",
    "difference": "اختلاف",
    "sum": "مجموع",
    "set_param_type_for_machine": "اضبط نوع param للآلة",
    "y1axis": "محور Y1",
    "y2axis": "محور Y2",
    "temperature_parameters": "معلمات درجة الحرارة",
    "area_name": "اسم المنطقة",
    "maintenance_due_on": "الصيانة المستحقة على",
    "days_left": "تبقى أيام",
    "enture_insights": "رؤى مشروع",
    "reports": "التقارير",
    "till_date_pie_chart": "حتى تاريخ الرسم البياني فطيرة",
    "till_date_bar_chart": "حتى تاريخ الرسم البياني",
    "tod_billing_widget": "تود الفواتير القطعة",
    "bar_trend_widget": "شريط الاتجاه القطعة",
    "target_based_bar_trend_widget": "أداة توجيه شريطية مستهدفة المستهدفة",
    "gauge_widget": "أداة قياس عنصر واجهة المستخدم",
    "consumption_pie_widget": "استهلاك فطيرة الاستهلاك",
    "consumption_gauge_widget": "أداة قياس الاستهلاك",
    "angular_gauge_widget": "أداة قياس الزاوي",
    "table_widget": "أداة الجدول",
    "banner_widget": "اللافتة أداة",
    "param_equation_banner_widget": "عنصر شعبة معادلة بارام",
    "shift_based_usage_table": "جدول الاستخدام القائم على التحول",
    "bitwise_word_widget": "عنصر واجهة مستخدم كلمة بت",
    "dynamic_log_widget": "أداة السجل الديناميكية",
    "machine_status_timeline_widget": "أداة الجدول الزمني لحالة الماكينة",
    "process_or_machine_status_widget": "أداة العملية/جهاز الجهاز",
    "parameter_status_based_usage_status": "parameterStatusBasedWidget",
    "parameter_insights_widget": "المعلمة رؤى أداة",
    "heat_map": "خريطة الحرارة",
    "usage_score_card_widget": "أداة استخدام بطاقة الاستخدام",
    "busbar_live_monitoring": "مراقبة بربار لايف",
    "bullet_or_angular_guage_widget": "عنصر واجهة مستخدم الرصاصة/الزاوي",
    "dual_axis_bar_trend_widget": "أداة توجيه شريط المحور المزدوج",
    "trigger_count_table_widget": "أداة تشغيل الجدول الزناد",
    "bar_trend_td_widget": "شريط TREND TD TIDGET",
    "horizantal_bar_trend_widget": "عنصر توجيه شريط أفقي",
    "choose_from_templates": "اختر_from_templates",
    "shift_timeframed_trends":"الاتجاهات ذات الإطار الزمني المستندة إلى التحول",
    "candlestick_trends":"اتجاهات عصا الشمعة",
    "free_ram":"Free RAM",
    "item":"Item",
    "item_code":"Item Code",
    "department":"Department",
    "departments":"Departments",
    "issue_vouchers":"Issue Vouchers",
    "issue_voucher":"Issue Voucher",
    "to_be_approved":"To Be Approved",
    "to_be_issued":"To Be Issued",
    "intended":"Intended",
    "approved":"Approved",
    "received":"Received",
    "receiver":"Receiver",
    "material":"material",
    "material_group":"material group",
    "material_desc":"Material Description",
    "uom":"Unit Of Measurement",
    "create_item_code":"Create Item Code",
    "create_department":"Create Department",
    "users":"Users",
    "items":"Items",
    "users_in_department":"Users in Department",
    "authorisers_in_department":"Authorisers in Department",
    "issuers_in_department":"Issuers in Department",
    "item_issuer_list":"Item Issuer List",
    "add_item_issuer":"Add Item Issuer",
    "store":"Store",
    "create_issue_voucher":"Create Issue Voucher",
    "voucher_requests":"Voucher Requests",
    "intended_qty":"Intended Qty",
    "issued_qty":"Issued Qty",
    "issue_items":"Issue Items",
    "voucher_receipt":"Voucher Receipt",
    "add_stock":"Add Stock",
    "attached_targets":"Attached Targets",
    "attach_target":"Attach Target",
    "add_target_to_parameter":"Add Target to Parameter",
    "empty":"Empty",
    "add_day_wise_target":"Add Day wise Target",
    "day_wise_targets":"Day Wise Targets",
    "fetch_targets":"Fetch Targets",
    "day_wise_machine_status_widget": "ويدجت حالة الآلات حسب اليوم",
  "add_day_wise_machine_status_widget": "إضافة ويدجت حالة الآلات حسب اليوم",
  "edit_day_wise_machine_status_widget": "تحرير ويدجت حالة الآلات حسب اليوم",
  "add_machines": "إضافة آلات",
  "added_machines": "الآلات المضافة",
  "consumption_start_time":"Consumption Start time",
  
}
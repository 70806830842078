<template>
    <v-container :dark="$store.getters.getColorPalette().isDark"  fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row>
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'" white--text>Edit Usage Scorecard Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Usage Scorecard Widget</span>
            </v-col>
        </v-row> -->
        <v-card  :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" outlined flat class="px-2 mb-2">
            <v-card-title v-if="edit" class="sub-heading">{{ $store.getters.getTextMap().edit_usage_score_card }} </v-card-title>
            <v-card-title v-else class="sub-heading"> {{ $store.getters.getTextMap().add_usage_score_card }} </v-card-title>  
            <v-form ref="widgetForm" v-model="widgetValid">
                <v-row>
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.label"
                            :counter="30"
                            :rules="[rules.required]"
                            :label="$store.getters.getTextMap().widget_label"
                            dense
                            outlined
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="widgetForm.width"
                            :items="widthOptions"
                            :label="$store.getters.getTextMap().widget_width"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                    <v-col align-self="center">
                    <v-select
                      :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.height"
                        :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <v-col align-self="center">
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="timeFrame"
                            :items="tdOptions"
                            :label="$store.getters.getTextMap().compare_to"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"   
                        ></v-select>  
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="edit" cols="3" align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <v-checkbox v-if="!loading" v-model="legend" :label="$store.getters.getTextMap().legend"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <v-card  :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" outlined flat>
            <v-row class="pa-3">
                <v-card-title v-if="formType=='showSingleParam'">{{$store.getters.getTextMap().add_parameters}}</v-card-title>
                <v-card-title v-if="formType=='showMultiParam'">{{$store.getters.getTextMap().add_equation}}</v-card-title>
                <v-spacer/>
                <v-col cols="auto" class="d-flex" align="right">
                    <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                        <v-radio :label="$store.getters.getTextMap().add_params" value="showSingleParam"></v-radio>
                        <v-radio :label="$store.getters.getTextMap().add_equation" value="showMultiParam"></v-radio>
                    </v-radio-group>
                </v-col>
                <!-- <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn style="margin-right:20px;" v-if="showMultiParam===false" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="addSingleParam">Add Params</v-btn>
                    <v-btn v-if="showSingleParam===false" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="addMultiParam">Add Equation</v-btn>
                </v-col> -->
            </v-row>
            <v-divider v-if="formType=='showSingleParam' && check===true" :dark="$store.getters.getColorPalette().isDark"/>
            <v-divider v-if="formType=='showMultiParam' && check===true" :dark="$store.getters.getColorPalette().isDark"/>
            <!-- Single Param -->
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" flat outlined  v-if="formType=='showSingleParam' && check===true" >
                    <v-form ref="usageForm" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                        <v-text-field
                                            v-if="!loading"
                                            v-model="form.label"
                                            :counter="30"
                                            :rules="[rules.required]"
                                            :label="$store.getters.getTextMap().param_label"
                                            dense
                                            outlined
                                            required
                                        ></v-text-field>
                                </v-col>    
                                    <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                     :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="workspace"
                                        :items="workspaceOptions"
                                        :label="$store.getters.getTextMap().workspace"
                                        outlined          
                                        dense
                                        item-text="label"
                                        item-value="value"
                                        required
                                        :rules="objectRules"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                   :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="machine"
                                        :items="machineOptions"
                                        :label="$store.getters.getTextMap().machine"
                                        outlined
                                        dense
                                        item-text="label"
                                        item-value="value"
                                        required
                                        :rules="objectRules"
                                        ></v-select>
                                </v-col>
                                <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="form.params"
                                        :items="paramOptions"
                                        :label="$store.getters.getTextMap().param"
                                        outlined
                                        dense
                                        item-text="label"
                                        item-value="value"
                                        multiple
                                        required
                                        :rules="[rules.required,rules.nonZeroLength]"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="form.colorMeta.type"
                                        :items="textInfoOptions"
                                        :label="$store.getters.getTextMap().assign_color"
                                        dense
                                        outlined
                                        item-text="label"
                                        item-value="value"
                                    ></v-select> 
                        </v-col>
                    </v-row>
                    <v-divider :dark="$store.getters.getColorPalette().isDark" />
                            <v-row no-gutters>
                                <v-chip class="ma-2" close color="indigo" text-color="white" v-for="param in form.params" @click:close="removeParam(param)" :key="param">
                                    {{getParamName(param)}}
                                </v-chip>
                                <v-spacer/>
                            <v-col cols="auto" class="d-flex mt-2" align="right" v-if="!loading" align-self="center">
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small outlined text  @click="addSingleParamToList">{{$store.getters.getTextMap().add_param_to_list}}</v-btn>
                                <v-btn class="ml-2" outlined small :color="$store.getters.getColorPalette().cancelbtnColor" text @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            <div style="text-align:center;" v-else>
                <v-row v-if="formType=='showSingleParam'">
                    <div>
                        <h3 style="margin-left:20px; color:#E57373;">You cannot add more than 5 KPI's, Please create a new Widget</h3>
                    </div>
                    <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">{{ $store.getters.getTextMap().cancel }}</v-btn>
                </v-row>
            </div>
            <!-- Multi Param/> -->
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" outlined flat v-if="formType=='showMultiParam' && check===true">
                <v-container>
                <v-form ref="usageFormMulti" v-model="ValidMulti">
                    <v-row>
                        <v-col align-self="center">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field
                                v-if="!loading"
                                v-model="form.label"
                                :counter="30"
                                :rules="[rules.required]"
                                :label=" $store.getters.getTextMap().param_label"
                                dense
                                outlined
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-if="!loading"
                                v-model="form.no_ofparams"
                                :label="$store.getters.getTextMap().enter_no_of_machines"
                                dense
                                outlined
                                :rules="countRules"
                                item-text="label"
                                item-value="value"
                                required
                            ></v-text-field> 
                        </v-col>
                        <v-col>
                            <v-select
                           :dark="$store.getters.getColorPalette().isDark" 
                   :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                v-if="!loading"
                                v-model="form.colorMeta.type"
                                :items="textInfoOptions"
                                :label="$store.getters.getTextMap().assign_color"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                            ></v-select> 
                        </v-col>
                        <v-col>
                            <v-btn  :color="$store.getters.getColorPalette().submitbtnColor" small outlined text @click="onEnter">{{$store.getters.getTextMap().enter}}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <!-- <v-card outlined> -->
                    <v-row v-if="dialogss===true">
                        <v-row v-if="show===true">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-select
                                        :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            v-if="!loading"
                                            v-model="workspace"
                                            :items="workspaceOptions"
                                            :label="$store.getters.getTextMap().workspace"
                                            outlined 
                                            dense         
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            v-if="!loading"
                                            v-model="machine"
                                            :items="machineOptions"
                                            :label="$store.getters.getTextMap().machine"
                                            outlined
                                            dense
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                        ></v-select>
                                    </v-col>
                                    <v-col align-self="center">
                                    </v-col>
                                    <v-col>
                                        <v-select
                                        :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            v-if="!loading"
                                            v-model="paramTemp"
                                            :items="paramOptions"
                                            :label="$store.getters.getTextMap().param"
                                            outlined
                                            dense
                                            item-text="label"
                                            item-value="value"
                                            multiple
                                            required
                                            :rules="[rules.required,rules.nonZeroLength]"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                       :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            v-if="!loading"
                                            v-model="operatorTemp"
                                            :items="operators"
                                            :label="$store.getters.getTextMap().operator"
                                            dense
                                            outlined
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="[rules.required,rules.nonZeroLength]"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-btn  :color="$store.getters.getColorPalette().accentCode" text outlined small @click="onAdd">{{$store.getters.getTextMap().add}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>
                    </v-row>
                <!-- </v-card> -->
                <v-divider :dark="$store.getters.getColorPalette().isDark" />
                <v-row class="my-1">
                    <v-col>
                        <v-chip color="blue"> {{$store.getters.getTextMap().equation}}: [{{equation}}]</v-chip>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark" />
                <v-row no-gutters>
                    <v-spacer/>
                    <v-col cols="auto" class="d-flex mt-2" align="right" v-if="!loading" align-self="center">
                        <v-btn :color="$store.getters.getColorPalette().accentCode" small outlined text  @click="addMultiParamToList">{{$store.getters.getTextMap().add_equation_to_list}}</v-btn>
                        <v-btn class="ml-2" small outlined :color="$store.getters.getColorPalette().cancelbtnColor" text @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                    </v-col>
                </v-row>
                </v-container>
            </v-card>
            <div style="text-align:center;" v-else>
                <v-row v-if="showMultiParam===true">
                    <div>
                        <h3 style="margin-left:20px; color:#E57373;">You cannot add more than 5 KPI's, Please create a new Widget</h3>
                    </div>
                    <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-row>
            </div>
            <!-- Param list -->
                <v-row v-if="!loading && tableData " class="px-2 pb-2">
                    <v-col cols="12">
                        <v-simple-table height="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
                            <template v-slot:default>
                                <thead >
                                    <tr>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().label}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().params}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="item in tableData">
                                        <td>{{ item.label }}</td>
                                        <td>{{ item.params }}</td>
                                        <td>                                        
   <DeleteConfirmation v-on:confirm="deleteFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this score card?">
 <v-icon small color="red" >mdi-delete</v-icon>
 </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody> 
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>   
        </v-card>
        <v-row no-gutters>
            <v-spacer/>
            <v-col  cols="auto" class="d-flex mt-2" align="right">
                <v-btn :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                  <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
// import CreateMultiParam from '@/components/CreateMultiParam.vue'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'AddUsageScoreCard',
    props:['widget','seq'],
    components:{
        InfoAlert,
        // CreateMultiParam
        DeleteConfirmation
    },
    mounted(){
        this.init()
        this.checkCount()
    },
    data(){
        return {
            check:false,
            showSingleParam:false,
            showMultiParam:false,
            counter:0,
            count:0,
            show:false,
            dialog:false,
            dialogss:false,
            api:'createWidgetForView',
            formType:"showSingleParam",
            loading:false,
            valid:false,
            ValidMulti:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            edit:false,
            workspace:null,
            machine:null,
            legend:false,
            textInfo:null,
            tableData:[],
            paramTemp:null,
            operatorTemp:null,
            paramArray:[],
            operatorArray:[],
            paramNeg:[],
            paramPos:[],
            paramNegFinal:[],
            paramPosFinal:[],
            // paramSingleFinal:[],
            paramMultiFinal:[],
            equation:'',
            form:{
                colorMeta:{}
            },
            widgetForm:{},
            machineOptions:[],
            paramOptions:[],
            operators:[
                {label:'+',value:'+'},
                {label:'-',value:'-'}
                ],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            tdOptions:[
                {label:'Compare to Last Month', value:'lm'},
                {label:'Compare to Last Week', value:'lw'}
            ],
            textInfoOptions:[
                {label:'Green for Positive & Red for Negative', value:'GPRN'},
                {label:'Red for Positive & Green for Negative', value:'RPGN'},
            ],
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
             countRules:[

                v => !!v || 'Required',

                v => (v && v>1) || 'Number of Machine should be greater than 1'

            ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        
        timeFrame:{
            get: function (){
            if(this.widgetForm && this.widgetForm.meta){
                return this.widgetForm.meta.time_frame
            }else{
                return null
            }
        },
        set: function (newValue){

            if(this.widgetForm && this.widgetForm.meta){
                this.widgetForm.meta.time_frame=newValue
            }else{
                this.widgetForm.meta={time_frame:newValue}
            }
            
        }
        }


        
    },
    methods:{
        init(){
            if(this.widget && this.widget.widget_id && this.widget.view_id){
            this.edit=true
            this.widgetForm=Object.assign({},this.widget)
            this.api='updateWidget'
            if(!(this.widgetForm.meta)){
                this.widgetForm.meta={data:[]}
                this.tableData=[]
            }else{
                this.tableData=this.widgetForm.meta.data
                this.legend=this.widgetForm.meta.legend
                this.textInfo=this.widgetForm.meta.textinfo
            }

        }else{
            if(this.widget.view_id){
                this.api='createWidgetForView'
                this.widgetForm=Object.assign({},this.widget)
                this.widgetForm.meta={data:[]}
                this.widgetForm.seq=this.seq?this.seq:0
                this.tableData=[]
            }
        }
        },
        checkCount(){
            if(this.widget.height==='fixed'){
                if(!this.tableData){
                    this.check=true
                    return
                }
                
                if(this.tableData && this.tableData.length<5){
                    this.check=true
                }else{
                    this.check=false
                }
            }else{
                this.check=true
            }
        },
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                this.loading = true;
      axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
        addSingleParam(){
            this.showSingleParam=true
        },
        addMultiParam(){
            this.showMultiParam=true
        },
        onEnter(){
            if(this.form.no_ofparams>1){
              // this.loading=true
              this.dialogss=true
              this.show=true
              
            }
            else{
              console.log("dialog is false")
            }
        },
        
        onAdd(){
          this.counter=this.counter+1
        //   console.log(this.counter)
        //   console.log(this.paramTemp,'paramtemp')
          for(let i of this.paramTemp){
                this.paramArray.push(i)
                this.operatorArray.push(this.operatorTemp)
                this.equation=this.equation.concat(this.operatorTemp)
                this.equation=this.equation.concat('(')
                this.equation=this.equation.concat(i)
                this.equation=this.equation.concat(')')
          }
          this.paramTemp=null
          this.operatorTemp=null
          this.workspace=null
          this.machine=null
          
          if (this.counter>=this.form.no_ofparams ){
            this.show=false
          }
          else{
            this.show=true
          }
            
        },
        addSingleParamToList(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              if(!this.tableData){
                this.tableData=[]
              }
            //   this.paramSingleFinal.push(this.form.params)
              this.tableData.push(
                {label:this.form.label, color:this.form.colorMeta,params:this.form.params}
              )
              this.form.params=[]
              this.loading=false
            }

        },
        addMultiParamToList(){
            this.$refs.usageFormMulti.validate()
            if(this.ValidMulti){
              this.loading=true
            // console.log(this.form)
            // console.log(this.paramArray,"paramarray")
            // console.log(this.operatorArray,"operatorrray")
            for(let i of this.operatorArray){
                if(i==='+'){
                    this.paramPos.push(this.paramArray[this.count])
                    this.count = this.count+1
                }else{
                    this.paramNeg.push(this.paramArray[this.count])
                    this.count = this.count+1
                }
            }
            this.count = 0
            // console.log(this.paramPos,'poitive')
            // console.log(this.paramNeg,'negative')

            // for(let i of this.paramPos){
            //     this.paramPosFinal.push(i)
            // }
            // for(let i of this.paramNeg){
            //     this.paramNegFinal.push(i)
            // }

            // console.log(this.paramPosFinal,'poitive final')
            // console.log(this.paramNegFinal,'negative final')
            this.paramMultiFinal.push(this.paramPos)
            this.paramMultiFinal.push(this.paramNeg)

            
              if(!this.tableData){
                this.tableData=[]
              } 
              this.tableData.push(
                {label:this.form.label,color:this.form.colorMeta, params:this.paramMultiFinal}
              )
              this.form.params=[]
              this.loading=false
              this.paramTemp=null
                this.operatorTemp=null
                this.equation=''
                this.form.no_ofparams= null,
                this.paramNegFinal=[]
                this.paramPosFinal=[]
                this.paramArray=[]
                this.paramPos=[]
                this.paramNeg=[]
                this.operatorArray=[]
                this.paramMultiFinal=[]
                this.counter=0
            //   console.log(this.tableData,"table data")
            }else{
                console.log('form valid false')
            }

        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.widgetForm.meta.data=this.tableData
            this.widgetForm.meta.legend=this.legend
            // this.widgetForm.meta.textinfo=this.textInfo
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
            this.loading=false
            //this.dialog=false
            this.$emit('success')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/

            this.showSingleParam=false
            this.showMultiParam=false
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.paramTemp=null
            this.operatorTemp=null
            this.equation=''
            if(this.$refs.usageFormMulti){
                this.$refs.usageFormMulti.reset()
            }else if(this.$refs.usageForm){
                this.$refs.usageForm.reset()
            }
            this.form.no_ofparams= null,
            this.paramNegFinal=[],
            this.paramPosFinal=[],
            this.paramArray=[],
            this.paramPos=[],
            this.paramNeg=[],
            this.operatorArray=[],
            
            this.paramMultiFinal=[],
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
         onClose(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
        removeParam(param){
            //console.log("remove param")
            //console.log(param)
            //this.stream.emit('removeParam',param)
            this.form.params=this.form.params.filter(x=>x!=param)
           
            //this.parameter.delete(param)
            
        },
        deleteFromTable(item){

            this.tableData=this.tableData.filter(x=>x.label!=item.label)
            this.checkCount()
        },
                cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        widget(){
            this.init()
        }
    }
}
</script>
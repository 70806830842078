<template>
    <v-container fluid class="px-0 py-2" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <LoadingComponent :loading="loading"/>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col cols="auto" class="d-flex">
                    <v-chip
                        v-if="
                        $store.state.user &&
                        $store.state.settingMode &&
                        $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        ><v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{
                            color: $store.getters.getColorPalette().background2ColorCode,
                        }"
                        :class="
                            $store.getters.getColorPalette().foregroundColorName +
                            ' mx-0 px-0'
                        "
                        ><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-identifier</v-icon></v-avatar>
                        {{ view.view_id }}
                        </v-chip>
            </v-col>
            <v-col v-if="showEdit && $store.state.user && $store.state.settingMode " cols="auto" class="d-flex">
                <v-btn class="mr-1"  @click="setAsDefault" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().add_to_dashboard }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-eye-arrow-left</v-icon>
                </v-btn>
                <EditViewBottomSheet :view="view"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn v-if="view && view.meta && view.meta.showFilter" :dark="$store.getters.getColorPalette().isDark"  small outlined :color="$store.getters.getColorPalette().accentCode" text @click="enableFilter=!enableFilter">
                            Filter<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-filter-outline</v-icon>
                         </v-btn>   
                <v-btn v-if="showEdit && $store.state.user && $store.state.settingMode " class="mx-1" :dark="$store.getters.getColorPalette().isDark"  small outlined :color="$store.getters.getColorPalette().accentCode" text @click="dialog=!dialog">{{ $store.getters.getTextMap().add }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small> mdi-plus </v-icon>
                </v-btn>
                <DeleteConfirmation v-if="showEdit && $store.state.user && $store.state.settingMode " v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this View?"> 
                    <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                    </v-btn>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="showEdit && $store.state.user && $store.state.settingMode ">
            <v-col cols="1" align-self="center" align="center" >
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <CreateConsolidatedView :view="view" v-on:close="dialog=false"/>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row v-if="view && view.data && view.data.length>0">
            <v-col v-if="enableFilter">
                <v-autocomplete
                    v-if="!loading"
                    v-model="viewList"
                    :items="filterOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    label="Filter Views"
                    item-text="label"
                    item-value="value"
                    multiple    
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters v-for="view in visibleViews" :key="view.view_id">
            <v-col cols="12">
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().accentCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="'transparent'"   class="my-1">
                    <v-divider class="ma-0 pa-3" :dark="$store.getters.getColorPalette().isDark" ></v-divider>
                    <v-subheader :style="{ fontSize:'20px', color: $store.getters.getColorPalette().accentCode }">
                        <v-icon :style="{ fontSize:'20px', color: $store.getters.getColorPalette().accentCode }" :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>{{icons[view.view_type]}}</v-icon> {{ view.name }}
                    </v-subheader>
                    <component v-bind:is="viewHash[view.view_type]" :view="view" :edit="false" :settingOptions="false" :editOptions="false"></component>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import LoadingComponent from '@/components/LoadingComponent'
import EditViewBottomSheet from '@/components/modals/EditViewBottomSheet'
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateConsolidatedView from '@/components/crud_components/CreateConsolidatedView'
import TableViewPage from '@/pages/views/TableViewPage'
import WidgetViewPage from '@/pages/views/WidgetViewPage'
import EnergyUsageViewPage from '@/pages/views/EnergyUsageViewPage'
import MachineScrollViewPage from '@/pages/views/MachineScrollViewPage'
import ParamScrollViewPage from '@/pages/views/ParamScrollViewPage'
import SLDViewPage from '@/pages/views/SLDViewPage'
import DynamicTimeframedWidgetView from '@/pages/views/DynamicTimeframedWidgetView'
export default {
    props:{
        'view':{
            type:Object
        },
        showEdit:{
            type:Boolean,
            default:true
        }
    },
    components:{
        InfoAlert,
        EditViewBottomSheet,
        LoadingComponent,
        DeleteConfirmation,
        CreateConsolidatedView,
    },
     data(){
        return {
            dialog: false,
            showDialog:false,
            loading:false,
            info:'',
            seq:0,
            showDismissibleAlert:false,
            ConsolidatedViewData:{},
            filterOptions:[],
            viewList:[],
            enableFilter:false,
            viewHash:{
                'tableView':TableViewPage,
                'widgetView':WidgetViewPage,
                'energyUsageView':EnergyUsageViewPage,
                'machineScrollView':MachineScrollViewPage,
                'paramScrollView':ParamScrollViewPage,
                'sldView':SLDViewPage,
                'dynamicTimeframedWidgetView':DynamicTimeframedWidgetView,
            },
            icons:{
                "tableView":'mdi-table',
                "machineScrollView":'mdi-engine',
                "paramScrollView":'mdi-turbine',
                "sldView":'mdi-vector-line',
                "widgetView":'mdi-widgets-outline',
                "energyUsageView":'mdi-lightning-bolt',
                "consolidatedView":'mdi-consolidate',
                "aqView":'mdi-weather-windy',
                "dynamicTimeframedWidgetView":'mdi-widgets-outline',
            }
        }
    },
    mounted(){
        this.createFilterOptions()
        this.init()
    },
    computed:{
        visibleViews() {
            let op=[]
            if(this.viewList && this.viewList.length>0){
                op = this.viewList.slice().sort((a, b) => {
                    return a.seq - b.seq;
                    });
            }
            return op;
        },
        views(){
            let op=[]
            if(this.view && this.view.data && this.view.data.views && this.view.data.views.length>0){
                for(let view_id of this.view.data.views){ 
                    op.append(this.$store.getters.views(view_id))
                }
            }
            return op
        }    
    },
    methods:{
        createFilterOptions(){
            let seq=0
            if(this.view && this.view.data && this.view.data.length>0){
                for (let i of this.view.data){
                    i['seq']=seq++
                    // let temp={'seq':seq++,...i}
                    this.filterOptions.push({'label':i.name,'value':i})
                }
            }
        },
        init(){
            if(this.view && this.view.view_id && this.view.data){
                this.viewList=this.view.data
                this.ConsolidatedViewData=Object.assign({},this.view.data)
                this.api='updateView'
                this.createMode=false
                this.viewName=this.view.name
                this.viewId=this.view.view_id
            }else{
                this.ConsolidatedViewData={}
                this.api='createView'
                this.edit=true
                this.createMode=true
            }
        },
        setAsDefault(){
            this.loading=true
            let payload={view_id:this.view.view_id}
            axios.post(this.$store.state.api+'updateDashboardView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.edit=false
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteView(){
            let payload={view_id:this.view.view_id}
            this.loading=true
            axios.post(this.$store.state.api+'deleteView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.edit=false
                    this.$store.dispatch('refreshViews')
                }
            }).catch(err=>{
                console.error(err)
                this.loading=false
            });
        },
    },
}
</script>

<template>
<v-container fluid :style="[{'background-color': $store.getters.getColorPalette().backgroundColorCode}]">
    <div :style="[{'background-color': $store.getters.getColorPalette().backgroundColorCode}]">
        <v-row>
    <v-col><h3><span :style="{color: $store.getters.getColorPalette().accentCode}" >{{$store.getters.getTextMap().machine}}: {{machine_details.machine.machine_name}}</span></h3></v-col>
    <v-col> <h3> <span :style="{color: $store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().no_of_parameters}}:{{params.length}}</span></h3></v-col>
    
    </v-row>
    <div class="card">
     <div class="card-content">
        <v-row>
        <v-col cols="4" v-for="param in params" :key="param.param_id">
       
            <Param :machineScrollView="true" :param="param"></Param>
        </v-col>
    </v-row>
     </div>
     </div>
     </div>
</v-container>
</template>

<script>
 import Param from '@/components/items/Param'
import axios from 'axios'

export default {
Name:"MachineScrollComponent",
props:['machine_details'],
components:{
  Param,
},
data(){
return{
      params:[],
      machine:[],
     
    
      }
     },

     mounted(){
    
       this.getParams()
          
    },
    
    methods:{
   getParams(){
            //console.log('getParams called')
            //console.log(this.machine_details,"m")
            if(this.machine_details && this.machine_details.machine.machine_id){
                //console.log(this.machine_details.machine_id,'machine')
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine_details.machine.machine_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.params=response.data.params.filter(d=>{return !d.featured})
                            console.log(this.params,'params')
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
     
       
    }
}

</script>

<style scoped>
.card {
   /* width: 500px; */
  /* height: 200px; */
  overflow: hidden;
}

.card-content {
  animation: scrollCard 25s linear infinite;
}

@keyframes scrollCard {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

</style>
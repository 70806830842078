<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid
        >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <!-- <v-row class="mb-2">
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark"
                    :class="'text-subtitle-1' + $store.getters.getColorPalette().accentName + '--text'">Edit Bar Trend TD
                    Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark"
                    :class="'text-subtitle-1' + $store.getters.getColorPalette().accentName + '--text'">Add Bar Trend TD
                    Widget</span>
            </v-col>
        </v-row> -->
        <v-card :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
            flat outlined class="px-3 mb-2">
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_bar_trend_td_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_bar_trend_td_widget}}</v-card-title> 
            <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
                <v-row class="py-3">
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="30"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                    </v-col>
                    <v-col align-self="center"  cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions"  :label="$store.getters.getTextMap().widget_width" dense outlined
                            item-text="label" item-value="value"></v-select>
                    </v-col>
                    <v-col align-self="center"  cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <v-col  cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-select v-if="!loading" v-model="widgetForm.height" :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height" dense outlined item-text="label" item-value="value"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    </v-col>
                    <v-col align-self="center"  cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="timeFrame" :items="tdOptions" :label="$store.getters.getTextMap().timeFrame" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>
                    <v-col align-self="center"  cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="graphType" :items="graphyTypeOptions" :label="$store.getters.getTextMap().graph_type" dense outlined
                            item-text="label" item-value="value"></v-select>
                    </v-col>
                    <v-col v-if="edit" align-self="center"  cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field v-if="!loading" v-model="widgetForm.seq" :counter="3" :label="$store.getters.getTextMap().sequence" dense
                            outlined></v-text-field>
                    </v-col>
                          <v-col align-self="center"  cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="yaxisLabel"  :label="$store.getters.getTextMap().yaxis_label" dense outlined
                            item-text="label" item-value="value"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <v-card :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
            flat outlined>
            <!-- Single Param -->
            <!-- <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().background1ColorCode" outlined flat> -->
                <v-card-title class="sub-heading">{{$store.getters.getTextMap().add_parameters}}</v-card-title>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-form ref="usageForm" v-model="valid">
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field v-if="!loading" v-model="form.label" :counter="30"
                                    :rules="[rules.required]" :label="$store.getters.getTextMap().param_label" dense outlined
                                    required></v-text-field>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                    v-model="workspace" :items="workspaceOptions" :label="$store.getters.getTextMap().workspace" outlined dense
                                    item-text="label" item-value="value" required :rules="objectRules"></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                    v-model="machine" :items="machineOptions" :label="$store.getters.getTextMap().machine" outlined dense
                                    item-text="label" item-value="value" required :rules="objectRules"></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                    v-model="form.param" :items="paramOptions" :label="$store.getters.getTextMap().param" outlined dense
                                    item-text="label" item-value="value" required
                                    :rules="[rules.required, rules.nonZeroLength]"></v-select>
                            </v-col>
                        </v-row>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        <v-row no-gutters>
                            <v-chip class="ma-2" close color="indigo" text-color="white" v-for="param in form.params"
                                @click:close="removeParam(param)" :key="param">
                                {{ $store.getters.getNameByParamId(param) }}
                            </v-chip>
                            <v-spacer/>
                            <v-col cols="auto" class="d-flex mt-2" align="right">
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined
                                    @click="addSingleParamToList">{{$store.getters.getTextMap().add_param_to_list}}</v-btn>
                                <v-btn class="ml-2" small text outlined
                                    :color="$store.getters.getColorPalette().cancelbtnColor"
                                    @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            <!-- </v-card> -->

            <!-- Multi Param/> -->

            <!-- Param list -->
            <v-card outlined flat :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().background2ColorCode" class="mx-auto">
                <v-row :color="$store.getters.getColorPalette().background1ColorCode" v-if="!loading && tableData"
                    class="pa-2">
                    <v-col cols="12" >
                        <v-simple-table height="200px"
                            :style="{ background: $store.getters.getColorPalette().backgroundColorCode }">
                            <template v-slot:default>
                                <thead >
                                    <tr>
                                        <th class="text-left">
                                            <strong>{{$store.getters.getTextMap().label}}</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>{{$store.getters.getTextMap().param_label}}</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>{{$store.getters.getTextMap().delete}}</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="item in tableData">

                                        <td>{{ item.label }}</td>
                                        <td>{{ $store.getters.getNameByParamId(item.param) }}</td>

                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteFromTable(item)"
                                                title="Delete Confirmation"
                                                description="Are you sure you want to delete this Bar TD widget?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex mt-2" align="right">
                <v-btn :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().submitbtnColor" small filled
                    @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
// import CreateMultiParam from '@/components/CreateMultiParam.vue'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name: 'AddBarTDWidget',
    props: ['widget', 'seq'],
    components: {
        InfoAlert,
        // CreateMultiParam,
        DeleteConfirmation
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            showDialog: false,
            showSingleParam: false,
            showMultiParam: false,
            counter: 0,
            count: 0,
            show: false,
            dialog: false,
            api: 'createWidgetForView',
            dialogss: false,
            loading: false,
            valid: false,
            ValidMulti: false,
            widgetValid: false,
            showDismissibleAlert: false,
            info: '',
            edit: false,
            workspace: null,
            machine: null,
            legend: false,
            textInfo: null,
            tableData: [],
            paramTemp: null,
            operatorTemp: null,
            paramArray: [],
            // paramSingleFinal:[],
            paramMultiFinal: [],
            equation: '',
            graphType: 2,
            form: {
            },
            widgetForm: {},
            machineOptions: [],
            paramOptions: [],
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                {label:'3/4 screen', value:9},
                { label: 'Auto', value: null }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            graphyTypeOptions: [
                { label: 'Bar', value: 2 },
                { label: 'Line', value: 1 },
            ],
            tdOptions: [
                { label: 'MTD', value: 'mtd' },
                { label: 'YTD', value: 'ytd' }
            ],
            textInfoOptions: [
                { label: 'Label & percentage', value: 'label+percent' },
                { label: 'Value Only', value: 'value' },
                { label: 'Value & Percentage', value: 'value+percent' },
                { label: 'Percentage only', value: 'percent' },
                { label: 'None', value: 'none' }
            ],
            rules: FormRules.rules,
            objectRules: [
                v => !!v || 'Required',
            ],
            countRules: [

                v => !!v || 'Required',

                v => (v && v > 1) || 'Number of Machine should be greater than 1'

            ],
            yaxisLabel:null

        }
    },
    computed: {
        workspaceOptions() {
            let op = []

            let g = this.$store.state.workspaces
            for (let i of g) {
                //op.push({value:i, text: i.name })
                op.push({ value: i.workspace_id, label: i.name })
            }
            return op
        },

        timeFrame: {
            get: function () {
                if (this.widgetForm && this.widgetForm.meta) {
                    return this.widgetForm.meta.time_frame
                } else {
                    return null
                }
            },
            set: function (newValue) {

                if (this.widgetForm && this.widgetForm.meta) {
                    this.widgetForm.meta.time_frame = newValue
                } else {
                    this.widgetForm.meta = { time_frame: newValue }
                }

            }
        }



    },
    methods: {
        init() {
            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true
                this.widgetForm = Object.assign({}, this.widget)
                this.api = 'updateWidget'
                if (!(this.widgetForm.meta)) {
                    this.widgetForm.meta = { data: [] }
                    this.tableData = []
                } else {
                    this.tableData = this.widgetForm.meta.data
                    this.graphType = this.widgetForm.meta.graphType
                    this.legend = this.widgetForm.meta.legend
                    this.textInfo = this.widgetForm.meta.textinfo
                    this.yaxisLabel = this.widgetForm.meta.yaxis_label
                }

            } else {
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView'
                    this.widgetForm = Object.assign({}, this.widget)
                    this.widgetForm.meta = { data: [] }
                    this.widgetForm.seq = this.seq ? this.seq : 0
                    this.tableData = []
                }
            }
        },
        getMachineList() {
            let op = []
            if (this.workspace) {
                let payload = {
                    workspace_id: this.workspace
                }
                // console.log(payload)
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status == "success") {
                            //console.log(response.data);
                            let machines = response.data.machines
                            //this.loading = false;
                            for (let i of machines) {
                                if (i && i.name) {
                                    op.push({ value: i.machine_id, label: i.name })
                                } else {
                                    op.push({ value: i.machine_id, label: i.machine_id })
                                }
                            }
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                        //this.onReset()
                        this.loading = false;
                    })
                    .catch(error => {
                        this.info = error;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    });
            }
            this.machineOptions = op
        },
        getParams() {
            //console.log('getParams called')
            //console.log(this.machine)
            if (this.machine) {
                this.loading = true
                //console.log('getParams executed')
                axios.post(this.$store.state.api + 'getParamsByMachine', { machine_id: this.machine }, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        //console.log(response.data)
                        if (response.data.status == 'success') {
                            this.loading = false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions = []
                            for (let i of response.data.params) {
                                this.paramOptions.push({ label: i.name, value: i.param_id })
                            }

                        } else {
                            this.loading = false
                            this.info = response.data.msg
                            this.showDismissibleAlert = true
                        }
                    }).catch(err => {
                        this.loading = false
                        this.info = err
                        this.showDismissibleAlert = true

                    })
            }
        },
        getParamName(param) {
            let p = this.$store.getters.getParamById(param)
            if (p) {
                return this.$store.getters.getDeviceById(p['device_id'])['name'] + "-" + p['name']
            } else {
                return "Issue with getting Param Name"
            }
        },
        addSingleParam() {
            this.showSingleParam = true
        },

        onEnter() {
            console.log(this.form.no_ofparams)
            if (this.form.no_ofparams > 1) {
                // this.loading=true
                this.dialogss = true
                this.show = true
                //   console.log("dialog is true")
            }
            else {
                //   console.log("dialog is false")
            }
        },

        addSingleParamToList() {
            this.$refs.usageForm.validate()
            if (this.valid) {
                console.log('entered')
                this.loading = true
                if (!this.tableData) {
                    this.tableData = []
                }
                console.log(this.tableData, 't')
                //   this.paramSingleFinal.push(this.form.params)
                this.tableData.push(
                    { label: this.form.label, param: this.form.param }
                )
                this.form = {}
                this.loading = false
            }

        },

        onReset() {
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.showSingleParam = false
            this.showMultiParam = false
            this.show = false,
                this.dialog = false,
                this.dialogss = false,
                this.counter = 0
            this.workspace = null
            this.machine = null
            this.form.label = null
            this.paramTemp = null
            this.operatorTemp = null
            this.equation = ''
            this.form.no_ofparams = null,
                this.paramNegFinal = [],
                this.paramPosFinal = [],
                this.paramArray = [],
                this.paramPos = [],
                this.paramNeg = [],
                this.operatorArray = [],

                this.paramMultiFinal = [],
                this.$refs.usageFormMulti.reset()
            //this.dialog=false
            this.loading = false
            //this.$emit('close')
        },
        onSubmit() {
            this.$refs.widgetForm.validate()
            if (this.widgetValid) {
                this.loading = true
                //evt.preventDefault()
                //this.form.id=(new Date).getTime().toString()
                this.widgetForm.meta.data = this.tableData
                this.widgetForm.meta.legend = this.legend
                this.widgetForm.meta.graphType = this.graphType
                this.widgetForm.meta.textinfo = this.textInfo
                this.widgetForm.meta.yaxis_label = this.yaxisLabel

                axios.post(this.$store.state.api + this.api, this.widgetForm, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.loading = false
                            //this.dialog=false
                            this.$emit('success')
                            this.$emit('close')
                        } else {
                            //console.log(response.data.msg)
                            this.info = response.data.msg;
                            //this.info = d;
                            this.showDismissibleAlert = true;
                            //console.log(response.data.msg)
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.info = err;
                        //this.info = d;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    });
                //this.$store.dispatch('addDevice', d)

            }
        },
        onClose() {
            this.show = false,
                this.dialog = false,
                this.dialogss = false,
                this.counter = 0
            this.workspace = null
            this.machine = null
            this.form.label = null
            this.form = {}
            this.$refs.widgetForm.reset()
            this.loading = false
            this.$emit('close')
        },
        removeParam(param) {
            //console.log("remove param")
            //console.log(param)
            //this.stream.emit('removeParam',param)
            this.form.params = this.form.params.filter(x => x != param)
            //this.parameter.delete(param)

        },
        deleteFromTable(item) {
            console.log(item)
            this.tableData = this.tableData.filter(x => x.label != item.label)
        },
        cancel() {
            this.showDialog = false
        },
        confirm() {

            this.showDialog = false
        }
    },
    watch: {
        workspace() {
            this.getMachineList()
        },
        machine() {
            this.getParams()
        },
        widget() {
            this.init()
        }
    }
}
</script>
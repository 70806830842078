<template>
    <div >
        <v-dialog  v-model="showDismissibleAlert" max-width="290">
            <v-card :dark="$store.getters.getColorPalette().isDark">
                <v-card-title class="headline">{{$store.getters.getTextMap().info}}</v-card-title>
                <v-card-text>{{info}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="showDismissibleAlert = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="py-0">
            <v-form ref="parameterForm" v-model="valid" >
                <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <v-card-title class="headline">{{mode=='Add Logged Parameter'?$store.getters.getTextMap().add_logged_parameter:$store.getters.getTextMap().edit_logged_parameter}}</v-card-title>
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-text-field
                                        :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.name"
                                        :counter="32"
                                        :rules="nameRules"
                                        :label="$store.getters.getTextMap().name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="form.command!=1">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-text-field
                                        :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.unit"
                                        :counter="5"
                                        :rules="unitRules"
                                        :label="$store.getters.getTextMap().unit"
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col v-if="form.command!=1">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                        :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.processing"
                                        :items="processing"
                                        item-text="label"
                                        item-value="value"
                                        :label="$store.getters.getTextMap().data_processing"
                                        :rules="selectRules"
                                        required
                                        clearable
                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.engineerAccessOnly"
                            :label="$store.getters.getTextMap().restrict_user_access"
                        ></v-checkbox>
                        <v-checkbox
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.rem_zero"
                            :label="$store.getters.getTextMap().no_zero_read"
                        ></v-checkbox>
                        <v-spacer></v-spacer>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">
                            {{ button }}
                        </v-btn>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">
                            {{$store.getters.getTextMap().cancel}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'CreateLoggedParameter',
    props:['machine','loggedParameterDetails'],
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            button:this.$store.getters.getTextMap().create,
            api:'addLoggedParameter',
            mode:'Add Logged Parameter',
            processing:[
                {label:this.$store.getters.getTextMap().average,value:'mean'},
                { label: this.$store.getters.getTextMap().difference,value:'difference'},
                { label: this.$store.getters.getTextMap().sum,value:'sum'},
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 32 && v.length > 0) || 'Name must be less than 33 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 12 && v.length > 0) || 'It must be less than 12 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      form:{
            name:null,
            unit:null,
            processing:null,
            engineerAccessOnly:false,
            val_type:'uint',
            rem_zero:false,
            machine_id:null,
            }
        }
    },
    mounted(){
       if(this.loggedParameterDetails && this.machine && this.loggedParameterDetails.param_id){
        this.form.name=this.loggedParameterDetails.name
        this.form.unit=this.loggedParameterDetails.unit
        this.form.processing=this.loggedParameterDetails.processing
        this.form.engineerAccessOnly=this.loggedParameterDetails.engineerAccessOnly
        this.form.machine_id=this.loggedParameterDetails.machine_id
        this.form.rem_zero=this.loggedParameterDetails.rem_zero
        this.form.param_id=this.loggedParameterDetails.param_id
        this.api='updateLoggedParameter'
        this.mode='Edit Logged Parameter'
        this.button=this.$store.getters.getTextMap().update
       }

    },
    computed:{
      dataTypes(){
        let op=[]
        if(this.$store.state.dataTypes){
          for(let i of this.$store.state.dataTypes){
            op.push({
              label:i.type,
              value:i.id
            })
          }
        }
        return op
      }
    },
    methods:{
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
                this.loading=true
                this.form.machine_id=this.machine.machine_id
                let d=Object.assign({}, this.form)
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                if(response.data.status==='success'){
                    this.$store.dispatch('refreshParameters')
                    this.onReset()
                }else{
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading=false
                }}).catch(err=>{console.log(err)});}
        },
        onReset(){
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
</style>



<template>
<v-container>
  <v-row>
    <v-col cols="8" class="pa-4 cols"   >
      <div class="scrollable-content">
      <div  class="svg-container pa-4" cols="2" ref="svgContainer"  >

      </div>
    </div>
    </v-col>

<v-col cols="4" class="cols">
  <!-- <div class="scrollable-content"> -->
    <v-row>
      <v-col cols="12">
        <v-card  flat  class="pa-2 pt-3 d-flex justify-space-between align-center flex-wrap " >
        

        <div  class="selected-svg-container" ref="selectedSvgContainer"  ></div>
         
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>

        <div class="text-center">
           <v-btn small @click="addSelectedSVGs" :color="$store.getters.getColorPalette().foregroundColorCode" :dark="$store.getters.getColorPalette().isDark">{{$store.getters.getTextMap().add_selected}}
</v-btn>
         </div>
      </v-col>
      <v-col>
        <v-btn small @click="clearSelection" :color="$store.getters.getColorPalette().foregroundColorCode" :dark="$store.getters.getColorPalette().isDark">{{$store.getters.getTextMap().clear_selection}}</v-btn>
      </v-col>
    </v-row>
    
        
      <!-- </div> -->
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import * as d3 from 'd3'
import AssetList from '@/utillities/AssetList';
export default {
  Name: "ShapesPallette",
  components:{
      
  },

 mounted() {
    this.drawchart();
    
  },
  data(){
    return{
          selectedSVGIndices: [],
           nameTextInstances: [] ,
           imageList:[],
           dialog:false,
            svgData:AssetList.svgAssets,

//  arrowData: [
//         {
//           "startIndex": 0, 
//           "endIndex": 1,   
//         }
//       ]
    }
   

  },
  methods:{
     drawchart(){
      // console.log(this.$store.getters.getColorPalette().accentCode);
  //  Accessing the SVG rendered element for styling
    const svgContainer = d3.select(this.$refs.svgContainer);
    svgContainer.style("display", "flex");
    svgContainer.style("flex-wrap", "wrap")
    svgContainer.style('justify-content',"center")
    svgContainer.style('align-items','flex-start')
    svgContainer.style('gap','15px')
    
      this.svgData.forEach(( svgItem,index) => {
      d3.xml(svgItem.path).then(svgDoc => {
        const svgElement = svgDoc.documentElement;
        svgElement.setAttribute("width", svgItem.width);
          svgElement.setAttribute("height", svgItem.height);

          svgElement.setAttribute("transform", `translate(${svgItem.translateX}, ${svgItem.translateY})` );
         
        // svgElement.setAttribute("transform", `translate(${index * 120}, 20)`);
       let shape=d3.select(svgElement)
      //  console.log(shape);
        shape.style('stroke',this.$store.getters.getColorPalette().accentCode)
        shape.style('fill',this.$store.getters.getColorPalette().accentCode)
        // shape.style('border','1px solid '+this.$store.getters.getColorPalette().accentCode)
          svgContainer.node().append(svgElement);
          svgElement.addEventListener("click", () => {
        this.handleSVGClick(index,svgItem);
        });
    //     shape.append('text').attr("x", svgItem.translateX + svgItem.width / 2)
    //  .attr("y", svgItem.translateY + svgItem.height + 15)
    //  .style('stroke',this.$store.getters.getColorPalette().accentCode)
    //  .text(svgItem.name)
      const nameTextInstance = this.createNameTextComponent(svgItem);
      const textX = svgItem.translateX + svgItem.width / 2; 
      const textY = svgItem.translateY + svgItem.height + 15; 
      nameTextInstance.attr("x", textX).attr("y", textY);
      this.nameTextInstances.push(nameTextInstance);

      });

       //console.log("SVG Item:", svgItem);
  });
 },
 drawSelectedShapes(){
  const svgContainer = d3.select(this.$refs.selectedSvgContainer);
  svgContainer.selectAll("*").remove()
    svgContainer.style("display", "flex");
    svgContainer.style("flex-wrap", "wrap")
    svgContainer.style('justify-content',"center")
    svgContainer.style('align-items','flex-start')
    svgContainer.style('gap','15px')
    
      this.imageList.forEach(( svgItem) => {
      d3.xml(svgItem.path).then(svgDoc => {
        const svgElement = svgDoc.documentElement;
        svgElement.setAttribute("width", svgItem.width);
          svgElement.setAttribute("height", svgItem.height);

          svgElement.setAttribute("transform", `translate(${svgItem.translateX}, ${svgItem.translateY})` );

       let shape=d3.select(svgElement)
      //  console.log(shape);
        shape.style('stroke',this.$store.getters.getColorPalette().accentCode)
        shape.style('fill',this.$store.getters.getColorPalette().accentCode)
        // shape.style('border','1px solid '+this.$store.getters.getColorPalette().accentCode)
          svgContainer.node().append(svgElement);


      });

       //console.log("SVG Item:", svgItem);
  });
 },

createNameTextComponent(svgItem) {
  const svgContainer = d3.select(this.$refs.svgContainer);
  const nameText = svgContainer
    .append("text")
    .text(svgItem.name)
    const textX = svgItem.translateX + svgItem.width / 2; 
    const textY = svgItem.translateY + svgItem.height + 15; 
  
  nameText.attr("x", textX).attr("y", textY);

  return nameText;
  
},
 

  handleSVGClick(index,svgItem) {
        // let temp ={'name':svgItem['name'],'path':svgItem['path']}
        this.imageList.push(svgItem)
       // console.log(this.imageList,"list"  )
        const selectedIndex = this.selectedSVGIndices.indexOf(index);
        if (selectedIndex === -1) {
          
            this.selectedSVGIndices.push(index);
        } else {
     
            this.selectedSVGIndices.splice(selectedIndex, 1);
        }
        this.drawSelectedShapes()
    },

 addSelectedSVGs() {
        const addedIndices = this.selectedSVGIndices.slice();
        this.selectedSVGIndices = [];
        this.$emit('value',this.imageList)
        this.imageList=[]
        console.log('Added SVG indices:', addedIndices);
             this.$emit('closeDialog');
             this.clearSelection()
             
    },
    clearSelection(){
      this.imageList=[]
      this.selectedSVGIndices=[]
      this.drawSelectedShapes()
    }

},
}
</script>

<style scoped>


.cols{
  border: 1px solid gray;
}

/* .pa-2{
  background-color: gray;
} */
.svg-container{
  height: 100vh;
}


.scrollable-content {
  overflow-y: auto; 

  /* background-color: #f1f1f1; */
}
.scrollable-content::-webkit-scrollbar {
  width: 10px; 
  height:5px;
}
.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 5px;
  height: 10px;
}

/* .svg-name-text {
 font-size: 10px;
    text-anchor: middle;

    transform: translateX(-50%); 
    margin-top: 50px;

} */

.svg-name {
    text-align: center;
    margin-top: 20px;
}
</style>

<template>
    <v-container fluid class="mx-0">
        <v-row no-gutters>
            <v-col  sm="12" xs="12"  md="6" lg="6" xl="6">
                <v-row no-gutters class="my-1 mx-1">
                    <v-col class="">
                        <div class="active_energy" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center' , height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().active_energy}}
                            <SingleParamTDUsage :style="{'background-color':'#00897B'}"  :param="active_energy" :type="'active_energy'" :time="'mtd'" :color="$store.getters.getColorPalette().accentCode"/>
                        </div>
                    </v-col>
                    <v-col class=" ">
                        <div class="apparent_energy" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center' , height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().apparent_energy}}
                            <SingleParamTDUsage  :style="{'background-color':'#8E24AA'}"  :param="apparent_energy" :type="'apparent_energy'" :time="'mtd'" :color="$store.getters.getColorPalette().accentCode"/>
                        </div>
                    </v-col>
                    <v-col class=" ">
                        

                            <div class="pf" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center', height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().power_factor}}
                                <SingleParamTDUsage   :style="{'background-color':'#E65100'}" :param="pf" :type="'pf'" :time="'mtd'" :color="$store.getters.getColorPalette().accentCode"/>
                            </div>
                        
                    </v-col>
                    <v-col class=" ">
                        <div class="active_power" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center', height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().active_power}}
                            <SingleParamTDUsage   :style="{'background-color':'#039BE5'}" :param="active_power" :time="'mtd'" :type="'active_power'" :color="$store.getters.getColorPalette().accentCode"/>
                        </div></v-col>
                        <v-col class=""><div class="reactive_power" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center', height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().reactive_power}}
                            <SingleParamTDUsage   :style="{'background-color':'#3949AB'}" :param="reactive_power" :time="'mtd'" :type="'reactive_power'" :color="$store.getters.getColorPalette().accentCode"/>
                        </div></v-col>
                </v-row>
                <v-row no-gutters class="my-1 mx-1">
                    <v-col class="mr-1 "> 
                        <div :style="{color:$store.getters.getColorPalette().accentCode, backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center', height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().total_usage_this_month}}
                        <SingleParamTDUsage :param="active_energy" :type="'active_energy'" :time="'mtd'"  :meta="[{font_size:'300%'},{color:'#FFA726'}]"/>
                      </div>
                    </v-col>
                    <v-col class=" ">
                        <div :style="{color:$store.getters.getColorPalette().accentCode,backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center', height:'100%',border:'1px solid '+$store.getters.getColorPalette().panelBorderColorCode}">{{$store.getters.getTextMap().total_usage_last_month}}
                              <SingleParamTDUsage :param="active_energy" :type="'active_energy'" :time="'lmtd'" :meta="[{font_size:'300%'},{color:'#FFA726'}]"/>
                          </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-1 mx-1">
                    <v-col class=" ">
                        <div :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">

                            <SingleParamUsageHeatMap :param="active_energy" :label="'Heat Map'" :dimensions="dimensions"/>
                        </div>

                    </v-col>
                </v-row>
                <v-row no-gutters class="my-1 mx-1">
                    <v-col class=" ">
                        <div :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
                            <SingleParamPeakDemand :param="active_power" :label="'Peak Power'"/>
                        </div>
                    </v-col>
                </v-row>

            </v-col>
            <v-col  sm="12" xs="12" md="6" lg="6" xl="6">
                <v-row no-gutters class="my-1 mx-1">
                    <v-col  class=" mr-1" height="50px">
                      <div class="" :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
                                <WeeklyConsumptionComparison
                                :param="active_energy"
                                :time="'mtd'"
                                :type="'tariff'"
                                :tariff="tariff"/>
                          </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-1 mx-1">
                    <v-col >
                        <div :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
                            <Multiparam :params="multiParams" title='MultiParam' :showGraphType="false" :dimensions="dimensions"/>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-1 mx-1">
                    <v-col class="">
                        <div :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
                            <LatestUsagePanel :params="latestUsageParams" title='Active & Apparent Energy (kWH v/s kVAH)' :showGraphType="false" :dimensions="dimensions"/>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
       <!-- <v-row>
        <v-col cols="12">
            <div class="wrapper">
                
                    <div class="wrapper_new">
                    <div style="grid-column: 1 / span 2 ">
                    <div class="active_energy" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Active energy
                            <SingleParamTDUsage :style="{'background-color':'#00897B'}"  :param="active_energy" :type="'active_energy'" :time="'mtd'" />
                        </div>
                    </div>
                    <div style="grid-column: 3 / span 2">

                        <div class="apparent_energy" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Apparent Energy
                            <SingleParamTDUsage  :style="{'background-color':'#8E24AA'}"  :param="apparent_energy" :type="'apparent_energy'" :time="'mtd'" />
                        </div>
                    </div>
                    <div style="grid-column: 5 / span 2">
                        <div class="pf" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Power Factor
                            <SingleParamTDUsage   :style="{'background-color':'#FB8C00'}" :param="pf" :type="'pf'" :time="'mtd'" />
                        </div>
                    </div>
                    <div style="grid-column: 7 / span 2">
                        <div class="active_power" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Active Power
                            <SingleParamTDUsage   :style="{'background-color':'#039BE5'}" :param="active_power" :time="'mtd'" :type="'active_power'"/>
                        </div>
                    </div>
                    <div style="grid-column: 9 / span 2">
                        <div class="reactive_power" :style="{color:$store.getters.getColorPalette().accentCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Reactive Power
                            <SingleParamTDUsage   :style="{'background-color':'#3949AB'}" :param="reactive_power" :time="'mtd'" :type="'reactive_power'"/>
                        </div>
                    </div>

                        <div style="grid-column:1/span 5">
                            <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Total Usage This Month
                        <SingleParamTDUsage :param="active_energy" :type="'active_energy'" :time="'mtd'" :meta="[{font_size:'300%'},{color:'#FDD835'}]"/>
                      </div>
                        </div>
                        <div style="grid-column:6/ span 5">
                            <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Total Usage Last Month
                              <SingleParamTDUsage :param="active_energy" :type="'active_energy'" :time="'lmtd'" :meta="[{font_size:'300%'},{color:'#FDD835'}]"/>
                          </div>

                        </div>


                        <div  style="grid-column:1/span 10"  :background-color="$store.getters.getColorPalette().background2ColorCode">
                      <div>
                        <SingleParamUsageHeatMap :param="active_energy" :label="'Heat Map'" :dimensions="dimensions"/>
                      </div>
                    </div>
                    <div style="grid-column:1/span 10" :background-color="$store.getters.getColorPalette().background2ColorCode">
                      <div><SingleParamPeakDemand :param="active_power" :label="'Peak Power'"/></div>
                    </div>
                 
                
            </div>

                
                    <div class="wrapper_new">
                <div style="grid-column: 1 / span 5">
                    <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Energy Cost This Month
                            <SingleParamTDUsage :param="active_energy" :time="'mtd'" :type="'tariff'" :tariff="tariff"/>
                        </div>
                    </div>
                    <div style="grid-column: 6 / span 5">

                        <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Energy Cost Last Month
                            <SingleParamTDUsage :param="active_energy" :time="'lmtd'" :type="'tariff'" :tariff="tariff"/>
                        </div>
                    </div>
                    <div style="grid-column: 1 / span 10">
                        <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode,fontSize:'70%',padding:'2%',textAlign:'center'}">Last Week's Usage (kWH)
                            <SingleParamWeeklyUsageBulletGauge :param="active_energy" :label="''"/>
                        </div>
                    </div>
                    <div style="grid-column: 1 / span 10">
                        <div>
                            <Multiparam :params="multiParams" title='MultiParam' :showGraphType="false" :dimensions="dimensions"/>
                        </div>
                    </div>
                    <div style="grid-column: 1 / span 10">
                        <div>
                            <LatestUsagePanel :params="latestUsageParams" title='Active & Apparent Energy (kWH v/s kVAH)' :showGraphType="false" :dimensions="dimensions"/>
                        </div>
                    </div>
   
            </div>
                
            </div>
            
                <div class="wrapper">
                    
                            <div class="column_main_1">
                    <div class="column_main_1_A"   :background-color="$store.getters.getColorPalette().background2ColorCode">
                        <div class="active_energy" :style="{color:$store.getters.getColorPalette().accentCode}">Active energy
                            <SingleParamTDUsage :style="{'background-color':'#00897B'}"  :param="active_energy" :type="'active_energy'" :time="'mtd'" />
                        </div>
                        <div class="apparent_energy" :style="{color:$store.getters.getColorPalette().accentCode}">Apparent Energy
                            <SingleParamTDUsage  :style="{'background-color':'#8E24AA'}"  :param="apparent_energy" :type="'apparent_energy'" :time="'mtd'" />
                        </div>
                        <div class="pf" :style="{color:$store.getters.getColorPalette().accentCode}">Power Factor
                            <SingleParamTDUsage   :style="{'background-color':'#FB8C00'}" :param="pf" :type="'pf'" :time="'mtd'" />
                        </div>
                        <div class="active_power" :style="{color:$store.getters.getColorPalette().accentCode}">Active Power
                            <SingleParamTDUsage   :style="{'background-color':'#039BE5'}" :param="active_power" :time="'mtd'" :type="'active_power'"/>
                        </div>
                        <div class="reactive_power" :style="{color:$store.getters.getColorPalette().accentCode}">Reactive Power
                            <SingleParamTDUsage   :style="{'background-color':'#3949AB'}" :param="reactive_power" :time="'mtd'" :type="'reactive_power'"/>
                        </div>
                    </div>
                    <div class="column_main_1_B" :style="{color:$store.getters.getColorPalette().accentCode}">
                      <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode}">Total Usage This Month
                        <SingleParamTDUsage :param="active_energy" :type="'active_energy'" :time="'mtd'" :meta="[{font_size:'300%'},{color:'#FDD835'}]"/>
                      </div>
                      <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode}">Total Usage Last Month
                        <SingleParamTDUsage :param="active_energy" :type="'active_energy'" :time="'lmtd'" :meta="[{font_size:'300%'},{color:'#FDD835'}]"/>
                    </div>
                    </div>
                    <div class="column_main_1_C"   :background-color="$store.getters.getColorPalette().background2ColorCode">
                      <div>
                        <SingleParamUsageHeatMap :param="active_energy" :label="'Heat Map'" :dimensions="dimensions"/>
                      </div>
                    </div>
                    <div class="column_main_1_D"   :background-color="$store.getters.getColorPalette().background2ColorCode">
                      <div><SingleParamPeakDemand :param="active_power" :label="'Peak Power'"/></div>
                    </div>
                 </div>
                       
                            <div class="column_main_2"   :background-color="$store.getters.getColorPalette().background2ColorCode">
                    <div class="column_main_2_A"   :style="{color:$store.getters.getColorPalette().accentCode}">
                        <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode}">Energy Cost This Month
                            <SingleParamTDUsage :param="active_energy" :time="'mtd'" :type="'tariff'" :tariff="tariff"/>
                        </div>
                        <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode}">Energy Cost Last Month
                            <SingleParamTDUsage :param="active_energy" :time="'lmtd'" :type="'tariff'" :tariff="tariff"/>
                        </div>
                    </div>
                    <div class="column_main_2_B"    :style="{color:$store.getters.getColorPalette().accentCode}">
                        <div :style="{backgroundColor:$store.getters.getColorPalette().background2ColorCode}">Last Week's Usage (kWH)
                            <SingleParamWeeklyUsageBulletGauge :param="active_energy" :label="''"/>
                        </div>
                    </div>
                    <div class="column_main_2_C"   :background-color="$store.getters.getColorPalette().background2ColorCode">
                        <div>
                            <Multiparam :params="multiParams" title='MultiParam' :showGraphType="false" :dimensions="dimensions"/>
                        </div>
                    </div>
                    <div class="column_main_2_D"   :background-color="$store.getters.getColorPalette().background2ColorCode">
                        <div>
                            <LatestUsagePanel :params="latestUsageParams" title='Active & Apparent Energy (kWH v/s kVAH)' :showGraphType="false" :dimensions="dimensions"/>
                        </div>
                    </div>
                    </div>
                        
            
            
                </div>
                
            </v-col>
            </v-row> -->
                
</v-container>
    </template>
    <script>
    import SingleParamUsageHeatMap from '@/components/graphs/SingleParamUsageHeatMap'
    import SingleParamPeakDemand from '@/components/graphs/SingleParamPeakDemand'
    import SingleParamTDUsage from '@/components/panels/SingleParamTDUsage'
    import WeeklyConsumptionComparison from '@/components/panels/WeeklyConsumptionComparison'
    import Multiparam from '@/components/panels/MultiParamLineGraph'
    import LatestUsagePanel from '@/components/panels/LatestUsagePanel'
    export default {
        
     name:'EnergyUsageViewComponent',
     props:['active_energy','apparent_energy','pf', 'active_power', 'reactive_power','dimensions','multiParams','latestUsageParams','tariff'],
     components:{
            SingleParamUsageHeatMap,
            SingleParamPeakDemand,
            SingleParamTDUsage,
            Multiparam,
            LatestUsagePanel,
            WeeklyConsumptionComparison
        },
     mounted() {

       },
     data(){
       return {
        pf_computed:0
       }
     },
    }
    </script>
    <style>
    .wrapper {
      display: grid;
      /* grid-auto-columns:1fr 1fr; */
      grid-template-columns: 1fr 1fr;
      gap:5px;
     
    }
    .wrapper_new {
      display: grid;
      /* grid-auto-columns:1fr 1fr; */
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto ;
      grid-template-rows:4fr;
      gap:5px 5px;
     
    }
    .main_item_1{
        display:grid;
    }

    /* column 2 */
    
    .column_main_1 {
    display: grid;
    grid-template-rows: 4fr ;
    }
    .column_main_1_A {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    }
    .column_main_1_A > div {
    /* background-color: #37474F; */
    margin:3px; 
    text-align: center;
    font-size: 70%;
    padding-top: 20%;
    /* height:25%; */
    }
    .active_energy{
        background-color: #00897B;
        color:white;
    }
    .apparent_energy{
        background-color: #8E24AA;
        color:white;
    }
    .pf{
        background-color: #E65100;

    }
    .active_power{
        background-color: #039BE5;
        color:white;
    }
    .reactive_power{
        background-color: #3949AB;
        color:white;
  
    }
    .column_main_1_B {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr;
    }
    .column_main_1_B > div {
    
    margin:3px;     
    text-align: center;
    font-size: 70%;
    padding-top: 2%;
    }
    /* .column_main_1_C {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr;
    } */
    .column_main_1_C > div {
 
    margin:3px; 
    text-align: center;
    font-size: 70%;
    /* padding-bottom: 25%; */
    }
    /* .column_main_1_D {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr;
    } */
    .column_main_1_D > div {
  
    margin:3px; 
    text-align: center;
    font-size: 70%;
    }
    
    /* column 2 */
    
    .column_main_2 {
    display: grid;
    grid-template-rows: 4fr;
    }
    .column_main_2_A {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr;
    }
    .column_main_2_A > div {

    font-weight:bolder;
    margin:3px; 
    font-size:70%;
    text-align: center;
    padding-top: 2%;
    }
    .column_main_2_B > div {
  
    font-size:70%;
    text-align: center;
    padding-top: 1%;
    margin:3px; 
    }
    .column_main_2_C > div {

    margin:3px; 
    /* padding-bottom: 20%;
    padding-top: 15%; */
    }
    .column_main_2_D > div {

    margin:3px; 
    }
    
    </style>
import SecurityUtillity from "@/utillities/SecurityUtillity"
import ColorUtility from '@/utillities/ColorUtility'
import LanguageUtility from '@/utillities/LanguageUtility'
import EntureStaticLists from '@/utillities/EntureStaticLists'

export default {
    getAccessByLevels:(state) => (levels)=>{
      
      if( levels && levels.length>0){

        for(let level of levels){
            if(!SecurityUtillity.authorise(level,state.user.user_level)){
                return false
            }
        }
    }
        return true
    },
    getColorPalette:(state)=>()=>{
      return ColorUtility.getTheme(state.theme)
    },
    getThemeList:()=>()=>{
      return ColorUtility.themeList
    },
    getTableHeaderColorName:(state)=>()=>{
      return ColorUtility.getTheme(state.theme).tableHeaderColorName
    },
    getTableHeaderColorCode:(state)=>()=>{
      return ColorUtility.getTheme(state.theme).tableHeaderColorCode
    },
    getTextMap:(state)=>()=>{
      return LanguageUtility.getLangTexts(state.lang)
    },
    getLangList:()=>()=>{
      return LanguageUtility.langList
    },
    getLangOptions:()=>()=>{
      return LanguageUtility.langOptions
        },
    getBackgroundColorOptions:()=>()=>{
      return EntureStaticLists.backgroundColorOptions
        },
    getUserMenuLinks:(state)=>()=>{
      let menu=[]
      for(let i of state.userMenuLinks){
        let temp=Object.assign({},i)
        temp['title']=LanguageUtility.getLangTexts(state.lang)[temp['title']]?LanguageUtility.getLangTexts(state.lang)[temp['title']]:temp['title']

        if( temp.levels && temp.levels.length>0){
          let access=false
          for(let level of temp.levels){
              if(SecurityUtillity.authorise(level,state.user.user_level)){
                access=true
              }
            }
            if(access){
              menu.push(temp)
            }
      }else{
        menu.push(temp)
      }
        
      }
      return menu
    },
    getOrganisationMenuLinks:(state)=>()=>{
      let menu=[]
      
      for(let i of state.organisationMenuLinks){
        let temp=Object.assign({},i)
        temp['title']=LanguageUtility.getLangTexts(state.lang)[temp['title']]?LanguageUtility.getLangTexts(state.lang)[temp['title']]:temp['title']
        if( temp.levels && temp.levels.length>0){
          let access=false
          for(let level of temp.levels){
            // console.log(level,state.user.user_level)
              if(SecurityUtillity.authorise(level,state.user.user_level)){
                access=true
              }
            }
            if(access){
              menu.push(temp)
            }
      }else{
        menu.push(temp)
      }
        
      }
      return menu
    },
    getSettingsMenuLinks:(state)=>()=>{
      let menu=[]
      for(let i of state.settingsMenuLinks){
        let temp=Object.assign({},i)
        temp['title']=LanguageUtility.getLangTexts(state.lang)[temp['title']]?LanguageUtility.getLangTexts(state.lang)[temp['title']]:temp['title']
        if( temp.levels && temp.levels.length>0){
          let access=false
          for(let level of temp.levels){
              if(SecurityUtillity.authorise(level,state.user.user_level)){
                access=true
              }
            }
            if(access){
              menu.push(temp)
            }
      }else{
        menu.push(temp)
      }
      }
      return menu
    },
    getConfigurationMenuLinks:(state)=>()=>{
      let menu=[]
      for(let i of state.configurationMenuLinks){
        let temp=Object.assign({},i)
        temp['title']=LanguageUtility.getLangTexts(state.lang)[temp['title']]?LanguageUtility.getLangTexts(state.lang)[temp['title']]:temp['title']
        if( temp.levels && temp.levels.length>0){
          let access=false
          for(let level of temp.levels){
              if(SecurityUtillity.authorise(level,state.user.user_level)){
                access=true
              }
            }
            if(access){
              menu.push(temp)
            }
      }else{
        menu.push(temp)
      }
        
      }
      return menu
    },
    getMenuLinks:(state)=>()=>{
      let menu=[]
      for(let i of state.menuLinks){
        let temp=Object.assign({},i)
        
        temp['title']=LanguageUtility.getLangTexts(state.lang)[temp['title']]?LanguageUtility.getLangTexts(state.lang)[temp['title']]:temp['title']
        if( temp.levels && temp.levels.length>0){
          let access=false
          for(let level of temp.levels){
              if(SecurityUtillity.authorise(level,state.user.user_level)){
                access=true
              }
            }
            if(access){
              menu.push(temp)
            }
      }else{
        menu.push(temp)
      }
        
      }
      return menu
    },
    // getGroupById: (state) => (id) => {
    //   return state.groups.find(x=>x.id === id)
    // },
    getNotificationGroupById: (state) => (id) => {
      return state.notificationGroups.find(x=>x.group_id === id)
    },
    getMGateById: (state) => (id) => {
      return state.mGates.find(x=>x.mgate_id === id)
    },
    // getNodeById: (state) => (id) => {
    //   return state.nodes.find(x=>x.node_id === id)
    // },
    // getSNodeById: (state) => (id) => {
    //   return state.snodes.find(x=>x.snode_id === id)
    // },
    getDeviceById: (state) => (id) => {
      return state.devices.find(x=> x.device_id == id)
    },
    getMachineById: (state) => (id) => {
      return state.machines.find(x=> x.machine_id == id)
    },
    getWorkspaceById: (state) => (id) => {
      return state.workspaces.find(x=>x.workspace_id === id)
    },
    getTriggerById: (state) => (id) => {
      return state.triggers.find(x=>x.trigger_id === id)
    },
    getViewById: (state) => (id) => {
      return state.views.find(x=>x.view_id === id)
    },
    getModuleById: (state) => (id) => {
      return state.entureModules.find(x=>x.module_id === id)
    },
    getAreaById: (state) => (id) => {
      return state.areas.find(x=>x.area_id === id)
    },
    getActivityById: (state) => (id) => {
      return state.activities.find(x=>x.activity_id === id)
    },
    getTaskById: (state) => (id) => {
      return state.tasks.find(x=>x.task_id === id)
    },
    getComboParamById: (state) => (id) => {
      return state.comboParams.find(x=>x.param_id === id)
    },
    getManualParameterById: (state) => (id) => {
      return state.manualParameters.find(x=>x.param_id === id)
    },
    getDerivedParametersById: (state) => (id) => {
      return state.derivedParameters.find(x=>x.param_id === id)
    },
    getListById: (state) => (id) => {
      return state.lists.find(x=>x.list_id === id)
    },
    getListDataById: (state) => (id) => {
      return state.listData.filter(x=>x.list_id === id)
    },
    getShiftById: (state) => (id) => {
      return state.shifts.find(x=>x.shift_id === id)
    },
    getShiftDataById: (state) => (id) => {
      return state.shiftData.filter(x=>x.shift_id === id)
    },
    getTargetDataById: (state) => (id) => {
      return state.targetData.filter(x=>x.target_id === id)
    },
    getTariffDataById: (state) => (id) => {
      return state.tariffData.filter(x=>x.tariff_id === id)
    },
    getLocationById: (state) => (id) => {
      return state.locations.find(x=>x.location_id === id)
    },
    getSKUById: (state) => (id) => {
      return state.skus.find(x=>x.sku_id === id)
    },
    getProductById: (state) => (id) => {
      return state.products.find(x=>x.product_id === id)
    },
    // getNodeStateById: (state) => (id) => {
    //   return state.nodeStates.find(x=>x.node_id === id)
    // },
    getDeviceTypeById: (state) => (id) => {
      //console.log(id)
      return state.deviceTypes.find(x=>x.id == id)
    },
    getDataTypeById: (state) => (id) => {
      //console.log(id)
      return state.dataTypes.find(x=>x.id == id)
    },
    /*getTagTypeById: (state) => (id) => {
      //console.log(id)
      return state.tagTypes.find(x=>x.id == id)
    },*/
    getTagByTagType: (state) => (tag_type) => {
      //console.log(id)
      return state.tagTypes.find(x=>x.tag_type == tag_type)
    },
    /*getTagIdByTagType: (state) => (tag_type) => {
      //console.log(id)
      let tag=state.tagTypes.find(x=>x.tag_type == tag_type)
      if (tag){
        return tag.id
      }

      return null
    },*/
    getMDeviceTypeById: (state) => (id) => {
      //console.log(id)
      return state.mDeviceTypes.find(x=>x.id == id)
    },
    getParameterById: (state) => (id) =>{
      return state.parameters.find(x=>x.parameter==id)
    },
    getParamById: (state) => (id) =>{
      return state.params.find(x=>x.param_id==id)
    },
    getNameByParamId:(state) =>(param_id)=> {
      let param = state.params.find(x=>x.param_id==param_id);
      if (param && param.name) {
          return param.name;
      }
      else {
          return param_id;
      }
  },
    getComputedParameterById: (state) => (id) =>{
      return state.computedParameters.find(x=>x.param_id==id)
    },
    getActionById: (state) => (id) =>{
      return state.actions.find(x=>x.action_id==id)
    },
    getCurrentSNodeDataById:(state)=>(id)=>{
      
      if(state.currentSNodeData[id]){
        //console.log("calling get snode current data")
        //console.log(state.currentSNodeData[id])
        return state.currentSNodeData[id]
      }
      return []
    },
    //TODO - remove
    getCurrentParameterDataById:(state)=>(id)=>{
      
      if(state.currentParameterData[id]){
        //console.log("calling get parameter current data")
        //console.log(state.currentParameterData[id])
        return state.currentParameterData[id]
      }
      return []
    },
    //TODO - remove
    getCurrentDerivedParameterDataById:(state)=>(id)=>{
      
      if(state.currentDerivedParameterData[id]){
        //console.log("calling get parameter current data")
        //console.log(state.currentParameterData[id])
        return state.currentDerivedParameterData[id]
      }
      return []
    },
    //TODO - remove
    getCurrentComputedParamUsage:(state)=>(id)=>{
      
      if(state.currentComputedParamUsage[id]){
        //console.log("calling get parameter current data")
        //console.log(state.currentParameterData[id])
        return state.currentComputedParamUsage[id]
      }
      return []
    },
    // getNodesByWorkspaceId: (state) => (workspace_id)=>{
      
    //   return state.nodes.filter(x=>x.workspace_id === workspace_id)
    // },
    getWorkspacesByHubId: (state) => (hub_id)=>{
      
      return state.workspaces.filter(x=>x.hub_id === hub_id)
    },
    getActionIDsByTriggerID:(state) => (trigger_id)=>{

      return state.actionMapping.filter(x=>x.trigger_id==trigger_id)
    },
    getTriggerIDsByParameter:(state) => (parameter)=>{

      return state.paramTriggerMapping.filter(x=>x.parameter==parameter)
    },
    getTargetIDsByParamID:(state) => (param_id)=>{

      return state.paramTargetMapping.filter(x=>x.param_id==param_id)
    },
    getParametersByDeviceID:(state) => (device_id)=>{

      return state.parameters.filter(x=>x.device_id==device_id)
    },
    getS7ParamsByDeviceID:(state) => (device_id)=>{

      return state.s7Params.filter(x=>x.device_id==device_id)
    },
    getParamsByMachineID:(state) => (machine_id)=>{

      return state.params.filter(x=>x.machine_id==machine_id)
    },
    getOPCParamsByVDeviceID:(state) => (device_id)=>{

      return state.opcParams.filter(x=>x.device_id==device_id)
    },
    getMQTTParamsByDeviceID:(state) => (device_id)=>{

      return state.mqttParameters.filter(x=>x.device_id==device_id)
    },
    getWriteParamsByDeviceID:(state) => (device_id)=>{

      return state.writeParams.filter(x=>x.device_id==device_id)
    },
    getManualParametersByDeviceID:(state) => (device_id)=>{

      return state.manualParameters.filter(x=>x.device_id==device_id)
    },
    getComputedParamsByDeviceID:(state) => (device_id)=>{

      return state.computedParameters.filter(x=>x.device_id==device_id)
    },
    getDerivedParamsByDeviceID:(state) => (device_id)=>{

      return state.derivedParameters.filter(x=>x.device_id==device_id)
    },
    // getSNodesByWorkspaceId: (state) => (workspace_id)=>{
      
    //   return state.snodes.filter(x=>x.workspace_id === workspace_id)
    // },
    getDevicesByWorkspaceId: (state) => (workspace_id)=>{
      
      return state.devices.filter(x=>x.workspace_id === workspace_id)
    },
    getVDevicesByWorkspaceId: (state) => (workspace_id)=>{
      
      return state.vdevices.filter(x=>x.workspace_id === workspace_id)
    },
    getS7DevicesByWorkspaceId: (state) => (workspace_id)=>{
      
      return state.s7Devices.filter(x=>x.workspace_id === workspace_id)
    },
    getMQTTDevicesByWorkspaceId: (state) => (workspace_id)=>{
      
      return state.mqttDevices.filter(x=>x.workspace_id === workspace_id)
    },
    // getLast30DaySNodeDataByID:(state) => (id) =>{
    //   if(state.last30DaysSNodeData[id]){
    //     //console.log("calling get snode current data")
    //     //console.log(state.currentSNodeData[id])
    //     return state.last30DaysSNodeData[id]
    //   }
    //   return []
    // },
    getNumberOfDevices: (state)=> ()=>{
      return state.devices.length //state.nodes.filter(x=>x.tag === 'device').length // + state.devices.length 
    },
    // getNumberOfNodes: (state)=> ()=>{
    //   return state.nodes.length //state.nodes.filter(x=>x.tag === 'device').length // + state.devices.length 
    // },
    getError:(state)=>()=>{
        return state.error
    },
    // getGroups:(state)=>()=>{
    //   return state.groups
    // },
    getDepartments:(state)=>()=>{
      return state.departments
    },
    getIssuerAccessDetails:(state)=>()=>{
      if(state.user.userid){
        state.itemIssuers.filter(item=>{
          return item.userid==state.user.userid
        })
      }
    },
    getNotificationGroups:(state)=>()=>{
      return state.notificationGroups
    },
    getMachineGroups:(state)=>()=>{
      return state.machineGroups
    },
    getParamGroups:(state)=>()=>{
      return state.paramGroups
    },
    getUserGroups:(state)=>()=>{
      return state.userGroups
    },
    getHubs: (state) =>()=>{
      return state.hubs
    },
    getActions: (state) =>()=>{
      return state.actions
    },
    getWords: (state) =>()=>{
      return state.words
    },
    getLists: (state) =>()=>{
      return state.lists
    },
    getShifts: (state) =>()=>{
      return state.shifts
    },
    getTriggers: (state) =>()=>{
      return state.triggers
    },
    getMGates: (state) =>()=>{
      return state.mGates
    },
    getOrganisationID: (state) =>()=>{
      if(state.organisation && state.organisation.organisation_id){
        return state.organisation.organisation_id
      }else{
        return ''
      }

    },
    getOrganisationName: (state) =>()=>{
      if(state.organisation && state.organisation.name){
        return state.organisation.name
      }else{
        return ''
      }

    },
    engineerAccess:(state)=>()=>{
      return Number(state.user.user_level)>10
    },
    getApi:(state)=>()=>{
      return state.api
    },
    // getTotalEnergyUsed:(state)=>()=>{
    //   let l=0
    //   for(let i in state.monthlyData){
    //     l+=parseFloat(state.monthlyData[i].utillised)
    //   }
    //   return l
    // },
    // getTotalEnergyWasted:(state)=>()=>{
    //   let l=0
    //   for(let i in state.monthlyData){
    //     l+=parseFloat(state.monthlyData[i].wasted)
    //   }
    //   return l
    // },
    // getNodesForGroup:(state)=>(group)=>{
    //   let nodeList=[]
      
    //   for(let i of group.items){
    //     //console.log(i)
    //     if(i.tag=='node'){
    //       nodeList.push(state.nodes.find(x=>x.node_id==i.id))
    //     }
    //     //nodeList.push(state.nodes.find(x=>x.node_id === group.items[i].node_id))
    //   }
    //   return nodeList
    // },
    // getSNodesForGroup:(state)=>(group)=>{
    //   let snodeList=[]
      
    //   for(let i of group.items){
    //     //console.log(i)
    //     if(i.tag=='snode'){
    //       snodeList.push(state.snodes.find(x=>x.snode_id==i.id))
    //     }
    //     //nodeList.push(state.nodes.find(x=>x.node_id === group.items[i].node_id))
    //   }
    //   return snodeList
    // },
    getTotalDeviceTypes:(state)=>()=>{
      let dmp={}
      for(let i in state.nodes){
        let dev=state.deviceTypes.find(x=>x.id === state.nodes[i].devType)
        if(!dev){
          dev={'type':'Unassigned'}
        }
        if(dev.type in dmp){
          dmp[dev.type]+=1
        }else{
          dmp[dev.type]=1
        }
      }
      //console.log(dmp)
      let dataPoints={'labels':[],'data':[]}
      for(let k in dmp){
        dataPoints.labels.push(k)
        dataPoints.data.push(dmp[k])
      }
      return dataPoints

    },
    getTotalMDeviceTypes:(state)=>()=>{
      let dmp={}
      for(let i in state.devices){
        let dev=state.mDeviceTypes.find(x=>x.id == state.devices[i].mdevice_type)
        if(!dev){
          dev={'type':'Unassigned'}
        }
        if(dev.type in dmp){
          dmp[dev.type]+=1
        }else{
          dmp[dev.type]=1
        }
      }
      //console.log(dmp)
      let dataPoints={'labels':[],'data':[]}
      for(let k in dmp){
        dataPoints.labels.push(k)
        dataPoints.data.push(dmp[k])
      }
      return dataPoints

    }

  }


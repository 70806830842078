import English from "@/utillities/languages/English";

export default {
    ...English,
    "accept": "സ്വീകരിക്കുക",
"reject": "നിരാകരിക്കുക",
"move_to_next_stage": "അടുത്ത ഘട്ടത്തിലേക്ക് നീങ്ങുക",
"save_as_draft": "ഡ്രാഫ്റ്റായി സേവ് ചെയ്യുക",
"save_and_submit": "സേവ് ചെയ്ത് സമർപ്പിക്കുക",
"closure": "അവസാനം",
"duration": "ദൈർഘ്യം",
"refill_request": "മറുപൂരത്തിനുള്ള അപേക്ഷ",
"is_be_cancelled": "റദ്ദാക്കപ്പെടും",
"delete_this": "ഇത് മായ്ക്കുക",
"event": "സംഭവം",

    "for": "ഇതിന്",
    "of": "ഇതിന്റെ",
    "downtime": "ഡൗൺടൈം",

    "metrics": "മെട്രിക്സ്",
    "oee_by_day": "ഓരോ ദിവസത്തിനും OEE",

    "ideal_cycle_time": "ആദർശ ചക്ര സമയം",
    
    "minutes": "മിനിറ്റ്",
    "production_time": "ഉല്‍പ്പാദന സമയം",
    "back": "തിരികെ",
    "down_times": "ഡൗണ്‍ ടൈംസ്",

    "top_5_reasons": "ടോപ്പ് 5 കാരണങ്ങൾ",
    "top_5_categories": "ടോപ്പ് 5 വിഭാഗങ്ങൾ",
    "enter_apparent_energy_manually": "കാണാവുന്ന ഊർജ്ജം കൈമുറയായി പ്രവേശിപ്പിക്കുക",

    "oee": "OEE",
    "reason": "കാരണം",
    "add_downtime_reason": "ഡൗntime കാരണം ചേർക്കുക",

    "default_target": "പ്രകൃതിരീതിയിലുള്ള ലക്ഷ്യം",

    "enter_in_minutes": "മിനിറ്റിനുള്ളിൽ പ്രവേശിക്കുക",
    "custome_fields": "ഇഷ്ടാനുസൃത ഫീൽഡുകൾ",
    "enter_data_in_minutes": "മിനിറ്റുകൾക്കുള്ളിൽ ഡാറ്റ നൽകുക",
    "enter_data_in_hours": "മണിക്കൂറുകളിൽ ഡാറ്റ നൽകുക",
    "create_tod_billing_widget": "ടോഡ് ബില്ലിംഗ് വിജറ്റ് സൃഷ്ടിക്കുക",
    "edit_tod_billing_widget": "ടോഡ് ബില്ലിംഗ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "choose_from_machine_groups": "മെഷീൻ ഗ്രൂപ്പുകളിൽ നിന്ന് തിരഞ്ഞെടുക്കുക",
    "tod_billing": "ടോഡ് ബില്ലിംഗ്",
    "choose": "തിരഞ്ഞെടുക്കുക",
    "create_shift_based_machine_usage_log": "മെഷീൻ ഉപയോഗ ലോഗ് സൃഷ്ടിക്കുക",
    "shift_based_machine_usage_log": "മെഷീൻ ഉപയോഗം ലോഗ്",
    "rejected": "നിരസിച്ചു",
    "machine_operator": "മെഷീൻ ഓപ്പറേറ്റർ",
    "production_end_timestamp": "ഉൽപാദന അവസാന സമയം",
    "production_start_timestamp": "ഉൽപാദന ആരംഭ സമയം",
    "running_time": "പ്രവർത്തിക്കുന്ന സമയം",
    "idle_time": "നിഷ്ക്രിയ സമയം",
    "down_time": "താഴേക്ക്",
    "date_row_index": "തീയതി വരി",
    "date_position": "തീയതി സ്ഥാനം",
    "save": "രക്ഷിക്കുക",
    "discard": "ഉപേക്ഷിക്കുക",
    "adjust": "കമപ്പെടുത്തുക",
    "safety_incident_sheet": "സുരക്ഷാ സംഭവ ഷീറ്റ്",
    "safety_inspection_sheet": "സുരക്ഷാ പരിശോധന ഷീറ്റ്",
    "ghg_emission_logs": "GHG എമിഷൻ ലോഗുകൾ",
    "add_ghg_emission_data": "GHG എമിഷൻ ലോഗുകൾ ചേർക്കുക",
    "ghg_scope_conversion": "GHG സ്കോപ്പ് പരിവർത്തനം",
    "create_ghg_scope_conversion": "GHG സ്കോപ്പ് പരിവർത്തനം സൃഷ്ടിക്കുക",
    "ghg_data_logs": "GHG എമിഷൻ ഡാറ്റ ലോഗുകൾ",
    "choose_inspection_type_optional": "ഇൻസ്റ്റെക്ഷൻ തരം തിരഞ്ഞെടുക്കുക (ഓപ്ഷണൽ)",
    "supplier_code": "വിതരണ കോഡ്",
    "contact": "സന്വര്ക്കം",
    "point_of_contact": "കോൺടാക്റ്റിന്റെ പോയിന്റ്",
    "quantity_supplied": "വിതരണം വിതരണം ചെയ്ത അളവ്",
    "create_product": "ഉൽപ്പന്നം സൃഷ്ടിക്കുക",
    "products": "ഉൽപ്പന്നങ്ങൾ",
    "data": "അടിസ്ഥാനവിവരം",
    "timezone": "സമയമേഖല",
    "tariffs": "താരിഫ്",
    "days_delta": "ഡെൽറ്റ",
    "machine_groups": "മെഷീൻ ഗ്രൂപ്പുകൾ",
    "param_groups": "പർമെറ്റർ ഗ്രൂപ്പുകൾ",
    "user_groups": "ഉപയോക്തൃ ഗ്രൂപ്പുകൾ",
    "topic_prefix": "വിഷയ പ്രിഫിക്സ്",
    "message_direction": "സന്ദേശ ദിശ",
    "safety_inspection": "സുരക്ഷാ പരിശോധന",
    "write_inspection_log": "പരിശോധന ലോഗ് എഴുതുക",
    "inspection_id": "പരീക്ഷണ ഐഡി",
    "inspector_id": "ഇൻസ്പെക്ടർ ഐഡി",
    "inspection_date": "പരിശോധന തീയതി",
    "inspection_type": "പരിശോധന തരം",
    "findings": "കണ്ടെത്തലുകൾ",
    "actions_required": "പ്രവർത്തനങ്ങൾ ആവശ്യമാണ്",
    "followup_date": "ഫോളോ അപ്പ് തീയതി",
    "status": "പദവി",
    "safety_incident": "സുരക്ഷാ സംഭവം",
    "write_incident": "എഴുതുക സംഭവം",
    "incident_description": "സംഭവ വിവരണം",
    "severity": "കാഠിന്യം",
    "accident": "ആകസ്മികത",
    "accident_with_loss": "നഷ്ടത്തിൽ അപകടം",
    "closed": "അടച്ച",
    "corrective_action": "തിരുത്തൽ നടപടി",
    "employee_id": "ജീവനക്കാരുടെ ഐഡി",
    "apply": "അപേക്ഷിക്കുക",
    "choose_location_optional": "സ്ഥാനം തിരഞ്ഞെടുക്കുക (ഓപ്ഷണൽ)",
    "locations": "സ്ഥലങ്ങൾ",
    "locations_event_types": "ലൊക്കേഷനുകൾ ഇവന്റ് തരങ്ങൾ",
    "create_tariff": "താരിഫ് സൃഷ്ടിക്കുക",
    "default_tariff_val": "സ്ഥിരസ്ഥിതി താരിഫ് മൂല്യം",
    "add_tariff_data": "താരിഫ് ഡാറ്റ ചേർക്കുക",
    "edit_tariff": "താരിഫ് എഡിറ്റുചെയ്യുക",
    "delete_tariff": "താരിഫ് ഇല്ലാതാക്കുക",
    "tariff_value": "താരിഫ് മൂല്യം",
    "machine_status_timeline": "മെഷീൻ നില ടൈംലൈൻ",
    "total_duration": "മൊത്തം ദൈർഘ്യം",
    "generate": "ഉത്പാദിപ്പിക്കുക",
    "remove_machine": "മെഷീൻ നീക്കംചെയ്യുക",
    "edit_machine_status_timeline_widget": "മെഷീൻ നില എഡിറ്റുചെയ്യുക ടൈംലൈൻ വിജറ്റ്",
    "add_machine_Status__timeline_widget": "മെഷീൻ സ്റ്റാറ്റസ് ടൈംലൈൻ വിജറ്റ് ചേർക്കുക",
    "select_timeframe": "ടൈംഫ്രെയിം തിരഞ്ഞെടുക്കുക",
    "timeframe_type_shift": "തവണ",
    "timeframe_type_custom": "സന്വദായം",
    "timeframe_type_yesterday": "തലേനാള്",
    "edit_report_details": "റിപ്പോർട്ട് വിശദാംശങ്ങൾ എഡിറ്റുചെയ്യുക",
    "download_current_template": "നിലവിലെ ടെംപ്ലേറ്റ് ഡൗൺലോഡുചെയ്യുക",
    "date_column_index": "തീയതി നിര (സൂചിക)",
    "select_area": "ഏരിയ തിരഞ്ഞെടുക്കുക",
    "select_shift": "ഷിഫ്റ്റ് തിരഞ്ഞെടുക്കുക",
    "dashboard_heading": "ഡാഷ്ബോർഡ്",
    "production_params": "പ്രൊഡക്ഷൻ പാരാമുകൾ",
    "no_modules_configured": "മൊഡ്യൂളുകളൊന്നുമില്ല",
    "dashboard_panel": "ഡാഷ്ബോർഡ് പാനൽ",
    "choose_type": "തരം തിരഞ്ഞെടുക്കുക",
    "name_is_required": "പേര് ആവശ്യമാണ്",
    "description_is_required": "വിവരണം ആവശ്യമാണ്",
    "required": "ആവശമായ",
    "you_have_to_select_something": "നിങ്ങൾ എന്തെങ്കിലും തിരഞ്ഞെടുക്കണം",
    "name_must_be_less_than_30_characters": "പേര് 30 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "name_must_be_less_than_25_characters": "പേര് 25 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "name_must_be_less_than_50_characters": "പേര് 50 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "description_must_be_less_than_35_characters": "വിവരണം 35 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "description_must_be_less_than_55_characters": "വിവരണം 55 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "number_must_be_less_than_6_digits": "നമ്പർ 6 അക്കത്തിൽ കുറവായിരിക്കണം",
    "number_must_be_less_than_8_digits": "നമ്പർ 8 അക്കത്തിൽ കുറവായിരിക്കണം",
    "number_must_be_less_than_7_digits": "നമ്പർ 7 അക്കത്തിൽ കുറവായിരിക്കണം",
    "panel": "പാനം",
    "gauge": "മാനദണ്ഡം",
    "param": "പര",
    "machine": "യന്തം",
    "add_panel_to_dashboard": "ഡാഷ്ബോർഡിലേക്ക് പാനൽ ചേർക്കുക",
    "edit_dashboard_panel": "ഡാഷ്ബോർഡ് പാനൽ എഡിറ്റുചെയ്യുക",
    "param_type": "പരാം തരം",
    "workspace": "വർക്ക്സ്പെയ്സ്",
    "device": "ഉപായം",
    "name": "പേര്",
    "language": "ഭാഷ",
    "time_duration": "സമയ ദൈർഘ്യം",
    "submit": "കീഴടങ്ങുക",
    "cancel": "റദ്ദാക്കുക",
    "close": "അടയ്ക്കുക",
    "usage": "ഉപയോഗം",
    "peak_value": "പീക്ക് മൂല്യം",
    "current_data": "നിലവിലെ ഡാറ്റ",
    "parameter": "പാരാമീറ്റർ",
    "computed_param": "കണക്കാക്കിയ പാരാം",
    "derived_param": "ഉത്ഭവിച്ച പരാം",
    "t4_days": "4 ദിവസം",
    "last_7_days": "കഴിഞ്ഞ 7 ദിവസം",
    "last_30_days": "കഴിഞ്ഞ 30 ദിവസം",
    "last_60_days": "കഴിഞ്ഞ 60 ദിവസം",
    "last_90_days": "കഴിഞ്ഞ 90 ദിവസം",
    "last_180_days": "കഴിഞ്ഞ 180 ദിവസം",
    "last_365_days": "ലാറ്റ് 365 ദിവസം",
    "last_1_hour": "കഴിഞ്ഞ 1 മണിക്കൂർ",
    "last_3_hours": "അവസാന 3 മണിക്കൂർ",
    "last_6_hours": "കഴിഞ്ഞ 6 മണിക്കൂർ",
    "reorder_views": "പുന order ക്രമീകരിക്കുക",
    "create_view": "സൃഷ്ടിക്കുക",
    "clear_view_name_filter": "ഫിൽട്ടറുകൾ മായ്ക്കുക",
    "view_type": "കാഴ്ച തരം",
    "description": "വിവരണം",
    "table_view": "പട്ടിക കാഴ്ച",
    "widget_view": "വിജറ്റ് കാഴ്ച",
    "dynamic_timeframed_widget_view": "ഡൈനാമിക് ടൈംഫ്രാമേജ് വിജറ്റ് കാഴ്ച",
    "diagram_view": "ഡയഗ്രം കാഴ്ച",
    "energy_usage_view": "Energy ർജ്ജ ഉപയോഗ കാഴ്ച",
    "machine_scroll_view": "മെഷീൻ സ്ക്രോൾ കാഴ്ച",
    "param_scroll_view": "പാരാം സ്ക്രോൾ കാഴ്ച",
    "consolidated_view": "ഏകീകൃത കാഴ്ച",
    "air_quality_view": "വായുവിന്റെ നിലവാരം",
    "view_auto_change": "യാന്ത്രിക മാറ്റം കാണുക",
    "no_views_configured": "കാഴ്ചപ്പാടുകളൊന്നും ക്രമീകരിച്ചിട്ടില്ല",
    "add_to_dashboard": "ഡാഷ്ബോർഡിലേക്ക് ചേർക്കുക",
    "edit_view_name": "പേര് എഡിറ്റുചെയ്യുക പേര്",
    "edit_view": "കാഴ്ച എഡിറ്റുചെയ്യുക",
    "refresh": "ഉന്മേഷം വീണ്ടെടുക്കുക",
    "create_machine": "യന്ത്രം സൃഷ്ടിക്കുക",
    "add_machine": "യന്ത്രം ചേർക്കുക",
    "sequence": "അനുകമം",
    "machine_type": "യന്ത്ര തരം",
    "physical_machine": "ഫിസിക്കൽ മെഷീൻ",
    "virtual_machine": "വെർച്വൽ മെഷീൻ",
    "positive_workspace": "പോസിറ്റീവ് വർക്ക്സ്പെയ്സ്",
    "positive_machine": "പോസിറ്റീവ് യന്ത്രം",
    "negative_workspace": "നെഗറ്റീവ് വർക്ക്സ്പെയ്സ്",
    "negative_machine": "നെഗറ്റീവ് മെഷീൻ",
    "label": "മേല്വിലാസക്കുറി",
    "positive_machine_Ids": "പോസിറ്റീവ് മെഷീൻ ഐഡി",
    "negative_machine_Ids": "നെഗറ്റീവ് മെഷീൻ ഐഡി",
    "trend_analysis_tools": "ട്രെൻഡ് വിശകലന ഉപകരണങ്ങൾ",
    "energy_consumption_by_machines": "മെഷീനുകളുടെ energy ർജ്ജ ഉപഭോഗം",
    "Create_report": "റിപ്പോർട്ട് സൃഷ്ടിക്കുക",
    "view_report": "റിപ്പോർട്ട് കാണുക",
    "tarrif_on": "താരിഫ് ഓൺ",
    "active_energy": "സജീവ .ർജ്ജം",
    "apparent_energy": "വ്യക്തമായ .ർജ്ജം",
    "automate_report": "റിപ്പോർട്ട് യാന്ത്രികമാക്കുക",
    "add_root_machine": "റൂട്ട് മെഷീൻ ചേർക്കുക",
    "add_common_machine": "പൊതുവായ യന്ത്രം ചേർക്കുക",
    "notification_groups": "അറിയിപ്പ് ഗ്രൂപ്പുകൾ",
    "add_machines_to_list": "പട്ടികയിലേക്ക് മെഷീനുകൾ ചേർക്കുക",
    "lists": "പട്ടിക",
    "machine_list": "യന്ത്ര പട്ടിക",
    "data_historian": "ഡാറ്റ ചരിത്രകാരൻ",
    "timeframed_trends": "കാലഹരണപ്പെട്ട ട്രെൻഡുകൾ",
    "dual_axis_trend_comparison": "ഇരട്ട ആക്സിസ് ട്രെൻഡ് താരതമ്യം",
    "granular24_hrs_trend": "ഗ്രാനുലാർ 24 എച്ച്ആർഎസ് പ്രവണത",
    "machinewise_trend": "മെഷീൻ തിരിച്ചുള്ള പ്രവണത",
    "basic_statistical_trends": "അടിസ്ഥാന സ്റ്റാറ്റിസ്റ്റിക്കൽ ട്രെൻഡുകൾ",
    "log_analysis": "ലോഗ് അനാലിസിസ്",
    "aqi_comparison_panel": "AQI താരതമ്യ പാനൽ",
    "time_of_day_trend": "പകൽ പ്രവണത",
    "param_historical_comparisons": "പാരാം ചരിത്ര താരതമ്യങ്ങൾ",
    "energy_usage_trends": "Energy ർജ്ജ ഉപയോഗ ട്രെൻഡുകൾ",
    "machine_name": "യന്ത്രത്തിന്റെ പേര്",
    "param_name": "PARAM പേര്",
    "trend_view": "ട്രെൻഡ് കാഴ്ച",
    "raw": "അസംസ്കൃതമായ",
    "trend": "ലഘുലേഖ",
    "normalised": "നോർമലൈസ് ചെയ്തു",
    "from_date": "തീയതി മുതൽ",
    "to_date": "തീയതി",
    "from": "മുതല്",
    "to": "... ലേക്ക്",
    "hour": "മണിക്കൂര്",
    "minute": "മിനിറ്റ്",
    "generate_report": "റിപ്പോർട്ട് സൃഷ്ടിക്കുക",
    "time_range": "സമയ ശ്രേണി",
    "no_data_available": "ഡാറ്റയൊന്നും ലഭ്യമല്ല",
    "daily": "ദിവസേന",
    "monthly": "മാസത്തില് ഒരിക്കല്",
    "weekly": "പതിവാരമായ",
    "week": "ആഴ്ച",
    "yearly": "വര്ത്യീയ",
    "device_name": "ഉപകരണത്തിന്റെ പേര്",
    "machine_wise_comparison": "മെഷീൻ തിരിച്ച് താരതമ്യം",
    "stats_detailed_report": "സ്ഥിതിവിവരക്കണക്കുകൾ വിശദമായ റിപ്പോർട്ട്",
    "log_type": "ലോഗ് തരം",
    "choose_Log": "ലോഗ് തിരഞ്ഞെടുക്കുക",
    "area": "പദേശം",
    "aq_device": "Aq ഉപകരണം",
    "nebeskie_labs_office": "നെബെസ്കി ലാബ്സ് ഓഫീസ്",
    "date_range": "തീയതി_RANGE",
    "this_year": "ഈ വര്ഷം",
    "last 6 months": "കഴിഞ്ഞ 6 മാസം",
    "last quater": "അവസാന പാദം",
    "mtd": "MTD",
    "ytd": "Youtube ഡൗൺലോഡർ",
    "yesterday": "തലേനാള്",
    "custom": "സന്വദായം",
    "run_report": "റൺ റിപ്പോർട്ട്",
    "create_report": "റിപ്പോർട്ട് സൃഷ്ടിക്കുക",
    "usage_report": "ഉപയോഗ റിപ്പോർട്ട്",
    "consolidated_report": "ഏകീകൃത റിപ്പോർട്ട്",
    "dynamic_excel_report": "ഡൈനാമിക് എക്സൽ റിപ്പോർട്ട്",
    "energy_usage_report": "Energy ർജ്ജ ഉപയോഗ റിപ്പോർട്ട്",
    "create_action": "പ്രവർത്തനം സൃഷ്ടിക്കുക",
    "action_type": "പ്രവർത്തന തരം",
    "notification_group": "അറിയിപ്പ് ഗ്രൂപ്പ്",
    "address": "അഭിസംബോധന ചെയ്യുക",
    "number_of_registers": "രജിസ്റ്ററുകളുടെ എണ്ണം",
    "value": "വിലമതിക്കുക",
    "send_notification": "അറിയിപ്പ് അയയ്ക്കുക",
    "write_parameter": "പാരാമീറ്റർ എഴുതുക",
    "relays": "റിലേകൾ",
    "relay_1": "റിലേ 1",
    "relay_2": "റിലേ 2",
    "relay_3": "റിലേ 3",
    "relay_4": "റിലേ 4",
    "email_must_be_valid": "ഇമെയിൽ സാധുവായിരിക്കണം",
    "it_should_be_a_number": "അത് ഒരു സംഖ്യയായിരിക്കണം",
    "it_should_be_a_valid_number": "അത് സാധുവായ ഒരു സംഖ്യയായിരിക്കണം",
    "operator": "ടെലഫോണ്ഓപ്പറേറ്റര്",
    "operand": "ഓപ്പറേഷൻ",
    "targets": "ടാർഗെറ്റുകൾ",
    "target_value": "ടാർഗെറ്റ് മൂല്യം",
    "wait_time_seconds": "കാത്തിരിപ്പ് സമയം (സെക്കൻഡ്)",
    "greater_than": "അതിലും വലുത്",
    "modulus_greater_than": "മൊമ്മലസ് എന്നതിനേക്കാൾ വലുത്",
    "lesser_than": "അതിൽ കുറവ്",
    "modulus_lesser_than": "മൊമ്മലസ് കുറവാണ്",
    "equal_to": "തുല്യമായി",
    "not_equal_to": "തുല്യമല്ല",
    "modulus_equal_to": "മൊമ്മലസ് തുല്യമാണ്",
    "greater_than_equal_to": "തുല്യത്തേക്കാൾ വലുത്",
    "modulus_greater_than_equal_to": "മൊമ്മലസ് തുല്യത്തേക്കാൾ വലുതാണ്",
    "lesser_than_equal_to": "തുല്യമായതിനേക്കാൾ കുറവാണ്",
    "modulus_lesser_than_equal_to": "മൊമ്മലസ് തുല്യമായി കുറവാണ്",
    "bit_high": "ബിറ്റ് ഉയർന്നത്",
    "bit_low": "ബിറ്റ് കുറവാണ്",
    "it_should_be_a_positive_whole_number": "അത് ഒരു പോസിറ്റീവ് മുഴുവൻ സംഖ്യയായിരിക്കണം",
    "it_should_be_a_positive": "അത് ഒരു പോസിറ്റീവ് ആയിരിക്കണം",
    "create_process": "പ്രക്രിയ സൃഷ്ടിക്കുക",
    "edit_process": "പ്രക്രിയ എഡിറ്റുചെയ്യുക",
    "status_rule": "നില നിയമം",
    "create_process_status_rule": "പ്രോസസ്സ് നില നിയമം സൃഷ്ടിക്കുക",
    "tag_type": "ടാഗ് തരം",
    "process_status": "പ്രോസസ് പദവി",
    "machines": "യന്ത്രങ്ങൾ",
    "running": "ഓടുന്നു",
    "idling": "നിഷ്ക്രിയം",
    "offline": "ഓഫ്ലൈൻ",
    "no_machines_configured": "മെഷീനുകളൊന്നും ക്രമീകരിച്ചിട്ടില്ല",
    "delete_process": "പ്രക്രിയ ഇല്ലാതാക്കുക",
    "add_machine_to_process": "പ്രോസസ്സ് ചെയ്യുന്നതിന് മെഷീൻ ചേർക്കുക",
    "delete_premise_area": "പ്രീസെ പ്രദേശം ഇല്ലാതാക്കുക",
    "add": "കൂട്ടിച്ചേര്ക്കുക",
    "add_to_list": "പട്ടികയിലേക്ക് ചേർക്കുക",
    "search": "അനേഷണം",
    "switch_to": "ഇതിലേക്ക് മാറുക",
    "switch_to_base_org": "അടിസ്ഥാന ഓർഗിലേക്ക് മാറുക",
    "switch": "മാറുക",
    "create_new_organisation": "പുതിയ ഓർഗനൈസേഷൻ സൃഷ്ടിക്കുക",
    "organisation_name": "ഓർഗനൈസേഷന്റെ പേര്",
    "organisation_type": "ഓർഗനൈസേഷൻ തരം",
    "country_code": "രാജ്യ കോഡ്",
    "country": "രാജം",
    "state": "രാജം",
    "clear": "വക്തമായ",
    "change_organisation": "ഓർഗനൈസേഷൻ മാറ്റുക",
    "organisation_id": "ഓർഗനൈസേഷൻ ഐഡി",
    "update_your_oem_logo": "നിങ്ങളുടെ OEM ലോഗോ അപ്ഡേറ്റുചെയ്യുക",
    "select_a_logo_to_upload": "അപ്ലോഡുചെയ്യാൻ ഒരു ലോഗോ തിരഞ്ഞെടുക്കുക",
    "submit_logo": "ലോഗോ സമർപ്പിക്കുക",
    "create_workspace": "വർക്ക്സ്പെയ്സ് സൃഷ്ടിക്കുക",
    "hub_id": "ഹബ് ഐഡി",
    "name_must_be_less_than_45_characters": "പേര് 45 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "id_is_required": "ഐഡി ആവശ്യമാണ്",
    "no_space_allowed": "ഒരു സ്ഥലവും അനുവദനീയമല്ല",
    "id_must_be_less_than_6_characters": "ഐഡി 6 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "edit_workspace": "വർക്ക്സ്പെയ്സ് എഡിറ്റുചെയ്യുക",
    "delete_workspace": "വർക്ക്സ്പെയ്സ് ഇല്ലാതാക്കുക",
    "add_modbus_device": "മോഡ്ബസ് ഉപകരണം ചേർക്കുക",
    "add_device": "ഉപകരണം ചേർക്കുക",
    "oops": "ക്ഷമിക്കണം !!",
    "ok": "ശരി",
    "slave_id": "സ്ലേവ് ഐഡി",
    "device_type": "ഉപകരണ തരം",
    "connection_type": "കണക്ഷൻ തരം",
    "mGate": "മിഗേറ്റ്",
    "make": "ഉണ്ടാക്കുക",
    "model": "മാതൃക",
    "name_must_be_less_than_33_characters": "പേര് 33 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "name_must_be_less_than_11_characters": "പേര് 11 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "it_should_be_a_valid_slave_id": "അത് സാധുവായ ഒരു അടിമ ഐഡി ആയിരിക്കണം",
    "name_must_be_less_than_4_digits": "പേര് 4 അക്കത്തിൽ കുറവായിരിക്കണം",
    "modbus_tcp": "മോഡ്ബസ് ടിസിപി",
    "modbus_rtu": "മോഡ്ബസ് ആർടിയു",
    "add_virtual_device": "വെർച്വൽ ഉപകരണം ചേർക്കുക",
    "add_mqtt_device": "MQTT ഉപകരണം ചേർക്കുക",
    "topic": "വിഷയം",
    "identifier": "തിരിച്ചറിയല്",
    "identifier_value": "ഐഡന്റിഫയർ മൂല്യം",
    "name_must_be_less_than_20_characters": "പേര് 20 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "no_special_characters_or_space_allowed": "പ്രത്യേക പ്രതീകങ്ങളോ സ്ഥലമോ അനുവദനീയമല്ല",
    "must_be_less_than_15_characters": "15 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "create_role": "പങ്ക് സൃഷ്ടിക്കുക",
    "role": "പങ്ക്",
    "role_is_required": "റോൾ ആവശ്യമാണ്",
    "role_must_be_less_than_40_characters": "റോൾ 40 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "role_should_not_contain_white_characters": "റോളിന് വൈറ്റ് പ്രതീകങ്ങൾ അടങ്ങിയിരിക്കരുത്",
    "comparison": "താരതമം",
    "historical_time_of_day_comparison_parameter": "ചരിത്രത്തിന്റെ അവധി താരതമ്യം - പാരാമീറ്റർ",
    "device_parameter": "ഉപകരണ പാരാമീറ്റർ",
    "date_1": "തീയതി 1",
    "date_2": "തീയതി 2",
    "tod_comparison_report": "ടോഡ് താരതമ്യ റിപ്പോർട്ട്",
    "time_frame": "ടൈം ഫ്രെയിം",
    "download_table": "ഡൗൺലോഡ് പട്ടിക",
    "table": "പീഠം",
    "showMinMax_values": "മിനിറ്റ്, പരമാവധി മൂല്യങ്ങൾ കാണിക്കുക",
    "usage_or_average": "ഉപയോഗം അല്ലെങ്കിൽ ശരാശരി",
    "min_value": "കുറഞ്ഞ മൂല്യം",
    "last_12_weeks": "കഴിഞ്ഞ 12 ആഴ്ച",
    "last_6_months": "കഴിഞ്ഞ 6 മാസം",
    "last_12_months": "കഴിഞ്ഞ 12 മാസം",
    "year_5": "5 വർഷം",
    "machinewise_comparison": "മെഷീൻ തിരിച്ച് താരതമ്യം",
    "timeFrame": "ടൈം ഫ്രെയിം",
    "it_should_be_a_date": "അത് ഒരു തീയതിയായിരിക്കണം",
    "time_of_day_parameter": "പകൽ- പാരാമീറ്റർ",
    "tod_report": "ടോഡ് - റിപ്പോർട്ട്",
    "in_this_timeframe": "ഈ സമയപരിധി",
    "average": "ശരാശരി",
    "maximum": "പരമാവധി",
    "minimum": "ഏറ്റവും കുറഞ്ഞ",
    "energy_usage_trend": "Energy ർജ്ജ ഉപയോഗ പ്രദേശം",
    "standard_deviation": "അടിസ്ഥാന വ്യതിയാനം",
    "statistical_mode": "സ്റ്റാറ്റിസ്റ്റിക്കൽ മോഡ്",
    "production_logs": "പ്രൊഡക്ഷൻ ലോഗുകൾ",
    "capacity_logs": "ശേഷിയുള്ള ലോഗുകൾ",
    "dynamic_logs": "ഡൈനാമിക് ലോഗുകൾ",
    "pollutants": "മലിനരമാർ",
    "group_by": "ഗ്രൂപ്പ്",
    "generate_grouped_trends": "ഗ്രൂപ്പ് ചെയ്ത ട്രെൻഡുകൾ സൃഷ്ടിക്കുക",
    "tCo2": "tco2",
    "scope_1": "സ്കോപ്പ് 1",
    "scope_2": "സ്കോപ്പ് 2",
    "scope_3": "സ്കോപ്പ് 3",
    "source": "ഉല്ഭവസ്ഥാനം",
    "type": "ടൈപ്പ് ചെയ്യുക",
    "user_access_log": "ഉപയോക്തൃ ആക്സസ് ലോഗ്",
    "user_logs": "ഉപയോക്തൃ ലോഗുകൾ",
    "fetch_logs": "ലോഗുകൾ നേടുക",
    "update_profile": "പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്യുക",
    "lang": "ഗാനത്ത്",
    "theme": "പതിപാദം",
    "phone": "ഫോൺ",
    "update": "അപ്ഡേയ്ക്കുക",
    "update_password": "പാസ്വേഡ് അപ്ഡേറ്റുചെയ്യുക",
    "change_password": "പാസ്വേഡ് മാറ്റുക",
    "old_password": "പഴയ പാസ്വേഡ്",
    "new_password": "പുതിയ പാസ്വേഡ്",
    "confirm_password": "പാസ്വേഡ് സ്ഥിരീകരിക്കുക",
    "password_must_be_more_than_8_characters": "പാസ്വേഡ് 8 പ്രതീകങ്ങളിൽ കൂടുതൽ ആയിരിക്കണം",
    "passwords_do_not_match": "പാസ്വേഡുകൾ പൊരുത്തപ്പെടുന്നില്ല",
    "add_hub": "ഹബ് ചേർക്കുക",
    "id_must_be_less_than_4_characters": "ഐഡി 4 പ്രതീകങ്ങളിൽ കുറവായിരിക്കണം",
    "add_maintainer": "പരിപാലകനെ ചേർക്കുക",
    "add_parent_org": "പാരന്റ് ഓർഗ് ചേർക്കുക",
    "add_parent_to_organisation": "ഓർഗനൈസേഷനിലേക്ക് മാതാപിതാക്കളെ ചേർക്കുക",
    "parent_org_id": "രക്ഷകർത്താവ് ഓർഗ് ഐഡി",
    "add_maintainer_to_organization": "ഓർഗനൈസേഷനായി പരിപാലിക്കുന്നയാൾ ചേർക്കുക",
    "maintainer_id": "പരിപാലിക്കുന്ന ഐഡി",
    "update_organisation_details": "ഓർഗനൈസേഷൻ വിശദാംശങ്ങൾ അപ്ഡേറ്റുചെയ്യുക",
    "update_your_organization_details": "നിങ്ങളുടെ ഓർഗനൈസേഷൻ വിശദാംശങ്ങൾ അപ്ഡേറ്റ് ചെയ്യുക",
    "default_email": "സ്ഥിരസ്ഥിതി ഇമെയിൽ",
    "picker_in_menu": "മെനുവിലെ പിക്കർ",
    "param_diagnostics": "പാരാം ഡയഗ്നോസ്റ്റിക്സ്",
    "show_logo": "ലോഗോ കാണിക്കുക",
    "show_oem_logo": "OEM ലോഗോ കാണിക്കുക",
    "organisation_alias": "ഓർഗനൈസേഷൻ അലിയാസ്",
    "update_organisation_alias": "ഓർഗനൈസേഷൻ അലിയാസ് അപ്ഡേറ്റ് ചെയ്യുക",
    "update_org_alias": "അപ്ഡേറ്റ് ഓർഗ് അപരനാമം",
    "update_your_organization_logo": "നിങ്ങളുടെ ഓർഗനൈസേഷൻ ലോഗോ അപ്ഡേറ്റ് ചെയ്യുക",
    "select_a_logo": "ഒരു ലോഗോ തിരഞ്ഞെടുക്കുക",
    "update_logo": "ലോഗോ അപ്ഡേറ്റുചെയ്യുക",
    "create_uam_user": "UAM ഉപയോക്താവിനെ സൃഷ്ടിക്കുക",
    "overview": "പൊതു അവലോകനം",
    "batches": "ബാത്സംസുകൾ",
    "track_batch": "ട്രാക്ക് ബാച്ച്",
    "batch_movement": "ബാച്ച് ചലനം",
    "production_planner": "പ്രൊഡക്ഷൻ പ്ലാനർ",
    "show_unshipped_only": "അൺ ഷിപ്പ് മാത്രം കാണിക്കുക",
    "view_logs": "ലോഗുകൾ കാണുക",
    "params": "പാരാമുകൾ",
    "add_filter": "ഫിൽട്ടർ ചേർക്കുക",
    "filters": "ഫിൽട്ടറുകൾ",
    "batch_id": "Buth_id",
    "show_details": "വിശദാംശങ്ങൾ കാണിക്കുക",
    "tracing_logs": "ട്രേസിംഗ് ലോഗുകൾ",
    "quantity": "അളവ്",
    "location_event_type": "ലൊക്കേഷൻ ഇവന്റ് തരം",
    "fetch_plans": "പദ്ധതികൾ ലഭ്യമാക്കുക",
    "sku": "SKU",
    "skus": "സ്റ്റോക്ക് സൂക്ഷിക്കൽ യൂണിറ്റുകൾ",
    "code": "നിയമാവലി",
    "add_production_plans": "ഉൽപാദന പദ്ധതികൾ ചേർക്കുക",
    "date": "തീയതി",
    "from_time": "കാലക്രമേണ",
    "to_time": "കാലാകാലങ്ങളിൽ",
    "activities": "പ്രവർത്തനങ്ങൾ",
    "task_name": "ടാസ്ക് പേര്",
    "task_details": "ടാസ്ക് വിശദാംശങ്ങൾ",
    "start_date": "ആരംഭിക്കുന്ന തീയതി",
    "end_date": "അവസാന തീയതി",
    "assignee": "നിയമനിക",
    "manager": "നിര്വാഹകന്",
    "activity": "പവര്ത്തനം",
    "activity_type": "പ്രവർത്തന തരം",
    "escalation_matrix": "എസ്കയൽ മാട്രിക്സ്",
    "maintenance_activity": "പരിപാലന പ്രവർത്തനം",
    "em_level": "ഇഎം ലെവൽ",
    "delay": "താമസിക്കുക",
    "user_group": "ഉപയോക്തൃ ഗ്രൂപ്പ്",
    "mins_5": "5 മിനിറ്റ്",
    "mins_10": "10 മിനിറ്റ്",
    "mins_15": "15 മിനിറ്റ്",
    "mins_30": "30 മിനിറ്റ്",
    "hour_1": "1 മണിക്കൂർ",
    "hour_6": "6 മണിക്കൂർ",
    "day_1": "1 ദിവസം",
    "day": "ദിവസം",
    "day_3": "3 ദിവസം",
    "insights": "സ്ഥിതിവിവരക്കണക്കുകൾ",
    "create_emailer_group": "ഇമെയിൽ സെർവർ ഗ്രൂപ്പ് സൃഷ്ടിക്കുക",
    "notification_type": "അറിയിപ്പ് തരം",
    "email": "ഇമെയിൽ",
    "sms": "എസ്എംഎസ്",
    "create_machine_group": "മെഷീൻ ഗ്രൂപ്പ് സൃഷ്ടിക്കുക",
    "create_param_group": "പരാം ഗ്രൂപ്പ് സൃഷ്ടിക്കുക",
    "create_user_group": "ഉപയോക്തൃ ഗ്രൂപ്പ് സൃഷ്ടിക്കുക",
    "create_module": "മൊഡ്യൂൾ സൃഷ്ടിക്കുക",
    "busbar_temp_module": "ബസ്ബാർ ടെംപ് മൊഡ്യൂൾ",
    "power_quality_module": "പവർ ഗുണനിലവാര മൊഡ്യൂൾ",
    "esg_module": "Esg മൊഡ്യൂൾ",
    "edge_hub": "എഡ്ജ് ഹബ്",
    "tolerance": "സഹനശക്തി",
    "ambient_temp_input_type": "ആംബിയന്റ് ടെംപ് ഇൻപുട്ട് തരം",
    "ambient_temp": "ആംബിയന്റ് ടെംപ്",
    "workspace_ambient_temp": "വർക്ക്സ്പെയ്സ് ആംബിയന്റ് ടെംപ്",
    "device_ambient_temp": "ഉപകരണ അന്തരീക്ഷ ടെംപ്",
    "r_temperature": "R താപനില",
    "y_temperature": "Y താപനില",
    "b_temperature": "ബി താപനില",
    "n_temperature": "N താപനില",
    "rated_current": "റേറ്റുചെയ്ത കറന്റ്",
    "no_load_current": "ലോഡ് കറന്റ് ഇല്ല",
    "r_current": "R നിലവിലുള്ളത്",
    "y_current": "Y കറന്റ്",
    "b_current": "B കറന്റ്",
    "n_current": "N നിലവിലുള്ളത്",
    "delete_activity": "പ്രവർത്തനം ഇല്ലാതാക്കുക",
    "substation_voltage_kv": "സബ്സ്റ്റേഷൻ വോൾട്ടേജ് (കെ.വി)",
    "transformer_rating_mva": "ട്രാൻസ്ഫോർമർ റേറ്റിംഗ് (എംവിഎ)",
    "percentage_impedance_of_the_transformer": "ട്രാൻസ്ഫോർമറിന്റെ% ഇംപെഡൻസ്",
    "voltage_rv_y": "വോൾട്ടേജ് ആർവി (വൈ)",
    "voltage_yb_v": "വോൾട്ടേജ് yb (v)",
    "voltage_br_v": "വോൾട്ടേജ് Br (v)",
    "current_r_a": "നിലവിലെ R (എ)",
    "current_y_a": "നിലവിലെ y (എ)",
    "current_b_a": "നിലവിലെ ബി (എ)",
    "thd_i": "ഞാൻ",
    "thd_v": "Thd v",
    "thd_i1": "I1",
    "active_power_kw": "സജീവ പവർ (KW)",
    "apparent_power_kva": "വ്യക്തമായ പവർ (കെവിഎ)",
    "reactive_power_kvar": "റിയാക്ടീവ് പവർ (കെവാർ)",
    "active_energy_kwh": "സജീവ energy ർജ്ജം (kWH)",
    "apparent_energy_kvah": "വ്യക്തമായ എനർജി (കെവിഎച്ച്)",
    "pf": "പിഎഫ്",
    "text": "മൂലഗന്ഥം",
    "energy_source": "Energy ർജ്ജ സ്രോതസ്സ്",
    "scope": "ഉത്കണ്ഠ",
    "system_logs": "സിസ്റ്റം ലോഗുകൾ",
    "system_logbook": "സിസ്റ്റം ലോഗ് ബുക്ക്",
    "esg_data_log": "ESG ഡാറ്റ ലോഗ്",
    "add_esg_data": "ESG ഡാറ്റ ചേർക്കുക",
    "add_esg_data_log": "ESG ഡാറ്റ ലോഗ് ചേർക്കുക",
    "source_type": "ഉറവിട തരം",
    "unit_of_measurement": "അളവിലുള്ള യൂണിറ്റ്",
    "from_date_optional": "തീയതി മുതൽ (ഓപ്ഷണൽ)",
    "emission_factor": "എമിഷൻ ഫാക്ടർ (kgco2)",
    "create_dynamic_logger": "ഡൈനാമിക് ലോഗർ സൃഷ്ടിക്കുക",
    "dynamic_log": "ഡൈനാമിക് ലോഗ്",
    "primary_field_label": "പ്രാഥമിക ഫീൽഡ് ലേബൽ",
    "primary_field_type": "പ്രാഥമിക ഫീൽഡ് തരം",
    "primary": "ആദത്തേതായ",
    "additional_fields": "അധിക ഫീൽഡുകൾ",
    "field_label": "ഫീൽഡ് ലേബൽ",
    "field_type": "ഫീൽഡ് തരം",
    "choose_list": "ലിസ്റ്റ് തിരഞ്ഞെടുക്കുക",
    "list": "പട്ടിക",
    "create_capacity_logger": "ശേഷി ലോഗർ സൃഷ്ടിക്കുക",
    "capacity_log": "കപ്പാസിറ്റ് ലോഗ്",
    "create": "സൃഷ്ടിക്കുക",
    "create_production_logger": "പ്രൊഡക്ഷൻ ലോഗർ സൃഷ്ടിക്കുക",
    "production_log": "നിര്മ്മാണ ലോഗ്",
    "production_logger": "പ്രൊഡക്ഷൻ ലോഗർ",
    "input_type": "ഇൻപുട്ട് തരം",
    "input": "നിക്ഷേപതം",
    "input_name": "ഇൻപുട്ട് പേര്",
    "output_type": "P ട്ട്പുട്ട് തരം",
    "output": "ഉല്പ്പന്നം",
    "output_name": "Put ട്ട്പുട്ട് നാമം",
    "output_params": "Output ട്ട്പുട്ട് പാരാമുകൾ",
    "compute_production": "ഉൽപാദനം കണക്കുകൂട്ടുക",
    "compute_energy": "Energy ർജ്ജം",
    "energy_param_type": "Energy ർജ്ജ പാത്രം തരം",
    "energy_device": "Energy ർജ്ജ ഉപകരണം",
    "energy_param": "Energy ർജ്ജ പാപം",
    "production_operator": "ഉത്പാദന ഓപ്പറേറ്റർ",
    "production_correction_factor": "ഉൽപാദന തിരുത്തൽ ഘടകം",
    "output_operator": "Put ട്ട്പുട്ട് ഓപ്പറേറ്റർ",
    "output_correction_factor": "Output ട്ട്പുട്ട് തിരുത്തൽ ഘടകം",
    "input_operator": "ഇൻപുട്ട് ഓപ്പറേറ്റർ",
    "input_correction_factor": "Output ട്ട്പുട്ട് തിരുത്തൽ ഘടകം",
    "energy_unit": "Energy ർജ്ജ യൂണിറ്റ്",
    "production_unit": "ഉത്പാദന യൂണിറ്റ്",
    "production": "നിര്മ്മാണം",
    "create_data_logger": "ഡാറ്റ ലോഗർ സൃഷ്ടിക്കുക",
    "data_log": "ഡാറ്റ ലോഗ്",
    "add_param": "പരാം ചേർക്കുക",
    "param_id": "PARAM ID",
    "create_batch_production_logger": "ബാച്ച് പ്രൊഡക്ഷൻ ലോഗർ സൃഷ്ടിക്കുക",
    "batch_tracing_log": "ബാച്ച് ട്രേസിംഗ് ലോഗ്",
    "log_name": "രേഖപ്പെടുത്തുക പേര്",
    "location": "സ്ഥാപിക്കല്",
    "info": "വിവരം",
    "remarks": "പരാമർശങ്ങൾ",
    "code_will_be_available_to_log_by_default": "സ്ഥിരസ്ഥിതിയായി ലോഗിൻ ചെയ്യുന്നതിന് കോഡ് ലഭ്യമാകും",
    "fields": "ഫീൽഡുകൾ",
    "additional_info": "അധിക വിവരം",
    "action": "പവര്ത്തി",
    "report_type": "റിപ്പോർട്ട് തരം",
    "create_parameter_report": "പാരാമീറ്റർ റിപ്പോർട്ട് സൃഷ്ടിക്കുക",
    "data_processing": "ഡാറ്റ പ്രോസസ്സിംഗ്",
    "addition": "കൂട്ടല്",
    "singular": "ഏകവചനം",
    "report": "വിവരം ശേഖരിക്കല്",
    "energy_parameters_kwh": "Energy ർജ്ജ പാരാമീറ്ററുകൾ (kWH)",
    "apparent_energy_KVAh": "വ്യക്തമല്ലാത്ത er ർജ്ജ kvah",
    "maximum_demand_MD": "പരമാവധി ആവശ്യം (MD)",
    "excel_frequency": "Excel ആവൃത്തി",
    "multiplier": "ഗുണിതം",
    "single": "ഒറ്റയായ",
    "incomers": "വരുമാനം",
    "sections": "വിഭാഗങ്ങൾ",
    "energy_distribution_report": "Energy ർജ്ജ വിതരണ റിപ്പോർട്ട്",
    "root_machine": "റൂട്ട് യന്ത്രം",
    "tariff_on": "താരിഫ് ഓൺ",
    "add_root_common_machine": "റൂട്ട് & കോമൺ മെഷീൻ ചേർക്കുക",
    "common_machine": "സാധാരണക്കാരൻ",
    "energy_bill_distribution_report": "Energy ർജ്ജ ബിൽ വിതരണ റിപ്പോർട്ട്",
    "create_consolidated": "ഏകീകൃതമായി സൃഷ്ടിക്കുക",
    "choose_report": "റിപ്പോർട്ട് തിരഞ്ഞെടുക്കുക",
    "id": "ഐഡി",
    "timeframed_sec": "സമയം സജ്ജമാക്കി",
    "create_dynamic_excel_report": "ഡൈനാമിക് എക്സൽ റിപ്പോർട്ട് സൃഷ്ടിക്കുക",
    "update_dynamic_excel_report": "ഡൈനാമിക് എക്സൽ റിപ്പോർട്ട് അപ്ഡേറ്റുചെയ്യുക",
    "add_sheet": "ഷീറ്റ് ചേർക്കുക",
    "sheet_name": "ഷീറ്റിന്റെ പേര്",
    "timestamp": "ടൈംസ്റ്റാമ്പ്",
    "sheet": "താള്",
    "sheets": "ഷീറ്റുകൾ",
    "sheet_id": "ഷീറ്റ് ഐഡി",
    "delete_sheet": "ഷീറ്റ് ഇല്ലാതാക്കുക",
    "report_name": "പേര് റിപ്പോർട്ടുചെയ്യുക",
    "update_details": "വിശദാംശങ്ങൾ അപ്ഡേറ്റുചെയ്യുക",
    "unit": "ഘടകം",
    "parameters": "പാരാമീറ്ററുകൾ",
    "send_excel_file": "Excel ഫയൽ അയയ്ക്കുക",
    "update_excel_template_based_report": "Excel ടെംപ്ലേറ്റ് അടിസ്ഥാനമാക്കിയുള്ള റിപ്പോർട്ട് അപ്ഡേറ്റുചെയ്യുക",
    "create_excel_template_based_report": "Excel ടെംപ്ലേറ്റ് അടിസ്ഥാനമാക്കിയുള്ള റിപ്പോർട്ട് സൃഷ്ടിക്കുക",
    "upload_excel_template_xlsx": "Excel ടെംപ്ലേറ്റ് അപ്ലോഡുചെയ്യുക (xlsx)",
    "param_equation": "പാരാം സമവാക്യം",
    "param_equations": "പാരാമീറ്റർ സമവാക്യങ്ങൾ",
    "edit_sheets": "ഷീറ്റുകൾ എഡിറ്റുചെയ്യുക",
    "row_no": "വരി നോ",
    "column_no": "നിര നമ്പർ",
    "row_number": "വരി നമ്പർ",
    "column_number": "നിര നമ്പർ",
    "colummn_number": "നിര നമ്പർ",
    "no_sheets_added": "ഷീറ്റുകൾ ഒന്നും ചേർത്തിട്ടില്ല",
    "process_based_usage_sheet": "പ്രോസസ്സ് അടിസ്ഥാനമാക്കിയുള്ള ഉപയോഗ ഷീറ്റ്",
    "process": "പതേകനടപടികള്",
    "process_label": "പ്രോസസ് ലേബൽ",
    "add_tag_types": "ടാഗ് തരങ്ങൾ ചേർക്കുക",
    "add_process_to_list": "പട്ടികയിലേക്ക് പ്രോസസ്സ് ചേർക്കുക",
    "machine_tag_based_daily_data_sheet": "മെഷീൻ ടാഗ് അടിസ്ഥാനമാക്കിയുള്ള ദൈനംദിന ഡാറ്റ ഷീറ്റ്",
    "machine_label": "മെഷീൻ ലേബൽ",
    "add_machine_to_list": "ലിസ്റ്റുചെയ്യാൻ മെഷീൻ ചേർക്കുക",
    "add_tag_type_to_list": "പട്ടികയിലേക്ക് ടാഗ് തരം ചേർക്കുക",
    "shift_based_usage_sheet": "അടിസ്ഥാനമാക്കിയുള്ള ഉപയോഗ ഷീറ്റ് ഷിഫ്റ്റ് ചെയ്യുക",
    "status_for_process": "പതേകനടപടികള്",
    "status_for_machine": "യന്തം",
    "esg_summary_item": "സംഗഹം",
    "esg_details_item": "വിശദാംശങ്ങൾ",
    "esg_scope1_label": "സ്കോപ്പ് 1",
    "esg_scope2_label": "സ്കോപ്പ് 2",
    "esg_scope3_label": "സ്കോപ്പ് 3",
    "esg_scopes_list_label": "സ്കോപ്സ്",
    "transpose": "ഉത്തേജിപ്പിക്കുക",
    "process_based_timeframed_data_sheet": "പ്രോസസ്സ് അടിസ്ഥാനമാക്കിയുള്ള സമയപരിധി",
    "add_pocess_to_list": "പട്ടികയിലേക്ക് പ്രോസസ്സ് ചേർക്കുക",
    "tag_types": "ടാഗ് തരങ്ങൾ",
    " consolidated_shift_based_usage_data_sheet": "ഏകീകൃത ഷിഫ്റ്റ് അടിസ്ഥാനമാക്കിയുള്ള ഉപയോഗ ഡാറ്റ ഷീറ്റ്",
    "date_format": "തീയതി ഫോർമാറ്റ്",
    "include_process_total": "പ്രോസസ്സ് ആകെ ഉൾപ്പെടുത്തുക",
    "machine_group_based_usage_sheet": "മെഷീൻ ഗ്രൂപ്പ് അടിസ്ഥാനമാക്കിയുള്ള ഉപയോഗ ഷീറ്റ്",
    "machine_group_label": "മെഷീൻ ഗ്രൂപ്പ് ലേബൽ",
    "machine_group": "മെഷീൻ ഗ്രൂപ്പ്",
    "add_machine_group_to_list": "ലിസ്റ്റുചെയ്യാൻ മെഷീൻ ഗ്രൂപ്പ് ചേർക്കുക",
    "parameter_based_usage_data_sheet": "പാരാമീറ്റർ അടിസ്ഥാനമാക്കിയുള്ള ഉപയോഗ ഡാറ്റ ഷീറ്റ്",
    "show_month_start&end_data": "മാസം ആരംഭവും അവസാന ഡാറ്റയും കാണിക്കുക",
    "statistics": "സ്ഥിതിവിവരക്കണക്ക്",
    "add_param_to_list": "പട്ടികയിലേക്ക് പരം ചേർക്കുക",
    "add_timestamp_to_list": "പട്ടികയിലേക്ക് ടൈംസ്റ്റാമ്പ് ചേർക്കുക",
    "parameter_group_based_timeframed": "പാരാമീറ്റർ ഗ്രൂപ്പ് അടിസ്ഥാനമാക്കിയുള്ള സമയപരിധി",
    "param_group_label": "പരമ ഗ്രൂപ്പ് ലേബൽ",
    "param_group": "പരാം ഗ്രൂപ്പ്",
    "add_param_group_to_list": "പട്ടികയിലേക്ക് പരമ ഗ്രൂപ്പ് ചേർക്കുക",
    "dynamic_logger_based_data_sheet": "ഡൈനാമിക് ലോഗർ അടിസ്ഥാനമാക്കിയുള്ള ഡാറ്റ ഷീറ്റ്",
    "dynamic_log_label": "ഡൈനാമിക് ലോഗ് ലേബൽ",
    "parameter_equation_based_sheet": "പാരാമീറ്റർ സമവാക്യം അടിസ്ഥാനമാക്കിയുള്ള ഷീറ്റ്",
    "data_source": "ഡാറ്റ ഉറവിടം",
    "equation_label": "സമവാക്യ ലേബൽ",
    "add_equation_to_list": "പട്ടികയിലേക്ക് സമവാക്യം ചേർക്കുക",
    "absolute_values": "കേവല മൂല്യങ്ങൾ",
    "consumption_based_sheet": "ഉപഭോഗ അധിഷ്ഠിത ഷീറ്റ്",
    "parameter_name": "പാരാമീറ്റർ പേര്",
    "statistical_analysis_based_sheet": "സ്ഥിതിവിവര വിശകലനം അടിസ്ഥാനമാക്കിയുള്ള ഷീറ്റ്",
    "parameter_instantaneous_data_sheet": "പാരാമീറ്റർ തൽക്ഷണ ഡാറ്റ ഷീറ്റ്",
    "create_sheet_column": "ഷീറ്റ് കോളം സൃഷ്ടിക്കുക",
    "column_label": "നിര ലേബൽ",
    "machine_id": "യന്ത്ര ഐഡി",
    "create_machine_list": "മെഷീൻ പട്ടിക സൃഷ്ടിക്കുക",
    "create_param_list": "പാരാമീറ്റർ പട്ടിക സൃഷ്ടിക്കുക",
    "create_sheets": "ഷീറ്റുകൾ സൃഷ്ടിക്കുക",
    "create_shift": "ഷിഫ്റ്റ് സൃഷ്ടിക്കുക",
    "description_optional": "വിവരണം (ഓപ്ഷണൽ)",
    "create_sku": "സ്കൂ സൃഷ്ടിക്കുക",
    "create_location": "ലൊക്കേഷൻ സൃഷ്ടിക്കുക",
    "location_type": "ലൊക്കേഷൻ തരം",
    "from_location": "ലൊക്കേഷനിൽ നിന്ന്",
    "to_location": "ലൊക്കേഷനിലേക്ക്",
    "event_type": "ഇവന്റ് തരം",
    "create_location_event_type": "ലൊക്കേഷൻ ഇവന്റ് തരം സൃഷ്ടിക്കുക",
    "create_list": "പട്ടിക സൃഷ്ടിക്കുക",
    "create_param_equation": "പാരാം സമവാക്യം സൃഷ്ടിക്കുക",
    "positive_params": "പോസിറ്റീവ് പാരാമുകൾ",
    "params_to_be_substracted": "കുറയ്ക്കേണ്ട പാരാമുകൾ",
    "create_combo_param": "കോംബോ പാരം സൃഷ്ടിക്കുക",
    "combo_params": "സംയോജിത പാരാമീറ്ററുകൾ",
    "calculated_params": "കണക്കാക്കിയ പാരാമീറ്ററുകൾ",
    "inter_param_operator": "ഇന്റർ പാരം ഓപ്പറേറ്റർ",
    "correction_operator": "തിരുത്തൽ ഓപ്പറേറ്റർ",
    "correction_operand": "തിരുത്തൽ പ്രവർത്തനം",
    "create_calculated_param": "കണക്കാക്കിയ പാരം സൃഷ്ടിക്കുക",
    "calculated_param": "കണക്കാക്കിയ പാരം",
    "correction_factor": "തിരുത്തൽ ഘടകം",
    "param1_type": "PARAM 1 തരം",
    "param1": "Param 1",
    "param1_operator": "PARAM 1 ഓപ്പറേറ്റർ",
    "param1_operand": "പാരാം 1 ഓപ്പറാൻഡേ",
    "param2_type": "PARAM 2 തരം",
    "param2": "PARAM 2",
    "param2_operator": "പാരാം 2 ഓപ്പറേറ്റർ",
    "param2_operand": "പാരാം 2 ഓപ്പറാൻഡേ",
    "create_esg_scope_conversion": "ESG സ്കോപ്പ് പരിവർത്തനം സൃഷ്ടിക്കുക",
    "category": "ഇനം",
    "esg_scope_logging": "ESG സ്കോപ്പ് ലോഗിംഗ്",
    "get_from_param": "പാരാമിൽ നിന്ന് നേടുക",
    "choose_param": "പരാം തിരഞ്ഞെടുക്കുക",
    "create_target": "ലക്ഷ്യം സൃഷ്ടിക്കുക",
    "create_word": "പദം സൃഷ്ടിക്കുക",
    "words": "വാക്കുകൾ",
    "choose_OEM": "ഒഇഎം തിരഞ്ഞെടുക്കുക",
    "get_the_models": "മോഡലുകൾ നേടുക",
    "get_oem_devices": "ഒഇഎം ഉപകരണങ്ങൾ നേടുക",
    "oem_devices": "ഒഇഎം ഉപകരണങ്ങൾ",
    "model_number": "മോഡൽ നമ്പർ",
    "register": "പട്ടികയുണ്ടാക്കുക",
    "add_modbus_gateway": "മോഡ്ബസ് ഗേറ്റ്വേ ചേർക്കുക",
    " add_modbus_TCP_gateway": "മോഡ്ബസ് ടിസിപി ഗേറ്റ്വേ ചേർക്കുക",
    "IPAddress": "ഐപി വിലാസം",
    "port_number": "പോർട്ട് നമ്പർ",
    "HubID": "ഹബ് ഐഡി",
    "baud_rate": "ബോഡി നിരക്ക്",
    "parity": "പാരിറ്റി",
    "stop_bit": "കുടിക്കുക നിർത്തുക",
    "update_wifi": "വൈഫൈ അപ്ഡേറ്റുചെയ്യുക",
    "SSID": "എസ്സിഡി",
    "password": "പാസ്വേഡ്",
    "restart_enture": "പുനരാരംഭിക്കുക",
    "force_update_configs": "കോൺഫിഗറുകൾ അപ്ഡേറ്റ് ചെയ്യുക",
    "activity_name": "പ്രവർത്തന നാമം",
    "board": "പലക",
    "calender": "പഞ്ചാംഗം",
    "maintenance": "പരിപാലനം",
    "welcome": "സാഗതം",
    "logout": "ലോഗൗട്ട്",
    "help": "സഹായം",
    "accounts": "അക്കൗണ്ടുകൾ",
    "organisation": "സംഘടന",
    "pointer_color_optional": "പോയിന്റർ വർണ്ണം (ഓപ്ഷണൽ)",
    "user_id": "ഉപയോക്തൃ ഐഡി",
    "confirm_new_password": "പുതിയ പാസ്വേഡ് സ്ഥിരീകരിക്കുക",
    "service": "സേവനം",
    "send_grid": "അയച്ചിരിക്കുന്നതാര്",
    "default_enture_view": "സ്ഥിരസ്ഥിതി ഓർഡൂർ കാഴ്ച",
    "permissions": "അനുമതികൾ",
    "areas": "മേഖലകൾ",
    "remark": "അഭിപായപ്പെടുക",
    "days": "ദിവസങ്ങൾ",
    "yesterdays_aqi": "ഇന്നലത്തെ എക്കി",
    "aqi": "എകി",
    "choose_air_quality_device": "എയർ ക്വാളിറ്റി ഉപകരണം തിരഞ്ഞെടുക്കുക",
    "delete_this_device": "ഈ ഉപകരണം ഇല്ലാതാക്കുക",
    "air_quality_label": "എയർ ക്വാളിറ്റി ലേബൽ",
    "humidity": "ഈര്പ്പാവസ്ഥ",
    "temperature": "താപനില",
    "watcher": "നിരീക്ഷകന്",
    "area_reference": "ഏരിയ റഫറൻസ്",
    "write_data": "ഡാറ്റ എഴുതുക",
    "day_of_week": "ആഴ്ചയിലെ ദിവസം",
    "month": "മാസം",
    "diagnostics_notification_groups": "ഡയഗ്നോസ്റ്റിക്സ് അറിയിപ്പ് ഗ്രൂപ്പുകൾ",
    "hub_name": "ഹബ് പേര്",
    "hub_model": "ഹബ് മോഡൽ",
    "hub_type": "ഹബ് തരം",
    "hub": "ഹബ്",
    "update_your_MQTT_bridge_config": "നിങ്ങളുടെ MQTT ബ്രിഡ്ജ് കോൺഫിഗറേഷൻ അപ്ഡേറ്റുചെയ്യുക",
    "MQTT_bridge_mode": "MQTT ബ്രിഡ്ജ് മോഡ്",
    "broker_address": "ബ്രോക്കർ വിലാസം",
    "update_your_OPC_config": "നിങ്ങളുടെ OPC കോൺഫിഗറേഷൻ അപ്ഡേറ്റുചെയ്യുക",
    "OPC_client_mode": "ഒപിസി ക്ലയന്റ് മോഡ്",
    "OPC_server_URL": "ഒപിസി സെർവർ URL",
    "user_auth": "ഉപയോക്താവ് ഓത്ത്",
    "mode": "മാതിരി",
    "security_policy": "സുരക്ഷാ നയം",
    "Update_your_Edge_Hub_restart_Schedule": "നിങ്ങളുടെ എഡ്ജ് ഹബ് പുനരാരംഭിക്കുക ഷെഡ്യൂൾ അപ്ഡേറ്റുചെയ്യുക",
    "auto_restart": "യാന്ത്രിക പുനരാരംഭിക്കുക",
    "time_24Hrs": "സമയം (24 മണിക്കൂർ)",
    "modbus_ping_latency": "മോഡ്ബസ് പിംഗ് ലേറ്റൻസി",
    "modbus_unhealthy_wait": "മോഡ്ബസ് അനാരോഗ്യകരമായ കാത്തിരിപ്പ്",
    "edit_written_log_book": "എഴുതിയ ലോഗ് പുസ്തകം എഡിറ്റുചെയ്യുക",
    "field_cant_change": "ഈ ഫീൽഡിന് മാറ്റാൻ കഴിയില്ല",
    "re_write": "പകര്ത്തുക",
    "write_in_logbook": "ലോഗ്ബുക്കിൽ എഴുതുക",
    "write": "എഴുതുക",
    "download_template": "ടെംപ്ലേറ്റ് ഡൗൺലോഡുചെയ്യുക",
    "write_in_logbook_csv": "ലോഗ് ബുക്കിൽ എഴുതുക .സിഎസ്വി",
    "upload_file": "ഫയൽ അപ്ലോഡുചെയ്യുക",
    "batch": "ഓടിക്കല്ല്",
    "move_batch": "ബാച്ച് നീക്കുക",
    "on": "മേല്",
    "off": "ദൂരെ",
    "fast_mode": "വേഗത്തിൽ മോഡ്",
    "batch_shipping_details": "ബാച്ച് ഷിപ്പിംഗ് വിശദാംശങ്ങൾ",
    "vehicle_no": "വാഹന നമ്പർ",
    "net_weight": "മൊത്തം ഭാരം",
    "actual_weight": "യഥാർത്ഥ ഭാരം",
    "vendor": "വില്പ്പനക്കാരന്",
    "loaded_by": "ലോഡുചെയ്തു",
    "verified_by": "പരിശോധിച്ചു",
    "document_no": "പ്രമാണം ഇല്ല",
    "multiple_machine_param": "ഒന്നിലധികം മെഷീനും പാരാമുകളും",
    "map_paramid_paramequation_to_row": "മാപ്പ് PRAM ID അല്ലെങ്കിൽ PARAM സമവാക്യങ്ങൾ",
    "add_current_date_to_sheet": "ഷീറ്റിലേക്ക് നിലവിലെ തീയതി ചേർക്കുക",
    "create_parameter_from_template": "ടെംപ്ലേറ്റിൽ നിന്ന് പാരാമീറ്റർ സൃഷ്ടിക്കുക",
    "selected_parameters_count": "തിരഞ്ഞെടുത്ത പാരാമീറ്ററുകൾ കണക്കാക്കുന്നു",
    "restricted_user_access": "നിയന്ത്രിത ഉപയോക്തൃ ആക്സസ്",
    "select_all_parameters": "എല്ലാ പാരാമീറ്ററുകളും തിരഞ്ഞെടുക്കുക",
    "edit_angular_gauge_widget": "കോണീയ ഗേജ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_angular_gauge_widget": "കോണാകൃതി ഗേജ് വിജറ്റ് ചേർക്കുക",
    "widget_label": "വിഡ്ജെറ്റ് ലേബൽ",
    "widget_width": "വിജറ്റ് വീതി",
    "widget_height": "വിജറ്റ് ഉയരം",
    "param_label": "പാരാം ലേബൽ",
    "choose_parameter": "പാരാമീറ്റർ തിരഞ്ഞെടുക്കുക",
    "processing": "നടപടി",
    "edit_banner_widget": "ബാനർ വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_banner_widget": "ബാനർ വിജറ്റ് ചേർക്കുക",
    "edit_bartd_widget": "ബാർ ടിഡി വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_bar_widget": "ബാർ വിജറ്റ് ചേർക്കുക",
    "yaxis_label": "Y - ആക്സിസ് ലേബൽ",
    "xaxis_label": "X - ആക്സിസ് ലേബൽ",
    "y1axis_label": "Y1 - ആക്സിസ് ലേബൽ",
    "y2axis_label": "Y2 - ആക്സിസ് ലേബൽ",
    "edit_dual_axis_bar_trend_widget": "ഡയുവൽ ആക്സിസ് ബാർ ട്രെൻഡ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_dual_axis_bar_trend_widget": "ഡ്യുവൽ ആക്സിസ് ബാർ ട്രെൻഡ് വിജറ്റ് ചേർക്കുക",
    "y1_graph_type": "Y1 ആക്സിസ് ഗ്രാഫ് തരം",
    "y2_graph_type": "Y2 ആക്സിസ് ഗ്രാഫ് തരം",
    "idealValue": "അനുയോജ്യമായ",
    "idealLabel": "അനുയോജ്യമായത്",
    "add_parameters": "പാരാമീറ്ററുകൾ ചേർക്കുക",
    "add_equation": "സമവാക്യം ചേർക്കുക",
    "add_params": "പാരാമുകൾ ചേർക്കുക",
    "enter_of_machines": "മെഷീനുകളുടെ നമ്പർ നൽകുക",
    "edit_bar_trend_td_widget": "ബാർ ട്രെൻഡ് ടിഡി വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_bar_trend_td_widget": "ബാർ ട്രെൻഡ് ടിഡി വിജറ്റ് ചേർക്കുക",
    "graph_type": "ഗ്രാഫ് തരം",
    "aggregation": "അഗ്രഗേഷൻ",
    "default_graph_type": "സ്ഥിരസ്ഥിതി ഗ്രാഫ് തരം",
    "bar_mode": "ബാർ മോഡ്",
    "show_ideal_line": "അനുയോജ്യമായ വരി കാണിക്കുക",
    "equations": "സമവാഹനങ്ങൾ",
    "edit_bitwise_word_widget": "ബിറ്റ് ടൈം വേഡ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_bitwise_word_widget": "ബിറ്റ്വൈസ് വേഡ് വിജറ്റ് ചേർക്കുക",
    "show_bit_status": "ബിറ്റ് സ്റ്റാറ്റസ് കാണിക്കുക",
    "edit_bullet_gauge_widget": "ബുള്ളറ്റ് ഗേജ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_bullet_gauge_widget": "ബുള്ളറ്റ് ഗേജ് വിജറ്റ് ചേർക്കുക",
    "reference_value_for": "റഫറൻസ് മൂല്യം",
    "gauge_type": "ഗേജ് തരം",
    "indicator_color": "ഇൻഡിക്കേറ്റർ നിറം",
    "busbar_temperature_graph_widget": "ബസ് ബാർ താപനില ഗ്രാഫ് വിജറ്റ്",
    "busbar_module": "ബസ് ബാർ മൊഡ്യൂൾ",
    "edit_consumption_gauge_widget": "ഉപഭോഗം എഡിറ്റുചെയ്യുക ഗേജ് വിജറ്റ്",
    "add_consumption_gauge_widget": "ഉപഭോഗ ഗേജ് വിജറ്റ് ചേർക്കുക",
    "linearGauge": "ലീനിയർ ഗേജ്",
    "show_target": "ടാർഗെറ്റ് കാണിക്കുക",
    "healthy_on_below_target": "ടാർഗെറ്റിന് താഴെയുള്ള ആരോഗ്യകരമായ",
    "edit_consumption_pie_widget": "ഉപഭോഗം എഡിറ്റുചെയ്യുക പൈ വിജറ്റ്",
    "add_consumption_pie_widget": "ഉപഭോഗം ചേർക്കുക പൈ വിജറ്റ്",
    "add_color_break_point": "കളർ ബ്രേക്ക് പോയിന്റ് ചേർക്കുക",
    "custom_color": "ഇഷ്ടാനുസൃത നിറം",
    "color": "നിറം",
    "edit_heat_map_widget": "ഹീറ്റ് മാപ്പ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_heat_map__widget": "ഹീറ്റ് മാപ്പ് വിജറ്റ് ചേർക്കുക",
    "choose_equation": "സമവാക്യം തിരഞ്ഞെടുക്കുക",
    "label_star": "ലേബൽ *",
    "color_star": "നിറം *",
    "operator1_star": "ഓപ്പറേറ്റർ 1 *",
    "value1_star": "മൂല്യം 1 *",
    "operator2_star": "ഓപ്പറേറ്റർ 2 *",
    "actions": "പ്രവർത്തനങ്ങൾ",
    "condition": "വ്യവസ്ഥകൾ",
    "status_color": "നില നിറം",
    "text_view": "ടെക്സ്റ്റ് കാഴ്ച",
    "edit_process_Status_widget": "പ്രോസസ് സ്റ്റാറ്റസ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_process_Status_widget": "പ്രോസസ് സ്റ്റാറ്റസ് വിജറ്റ് ചേർക്കുക",
    "edit_machine_Status_widget": "മെഷീൻ സ്റ്റാറ്റസ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_machine_Status_widget": "മെഷീൻ സ്റ്റാറ്റസ് വിജറ്റ് ചേർക്കുക",
    "choose_process": "പ്രക്രിയകൾ തിരഞ്ഞെടുക്കുക",
    "choose_machine": "മെഷീനുകൾ തിരഞ്ഞെടുക്കുക",
    "table_header_label": "പട്ടിക ഹെഡർ ലേബൽ",
    "edit_table_TD_widget": "പട്ടിക ടിഡി വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_table_TD_widget": "ടേബിൾ ടിഡി വിജറ്റ് ചേർക്കുക",
    "configure_table": "പട്ടിക കോൺഫിഗർ ചെയ്യുക",
    "edit_target_based_trend_widget": "ടാർഗെറ്റ് അടിസ്ഥാനമാക്കിയുള്ള ട്രെൻഡ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_target_based_trend_widget": "ടാർഗെറ്റ് അടിസ്ഥാനമാക്കിയുള്ള ട്രെൻഡ് വിജറ്റ് ചേർക്കുക",
    "target": "ലക്ഷം",
    "target_line_label": "ടാർഗെറ്റ് ലൈൻ ലേബൽ",
    "edit_usage_score_card": "ഉപയോഗ സ്കോർകാർഡ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_usage_score_card": "ഉപയോഗ സ്കോർകാർഡ് വിജറ്റ് ചേർക്കുക",
    "compare_to": "ഇതുമായി താരതമ്യം ചെയ്യുക",
    "assign_color": "നിറം നൽകുക",
    "enter": "പവേശിക്കുക",
    "gauge_to_dashboard": "ഡാഷ്ബോർഡിലേക്കുള്ള ഗേജ്",
    "widget_types": "വിജറ്റ് തരങ്ങൾ",
    "from_value": "മൂല്യത്തിൽ നിന്ന്",
    "to_value": "വിലമതിക്കാൻ",
    "machine_status": "യന്ത്ര പദവി",
    "deviceA_type": "ഉപകരണം ഒരു തരം",
    "templates": "ടെംപ്ലേറ്റുകൾ",
    "add_triggers_to_parameter": "പാരാമീറ്ററിലേക്ക് ട്രിഗറുകൾ ചേർക്കുക",
    "triggers": "ട്രിഗറുകൾ",
    "show_filter": "ഫിൽറ്റർ കാണിക്കുക",
    "view_id": "ഐഡി കാണുക",
    "move_up": "മുകളിലേക്ക് നീങ്ങുക",
    "move_down": "താഴേക്ക് നീങ്ങുക",
    "multiplier_types": "മൾട്ടിപ്രിയൻ_തിപ്സ്",
    "energy_report": "Energy ർജ്ജ റിപ്പോർട്ട്",
    "add_machine_param": "മെഷീൻ പാരാം ചേർക്കുക",
    "aliasing": "അപരനാത്മകം",
    "no_zero_read": "പൂജ്യമായി വായിച്ചിട്ടില്ല",
    "remove_negative_entropy": "നെഗറ്റീവ് എൻട്രോപ്പി നീക്കംചെയ്യുക",
    "calculate_consumption": "ഉപഭോഗം കണക്കാക്കുക",
    "disabled": "അശക്തനായ",
    "limiter_value": "ലിമിറ്റർ മൂല്യം",
    "machine_scroll_view_page": "മെഷീൻ സ്ക്രോൾ വ്യൂ പേജ്",
    "create_mannual_parameter": "മാൻഗ് പാരാമീറ്റർ സൃഷ്ടിക്കുക",
    "restrict_user_access": "ഉപയോക്തൃ ആക്സസ്സ് നിയന്ത്രിക്കുക",
    "add_device_parameter": "ഉപകരണ പാരാമീറ്റർ ചേർക്കുക",
    "entropy": "എൻട്രോപ്പി",
    "idle_latency": "നിഷ്ക്രിയ ലേവേഷൻ",
    "add_OPC_parameter": "ഒപിസി പാരാമീറ്റർ ചേർക്കുക",
    "node_id": "നോഡ് ഐഡി",
    "byte_format": "ബൈറ്റ് ഫോർമാറ്റ്",
    "param_scroll_view_page": "പാപം സ്ക്രോൾ വ്യൂ പേജ്",
    "timeframed_sec_report": "സമയം സജ്ജമാക്കിയ സമയം",
    "prod_unit": "പ്രൊഡഡ് യൂണിറ്റ്",
    "SEC_unit": "സെക്കൻഡ് യൂണിറ്റ്",
    "energy_parameters": "Energy ർജ്ജ പാരാമീറ്ററുകൾ",
    "prod_param_type": "പ്രോഡി പരം തരം",
    "production_parameters": "ഉൽപാദന പാരാമീറ്ററുകൾ",
    "no_of_intervals": "ഇടവേളകളുടെ എണ്ണം",
    "granular_data": "ഗ്രാനുലാർ ഡാറ്റ",
    "csv": "സിഎസ്വി",
    "delete_rule": "ഭരണം ഇല്ലാതാക്കുക",
    "enable_disable_all_parameters": "എല്ലാ പാരാമീറ്ററുകളും പ്രാപ്തമാക്കുക / അപ്രാപ്തമാക്കുക",
    "disable": "പ്രവർത്തനരഹിതമാക്കുക",
    "enable": "കഴിവുണ്ടാക്കുക",
    "edit_computed_parameter": "കമ്പ്യൂട്ട് പാരാമീറ്റർ എഡിറ്റുചെയ്യുക",
    "quantization": "ക്വാണ്ടൈസേഷൻ",
    "command": "ആജ്ഞാപിക്കുക",
    "register_size": "വലുപ്പം രജിസ്റ്റർ ചെയ്യുക",
    "consumer_ID": "ഉപഭോക്തൃ ഐഡി",
    "site_id": "സൈറ്റ് ഐഡി",
    "get_data": "ഡാറ്റ നേടുക",
    "update_param_type": "പാരാം തരം അപ്ഡേറ്റ് ചെയ്യുക",
    "write_in_data_logbook": "ഡാറ്റ ലോഗ്ബുക്കിൽ എഴുതുക",
    "daily_data": "ദൈനംദിന ഡാറ്റ",
    "log_entry": "ലോഗ് എൻട്രി",
    "enable_Scroll_mode": "സ്ക്രോൾ മോഡ് പ്രാപ്തമാക്കുക",
    "multiple_areas": "ഒന്നിലധികം പ്രദേശങ്ങൾ",
    "time_of_day": "ദിവസത്തിന്റെ സമയം",
    "capacity_logger": "ശേഷി ലോഗർ",
    "provider": "ദാതാവ്",
    "total_sites_received_bills_MTD": "മൊത്തം സൈറ്റുകൾക്ക് ബില്ലുകൾ ലഭിച്ചു",
    "sites_yet_to_receive_bills": "ബില്ലുകൾ സ്വീകരിക്കാനായി ഇതുവരെ സൈറ്റുകൾ",
    "total_sites": "ആകെ സൈറ്റുകൾ",
    "sites_received_bills_MTD": "% സൈറ്റുകൾക്ക് ബില്ലുകൾ ലഭിച്ചു",
    "total": "മൊത്തമായ",
    "total_bills_received_mtd": "മൊത്തം ബില്ലുകൾക്ക് എംടിഡി ലഭിച്ചു",
    "arrear_1": "കുഞ്ഞ് 1",
    "arrear_2": "അരീയർ 2",
    "no_reading": "വായനയില്ല",
    "bills_received_in_5_days": "കഴിഞ്ഞ 5 ദിവസത്തിനുള്ളിൽ ബില്ലുകൾ ലഭിച്ചു",
    "site_wise_status": "ശരി നില ഒഴികെയുള്ള സൈറ്റ് തിരിച്ചുള്ള നില",
    "site_wise_final_summary": "സൈറ്റ് തിരിച്ചുള്ള അന്തിമ സംഗ്രഹം",
    "source_link": "ഉറവിട ലിങ്ക്",
    "data_type": "ഡാറ്റ തരം",
    "select_an_image_to_upload": "അപ്ലോഡുചെയ്യാൻ ഒരു ചിത്രം തിരഞ്ഞെടുക്കുക",
    "user_group_Id": "ഉപയോക്തൃ ഗ്രൂപ്പ് ഐഡി",
    "user_group_name": "ഉപയോക്തൃ ഗ്രൂപ്പ് പേര്",
    "delay_in_mins": "കാലതാമസം (മിനിറ്റ്)",
    "delete_task": "ചുമതല ഇല്ലാതാക്കുക",
    "watchers": "നിരീക്ഷകർ",
    "comments": "അഭിപ്രായങ്ങൾ",
    "asset_under_maintenance": "അറ്റകുറ്റപ്പണിയിൽ അസറ്റ്",
    "start_time": "ആരംഭ സമയം",
    "end_time": "അവസാന സമയം",
    "download_latest_usage_table": "ഏറ്റവും പുതിയ ഉപയോഗ പട്ടിക ഡൗൺലോഡുചെയ്യുക",
    "tag": "ടാഗ്",
    "add_target_data": "ടാർഗെറ്റ് ഡാറ്റ ചേർക്കുക",
    "edit_target": "ടാർഗെറ്റ് എഡിറ്റുചെയ്യുക",
    "delete_target": "ലക്ഷ്യം ഇല്ലാതാക്കുക",
    "prop": "പഴം",
    "excel_template_based_report": "Excel ടെംപ്ലേറ്റ് അടിസ്ഥാനമാക്കിയുള്ള റിപ്പോർട്ട്",
    "configured_hub": "കോൺഫിഗർ ചെയ്ത ഹബ്",
    "configured_relay": "കോൺഫിഗർ ചെയ്ത റിലേ",
    "desired_state": "ആവശ്യമുള്ള അവസ്ഥ",
    "configured_notification_group": "അറിയിപ്പ് ഗ്രൂപ്പ് ക്രമീകരിച്ചു",
    "email_ids": "ഇമെയിൽ ഐഡികൾ",
    "logs": "ലോഗുകൾ",
    "unit_id": "യൂണിറ്റ് ഐഡി",
    "download_report": "ഡൗൺലോഡ് റിപ്പോർട്ട്",
    "frequency": "ആവര്ത്തനം",
    "ip": "ഐപി",
    "edit_wifi": "വൈഫൈ എഡിറ്റുചെയ്യുക",
    "details": "വിശദാംശങ്ങൾ",
    "connected_to": "കണക്റ്റുചെയ്തു",
    "software_version": "സോഫ്റ്റ്വെയർ പതിപ്പ്",
    "mac_address": "മാക് വിലാസം",
    "opc_client_enabled": "ഒപിസി ക്ലയന്റ് പ്രവർത്തനക്ഷമമാക്കി",
    "MQTT_bridge_enabled": "MQTT പാലം പ്രവർത്തനക്ഷമമാക്കി",
    "hub_specification": "ഹബ് സ്പെസിഫിക്കേഷൻ",
    "hub_details": "ഹബ് വിശദാംശങ്ങൾ",
    "restart_schedule": "ഷെഡ്യൂൾ പുനരാരംഭിക്കുക",
    "restart_device": "ഉപകരണം പുനരാരംഭിക്കുക",
    "set_up_restart_time": "പുനരാരംഭിക്കുക സമയം സജ്ജമാക്കുക",
    "connectivity_logs": "കണക്റ്റിവിറ്റി ലോഗുകൾ",
    "internal_cache_size": "ആന്തരിക കാഷെ വലുപ്പം",
    "default": "കുറ്റം",
    "configs": "കോൺജക്റ്റുകൾ",
    "processed": "പ്രോസസ്സ് ചെയ്തു",
    "space_available": "സ്ഥലം ലഭ്യമാണ്",
    "kb": "കെ.ബി.",
    "mb": "MB",
    "last_ping": "അവസാന പിംഗ്",
    "toggle": "ടോഗിൾ ചെയ്യുക",
    "configured_creds": "ക്രമീകരിച്ച ക്രെഡിറ്റുകൾ",
    "psk": "Psk",
    "set_value": "മൂല്യം സജ്ജമാക്കുക",
    "time_current_time": "സമയം (സൂചിപ്പിച്ചിട്ടില്ലെങ്കിൽ നിലവിലെ സമയം എടുക്കും)",
    "Set": "സജ്ജീകൃതരംഗം",
    "health": "ആരോഗം",
    "port": "തുറമുഖം",
    "mqtt_params": "MQTT-PAMAMS",
    "delete_device": "ഉപകരണം ഇല്ലാതാക്കുക",
    "view_details": "വിശദാംശങ്ങൾ കാണുക",
    "parameter_id": "പാരാമീറ്റർ ഐഡി",
    "email_address": "ഇമെയിൽ വിലാസം",
    "attached_triggers": "അറ്റാച്ചുചെയ്ത ട്രിഗറുകൾ",
    "parameter_details": "പാരാമീറ്റർ വിശദാംശങ്ങൾ",
    "enable_write": "എഴുതാൻ പ്രാപ്തമാക്കുക",
    "disable_write": "എഴുതുക",
    "offset": "ഓഫ്സെറ്റ്",
    "no_of_parameters": "പാരാമീറ്ററുകളുടെ എണ്ണം",
    "creation_time": "സൃഷ്ടിക്കൽ സമയം",
    "not_configured": "ക്രമീകരിച്ചിട്ടില്ല",
    "add_rule": "റൂൾ ചേർക്കുക",
    "sl_no": "Sl ഇല്ല",
    "targeted_by_value": "മൂല്യം ടാർഗെറ്റുചെയ്തു",
    "configured_action": "കോൺഫിഗർ ചെയ്ത പ്രവർത്തനം",
    "add_action": "പ്രവർത്തനം ചേർക്കുക",
    "action_id": "ആക്ഷൻ ഐഡി",
    "delete_user": "ഉപയോക്താവിനെ ഇല്ലാതാക്കുക",
    "bit": "കടിവാളം",
    "low": "താണനിലയില്",
    "high": "ഉയര്ന്ന",
    "esg_scope_conversion": "ESG സ്കോപ്പ് പരിവർത്തനം",
    "batch_production_logs": "ബാച്ച് പ്രൊഡക്ഷൻ ലോഗുകൾ",
    "esg_data_logs": "ESG ഡാറ്റ ലോഗുകൾ",
    "remove_from_dashboard": "ഡാഷ്ബോർഡിൽ നിന്ന് നീക്കംചെയ്യുക",
    "data_logs": "ഡാറ്റ ലോഗുകൾ",
    "select_device": "ഉപകരണം തിരഞ്ഞെടുക്കുക",
    "fetch_connectivity_logs": "കണക്റ്റിവിറ്റി ലോഗുകൾ നേടുക",
    "hub_internet_connectivity_logs": "ഹബ് ഇന്റർനെറ്റ് കണക്റ്റിവിറ്റി ലോഗുകൾ",
    "modbus_gateways": "മോഡ്ബസ് ഗേറ്റ്വേകൾ",
    "device_switched_on": "ഉപകരണം സ്വിച്ചുചെയ്തു",
    "device_switched_off": "ഉപകരണം സ്വിച്ച് ഓഫ് ചെയ്യുന്നു",
    "parameter_report": "പാരാമീറ്റർ റിപ്പോർട്ട്",
    "timeframed_specific_energy_consumption_report": "വ്യക്തമായ energy ർജ്ജ ഉപഭോഗ റിപ്പോർട്ട്",
    "ebill_distribution_report": "ഇ-ബിൽ വിതരണ റിപ്പോർട്ട്",
    "root_user_management": "റൂട്ട് യൂസർ മാനേജുമെന്റ്",
    "user_acces_management": "ഉപയോക്തൃ ആക്സസ് മാനേജുമെന്റ്",
    "inference": "അനുമാനം",
    "turn_off": "ഓഫ് ചെയ്യുക",
    "turn_on": "ഓൺ ചെയ്യുക",
    "diagram_pallette": "ഡയഗ്രം പാലറ്റ്",
    "add_component": "ഘടകം ചേർക്കുക",
    "components": "ഘടകങ്ങൾ",
    "add_text": "വാചകം ചേർക്കുക",
    "add_realtime_data": "തത്സമയ ഡാറ്റ ചേർക്കുക",
    "shapes": "രൂപങ്ങൾ",
    "lines": "ലൈനുകൾ",
    "text_realtime_component": "വാചകം തത്സമയ ഘടകം",
    "shapes_pallette": "പാലറ്റ് രൂപപ്പെടുത്തുക",
    "lines_pallette": "ലൈൻസ് പാലറ്റ്",
    "click_choose": "ക്ലിക്കുചെയ്ത് SLD- ലേക്ക് ചേർക്കുന്നതിന് ആകൃതികൾ തിരഞ്ഞെടുക്കുക",
    "choose_line": "SLD- ലേക്ക് ചേർക്കുന്നതിന് ലൈൻ തിരഞ്ഞെടുക്കുക",
    "choose_image": "ചിത്രം തിരഞ്ഞെടുക്കുക",
    "upload_image_to_add": "SLD- ലേക്ക് ചേർക്കുന്നതിന് ചിത്രം അപ്ലോഡുചെയ്യുക",
    "add_realtime_textdata": "SLD- ലേക്ക് തത്സമയ അല്ലെങ്കിൽ ടെക്സ്റ്റ് ഡാറ്റ ചേർക്കുക",
    "update_user": "ഉപയോക്താവിനെ അപ്ഡേറ്റുചെയ്യുക",
    "add_watcher": "നിരീക്ഷകനെ ചേർക്കുക",
    "premise_area": "പ്രീസ് ഏരിയ",
    "create_computed_param": "കമ്പ്യൂട്ട് പാരാം സൃഷ്ടിക്കുക",
    "create_derived_param": "ഡെറിവേർ ചെയ്ത പാരം സൃഷ്ടിക്കുക",
    "proceed_to_ship": "കപ്പലിലേക്ക് പോകുക",
    "esg_data_collection": "ESG ഡാറ്റ ശേഖരണം",
    "esg_scope_details": "ESG സ്കോപ്പ് വിശദാംശങ്ങൾ",
    "select_custom_date_range": "ഇഷ്ടാനുസൃത തീയതി ശ്രേണി തിരഞ്ഞെടുക്കുക",
    "days_365": "365 ദിവസം",
    "days_180": "180 ദിവസം",
    "days_30": "30 ദിവസം",
    "create_user_for_SSO": "SSO നായി ഉപയോക്താവിനെ സൃഷ്ടിക്കുക",
    "add_widget": "വിജറ്റ് ചേർക്കുക",
    "add_machine_status_rules": "മെഷീൻ നില നിയമങ്ങൾ ചേർക്കുക",
    "add_trigger": "ട്രിഗർ ചേർക്കുക",
    "add_logged_parameter": "ലോഗിൻ ചെയ്ത പാരാമീറ്റർ ചേർക്കുക",
    "edit_logged_parameter": "ലോഗിൻ ചെയ്ത പാരാമീറ്റർ എഡിറ്റുചെയ്യുക",
    "create_mannual_param": "മാൻഗ് പാരം സൃഷ്ടിക്കുക",
    "add_opc_param": "ഒപിസി പാരം ചേർക്കുക",
    "create_write_block": "റൈറ്റ് ബ്ലോക്ക് സൃഷ്ടിക്കുക",
    "all_parameters": "എല്ലാ പാരാമീറ്ററുകളും",
    "edit_device": "ഉപകരണം എഡിറ്റുചെയ്യുക",
    "edit_modbus_device": "മോഡ്ബസ് ഉപകരണം എഡിറ്റുചെയ്യുക",
    "edit_mqtt_device": "MQTT ഉപകരണം എഡിറ്റുചെയ്യുക",
    "limit_entropy": "എൻട്രോപ്പി പരിമിതപ്പെടുത്തുക",
    "emails": "ഇമെയിലുകൾ",
    "power_factor": "പവർഫാക്ടർ (പിഎഫ്)",
    "tarrif_rate": "താരിഫ് നിരക്ക്",
    "apparent_power": "വ്യക്തമായ ശക്തി",
    "reactive_power": "റിയാക്ടീവ് പവർ",
    "active_power": "സജീവമായ പവർ",
    "energy_usage_view_page": "Energy ർജ്ജ ഉപയോഗ വ്യൂ പേജ്",
    "switch_organisation": "ഓർഗനൈസേഷൻ സ്വിച്ച് ചെയ്യുക",
    "wireless_creds": "വയർലെസ് ക്രെഡിറ്റുകൾ",
    "latest": "ഏറ്റവും പുതിയ",
    "value_is": "മൂല്യം",
    "please_take_necessary_action": "ആവശ്യമായ പ്രവർത്തനം സ്വീകരിക്കുക",
    "edit_role": "പങ്ക് എഡിറ്റുചെയ്യുക",
    "delete_role": "പങ്ക് ഇല്ലാതാക്കുക",
    "today": "ഇന്നേദിവസം",
    "days_4": "4 ദിവസം",
    "due": "കുടിശ്ശിക",
    "move_to_done": "ചെയ്യാൻ പോവുക",
    "power_system_fault": "പവർ സിസ്റ്റം തെറ്റ് എണ്ണം (അവസാന ആഴ്ച)",
    "alarms": "അലാറങ്ങൾ",
    "electricity_consumption": "വൈദ്യുതി ഉപഭോഗം (ദിവസം വരെ മാസം)",
    "average_demand": "ശരാശരി ആവശ്യം",
    "water_use_intensity": "ജല ഉപയോഗ തീവ്രത (WUI)",
    "average_water_cost": "ശരാശരി വാട്ടർ ചെലവ് (പ്രതിമാസ)",
    "water_use_efficiency": "ജല ഉപയോഗക്ഷമത (WUW)",
    "batch_logs": "ബാച്ച് ലോഗുകൾ",
    "edit_log": "ലോഗ് എഡിറ്റുചെയ്യുക",
    "delete_log": "ലോഗ് ഇല്ലാതാക്കുക",
    "dates": "തീയതി",
    "log_details": "ലോഗ് വിശദാംശങ്ങൾ",
    "view": "ദര്ശനം",
    "scope1_emission": "സ്കോപ്പ് 1 എമിഷൻ",
    "scope2_emission": "സ്കോപ്പ് 2 എമിഷൻ",
    "lmtd": "LMTD",
    "company_data": "കമ്പനി ഡാറ്റ",
    "historian": "ചിതകാരി",
    "from_optional": "(ഓപ്ഷണൽ) മുതൽ",
    "to_optional": "ടു (ഓപ്ഷണൽ)",
    "trends": "ട്രെൻഡുകൾ",
    "generate_trend": "പ്രവണത സൃഷ്ടിക്കുക",
    "by": "മൂലം",
    "check_logs": "ലോഗുകൾ പരിശോധിക്കുക",
    "workspaces": "വർക്ക്സ്പെയ്സുകൾ",
    "types_of_nodes_connected": "ബന്ധിപ്പിച്ച നോഡുകളുടെ തരങ്ങൾ",
    "types_of_modbus_device_connected": "കണക്റ്റുചെയ്തിരിക്കുന്ന മോഡ്ബസ് ഉപകരണങ്ങളുടെ തരങ്ങൾ",
    "no_of_registers": "രജിസ്റ്ററുകളുടെ എണ്ണം",
    "write_register": "എഴുതുക രജിസ്റ്റർ ചെയ്യുക",
    "setting_view": "കാഴ്ച ക്രമീകരിക്കുന്നു",
    "sign_up": "സൈൻ അപ്പ് ചെയ്യുക",
    "reset": "പുന .സജ്ജമാക്കുക",
    "mobile_number": "മൊബൈൽ നമ്പർ",
    "nebeskie_labs": "നെബെസ്കി ലാബുകൾ",
    "please_register_and_send_otp": "നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത ഇമെയിൽ ഐഡി നൽകി OTP അയയ്ക്കുക ക്ലിക്കുചെയ്യുക.",
    "reset_password": "പാസ്വേഡ് പുന reset സജ്ജമാക്കുക",
    "back_to_login": "ലോഗിൻ ചെയ്യാൻ മടങ്ങുക",
    "otp": "OTP",
    "send_otp": "OTP അയയ്ക്കുക",
    "org_alias": "Org അപരനാമം",
    "please_stand_by": "ദയവായി നിൽക്കൂ",
    "total_usage_this_month": "ഈ മാസം ആകെ ഉപയോഗം",
    "total_usage_last_month": "കഴിഞ്ഞ മാസം ആകെ ഉപയോഗം",
    "generate_report_manually": "റിപ്പോർട്ട് സ്വമേധയാ സൃഷ്ടിക്കുക",
    "energy_distribution_detailed_report": "Energy ർജ്ജ വിതരണ വിശദമായ റിപ്പോർട്ട്",
    "the_report_is_processing": "റിപ്പോർട്ട് പ്രോസസ്സ് ചെയ്യുന്നു",
    "enter_active_energy_manually": "സജീവ energy ർജ്ജം സ്വമേധയാ നൽകുക",
    "include_others": "മറ്റുള്ളവരെ ഉൾപ്പെടുത്തുക",
    "bill_amount": "ബിൽ തുക",
    "yes_delete_it": "അതെ, അത് ഇല്ലാതാക്കുക",
    "create_write_param": "എഴുത്ത് ഭാഗം സൃഷ്ടിക്കുക",
    "report_list": "റിപ്പോർട്ട് പട്ടിക",
    "plant_id": "പ്ലാന്റ് ഐഡി",
    "plant_name": "നടുകളുടെ പേര്",
    "plant_address": "നടുക",
    "add_selected": "തിരഞ്ഞെടുത്തത് ചേർക്കുക",
    "clear_selection": "തിരഞ്ഞെടുത്തത്",
    "real_time_value": "തത്സമയ മൂല്യം",
    "default_status": "സ്ഥിരസ്ഥിതി നില",
    "default_label": "സ്ഥിരസ്ഥിതി ലേബൽ",
    "default_color_optional": "സ്ഥിരസ്ഥിതി നിറം ഓപ്ഷണൽ",
    "rule_based_status": "റൂൾ അടിസ്ഥാനമാക്കിയുള്ള നില",
    "show_label_only": "ലേബൽ മാത്രം കാണിക്കുക",
    "show_unit": "യൂണിറ്റ് കാണിക്കുക",
    "status_rules": "നില നിയമങ്ങൾ",
    "configure": "ക്രമീകരിക്കുക",
    "monthly_usage": "പ്രതിമാസ ഉപയോഗം",
    "meter_consumption_kwh": "മീറ്റർ ഉപഭോഗം (kWH)",
    "total_unit": "ആകെ യൂണിറ്റ്",
    "region": "പദേശം",
    "average_energy_consumed": "ശരാശരി energy ർജ്ജം ഉപയോഗിക്കുന്നു",
    "average_production": "ശരാശരി ഉത്പാദനം",
    "average_sec": "ശരാശരി സെക്കൻഡ്",
    "total_production": "മൊത്തം ഉത്പാദനം",
    "total_energy_consumed": "ഉപഭോഗം കഴിച്ച മൊത്തം energy ർജ്ജം",
    "max_sec": "പരമാവധി സെന്റ്",
    "device_1": "ഉപകരണം 1",
    "device_parameter_1": "ഉപകരണ പാരാമീറ്റർ 1",
    "device_2": "ഉപകരണം 2",
    "device_parameter_2": "ഉപകരണ പാരാമീറ്റർ 2",
    "create_param": "പരാം സൃഷ്ടിക്കുക",
    "update_tags": "ടാഗുകൾ അപ്ഡേറ്റുചെയ്യുക",
    "remove_from_process": "പ്രക്രിയയിൽ നിന്ന് നീക്കംചെയ്യുക",
    "machine_parameters": "മെഷീൻ പാരാമീറ്ററുകൾ",
    "energy": "ഊര്ജം",
    "power": "ശക്തി",
    "remove_from_featured": "ഫീച്ചർ ചെയ്തതിൽ നിന്ന് നീക്കംചെയ്യുക",
    "other_parameters": "മറ്റ് പാരാമീറ്ററുകൾ",
    "add_to_featured": "ഫീച്ചർ ചെയ്തു",
    "logged_parameters": "ലോഗിൻ ചെയ്ത പാരാമീറ്ററുകൾ",
    "tags": "ടാഗുകൾ",
    "machine_status_rules": "മെഷീൻ നില നിയമങ്ങൾ",
    "connected_communication_devices": "കണക്റ്റുചെയ്ത ആശയവിനിമയ ഉപകരണങ്ങൾ",
    "mqtt_devices": "MqTT ഉപകരണങ്ങൾ",
    "modbus_devices": "മോഡ്ബസ് ഉപകരണങ്ങൾ",
    "total_no_of_params": "ആകെ പാരാമുകളുടെ എണ്ണം",
    "edge_devices": "എഡ്ജ് ഉപകരണങ്ങൾ",
    "machines_configured": "മെഷീനുകൾ കോൺഫിഗർ ചെയ്തു",
    "reports_configured": "ക്രമീകരിച്ച റിപ്പോർട്ടുകൾ",
    "views_configured": "കാഴ്ചകൾ ക്രമീകരിച്ചു",
    "send_email_notification": "ഇമെയിൽ അറിയിപ്പ് അയയ്ക്കുക",
    "enture_send_email_notification": "തിരഞ്ഞെടുത്ത ഉപയോക്തൃ ഗ്രൂപ്പിലേക്ക് ഇമെയിൽ അറിയിപ്പ് അയയ്ക്കുക",
    "agree_and_send": "സമ്മതിക്കുക & അയയ്ക്കുക",
    "ebill": "എബില്ലിന്",
    "monthly_trend": "പ്രതിമാസ പ്രവണത",
    "param_view": "പാരാം കാഴ്ച",
    "calories": "കലോറി",
    "param_count_view": "PARAM എണ്ണൽ കാഴ്ച",
    "created": "സൃഷ്ടിച്ചു",
    "api_data_count_view": "API ഡാറ്റ എണ്ണം കാഴ്ച",
    "diesel_emissions": "ഡീസൽ ഉദ്വമനം",
    "eb_emissions": "ഇബ് ഉദ്വമനം",
    "emissions_kg_co2": "എമിഷൻ kg-co2",
    "kpis": "കെപിസികൾ",
    "jan_23": "ജനുവരി -23",
    "feb_23": "ഫെബ്രുവരി -23",
    "mar_23": "മാർച്ച് -23",
    "apr_23": "ഏപ്രിൽ -2",
    "may_23": "മെയ് -2",
    "jun_23": "ജുൻ -23",
    "lpg_emissions": "എൽപിജി ഉദ്വമനം",
    "petrol_emissions": "പെട്രോൾ ഉദ്വമനം",
    "bullet_gauge": "ബുള്ളറ്റ് ഗേജ്",
    "equipment": "സജ്ജീകരണം",
    "enter_no_of_machines": "മെഷീനുകളുടെ നമ്പർ നൽകുക",
    "shift": "തവണ",
    "upload": "അപ്ലോഡുചെയ്യുക",
    "image_upload": "ഇമേജ് അപ്ലോഡ്",
    "derived_parameter": "ഉത്പാദനത്തിൽ പാരാമീറ്റർ",
    "value_type": "Puroun_type",
    "enable_entropy": "എൻട്രോപ്പി പ്രാപ്തമാക്കുക",
    "remove_decimal": "ദശാംശ നീക്കംചെയ്യുക",
    "limiter_entropy": "ലിമിറ്റർ എൻട്രോപ്പി",
    "show_sku_id": "സ്കു ഐഡി കാണിക്കുക",
    "production_start_date": "പ്രൊഡക്ഷൻ ആരംഭ തീയതി (ഓപ്ഷണൽ)",
    "production_start_time": "ഉൽപാദന ആരംഭ സമയം (ഓപ്ഷണൽ)",
    "time": "കാലം",
    "temperature_c": "താപനില (സി)",
    "set_temperature_c": "സെറ്റ് താപനില (സി)",
    "no_of_people": "ആളുകളുടെയും",
    "co2_level": "CO2 ലെവൽ",
    "aqi_optional": "AQI ഓപ്ഷണൽ",
    "delete_sheets": "ഷീറ്റുകൾ ഇല്ലാതാക്കുക",
    "root_login": "റൂട്ട് ലോഗിൻ",
    "uam_login": "Uam ലോഗിൻ",
    "login": "ലോഗിൻ",
    "add_oem_logo": "OEM ലോഗോ ചേർക്കുക",
    "devices": "ഉപകരണങ്ങൾ",
    "derived_data_units": "ഉരുത്തിരിഞ്ഞ / ഡാറ്റ യൂണിറ്റുകൾ",
    "roles": "റോളുകൾ",
    "engineering": "എഞ്ചിനീയറിംഗ്",
    "control_panel": "നിയന്ത്രണ പാനൽ",
    "views": "കാഴ്ചകൾ",
    "reports_analysis": "റിപ്പോർട്ടുകളും വിശകലനവും",
    "actions_triggers": "പ്രവർത്തനങ്ങളും ട്രിഗറുകളും",
    "modules": "മൊഡ്യൂളുകൾ",
    "processes": "പ്രോസസ്സുകൾ",
    "air_quality": "വിമാന നിലവാരം",
    "manufacturing": "നിർമ്മാണം",
    "esg": "പതികാരം",
    "task_managment": "ടാസ്ക് മാനേജുമെന്റ്",
    "sustainability": "സുസ്ഥിരത",
    "groups": "ഗ്രൂപ്പുകൾ",
    "log_book": "ലോഗ് ബുക്ക്",
    "monitor": "നിരന്തരം നിരീക്ഷിക്കുക",
    "enture_settings": "ക്രമീകരണങ്ങൾ രക്ഷിക്കുക",
    "configuration": "കോൺഫിഗറേഷൻ",
    "bar": "കന്വി",
    "line": "നിര",
    "s7_device": "S7 ഉപകരണം",
    "rack": "തോട്ടെ",
    "slot": "സ്ലോട്ട്",
    "db_number": "Db നമ്പർ",
    "byte_offset": "ബൈറ്റ് ഓഫ്സെറ്റ്",
    "bit_offset": "ബിറ്റ് ഓഫ്സെറ്റ്",
    "data_length": "ഡാറ്റ നീളം",
    "val_type": "മൂല്യ തരം",
    "create_s7_device": "S7 ഉപകരണം ചേർക്കുക",
    "scope_label": "സ്കോപ്സ്",
    "client_safety": "ക്ലയൻറ് സുരക്ഷ",
    "edit": "തിരുത്തുക",
    "delete": "ഇല്ലാതാക്കുക",
    "choose_param_to_monitor": "നിരീക്ഷിക്കാൻ പരം തിരഞ്ഞെടുക്കുക",
    "rules": "നിയമങ്ങൾ",
    "add_to_row": "വരിയിലേക്ക് ചേർക്കുക",
    "add_usage": "ഉപയോഗം ചേർക്കുക",
    "notifications": "അറിയിപ്പുകൾ",
    "organisation_details": "ഓർഗനൈസേഷൻ വിശദാംശങ്ങൾ",
    "profile": "ആകൃതി",
    "user_access": "ഉപയോക്തൃ ആക്സസ്",
    "admin": "അഡ്മിൻ",
    "shifts": "ഷിഫ്റ്റുകൾ",
    "add_pie_TD_widget": "പൈ ടിഡി വിജറ്റ് ചേർക്കുക",
    "edit_pie_TD_widget": "പൈ ടിഡി വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "legend": "ഇതിഹാസം",
    "pie": "ഇറച്ചിയട",
    "edit_bar_trend_widget": "ബാർ ട്രെൻഡ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_bar_trend_widget": "ബാർ ട്രെൻഡ് വിജറ്റ് ചേർക്കുക",
    "edit_horizantal_bar_trend_widget": "തിരശ്ചീന ബാർ ട്രെൻഡ് വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_horizantal_bar_trend_widget": "തിരശ്ചീന ബാർ ട്രെൻഡ് വിജറ്റ് ചേർക്കുക",
    "too_long": "വളരെക്കാലം",
    "trigger_logs": "പ്രവർത്തനങ്ങളെ ട്രിഗർ ചെയ്യുക",
    "create_trigger": "ട്രിഗർ സൃഷ്ടിക്കുക",
    "right_click_to_configure": "ക്രമീകരിക്കാൻ വലത് ക്ലിക്കുചെയ്യുക",
    "width": "വീതി",
    "height": "പൊക്കം",
    "hide_value": "മൂല്യം മറയ്ക്കുക",
    "edit_machine": "മെഷീൻ എഡിറ്റുചെയ്യുക",
    "wait_time": "കാത്തിരിക്കൂ",
    "production_date": "നിര്മ്മാണ തീയതി",
    "connectivity_status": "കണക്റ്റിവിറ്റി നില",
    "production_plans": "ഉൽപാദന പദ്ധതികൾ",
    "production_pending": "നിര്മ്മാണം ശേഷിക്കുന്നു",
    "pending_shipment": "കയറ്റുമതി തീർപ്പാക്കിയിട്ടില്ല",
    "trace_logs": "ട്രേസ് ലോഗുകൾ",
    "movement_events": "ചലന ഇവന്റുകൾ",
    "kgco2": "kgco2",
    "entropy_threshold": "എൻട്രോപ്പി പരിധി",
    "threshold_value": "ത്രെഷോൾഡ് മൂല്യം",
    "pending_dispatch": "തീർപ്പുകൽപ്പിക്കാത്ത അയയ്ക്കുന്നു",
    "dispatched": "അയച്ചു",
    "dispatch_details": "വിശദാംശങ്ങൾ അയയ്ക്കുക",
    "dispatch": "അയയ്ക്കുക",
    "raw_materials": "അസംസ്കൃത വസ്തുക്കൾ",
    "pour_qty": "അളവ് ഒഴിക്കുക",
    "summary": "സംഗഹം",
    "optional": "ഇഷ്ടാനുസൃതമായ",
    "param_mqtt_map": "PARAM MQTT മാപ്പ്",
    "machine_status_mqtt_map": "മെഷീൻ സ്റ്റാറ്റസ് MQTT മാപ്പ്",
    "edit_trigger_count_table_widget": "ട്രിഗർ എണ്ണം ടേബിൾ വിജറ്റ് എഡിറ്റുചെയ്യുക",
    "add_trigger_count_table_widget": "ട്രിഗർ കൗണ്ട് പട്ടിക വിജറ്റ് ചേർക്കുക",
    "count": "എണ്ണുക",
    "real_time_consumption": "തത്സമയ ഉപഭോഗം",
    "search_view": "തിരയൽ കാഴ്ച",
    "multiple_entries": "ഒന്നിലധികം എൻട്രികൾ",
    "combined_parameter": "സംയോജിത പാരാമീറ്റർ",
    "background_color": "പശ്ചാത്തല നിറം",
    "resize": "വലുപ്പം മാറ്റുക",
    "remove": "മാറ്റുക",
    "select_views": "കാഴ്ചകൾ തിരഞ്ഞെടുക്കുക",
    "difference": "ഭിന്നത",
    "sum": "മൊത്തം",
    "set_param_type_for_machine": "മെഷീനായി പാത്രം തരം സജ്ജമാക്കുക",
    "y1axis": "Y1 അക്ഷം",
    "y2axis": "Y2 അക്ഷം",
    "temperature_parameters": "താപനില പാരാമീറ്ററുകൾ",
    "area_name": "ഏരിയ നാമം",
    "maintenance_due_on": "അറ്റകുറ്റപ്പണി",
    "days_left": "ദിവസങ്ങൾ ശേഷിക്കുന്നു",
    "enture_insights": "സംരംഭ സ്ഥിതിവിവരക്കണക്കുകൾ",
    "reports": "റിപ്പോർട്ടുകൾ",
    "till_date_pie_chart": "തീയതി വരെ പൈ ചാർട്ട്",
    "till_date_bar_chart": "തീയതി ബാർ ചാർട്ട് വരെ",
    "tod_billing_widget": "ടോഡ് ബില്ലിംഗ് വിജറ്റ്",
    "bar_trend_widget": "ബാർ ട്രെൻഡ് വിജറ്റ്",
    "target_based_bar_trend_widget": "ടാർഗെറ്റ് അടിസ്ഥാനമാക്കിയുള്ള ബാർ ട്രെൻഡ് വിജറ്റ്",
    "gauge_widget": "ഗേജ് വിജറ്റ്",
    "consumption_pie_widget": "ഉപഭോഗം പൈ വിജറ്റ്",
    "consumption_gauge_widget": "ഉപഭോഗ ഗേജ് വിജറ്റ്",
    "angular_gauge_widget": "കോണീയ ഗേജ് വിജറ്റ്",
    "table_widget": "പട്ടിക വിജറ്റ്",
    "banner_widget": "ബാനർ വിജറ്റ്",
    "param_equation_banner_widget": "പാത്രം സമക്ക ബാനർ വിജറ്റ്",
    "shift_based_usage_table": "അടിസ്ഥാനമാക്കിയുള്ള ഉപയോഗ പട്ടിക ഷിഫ്റ്റ് ചെയ്യുക",
    "bitwise_word_widget": "ബിറ്റ്വൈസ് വേഡ് വിജറ്റ്",
    "dynamic_log_widget": "ഡൈനാമിക് ലോഗ് വിജറ്റ്",
    "machine_status_timeline_widget": "മെഷീൻ നില ടൈംലൈൻ വിജറ്റ്",
    "process_or_machine_status_widget": "പ്രോസസ്സ് / മെഷീൻ സ്റ്റാറ്റസ് വിജറ്റ്",
    "parameter_status_based_usage_status": "പരമതാട്സ്ബാസെഡ്വിഡ്ജെറ്റ്",
    "parameter_insights_widget": "പാരാമീറ്റർ സ്ഥിതിവിവരക്കണക്കുകൾ വിജറ്റ്",
    "heat_map": "ചൂട് മാപ്പ്",
    "usage_score_card_widget": "ഉപയോഗ സ്കോർ കാർഡ് വിജറ്റ്",
    "busbar_live_monitoring": "ബസ്ബാർ ലൈവ് മോണിറ്ററിംഗ്",
    "bullet_or_angular_guage_widget": "ബുള്ളറ്റ് / കോണാകൃതി ഗേറ്റ് വിജറ്റ്",
    "dual_axis_bar_trend_widget": "ഡ്യുവൽ ആക്സിസ് ബാർ ട്രെൻഡ് വിജറ്റ്",
    "trigger_count_table_widget": "ട്രിഗർ കൗണ്ട് ടേബിൾ വിജറ്റ്",
    "bar_trend_td_widget": "ബാർ ട്രെൻഡ് ടിഡി വിജറ്റ്",
    "horizantal_bar_trend_widget": "തിരശ്ചീന ബാർ ട്രെൻഡ് വിജറ്റ്",
    "choose_from_templates": "തിരഞ്ഞെടുക്കുക from templates",
    "shift_timeframed_trends": "ഷിഫ്റ്റ് അടിസ്ഥാനമാക്കിയുള്ള ടൈംഫ്രെയിംഡ് ട്രെൻഡുകൾ",
    "candlestick_trends": "മെഴുകുതിരി ട്രെൻഡുകൾ",
    "free_ram": "Free RAM",
    "item": "Item",
    "item_code": "Item Code",
    "department": "Department",
    "departments": "Departments",
    "issue_vouchers": "Issue Vouchers",
    "issue_voucher": "Issue Voucher",
    "to_be_approved": "To Be Approved",
    "to_be_issued": "To Be Issued",
    "intended": "Intended",
    "approved": "Approved",
    "received": "Received",
    "receiver": "Receiver",
    "material": "material",
    "material_group": "material group",
    "material_desc": "Material Description",
    "uom": "Unit Of Measurement",
    "create_item_code": "Create Item Code",
    "create_department": "Create Department",
    "users": "Users",
    "items": "Items",
    "users_in_department": "Users in Department",
    "authorisers_in_department": "Authorisers in Department",
    "issuers_in_department": "Issuers in Department",
    "item_issuer_list": "Item Issuer List",
    "add_item_issuer": "Add Item Issuer",
    "store": "Store",
    "create_issue_voucher": "Create Issue Voucher",
    "voucher_requests": "Voucher Requests",
    "intended_qty": "Intended Qty",
    "issued_qty": "Issued Qty",
    "issue_items": "Issue Items",
    "voucher_receipt": "Voucher Receipt",
    "add_stock": "Add Stock",
    "attached_targets": "Attached Targets",
    "attach_target": "Attach Target",
    "add_target_to_parameter": "Add Target to Parameter",
    "empty": "Empty",
    "add_day_wise_target": "Add Day wise Target",
    "day_wise_targets": "Day Wise Targets",
    "fetch_targets": "Fetch Targets",

    "day_wise_machine_status_widget": "ദിവസാനുസൃത മെഷീൻ നില വിയജറ്റ്",
    "add_day_wise_machine_status_widget": "ദിവസാനുസൃത മെഷീൻ നില വിയജറ്റ് ചേർക്കുക",
    "edit_day_wise_machine_status_widget": "ദിവസാനുസൃത മെഷീൻ നില വിയജറ്റ് എഡിറ്റ് ചെയ്യുക",
    "add_machines": "മെഷീനുകൾ ചേർക്കുക",
    "added_machines": "ചേർത്ത മെഷീനുകൾ",
    "consumption_start_time":"Consumption Start time",




}
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroun2ColorCode}" flat outlined class="pa-3">    
        <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="machineForm" v-model="valid">
                <v-row>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.machine_status"
                            outlined
                            :items="machineStatusOptions"
                            :label="$store.getters.getTextMap().machine_status"
                            dense
                            filled
                            :rules="[rules.required]"
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col> 
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.tag_type"
                            outlined
                            :items="tagTypeOptions"
                            :label="$store.getters.getTextMap().tag_type"
                            dense
                            filled
                            :rules="[rules.required]"
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col> 
                </v-row>
                <v-row>
                    <v-col>
                        <v-select  
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().operator"
                            outlined
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="[rules.required]"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            dense
                            outlined
                            :color="$store.getters.getColorPalette().inputtext2Color"
                            :loading="loading"
                            v-model="form.operand"
                            :counter="8"
                            :rules="[rules.num]"
                            :label="$store.getters.getTextMap().operand"
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            v-if="!loading"
                            v-model="form.wait_time"
                            :items="waitTimes"
                            :label="$store.getters.getTextMap().wait_time+' - '+$store.getters.getTextMap().minute"
                            :color="$store.getters.getColorPalette().inputtext2Color"
                            outlined dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="right">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-btn class="mr-1" small v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                        <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <MachineStatusRulesList :success="success" :machine="machine"/>
    </v-container>
</template>
<script>
import MachineStatusRulesList from '@/components/lists/MachineStatusRulesList'
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'AddMachineStatusRule',
    props:['machine'],
    components:{
        InfoAlert,
        MachineStatusRulesList
    },
    data(){
        return {
            showDismissibleAlert:false,
            success:false,
            loading:false,
            info:'',
            valid:false,
            api:"addMachineStatusRule",
            form:{},
            machineStatusOptions:[
                {label:"Running",value:'running'},
                // {label:"On",value:'on'},
                {label:"Idling",value:'idling'},
                {label:"Loading/unloading",value:'loading'},
                // {label:"Un-Loading",value:'unloading'},
                {label:"Fault",value:'fault'},
            ],
            operators:[
                {label:'Greater than', value:'>'},
                {label:'Modulus Greater than', value:'|>'},
                {label:'Lesser than', value:'<'},
                {label:'Modulus Lesser than', value:'|<'},
                {label:'Equals to', value:'=='},
                {label:'Not Equal to', value:'!='},
                {label:'Modulus Equals to', value:'|=='},
                {label:'Greater than equal to', value:'>='},
                {label:'Modulus Greater than equal to', value:'|>='},
                {label:'Lesser than equal to', value:'<='},
                {label:'Modulus Lesser than equal to', value:'|<='},
                {label:'Bit High', value:'bitHigh'},
                {label:'Bit Low', value:'bitLow'},
            ],
            waitTimes:[
            {label:'0 minutes', value:0},
            {label:'1 minute', value:1},
            {label:'2 minutes', value:2},
            {label:'3 minutes', value:3},
            {label:'4 minutes', value:4},
            // {label:'5 minutes', value:5},
            ],
            rules:FormRules.rules,
        }
    },
    computed:{
        tagTypeOptions(){
            let op=[]
            if(this.$store.state.tagTypes && this.$store.state.tagTypes.length>0 ){
                for(let i of this.$store.state.tagTypes){
                    if(i && i.name){
                        op.push({value:i.tag_type, label: i.name })
                    }else{
                        op.push({value:i.tag_type, label: i.tag_type })
                    }
                }
            }
            return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.machineForm.validate()
            if(this.valid){
                this.loading = true;
                this.form['machine_id']=this.machine.machine_id
                axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        this.loading = false;
                        this.success=true
                        this.onReset()
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.onReset()
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        },
        onReset(){
            this.$refs.machineForm.reset()
            this.$emit('close')
        }
    },
}
</script>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-row no-gutters class="pb-1">
            <v-col>
                <h3>{{ graph_label ? graph_label : "TOD Billing Graph" }}</h3>
            </v-col>
            <v-col>
                <v-btn :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="downloadInvoice">{{
                        $store.getters.getTextMap().generate_report }}</v-btn>
            </v-col>
            <v-col align="right">
                <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
                    <v-btn small>{{ $store.getters.getTextMap().bar }}</v-btn>
                    <v-btn small>{{ $store.getters.getTextMap().line }}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-divider />
        <div ref="vis"></div>
        <div v-if="!(dbData && dbData.length > 0)" :style="[{ 'display': 'flex', 'justify-content': 'center' }]">
            <v-icon class="mr-3">mdi-database-remove-outline</v-icon>
            <h3>No data to show</h3>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import Plotly from 'plotly.js'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)

export default {
    name: 'TODBillingBarGraph',
    props: ['machines', 'from_date', 'to_date', 'background_color', 'graph_label', 'group_name'],
    components: {
        InfoAlert
    },
    data() {
        return {
            loading: false,
            valid: false,
            showDismissibleAlert: false,
            info: '',
            dbData: [],
            graphData: {},
            graphType: 0,
            payload: null,
            layout: {
                barmode: 'group',
                font: {
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                plot_bgcolor: this.background_color ? this.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor: this.background_color ? this.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                showlegend: true,
                autosize: true,
                margin: {
                    t: 10,
                    pad: 0
                },
                xaxis: {
                    title: "Date",
                    tickangle: -12,
                    zeroline: true,
                    showgrid: true,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                yaxis: {
                    zeroline: true,
                    title: "Amount",
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted() {
        this.getData()

        
    },
    methods: {
        // downloadInvoice(){
        //     let pdf = new jsPDF();
        //     //pdf.table(20, 30, this.currentData);
        //     let body={}
        //     let total=0
        //     for(let d of this.dbData){
        //         if(!(d.label in body)){
        //             body[d.label]=[d.label,d.tariff_amount]
        //         }else{
        //             body[d.label][1]=body[d.label][1]+d.tariff_amount
        //         }
        //         total+=d.tariff_amount

        //     }

        //     pdf.autoTable({
        //         head: [['Name','Tariff(INR)']],
        //         body: Object.values(body).concat([['Total',total]])
        //         })
        //     pdf.save('out.pdf');

        // },

        downloadInvoice() {
            let date = moment().format('YYYY-MM-DD');
            let content = `
            <div style="font-size: 16px; background-color:white;width:100%">
            <div style="padding:20px;">
            <p style="text-align:end; opacity:0.3">Powered By <b> Enture</b> </p>
            </div>
            <div style="background-image: linear-gradient(to top, #09203f 0%, #537895 100%);width:100%;padding:40px">
            <h1 style="text-align: left;  font-size:45px; color:#FFD700; text-shadow:2px 2px 4px black; border-bottom:4px solid #537895 ">Energy Consumption Report</h1>

            <div>
                <p style='padding-top:10px; padding-bottom:10px; color:white;letter-spacing:3px; text-shadow:2px 2px 4px black; '>From Date: ${this.from_date}</p>
                <p style='padding-top:10px; padding-bottom:10px; color:white;letter-spacing:3px; text-shadow:2px 2px 4px black; '>To Date: ${this.to_date}</p>
            </div>
            <p style='padding-top:10px;  color:white;letter-spacing:3px; text-shadow:2px 2px 4px black;font-size:12px; text-align:end; '> Genearated on : ${date}</p>
            </div>

            <table   width="100%" style="border-collapse: collapse; margin-top: 20px; margin-bottom:20px">
            <thead>
            <tr style="background-color: teal;">
            <th style="padding: 15px; text-align: center;color:white; text-shadow:2px 2px 4px black;">Name</th>
            <th style="padding: 15px; text-align: center;color:white; text-shadow:2px 2px 4px black;">Consumption of Units</th>
            <th style="padding: 15px; text-align: center;color:white; text-shadow:2px 2px 4px black;">Tariff (INR)</th>
            </tr>
            </thead>
            <tbody>`;

            let body = {};
            let total = 0;


            this.dbData = [...this.dbData];

            let totalUnitsConsumed = 0;
            for (let d of this.dbData) {
                // If d.units_consumed is null or undefined, treat it as 0
                const unitsConsumed = d.units_consumed ?? 0;

                // Check if label exists in the body
                if (!(d.label in body)) {
                    if(this.group_name){
                        body[d.label] = [ this.group_name + " " + d.label, d.tariff_amount, unitsConsumed];
                    }else{
                        body[d.label] = [ d.label, d.tariff_amount, unitsConsumed];
                    }
                } else {
                    // Add tariff amount and update units consumed
                    body[d.label][1] += d.tariff_amount;
                    body[d.label][2] += unitsConsumed;
                }


                
                // Sum the units consumed
                totalUnitsConsumed += unitsConsumed;
                total += d.tariff_amount;
            }


            Object.values(body).forEach((row, index) => {
                // console.log(row,"row");

                content += `
          <tr style="${index % 2 == 0 ? 'background-color:#E3F2FD;' : 'background-color:#B3E5FC'}">
            <td style="padding: 15px; text-align:center">${row[0]}</td>
            <td style="padding: 15px; text-align: center;">${row[2] == null ? 0 : row[2].toFixed(2)} Kwh</td>
            <td style="padding: 15px; text-align: center;">${row[1].toFixed(2)}</td>
          </tr>`;
            });

            content += `
        <tr style="font-weight: bold; background-color:#ECEFF1">
          <td style="padding: 15px; text-align: center;">Total</td>
          <td style="padding: 15px; text-align: center;">${totalUnitsConsumed.toFixed(2)} Kwh</td>
          <td style="padding: 15px; text-align: center;">${total.toFixed(2)}</td>
        </tr>
      </tbody>
      </table>

      <div>
        <p style="text-align:left;padding-top:10px;font-size:10px"> <b>Note:</b> Copyrights @ 2020, All Rights Reserved by <a herf="https://nebeskie.com">Nebeskie Labs</a> </p>
        <p style="text-align:left;padding-top:10px;font-size:10px"> <b>Note:</b> This document, including all content and information, is powered by Nebeskie Labs . Unauthorized reproduction, distribution, or use of this document or its contents is prohibited.</p>
    </div>

    </div>`;

            // Options for PDF generation
            const opt = {
                margin: 0.5,
                filename: 'invoice_report.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            // Generate and save PDF
            html2pdf().from(content).set(opt).save();
        },
        createGraph() {
            this.graphData = {}
            for (let i of this.dbData) {
                if (!(i.label in this.graphData)) {
                    let type = this.graphType == 1 ? 'line' : 'bar'
                    this.graphData[i.label] = {
                        x: [],
                        y: [],
                        name: i.label,
                        type: type,
                        opacity: 1,
                        marker: {
                            line: {
                                color: this.$store.getters.getColorPalette().accentCode,
                                width: 1
                            },
                        }
                    }
                }

                // console.log(this.graphData);

                this.graphData[i.label]['x'].push(moment(i.date, 'YYYYMMDD').format('YYYY-MM-DD'))
                this.graphData[i.label]['y'].push(i.tariff_amount)
            }
            Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, { displaylogo: false });
        },
        getData() {
            if (this.machines && this.machines.length > 0) {
                this.loading = true
                let labelMap = {}
                let payload = {
                    from_date: this.from_date,
                    groupByDate: true
                }



                let temp = []
                for (let i of this.machines) {
                    labelMap[i.machine_id] = i.machine_name
                    temp.push(i.machine_id)
                }
                payload['machines'] = temp
                if (this.to_date) {
                    payload['to_date'] = this.to_date
                }
                this.payload = payload
                axios.post(this.$store.state.api + 'getTotalMachineTariffDailyDataBetweenDate', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
  

                        if (response.data.status === 'success') {
                            this.dbData = response.data.data


                            this.dbData.map(x => {
                                x.label = labelMap[x.machine_id] ? labelMap[x.machine_id] : 'Label Not defined'
                            })


                            this.dialog = false
                            this.loading = false
                        } else {
                            this.loading = false
                            this.info = response.data.msg
                            this.showDismissibleAlert = true
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        this.info = err
                        this.showDismissibleAlert = true
                    });
            }
        },
    },
    watch: {
        dbData() {
            if (this.dbData && this.dbData.length > 0) {
                this.createGraph();
            }
        },
        machines() {
            this.getData();
        },
        from_date() {
            this.getData();
        },
        to_date() {
            this.getData();
        },
        graphType() {
            if (this.dbData && this.dbData.length > 0) {
                this.createGraph();
            }
        },
    }
}
</script>

<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon color="red" small>mdi-trash-can</v-icon></v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text  @click="update = false">{{$store.getters.getTextMap().close}}
</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddParameterStatusBasedWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <div v-if="widget.meta.parameter" @click="goToView">
            <v-card :style="[widget.height === 'fixed' ? {'border-radius': '5px', 'border': '0px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'height': '100%'} : {'border-radius': '5px', 'border': '0px solid ' + $store.getters.getColorPalette().panelBorderColorCode}]" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                <v-card-title :style="{background: $store.getters.getColorPalette().foregroundColorCode, color: $store.getters.getColorPalette().accentCode}">
                    {{ widget.label }}
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-subtitle :style="{ color: $store.getters.getColorPalette().accentCode}">
                    {{ widget.meta.parameter_label }}
                    <v-divider :dark="$store.getters.getColorPalette().isDark"  class="my-3"></v-divider>
                    <v-row>
                        <v-col>
                            <h2 :style="{ color: color}">{{ status }}</h2>
                        </v-col>
                        <v-divider v-if="!widget.meta.hide_value" vertical :dark="$store.getters.getColorPalette().isDark"/>
                        <v-col v-if="!widget.meta.hide_value">
                            <h2 :style="{'color': $store.getters.getColorPalette().submitbtnColor}">{{ val }} {{ widget.meta.unit }}</h2>
                        </v-col>
                    </v-row>
                    <v-divider :dark="$store.getters.getColorPalette().isDark"  class="my-3"></v-divider>
                </v-card-subtitle>
            </v-card>
        </div>
        <div v-else
            :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import DateUtils from '@/utillities/DateUtils';
import axios from 'axios';
import { io } from "socket.io-client";
import RuleProcessingUtillity from '@/utillities/RuleProcessingUtillity'
import AddParameterStatusBasedWidget from '@/components/crud_components/widgets/AddParameterStatusBasedWidget';
export default
    {
    name: 'ParameterStatusBasedWidget',
    props: ['widget','edit'],
    components: {
        AddParameterStatusBasedWidget,
            InfoAlert
        },
        data() {
            return {
                title: 'Process Based Widget',
                dateUtils: DateUtils,
                loading: false,
                status:'Loading',
                showMenu: false,
                x:0,
                y:0,
                info: '',
                dialog: false,
                streamData:{},
                val:'-',
                color:this.$store.getters.getColorPalette().accentCode,
                showDismissibleAlert: false,
                update: false,
                evaluateRule:RuleProcessingUtillity.evaluateRule
            }
        },
        created() {
        this.stream = io(this.$store.state.streamApi, {withCredentials: true,auth: { token: this.$store.state.jwt },});
        },
        beforeDestroy() {
            this.stream.close();
        },
        mounted() {
            if (this.widget && this.widget.meta && this.widget.meta.parameter){
                this.startStream()
                this.stream.emit("getParam", this.widget.meta.parameter);
            }
        },
        methods: {
            show(e) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
            },
            goToView(){
                if(this.widget && this.widget.meta && this.widget.meta.click_to_view){

                    this.$router.push("/fullview/"+this.widget.meta.click_to_view);
                }
            },
            startStream(){
                this.stream.on("connect_error", (err) => {console.log(err.message);});
                this.stream.on("error", (data) => {console.error(data);this.status='offline'});
                this.stream.on("param", (data) => {
                    if (data.constructor == String) {
                        data = JSON.parse(data);
                    }
                    this.streamData = Object.assign({}, this.streamData);
                    if(data.param_id){
                        this.streamData[data.param_id]=data
                    }
                    if(data.parameter){
                        this.streamData[data.parameter]=data
                    }
                    if(this.streamData && this.streamData[this.widget.meta.parameter] && !isNaN(this.streamData[this.widget.meta.parameter].data)){
                        this.checkStatus(this.streamData[this.widget.meta.parameter].data)
                    }
                })
            },
            checkStatus(value){
                // console.log(this.widget.label,value)
                let defaultColor=this.$store.getters.getColorPalette().accentCode
                let response=this.evaluateRule(value,this.widget.meta.statusRules,{label:'unknown'})
                this.status=response['label']
                this.color=response['color']?response['color']:defaultColor
                this.val=value
            },
            deleteWidget() {
                this.loading = true
                axios.post(this.$store.state.api + 'deleteWidget', this.widget, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.info = "Widget Deleted"
                        this.$emit('update')
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                });
            },
        },
    }
</script>
<style scoped>
.count{
    font-size:30px;
    margin:30px;
    font-weight:bolder;
}

    /* text-align: left; */
    /* display: flex; */
    /* align-self:center; */
    /* align-items: center; */
    /* font-weight:bolder; */

</style>

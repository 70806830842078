<template>
    <v-dialog v-model="dialog" max-width="390">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined >
                {{$store.getters.getTextMap().attach_target}}

                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                    mdi-plus-circle-outline
                </v-icon> 
            </v-btn>
        </template>
      <AttachTargetToParam :parameter="parameter" :attachedTargets="attachedTargets" v-on:close="dialog=false"  v-on:update="$emit('update')"/>
    </v-dialog>
</template>
<script>
import AttachTargetToParam from '@/components/crud_components/AttachTargetToParam'
export default {
    name:'AttachTriggerToParamModal',
    props:['parameter','attachedTargets'],
    components:{
        AttachTargetToParam
    },
    data(){return {
        dialog:false
    }}
}
</script>
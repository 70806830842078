<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-bottom-sheet v-model="dialog">
                    <v-sheet class="text-center" >
                        <CreateLoggedParameter :machine="machine" :loggedParameterDetails="update_param_details" v-on:close="dialog=false"/>
                    </v-sheet>
                </v-bottom-sheet>
            </v-col>
        </v-row>
        <v-card :dark="$store.getters.getColorPalette().isDark">
            <v-row>
                <v-col>
                    <v-simple-table height="200px" :style="{background: 'transparent'}" >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().created}}
</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().param_id}}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().param_name}}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().edit}}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().delete}}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.param_id" v-for="item in params">
                                    <td>{{ item.created }}</td>
                                    <td>{{ item.param_id }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>
                                        <v-icon  small  :color="$store.getters.getColorPalette().accentCode" @click="updateLoggedParameter(item)">mdi-pencil</v-icon>
                                    </td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteLoggedParameter(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                            <v-icon  small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateLoggedParameter from '@/components/CreateLoggedParameter'
export default {
    name:'LoggedParamsTable',
    props:['machine'],
    components:{
            InfoAlert,
            DeleteConfirmation,
            CreateLoggedParameter
        },
        data(){ 
            return {
                loading:false,
                dialog:false,
                showDismissibleAlert:false,
                info:'',
                params:[],
                update_param_details:{},
            }},
        mounted(){
            this.getLoggedParams()
        },
        methods:{
            getLoggedParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine && this.machine.machine_id){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getLoggedParamsFromMachine',{machine_id:this.machine.machine_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            this.params=response.data.params
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        updateLoggedParameter(param_details){
            this.dialog=!this.dialog
            this.update_param_details = param_details
        },
        deleteLoggedParameter(param_details){
            if(param_details && param_details.param_id){
                axios.post(this.$store.state.api+'deleteLoggedParameter',{param_id:param_details.param_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            this.onReset()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        onReset(){
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
        }
}

</script>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-row>
            <v-col>
                <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().set_param_type_for_machine }}</span>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>        
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-autocomplete
                v-if="!loading"
              v-model="form.tag_type"
          :items="tagTypeOptions"
          :label="$store.getters.getTextMap().tag_type"
          outlined
          item-text="label"
          item-value="value"
          required
          :dark="$store.getters.getColorPalette().isDark" 
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            :rules="objectRules"
        ></v-autocomplete>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-autocomplete
                v-if="!loading"
              v-model="form.param_id"
          :items="paramOptions"
          :label="$store.getters.getTextMap().parameters"
          outlined
          item-text="label"
          item-value="value"
          required
   :dark="$store.getters.getColorPalette().isDark"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            :rules="objectRules"
        ></v-autocomplete>
            </v-col>
            <v-col v-if="!loading" align-self="center">
                <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            outlined
            @click="onSubmit"
          >
            {{$store.getters.getTextMap().update_param_type}}
          </v-btn>
          
            </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'SetTagTypeInMachine',
    props:['machine','params'],
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            form:{
            },
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        tagTypeOptions(){
            let op=[]
          
          if(this.$store.state.tagTypes && this.$store.state.tagTypes.length>0 ){
              
              for(let i of this.$store.state.tagTypes){
                  
                  if(i && i.name){
                    
                    op.push({value:i.tag_type, label: i.name })
                  }else{
                    op.push({value:i.tag_type, label: i.tag_type })
                  }
                  
              
          }
          }
          // console.log(op);
          return op
       
        },
        paramOptions(){
             
            let op=[]
          
          if(this.params && this.params.length>0 ){
              
              for(let i of this.params){
                  
                  if(i && i.name){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form['machine_id']=this.machine.machine_id
            axios.post(this.$store.state.api+'setTagTypeInMachine',this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        
        this.$emit('success')
        
            this.loading=false
            //this.dialog=false
            //this.$emit('close')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.usageForm.reset()
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        }
    }
}
</script>
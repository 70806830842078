<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col cols="4">
                <v-card class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <!-- <v-icon large left>mdi-gas-station</v-icon> -->
                        {{$store.getters.getTextMap().power_system_fault}}
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8">
                                <h2>4</h2><h3>{{$store.getters.getTextMap().alarms}}
</h3>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <!-- <v-icon large left>mdi-currency-rupee</v-icon> -->
                        {{$store.getters.getTextMap().electricity_consumption}}

                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8">
                                <h2>3299</h2><h3>kWH</h3>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <!-- <v-icon large left>mdi-gas-station</v-icon> -->
                        {{$store.getters.getTextMap().average_demand}}

                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8">
                                <h2>1475</h2><h3>KW</h3>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
// import Plotly from 'plotly.js'
export default {
    name:'PowerModuleInsights',
    components:{
        InfoAlert,
    },
    data(){
        return {
            graphType:'pm',
            info:'',
            loading:false,
            showDismissibleAlert:false,
            layout : {
                colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        // showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    },
                    layoutHeat : {
                        font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -60,
                        zeroline: false,
                        // showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }

                    }
        }
    },
    mounted(){
        // this.CreateWaterUsageChart()
        // this.createWaterUsageHeatMap()
    },
    methods:{

    },
    watch:{

    }
}
</script>

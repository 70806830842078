<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row>
            <v-col>
                <v-card-title v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Parameter Status Widget</v-card-title>
                <v-card-title v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Parameter Based Widget</v-card-title>
            </v-col>
        </v-row> -->
        <!-- widget form -->
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2">
                <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_parameter_status_widget}}</v-card-title>
                <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_parameter_status_widget}}</v-card-title> 
                <v-row>
                    <!--   Label -->
                    <v-col>
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-text-field 
                            dense 
                            outlined 
                            v-if="!loading" 
                            v-model="widgetForm.label" 
                            :counter="30"
                            :rules="[rules.required]" 
                            :label="$store.getters.getTextMap().widget_label">
                        </v-text-field>
                    </v-col>
                    <!-- widget width -->
                    <v-col>
                        <v-select 
                            dense outlined :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions" :label="$store.getters.getTextMap().widget_width" item-text="label"
                            item-value="value" :rules="[rules.required]">
                        </v-select>
                    </v-col>
                    <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <!-- widget swq -->
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <!-- widget height -->
                    <v-col>
                        <v-select 
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark:$store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.height" :items="heightOptions" item-text="label" item-value="value"
                            :label="$store.getters.getTextMap().widget_height" :rules="[rules.required]" dense outlined>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="pa-2 my-2">
                <v-row>
                    <v-col>
                        <v-card-title class="sub-heading">{{$store.getters.getTextMap().choose_parameter}}</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center" v-if="$store.state.user">
                        <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                            <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                            <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                <v-row>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field 
                            dense 
                            outlined 
                            v-if="!loading" 
                            v-model="widgetForm.meta.parameter_label" 
                            :counter="30"
                            :rules="[rules.required]" 
                            :label="$store.getters.getTextMap().param_label">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field 
                        dense 
                        outlined 
                        v-if="!loading" 
                        v-model="widgetForm.meta.unit" 
                        :counter="30"
                        :label="$store.getters.getTextMap().unit">
                    </v-text-field>
                </v-col>
                <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                        v-model="widgetForm.meta.click_to_view"
                        :items="viewOptions"
                        required
                        clearable
                        :label="$store.getters.getTextMap().view"
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        outlined
                        dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-checkbox
         :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="widgetForm.meta.hide_value"
                    :label="$store.getters.getTextMap().hide_value"
                    dense
                    outlined
                    ></v-checkbox>

                    </v-col>
                </v-row>
                <v-row>
                    <!-- {{Parameter Options}} -->
                    <v-col v-if="formType==='deviceForm'">
                        <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col v-if="formType==='machineForm'">
                        <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                </v-row>
            </v-card>
        </v-form>
        <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="pa-2">
            <v-form ref="ruleForm" v-model="ruleValid">
                <v-row>
                    <v-col>
                        <v-card-title class="sub-heading"> {{$store.getters.getTextMap().status_rules}}</v-card-title>
                        <v-simple-table class="mt-3" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">{{$store.getters.getTextMap().label}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().color}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().operator}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().value}}</th>
                                        <th class="text-left">{{$store.getters.getTextMap().actions}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(rule,index) in parameterStatusRule" :key="index">
                                        <td>{{ rule.label }}</td>
                                        <td><div :style="{'background-color':rule.color, 'padding':'3px','border-radius':'5px'}">{{ rule.color }}</div></td>
                                        <td>{{ rule.operator }}</td>
                                        <td>{{ rule.operand }}</td>
                                        <td>
                                            <v-icon @click="moveUp(index)" :disabled="index === 0" color="blue">mdi-arrow-up-thin</v-icon>
                                            <v-icon @click="moveDown(index)" :disabled="index === parameterStatusRule.length - 1" color="blue">mdi-arrow-down-thin</v-icon>
                                            <v-icon @click="removeRule(index)" color="red">mdi-delete</v-icon>
                                        </td>
                                    </tr>
                                    <br/>
                                    <tr class="mt-3">
                                        <td>
                                            <v-text-field
                                                v-if="!loading"
                                                v-model="ruleForm.label"
                                                :rules="objectRules"
                                                :label="$store.getters.getTextMap().label"
                                                dense
                                                outlined
                                                required
                                                :dark="$store.getters.getColorPalette().isDark" 
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-select
                                                v-model="ruleForm.color"
                                                :items="colorOptions"
                                                required
                                                clearable
                                                :label="$store.getters.getTextMap().status_color"
                                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                                outlined
                                                dense
                                                :rules="objectRules"
                                            >
                                                <template v-slot:selection="{ item }">
                                                    <div :style="{'background-color':item.value, 'padding':'3px','border-radius':'5px'}">{{ item.label }}</div>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <div :style="{'background-color':item.value, 'padding':'3px 5px 5px','border-radius':'5px'}">{{ item.label }}</div>
                                                </template>
                                            </v-select>
                                        </td>
                                        <td>
                                            <v-autocomplete
                                                v-model="ruleForm.operator"
                                                :items="operatorOptions"
                                                required
                                                clearable
                                                :label="$store.getters.getTextMap().operator"
                                                item-text="label"
                                                item-value="value"
                                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                                outlined
                                                dense
                                                :rules="objectRules"
                                            ></v-autocomplete>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-if="!loading"
                                                v-model="ruleForm.operand"
                                                :rules="[rules.required,rules.noSpace,rules.num]"
                                                :label="$store.getters.getTextMap().value"
                                                dense
                                                outlined
                                                required
                                                :dark="$store.getters.getColorPalette().isDark" 
                                            ></v-text-field>
                                        </td>
                                        <td><v-btn class="mb-6" text outlined  small @click="addRule">{{$store.getters.getTextMap().add}}<v-icon  color="blue">mdi-plus</v-icon></v-btn></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="d-flex mt-2" align="right" align-self="center">
                <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import InfoAlert from '@/components/InfoAlert';
    import FormRules from '@/utillities/FormRules'
    import EntureStaticLists from '@/utillities/EntureStaticLists'
    import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
    import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
    import axios from 'axios';
    export default {
        name: 'AddParameterStatusBasedWidget',
        props: ['widget', 'seq'],
        components: {
            InfoAlert,
            WorkspaceDeviceParamForm,
            WorkspaceMachineParamForm,
        },
        data() {
            return {
                edit: false,
                widgetValid: false,
                ruleValid: false,
                api: 'createWidgetForView',
                showDismissibleAlert: false,
                info: '',
                loading: false,
                formType:'machineForm',
                parameterStatusRule:[],
                widthOptions: [
                    { label: 'Full screen', value: 12 },
                    { label: 'Half screen', value: 6 },
                    { label: '1/3 screen', value: 4 },
                    { label: 'Quarter screen', value: 3 },
                    {label:'3/4 screen', value:9},
                    { label: 'Auto', value: null }
                ],
                heightOptions: [
                    { label: 'Fixed', value: 'fixed' },
                    { label: 'Flex', value: 'flex' },
                ],
                operatorOptions:EntureStaticLists.comparisonOperators,
                colorOptions: EntureStaticLists.colorOptions,
                meta: {},
                ruleForm:{},
                widgetForm: {
                    meta:{
                        process_ids: [],
                    }
                },
                rules:FormRules.rules,
                objectRules: [
                    FormRules.rules.required
                ],
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            viewOptions() {
                let op = []
                if (this.$store.state.views ) {
                    for (let i of this.$store.state.views) {
                        op.push({label: i.name,value: i.view_id})
                    }
                }
                return op
            },
            
        },
        methods: {
            init() {
                if (this.widget && this.widget.widget_id && this.widget.view_id) {
                    this.edit = true
                    this.widgetForm = Object.assign({}, this.widget)
                    this.api = 'updateWidget'
                    this.widgetLabel = this.widgetForm.label
                    if (!(this.widgetForm.meta)) {
                        this.widgetForm.meta = {}
                    }else{
                        this.parameterStatusRule=this.widgetForm.meta.statusRules
                    }
                } else {
                    if (this.widget.view_id) {
                        this.api = 'createWidgetForView'
                        this.widgetForm = Object.assign({}, this.widget)
                        this.widgetForm.meta = { }
                        this.widgetForm.seq = this.seq ? this.seq : 0
                    }
                }
            },
            updateParamList(value){
                if(value && value.parameter && value.parameter.param_id){
                    this.widgetForm.meta.parameter=value.parameter.param_id
                    this.widgetForm.meta.parameter_label=value.parameter.name
                }
            },
            moveUp(index) {
                if (index > 0) {
                    const temp = this.parameterStatusRule[index];
                    this.$set(this.parameterStatusRule, index, this.parameterStatusRule[index - 1]);
                    this.$set(this.parameterStatusRule, index - 1, temp);
                }
            },
            moveDown(index) {
                if (index < this.parameterStatusRule.length - 1) {
                    const temp = this.parameterStatusRule[index];
                    this.$set(this.parameterStatusRule, index, this.parameterStatusRule[index + 1]);
                    this.$set(this.parameterStatusRule, index + 1, temp);
                }
            },
            addRule() {
                this.$refs.ruleForm.validate()
                    if(this.ruleValid){
                        let temp=this.parameterStatusRule
                        temp.push({
                        label: this.ruleForm['label'],
                        color: this.ruleForm['color'],
                        operator: this.ruleForm['operator'],
                        operand: Number(this.ruleForm['operand']),
                        });
                        this.parameterStatusRule=temp
                    }
                },
            removeRule(index) {
                if (index !== -1) {
                    this.parameterStatusRule.splice(index, 1); 
                }
            },
            onSubmit() {
                this.$refs.widgetForm.validate()
                if (this.widgetValid) {
                    this.widgetForm['meta']['statusRules']=this.parameterStatusRule
                    axios.post(this.$store.state.api + this.api, this.widgetForm, {
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.jwt
                        }
                    }).then(res => {
                        if (res.data.status === 'success') {
                            this.info = "Widget Created";
                            this.loading = false
                            this.showDismissibleAlert = true
                            this.$refs.widgetForm.reset()
                            this.$refs.ruleForm.reset()
                            this.$emit('success')
                            this.$emit('close')
                        }
                    })
                }
            },
            onClose() {
                this.$refs.widgetForm.reset()
                this.$refs.ruleForm.reset()
            }
        },
        watch: {
            widget() {
                this.init()
            }
        }
    }
</script>
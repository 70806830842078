<template>
    <div :style="[{'background-color': $store.getters.getColorPalette().backgroundColorCode }]" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :color="$store.getters.getColorPalette().backgroundColorName" :dark="$store.getters.getColorPalette().isDark" flat>
            <v-divider></v-divider>
            <v-container v-if="settingOptions">
                <v-row v-if="showEdit && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " >
                    <v-col cols="auto" class="d-flex">
                    <v-chip
                        v-if="
                        $store.state.user &&
                        $store.state.settingMode &&
                        $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        ><v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{
                            color: $store.getters.getColorPalette().background2ColorCode,
                        }"
                        :class="
                            $store.getters.getColorPalette().foregroundColorName +
                            ' mx-0 px-0'
                        "
                        ><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-identifier</v-icon></v-avatar>
                        {{ view.view_id }}
                        </v-chip>
            </v-col>
            <v-col cols="auto" class="d-flex">
                    <v-btn class="mr-1" v-if="!edit" @click="setAsDefault" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().add_to_dashboard }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-eye-arrow-left</v-icon>
                    </v-btn>
                    <EditViewBottomSheet :view="view"/>
                    <DeleteConfirmation v-if="!edit" v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this table View?"> 
                        <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete_view }}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                        </v-btn>
                    </DeleteConfirmation>
                </v-col>
                <v-col  cols="auto" class="d-flex ml-auto" align="right">
                    <v-btn v-if="!edit" @click="startEdit" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().edit_view }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon></v-btn>&nbsp;
                    <v-btn v-if="!edit" @click="deleteView" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete }}
                        <DeleteConfirmation v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">                                                                         
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                        </DeleteConfirmation>  
                    </v-btn>
                    <v-btn v-if="edit" @click="save" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().save }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-content-save</v-icon></v-btn>&nbsp;
                    <v-btn v-if="edit" @click="discard" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().discard }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-close</v-icon></v-btn></v-col>
                    </v-row>
                </v-container>
                <v-row  no-gutters>
                    <v-col cols="12">
                        <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                            <template v-slot:default>
                            <thead>
                                <tr >
                                    <th :class="'white--text text-center  text-subtitle-1 text-capitalize '+$store.getters.getColorPalette().foregroundColorName"></th>
                                    <th v-for="(item,index) in tableData.headers"
                                        class="text-center"
                                        :key="item" :class="$store.getters.getColorPalette().foregroundColorName" :color="$store.getters.getColorPalette().accentCode">
                                        <strong>{{item}}</strong><span>
                                        <v-btn small v-if="edit"  icon @click="editHeader(index,item)" color="blue" >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn small v-if="edit"  icon color="red" >
                                            <DeleteConfirmation v-on:confirm="deleteHeader(index)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">                                                                         
                                                <v-icon small>mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </v-btn></span>
                                    </th>
                                    <th v-if="edit" :class="'text-center  text-subtitle-1 text-capitalize '+$store.getters.getColorPalette().foregroundColorName" >
                                        <v-btn small v-if="edit"  icon @click="editHeader(tableData.headers.length)"  :color="$store.getters.getColorPalette().accentCode">
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn> 
                                    </th>
                                </tr>
                            </thead>
                            <v-divider></v-divider>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}" v-if="!loading" >
                                <tr v-for="(param,paramIndex) in tableData.params" :key="paramIndex" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                                    <td :color="$store.getters.getColorPalette().accentCode"><strong>{{param.name}}</strong>
                                        <v-btn small v-if="edit"  icon @click="editName(paramIndex,param)" :color="$store.getters.getColorPalette().accentCode" >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-center  " v-for="(item,index) in param.param" @click="dialog=true" :key="index">
                                        <div v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','write'])" :style="[{'cursor':'pointer'}]" @click="openDialog(index,item,isNaN(streamData[item]) ? '-' : streamData[item])">{{ isNaN(streamData[item]) ? '-' : streamData[item] }}</div>
                                        <div v-else>{{ isNaN(streamData[item]) ? '-' : streamData[item] }}</div>
                                        <v-btn small v-if="edit"  icon @click="editParam(paramIndex,index)" color="blue" >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn small v-if="edit"  icon :color="$store.getters.getColorPalette().deletebtnColor" >
                                            <DeleteConfirmation v-on:confirm="deleteParam(paramIndex,index)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                                                <v-icon small>mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </v-btn>
                                    </td>
                                    <td v-if="edit" class="text-center text-capitalize ">
                                        <v-btn small v-if="edit" @click="editParam(paramIndex,param.param.length)"   icon  color="green" >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </td>
                                    <td v-if="edit" class="text-right text-capitalize ">
                                        <v-btn  v-if="edit" icon  :color="$store.getters.getColorPalette().deletebtnColor">
                                            <DeleteConfirmation v-on:confirm="deleteRow(paramIndex)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                                                <v-icon small>mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-if="edit">
                                    <td>
                                        <v-btn small v-if="edit" @click="addRow" outlined color="blue" >{{ $store.getters.getTextMap().add }}
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <!-- <test :isOpen="dialogIndex === paramData.index" @closeDialog="closeDialog" :paramData="paramData"/> -->
            <GetTextModal :show="headerEdit" :name="headerName" v-on:close="headerEdit=false" v-on:value="updateHeader" />
            <GetTextModal :show="nameEdit" :name="name" v-on:close="nameEdit=false" v-on:value="updateName" />
            <GetParameterModal :show="paramEdit" v-on:close="paramEdit=false" v-on:value="updateParam" />
            <v-dialog  :value="dialogIndex === paramData.index" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card :dark="$store.getters.getColorPalette().isDark" :class="$store.getters.getColorPalette().backgroundColorName" class="pa-0 ma-0">
                    <v-toolbar :dark="$store.getters.getColorPalette().isDark" :class="$store.getters.getColorPalette().foregroundColorName">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ paramData.param_id }} Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="closeDialog">close</v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <ParamDetails :param_id="paramData.param_id" :val="paramData.val" />
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios'
import {io} from 'socket.io-client'
import GetTextModal from '@/components/modals/GetTextModal'
import GetParameterModal from '@/components/modals/GetParamModal'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import EditViewBottomSheet from '@/components/modals/EditViewBottomSheet'
import ParamDetails from '@/components/items/ParamDetails'
// import test from '@/pages/views/test'
// import ColorUtility from '@/utillities/ColorUtility'
export default {
    name:'TableViewPage',
    props:{
    'view':{
        type:Object
    },
    showEdit:{
        type:Boolean,
        default:true
    },
    settingOptions:{
        type:Boolean,
        default:true
    }
},
    components:{
        GetTextModal,
        GetParameterModal,
        EditViewBottomSheet,
        InfoAlert,
        DeleteConfirmation,
        ParamDetails,
    },
    created(){
        //this.socket=io('https://localhost:80');
        this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
    },
    beforeDestroy() {
        //this.socket.close()
        //console.log('before destroy')
        this.stream.close()
    },
    mounted(){
        if(this.view && this.view.view_id && this.view.data){
            this.tableData=Object.assign({},this.view.data)
            this.api='updateView'
            this.createMode=false
            this.viewName=this.view.name
            this.viewId=this.view.view_id
        }else{
            this.tableData={headers:[],params:[]}
            this.api='createView'
            this.edit=true
            this.createMode=true
        }
            this.stream.on("connect_error", (err) => {
            console.log(err.message); // prints the message associated with the error
        });
            this.stream.on('error',data=>{
            console.error(data)
        })
        this.stream.on('param',data=>{
            //console.log(typeof data)
            if(data.constructor==String){
            data=JSON.parse(data)
        }
        if(!('param_id' in data)){
            data.param_id=data.parameter
        }
        //console.log(typeof data)
        let temp=Object.assign({},this.streamData)
        temp[data.param_id]=Math.round(Number(data.data)*1000)/1000
        this.streamData=temp
        //console.log(temp)
        })
        this.configureParamStream() 
    },
    data(){
        return {
            editOption:true,
            showDialog:false,
            // colorUtil:ColorUtility,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            createMode:false,
            api:'updateView',
            edit:false,
            headerIndex:null,
            headerEdit:false,
            nameIndex:null,
            nameEdit:false,
            parIndex:null,
            paramEdit:false,
            viewName:null,
            viewId:null,
            dialog:false,
            dialogIndex: null,
            paramData:{},
            cache:{},
            streamData:{},
            name:null,
            headerName:null,
            tableData:{
                headers:['Pump1','Pump2','Blower1','Blower2',],
                params:[
                    {name:'RPM',param:['rpm1','rpm2','rpm3','rpm4']},
                    {name:'Current',param:['current1','current2','current3','current4'],unit:'Amps'},
                    {name:'Run time',param:['run1','run2']},
                    {name:'KWH',param:['kwh1']},
                ]
            }
        }
    },
    computed:{
        headers(){
            return this.tableData['headers']
        }
    },
    methods:{
        openDialog(index,param_id,value) {
            this.paramData={
                'param_id':param_id,
                'val':value,
                'index':index
            }
            this.dialogIndex = index; // Set the dialogIndex to open the dialog
        },
        closeDialog() {
            this.paramData={}
            this.dialogIndex = null; // Close the dialog
        },
        configureParamStream(){
            for(let i of Object.keys(this.streamData)){
                this.stream.emit('removeParam',i)
            }
            let params=new Set()
            for(let i of this.tableData.params){
                for(let p of i.param){
                    params.add(p)
                }
            }
            for (let s of params){
                this.stream.emit('getParam',s)
            }
        },
        setAsDefault(){
            this.loading=true
            let payload={view_id:this.viewId}
            axios.post(this.$store.state.api+'updateDashboardView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.edit=false
                    //this.$store.dispatch('refreshViews')
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })
        },
        save(){
            this.loading=true
            let payload={
                view_id:this.viewId,
                view_type:'tableView',
                data:this.tableData,
                name:this.viewName
            }
            axios.post(this.$store.state.api+this.api,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.edit=false
                    this.$store.dispatch('refreshViews')
                    this.configureParamStream()
                    
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })
        },
        deleteView(){
            let payload={
                view_id:this.viewId
            }
            this.loading=true
            axios.post(this.$store.state.api+'deleteView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.edit=false
                    this.$store.dispatch('refreshViews')
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        discard(){
            this.tableData=Object.assign({},this.cache)
            this.edit=false
        },
        startEdit(){
            this.cache=Object.assign({},this.tableData)
            this.edit=true
        },
        addRow(){
            this.tableData.params.push({name:'Unassigned',param:[]})
        },
        deleteRow(index){
            this.tableData.params.splice(index,1)
        },
        editName(index,param){
            if(param && param.name){
                this.name=param.name
            }
            this.nameIndex=index
            this.nameEdit=true
        },
        updateName(value){
            this.tableData['params'][this.nameIndex]['name']=value
            this.name=null
            //console.log(this.tableData)
        },
        editHeader(index,header){
            if(header){
                this.headerName = header
            }
            this.headerIndex=index
            this.headerEdit=true
        },
        updateHeader(value){
            let headers=this.tableData['headers'].slice()
            headers[this.headerIndex]=value
            this.tableData['headers']=headers
            this.headerName=null
            //console.log(this.tableData)
        },
        deleteHeader(index){
            this.tableData.headers.splice(index,1)
        },
        editParam(paramIndex,index){
            this.parIndex=[paramIndex,index]
            this.paramEdit=true
            //console.log(this.parIndex)
        },
        deleteParam(paramIndex,index){
            this.tableData.params[paramIndex]['param'].splice(index,1)
        },
        updateParam(value){
            let params=this.tableData['params'][this.parIndex[0]]['param'].slice()
            params[this.parIndex[1]]=value
            this.tableData['params'][this.parIndex[0]]['param']=params
        },
       
        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    }
}
</script>
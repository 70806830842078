<template>
  <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'max-height':'80%'}" >
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) && showEdit" >
  <v-col cols="auto" class="d-flex">
                    <v-chip
                        v-if="
                        $store.state.user &&
                        $store.state.settingMode &&
                        $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        ><v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{
                            color: $store.getters.getColorPalette().background2ColorCode,
                        }"
                        :class="
                            $store.getters.getColorPalette().foregroundColorName +
                            ' mx-0 px-0'
                        "
                        ><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-identifier</v-icon></v-avatar>
                        {{ view.view_id }}
                        </v-chip>
            </v-col>
            <v-col cols="auto" class="d-flex">
                  <v-btn class="mr-1" v-if="!edit" @click="setAsDefault" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().add_to_dashboard }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-eye-arrow-left</v-icon>
                  </v-btn>
                  <EditViewBottomSheet v-if="!edit" :view="view"/>
                  <DeleteConfirmation v-if="!edit" v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this table View?"> 
                      <v-btn class="ml-1" :color="$store.getters.getColorPalette().deletebtnColor" small text outlined>{{ $store.getters.getTextMap().delete_view }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                      </v-btn>
                  </DeleteConfirmation>
                  <v-btn class="ml-1" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="showScroll=!showScroll">{{ $store.getters.getTextMap().adjust }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small>mdi-magnify</v-icon>
                      </v-btn>
              </v-col>
              <v-col  cols="auto" class="d-flex ml-auto" align="right">
                  <v-btn v-if="!edit" @click="edit=!edit" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().edit }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon></v-btn>&nbsp;
                  <v-btn v-if="edit" @click="save" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().save }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-content-save</v-icon></v-btn>&nbsp;
                  <v-btn v-if="edit" @click="discard" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().discard }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-close</v-icon></v-btn></v-col>
  </v-row>
  <br>
         <v-row no-gutters dense v-if="$store.state.user && $store.state.settingMode">
           
           <v-col align-self="end">
             
           </v-col>
           <v-col cols="1" align-self="center" v-if="edit">  
             <SldMenuList v-on:value="emitImageList" v-on:lineSelect="handleSelectedLines" v-on:addTextBox="addTextBox" v-on:imageUpload="imageUpload"/> 
           </v-col>
         </v-row>
         <v-dialog
     v-model="showScroll"
     
     max-width="390"
   >
    
     <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-card-title class="headline">
         {{ $store.getters.getTextMap().adjust }}
       </v-card-title>
       <v-card-text>
         <v-slider
         label="Zoom"
         v-model="zoom"
         :min="10"
         :max="2000"
             :step="10"
             :thumb-size="25"
             color="teal"
             ticks
             thumb-label="always"
             
             outlined
             ></v-slider>
             <v-slider
    v-if="$store.state.user && $store.state.settingMode"
    v-model="horizontalScroll"
    step="10"
    label="Horizontal Scroll"
    thumb-label
    ticks
    :min="-2000"
             :max="2000"
             :style="{height: '100%'}"
    ></v-slider>
    <v-slider
    v-if="$store.state.user && $store.state.settingMode"
    v-model="verticalScroll"
    label="Vertical Scroll"
      step="10"
      vertical
      thumb-label
      ticks
      :min="-2000"
      :max="2000"
      style="height: 100%;"
      ></v-slider>
    </v-card-text>
        </v-card>

        </v-dialog>
  
<!-- Context Menu for Images -->
<v-menu
 v-model="shapeContextMenu.show"
 :position-x="shapeContextMenu.x"
 :position-y="shapeContextMenu.y"
 absolute
 >
 <v-list>
   <v-list-item @click="resizeShape(shapeContextMenu.index)">
     <v-list-item-title>{{ $store.getters.getTextMap().resize }}</v-list-item-title>
    </v-list-item>
    <v-list-item @click="removeShape(shapeContextMenu.index)">
      <v-list-item-title>{{ $store.getters.getTextMap().remove }}</v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>

<!-- Context Menu for Lines -->
<v-menu
v-model="lineContextMenu.show"
:position-x="lineContextMenu.x"
 :position-y="lineContextMenu.y"
 absolute
>
 <v-list>
   <!-- Add an option to remove the line -->
   <v-list-item @click="removeLine(lineContextMenu.index)">
    <v-list-item-title>{{ $store.getters.getTextMap().remove }}</v-list-item-title>
   </v-list-item>
  </v-list>
</v-menu>


<!-- Context Menu for images -->
<v-menu
v-model="imageContextMenu.show"
:position-x="imageContextMenu.x"
:position-y="imageContextMenu.y"
absolute
>
<v-list>
  <!-- Add an option to remove the line -->
  <v-list-item @click="removeImage(imageContextMenu.index)">
    <v-list-item-title>{{ $store.getters.getTextMap().resize }}</v-list-item-title>
  </v-list-item>
</v-list>
</v-menu>

<!-- Context Menu for Text Box -->
<v-menu
v-model="textContextMenu.show"
:position-x="textContextMenu.x"
:position-y="textContextMenu.y"
absolute
>
<v-list>
  
  <v-list-item @click="enableTextEdit">
     <v-list-item-title>{{ $store.getters.getTextMap().edit }}</v-list-item-title>
    </v-list-item>
    <v-list-item @click="enableTextResize">
      <v-list-item-title>{{ $store.getters.getTextMap().resize }}</v-list-item-title>
    </v-list-item>
    <v-list-item @click="removeText(textContextMenu.index)">
     <v-list-item-title>{{ $store.getters.getTextMap().remove }}</v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>


<v-dialog
v-model="textDialog"

     max-width="290"
     >
     <v-form
     ref="textForm"
     v-model="textValid"
   
     >
     <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-card-title class="headline">
        Edit Text
      </v-card-title>
      <v-card-text>
           <v-text-field
           v-model="textLabel"
     :counter="15"
     :rules="[rules.required]"
     label="Text"
     required
     ></v-text-field>
    </v-card-text>
    <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
         text
         @click="editText(textContextMenu.index)"
         >
           Update
         </v-btn>
         <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
           text
           @click="textDialog=false"
           >
           Cancel
         </v-btn>
        </v-card-actions>
     </v-card>
    </v-form>
   </v-dialog>
   
   <v-dialog
     v-model="elementResizeDialog"
     
     max-width="290"
     >
    
     <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
       <v-card-title class="headline">
         {{ $store.getters.getTextMap().resize }}
        </v-card-title>
       <v-card-text>
         <v-slider
        v-model="elementSize"
        thumb-label
        label="Zoom"
        hint="Size"
          max="4"
          step="0.01"
          min="0.1"
        ></v-slider>
      </v-card-text>
        <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
           text
           @click="resize"
         >
           Done
         </v-btn>
       </v-card-actions>
        </v-card>

      </v-dialog>
   <v-dialog
   v-model="resizeDialog"
   
     max-width="290"
     >
    
     <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
       <v-card-title class="headline">
         {{ $store.getters.getTextMap().resize }}
       </v-card-title>
       <v-card-text>
        <v-slider
        v-model="size"
          hint="Size"
          max="15"
          step="0.2"
          min="0"
        ></v-slider>
        </v-card-text>
        <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
           text
           @click="resizeDialog=false"
         >
           Done
         </v-btn>
       </v-card-actions>
        </v-card>

        </v-dialog>

 <v-dialog
    v-model="paramEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
  <v-card  :dark="$store.getters.getColorPalette().isDark">
    
  <v-toolbar
  :dark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().foregroundColorCode"
      >
        <v-btn
          icon
          :dark="$store.getters.getColorPalette().isDark"
          @click="paramEdit = false"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Choose Parameter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :dark="$store.getters.getColorPalette().isDark"
            text
            @click="paramEdit = false"
            >
            close
           </v-btn>
         </v-toolbar-items>
       </v-toolbar>
        <v-card-title>Add real time data to the SLD</v-card-title>
        
         <RealTimeStatusForm   :meta="paramMeta" v-on:close="paramEdit=false" v-on:value="updateTextBox"/>

         <!-- <v-img
       v-if="temp"
       :src="temp"
       alt="Uploaded Image"
       ></v-img> -->
      </v-card>
    </v-dialog>
    <div class="svgContainer">
      <svg ref="playground" overflow="scroll" :style="{border: '2px solid '+$store.getters.getColorPalette().accentCode, 'max-height':screenHeight+'px' }" xmlns="http://www.w3.org/2000/svg" width="100%"  height="100%" :viewBox="horizontalScroll.toString()+' '+verticalScroll.toString()+' '+zoom.toString()+' '+zoom.toString()">
      </svg></div>
</v-container>

</template>

<script>
import {io} from 'socket.io-client';
import axios from 'axios'
import SldMenuList from '@/components/menu/SldMenuList';
import InfoAlert from '@/components/InfoAlert';
import DeleteConfirmation from '@/components/DeleteConfirmation';
// import GetParameterModal from '@/components/modals/GetParameterModal'
import RealTimeStatusForm from "@/components/sld/RealtimeStatusForm"

import EditViewBottomSheet from '@/components/modals/EditViewBottomSheet';
import FormRules from '@/utillities/FormRules'
import RuleProcessingUtillity from '@/utillities/RuleProcessingUtillity'
import DateUtils from '@/utillities/DateUtils'
//import ResizeShapes from "@/components/sld/ResizeShapes";

import * as d3 from 'd3'; 



export default {
 name:'SLDViewPage',
 props:{
  'view':{
    type:Object,
    required:true
  },
  showEdit:{
      type:Boolean,
      default:true
  }
},
components:{
     
          SldMenuList,
          // GetParameterModal,
          InfoAlert,
          DeleteConfirmation,
          EditViewBottomSheet,
          RealTimeStatusForm,
         //  ResizeShapes,
         
},
created(){
   //this.socket=io('https://localhost:80');
   this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
        this.runUpdates()


 },
 beforeDestroy() {
   //this.socket.close()
   //console.log('before destroy')
   this.runUpdateFlag=false
   this.stream.close()
 },
mounted(){
  this.updateViewData()
 this.init()
 // Get the width and height of the screen
// const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
// const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// console.log("Screen Width:", screenWidth);
// console.log("Screen Height:", screenHeight);
},

data(){
      return{
        showScroll:false,
        runUpdateFlag:true,
       stream:null,
       streamData:{},
       usageData:{},
       paramEdit:false,
       verticalScroll:0,
       horizontalScroll:0,
       info:'',
       loading:false,
       showDismissibleAlert:false,
       edit:false,
       rules:FormRules.rules,
       evaluateRule:RuleProcessingUtillity.evaluateRule,
              // selectedLines :JSON.parse(localStorage.getItem('selectedLines')) || [],
              // imageList: JSON.parse(localStorage.getItem('imageList')) || [],
              // Items: [...(JSON.parse(localStorage.getItem('imageList')) || []), ...(JSON.parse(localStorage.getItem('selectedLines')) || [])],
              dragHandler:null,
              deltaX:1,
              deltaY:1,
              zoom:600,
              refreshTexts:false,
              textDialog:false,
              textValid:null,
              rtdTextDialog:false,
              rtdTextValid:null,
              resizeDialog:false,
              textResizeDialog:false,
              resizeIndex:-1,
              textResizeIndex:-1,
              size:1,
              elementSize:1,
              elementResizeDialog:false,
              elementType:'text',
              elementIndex:-1,
              api:'updateSLDView',
              viewName:null,
              viewId:null,
              viewData:{},
              shapes:[
              ],
              lines:[
               {name:'line',
               type:'line',
             x1:1,x2:100,y1:1,y2:100},
             
             ],
             texts:[
               
             ],
             images:[],
             unitMap:{},
             svgContainer:null,
             textLabel:null,
             paramMeta:null,
              imageContextMenu: {
                     show: false,
                      x: 0,
                      y: 0,
                      index: -1,
                     },
              shapeContextMenu: {
                     show: false,
                      x: 0,
                      y: 0,
                      index: -1,
                     },
             lineContextMenu: {
                     show: false,
                     x: 0,
                     y: 0,
                     index: -1,
                   },
             textContextMenu: {
                     show: false,
                     x: 0,
                     y: 0,
                     index: -1,
                   },
    shapeLabels: {},
       selectedItemIndex: -1,
   
   
                     
      }
      
},
computed:{
//    edit(){
//      return this.$store.state.settingMode
//    }
screenWidth(){
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return screenWidth;

},
screenHeight(){

  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return screenHeight*0.8;
},

},
methods:{

  runUpdates(){
    if(this.runUpdateFlag){
      this.updateRTData()
      setTimeout( ()=> {
        this.runUpdates()
      }, 1000);
    }
  },

 enableTextEdit(){
   // console.log(this.textContextMenu.index)
   let item=this.texts[this.textContextMenu.index]
   // console.log(item)
   if(!item) return
   this.paramMeta=item
   switch(item.type){
     case 'rtd':
       this.paramEdit=true
      //  this.paramMeta=item
       break;
     case 'rtc':
       this.paramEdit=true
       break;
     default:
       this.paramEdit=true
   }
   // this.paramEdit=true
 },
 enableTextResize(){
   // console.log(this.textContextMenu.index)
  //  let item=this.texts[this.textContextMenu.index]
   // console.log(item)
   this.elementSize=this.texts[this.textContextMenu.index]['size']?this.texts[this.textContextMenu.index]['size']:1
   this.elementType='text'
   this.elementIndex=this.textContextMenu.index
   this.elementResizeDialog=true
   // this.paramEdit=true
 },
 resize(){
  this.texts[this.textContextMenu.index]['size']=this.elementSize
  this.updateRTData()
  this.elementResizeDialog=false
 },
 subscribe(){
  this.getUnits()
           if(this.texts && this.texts.length>0){
             this.texts.forEach((param)=>{
               if(param.type=='rtd'){
                 this.stream.emit('getParam',param.param_id)
               }else if(param.type=='rtc'){
                this.stream.emit('getParamConsumption',param.param_id)
               }else if(param.type=='usage'){
                let usageTimeframe=param.usage_timeframe?param.usage_timeframe:'MTD'
                this.getUsage([param.param_id],usageTimeframe,param.usage_aggregation)
               }
               
             })
           }

       },
getUnits(){
  let unitMap={}
  for(let d of this.texts){
    if(d.type && d.type!='text'){
      let param=this.$store.getters.getParamById(d['param_id'])
      if(param && param.unit){
        unitMap[d['param_id']]=param.unit
      }
    }
  }

  this.unitMap=unitMap


},
async getUsage(params, timeFrame, metric='sum'){

  let payload={
    from_date:DateUtils.getFromDate(timeFrame).format('YYYYMMDD'),
    parameters:params,
  }

  axios.post(this.$store.state.api + 'getParamDailyDataBetweenDate', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } }).then(response=>{
    if(response.data.status==='success'){
      for(let data of response.data.data){
        let param_id=data['param_id']
        // console.log(param_id)

        let value=null
        if(metric=='sum'){
          value=data['usage']
        }else{
          value=data['avg']
          
        }
        if(!(param_id in this.usageData)){
          
          this.usageData[param_id]={}
        }
        this.usageData[param_id][timeFrame]=value
        // console.log(param_id,value)
      }
      this.refreshTexts=true
    }else{
      this.info = response.data.msg;
      //this.info = d;
              this.showDismissibleAlert = true;
      //console.log(response.data.msg)

    }
  }).catch(err=>{
    console.error(err)
  })

},
 init(){
  this.runUpdateFlag=true
   this.stream.on("connect_error", (err) => {
           console.error(err.message); // prints the message associated with the error
           });
           this.stream.on('error',data=>{
           console.error(data)
           
           })

           this.stream.on('param',data=>{
             if(data.constructor==String){
               data=JSON.parse(data)
              }
              // console.log(data.data)
           // console.log(data)
           let temp=Object.assign({},this.streamData)
           temp[data.param_id]=Math.round(Number(data.data)*1000)/1000
           this.streamData=temp
           // console.log(temp)
           this.refreshTexts=true
          //  this.updateRTData()
           })

           this.stream.on('paramConsumption',data=>{
               //console.log(typeof data)
               if(data.constructor==String){
               data=JSON.parse(data)
           }
           // console.log(data)
           let temp=Object.assign({},this.streamData)
           temp[data.param_id]=Math.round(Number(data.data)*1000)/1000
           this.streamData=temp
           // console.log(temp)
           this.refreshTexts=true
          //  this.updateRTData()
           })

      

  
   this.redraw()
   this.subscribe()

 },
 updateViewData(){
  if(this.view && this.view.data){
          this.viewData=Object.assign({},this.view.data)
          this.api='updateSLDView'
          this.viewName=this.view.name
          this.viewId=this.view.view_id
          this.shapes=this.viewData.shapes?[...this.viewData.shapes]:[]
          this.lines=this.viewData.lines?[...this.viewData.lines]:[]
          this.texts=this.viewData.texts?[...this.viewData.texts]:[]
          this.images=this.viewData.images?[...this.viewData.images]:[]
          this.zoom=this.viewData.zoom?this.viewData.zoom:600
          if(Array.isArray(this.zoom)){
            this.zoom=this.zoom[1]
          }
          this.verticalScroll=this.viewData.verticalScroll?this.viewData.verticalScroll:10
          this.horizontalScroll=this.viewData.horizontalScroll?this.viewData.horizontalScroll:10
          
      }
 },
 updateRTData() {
  // console.log('updateRTData')
  // console.log(this.stream.connected)
  if(!this.refreshTexts){
    return
  }
  // console.log('executing')
  this.refreshTexts=false
 // Update the data
 let data=this.texts
 const svgContainer=this.svgContainer
 let edit=this.edit;
 let deltaX=1;
 let deltaY=1;
 let dragHandler = d3.drag()
.on("start", function (event) {
 if(edit){
 // console.log(event.x)
 var current = d3.select(this);
 // console.log(current.attr("x1"),event.x)
 
 
 
   deltaX = current.attr("x")?(parseFloat(current.attr("x")) - event.x):1;
   deltaY = current.attr("y")?(parseFloat(current.attr("y")) - event.y):1;
   // console.log(deltaX,deltaY)
}
})
.on("drag", function (event,l) {
 if(edit){
 // console.log( event.x , deltaX)

   d3.select(this)
       .attr("x", event.x + deltaX)
       .attr("y", event.y + deltaY);
       l['x']=event.x + deltaX
       l['y']=event.y + deltaY

}
   
});
 // Select the text elements and bind data
 const textSelection = svgContainer.selectAll("text")
   .data(data);
   // Exit: Remove any extra elements
   textSelection.exit().remove();
   let streamData=this.streamData
   let usageData=this.usageData
   let evaluateRule=this.evaluateRule
   let unitMap=this.unitMap
   let defaultColor=this.$store.getters.getColorPalette().accentCode
   let processTextData=function (d){
     // console.log(d)
     let elem=d3.select(this)
     //  console.log(elem)
     if(d.size && !isNaN(d.size)){
      elem.style("font-size", (d.size*12).toString()+"px")
     }else{

       elem.style("font-size", "12px")
     }
     
     
     if(d.type && d.type!='text'){
       if(d['param_id']){
       let value=streamData[d['param_id']]
       if(d.type=='usage'){
        let timeFrame=d['usage_timeframe']?d['usage_timeframe']:'MTD'
        value=usageData[d['param_id']]?usageData[d['param_id']][timeFrame]:'-'
        // console.log(usageData)
       }
       if(isNaN(value)){
         value='-'
        }
        if(d['showUnit'] && unitMap[d['param_id']]){
         value+=' '+unitMap[d['param_id']]
        }
       if(d['ruleBased']){
         let final=evaluateRule(value,d.statusRules,d)
         // final={...d,...final}
         //  let temp={...d}
         elem.style('stroke',final['color']?final['color']:defaultColor)
         if(d['showLabel']){
           value=final['label']
          }
          // console.log(value)

      }else{
        elem.style('stroke',(d)=>d['color']?d['color']:defaultColor)
      }
      elem.text(value)
      //  temp['color']=final['color']
      //  temp['label']=final['label']
      //  console.log('evalRule',temp)
      //  return temp
      
    }else{
      elem.style('stroke',(d)=>d['color']?d['color']:defaultColor)
      elem.text('-')
    }
  }else{
    elem.style('stroke',(d)=>d['color']?d['color']:defaultColor)
    elem.text(d['label'])
  }
  return d
  
}

// let labelFunc=d=>{
//   // console.log(d)
//   switch(d.type){
//     case 'text':
//       return d['label']
//       case 'rtd':
//         return streamData[d['param_id']]?streamData[d['param_id']]:'-'
//         case 'rtc':
//           return streamData[d['param_id']]?streamData[d['param_id']]:'-'
//         default:
//           //  console.log(d)
//           return d['label']
//         }
        
//       }
      let textContextMenu=this.textContextMenu
      // Enter: Create new elements for new data points
      const newTextElements = textSelection.enter()
      .append("text")
      .attr("x", (d) => d['x']?d['x']:10)
      .attr("y", (d) => d['y']?d['y']:10)
      .each(processTextData)
      //  .style('stroke',(d)=>d['color']?d['color']:this.$store.getters.getColorPalette().accentCode)
      //  .style("font-size", "12px")
      //  .text(labelFunc)
   .on("contextmenu", function (event) {
     // d3.event.preventDefault();
     // react on right-clicking
     //  console.log(event)
     const e = newTextElements.nodes();
     const index = e.indexOf(this);
     //  console.log(index)
      event.preventDefault();
// console.log('showTextContextMenu called'); 
textContextMenu.show = true;
textContextMenu.x = event.clientX;
textContextMenu.y = event.clientY;
textContextMenu.index = index; 
   });
   
   newTextElements.call(dragHandler)
   // Update: Update text content for existing elements
  //  textSelection.each(processTextData)
//  textSelection.text(labelFunc);

 // Merge the enter and update selections for further processing
 const allTextElements = newTextElements.merge(textSelection);

 // Apply transitions for a smoother update
 allTextElements
   .transition()
   .duration(500) // Transition duration in milliseconds
   .attr("x", (d) => d['x']?d['x']:10)
   .attr("y", (d) => d['y']?d['y']:10)
   .each(processTextData);
  //  .text(labelFunc);

},
 redraw(){
  // console.log('redraw called')
   const svgContainer = d3.select(this.$refs.playground);
   this.svgContainer=svgContainer
   svgContainer.selectAll("*").remove()
   let edit=this.edit
   
   let imageContextMenu=this.imageContextMenu
   this.images.forEach((l,index)=>{
    let deltaX=1
 let deltaY=1
    let i=svgContainer.append("image")
       .attr("x", l['x'])
       .attr("y", l['y'])
       .attr("width", l['width'])
       .attr("height", l['height'])
       .attr('href', l['image'])
       .style('stroke',this.$store.getters.getColorPalette().accentCode)
       .on("contextmenu", function (event) {
           // d3.event.preventDefault();
          // react on right-clicking
         //  console.log(event)
         //  console.log(i)
          event.preventDefault();
 //  console.log('showLineContextMenu called'); 
 imageContextMenu.show = true;
 imageContextMenu.x = event.clientX;
 imageContextMenu.y = event.clientY;
 imageContextMenu.index = index; 
       });

       let dragHandler = d3.drag()
   .on("start", function (event) {
     if(edit){
     var current = d3.select(this);
       deltaX = current.attr("x")?(parseFloat(current.attr("x")) - event.x):1;
       deltaY = current.attr("y")?(parseFloat(current.attr("y")) - event.y):1;
     
     
       // deltaX = current.attr("x1")?(parseFloat(current.attr("x1")) - event.x):1;
       // deltaY = current.attr("y1")?(parseFloat(current.attr("y1")) - event.y):1;
       // console.log(deltaX,deltaY)
   }
   })
   .on("drag", function (event) {
     if(edit){
     // console.log( event.x , deltaX)
       d3.select(this)
           .attr("x", event.x + deltaX)
           .attr("y", event.y + deltaY);
           l['x']=event.x + deltaX
           l['y']=event.y + deltaY
     
   }
       
   })
   i.call(dragHandler)

   });
 
   for(let index in this.shapes){
     let i=this.shapes[index]
     let deltaX=1
 let deltaY=1
     let dragHandler = d3.drag()
   .on("start", function (event) {
     // console.log(event.x)
     if(edit){

     
     var current = d3.select(this);
     // console.log(current.attr("x"))
       deltaX = current.attr("x")?(parseFloat(current.attr("x")) - event.x):1;
       deltaY = current.attr("y")?(parseFloat(current.attr("y")) - event.y):1;
       // console.log(deltaX,deltaY)
     }
   })
   .on("drag", function (event) {
     if(edit){
     // console.log( event.x , deltaX)
       d3.select(this)
           .attr("x", event.x + deltaX)
           .attr("y", event.y + deltaY);
           i['x']=event.x + deltaX
           i['y']=event.y + deltaY
     }
   });
     let shapeContextMenu=this.shapeContextMenu;
     d3.xml(i.path).then(svgDoc => {
       const svgElement = svgDoc.documentElement;
       if(!i.x){
         i.x=1
         i.translateX=0
       }
       if(!i.y){
         i.y=1
         i.translateY=10
       }
      //  let rect=svgContainer.append('rect')
       let shape=d3.select(svgElement)
       if(!shape){
        return
       }
      //  console.log(i.width*(i.zoomX?i.zoomX:1))
       shape.attr("width", i.width*(i.zoomX?i.zoomX:1))
       shape.attr("height", i.height*(i.zoomY?i.zoomY:1))
       shape.attr("x", parseFloat(i.x))
       shape.attr("y", parseFloat(i.y))
       shape.style("stroke",this.$store.getters.getColorPalette().accentCode)
       shape.style('fill',this.$store.getters.getColorPalette().accentCode)
      //  let transfrm=d3.svg.transform().rotate(-45).translate(i.translateX, i.translateY);
      //  shape.attr("transform", transfrm )
       shape.attr("transform", `translate(${i.translateX}, ${i.translateY})` )

      //  shape.attr("transform", "scale(3,3) )" )
      //  shape.attr("transform", `scale(${i.zoomX?i.zoomX:2}, ${i.zoomY?i.zoomY:2})` )
      // rect.attr("width", i.width*(i.zoomX?i.zoomX:1))
      //  rect.attr("height", i.height*(i.zoomY?i.zoomY:1))
      //  rect.attr("x", parseFloat(i.x))
      //  rect.attr("y", parseFloat(i.y))
      //  rect.style('fill','#F000000F')
      //  rect.attr("transform", `translate(${i.translateX}, ${i.translateY})` )
       shape.on("contextmenu", function (event) {
           // d3.event.preventDefault();
         //  console.log(event)
          event.preventDefault();
          shapeContextMenu.show = true;
          shapeContextMenu.x = event.clientX;
          shapeContextMenu.y = event.clientY;
          shapeContextMenu.index = index; 
       });
       // svgElement.setAttribute("width", i.width);
       //   svgElement.setAttribute("height", i.height);
       //   svgElement.setAttribute("x", parseFloat(i.x));
       //   svgElement.setAttribute("y", parseFloat(i.y));
       //   svgElement.setAttribute("transform", `translate(${i.translateX}, ${i.translateY})` );
         
      //  svgContainer.append(rect)
        //  rect.node().append(svgElement);
         svgContainer.node().append(svgElement);
        //  let shp=svgContainer.append('g')
        //  shp.node().append(svgElement);
        //  dragHandler(rect)
         dragHandler(shape)
         // d3.select(svgElement).call(dragHandler);
         
     });
   }
let lineContextMenu=this.lineContextMenu
this.lines.forEach((l,index)=>{
 

     let dasharray="0,0"
     switch(l.type){
       case 'line':
         dasharray=l['stroke-dasharray']?l['stroke-dasharray']:'0,0'
         break;
       case 'dotted':
         dasharray=l['stroke-dasharray']?l['stroke-dasharray']:'5,5'
         break;
       default:
         dasharray='0,0'

     }
     svgContainer.append('defs')
  .append('marker')
  .attr('id', 'arrow')
  .attr('viewBox', [0, 0, 12, 12])
  .attr('refX', 6)
  .attr('refY',6)
  .attr('markerWidth', 15)
  .attr('markerHeight', 15)
  .attr('orient', 'auto-start-reverse')
  .append('path')
  .attr('d', "M2,2 L10,6 L2,10 L6,6 L2,2")
  .attr('stroke', this.$store.getters.getColorPalette().accentCode);
     // console.log(l)
     let i=svgContainer.append("line")
       .attr("x1", l['x1'])
       .attr("y1", l['y1'])
       .attr("x2", l['x2'])
       .attr("y2", l['y2']).style("stroke", "black").style('stroke-width',l['strokeWidth']?l['strokeWidth']:2)
       .attr('marker-end', l['arrow']?'url(#arrow)':'')
       .style('stroke-linecap',"round")
       .style('stroke-dasharray',dasharray)
       .style('stroke',this.$store.getters.getColorPalette().accentCode)
       .on("contextmenu", function (event) {
           // d3.event.preventDefault();
          // react on right-clicking
         //  console.log(event)
         //  console.log(i)
          event.preventDefault();
 //  console.log('showLineContextMenu called'); 
 lineContextMenu.show = true;
 lineContextMenu.x = event.clientX;
 lineContextMenu.y = event.clientY;
 lineContextMenu.index = index; 
       });
       let deltaX=1
 let deltaY=1
 let d='start'
     let dragHandler = d3.drag()
   .on("start", function (event) {
     if(edit){
     // console.log(event.x)
     var current = d3.select(this);
     // console.log(current.attr("x1"),event.x)
     
     if(Math.abs(parseFloat(current.attr("x1")) - event.x)<=Math.abs(parseFloat(current.attr("x2")) - event.x)){
       d='start'
       deltaX = current.attr("x1")?(parseFloat(current.attr("x1")) - event.x):1;
       deltaY = current.attr("y1")?(parseFloat(current.attr("y1")) - event.y):1;
     }else{
       d='end'
       deltaX = current.attr("x2")?(parseFloat(current.attr("x2")) - event.x):1;
       deltaY = current.attr("y2")?(parseFloat(current.attr("y2")) - event.y):1;
     }
     
       // deltaX = current.attr("x1")?(parseFloat(current.attr("x1")) - event.x):1;
       // deltaY = current.attr("y1")?(parseFloat(current.attr("y1")) - event.y):1;
       // console.log(deltaX,deltaY)
   }
   })
   .on("drag", function (event) {
     if(edit){
     // console.log( event.x , deltaX)
     if(d=='start'){
       d3.select(this)
           .attr("x1", event.x + deltaX)
           .attr("y1", event.y + deltaY);
           l['x1']=event.x + deltaX
           l['y1']=event.y + deltaY
     }else{
       d3.select(this)
           .attr("x2", event.x + deltaX)
           .attr("y2", event.y + deltaY);
           l['x2']=event.x + deltaX
           l['y2']=event.y + deltaY
     }
   }
       
   });
   i.call(dragHandler)
});

this.refreshTexts=true
this.updateRTData()

 },

emitImageList(value){
  //  console.log('Received emitted value:', value);
   // this.imageList=value
   // this.updateSelectedItems();
   this.shapes=this.shapes.concat(value)
   // console.log( this.imageList,"emit image")
   // localStorage.setItem('imageList', JSON.stringify(this.imageList));
   this.init()

 },

handleSelectedLines(selectedLines) {
  console.log('Received selected lines:', selectedLines);
 //  this.selectedLines=selectedLines
  this.lines=this.lines.concat(selectedLines)
 //  this.updateSelectedItems();
 //  localStorage.setItem('selectedLines', JSON.stringify(selectedLines)); 
 this.redraw()
 },
 addTextBox(textBox) {
  // console.log('Received TextBox:', textBox);

  this.texts=this.texts.concat(textBox)
  this.subscribe()
 this.redraw()
 },
 imageUpload(img){
  let temp={...img, x:10,y:20}
  
  this.images=this.images.concat(temp)
  this.redraw()
 },


//  showContextMenu(event, index) {
//    event.preventDefault();
//     console.log('showContextMenu called'); 
//      this.shapeContextMenu.show = true;
//      this.shapeContextMenu.x = event.clientX;
//      this.shapeContextMenu.y = event.clientY;
//      this.shapeContextMenu.index = index;
//  },


//  showLineContextMenu(event, lineIndex) {
//    event.preventDefault();
//     console.log('showLineContextMenu called'); 
//    this.lineContextMenu.show = true;
//    this.lineContextMenu.x = event.clientX;
//    this.lineContextMenu.y = event.clientY;
//    this.lineContextMenu.index = lineIndex; 
//  },


resizeShape(index) {
this.resizeDialog=true
     this.resizeIndex=index;
     this.size=this.shapes[index]['zoomX']?this.shapes[index]['zoomX']:1
     this.redraw()
   },
updateSize(){
this.shapes[this.resizeIndex]['zoomX']=this.size
this.shapes[this.resizeIndex]['zoomY']=this.size
this.redraw()

},
removeShape(index) {
     this.shapes.splice(index, 1);
     this.shapeContextMenu.show = false;
     this.redraw()
   },

   removeImage(index) {
   this.images.splice(index, 1);
   // this.updateSelectedItems();
   // localStorage.setItem('selectedLines', JSON.stringify(this.selectedLines));
   this.imageContextMenu.show = false;
   this.redraw()
 },
 removeLine(index) {
   this.lines.splice(index, 1);
   // this.updateSelectedItems();
   // localStorage.setItem('selectedLines', JSON.stringify(this.selectedLines));
   this.lineContextMenu.show = false;
   this.redraw()
 },
 removeText(index) {
   this.texts.splice(index, 1);
   // this.updateSelectedItems();
   // localStorage.setItem('selectedLines', JSON.stringify(this.selectedLines));
   this.textContextMenu.show = false;
   this.redraw()
 },
 editText(index) {
   this.$refs.textForm.validate();
   if(this.textValid){
     this.textDialog = false;
     this.texts[index]['label']=this.textLabel
   this.redraw()
   }
   // this.updateSelectedItems();
   // localStorage.setItem('selectedLines', JSON.stringify(this.selectedLines));
   
 },
 updateTextBox(payload){
   this.texts[this.textContextMenu.index]=payload
   this.paramMeta=payload
   this.refreshTexts=true

 },
 updateParam(val){
   console.log(val)
   this.texts[this.textContextMenu.index]['param_id']=val
   this.subscribe()
   this.redraw()
 },
 save(){
          this.loading=true
          this.viewData={
              zoom:this.zoom,
              verticalScroll:this.verticalScroll,
              horizontalScroll:this.horizontalScroll,
              lines:this.lines,
              shapes:this.shapes,
              texts:this.texts,
              images:this.images
          }
          let payload={
              view_id:this.viewId,
              view_type:'sldView',
              data:this.viewData,
              name:this.viewName,
              // bin_data:{images:this.images}
          }
          const form = new FormData();
          const json = JSON.stringify(payload);
          // const blob = new Blob([json], {
          //   type: 'application/json'
          // });

// Append text fields to the form
form.append('document', json);

// `file` can either be a Buffer or a Stream
// don't forget the 3rd argument, the file name, when appending a file
// form.append('productImage', file, 'stickers.jpg');
          axios.post(this.$store.state.api+this.api,form,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }})
                  .then(response=>{
                      if(response.data.status=='success'){
                          this.loading=false
                          this.edit=false
                          this.$store.dispatch('refreshViews')
                          this.init()
                          
                          
                      }else{
                          this.loading=false
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                  }).catch(err=>{
                      this.loading=false
                      this.info=err
                      this.showDismissibleAlert=true
                      
                  })
      },
      deleteView(){
          let payload={
              
              view_id:this.viewId
          }
          this.loading=true
          axios.post(this.$store.state.api+'deleteView',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }})
                  .then(response=>{
                      if(response.data.status=='success'){
                          this.loading=false
                          this.edit=false
                          this.$store.dispatch('refreshViews')
                          
                      }else{
                          this.loading=false
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                  }).catch(err=>{
                      this.loading=false
                      this.info=err
                      this.showDismissibleAlert=true
                      
                  })
      },
      setAsDefault(){
          this.loading=true
          let payload={view_id:this.viewId}
          axios.post(this.$store.state.api+'updateDashboardView',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }})
                  .then(response=>{
                      if(response.data.status=='success'){
                          this.loading=false
                          this.edit=false
                          //this.$store.dispatch('refreshViews')
                          
                      }else{
                          this.loading=false
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                  }).catch(err=>{
                      this.loading=false
                      this.info=err
                      this.showDismissibleAlert=true
                      
                  })

      },
      discard(){
          this.updateViewData()
          // this.subscribe()
          this.edit=false


          
      },

},
watch:{
 edit(){
   this.init()
 },
 view(){
            // console.log('view update')
              this.updateViewData()
              this.init()
          },
size(){
  this.updateSize()
},
// verticalScroll(){
//   this.zoom[1]=this.verticalScroll
// }
}
}
</script>

<style scoped>
.svgContainer {
  height: 70%;
  width: 100%;
  max-height:90%;
  /* border:2px solid #000; */
  /* overflow: scroll; */
 }
.scrollable {
width: 100%;
height: 100%;
overflow: scroll;
/* background: rgb(255,0,0,0.2); */

display: grid;
}

.slider-container {
height: 300px; /* Set the height of the slider container */
display: flex;
align-items: center;
}
.slider {
-webkit-appearance: none; /* Override default CSS styles */
appearance: none;
width: 10px; /* Set the width of the slider */
height: 100%; /* Set the height of the slider to fill the container */
background: #d3d3d3; /* Gray background */
outline: none; /* Remove default focus style */
opacity: 0.7; /* Set transparency */
-webkit-transition: .2s; /* 0.2 second transition on hover */
transition: opacity .2s;
/* Rotate the slider to be vertical */
transform: rotate(270deg);
/* Reverse direction so that minimum is at the top */
writing-mode: bt-lr;
/* Adjust orientation so that slider thumb goes from top to bottom */
transform-origin: top;
}

/*  
.sheet-container {
 background-color:white;
     background-image: linear-gradient(to right, transparent 1px, rgba(0, 0, 0, 0.1) 1px),
                     linear-gradient(to bottom, transparent 1px, rgba(0, 0, 0, 0.1) 1px);
 box-shadow: 0px 0px 10px rgba(65, 63, 63, 0.1);
 padding: 20px;
 border-radius: 8px;
 position: relative;
}
.image-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
 user-select: none;
  transform: translateZ(0);
}
.label-input {
    margin-left: -79px;
   margin-top: 98px;

}
.resizable {
 resize: both; 
 overflow: auto;
}
.custom-image {
  width: 100px;
  height: auto; 
} */
/* .stretch-up {
 cursor: n-resize;
}

.stretch-down {
 cursor: s-resize;
}

.stretch-left {
 cursor: w-resize;
}

.stretch-right {
 cursor: e-resize;
} */
</style>
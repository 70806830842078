import moment from 'moment'

export default{

    getFromDate(timeFrame='mtd'){
        timeFrame=timeFrame.toString().toLowerCase()
        let from_date=moment().subtract(1,'days')
        switch(timeFrame){

            case 'yesterday':
                from_date = moment().subtract(1,'days');
                  break;
            case 'mtd':
                from_date=moment().startOf('month') 
                  break;
            case 'ytd':
                  from_date=moment().startOf('year')
                  break;
            default:
                if(isNaN(timeFrame)){
                    from_date=moment().subtract(1,'days')
                }else{
                    from_date=moment().subtract(parseInt(timeFrame),'days')
                }

        }
        // console.log(from_date)
        return from_date
    },
    toReadableTimeFromEpoch(timestamp, format='dddd, MMMM Do YYYY, h:mm:ss a') {
        if (isNaN(timestamp)) {
          return null
        }
        return moment.unix(Number(timestamp)).format(format)
  
      },
      toReadableTime(timestamp, format='YYYY-MM-DD, HH:mm:ss (dddd, MMMM Do )') {
        if(timestamp)
        return moment(timestamp).format(format)
      return '-'
  
      },
    checkCondition(value,operator,operand){
        switch(operator){
            case '>':
                return Number(value)>Number(operand)
            case '<':
                return Number(value)<Number(operand)
            case '==':
                return Number(value)==Number(operand)
            case '!=':
                return Number(value)!=Number(operand)
            case '>=':
                return Number(value)>=Number(operand)
            case '<=':
                return Number(value)<=Number(operand)
            default:
                return false

        }

    }
}
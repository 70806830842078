<template>
        <v-dialog v-model="dialog"  hide-overlay transition="dialog-bottom-transition">
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="valid">
            <v-card   :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" elevation="4">
                <v-card-title class="headline">{{$store.getters.getTextMap().energy_usage_view_page}}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>  
                                <v-row>  
                                <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                     :dark="$store.getters.getColorPalette().isDark"
                                      :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="workspace"
                                        :items="workspaceOptions"
                                        :label="$store.getters.getTextMap().workspace"
                                        outlined          
                                        item-text="label"
                                        item-value="value"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                      :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="machine"
                                        :items="machineOptions"
                                        :label="$store.getters.getTextMap().machine"
                                        outlined
                                        item-text="label"
                                        item-value="value"
                                    ></v-select>
                                </v-col>
                            </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select
                                       :dark="$store.getters.getColorPalette().isDark"
                                          :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.active_energy"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().energy_parameters_kwh"  
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            clearable
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col> 
                                    <v-col>
                                        <v-select
                                         :dark="$store.getters.getColorPalette().isDark"
                                         :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.apparent_energy"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().apparent_energy_KVAh"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            clearable
                                            small-chips
                                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col>   
                                    <v-col>
                                        <v-select
                                          :dark="$store.getters.getColorPalette().isDark"
                                           :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.pf"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().power_factor"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>    
                                    <v-col>
                                        <v-select
                                       :dark="$store.getters.getColorPalette().isDark"
                                         :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.active_power"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().active_power"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>    
                                    <v-col>
                                        <v-select
                                          :dark="$store.getters.getColorPalette().isDark"
                                          :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.reactive_power"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().reactive_power"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>  
                                  <v-col>
                                        <v-select
                                          :dark="$store.getters.getColorPalette().isDark"
                                          :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.apparent_power"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().apparent_power"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>   
                                <v-col>
                                        <v-text-field
                                        :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.tariff_rate"
                                            :rules="numberRules"
                                            :label="$store.getters.getTextMap().tarrif_rate"
                                            required
                                        ></v-text-field>
                                    </v-col>   
                            </v-row>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">{{$store.getters.getTextMap().submit}}
</v-btn>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">{{$store.getters.getTextMap().cancel}}
</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import axios from 'axios'
export default {
    mounted(){
        this.dialog=true
    },
    name:'GetEnergyUsageViewParameters',
    props:['show'],
    data(){
        return {
            valid:false,
            loading:false,
            dialog:false,
            paramOptions1:[],
            machineOptions:[],
                workspace:null,
                machine:null,
                listRules:[
                        v=> !!v || 'Required',
                        v => (v && v.length>0) || 'You have to select something',
                ],
            form:{},
            objectRules:[
                v => !!v || 'Required',
              ],
              numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 )|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
    },
    methods:{
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams1(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions1=[]
                            for(let i of response.data.params){
                                this.paramOptions1.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.valid){
                this.loading=true
                this.$emit('value',this.form)
                this.$emit('close')
            }

        },
        onReset(){
            this.dialog=false,
            this.loading=false
            this.$emit('close')
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
            
        },
        machine(){
            this.getParams1()
        },
    }
}
</script>
<template>
    <v-dialog v-model="dialog" max-width="390">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined >
                {{$store.getters.getTextMap().add_trigger}}

                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                    mdi-plus-circle-outline
                </v-icon> 
            </v-btn>
        </template>
      <AttachTriggerToParam :parameter="parameter" v-on:close="dialog=false" />
    </v-dialog>
</template>
<script>
import AttachTriggerToParam from '@/components/crud_components/AttachTriggerToParam'
export default {
    name:'AttachTriggerToParamModal',
    props:['parameter'],
    components:{
        AttachTriggerToParam
    },
    data(){return {
        dialog:false
    }}
}
</script>
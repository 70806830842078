<template>

    <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row>
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Process Status Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Process Status Widget</span>
            </v-col>
        </v-row> -->
        <!-- widget form -->
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                <v-card-title v-if="edit" class="sub-heading">{{statusFor && statusFor==='machine'?$store.getters.getTextMap().edit_machine_Status_widget:$store.getters.getTextMap().edit_process_Status_widget}}</v-card-title>
                <v-card-title v-else class="sub-heading">{{statusFor && statusFor==='machine'?$store.getters.getTextMap().add_machine_Status_widget:$store.getters.getTextMap().add_process_Status_widget}}</v-card-title> 
                <v-row>
                    <!--   Label -->
                    <v-col>
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-text-field 
                            dense 
                            outlined 
                            v-if="!loading" 
                            v-model="widgetForm.label" 
                            :counter="30"
                            :rules="[rules.required]" 
                            :label="$store.getters.getTextMap().widget_label">
                        </v-text-field>
                    </v-col>
                    <!-- widget width -->
                    <v-col>
                        <v-select 
                            dense outlined :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.width" :items="widthOptions"  :label="$store.getters.getTextMap().widget_width" item-text="label"
                            item-value="value" :rules="[rules.required]">
                        </v-select>
                    </v-col>
                    <!-- widget swq -->
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <!-- widget height -->
                    <v-col>
                        <v-select 
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputTextColor"
                            :menu-props="{dark:$store.getters.getColorPalette().isDark}" 
                            v-if="!loading"
                            v-model="widgetForm.height" :items="heightOptions" item-text="label" item-value="value"
                            :label="$store.getters.getTextMap().widget_height" :rules="[rules.required]" dense outlined>
                        </v-select>
                    </v-col>
                    <v-col >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                </v-row>
            </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
                <!-- <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background1ColorCode" flat outlined> -->
                    <v-row class="px-3">
                        <v-card-title class="sub-heading">{{statusFor==='machine'?$store.getters.getTextMap().choose_machine:$store.getters.getTextMap().choose_process}}</v-card-title>
                        <v-spacer/>
                        <v-col cols="auto" class="d-flex" align="right">
                    <v-radio-group v-model="statusFor" row :dark="$store.getters.getColorPalette().isDark">
                        <v-radio :label="$store.getters.getTextMap().status_for_process" value="process"></v-radio>
                        <v-radio :label="$store.getters.getTextMap().status_for_machine"  value="machine"></v-radio>
                    </v-radio-group>
                </v-col>
                    </v-row>
                    <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                    <v-container fluid>
                        <v-row v-if="statusFor==='machine'">
                            <!-- {{Machine Options}} -->
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field 
                                    dense 
                                    outlined 
                                    v-if="!loading" 
                                    v-model="widgetForm.meta.header_label" 
                                    :counter="30"
                                    :rules="[rules.required]" 
                                    :label="$store.getters.getTextMap().table_header_label">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    outlined   
                                    dense       
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="widgetForm.meta.machine_ids"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    multiple
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <!-- {{Process Options}} -->
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field 
                                    dense 
                                    outlined 
                                    v-if="!loading" 
                                    v-model="widgetForm.meta.header_label" 
                                    :counter="30"
                                    :rules="[rules.required]" 
                                    :label="$store.getters.getTextMap().table_header_label">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-autocomplete 
                                    dense outlined 
                                    v-model="widgetForm.meta.process_ids" 
                                    v-if="!loading" 
                                    :counter="15"
                                    :rules="[rules.required]" 
                                    :items="processOptions" 
                                    item-text="label" 
                                    multiple
                                    item-value="value"
                                    :label="$store.getters.getTextMap().process">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                <!-- </v-card> -->
            </v-card>
            <v-row no-gutters>
                <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
    import InfoAlert from '@/components/InfoAlert';
    import FormRules from '@/utillities/FormRules'
    import axios from 'axios';
    export default {
        name: 'AddProcessStatusBasedWidget',
        props: ['widget', 'seq'],
        components: {
            InfoAlert
        },
        data() {
            return {
                edit: false,
                widgetValid: false,
                api: 'createWidgetForView',
                showDismissibleAlert: false,
                info: '',
                loading: false,
                rules: FormRules.rules,
                widthOptions: [
                    { label: 'Full screen', value: 12 },
                    { label: 'Half screen', value: 6 },
                    { label: '1/3 screen', value: 4 },
                    { label: 'Quarter screen', value: 3 },
                    {label:'3/4 screen', value:9},
                    { label: 'Auto', value: null }
                ],
                heightOptions: [
                    { label: 'Fixed', value: 'fixed' },
                    { label: 'Flex', value: 'flex' },
                ],
                workspace:null,
                processOptions: [],
                machineOptions:[],
                meta: {},
                statusFor:'process',
                widgetForm: {
                    meta:{
                        statusFor:'process',
                        process_ids: [],
                        machine_ids: [],
                    }
                },
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            paramOptions() {
                let op = []
                if (this.logbookID && this.logbookID.meta) {
                    for (let i of Object.keys(this.logbookID.meta)) {
                        op.push({label: i,value: i})
                    }
                }
                return op
            },
            workspaceOptions(){
                let op=[]
                let g=this.$store.state.workspaces
                for(let i of g){
                    op.push( {value:i.workspace_id, label: i.name })
                }
                return op
            },
        },
        methods: {
            init() {
                if (this.widget && this.widget.widget_id && this.widget.view_id) {
                    this.edit = true
                    this.widgetForm = Object.assign({}, this.widget)
                    this.api = 'updateWidget'
                    this.widgetLabel = this.widgetForm.label
                    if (!(this.widgetForm.meta)) {
                        this.widgetForm.meta = {statusFor:'process', process_ids: [],machine_ids: [] }
                        this.statusFor="process"
                    }else{
                        this.statusFor=this.widgetForm.meta.statusFor?this.widgetForm.meta.statusFor:"process"
                    }
                } else {
                    if (this.widget.view_id) {
                        this.api = 'createWidgetForView'
                        this.widgetForm = Object.assign({}, this.widget)
                        this.widgetForm.meta = {statusFor:'process', process_ids: [],machine_ids: [] }
                        this.widgetForm.seq = this.seq ? this.seq : 0
                    }
                    this.statusFor="process"
                }
                this.getProcesses()
            },
            getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesForWhichRulesAreConfigured", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let machines=response.data.machines
                        for(let i of machines){
                            if(i && i.name){
                                op.push({value:i.machine_id, label: i.name })
                            }else{
                                op.push({value:i.machine_id, label: i.machine_id })
                            }
                        }   
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
                this.machineOptions=op
            },
            getProcesses() {
                let op=[]
                axios.get(this.$store.state.api + "getProcessesForWhichRulesAreConfigured",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let processes=response.data.data
                        for(let i of processes){
                            if(i && i.name){
                                op.push({value:i.process_id, label: i.name })
                            }else{
                                op.push({value:i.process_id, label: i.process_id })
                            }
                        }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error)
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
                this.processOptions=op
            },
            onSubmit() {
                this.$refs.widgetForm.validate()
                if (this.widgetValid) {
                    this.widgetForm.meta['statusFor']=this.statusFor
                    axios.post(this.$store.state.api + this.api, this.widgetForm, {
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.jwt
                        }
                    }).then(res => {
                        if (res.data.status === 'success') {
                            this.info = "Widget Created";
                            this.loading = false
                            this.showDismissibleAlert = true
                            this.$refs.widgetForm.reset()
                            this.$emit('success')
                            this.$emit('close')
                        }
                    })
                }
            },
            onClose() {
                this.$refs.widgetForm.reset()
            }
        },
        watch: {
            widget() {
                this.init()
            },
            workspace(){
                this.getMachineList()
            },
        }
    }
</script>
<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">  
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
        <v-list :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-list-item @click="update=true">
              <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="deleteWidget">
              <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
              <v-list-item-icon>
                 
                <v-icon color="red" small>mdi-trash-can</v-icon>
               
              </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{widget.label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <AddHeatMapWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
        </v-card>
      </v-dialog>
      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
      <div v-if="widget && widget.meta &&  (widget.meta.formType==='paramForm' || widget.meta.data.length>0)">
        <div v-if="widget.meta.data && widget.meta.data.length>0" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'175px',}:{}]" >
            <div>
                <SingleParamUsageHeatMap :param="getparam" :label="getLabel" :height="widget.height" :width="widget.width"/>
            </div>
        </div>
        <div v-else  :style="[widget.height==='fixed' ? {'height':'150px','text-align':'center'}:{'text-align':'center'}]">
          <v-icon small>mdi-hail</v-icon>
          <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
      </div>
      <div v-else>
        <div v-if="widget.meta.equationData" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'175px',}:{}]" >
            <div>
                <ParamEquationBasedHeatMap :eq_id="widget.meta.equationData" :label="widget.label" :height="widget.height" :width="widget.width"/>
            </div>
        </div>
        <div v-else  :style="[widget.height==='fixed' ? {'height':'150px','text-align':'center'}:{'text-align':'center'}]">
          <v-icon small>mdi-hail</v-icon>
          <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
      </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddHeatMapWidget from '@/components/crud_components/widgets/AddHeatMapWidget'
import SingleParamUsageHeatMap from '@/components/graphs/SingleParamUsageHeatMap'
import ParamEquationBasedHeatMap from '@/components/graphs/ParamEquationBasedHeatMap'
import ColorUtility from '@/utillities/ColorUtility'
export default {
    name:'HeatMapWidget',
    props:['widget','filter','edit'],
    components:{
        AddHeatMapWidget,
        SingleParamUsageHeatMap,
        ParamEquationBasedHeatMap,
        InfoAlert,
        // DeleteConfirmation
    },
    data(){
        return {
           showDialog:false,
           colorCode:ColorUtility.colorTypesCodeArray,
            x:0,
            y:0,
            showMenu:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            update:false,
        }
    },
    mounted(){

    },
    computed:{
        getparam(){
            return this.widget.meta.data[0].params
        },
        getLabel(){
            return this.widget.label
        },
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
      },
        deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    // watch:{
    //     widget:{
    //         handler(){
    //             this.getData()
    //         },
    //         deep:true
    //     }
    // }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
</style>
<template>
    <div class="text-center">
        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="dialog = !dialog">
            {{$store.getters.getTextMap().add_machine_status_rules}}
<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-list-status</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title> {{$store.getters.getTextMap().add_machine_status_rules}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}
</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddMachineStatusRule :machine="machine" v-on:close="onClose"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import AddMachineStatusRule from '@/components/crud_components/AddMachineStatusRule'
export default {
    name:'AddMachineStatusRuleModal',
    props:['machine'],
    components:{AddMachineStatusRule},
    data(){
        return {
            dialog:false
        }
      },
    methods:{
        onClose(){
            // this.dialog=false
            this.$emit('close')
        }
    }
}
</script>
  
<template>
    <div  @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            @click="dialog=true"
            v-if="$store.state.settingMode && edit"
        >
        <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-list-item @click="showEdit=true">
                <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                <v-list-item-icon>
                    <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="deleteWidget" >
                <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                <v-list-item-icon>
                     
                    <v-icon color="red" small>mdi-trash-can</v-icon>

                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-menu>
    <v-dialog
      v-model="showEdit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showEdit = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-toolbar-title>{{this.widget.label}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items><v-btn :dark="$store.getters.getColorPalette().isDark" text @click="showEdit = false">{{$store.getters.getTextMap().close}}</v-btn></v-toolbar-items>
            </v-toolbar>
            <AddMachineWidget :widget="widget" v-on:success="$emit('update')" v-on:close="showEdit=false"/>
        </v-card>
    </v-dialog>
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        
                <span v-if="machine" :style="{color:$store.getters.getColorPalette().accentCode,'background-color':$store.getters.getColorPalette().backgroundColor2Code,'height':height,}">
                    
                    <!-- <v-card-subtitle> -->
                        <v-skeleton-loader
                        v-if="loading"
                            class="mx-auto"
                            type="text"
                            ></v-skeleton-loader>
                            
                            <Machine class="mx-1 my-1" v-else :hideDelete="true" :machine="machine" />
                
            </span>
            <span v-else :style="[{'height':height}]" >
                <v-icon small>mdi-hail</v-icon>
                <h5>{{$store.getters.getTextMap().right_click_to_configure}}</h5>
            </span>
            
                
            
    </div>
</template>

<script>
// import {io} from 'socket.io-client'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
// import AddGaugeWidget from '@/components/crud_components/widgets/AddGaugeWidget'
import Machine from '@/components/items/Machine'
import axios from 'axios'
import AddMachineWidget from '@/components/crud_components/widgets/AddMachineWidget.vue';
export default {
name: "MachineWidget",
components:{
    AddMachineWidget,
        Machine,
        InfoAlert,
        // DeleteConfirmation
    },
// props:['widget'],
props:{
    widget:{
        type:Object,
        required:true

    },
            edit:{
            type:Boolean,
            default:true
      }
},
data() {
    return {
            showDialog:false,
            showEdit:false,
            loading:false,
            x: 0,
            y: 0,
            showMenu:false,
            stream:null,
            val:null,
            info:'',
            showDismissibleAlert:false,
            machine:null
    };
  },
// created(){
//     //this.socket=io('https://localhost:80');
//     this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
//     },

mounted() {
    // console.log(this.widget.meta.data[0].color_meta,'this.widget.meta.data.color_meta before json parse')
    this.init()
  },
// beforeDestroy() {
//     this.stream.close()
//     delete this.gauge;
//   },
computed:{
    width(){
        if(this.widget.height==='fixed'){
            return '200'
        }
        return null
    },
    height(){
        if(this.widget.height==='fixed'){
            return '175px'
        }
        return null
    },
    
    
        },
  methods: {
      show (e) {
          e.preventDefault()
          this.showMenu = false
          this.x = e.clientX
          this.y = e.clientY
          if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
        },
        init(){
            if(this.widget.meta.machine_id){
                this.loading=true
                let payload={
                    machine_id:this.widget.meta.machine_id
                }
                this.machine=null
                axios.post(this.$store.state.api+'getMachineByID',payload,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }}).then(response=>{
            if(response.data.status==='success'){
                this.machine=response.data.machine
            // this.$store.dispatch("refreshDashboardGauges")
            //this.onReset()
            this.dialog=false
                this.loading=false
                //this.dialog=false
                //this.$emit('close')
        }
        })
        .catch(err=>{console.error(err)});


            }
        },
    deleteWidget(){
    axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }}).then(response=>{
        if(response.data.status==='success'){
            // this.$store.dispatch("refreshDashboardGauges")
            //this.onReset()
            this.dialog=false
                this.loading=false
                //this.dialog=false
                //this.$emit('close')
        }
        })
        .catch(err=>{console.log(err)});
    },
    
  cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
  },
    watch:{
        widget:{
            handler(){
                this.init()
            },
            deep:true
        }
    }
};
</script>

<style scoped>
.gauge-title span {
  display: inline;
  text-align: center;
}
</style>
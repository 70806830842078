<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row no-gutters>
            <!-- <v-col ><span><strong>{{'Status: '+status }}</strong></span></v-col> -->
            <v-col ><span><strong  :style="{ color: (status === 'loading' || status === 'offline') ? 'red' : 'green', backgroundColor: 'white' }" class="px-3 rounded-lg">{{display_msg?display_msg+status:'Status: '+status }}</strong></span></v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import { io } from "socket.io-client";
import axios from 'axios'
import moment from 'moment'
import BinaryMathOperations from  '@/utillities/BinaryMathOperations'
export default {
    name:'getMachineStatus',
    props:['machine','display_msg'],
    components:{
        InfoAlert
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            rawData:[],
            ruleData:{},
            tag_types:[],
            tagParamMap:[],
            streamData: {},
            status:'loading',
            mathOperation:BinaryMathOperations.performOperation
        }
    },
    created() {
        this.stream = io(this.$store.state.streamApi, {withCredentials: true,auth: { token: this.$store.state.jwt },});
    },
    beforeDestroy() {
        this.stream.close();
    },
    mounted(){
        this.fetchMachineStatusRules()
    },
    methods:{
        startStream(){
            this.stream.on("connect_error", (err) => {console.log(err.message);});
            this.stream.on("error", (data) => {console.error(data);this.status='offline'});
            this.stream.on("param", (data) => {
                if (data.constructor == String) {
                    data = JSON.parse(data);
                }
                this.streamData = Object.assign({}, this.streamData);
                this.streamData[data.param_id]=data
                // console.log(data.param_id)
                this.$emit('success')
                let proceedForStatusCheck=this.checkTimestampCondition(data.timestamp)
                if(proceedForStatusCheck){
                    this.checkStatus(this.streamData)
                }else{
                    this.status='offline'
                }
            })
        },
        checkTimestampCondition(timestamp){
            let streamDataTimestamp = moment.unix(timestamp);
            let differenceInMinutes = moment().diff(streamDataTimestamp, 'minutes');
            return differenceInMinutes < 5
        },
        checkStatus(newData) {
            this.status = Object.keys(this.ruleData).find((status) =>
                this.ruleData[status].every((rule) => {
                    if (this.tagParamMap && rule.tag_type && this.tagParamMap[rule.tag_type] && newData[this.tagParamMap[rule.tag_type]]) {
                        return this.mathOperation(newData[this.tagParamMap[rule.tag_type]].data, rule.operator, rule.operand);
                    }else{
                        return false;
                    }
                })
            );
            if (!this.status) {
                this.status = 'offline';
            }

            this.$emit('success');
        },
        // checkStatus(newData) {
        //     this.status = Object.keys(this.ruleData).find((status) =>
        //     this.ruleData[status].every((rule) =>
        //         if(this.tagParamMap[rule.tag_type]){

        //             this.mathOperation(newData[this.tagParamMap[rule.tag_type]].data,rule.operator,rule.operand),
        //         }
        //     ),
        //     );
        //     if (!this.status) {
        //         this.status = 'Not Communicating/Off';
        //     }
        //     this.$emit('success')
        // },
        getData(){
            // console.log(this.ruleData,'ruleData')
            // console.log(this.tagParamMap,'tagParamMap')
            this.$emit('success')
            for (let i of Object.keys(this.tagParamMap)) {
                this.stream.emit("getParam", this.tagParamMap[i]);
            }
        },
        createTagTypeParamMap(){
            axios.post(this.$store.state.api + "getTagTypeParamsByMachineId", {machine_id:this.machine.machine_id,tag_types:this.tag_types},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    this.loading = false;
                    let temp={}
                    for(let i of response.data.data){
                        temp[i['tag_type']]=i.param_id
                    }
                    if(response.data.data.length<=0){
                        this.$emit('cancel')
                    }
                    this.tagParamMap=temp
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        createRuleStructure(){
            let temp={}
            this.ruleData={}
            this.tag_types=[]
            let tag_types=new Set([])
            for(let i of this.rawData){
                tag_types.add(i.tag_type)
                if (!temp[i.machine_status]) {
                    temp[i.machine_status] = [];
                }
                temp[i.machine_status].push({tag_type:i.tag_type,operator:i.operator,operand:i.operand})
            }
            this.tag_types=[...tag_types]
            this.ruleData=temp
            this.createTagTypeParamMap()
        },
        fetchMachineStatusRules(){
            axios.post(this.$store.state.api + "getMachineStatusRulesByMachineId", {machine_id:this.machine.machine_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    this.loading = false;
                    if(response.data.rules.length<=0){
                        this.$emit('cancel')
                    }
                    this.rawData=response.data.rules
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        getColorByStatus(status){
            switch(status){
                case "running":
                    return '#00E676'
                case "idling":
                    return '#F9A825'
                default:
                    return '#D32F2F'
            }
        },
        emitColor(){
            if(this.status){
                this.$emit('color',this.getColorByStatus(this.status),this.status)
            }else{
                this.$emit('cancel')
            }
        },
        // checkStatus(newData){
        //     let assignDefaultStatus=true
        //     this.status=null
        //     for(let i of Object.keys(this.ruleData)){
        //         let benchmark=this.ruleData[i].length
        //         let temp=[]
        //         for(let j of this.ruleData[i]){
        //             if(newData[j['tag_type']] + j['operator'] + j['operand']){
        //                 temp.push('true')
        //             }
        //         }
        //         if(temp.length===benchmark){
        //             this.status=i
        //             assignDefaultStatus=false
        //             break
        //         }
        //     }
        //     if(assignDefaultStatus){
        //         this.status='Machine is Not Communicating'
        //     }
        // },
        emitStatus(newStatus,oldStatus){
            this.$emit('status',{newStatus,oldStatus})
        },
    },
    watch:{
        rawData(){
            if(this.rawData && this.rawData.length>0){
                this.createRuleStructure()
            }
        },
        tagParamMap(){
            if(this.tagParamMap){
                this.startStream()
                this.getData()
            }
        },
        // status(){
        //     this.emitColor()
        // },
        status:{
            handler(newStatus, oldStatus) {
                this.emitColor()
                this.emitStatus(newStatus,oldStatus)
            },
            immediate: true,
        }
    }
}
</script>
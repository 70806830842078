import English from '@/utillities/languages/English'
// import German from '@/utillities/languages/German'
import Tamil from '@/utillities/languages/Tamil'
import Hindi from '@/utillities/languages/Hindi'
import German from '@/utillities/languages/German';
import French from '@/utillities/languages/French';
import Mandarin from '@/utillities/languages/Mandarin';
import Spanish from '@/utillities/languages/Spanish';
import Kannada from '@/utillities/languages/Kannada';
import Bengali from '@/utillities/languages/Bengali';
import Marathi from '@/utillities/languages/Marathi';
import Telugu from '@/utillities/languages/Telugu';
import Malayalam from '@/utillities/languages/Malayalam';
import Arabic from '@/utillities/languages/Arabic';
import Gujarati from '@/utillities/languages/Gujarati';

export default{
    langList:['english','french','german','hindi','mandarin','spanish','tamil','kannada','bengali','marathi','telugu','malayalam','arabic', 'gujarati'],
    langOptions:[
        {label:'English',value:'english'},
        {label:'Français',value:'french'},
        {label:'Deutsch',value:'german'},
        {label:'हिंदी',value:'hindi'},
        {label:'中文',value:'mandarin'},
        {label:'Española',value:'spanish'},
        {label:'தமிழ்',value:'tamil'},
        {label:'ಕನ್ನಡ',value:'kannada'},
        {label:'বাংলা',value:'bengali'},
        {label:'मराठी',value:'marathi'},
        {label:'తెలుగు',value:'telugu'},
        {label:'മലയാളം',value:'malayalam'},
        {label:'عربي',value:'arabic'},
        {label:'ગુજરાતી',value:'gujarati'},
        
    ],
    getLangTexts:(lang)=>{
        switch(lang){
            case 'english':
                return English;
            case 'tamil':
                return Tamil;
            case 'hindi':
                return Hindi;
            case 'german':
                return German;
            case 'french':
                return French;
            case 'mandarin':
                return Mandarin;
            case 'spanish':
                return Spanish;
            case 'kannada':
                return Kannada
            case 'bengali':
                return Bengali
            case 'marathi':
                return Marathi
            case 'telugu':
                return Telugu
            case 'malayalam':
                return Malayalam
            case 'arabic':
                return Arabic
            case 'gujarati':
                return Gujarati
        
            default:
                return English
            }
    },

}
<template>
<div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <div class="py-0">
          
          <v-form
    ref="parameterForm"
    v-model="valid" >
      <v-card>
        <v-card-title class="headline">
        {{ $store.getters.getTextMap().attach_params_to_machine }}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col><v-select
                v-if="!loading"
              v-model="devType"
          :items="devTypeOptions"
          :label="$store.getters.getTextMap().deviceA_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select></v-col>
        <v-col>
            <v-select
                v-if="!loading"
              v-model="dev"
          :items="deviceOptions"
          :label="$store.getters.getTextMap().device"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
        </v-col>
        <v-col>
            <v-select
                v-if="!loading"
              v-model="params"
          :items="paramOptions"
          :label="$store.getters.getTextMap().parameters"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            multiple
         :rules="listRules"
         clearable
         small-chips
        ></v-select>
        </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-chip
      class="ma-2"
      close
      color="blue"
      text-color="white"
      @click:close="close(param)" 
      v-bind:key="param.parameter" v-for="param in params"
    >
      {{param.name}}
    </v-chip>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col align="right">
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{  $store.getters.getTextMap().add }}
          </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                        v-if="!loading"
                            :color="$store.getters.getColorPalette().submitbtnColor"
                            text
                            @click="onReset"
                        >
                        {{  $store.getters.getTextMap().cancel }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
      </v-card>
          </v-form>
        </div>
</div>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'AddParamToMachine',
    // props:['machine'],
    props:{
      machine:{
        type:Object,
        required:true
      },
      eliminate:{
        type:Array,
        default:()=>[]
      }
    },
    components:{
        InfoAlert
    },
    data(){
        return {
            valid:false,
            loading:false,
            showDismissibleAlert: false,
            info:'',
            devType:'modbus',
            dev:null,
            params:[],
            objectRules:FormRules.objectRules,
            listRules:FormRules.listRules,
            devTypeOptions:[
                {label:'MODBUS', value:'modbus'},
                {label:'S7Comm', value:'s7'},
                {label:'MQTT', value:'mqtt'},
                {label:'OPC UA', value:'opc'},
            ]
        }
    },
    computed:{
        deviceOptions(){
             
            let op=[]
          
          if(this.machine && this.machine.workspace_id && this.devType){
              let g=[]
              switch (this.devType){
                case 'mqtt':
                g=this.$store.getters.getMQTTDevicesByWorkspaceId(this.machine.workspace_id)
                    break;
                case 'opc':
                  g=this.$store.getters.getVDevicesByWorkspaceId(this.machine.workspace_id)
                    break;
                case 's7':
                  g=this.$store.getters.getS7DevicesByWorkspaceId(this.machine.workspace_id)
                    break;
                default:
                    g=this.$store.getters.getDevicesByWorkspaceId(this.machine.workspace_id)
              }
              
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
          }
          }
          return op
        },
        paramOptions(){
            let op=[]
            console.log('paramOptions')
            console.log(this.dev)
          
          if(this.dev && this.dev.device_id ){
            console.log(this.$store.state.opcParams)
              let g=[]
              switch (this.devType){
                case 'mqtt':
                g=this.$store.getters.getMQTTParamsByDeviceID(this.dev.device_id)
                    break;
                case 'opc':
                    g=this.$store.getters.getOPCParamsByVDeviceID(this.dev.device_id)
                    break;
                case 's7':
                    g=this.$store.getters.getS7ParamsByDeviceID(this.dev.device_id)
                    break;
                default:
                    g=this.$store.getters.getParametersByDeviceID(this.dev.device_id)
              }
              for(let i of g){
                  if(this.eliminate && this.eliminate.length>0 && this.eliminate.includes(i.param_id)){
                    continue;
                  }
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        }
    },
    methods:{
        close(param){
        //console.log(param)
        this.params=this.params.filter(
      function(data){ return data.param_id != param.param_id }
  );
      },
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let p=[]
            for(let i of this.params){
                p.push(i.param_id)
            }
            let d={machine_id:this.machine.machine_id,params:p}
            axios.post(this.$store.state.api+'attachParamsToMachine',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$emit('success')
        //console.log("Params added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      console.log(err)
      });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    },
    watch:{
        devType(){
            this.dev=null
        },
        
    }
}
</script>
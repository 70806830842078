<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon color="red" small>mdi-trash-can</v-icon></v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-if="widget && widget.widget_id" v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text  @click="update = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddMachineStatusTimelineWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row no-gutters dense>
            <v-col>
                <div :style="[{'display':'flex','justify-content':'center'}]">
                    <v-card-title>{{ widget.label }}</v-card-title>
                </div>
            </v-col>
        </v-row>
        <!-- <v-divider :dark="$store.getters.getColorPalette().isDark"/> -->
        <v-row no-gutters dense>
            <v-col v-if="widget && widget.widget_id && widget.meta && machineList && machineList.length>0 && timestampList && timestampList.length>0">
                <div v-for="machine in machineList" :key="machine.machine_id">
                    <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="mb-1">
                        <div :style="[{'display':'flex','justify-content':'space-between'}]">
                            <div>
                                <v-card-subtitle class="sub-heading">{{machine.machine_name}}</v-card-subtitle>
                            </div>
                            <div v-if="widget && widget.widget_id && widget.meta && widget.meta.timeframeType==='shift' && widget.meta.shiftDetails && widget.meta.shiftDetails.shift_id">
                                <v-card-subtitle class="sub-heading">{{widget.meta.shiftDetails.name}}</v-card-subtitle>
                            </div>
                        </div>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        <div v-for="(item,index) in timestampList" :key="index">
                            <div :style="[{'display':'flex','justify-content':'end'}]">
                               <v-card-subtitle class="sub-heading">{{  $store.getters.getTextMap().start_time }}: {{formattedDate(item.start_time,'from')}},  {{  $store.getters.getTextMap().end_time }}:{{formattedDate(item.end_time,'to')}}</v-card-subtitle>
                            </div>
                            <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                            <MachineStatusTimelineGraph :machine_id="machine.machine_id" :start_time="getTimestamp(item.start_time,'from')" :end_time="getTimestamp(item.end_time,'to')"/>
                            <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        </div>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                    </v-card>
                </div>
            </v-col>
            <v-col v-else>
                <div :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
                    <v-icon small>mdi-hail</v-icon><h5>{{$store.getters.getTextMap().not_configured}}</h5>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
import MachineStatusTimelineGraph from '@/components/graphs/MachineStatusTimelineGraph.vue'
import AddMachineStatusTimelineWidget from '@/components/crud_components/widgets/AddMachineStatusTimelineWidget.vue'
export default {
    name:'MachineStatusTimelineWidget',
    props:['widget','seq','disableTimeframe','edit'],
    components:{
        InfoAlert,
        MachineStatusTimelineGraph,
        AddMachineStatusTimelineWidget
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            x:0,
            y:0,
            showMenu: false,
            dialog: false,
            update: false,
            machineList:[],
            timestampList:[]
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        formattedDate(time,type){
            let timestamp=this.getTimestamp(time,type)
            if(timestamp){
                return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
            }
            return null
        },
        getTimestamp(time,type){
            let days_to_subtract=this.widget && this.widget.meta && this.widget.meta.days_delta && type && type==='from'?this.widget.meta.days_delta:1
            let dt=moment().subtract(days_to_subtract, 'days').format("YYYYMMDD")
            if(time){
                dt+=' '+time.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
        init(){
            if(this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.machineList && this.widget.meta.machineList.length>0 && this.widget.meta.timestamps && this.widget.meta.timestamps.length>0){
                this.machineList=this.widget.meta.machineList
                this.timestampList=this.widget.meta.timestamps
            } 
        },
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
        },
        deleteWidget() {
            this.loading = true
            axios.post(this.$store.state.api + 'deleteWidget', this.widget, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    this.dialog = false
                    this.loading = false
                    this.info = "Widget Deleted"
                    this.$emit('update')
                }
            })
            .catch(err => {
                console.error(err)
                this.loading = false
            });
        },
    },
    watch:{
        widget(){
            this.init()
        }
    }
}
</script>
<template>
<v-container fluid :style="[{'background-color': $store.getters.getColorPalette().backgroundColorCode}]">
    <div :style="[{'background-color': $store.getters.getColorPalette().backgroundColorCode}]"> 
    <div class="card">
     <div class="card-content">
       <v-row >
        <v-col cols="12">
          <span v-for="machine in machine_details.machine_list" :key="machine.machine_id">
            <v-row no-gutters>
              <v-col><h3><span :style="{color: $store.getters.getColorPalette().accentCode}">Name: &nbsp; {{machine.machine_name}}  </span></h3></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row >
              <v-col  cols="4" v-for="param in params" :key="param.param_id">
                <v-card  v-if="machine.machine_id === param.machine_id">
                  <strong>{{param.name}}:</strong><h4>{{streamData[param.param_id]?Math.round(Number(streamData[param.param_id].data)*1000)/1000:'-' }} {{param.unit}}</h4>
                </v-card>
              </v-col>
          </v-row>
          </span>
        </v-col>  
    </v-row>
     </div>
     </div>
     </div>
</v-container>
</template>

 <script>
//import Param from '@/components/items/Param'
import axios from 'axios'
import {io} from 'socket.io-client'

export default {
Name:"ParamScrollComponent",
props:['machine_details'],
components:{
 //Param,

},
data(){
return {
      params:[], 
      machine_list:[],
      machines:[],
       streamData:{},
      stream:null,
 
      }
     },


      created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true,auth:{token:this.$store.state.jwt}})
  // console.log( this.stream,"stream")},
      },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },

     mounted(){      
    //  console.log("param")
this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.log(data)
      
    })
    this.stream.on('ack',data=>{
      console.log(data)
      
    })
    this.stream.on('param',data=>{
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      
       this.streamData[data.param_id]=data
    })
    this.getParams()
    },

    methods:{
      getData(param_id){
        let temp=this.streamData[param_id]

        if(temp ){
          return Math.round(Number(temp.data)*1000)/1000
        }


        return '-'

      },
           getParams(){
       if(this.machine_details  && this.machine_details.machine_list){
        // console.log(this.machine_details.machine_list,"ml")
        this.streamData={}
        this.machines = []
            for(let i of this.machine_details.machine_list){
                  //  console.log(i,"i")
                   this.loading=true

                    // console.log('getParams executed')
                    axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:i.machine_id},{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            // console.log(response.data.params,"response")
                            if(response.data.status=='success'){
                                this.loading=false
                                //this.params=response.data.params     
                                this.machines.push({machine_id:i.machine_id,machine_name:i.name,params:response.data.params}) 
                                   this.params=this.params.concat(response.data.params)
                                    // console.log(this.params,"params")
                                 for(let i of response.data.params){
                                  this.stream.emit('getParam',i.param_id)
                                 }
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
            }
                }
        },
                                       
    }
}

</script>

<style scoped>
.card {
   /* width: 500px; */
  /* height: 200px; */
  overflow: hidden;
}

.card-content {
  animation: scrollCard 30s linear infinite;
}

@keyframes scrollCard {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

</style>
<template>
    <v-dialog
      v-model="showDismissibleAlert"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title class="headline">{{$store.getters.getTextMap().info}}
</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="$emit('close')"
          >
          {{$store.getters.getTextMap().ok}}

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:'InfoAlert',
    props:['showDismissibleAlert','info'],
    data(){
        return {
            
        }
    },
    methods: {
      
    },
}
</script>
import blue from '@/utillities/themes/blue'
import lightBlue from '@/utillities/themes/lightBlue'
import lite from '@/utillities/themes/lite'
import dark from '@/utillities/themes/dark'
import white from '@/utillities/themes/white'
import black from '@/utillities/themes/black'
/*
const colorCodes={
    'teal': '#64FFDA',
    'deeppurple':'#B388FF',
    'cyan': '#18FFFF',
    'green':'#69F0AE',
    'purple': '#E040FB',
    'pink': '#FF4081',
    'lime': '#C6FF00',
    'lightblue': '#40C4FF',
    'red':'#FF1744',
    'yellow': '#FFFF00',
    'lightgreen':'#76FF03',
    'deeporange': '#FF3D00', 
    'greylighten':'#BDBDBD',
     'blue': '#2979FF',
    // 'amber':'#FFD74',
    'orange':'#FF9800',
    'deeppurplelighten':'#D1C4E9',
//    'tealdarken' :'#00897B'
   'tealdarken' :'#26A69A'
}
const colorCodesWhite={

    'indigolighten': '#5C6BC0',
    'purple': '#E040FB',
    'green':'#69F0AE',
    'pink': '#FF4081',
    'teal': '#64FFDA',
    'lime': '#C6FF00',
    'lightblue': '#40C4FF',
    'red':'#FF1744',
    'yellow': '#FFFF00',
    'deeppurple':'#B388FF',
    'lightgreen':'#76FF03',
    'deeporange': '#FF3D00',
    'cyan': '#18FFFF',   
    'greylighten':'#BDBDBD',
     'blue': '#2979FF',
    // 'amber':'#FFD74',
    'orange':'#FF9800',
    'deeppurplelighten':'#D1C4E9'
        
}
const colorNames={
    'green': 'green accent-2',
    'purple': 'purple accent-2',
    'pink': 'pink accent-2',
    'teal':'teal accent-2',
    'lime': 'lime accent-3',
    'lightblue': 'light-blue accent-2',
    'red':'red accent-3',   
    'yellow': 'yellow accent-2',
    'deeppurple':'deep-purple accent-1',
    'lightgreen' :'light-green accent-3',
    'cyan':  'cyan accent-2',
   'deeporange': 'deep-orange accent-3',
   'blue': 'blue accent-3',   
   'amber' :'amber accent-2',
   'greylighten':'grey lighten-1',
   'orange':'orange ',
   'deeppurplelighten':'deep-purple lighten-4',
   'tealdarken':'teal lighten-1'
//    'tealdarken':'teal darken-1'
 
}

const gradColorName=[
    'orange',
    'teal  ',
    'orange lighten-3',
    'teal lighten-3',
    'orange lighten-1',
    'orange lighten-1',
    'torange lighten-4',
    'teal lighten-4',
    'orange darken-1',
    'teal darken-1',
    'orange darken-3',
    'teal darken-3',
]
const gradColorCode=[
'#FF9800',
'#009688',
'#FFCC80',
'#80CBC4',
'#FFA726',
'#26A69A',
'#FFE0B2',
'#80CBC4',
'#FB8C00',
'#00897B',
'#EF6C00',
'#00695C'
]

var colorscaleCodeHeatMap = [
[0,'#40C4FF'],
// [0.1,'#81C784'],
// [0.2,'#66BB6A'],
// [0.3,'#FFF59D'],
// [0.4,'#FFF176'],
[0.5,'#FFEE58'],
// [0.6,'#FFB74D'],
// [0.7,'#FFA726'],
// [0.8,'#FB8C00'],
// [0.9,'#F57C00'],
[1,'#FF4081'],
];
// var colorscaleCodeHeatMap = [
// [0,'#A5D6A7'],
// [0.1,'#81C784'],
// [0.2,'#66BB6A'],
// [0.3,'#FFF59D'],
// [0.4,'#FFF176'],
// [0.5,'#FFEE58'],
// [0.6,'#FFB74D'],
// [0.7,'#FFA726'],
// [0.8,'#FB8C00'],
// [0.9,'#F57C00'],
// [1,'#EF6C00'],
// ];

// const gradColorCode=[
//  #E0F2F1',
// '#B2DFDB',
// '#80CBC4',
// '#4DB6AC',
// '#26A69A',
// '#00897B',
// '#00796B',
// '#00695C',
// '#004D40',
// '#A7FFEB',
// '#64FFDA',
// '#1DE9B6',
// '#00BFA5',
// ]

// const tickfontColor=[
//     '#FDD835'
// ]
*/

export default {
    name:'ColorList',
    themeList:['dark','white','black','lite','blue','light-blue'],
    // colorTypesName:colorNames,
    // colorTypesCodes:colorCodes,
    // fontcolorTypescodes:tickfontColor,
    // tableHeaderColorName:colorNames['pink'],
    // tableHeaderColorCode:colorCodes['pink'],
    // graphPoints:colorCodes['lime'],
    // backgroundColorCode:'#263238',
    // colorTypesNameArray:Object.values(colorNames),
    // colorTypesCodeArray:Object.values(colorCodes),
    // dropDownCodeArray: () => {
    //     let dropDownCodeArrays = []
    //     let count = 0
    //     for(let i of Object.keys(colorCodes)){
    //         let tempObject ={label:i,values:Object.values(colorCodes)[count]}
    //         dropDownCodeArrays.push(tempObject)
    //         count = count+1
    //     }
    //     return dropDownCodeArrays
    // },
    // dropDownNameArray : () => {
    //     let dropDownNameArrays=[]
    //     let count = 0
    //     for(let i of Object.keys(colorNames)){
    //         let tempObject ={label:i,values:Object.values(colorNames)[count]}
    //         dropDownNameArrays.push(tempObject)
    //         count = count+1
    //     }
    //     return dropDownNameArrays
    // },
    getTheme:(theme)=>{
        switch(theme){
            case 'lite':
                return lite;
        case 'blue':
            return blue;
        case 'light-blue':
            return lightBlue;
        case 'white':
            return white;
        case 'black':
            return black;
    default:
        return dark













        }
    
    }
    
}

<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon color="red" small>mdi-trash-can</v-icon></v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text  @click="update = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddDynamicLogWidget :widget="widget" :disableTimeframe="disableTimeframe" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-row v-if="widget.meta.data && showGraphType" no-gutters>
            <v-col align="right">
                <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                    <v-btn small>
{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <div v-if="widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'248px',}:{}]"></div>
        <div v-else
            :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>
<script>
    import InfoAlert from '@/components/InfoAlert';
    import Plotly from 'plotly.js';
    import DateUtils from '@/utillities/DateUtils';
    import ColorUtility from '@/utillities/ColorUtility';
    import moment from 'moment';
    import axios from 'axios';
    import AddDynamicLogWidget from '@/components/crud_components/widgets/AddDynamicLogWidget';
    export default
        {
        name: 'DynamicLogWidget',
        props: ['widget','edit','disableTimeframe','timeframe'],
        components: {
            AddDynamicLogWidget,
            InfoAlert
        },
        data() {
            return {
                title: 'Dynamic Log Widget',
                dateUtils: DateUtils,
                graphType: 'bar',
                tempDataFinale: [],
                graphData: {},
                loading: false,
                x:0,
                y:0,
                colors:ColorUtility.colorCodes,
                info: '',
                dialog: false,
                showDismissibleAlert: false,
                update: false,
                showMenu: false,
                primaryFieldLabel: '',
                showGraphType: {
                    type: Boolean,
                    default: true
                },
                layout: {
                    barmode: 'group',
                    font: {color: this.$store.getters.getColorPalette().accentCode,},
                    plot_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend: true,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color: this.$store.getters.getColorPalette().accentCode,
                    },
                    yaxis: {
                        showticklabels: true,
                        color: this.$store.getters.getColorPalette().accentCode,
                    },
                    uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                    }
                }
            }
        },
        mounted() {
            this.getWidgetData()
        },
        methods: {
            show(e) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
            },
            calculateTimeStamp(a, b) {
                try {
                    if (a == isNaN && a != undefined | b == isNaN && b != undefined) {return null}
                    let dateTimeString = `${a} ${b}`;
                    let timeStamp = moment(dateTimeString, "YYYY-MM-DD HH:mm");
                    if (!timeStamp.isValid()) {
                        console.error("Invalid date-time format:", dateTimeString);
                        return null;
                    }
                    return Number(timeStamp.format("X"));
                } catch (error) {
                    console.error("Error in calculateTimeStamp:", error);
                    return null;
                }
            },
            createChart() {
                this.graphData = {};
                if(this.widget.height=='fixed'){

                    this.layout['height']='250';
                }
                this.layout['title'] = this.widget.label
                // let primaryfield = null
                // if(this.widget && this.widget.meta && this.widget.meta.primary_field && this.widget.meta.primary_field_label){
                //     primaryfield = this.widget.meta.primary_field_label;
                // }
                for (let i of this.tempDataFinale) {  
                    // if(primaryfield){
                    //     i['meta_data'][primaryfield] = i.primary_field_data;
                    // }      
                    // let keys=Object.keys(i['meta_data'])?Object.keys(i['meta_data']):[]
                    for (let j of Object.keys(i['meta_data'])) {
                        if(i['meta_data'][j] && this.widget.meta.data.includes(j)){
                            if (!(j in this.graphData)) {
                                let type = this.graphType == 1 ? 'line' : 'bar';
                                this.graphData[j] = {
                                    x: [],
                                    y: [],
                                    name: j,
                                    type: type,
                                    opacity: 0.9,
                                    marker: {
                                        color:this.colors,
                                        line: {
                                            color: this.$store.getters.getColorPalette().accentCode,
                                            width: 1
                                        },
                                    },
                                };
                            }
                            this.graphData[j]['x'].push(moment(i.from_timestamp).format('YYYY-MM-DD'));
                            this.graphData[j]['y'].push(i['meta_data'][j]);
                        }
                    }


                    if(this.widget.meta && this.widget.meta.primary_field_label){
                        
                        if(!('primary_field_data' in this.graphData)){
                        let type = this.graphType == 1 ? 'line' : 'bar';
                                this.graphData['primary_field_data'] = {
                                    x: [],
                                    y: [],
                                    name: this.widget.meta.log_details.primary_field_label,
                                    type: type,
                                    opacity: 0.9,
                                    marker: {
                                        color:this.colors,
                                        line: {
                                            color: this.$store.getters.getColorPalette().accentCode,
                                            width: 1
                                        },
                                    },
                                };
                    }else{
                        this.graphData['primary_field_data']['x'].push(moment(i.from_timestamp).format('YYYY-MM-DD'));
                    this.graphData['primary_field_data']['y'].push(i['primary_field_data']);

                    }
                    }
                    
                    
                    
                }
                const traces = Object.values(this.graphData);
                Plotly.newPlot(this.$refs.vis, traces, this.layout, { displaylogo: false });
            },
            async getWidgetData() {
                this.loading = true
                let from_date;
                let to_date;
                let logID;
                if (this.widget.height === 'fixed') {
                    this.layout.height = 160
                    this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode }
                    this.layout.margin = { t: 40, b: 40, l: 40, r: 40 }
                }
                if (this.widget && this.widget.meta && this.widget.meta.graph_type) {
                    this.graphType = this.widget.meta.graph_type
                }





                if (this.widget.meta && this.widget.meta.time_frame && this.widget.meta.log_details.log_id) {
                    from_date = Number(this.dateUtils.getFromDate(this.widget.meta.time_frame).format('YYYYMMDD'))
                    to_date = Number(moment().format('YYYYMMDD'))
                    logID = this.widget.meta.log_details.log_id
                    this.tempDataFinale = []
                    let payload = {
                        from_timestamp: this.calculateTimeStamp(from_date),
                        to_timestamp: this.calculateTimeStamp(to_date),
                        log_id: logID
                    };
                    try {
                        const response = await axios.post(this.$store.state.api + "/getDynamicLogDataBetweenTimestamp", payload, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}});
                        if (response.data.status === 'success') {
                            this.tempDataFinale = response.data.data

                            console.log(this.tempDataFinale);
                            this.loading = false
                            this.createChart()
                        } else {
                            this.info = response.data.msg
                            this.showDismissibleAlert = true
                            this.loading = false
                        }
                    } catch (err) {
                        this.info = err,
                        this.showDismissibleAlert = true
                        this.loading = false
                    }
                }
            },
            deleteWidget() {
                this.loading = true
                axios.post(this.$store.state.api + 'deleteWidget', this.widget, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.info = "Widget Deleted"
                        this.$emit('update')
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                });
            },
        },
        watch: {
            graphType: function () {
                this.createChart()
            },
        }
    }
</script>
<template>
    <v-container fluid @contextmenu="show" :dark="$store.getters.getColorPalette().isDark"
        :style="{ 'background-color': widget.background_color }">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog = true"
            v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update = true">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().delete }}
                        </strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false">{{
                            $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddDaywiseMachineStatusWidget :widget="widget" v-on:close="update = false"
                    v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>

        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta && widget.meta.data" ref="vis"
            :style="[widget.height === 'fixed' ? { 'background-color': widget.background_color ? widget.background_color : $store.getters.getColorPalette().background2ColorCode, 'height': '150px', } : {}]">
        </div>
        <div v-else
            :style="[widget.height === 'fixed' ? { 'height': '175px', 'text-align': 'center' } : { 'text-align': 'center' }]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{ $store.getters.getTextMap().not_configured }}</h5>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
import AddDaywiseMachineStatusWidget from '@/components/crud_components/widgets/AddDaywiseMachineStatusWidget'
import moment from 'moment';
import Plotly from 'plotly.js'

export default {
    name: "DaywiseMachineStatusWidget",
    components: {
        InfoAlert,
        AddDaywiseMachineStatusWidget
    },
    props: {
        widget: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        },

    },
    mounted() {
        // console.log(this.widget);
        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data.length > 0) {
            this.createInputData()
        }

    },
    data() {
        return {
            showMenu: false,
            showDismissibleAlert: false,
            info: false,
            update: false,
            dialog: false,
            x: 0,
            y: 0,
            loading: false,
            payload: null,
            inputData: [],
            graphData: {},
            multiParamGraphData: {},
            tempDataFinale: [],
            layout: {
                barmode: 'stack',
                font: { color: this.$store.getters.getColorPalette().accentCode },
                plot_bgcolor: this.widget.background_color ? this.widget.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor: this.widget.background_color ? this.widget.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                hoverlabel: { namelength: -1, },
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                yaxis: {
                    range: [0, this.benchmarkYValue + 100],
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                },
                timeframe: null,

            },
        }
    },
    methods: {
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if (this.$store.state.settingMode) {
                this.$nextTick(() => {
                    this.showMenu = true
                })
            }
        },
        createInputData() {
            let temp = []
            for (let i of this.widget.meta.data) {
                temp.push({
                    machine_id: i.machine_id
                })
            }
            this.inputData = temp
        },
        init() {
            this.getData()

        },

        deleteWidget() {
            this.loading = true
            axios.post(this.$store.state.api + 'deleteWidget', this.widget, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.$emit('update')
                    }
                }).catch(err => {
                    console.error(err)
                    this.loading = false
                });
        },

        getFromDate(date_format) {
            let from_date = moment();

            // console.log(this.widget, "timeframe");


            if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.time_frame) {

                switch (this.widget.meta.time_frame) {
                    case '7':
                        from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                        break;
                    case '30':
                        from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                        break;
                    case '60':
                        from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                        break;
                    case '90':
                        from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                        break;
                    case '180':
                        from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                        break;
                    case '365':
                        from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                        break;
                    case '1':
                        from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                        break;
                    case 'ytd':
                        from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                        break;
                    default:
                        from_date = date_format ? moment().subtract(30, 'days').startOf('month').format(date_format) : moment().startOf('month');
                        break;
                }

                // console.log(this.timframe);

                console.log(from_date);


            }

            return from_date
        },


        // downloadJSON(data, filename = 'data.json') {
        //     const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        //     const link = document.createElement('a');
        //     link.href = URL.createObjectURL(blob);
        //     link.download = filename;
        //     link.click();
        // },
        createMultipleParamChart() {
          //  this.downloadJSON(this.tempDataFinale)
            if (this.widget && this.widget.meta) {
                this.layout.yaxis['title'] = this.widget.meta.yaxis_label
                this.layout.xaxis['title'] = this.widget.meta.xaxis_label
                let type = this.graphType == 1 ? 'line' : 'bar'



                let groupedData = {}
                console.log(this.tempDataFinale, "rempd");
                this.tempDataFinale.forEach((entry) => {
                    entry['machine_name'] = this.$store.getters.getMachineById(entry.machine_id).name
                    let date = new Date(entry.date).toISOString().split('T')[0]
                    if (!groupedData[date]) {
                        groupedData[date] = {
                            Idle: 0,
                            Running: 0,
                            Stopped: 0
                        };
                    }
                    groupedData[date][entry.status] += entry.total_duration / 3600;
                })


                const statusColors = {
                    offline: "#FF0000", // Red for offline
                    running: "#00FF00", // Green for running
                    idle: "#FFFF00" // Yellow for idle
                };



                let graphData = {}
                console.log(this.tempDataFinale, "temtdaata");
                this.payload.machines.forEach(id => {
                    let date_wise_data = this.tempDataFinale.filter(item => item.machine_id == id)
                    console.log(date_wise_data);
                    let status= this.tempDataFinale.map(i=>i.status)
                    let name = this.$store.getters.getMachineById(id).name
                    graphData[name] = {
                        x: date_wise_data.map(i => new Date(i.date).toISOString().split('T')[0]),
                        y: date_wise_data.map(i => i.total_duration / 3600),
                        type: type,
                        text:status.map(String),
                        opacity: 0.9,
                        name:name,
                        marker: {
                            line: {
                                color: date_wise_data.map(i => statusColors[i.status] || "#808080"),
                                width: 1
                            },
                        },
                        
                    }
                })

                console.log(graphData);

                this.addIdealValueToGraph(graphData)
            }
        },


        addIdealValueToGraph(traces) {
            this.layout['title'] = this.widget.label
            this.layout['barcornerradius'] = 15
            if (this.widget.meta && this.widget.meta.legend) {
                this.layout['showlegend'] = true
            }
            if (this.widget.meta && this.widget.meta.barmode) {
                this.layout['barmode'] = this.widget.meta.barmode
            } else {
                this.layout['barmode'] = 'group'
            }

            console.log(traces);

            Plotly.newPlot(this.$refs.vis, Object.values(traces), this.layout, { displaylogo: false })
        },


        async getData() {
            try {
                if (this.widget.height === 'fixed') {
                    this.layout.height = 160;
                    this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode };
                    this.layout.margin = { t: 40, b: 40, l: 40, r: 40 };
                }
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray;
                this.graphData = {};
                this.multiParamGraphData = {};

                let from_date = moment().subtract(1, 'days');
                from_date = this.getFromDate('YYYYMMDD');
                let to_date = moment().format('YYYYMMDD');

                if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData) {
                    this.tempDataFinale = [];

                    // console.log(this.inputData, "inputdata");
                    let payload = {
                        from_date: from_date,
                        to_date: to_date,
                        // total: true,
                        machines: []
                    }
                    for (let i of this.inputData) {
                        payload['machines'].push(i.machine_id)
                    }

                    // console.log(payload,"payload");

                    if (payload) {
                        let response = await axios.post(this.$store.state.api + 'getDayWiseMachineStatusData', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                        // console.log(response, "response");
                        this.payload = payload
                        if (response.data.status == 'success') {
                            this.loading = false;
                            let data = response.data.data
                            // console.log(data);
                            // responseData.push(data)
                            this.tempDataFinale = [...data]

                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    }


                    // console.log(responseData);

                    this.createMultipleParamChart();
                    this.loading = false;
                }

            } catch (err) {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            }
        }
    },
    watch: {
        widget() {
            this.createInputData()
        },

        inputData() {
            this.init()
        }

    }
}
</script>

<style></style>
<template>
    <v-container :dark="$store.getters.getColorPalette().isDark"  fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Heat Map Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Heat Map Widget</span>
            </v-col>
        </v-row> -->
        <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="widgetForm" v-model="widgetValid">
        <v-card  :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">   
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_heat_map_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_heat_map_widget}}</v-card-title>  
            <v-row>
                <v-col align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field v-if="!loading" dense outlined v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center">
                    <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.height"
                        :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                <v-col v-if="edit"  align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        v-if="!loading"
                        v-model="widgetForm.seq"
                        :counter="3"
                        :label="$store.getters.getTextMap().sequence"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <!-- <v-col align-self="center">
                    <v-select
                    dark
                        color="cyan lighten-1"
                        :menu-props="{dark: true}"
                        v-if="!loading"
                        v-model="timeFrame"
                        :items="tdOptions"
                        label="Time Frame"
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col> -->
            </v-row>
        </v-card>
        <v-card  :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
            <v-row class="ml-1">
                <v-card-title v-if="formType==='paramForm'">{{$store.getters.getTextMap().choose_parameter}}</v-card-title>
                <v-card-title v-if="formType==='equationForm'">{{$store.getters.getTextMap().choose_equation}}</v-card-title>
                <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                        <v-radio :label="$store.getters.getTextMap().add_params" value="paramForm"></v-radio>
                        <v-radio :label="$store.getters.getTextMap().add_equation" value="equationForm"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-divider v-if="formType==='paramForm'" :dark="$store.getters.getColorPalette().isDark"/>
            <v-divider v-if="formType==='equationForm'" :dark="$store.getters.getColorPalette().isDark"/>
            <v-card v-if="formType==='paramForm'"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" flat>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-text-field
                                        v-if="!loading"
                                        v-model="form.label"
                                        :counter="30"
                                        :rules="[rules.required]"
                                        :label="$store.getters.getTextMap().param_label"
                                        required
                                        dense
                                        outlined
                                    ></v-text-field>
                            </v-col>    
                                <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    outlined      
                                    dense    
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    ></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                             :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="form.params"
                                    :items="paramOptions"
                                    :label="$store.getters.getTextMap().params"
                                    outlined
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                        </v-row>
                </v-container>
            </v-card>
            <v-card v-if="formType==='equationForm'"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" flat>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-select
                                :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-if="!loading"
                                v-model="eq_id"
                                outlined
                                :items="paramEquationOptions"
                                :label="$store.getters.getTextMap().param_equation"
                                dense
                                filled
                                item-text="label"
                                item-value="value"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            ></v-select>
                        </v-col> 
                    </v-row>
                </v-container>
            </v-card>
        </v-card>
    </v-form>
        <v-row no-gutters>
            <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                 <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
// import CreateMultiParam from '@/components/CreateMultiParam.vue'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'AddHeatMapWidget',
    props:['widget','seq'],
    components:{
        InfoAlert,
        // CreateMultiParam
    },
    mounted(){
        this.init()
    },
    data(){
        return {
              showDialog:false,
            show:false,
            dialog:false,
            dialogss:false,
            loading:false,
            valid:false,
            formType:'paramForm',
            api:'createWidgetForView',
            ValidMulti:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            edit:false,
            eq_id:null,
            workspace:null,
            machine:null,
            legend:false,
            textInfo:null,
            tableData:[],
            paramEqOptions:[],
            form:{
            },
            widgetForm:{},
            machineOptions:[],
            paramOptions:[],
            operators:[
                {label:'+',value:'+'},
                {label:'-',value:'-'}
                ],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            tdOptions:[
                {label:'MTD', value:'mtd'},
                {label:'YTD', value:'ytd'}
            ],
            textInfoOptions:[
                {label:'Label & percentage', value:'label+percent'},
                {label:'Value Only', value:'value'},
                {label:'Value & Percentage', value:'value+percent'},
                {label:'Percentage only', value:'percent'},
                {label:'None',value:'none'}
            ],
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
             countRules:[

                v => !!v || 'Required',

                v => (v && v>1) || 'Number of Machine should be greater than 1'

            ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        paramEquationOptions(){
            let op=[]
            for(let i of this.paramEqOptions){
                op.push( {value:i.eq_id, label: i.label })
            }
            return op
        },
        timeFrame:{
            get: function (){
            if(this.widgetForm && this.widgetForm.meta){
                return this.widgetForm.meta.time_frame
            }else{
                return null
            }
        },
        set: function (newValue){

            if(this.widgetForm && this.widgetForm.meta){
                this.widgetForm.meta.time_frame=newValue
            }else{
                this.widgetForm.meta={time_frame:newValue}
            }
            
        }
        }
    },
    methods:{
        init(){
            this.getParamEquations()
            if(this.widget && this.widget.widget_id && this.widget.view_id){
            this.edit=true
            this.widgetForm=Object.assign({},this.widget)
            this.api='updateWidget'
            if(!(this.widgetForm.meta)){
                this.widgetForm.meta={data:[],equationData:null}
                this.tableData=[]
                this.eq_id=null
            }else{
                this.formType=this.widgetForm.meta.formType
                if(this.formType=='paramForm'){
                    this.tableData=this.widgetForm.meta.data
                    this.form.label=this.widgetForm.meta.data[0].label
                    this.form.params = this.widgetForm.meta.data[0].params
                }else{
                    this.eq_id=this.widgetForm.meta.equationData
                    this.form.label=this.widgetForm.label
                }
                this.legend=this.widgetForm.meta.legend
                this.textInfo=this.widgetForm.meta.textinfo
            }

        }else{
            if(this.widget.view_id){
                this.api='createWidgetForView'
                this.widgetForm=Object.assign({},this.widget)
                this.widgetForm.meta={data:[],equationData:null}
                this.widgetForm.seq=this.seq?this.seq:0
                this.tableData=[]
                this.eq_id=null
            }
        }
        },
        getParamEquations(){
        this.loading=true
            axios.get(this.$store.state.api+'paramEquations',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.paramEqOptions=response.data.data
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })
        },
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
        onReset(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
              this.loading=true
              this.widgetForm.meta.formType=this.formType
              if(this.formType==='paramForm'){
                  if(!this.tableData){
                    this.tableData=[]
                  }
                this.tableData=[{label:this.form.label, params:this.form.params}]
                this.widgetForm.meta.data=this.tableData
                this.widgetForm.meta.equationData=null
              }else{
                  this.widgetForm.meta.equationData=this.eq_id
                  this.widgetForm.meta.data=[]
              }
            this.widgetForm.meta.legend=this.legend
            this.widgetForm.meta.textinfo=this.textInfo
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
            this.loading=false
            //this.dialog=false
            this.$emit('success')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
          onClose(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
        removeParam(param){
            //console.log("remove param")
            //console.log(param)
            //this.stream.emit('removeParam',param)
            this.form.params=this.form.params.filter(x=>x!=param)
            //this.parameter.delete(param)
            
        },
        deleteFromTable(item){
            console.log(item)
            this.tableData=this.tableData.filter(x=>x.label!=item.label)
        },
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        widget(){
            this.init()
        }
    }
}
</script>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row>
            <v-col cols="auto" class="d-flex mb-2" align="right">
                <span :style="{backgroundColor: '#00E676',padding:'5px',marginRight:'5px'}" ></span><h5 :style="{marginRight:'10px'}">Running</h5>
                <span :style="{backgroundColor: '#F9A825',padding:'5px',marginRight:'5px'}" ></span><h5>Idling</h5>
            </v-col>
        </v-row> -->
        <v-row v-if="usageData && usageData.length>0">
            <v-col>
                <v-row class="mx-1 mb-2 pt-1">
                    <v-icon class="mr-3">mdi-timeline-clock-outline</v-icon><h4>Total Duration</h4>  
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-row>
                    <v-col cols="auto" class="d-flex" align="right" v-for="(item,index) in usageData" :key="index">
                        <div :style="[{'display':'flex','justify-content':'start'}]">
                            <span :style="{display:'flex', alignItems:'center'}">
                                <span :style="{backgroundColor: getStatusColorClass(item.status),padding:'5px',marginRight:'5px'}" ></span><body :style="{marginRight: '10px'}">{{ item.status }}: {{getFormatDuration(item && item.total_duration_in_seconds?item.total_duration_in_seconds:1) }}</body>
                                <!-- <span :style="{backgroundColor: getStatusColorClass(item.status),padding:'5px',marginRight:'5px'}" ></span><body :style="{marginRight: '10px'}">{{ item.status }}: {{ (item.total_duration_in_seconds / 3600).toFixed(0) }}</body> -->
                            </span>
                            <!-- <v-divider vertical :dark="$store.getters.getColorPalette().isDark"/> -->
                            <!-- <v-card-subtitle class="sub-heading">{{ item.status }}: {{Number(item.total_duration_in_seconds/3600).toFixed() }}</v-card-subtitle> -->
                            <v-divider vertical :dark="$store.getters.getColorPalette().isDark"/>
                        </div>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
            </v-col>
        </v-row>
        <div  ref="vis"></div>
        <div v-if="!(dbData && dbData.length>0)" :style="[{'display':'flex','justify-content':'center'}]">
            <v-icon class="mr-3">mdi-database-remove-outline</v-icon><h3>No data to show</h3>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import Plotly from 'plotly.js'
// import moment from 'moment'

export default {
    name: 'MachineStatusTimelineGraph',
    props: ['machine_id', 'start_time', 'end_time'],
    components: {
        InfoAlert
    },
    data() {
        return {
            loading: false,
            valid: false,
            showDismissibleAlert: false,
            info: '',
            dbData: [],
            usageData: [],
            graphData: {},
            layout: {
                font: { color: this.$store.getters.getColorPalette().accentCode, size: 10 }, // Decreased font size for x-axis labels
                plot_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                hoverlabel: { namelength: -1 },
                height:200,
                xaxis: {
                    // tickangle: -12,
                    // showgrid: true,
                    zeroline: false,
                    // showline: true,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                yaxis: {
                    // showgrid: true,
                    zeroline: false,
                    showline: false,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                    // title: 'Machine Status'
                },
                margin: {
                    t:10,
                    l:35,
                    r:35,
                    b:25
                },
                showlegend: true
            }

        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        createGraph() {
            // let trace1 = {
            //     x: [],
            //     y:[1, 1],
            //     orientation: 'h',
            //     type: 'bar',
            //     name: 'Offline',
            //     opacity:0.5,
            //     line: { color: 'red', width: 20 },
            //     mode: 'lines',
            // };
            // let trace2 = {
            //     x: [],
            //     y:[1, 1],
            //     opacity:0.5,
            //     orientation: 'h',
            //     type: 'bar',
            //     name: 'Idling',
            //     line: { color: 'yellow', width: 30 },
            //     mode: 'lines',
            // };
            // let trace3 = {
            //     x: [],
            //     y:[1, 1],
            //     opacity:0.5,
            //     orientation: 'h',
            //     type: 'bar',
            //     name: 'Running',
            //     line: { color: 'green', width: 20 },
            //     mode: 'lines',
            // };
            // let index=0
            // for (let i of this.dbData) {
            //     let start_timestamp = moment.unix(i.timestamp).format('DD-MM HH:mm');
            //     let end_timestamp = moment.unix(this.dbData[index+1]['timestamp']).format('DD-MM HH:mm');
            //     switch (i.status) {
            //         case 'running':
            //             trace3['x'].push(start_timestamp,end_timestamp);
            //             break;
            //         case 'idling':
            //             trace2['x'].push(start_timestamp,end_timestamp);
            //             break;
            //         default:
            //             trace1['x'].push(start_timestamp,end_timestamp);
            //             break;
            //     }
            // }
            // var data = [trace1, trace2, trace3];

            // let count=0
            // for (let i of this.dbData) {
            //     let timestamp = moment.unix(i.timestamp).format('DD-MM HH:mm');
            //     let temp=`trace${count}`
            //     let lineColor=i.status==='running'?'#00E676':'#F9A825'
            //     this.graphData[temp]={
            //         x:[timestamp],
            //         y:['Status'],
            //         orientation: 'h',
            //         type: 'bar',
            //         opacity:0.9,
            //         marker:{
            //             color:lineColor,
            //         },
            //     }
            //     count=count+1
            // }

            this.graphData={}
            for (let i of this.dbData) {
                let lineColor = i.status === 'running' ? '#00E676' : (i.status === 'idling' ? '#F9A825' : '#D32F2F');
                let timestamp = new Date(i.timestamp * 1000);
                if(!(i.status in this.graphData)){
                    this.graphData[i.status]={
                        x: [],
                        y: [],
                        name:i.status,
                        orientation: 'h',
                        mode:'markers',
                        // type:'line',
                        opacity:0.9,
                        marker: {
                            color:lineColor,
                            // size: 30,
                            symbol: 'square'
                        },
                        width: 1
                    }
                }
                this.graphData[i.status]['x'].push(timestamp)
                this.graphData[i.status]['y'].push("Status")
            }
            Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, { displaylogo: false });
        },
        getUsageData() {
            let payload = {
                machine_id: this.machine_id,
                from_timestamp:this.start_time,
                to_timestamp:this.end_time
                // from_timestamp:1717957800,
                // to_timestamp:1718040600
            };
            axios.post(this.$store.state.api + 'getMachineUsageDataBetweenTimestamp', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
            .then(response => {  
                    if (response.data.status === 'success') {
                        this.usageData=response.data.data
                        this.loading = false;
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
        },
        getData() {
            let payload = {
                machines: [this.machine_id],
                from_timestamp:this.start_time,
                to_timestamp:this.end_time
                // from_timestamp:1717957800,
                // to_timestamp:1718040600
            };
            axios.post(this.$store.state.api + 'getMachineDataByMachineBetweenTimestamp', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
            .then(response => {  
                    if (response.data.status === 'success') {
                        this.dbData = response.data.data;
                        this.loading = false;
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
        },
        getFormatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            return `${hours} Hr ${minutes} min`;
        },
        getStatusColorClass(status) {
            switch(status){
                case "Offline":
                    return '#D32F2F'
                case "idling":
                    return '#FFF176'
                case "running":
                    return '#00E676'
            }
        },
    },
    watch: {
        dbData() {
            if (this.dbData && this.dbData.length > 0) {
                this.createGraph();
                this.getUsageData()
            }
        },
        machine_id() {
            if (this.machine_id) {
                this.getData();
            }
        },
        start_time() {
            if (this.start_time) {
                this.getData();
            }
        },
        end_time() {
            if (this.end_time) {
                this.getData();
            }
        }
    }
}
</script>

<style scoped>
</style>

<template>
    <div  @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            @click="dialog=true"
            v-if="$store.state.settingMode"
        >
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="showEdit=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget" >
                    <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog
            v-model="showEdit"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showEdit = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{this.widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items><v-btn :dark="$store.getters.getColorPalette().isDark" text @click="showEdit = false">{{$store.getters.getTextMap().close}}</v-btn></v-toolbar-items>
                </v-toolbar>
                <AddTODBillingWidget :widget="widget" v-on:success="$emit('update')" v-on:close="showEdit=false"/>
            </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <span v-if="widget && widget.widget_id &&widget.meta && widget.meta.machines && widget.meta.machines.length>0 && from_date">
            <TODBillingBarGraph :machines="widget.meta.machines" :from_date="from_date" :graph_label="widget.label"/>
        </span>
        <span v-else :style="[{'height':height}]" >
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().right_click_to_configure}}</h5>
        </span> 
    </div>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
import AddTODBillingWidget from '@/components/crud_components/widgets/AddTODBillingWidget.vue';
import TODBillingBarGraph from '@/components/graphs/TODBillingBarGraph.vue'
export default {
    name: "TODBillingWidget",
    props:['widget','disableTimeframe','timeframe'],
    components:{
        AddTODBillingWidget,
        TODBillingBarGraph,
        InfoAlert,
    },
    data() {
        return {
                showDialog:false,
                dialog:false,
                showEdit:false,
                loading:false,
                x: 0,
                y: 0,
                showMenu:false,
                info:'',
                showDismissibleAlert:false,
                from_date:null
        };
    },
    mounted() {
        this.getFromDate()
    },
    computed:{
        width(){
            if(this.widget.height==='fixed'){
                return '200'
            }
            return null
        },
        height(){
            if(this.widget.height==='fixed'){
                return '175px'
            }
            return null
        },
    },
    methods: {
        show (e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if(this.$store.state.settingMode){
                this.$nextTick(() => {
                    this.showMenu = true
                })
            }
        },
        getFromDate(){
            let tf=null
            if(this.timeframe && this.disableTimeframe){
                tf=this.timeframe
            }else{
                tf=this.widget.meta && this.widget.meta.time_frame?this.widget.meta.time_frame:'mtd'
            }
            this.from_date = moment().subtract(1, 'days');
            switch (tf) {
                case '7':
                    this.from_date = moment().subtract(7,'days').format('YYYYMMDD');
                    break;
                case '30':
                    this.from_date = moment().subtract(30,'days').format('YYYYMMDD');
                    break;
                case '60':
                    this.from_date = moment().subtract(60,'days').format('YYYYMMDD');
                    break;
                case '90':
                this.this.from_date = moment().subtract(90,'days').format('YYYYMMDD');
                    break;
                case '180':
                    this.from_date = moment().subtract(180,'days').format('YYYYMMDD');
                    break;
                case '365':
                    this.from_date = moment().subtract(365,'days').format('YYYYMMDD');
                    break;
                case 'yesterday':
                    this.from_date = moment().subtract(1,'days').format('YYYYMMDD');
                    break;
                case 'ytd':
                    this.from_date = moment().startOf('year').format('YYYYMMDD');
                    break;
                default:
                    this.from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                    break;
            }
        },
        deleteWidget(){
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.dialog=false
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            });
        },
        cancel(){
            this.showDialog=false
        },
        confirm(){
            this.showDialog=false
        }
    },
    watch:{
        widget:{
            handler(){
                this.getFromDate()
            },
            deep:true
        }
    }
};
</script>
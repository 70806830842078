<template>
    <v-container fluid :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissableAlert="showDismissableAlert" v-on:close="showDismissableAlert" :info="info" />
        <v-card :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': `1px solid ${$store.getters.getColorPalette().panelBorderColorCode}`, 'background-color': $store.getters.getColorPalette().backgroundColorCode }"
            class="px-2 mb-2">
            <v-card-title class="sub-heading">{{ $store.getters.getTextMap().add_horizantal_bar_trend_widget
                }}</v-card-title>

            <!-- Form Input Fields -->
            <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">

                <v-row class="px-5">
                    <!-- Widget Label -->
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-text-field dense outlined v-if="!loading" v-model="widgetForm.widget_label" :counter="30"
                            :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label"
                            required></v-text-field>
                    </v-col>

                    <!-- Widget width -->
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select v-if="!loading" v-model="widgetForm.width"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :label="$store.getters.getTextMap().widget_width" :items="widthOptions" item-text="label"
                            item-value="value" :menu-props="{ dark: $store.getters.getColorPalette().isDark }" dense
                            :rules="[rules.required]" outlined>
                        </v-select>
                    </v-col>

                    <!-- Widget Height -->
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select v-if="!loading" v-model="widgetForm.height"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :label="$store.getters.getTextMap().widget_height" :items="heightOptions"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" item-text="label"
                            :rules="[rules.required]" item-value="value" dense outlined></v-select>
                    </v-col>


                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>


                    <!-- Widget timeframe like rtd, ytd etc.. -->
                    <v-col align-self="center" cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="timeFrame" :items="tdOptions" :label="$store.getters.getTextMap().timeFrame" dense
                            :rules="[rules.required]" outlined item-text="label" item-value="value"></v-select>
                    </v-col>

                    <!-- Widget timeRange -->
                    <v-col align-self="center" cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="timeRange" :items="trOptions" :label="$store.getters.getTextMap().aggregation"
                            :rules="[rules.required]" dense outlined item-text="label" item-value="value"></v-select>
                    </v-col>

                    <!-- Widget timeRange -->
                    <v-col align-self="center" cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="barmode" :items="barmodeOptions" :label="$store.getters.getTextMap().bar_mode"
                            :rules="[rules.required]" dense outlined item-text="label" item-value="value"></v-select>
                    </v-col>


                    <!-- x-axis label -->
                    <v-col align-self="center" cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="xaxisLabel" :label="$store.getters.getTextMap().xaxis_label" dense outlined
                            :rules="[rules.required]"></v-text-field>
                    </v-col>

                    <!-- y-axis label -->
                    <v-col align-self="center" cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="yaxisLabel" :label="$store.getters.getTextMap().yaxis_label" dense outlined
                            :rules="[rules.required]"></v-text-field>
                    </v-col>
                </v-row>
                </v-form>

                <v-form v-model="formValid" ref="paramForm">
                <v-row>
                    <v-col>
                        <v-card :dark="$store.getters.getColorPalette().isDark"
                            :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().backgroundColorCode }"
                            flat outlined>
                            <v-card-title class="sub-heading">{{ $store.getters.getTextMap().add_parameters
                                }}</v-card-title>
                            <v-row class="px-2">
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                        v-model="form.param_label"
                                        :label="$store.getters.getTextMap().param_label" dense outlined
                                        :rules="[rules.required]"></v-text-field>

                                </v-col>
                                <!-- Workspace Options -->
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                        v-model="workspace" :items="workspaceOptions"
                                        :label="$store.getters.getTextMap().workspace" outlined dense item-text="label"
                                        item-value="value" required :rules="objectRules"></v-select>
                                </v-col>


                                <!-- Machine Options based on the workspaces -->
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                        v-model="machine" :items="machineOptions"
                                        :label="$store.getters.getTextMap().machine" outlined dense item-text="label"
                                        item-value="value" required :rules="objectRules"></v-select>
                                </v-col>

                                <!-- Param Options based on the machines-->
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                                        :rules="[rules.required]" v-model="form.param" :items="paramOptions"
                                        :label="$store.getters.getTextMap().parameters" outlined dense multiple
                                        item-text="label" item-value="value" required></v-select>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" class="text-center ">
                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined
                            @click="addParamToList" class="mr-3"> {{ $store.getters.getTextMap().add_to_list }} </v-btn>
                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined
                            @click="onReset()">{{ $store.getters.getTextMap().clear }}</v-btn>
                    </v-col>
                </v-row>



                <v-row class="pa-2" v-if="!loading && tableData">
                    <v-col>
                        <v-simple-table height="200px"
                            :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left"><strong>{{ $store.getters.getTextMap().label }}</strong>
                                        </th>
                                        <th class="text-left"><strong>{{ $store.getters.getTextMap().name }}</strong>
                                        </th>
                                        <th class="text-left"> <strong>{{ $store.getters.getTextMap().id }}</strong>
                                        </th>
                                        <th class="text-left"> <strong>{{ $store.getters.getTextMap().type }}</strong>
                                        </th>
                                        <th class="text-left">{{ $store.getters.getTextMap().delete }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Deleting parameter from the list -->
                                    <tr :key="item.param_label" v-for="item in tableData">
                                        <td>{{ item.param_label }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.param }}</td>
                                        <td>{{ item.idType }}</td>
                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteFromTable(item)"
                                                title="Delete Confirmation"
                                                description="Are you sure you want to delete this?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="text-center">
                        <v-btn :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().submitbtnColor" small filled
                            @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                        <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().cancelbtnColor" small
                            @click="onClose">{{ $store.getters.getTextMap().cancel }}</v-btn>
                    </v-col>
                </v-row>

            </v-form>



        </v-card>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert';
import FormRules from '@/utillities/FormRules';
import axios from 'axios';
import DeleteConfirmation from '@/components/DeleteConfirmation';


export default {
    name: "AddHorizantalBarTrendWidget",
    components: {
        InfoAlert,
        DeleteConfirmation
    },
    props:["widget","seq"],
    mounted(){
        this.init()
    },
    data() {
        return {
            showDismissableAlert: false,
            info: '',
            widgetValid: false,
            loading: false,
            widgetForm: {},
            rules: FormRules.rules,
            timeRange: null,
            timeFrame: null,
            yaxisLabel: null,
            xaxisLabel: null,
            workspace: null,
            machineOptions: null,
            machine: null,
            paramOptions: null,
            tableData: [],
            barmode:null,
            formValid:false,
            form:{},
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                { label: '3/4 screen', value: 9 },
                { label: 'Auto', value: null }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            tdOptions: [
                { label: 'MTD (Month Till Date)', value: 'mtd' },
                { label: 'YTD (Year Till Date)', value: 'ytd' },
                { label: 'last 7 days', value: '7' },
                { label: 'last 30 days', value: '30' },
                { label: 'last 60 days', value: '60' },
                { label: 'last 90 days', value: '90' },
                { label: 'last 180 days', value: '180' },
                { label: 'last 365 days', value: '365' },
            ],
            barmodeOptions: [
                { label: 'Stacked', value: 'stack' },
                { label: 'Grouped', value: 'group' }
            ],
            trOptions: [
                { label: 'Daily', value: 'day' },
                { label: 'Weekly', value: 'week' },
                { label: 'Monthly', value: 'month' },
                { label: 'Yearly', value: 'year' },
            ],
            objectRules: [
                v => !!v || 'Required',
            ],
        }
    },
    computed: {
        // Workspaces input fiels data
        workspaceOptions() {
            let op = []
            let g = this.$store.state.workspaces
            for (let i of g) {
                op.push({ value: i.workspace_id, label: i.name })
            }
            return op
        }
    },
    methods: {
            init() {
            // If widget created already and widget data is there.
            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true
                this.widgetForm = Object.assign({}, this.widget)
                this.api = 'updateWidget'
                if (!(this.widgetForm.meta)) {
                    this.widgetForm.meta = { data: [] }
                    this.tableData = []
                } else {
                    this.tableData = this.widgetForm.meta.data
                    this.barmode = this.widgetForm.meta.barmode
                    this.legend = this.widgetForm.meta.legend
                    this.timeFrame=this.widgetForm.meta.time_frame
                    this.timeRange=this.widgetForm.meta.time_range
                    this.yaxisLabel = this.widgetForm.meta.yaxisLabel
                    this.xaxisLabel = this.widgetForm.meta.xaxisLabel
                }
            } else {
                // There is no widget for view then this will initiate
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView'
                    this.widgetForm = Object.assign({}, this.widget)
                    this.widgetForm.meta = { data: [] }
                    this.widgetForm.seq = this.seq ? this.seq : 0
                    this.tableData = []
                }
            }
        },

        // Machine field options data
        getMachineList() {
            let op = []
            if (this.workspace) {
                let payload = {
                    workspace_id: this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        if (response.data.status == "success") {
                            let machines = response.data.machines
                            for (let i of machines) {
                                if (i && i.name) {
                                    op.push({ value: i.machine_id, label: i.name })
                                } else {
                                    op.push({ value: i.machine_id, label: i.machine_id })
                                }
                            }
                        } else {
                            console.log(response.data.msg)
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error)
                        this.info = error;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    });
            }
            this.machineOptions = op
        },

        getParams() {
            if (this.machine) {
                this.loading = true
                axios.post(this.$store.state.api + 'getParamsByMachine', { machine_id: this.machine }, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        if (response.data.status == 'success') {
                            this.loading = false
                            this.paramOptions = []
                            for (let i of response.data.params) {
                                this.paramOptions.push({ label: i.name, value: i.param_id })
                            }
                        } else {
                            console.log(response.data.msg)
                            this.loading = false
                            this.info = response.data.msg
                            this.showDismissibleAlert = true
                        }
                    }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.info = err
                        this.showDismissibleAlert = true
                    })
            }

        },
        addParamToList() {
            console.log("called");
            
            this.$refs.paramForm.validate()
            if (this.formValid) {
                console.log("insdide");
                this.form['name'] = []
                this.form['idType'] = "parameter"
                this.form.param.forEach((item) => {
                    let name = this.$store.getters.getNameByParamId(item)
                    this.form.name.push(name)
                })
                this.tableData.push(this.form)
                console.log(this.tableData);
                this.form={}
                this.onParamFormReset()
                

            }

        },
        onParamFormReset(){
            this.$refs.paramForm.reset();
        },
        onReset() {
            this.$refs.widgetForm.reset();
        },
        deleteFromTable(item) {
            this.tableData = this.tableData.filter(x => x.param_label != item.param_label)
        },
        onClose(){
            this.$emit('close')
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
                this.widgetForm['meta']={}
                this.widgetForm.meta['data']=this.tableData
                this.widgetForm['label']=this.widgetForm.widget_label
                this.widgetForm.meta['time_frame'] = this.timeFrame
                this.widgetForm.meta['time_range'] = this.timeRange
                this.widgetForm.meta['barmode']=this.barmode
                this.widgetForm.meta['legend']=false
                this.widgetForm.meta['xaxisLabel']=this.xaxisLabel
                this.widgetForm.meta['yaxisLabel']=this.yaxisLabel

                console.log(this.widgetForm);
                axios.post(this.$store.state.api + this.api, this.widgetForm, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.loading = false
                        this.$emit('success')
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading = false
                });        
            }
        }

    },
    watch: {
        workspace() {
            this.getMachineList()
        },
        machine() {
            this.getParams()
        },
    }
}
</script>

<style></style>
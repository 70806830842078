<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
         <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Table TD Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Table TD Widget</span>
            </v-col>
        </v-row> -->
        <v-card flat outlined :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" class="px-2 mb-2">  
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_table_TD_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_table_TD_widget}}</v-card-title>   
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
            <v-row>
                <v-col align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field v-if="!loading" v-model="widgetForm.label" outlined dense :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.height"
                        :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                <v-col v-if="!disableTimeframe" align-self="center">
                    <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="timeFrame"
                        :items="tdOptions"
                        :label="$store.getters.getTextMap().timeFrame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
            </v-row>
            <v-row v-if="edit">
                <v-col cols="3" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                            ></v-text-field>
                </v-col>
            </v-row>
        </v-form>
        </v-card>

            <v-card flat outlined :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" class="pa-2">
              <v-card-title>{{$store.getters.getTextMap().configure_table}}</v-card-title>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-simple-table  fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                            <template v-slot:default>
                            <thead>
                                <tr >
                                    <th :class="'white--text text-center  text-subtitle-1 text-capitalize '+$store.getters.getColorPalette().foregroundColorName"></th>
                                    <th v-for="(item,index) in tableData.headers"
                                        class="text-center"
                                        :key="item" :class="$store.getters.getColorPalette().foregroundColorName" :color="$store.getters.getColorPalette().accentCode">
                                        <strong>{{item}}</strong><span>
                                        <v-btn small   icon @click="editHeader(index)" color="blue" >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn small   icon color="red" >
                                            <DeleteConfirmation v-on:confirm="deleteHeader(index)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">                                                                         
                                                <v-icon small>mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </v-btn></span>
                                    </th>
                                    <th  :class="'text-center  text-subtitle-1 text-capitalize '+$store.getters.getColorPalette().foregroundColorName" >
                                        <v-btn small   icon @click="editHeader(tableData.headers.length)"  :color="$store.getters.getColorPalette().accentCode">
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn> 
                                    </th>
                                </tr>
                            </thead>
                            <v-divider></v-divider>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}" v-if="!loading" >
                                <tr v-for="(param,paramIndex) in tableData.params" :key="paramIndex" :style="{'background-color':$store.getters.getColorPalette().background1ColorCode}">
                                    <td :color="$store.getters.getColorPalette().accentCode"><strong>{{param.name}}</strong>
                                        <v-btn small   icon @click="editName(paramIndex)" :color="$store.getters.getColorPalette().accentCode" >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-center  " v-for="(item,index) in param.param" :key="index">{{columnData}}
                                        <v-btn small   icon @click="editParam(paramIndex,index)" color="blue" >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn small  icon :color="$store.getters.getColorPalette().deletebtnColor" >
                                            <DeleteConfirmation v-on:confirm="deleteParam(paramIndex,index)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                                                <v-icon small>mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </v-btn>
                                    </td>
                                    <td  class="text-center text-capitalize ">
                                        <v-btn small  @click="editParam(paramIndex,param.param.length)"   icon  color="green" >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </td>
                                    <td  class="text-right text-capitalize ">
                                        <v-btn   icon  :color="$store.getters.getColorPalette().deletebtnColor">
                                            <DeleteConfirmation v-on:confirm="deleteRow(paramIndex)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                                                <v-icon small>mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </v-btn>
                                    </td>  
                                </tr>
                                <tr >
                                    <td>
                                        <v-btn :dark="$store.getters.getColorPalette().isDark"  @click="addRow" small text outlined>Add Row
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <GetTextModal :show="headerEdit" v-on:close="headerEdit=false" v-on:value="updateHeader" />
            <GetTextModal :show="nameEdit" v-on:close="nameEdit=false" v-on:value="updateName" />
            <GetParamModal :show="paramEdit" v-on:close="paramEdit=false" v-on:value="updateParam" />
        </v-card>
           <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex mt-2" align="right">
                <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                 <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
 </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import GetTextModal from '@/components/modals/GetTextModal'
import GetParamModal from '@/components/modals/GetParamModal'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'AddTableWidget',
    props:['widget','seq','disableTimeframe'],
    components:{
        InfoAlert,
        GetTextModal,
        GetParamModal,
        DeleteConfirmation
    },
 
    mounted(){
        this.init()
    },
    data(){
        return {
             edit:false,
              headerIndex:null,
            headerEdit:false,
            nameIndex:null,
            nameEdit:false,
            parIndex:null,
            paramEdit:false,
            paramValue:null,
            api:'createWidgetForView',
            counter:0,
            count:0,
            show:false,
            dialog:false,
            dialogss:false,
            loading:false,
            valid:false,
            ValidMulti:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            workspace:null,
            machine:null,
            legend:false,
            textInfo:null,
            columnData:null,
            equation:'',
            form:{
                
            },
            widgetForm:{},
            machineOptions:[],
            paramOptions:[],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            tdOptions:[
                {label:'Real Time', value:'rtd'},
                {label:'last 7 days', value:'7days'},
                {label:'last 30days', value:'30days'},
                {label:'last 60days', value:'60days'},
                {label:'last 180days', value:'180days'},
                {label:'MTD', value:'mtd'},
                {label:'YTD', value:'ytd'}
            ],
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
             countRules:[

                v => !!v || 'Required',

                v => (v && v>1) || 'Number of Machine should be greater than 1'

            ],
             tableData:{
                headers:[],
                params:[
                    // {name:'RPM',param:['rpm1','rpm2','rpm3','rpm4']},
                    // {name:'Current',param:['current1','current2','current3','current4'],unit:'Amps'},
                    // {name:'Run time',param:['run1','run2']},
                    // {name:'KWH',param:['kwh1']},
                ]
            }
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
            let g=this.$store.state.workspaces
            for(let i of g){
                //op.push({value:i, text: i.name })
                op.push( {value:i.workspace_id, label: i.name })
                }
            return op
        },
         timeFrame:{
            get: function (){
            if(this.widgetForm && this.widgetForm.meta){
                return this.widgetForm.meta.time_frame
            }else{
                return null
            }
        },
        set: function (newValue){

            if(this.widgetForm && this.widgetForm.meta){
                this.widgetForm.meta.time_frame=newValue
            }else{
                this.widgetForm.meta={time_frame:newValue}
            }
            
        }
        },
         headers(){
            return this.tableData['headers']
        }

    },
    methods:{
          init(){
           
            if(this.widget && this.widget.widget_id && this.widget.view_id){
            this.edit=true
            this.api='updateWidget'
            this.widgetForm=Object.assign({},this.widget)
            if(!(this.widgetForm.meta)){
                this.widgetForm.meta={data:[]}
                this.tableData=[]
            }else{
                this.tableData=this.widgetForm.meta.data
                //console.log( this.tableData,"checktable")
                this.legend=this.widgetForm.meta.legend
                this.textInfo=this.widgetForm.meta.textinfo
            }
 //console.log(this.widget,"init")
 //console.log(this.widgetForm,"initform")
        }else{

            if(this.widget.view_id){
                this.api='createWidgetForView'
                
                this.widgetForm=Object.assign({},this.widget)
                
                this.widgetForm.meta={data:[]}
                this.widgetForm.seq=this.seq?this.seq:0
                 

                // this.tableData=[]

            }

        }
        },
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            if(this.machine ){
                this.loading=true
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
         deleteView(){
            let payload={
                widget_id:this.widgetId
            }
            axios.post(this.$store.state.api+'deleteView',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.edit=false
                            this.$store.dispatch('refreshViews')
                           
                           
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
        },
        discard(){
            this.tableData=Object.assign({},this.cache)
            this.edit=false
            
        },
        startEdit(){
            this.cache=Object.assign({},this.tableData)
            this.edit=true
        },
        addRow(){
            this.tableData.params.push({name:'Unassigned',param:[]})
        },
        deleteRow(index){
            this.tableData.params.splice(index,1)
        },
        editName(index){
            this.nameIndex=index
            this.nameEdit=true

        },
        updateName(value){
            this.tableData['params'][this.nameIndex]['name']=value
            
            //console.log(this.tableData,"tdd")
        },
        editHeader(index){
            this.headerIndex=index
            this.headerEdit=true
        },
        updateHeader(value){
            let headers=this.tableData['headers'].slice()
            headers[this.headerIndex]=value
            this.tableData['headers']=headers
            //console.log(this.tableData)
        },
        deleteHeader(index){
            this.tableData.headers.splice(index,1)
        },
        editParam(paramIndex,index){
            this.parIndex=[paramIndex,index]
            this.paramEdit=true
            //console.log(this.parIndex)
        },
        deleteParam(paramIndex,index){
            this.tableData.params[paramIndex]['param'].splice(index,1)
        },
        updateParam(value){
            let params=this.tableData['params'][this.parIndex[0]]['param'].slice()
            params[this.parIndex[1]]=value
            this.tableData['params'][this.parIndex[0]]['param']=params
        // console.log(this.tableData,"tdd11")  
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        },




      onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.widgetForm.meta.data=this.tableData
            this.widgetForm.meta.legend=this.legend
            this.widgetForm.meta.textinfo=this.textInfo
            console.log(this.widgetForm,"wform")
            console.log(this.api,"wform")
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
            this.loading=false
            //this.dialog=false
            this.$emit('success')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
            
        },
        
        onReset(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
        onClose(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
        removeParam(param){
            //console.log("remove param")
            //console.log(param)
            //this.stream.emit('removeParam',param)
            this.form.params=this.form.params.filter(x=>x!=param)
            //this.parameter.delete(param)
            
        },
        deleteFromTable(item){
           // console.log(item)
            this.tableData=this.tableData.filter(x=>x.label!=item.label)
        },
        
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        widget(){
            this.init()
        }
    }
}
</script>
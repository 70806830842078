<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row no-gutters>
            <v-col ><span class="statusHighlight" :style="'background-color:'+getColorByStatus(status)"><strong>{{status }}</strong></span></v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import { io } from "socket.io-client";
import axios from 'axios'
import moment from 'moment'
import BinaryMathOperations from  '@/utillities/BinaryMathOperations'
export default {
    name:'ProcessStatusComponent',
    props:['rawData'],
    components:{
        InfoAlert
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            ruleData:{},
            tag_types:[],
            tagTypesMachineMap:{},
            tagParamMap:[],
            streamData: {},
            status:'loading',
            count:{
                offline:0,
                running:0,
                idling:0,
            },
            mathOperation:BinaryMathOperations.performOperation
        }
    },
    created() {
        this.stream = io(this.$store.state.streamApi, {withCredentials: true,auth: { token: this.$store.state.jwt },});
    },
    beforeDestroy() {
        this.stream.close();
    },
    mounted(){
        if(this.rawData && this.rawData.length>0){
                this.createRuleStructure()
        }
    },
    methods:{
        startStream(){
            this.stream.on("connect_error", (err) => {console.log(err.message);});
            this.stream.on("error", (data) => {console.error(data);this.status='offline'});
            this.stream.on("param", (data) => {
                if (data.constructor == String) {
                    data = JSON.parse(data);
                }
                this.streamData = Object.assign({}, this.streamData);
                this.streamData[data.param_id]=data
                this.$emit('success')
                let proceedForStatusCheck=this.checkTimestampCondition(data.timestamp)
                if(proceedForStatusCheck){
                    this.checkStatus(this.streamData)
                }else{
                    this.status='offline'
                }
            })
        },
        checkTimestampCondition(timestamp){
            let streamDataTimestamp = moment.unix(timestamp);
            let differenceInMinutes = moment().diff(streamDataTimestamp, 'minutes');
            return differenceInMinutes < 5
        },
        checkStatus(newData) {
            this.status = Object.keys(this.ruleData).find((status) =>
                this.ruleData[status].every((rule) => {
                    if (this.tagParamMap && rule.tag_type && this.tagParamMap[rule.machine_id][rule.tag_type] && newData[this.tagParamMap[rule.machine_id][rule.tag_type]]) {
                        return this.mathOperation(newData[this.tagParamMap[rule.machine_id][rule.tag_type]].data, rule.operator, rule.operand);
                    }else{
                        return false;
                    }
                })
            );
            if (!this.status) {
                this.status = 'offline';
            }

            this.$emit('success');
        },
        getData(){
            // console.log(this.tagParamMap)
            this.$emit('success')
            for (let i of Object.keys(this.tagParamMap)) {
                for(let j of this.tag_types){
                    if(this.tagParamMap[i][j]){
                        this.stream.emit("getParam", this.tagParamMap[i][j]);
                    }
                }
            }
        },
        async createTagTypeParamMap(){
            try{
                let temp={}
                for(let i of Object.keys(this.tagTypesMachineMap)){
                    let tag_types=[...this.tagTypesMachineMap[i]]
                    let response=await axios.post(this.$store.state.api + "getTagTypeParamsByMachineId", {machine_id:i,tag_types},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if (response.data.status == "success") {
                        this.loading = false;
                        for(let j of response.data.data){
                            if(!temp[i]){
                                temp[i]={}
                            }
                            temp[i][j['tag_type']]=j.param_id
                        }
                        if(response.data.data.length<=0){
                            this.$emit('cancel')
                        }
                    } else {
                        this.loading = false;
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                }
                this.tagParamMap=temp
            }catch(error){
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            }
        },
        createRuleStructure(){
            let temp={}
            this.ruleData={}
            this.tag_types=[]
            let tag_types=new Set([])
            for(let i of this.rawData){
                tag_types.add(i.tag_type)
                if(!this.tagTypesMachineMap[i.machine_id]){
                    this.tagTypesMachineMap[i.machine_id]=new Set([])
                }
                this.tagTypesMachineMap[i.machine_id].add(i.tag_type)
                if (!temp[i.process_status]) {
                    temp[i.process_status] = [];
                }
                temp[i.process_status].push({tag_type:i.tag_type,operator:i.operator,operand:i.operand,machine_id:i.machine_id})
            }
            this.tag_types=[...tag_types]
            this.ruleData=temp
            this.createTagTypeParamMap()
        },
        getColorByStatus(status){
            switch(status){
                case "running":
                    return '#00E676'
                case "offline":
                    return '#D32F2F'
                case "idling":
                    return '#F9A825'
                default:
                    return '#D32F2F'
            }
        },
        emitStatus(newStatus,oldStatus){
            this.$emit('status',{newStatus,oldStatus})
        },
    },
    watch:{
        tagParamMap(){
            if(this.tagParamMap){
                this.startStream()
                this.getData()
            }
        },
        status:{
            handler(newStatus, oldStatus) {
                this.emitStatus(newStatus,oldStatus)
            },
            immediate: true,
        }
    }
}
</script>
<style scoped>
.statusHighlight{
    padding:5px;
    border-radius:3px;
    display:inline-block;
}
</style>
<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />

        <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Consumption Gauge Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Consumption Gauge Widget</span>
            </v-col>
        </v-row> -->
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">   
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_consumption_gauge_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_consumption_gauge_widget}}</v-card-title> 
            <v-divider :dark="$store.getters.getColorPalette().isDark" class="mb-2"/>
        <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="widgetForm" v-model="widgetValid">
            <v-row>
                <v-col align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                 <v-col>
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.height"
                            :items="heightOptions"
                            :label="$store.getters.getTextMap().widget_height"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                </v-col>
                <v-col align-self="center">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                <v-col v-if="!disableTimeframe" align-self="center">
                    <v-select
                   :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="timeFrame"
                        :items="tdOptions"
                        :label="$store.getters.getTextMap().timeFrame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-row>
                
                <v-col v-if="edit" align-self="center">
                    <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                            ></v-text-field>
                </v-col>
                <v-col>
                    <v-checkbox
      v-model="linearGauge"
      :label="$store.getters.getTextMap().linearGauge"
    ></v-checkbox>
                </v-col>
                <v-col>
                    <v-checkbox
      v-model="showTarget"
      :label="$store.getters.getTextMap().show_target"
    ></v-checkbox>
                </v-col>
                <v-col>
                    <v-checkbox
      v-model="negativeHealthy"
      :label="$store.getters.getTextMap().healthy_on_below_target"
    ></v-checkbox>
                </v-col>
                <v-col  align-self="center" v-if="!loading && showTarget">
                      <v-text-field 
                      v-model="targetValue"   
                      :label="$store.getters.getTextMap().target_value" 
                      :rules="[rules.required,rules.num]"
                      type="number"  
                      dense
                      outlined
                      >
                            </v-text-field>
                    </v-col>
                    <v-col align-self="center" v-if="!loading && showTarget">
                          <v-text-field 
                           v-model="targetLabel" 
                           type="text" 
                           :label="$store.getters.getTextMap().target_label" 
                           :rules="[rules.required,rules.validString]"
                           dense
                           outlined>
                            </v-text-field>
                        </v-col>

            </v-row>
            <v-divider :dark="$store.getters.getColorPalette().isDark" class="mb-2"/>
            <v-row>
                <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    outlined         
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    
                                ></v-select>
                            </v-col>
                <v-col>
                                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    
                                    ></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-autocomplete
                              :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="params"
                                    :items="paramOptions"
                                    :label="$store.getters.getTextMap().params"
                                    outlined
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    multiple
                                    required
                                    :rules="[rules.required,rules.nonZeroLength]"
                                ></v-autocomplete>
                            </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-chip small class="ma-2" close color="indigo" text-color="white" v-for="param in params" @click:close="removeParam(param)" :key="param">
                                {{getParamName(param)}}
                            </v-chip>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
    <v-row no-gutters>
        <v-spacer/>
        <v-col  cols="auto" class="d-flex mt-2" align="right">
            <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
              <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{ $store.getters.getTextMap().cancel }}</v-btn>
        </v-col>
    </v-row>

        
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
// import CreateMultiParam from '@/components/CreateMultiParam.vue'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'AddConsumptionGaugeWidget',
    props:['widget','seq','disableTimeframe'],
    components:{
        InfoAlert,
        // CreateMultiParam
        // DeleteConfirmation
    },
    mounted(){
        this.init()
    },
    data(){
        return {
            showDialog:false,
            showSingleParam:false,
            showMultiParam:false,
            counter:0,
            count:0,
            show:false,
            dialog:false,
            dialogss:false,
            loading:false,
            valid:false,
            ValidMulti:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            api:'createWidgetForView',
            edit:false,
            workspace:null,
            machine:null,
            params:[],
            graphType:2,
            barmode:'group',
            targetValue:0,
            negativeHealthy:false,
            showTarget:false,
            linearGauge:false,
            targetLabel:'',
            tableData:[],
            paramTemp:null,
            operatorTemp:null,
            paramArray:[],
            // paramSingleFinal:[],
            paramMultiFinal:[],
            equation:'',
            form:{
            },
            widgetForm:{},
            machineOptions:[],
            paramOptions:[],
            barmodeOptions:[
            {label:'Stacked', value:'stack'},
            {label:'Grouped', value:'group'}
            ],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            graphTypeOptions:[
                {label:'Bar', value:2},
                {label:'Line', value:1},
            ],
            tdOptions:[
                {label:'Today', value:'rtd'},
                {label:'Yesterday', value:'yesterday'},
                {label:'MTD (Month Till Date)', value:'mtd'},
                {label:'YTD (Year Till Date)', value:'ytd'},
                {label:'last 7 days', value:'7'},
                {label:'last 30 days', value:'30'},
                {label:'last 60 days', value:'60'},
                {label:'last 90 days', value:'90'},
                {label:'last 180 days', value:'180'},
                {label:'last 365 days', value:'365'},
            ],
            
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
             
            
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        
        timeFrame:{
            get: function (){
            if(this.widgetForm && this.widgetForm.meta){
                return this.widgetForm.meta.time_frame
            }else{
                return null
            }
        },
        set: function (newValue){

            if(this.widgetForm && this.widgetForm.meta){
                this.widgetForm.meta.time_frame=newValue
            }else{
                this.widgetForm.meta={time_frame:newValue}
            }
            
        }
        }


        
    },
    methods:{
        init(){
            if(this.widget && this.widget.widget_id && this.widget.view_id){
            this.edit=true
            this.widgetForm=Object.assign({},this.widget)
            this.api='updateWidget'
            if(!(this.widgetForm.meta)){
                this.widgetForm.meta={data:[]}
                this.params=[]
            }else{
                this.params=this.widgetForm.meta.params

                this.targetValue=this.widgetForm.meta.targetValue
                this.targetLabel=this.widgetForm.meta.targetLabel
                this.negativeHealthy=this.widgetForm.meta.negativeHealthy
                this.linearGauge=this.widgetForm.meta.linearGauge
                // this.legend=this.widgetForm.meta.legend
                this.showTarget=this.widgetForm.meta.showTarget
            }
        }else{
            if(this.widget.view_id){
                this.api='createWidgetForView'
                this.widgetForm=Object.assign({},this.widget)
                this.widgetForm.meta={params:[]}
                this.widgetForm.seq=this.seq?this.seq:0
                this.tableData=[]
            }
        }
        },
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
      
       
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.showSingleParam=false
            this.showMultiParam=false
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.paramTemp=null
            this.$refs.usageFormMulti.reset()
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.widgetForm.meta.params=this.params

            this.widgetForm.meta.targetValue=this.targetValue
            this.widgetForm.meta.targetLabel=this.targetLabel
                       this.widgetForm.meta.showTarget=this.showTarget
                       this.widgetForm.meta.negativeHealthy=this.negativeHealthy
                       this.widgetForm.meta.linearGauge=this.linearGauge
           // console.log(this.widgetForm.meta,"targetValue")
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
            this.loading=false
            //this.dialog=false
            this.$emit('success')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onClose(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },
        removeParam(param){
            //console.log("remove param")
            //console.log(param)
            //this.stream.emit('removeParam',param)
            this.params=this.params.filter(x=>x!=param)
            //this.parameter.delete(param)
            
        },
        deleteFromTable(item){
            console.log(item)
            this.tableData=this.tableData.filter(x=>x.label!=item.label)
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }

    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        widget(){
            this.init()
        }

    }
}
</script>
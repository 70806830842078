<template>
   <v-container @contextmenu="show" fluid class="px-0 py-0" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}" >
        <LoadingComponent :loading="loading"/>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-container v-if="settingOptions">
            <v-row v-if="showEdit && $store.state.user && $store.state.settingMode ">
                <v-col cols="auto" class="d-flex">
                    <v-chip
                        v-if="
                        $store.state.user &&
                        $store.state.settingMode &&
                        $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        ><v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{
                            color: $store.getters.getColorPalette().background2ColorCode,
                        }"
                        :class="
                            $store.getters.getColorPalette().foregroundColorName +
                            ' mx-0 px-0'
                        "
                        ><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-identifier</v-icon></v-avatar>
                        {{ view.view_id }}
                        </v-chip>
            </v-col>
            <v-col cols="auto" class="d-flex">
                    <v-btn class="mr-1" @click="setAsDefault" :color="$store.getters.getColorPalette().accentCode" small text outlined> {{ $store.getters.getTextMap().add_to_dashboard}} <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-eye-arrow-left</v-icon></v-btn>
                    <EditViewBottomSheet :view="view"/>
                </v-col>
                <v-col  cols="auto" class="d-flex ml-auto" align="right">
                    <DeleteConfirmation v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this View?"> 
                        <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete }}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                        </v-btn>
                    </DeleteConfirmation>
                </v-col>
                <!-- <v-col cols="1"><span><v-icon dark small @click="getWidgets">
                mdi-refresh
              </v-icon></span></v-col> -->
            </v-row>
        </v-container>
        <div>
            <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y v-if="edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-list-item @click="createMachineScrollViewForm=true">
              <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
          </v-list-item>
          <!-- <v-list-item @click="refresh">
              <v-list-item-title><strong>Delete</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon color="red" small>mdi-trash-can</v-icon>
              </v-list-item-icon>
          </v-list-item> -->
        </v-list>
    </v-menu>
        <div v-if="machine_details" >
            <MachineScrollComponent :machine_details="machine_details"/>
        </div>
        <div  v-else :style="{'text-align':'center'}">
            <v-icon small>mdi-hail</v-icon>
          <h4 :style="{'color':$store.getters.getColorPalette().accentCode ,'margin-top':'10px','text-align':'center'}">{{$store.getters.getTextMap().not_configured}} </h4>
          <h5 :style="{'color':$store.getters.getColorPalette().accentCode ,'margin-top':'10px','text-align':'center'}">{{$store.getters.getTextMap().right_click_to_configure}}</h5>
        </div>
        </div>
        
        <CreateMachineScroll v-if="createMachineScrollViewForm===true" v-on:close="createMachineScrollViewForm=false"  v-on:value="updateMachineScrollView"/>
    </v-container>
</template>

<script>
    import InfoAlert from '@/components/InfoAlert'
    import DeleteConfirmation from '@/components/DeleteConfirmation'
    import LoadingComponent from '@/components/LoadingComponent'
    import EditViewBottomSheet from '@/components/modals/EditViewBottomSheet'
    import CreateMachineScroll from '@/components/crud_components/CreateMachineScroll'
    import MachineScrollComponent from '@/components/MachineScrollComponent'
    import axios from 'axios'
    export default {
        name:'MachineScrollViewPage',
        props:{
        'view':{
            type:Object
        },
        showEdit:{
            type:Boolean,
            default:true
        },
        edit:{
           type:Boolean,
            default:true
        },
         settingOptions:{
           type:Boolean,
            default:true
        }
    },
        components:{
            InfoAlert,
            EditViewBottomSheet,
            LoadingComponent,
            MachineScrollComponent,
            CreateMachineScroll,
            DeleteConfirmation
        },
        mounted(){
            // this.getWidgets()
            console.log("mmm")
            this.init()
        },
        data(){
            return {
                showDialog:false,
                loading:false,
                info:'',
                seq:0,
                x:0,
                y:0,
                showDismissibleAlert:false,
                showMenu:false,
                createMachineScrollViewForm:{},
                machine_details:null,  
                editMode:this.edit,
            }
        },
        computed:{

        },
        methods:{
            show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },    
      init(){
        console.log(this.view,'view')
        if(this.view && this.view.view_id && this.view.data){
            this.machineScrollViewData=Object.assign({},this.view.data)
            this.api='updateView'
            this.createMode=false
            this.viewName=this.view.name
            this.viewId=this.view.view_id
            this.getData()
        }else{
            this.machineScrollViewData={}
            this.api='createView'
            this.editMode=true
            this.createMode=true
        }
      },
    getData(){
        this.machine_details=this.machineScrollViewData['machine_details']
          console.log( this.machine_details,"data")
    },
       showParamScrollViewForm(){
             this.createMachineScrollViewForm=true
            },

    updateMachineScrollView(value){
                let rawData={
                    machine_details:value
                }
                this.machineScrollViewData = rawData
             console.log( this.machineScrollViewData,"data")
                this.save()
                this.createMachineScrollViewForm=false
            },
            save(){
            this.loading=true
            let payload={
                view_id:this.viewId,
                view_type:'machineScrollView',
                data:this.machineScrollViewData,
                name:this.viewName
            }
                console.log(payload,'payload')  
            axios.post(this.$store.state.api+this.api,payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.edit=false
                        console.log('success')
                            this.$store.dispatch('refreshViews')
                            
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
        },
             setAsDefault(){
                this.loading=true
                let payload={view_id:this.view.view_id}
                axios.post(this.$store.state.api+'updateDashboardView',payload,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                            
                                this.edit=false
                                //this.$store.dispatch('refreshViews')
                                
                            }else{
                                
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                            this.loading=false
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
    
            },
            deleteView(){
                let payload={view_id:this.view.view_id}
                this.loading=true
              axios.post(this.$store.state.api+'deleteView',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                  if(response.data.status==='success'){
                          this.edit=false
                          this.$store.dispatch('refreshViews')
                        //   this.getWidgets()
                  }
                  })
                  .catch(err=>{
                      console.error(err)
                  this.loading=false});
            },
            // addWidgetSuccess(){
                
            //     this.getWidgets()
            // },
             cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }
        },
        watch:{
        view:{
            handler(){
                
               // this.init()
            },
            deep:true
        },
    }

    }
</script>

<style>

</style>
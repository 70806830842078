<template>
        <v-dialog v-model="dialog"  hide-overlay transition="dialog-bottom-transition">
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="valid">
            <v-card   :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" elevation="4">
                <v-card-title class="headline">Machine Scroll View Page</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>  
                                <v-row>  
                                <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                     :dark="$store.getters.getColorPalette().isDark"
                                      :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="workspace"
                                        :items="workspaceOptions"
                                        :label="$store.getters.getTextMap().workspace"
                                        outlined          
                                        item-text="label"
                                        item-value="value"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                      :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="machine"
                                        :items="machineOptions"
                                        :label="$store.getters.getTextMap().machine"
                                        outlined
                                        item-text="label"
                                        item-value="value"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import axios from 'axios'


export default {
    components:{
      
    },
    mounted(){
        this.dialog=true 
    },
    name:'GetParamScrollViewParameters',
    props:['show'],
    data(){
        return {
            valid:false,
            loading:false,
            dialog:false,
            paramOptions:[],
            machineOptions:[],
            workspace:null,
            machine:null,
            form:{},
            params:[],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
    },
    methods:{
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:{'machine_id':i.machine_id,'machine_name':i.name}, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
         
  getParams(){
            //console.log('getParams called')
            console.log(this.machine)
            if(this.machine && this.machine.machine_id){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine.machine_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                           
                            this.params=response.data.params.filter(d=>{return !d.featured})
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
                onSubmit(){
                    this.form['machine']=this.machine

                    console.log(this.form,"form")
            this.$refs.widgetForm.validate()
            if(this.valid){
                this.loading=true
                console.log(this.form,"machinename")
                this.$emit('value',this.form)
                this.$emit('close')
            }

        },
   
        onReset(){
            this.dialog=false,
            this.loading=false
            this.$emit('close')
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
            
        },
        //  machine(){
        //     this.getParams()
        // }

    }
}
</script>
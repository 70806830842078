<template>
    <v-snackbar v-model="show" :color="color" :timeout="timeout" top>
      <v-row>
        <v-col><span>{{ msg }}</span></v-col>
        <v-col cols="1" align="right">
          <v-btn icon small :dark="$store.getters.getColorPalette().isDark"  @click="closeSnackbar"><v-icon small>mdi-close</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </template>
  
  <script>
  import { io } from "socket.io-client";
  export default {
    name:'PopupNotification',
    props: {
      message: String,
      color: {
        type: String,
        default: 'blue',
      },
      timeout: {
        type: Number,
        default: 10000,
      },
    },
    data() {
      return {
        show: false,
        streamData:{},
        msg:{},
        stream:null
      };
    },
    created() {
        this.stream = io(this.$store.state.streamApi, {withCredentials: true,auth: { token: this.$store.state.jwt },});
        // this.stream = io('http://192.168.1.105/stream', {withCredentials: true,auth: { token: this.$store.state.jwt },});
        // console.log(this.$store.state.jwt )
    },
    beforeDestroy() {
        this.stream.close();
    },
    mounted(){
      if(!this.message){
        this.startStream()
      }
    },
    computed:{
      jwt(){
        return this.$store.state.jwt
      }

    },
    methods: {
      init(){
        if(this.stream){
          this.stream.close();
        }
        this.stream = io(this.$store.state.streamApi, {withCredentials: true,auth: { token: this.$store.state.jwt },});

        if(!this.message){
        this.startStream()
      }
      },
      startStream(){
        // console.log('start')
        this.stream.on("connect_error", (err) => {console.log(err.message,'err')});
        this.stream.on("error", (data) => {console.error(data)});
        this.stream.on("notification",(message)=>{
          if(message.constructor==String){
            message=JSON.parse(message)
      }
      message['timestamp']=Date.now()
      let paramName=''
          switch(message['type']){
            case 'paramTrigger':
              this.$store.commit('addNotifications',message)
              
              paramName=this.$store.getters.getParamById(message['id'])?this.$store.getters.getParamById(message['id'])['name']:message['id']
              
              this.msg='Trigger:'+message['name']+'. The Parameter '+paramName.toString()+' is '+message['value'].toString()
              break;
            case 'paramTargetTrigger':
              this.$store.commit('addNotifications',message)
              
              paramName=this.$store.getters.getParamById(message['id'])?this.$store.getters.getParamById(message['id'])['name']:message['id']
              
              this.msg='Target Breach:'+message['name']+'. The Parameter '+paramName.toString()+' Consumption is '+message['value'].toString()
              break;
            default:
              console.log(message)
              // this.msg=message
          }
          
        })
        this.stream.emit("getNotification");
      },
      closeSnackbar() {
          this.show = false;
      },
    },  
    watch: {
      jwt(){
        this.init()
      },
      message(newMsg) {
        if (newMsg) {
          this.show = true;
        }
      },
      msg(newMsg) {
        if (newMsg) {
          this.show = true;
        }
      },
    },
  };
  </script>
  
  
<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">  
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
        <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-list-item @click="update=true">
              <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="deleteWidget">
              <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}
</strong></v-list-item-title>
              <v-list-item-icon>
                  
                <v-icon color="red" small>mdi-trash-can</v-icon>
              </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{widget.label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}
</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <AddBarTDWidget :widget="widget" :disableTimeframe="disableTimeframe" v-on:close="update=false" v-on:success="$emit('update')" />
        </v-card>
      </v-dialog>
      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':widget.background_color?widget.background_color:$store.getters.getColorPalette().background2ColorCode,'height':'175px',}:{}]" ></div>
        <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
          <v-icon small>mdi-hail</v-icon>
          <h5> {{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddBarTDWidget from '@/components/crud_components/widgets/AddBarTDWidget'
import ColorUtility from '@/utillities/ColorUtility'
export default {
    name:'BarTDWidget',
   props:{
  widget:{
    type: Object,
    required:true
  },
  timeframe:{
    type: String,
  },
  edit:{
    type:Boolean,
    required:true
  },
  disableTimeframe:{
    type:Boolean,
    required:true
  },
  idealValue:Number,
  idealLabel:String,
},
    components:{
        AddBarTDWidget,
        InfoAlert,
        // DeleteConfirmation
    },
    data(){
        return {
           showDialog:false,
           colorCode:ColorUtility.colorTypesCodeArray,
            x:0,
            y:0,
            showMenu:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            update:false,
            graphData:{},
            benchmarkline:{},
            positive:0,
            negative:0,
            count:0,
            layout : {
                    //height: 300,
                    //width: 500,
                    // coloraxis:{
                    //   colorbar:{
                    //     bordercolor:'#FFF',
                    //     outlinecolor:'#FFF',
                    //     tickcolor:'#FFF'
                    //   }
                    // },
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.widget.background_color?this.widget.background_color:this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor:this.widget.background_color?this.widget.background_color:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:false,
                    autosize: true,
                    hoverlabel:{
                    namelength:-1,
                  },
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
        }
    },
    mounted(){
        if(this.widget && this.widget.meta && this.widget.meta.data){
            this.getData()
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
      },
        createChart(){
            this.layout['title']= this.widget.label
            //let textInfo='label+percent'

            if(this.widget.meta && this.widget.meta.yaxis_label){
          this.layout.yaxis['title'] = this.widget.meta.yaxis_label
          }

            if(this.widget.meta && this.widget.meta.legend){
              this.layout['showlegend']=true
              
              //textInfo='percent'
            }
            /*if(this.widget.meta && this.widget.meta.textinfo){
              textInfo=this.widget.meta.textinfo
            }*/
            /*this.layout['annotations']=[{
                    
                    showarrow: false,
                    text: this.widget.meta.time_frame.toUpperCase(),
                    x: 0.5,
                    y: 0.5
                    }]*/
            let data = [{
            y: Object.values(this.graphData),
            x: Object.keys(this.graphData),
            type: 'bar',
            width:0.1,
            //text:'mtd',
            //textinfo: textInfo,
            //textposition: "outside",
            opacity:0.9,
            marker: {
              // color:this.$store.getters.getColorPalette().gradColorCode,

line: {
    color: this.$store.getters.getColorPalette().accentCode,
    width: 1
  },
}
            //hole:0.4,
            //automargin: true
                    }];
   if (this.widget.meta.showIdeal) {
        const benchmarkLine = {
            type: 'line',
            x: [Object.keys(this.graphData)[0], Object.keys(this.graphData)[Object.keys(this.graphData).length - 1]], 
            y: [this.widget.meta.idealValue ? this.widget.meta.idealValue : 0, this.widget.meta.idealValue ? this.widget.meta.idealValue : 0],
            line: {
                color: 'red',
                width: 2,
                dash: 'dash',
            },
            showlegend: true,
            name: this.widget.meta.idealLabel ? this.widget.meta.idealLabel : '',
        };

        // Add the benchmark line trace to the data array
        data.push(benchmarkLine);
    }        
            Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
        },
        getData(){
          if(this.widget.height==='fixed'){
              this.layout.height=170
              // this.layout.width=this.widget.width*97
              this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
              this.layout.margin={
                t:40,
                b:40,
                l:40,
                r:40
              }
          }
  this.layout.colorway = this.colorCode
            this.graphData={}
            let from_date=moment().subtract(1,'days').format("YYYYMMDD")
            let tf=null
            if(this.timeframe && this.disableTimeframe){
              tf=this.timeframe
            }else{
              tf=this.widget.meta && this.widget.meta.time_frame?this.widget.meta.time_frame:'month'
            }
            switch( tf)
            {
              case '7':
                  from_date = moment().subtract(7,'days').format('YYYYMMDD');
                  break;
                  
              case '30':
                from_date=moment().subtract(30, 'days').format('YYYYMMDD')
                break;
                
                case '60':
                  from_date=moment().subtract(60, 'days').format('YYYYMMDD')
                  break;
                  
                  case '90':
                    from_date=moment().subtract(90, 'days').format('YYYYMMDD')
                    break;

                  case '180':
                    from_date=moment().subtract(180, 'days').format('YYYYMMDD')
                    break;
                    
                  case '365':
                    from_date=moment().subtract(365, 'days').format('YYYYMMDD')
                    break;
                  case 'yesterday':
                  from_date = moment().subtract(1,'days').format('YYYYMMDD');
                    break;
                  case 'ytd':
                    from_date=moment().startOf('year').format('YYYYMMDD')  
                    break;

                  default:
                    from_date=moment().subtract(1, 'days').startOf('month').format('YYYYMMDD')
                    break;
           }
            
            if(this.widget && this.widget.meta && this.widget.meta.data){
                for(let i of this.widget.meta.data){
                  let count = 0
                let positive = 0
                let negative = 0
                    let typeoff =typeof(i.params[0])
                    if(i.params.length>1 && typeoff==='object'){
                      // this.count=0
                      count=0

                      for(let j of i.params){
                        if(j.length>0){
                          let payload={
                          parameters:j,
                          from_date:from_date
                        }
                      axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {
                      Authorization: 'Bearer '+ this.$store.state.jwt
                      }}).then(response=>{
                      if(response.data.status==='success'){
                        if (count===0) {
                        // if (this.count===0) {
                          if(j.length>0){
                            positive = Number(response.data.data['usage'])
                          // this.positive = Number(response.data.data['usage'])
                          }
                        }else{
                          if(j.length>0){
                            negative = Number(response.data.data['usage'])
                          // this.negative = Number(response.data.data['usage'])
                          }
                        }
                      this.graphData[i.label]= positive - negative
                      // this.graphData[i.label]= this.positive - this.negative
                      this.createChart()
                      count = count+1
                      // this.count = this.count+1
                      }else{
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                      this.loading=false
                      }).catch(err=>{
                      console.error(err)
                      this.loading=false});  
                    }
                  }
                    }else{
                      let payload={
                          parameters:i.params,
                          from_date:from_date
                      }
                  axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                    // console.log(response);
                  if(response.data.status==='success'){
                      this.graphData[i.label]=Number(response.data.data['usage'])
                      this.createChart()
                  }else{
                      this.info=response.data.msg
                      this.showDismissibleAlert=true
                  }
                      this.loading=false
                  })
                  .catch(err=>{
                      console.error(err)
                      this.loading=false});
                    }
                }
            }
        },
        deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    watch:{
        widget:{
            handler(){
                this.getData()
            },
            deep:true
        },
         idealValue: function(newIdealValue) {
      // console.log('idealValue changed to:', newIdealValue);
      this.benchmarkLine.y = [newIdealValue ? newIdealValue : 0];
      this.createChart();
    },
        timeframe:{
          handler(){
                this.getData()
            },
            deep:true
        }
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
</style>
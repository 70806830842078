
const colorCodes={
    'teal': '#64FFDA',
    'deeppurple':'#B388FF',
    'cyan': '#18FFFF',
    'green':'#69F0AE',
    'purple': '#E040FB',
    'pink': '#FF4081',
    'lime': '#C6FF00',
    'lightblue': '#40C4FF',
    'red':'#FF1744',
    'yellow': '#FFFF00',
    'lightgreen':'#76FF03',
    'deeporange': '#FF3D00', 
    'greylighten':'#BDBDBD',
     'blue': '#2979FF',
    // 'amber':'#FFD74',
    'orange':'#FF9800',
    'deeppurplelighten':'#D1C4E9',
//    'tealdarken' :'#00897B'
   'tealdarken' :'#26A69A'
}
const colorNames={
    'green': 'green accent-2',
    'purple': 'purple accent-2',
    'pink': 'pink accent-2',
    'teal':'teal accent-2',
    'lime': 'lime accent-3',
    'lightblue': 'light-blue accent-2',
    'red':'red accent-3',   
    'yellow': 'yellow accent-2',
    'deeppurple':'deep-purple accent-1',
    'lightgreen' :'light-green accent-3',
    'cyan':  'cyan accent-2',
   'deeporange': 'deep-orange accent-3',
   'blue': 'blue accent-3',   
   'amber' :'amber accent-2',
   'greylighten':'grey lighten-1',
   'orange':'orange ',
   'deeppurplelighten':'deep-purple lighten-4',
   'tealdarken':'teal lighten-1'
//    'tealdarken':'teal darken-1'
 
}

const gradColorName=[
    'orange',
    'teal  ',
    'orange lighten-3',
    'teal lighten-3',
    'orange lighten-1',
    'orange lighten-1',
    'torange lighten-4',
    'teal lighten-4',
    'orange darken-1',
    'teal darken-1',
    'orange darken-3',
    'teal darken-3',
]
const gradColorCode=[
'#FF9800',
'#009688',
'#FFCC80',
'#80CBC4',
'#FFA726',
'#26A69A',
'#FFE0B2',
'#80CBC4',
'#FB8C00',
'#00897B',
'#EF6C00',
'#00695C'
]

var colorscaleCodeHeatMap = [
[0,'#40C4FF'],
// [0.1,'#81C784'],
// [0.2,'#66BB6A'],
// [0.3,'#FFF59D'],
// [0.4,'#FFF176'],
[0.5,'#FFEE58'],
// [0.6,'#FFB74D'],
// [0.7,'#FFA726'],
// [0.8,'#FB8C00'],
// [0.9,'#F57C00'],
[1,'#FF4081'],
];
// var colorscaleCodeHeatMap = [
// [0,'#A5D6A7'],
// [0.1,'#81C784'],
// [0.2,'#66BB6A'],
// [0.3,'#FFF59D'],
// [0.4,'#FFF176'],
// [0.5,'#FFEE58'],
// [0.6,'#FFB74D'],
// [0.7,'#FFA726'],
// [0.8,'#FB8C00'],
// [0.9,'#F57C00'],
// [1,'#EF6C00'],
// ];

// const gradColorCode=[
//  #E0F2F1',
// '#B2DFDB',
// '#80CBC4',
// '#4DB6AC',
// '#26A69A',
// '#00897B',
// '#00796B',
// '#00695C',
// '#004D40',
// '#A7FFEB',
// '#64FFDA',
// '#1DE9B6',
// '#00BFA5',
// ]

// const tickfontColor=[
//     '#FDD835'
// ]


export default {
    name:'DarkColorList',
    avatarColorCode:'#FF7043',
    avatarColorName:'deep-orange lighten-1',
colorTypesName:colorNames,
colorTypesCodes:colorCodes,
mgateList:'#FFF',
isDark:true,
// buttonColorFill:
// cardBorderColor:'#FFC400',
topBar:'#263238',
topBarAccent:'#FFFFFF',
navigation:'#37474F',
panelBorderColorCode:'#37474F',
panelBorderColorName:'blue-grey darken-3',
navigation_icon:'#FFFFFF',
cardBorderColor:'#546E7A',
iconColor:'#F44336', // TODO - Need to check & Update
iconBorderColor:'#0cab83', // TODO - Need to check & Update
alertIconColor:'#F44336', // TODO - Need to check & Update
tab: '#4DB6AC', //'#43A047',
tabAccent:'#FFFFFF',
fontcolorTypescodes:['#FDD835'],
appBarColorName:'blue-grey darken-4',
tableHeaderColorName:colorNames['tealdarken'],
tableHeaderColorCode:colorCodes['tealdarken'],
widgettableHeaderColorCode:colorCodes['blue'],
foregroundColorName: 'teal lighten-2',//'green darken-1',//Object.values(colorNames)[0] accent 4 original,
foregroundColorCode: '#4DB6AC',//'#43A047',//'#81C784',//Object.values(colorCodes)[0],
expansionPanelHeaderColorName:'blue-grey darken-4',
expansionPanelHeaderColorCode:'#263238',
cardColor:'grey lighten-3',
cardColorCode:'#EEEEEE',
cardColorLoginPage:'grey lighten-3',
graphPoints:colorCodes['lime'],
backgroundColorCode:'#263238',
backgroundColorName:'blue-grey darken-4',
background1ColorCode:'#000000',
background2ColorCode:'#37474F',
alertColor:'#E57373',
successColor:'#81C784',
accentCode:'#FFFFFF',
accentName:'white',
bulletGaugeBar:'orange',
colorTypesNameArray:Object.values(colorNames),
colorTypesCodeArray:Object.values(colorCodes),
colorTypesCodeArrayHeatMap:colorscaleCodeHeatMap,
gradientColorCodes:gradColorCode,
gradientColorNames:gradColorName,
submitbtnColor:'#26A69A',    //"green darken-1",
submitbtn2Color:"#388E3C",
cancelbtnColor:  '#EF5350', 
esgbtnColor:'lightblue',  //"grey darken-3",
deletebtnColor:"red",
downloadbtnColor:"light-blue",
inputtextColor:"cyan lighten-1",
inputtext2Color:"yellow lighten-3",
tabActiveColor:"yellow lighten-3",
chipColor:"indigo",
tabStripColor:'grey darken-1',
btnborderColorCode:  '#A7FFEB' ,//'#0cab83',
tabactiveColor:"#FFFFFF",
loggerColor: "#546E7A",
tickfontColor: "#FDD835",
textColor:  "#64FFDA",       //"#4CAF50",
mgateColor:"#80CBC4",
// esg_module_card:'orange lighten-1',
esg_module_card:'#FFA726',
esg_module_card_text:'#0F0F0F',
dropDownCodeArray: () => {
    let dropDownCodeArrays = []
    let count = 0
    for(let i of Object.keys(colorCodes)){
        let tempObject ={label:i,values:Object.values(colorCodes)[count]}
        dropDownCodeArrays.push(tempObject)
        count = count+1
    }
    return dropDownCodeArrays
},
dropDownNameArray : () => {
    let dropDownNameArrays=[]
    let count = 0
    for(let i of Object.keys(colorNames)){
        let tempObject ={label:i,values:Object.values(colorNames)[count]}
        dropDownNameArrays.push(tempObject)
        count = count+1
    }
    return dropDownNameArrays
},
}
<template>
        <v-dialog v-model="dialog"  hide-overlay transition="dialog-bottom-transition">
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="valid">
            <v-card   :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" elevation="4">
                <v-card-title class="headline">{{$store.getters.getTextMap().param_scroll_view_page}}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>  
                                <v-row>  
                                <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                     :dark="$store.getters.getColorPalette().isDark"
                                      :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="workspace"
                                        :items="workspaceOptions"
                                        :label="$store.getters.getTextMap().workspace"
                                        outlined        
                                        item-text="label"
                                        item-value="value"
                                  
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-select
                                      :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="machine"
                                        :items="machineOptions"
                                        :label="$store.getters.getTextMap().machine"
                                        outlined
                                        item-text="label"
                                        item-value="value"
                                
                                    ></v-select>
                                </v-col>
                            </v-row>
                          
                            
                        </v-row>
                        <v-row>
             <v-col v-if="!loading">
                    <v-btn color="gray darken-1" small @click="addMachineToList" >{{$store.getters.getTextMap().add_machine_to_list}}</v-btn>
                 <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onMachineReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                            </v-row>
                    <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && machineTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <!-- <th class="text-left">
                                                    <strong>Label</strong>
                                                </th> -->
                                                <th class="text-left">
                                                    <strong>{{ $store.getters.getTextMap().machines }}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in machineTableDetails"
                                            >
                                            <!-- <td>{{ item.label }}</td> -->
                                            <td>{{ item.machine_id }}</td>
                                            
                                            <td><v-icon small color="red" @click="deleteFromMachineTable(item)">mdi-delete</v-icon></td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
            </v-col>


                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                        <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">{{ $store.getters.getTextMap().cancel }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import axios from 'axios'


export default {
    components:{
      
    },
    mounted(){
        this.dialog=true 
    },
    name:'GetParamScrollViewParameters',
    props:['show'],
    data(){
        return {
          
            valid:false,
            loading:false,
            dialog:false,
             paramOptions:[],
            machineOptions:[],
            machineTableDetails:[],
            workspace:null,
            machine:null,
         
            form:{
                machine_list:[],    
            },
            params:[],
                  listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
    },
    methods:{
                  getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                // console.log(payload)
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },

 addMachineToList(){
                       
                if(!this.machineTableDetails){
                    this.machineTableDetails = []
                }
                this.machineTableDetails.push(
                     {machine_id:this.machine.machine_id,machine_name:this.machine.machine_name}
                  
                )
            },
            
onMachineReset(){
            this.workspace=null
            this.machine=null
            // this.machine_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },

 deleteFromMachineTable(item){
            this.machineTableDetails=this.machineTableDetails.filter(x=>x.machine_id!=item.machine_id)
            },
            deleteFromTagTypeTable(item){
            this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
            },

  getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine && this.machine.machine_id){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine.machine_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                           
                            this.params=response.data.params.filter(d=>{return !d.featured})
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
                onSubmit(){
                    this.form['machine_name']=this.machine
                     this.form['machine_list']= this.machineTableDetails
                    console.log(this.form,"newform")
            this.$refs.widgetForm.validate()
            if(this.valid){
                this.loading=true
                this.$emit('value',this.form)
                this.$emit('close')
            }

        },
   
        onReset(){
            this.dialog=false,
            this.loading=false
            this.$emit('close')
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
            
        },
        //  machine(){
        //     this.getParams()
        // }

    }
}
</script>
<template>
  <div class="text-center">
    <v-btn    :dark="$store.getters.getColorPalette().isDark"  small color="teal accent-3" @click="dialog=!dialog" >
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    
<v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
           :dark="$store.getters.getColorPalette().isDark" 
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.getTextMap().dashboard_panel }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
             :dark="$store.getters.getColorPalette().isDark" 
              text
              @click="dialog = false"
            >
            {{ $store.getters.getTextMap().close }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
              v-model="type"
          :items="types"
          :label="$store.getters.getTextMap().choose_type"
          filled
          item-text="label"
          item-value="value"
          required
          :rules="objectRules"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="type=='panel'">
            <v-col cols="12">
              <AddPanelToDashboard v-on:close="dialog=false" />
            </v-col>
          </v-row>
          <v-row v-else-if="type=='gauge'">
            <v-col cols="12">
              <AddGaugeToDashboard v-on:close="dialog=false" />
            </v-col>
          </v-row>
          <v-row v-else-if="type=='param'">
            <v-col cols="12">
              <AddParamToDashboard v-on:close="dialog=false" />
            </v-col>
          </v-row>
          <v-row v-else-if="type=='machine'">
            <v-col cols="12">
              <AddMachineToDashboard v-on:close="dialog=false" />
            </v-col>
          </v-row>
        </v-container>
    </v-card>
</v-dialog>
  </div>
</template>
<script>
import AddPanelToDashboard from '@/components/crud_components/AddPanelToDashboard'
import AddGaugeToDashboard from '@/components/crud_components/AddGaugeToDashboard'
import AddParamToDashboard from '@/components/crud_components/AddParamToDashboard'
import AddMachineToDashboard from '@/components/crud_components/AddMachineToDashboard'

export default {
    name:'AddPanelToDashboardBottomSheet',
    components:{
        AddPanelToDashboard,
        AddGaugeToDashboard,
        AddParamToDashboard,
        AddMachineToDashboard
    },
    data(){
        return {
            dialog:false,
            type:'panel',
            types:[
              {label:this.$store.getters.getTextMap().panel, value:'panel'},
              {label:this.$store.getters.getTextMap().gauge, value:'gauge'},
              {label:this.$store.getters.getTextMap().param, value:'param'},
              {label:this.$store.getters.getTextMap().machine, value:'machine'},
            ],
            objectRules:[
              v => !!v || this.$store.getters.getTextMap().required,
            ],
      }
    },
    
}
</script>

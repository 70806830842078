import English from "@/utillities/languages/English";
export default {
    ...English,
    "accept": "ఆమోదించు",
"reject": "తిరస్కరించు",
"move_to_next_stage": "తరువాతి దశకు వెళ్ళండి",
"save_as_draft": "డ్రాఫ్ట్‌గా సేవ్ చేయండి",
"save_and_submit": "సేవ్ చేసి సమర్పించండి",
"closure": "ముగింపు",
"duration": "వ్యవధి",
"refill_request": "రిఫిల్ కోసం అభ్యర్థన",
"is_be_cancelled": "రద్దు చేయబడుతుంది",
"delete_this": "ఇది తొలగించు",
"event": "ఈవెంట్",

    "for": "కోసం",
    "of": "యొక్క",
    "downtime": "డౌన్‌టైమ్",

    "metrics": "మీట్రిక్స్",
    "oee_by_day": "రోజుకు OEE",

    "ideal_cycle_time": "ఆదర్శ చక్ర సమయం",
    
    "minutes": "నిమిషాలు",
    "production_time": "ఉత్పత్తి సమయం",
    "back": "వెనక్కి",
    "down_times": "డౌన్ టైమ్స్",

    "top_5_reasons": "టాప్ 5 కారణాలు",
    "top_5_categories": "టాప్ 5 వర్గాలు",
    "enter_apparent_energy_manually": "స్పష్టమైన శక్తిని మానవీయంగా నమోదు చేయండి",

    "oee": "OEE",
    "reason": "కారణం",
    "add_downtime_reason": "డౌntime కారణాన్ని చేర్చండి",

    "default_target": "డిఫాల్ట్ లక్ష్యం",

    "enter_in_minutes": "నిమిషాల్లో నమోదు చేయండి",
    "custome_fields": "అనుకూల ఫీల్డ్‌లు",
    "enter_data_in_minutes": "నిమిషాల్లో డేటాను నమోదు చేయండి",
    "enter_data_in_hours": "గంటల్లో డేటాను నమోదు చేయండి",
    "create_tod_billing_widget": "టాడ్ బిల్లింగ్ విడ్జెట్ సృష్టించండి",
    "edit_tod_billing_widget": "టాడ్ బిల్లింగ్ విడ్జెట్‌ను సవరించండి",
    "choose_from_machine_groups": "యంత్ర సమూహాల నుండి ఎంచుకోండి",
    "tod_billing": "టాడ్ బిల్లింగ్",
    "choose": "ఎంచుకోండి",
    "create_shift_based_machine_usage_log": "యంత్ర వినియోగ లాగ్‌ను సృష్టించండి",
    "shift_based_machine_usage_log": "యంత్ర వినియోగ లాగ్",
    "rejected": "తిరస్కరించబడింది",
    "machine_operator": "మెషిన్ ఆపరేటర్",
    "production_end_timestamp": "ఉత్పత్తి ముగింపు సమయం",
    "production_start_timestamp": "ఉత్పత్తి ప్రారంభ సమయం",
    "running_time": "నడుస్తున్న సమయం",
    "idle_time": "పనిలేకుండా సమయం",
    "down_time": "డౌన్ సమయం",
    "date_row_index": "తేదీ వరుస",
    "date_position": "తేదీ స్థానం",
    "save": "సేవ్",
    "discard": "విస్మరించండి",
    "adjust": "సర్దుబాటు",
    "safety_incident_sheet": "భద్రతా సంఘటన షీట్",
    "safety_inspection_sheet": "భద్రతా తనిఖీ షీట్",
    "ghg_emission_logs": "GHG ఉద్గార లాగ్‌లు",
    "add_ghg_emission_data": "GHG ఉద్గార లాగ్లను జోడించండి",
    "ghg_scope_conversion": "GHG స్కోప్ మార్పిడి",
    "create_ghg_scope_conversion": "GHG స్కోప్ మార్పిడిని సృష్టించండి",
    "ghg_data_logs": "GHG ఉద్గార డేటా లాగ్స్",
    "choose_inspection_type_optional": "తనిఖీ రకాన్ని ఎంచుకోండి (ఐచ్ఛికం)",
    "supplier_code": "సరఫరాదారు కోడ్",
    "contact": "సంప్రదించండి",
    "point_of_contact": "సంప్రదింపు పాయింట్",
    "quantity_supplied": "పరిమాణం సరఫరా",
    "create_product": "ఉత్పత్తిని సృష్టించండి",
    "products": "ఉత్పత్తులు",
    "data": "డేటా",
    "timezone": "టైమ్‌జోన్",
    "tariffs": "సుంకాలు",
    "days_delta": "డేస్ డెల్టా",
    "machine_groups": "యంత్ర సమూహాలు",
    "param_groups": "పార్మెటర్ సమూహాలు",
    "user_groups": "వినియోగదారు సమూహాలు",
    "topic_prefix": "టాపిక్ ఉపసర్గ",
    "message_direction": "సందేశ దిశ",
    "safety_inspection": "భద్రతా తనిఖీ",
    "write_inspection_log": "తనిఖీ లాగ్ రాయండి",
    "inspection_id": "తనిఖీ ID",
    "inspector_id": "ఇన్స్పెక్టర్ ఐడి",
    "inspection_date": "తనిఖీ తేదీ",
    "inspection_type": "తనిఖీ రకం",
    "findings": "కనుగొన్నవి",
    "actions_required": "చర్యలు అవసరం",
    "followup_date": "తేదీని అనుసరించండి",
    "status": "స్థితి",
    "safety_incident": "భద్రతా సంఘటన",
    "write_incident": "సంఘటన రాయండి",
    "incident_description": "సంఘటన వివరణ",
    "severity": "తీవ్రత",
    "accident": "ప్రమాదం",
    "accident_with_loss": "నష్టంతో ప్రమాదం",
    "closed": "మూసివేయబడింది",
    "corrective_action": "దిద్దుబాటు చర్య",
    "employee_id": "ఉద్యోగి ID",
    "apply": "వర్తించండి",
    "choose_location_optional": "స్థానాన్ని ఎంచుకోండి (ఐచ్ఛికం)",
    "locations": "స్థానాలు",
    "locations_event_types": "స్థానాల ఈవెంట్ రకాలు",
    "create_tariff": "సుంకం సృష్టించండి",
    "default_tariff_val": "డిఫాల్ట్ సుంకం విలువ",
    "add_tariff_data": "సుంకం డేటాను జోడించండి",
    "edit_tariff": "సుంకాన్ని సవరించండి",
    "delete_tariff": "సుంకాన్ని తొలగించండి",
    "tariff_value": "సుంకం విలువ",
    "machine_status_timeline": "యంత్ర స్థితి కాలక్రమం",
    "total_duration": "మొత్తం వ్యవధి",
    "generate": "ఉత్పత్తి",
    "remove_machine": "యంత్రాన్ని తొలగించండి",
    "edit_machine_status_timeline_widget": "మెషిన్ స్టేటస్ టైమ్‌లైన్ విడ్జెట్‌ను సవరించండి",
    "add_machine_Status__timeline_widget": "యంత్ర స్థితి టైమ్‌లైన్ విడ్జెట్‌ను జోడించండి",
    "select_timeframe": "కాలపరిమితి ఎంచుకోండి",
    "timeframe_type_shift": "షిఫ్ట్",
    "timeframe_type_custom": "ఆచారం",
    "timeframe_type_yesterday": "నిన్న",
    "edit_report_details": "నివేదిక వివరాలను సవరించండి",
    "download_current_template": "ప్రస్తుత టెంప్లేట్‌ను డౌన్‌లోడ్ చేయండి",
    "date_column_index": "తేదీ కాలమ్ (సూచిక)",
    "select_area": "ప్రాంతాన్ని ఎంచుకోండి",
    "select_shift": "షిఫ్ట్ ఎంచుకోండి",
    "dashboard_heading": "డాష్‌బోర్డ్",
    "production_params": "ఉత్పత్తి పారామ్‌లు",
    "no_modules_configured": "మాడ్యూల్స్ కాన్ఫిగర్ చేయబడలేదు",
    "dashboard_panel": "డాష్‌బోర్డ్ ప్యానెల్",
    "choose_type": "రకాన్ని ఎంచుకోండి",
    "name_is_required": "పేరు అవసరం",
    "description_is_required": "వివరణ అవసరం",
    "required": "అవసరం",
    "you_have_to_select_something": "మీరు ఏదో ఎంచుకోవాలి",
    "name_must_be_less_than_30_characters": "పేరు 30 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "name_must_be_less_than_25_characters": "పేరు 25 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "name_must_be_less_than_50_characters": "పేరు 50 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "description_must_be_less_than_35_characters": "వివరణ తప్పనిసరిగా 35 అక్షరాల కంటే తక్కువగా ఉండాలి",
    "description_must_be_less_than_55_characters": "వివరణ తప్పనిసరిగా 55 అక్షరాల కంటే తక్కువగా ఉండాలి",
    "number_must_be_less_than_6_digits": "సంఖ్య 6 అంకెల కన్నా తక్కువ ఉండాలి",
    "number_must_be_less_than_8_digits": "సంఖ్య 8 అంకెల కన్నా తక్కువ ఉండాలి",
    "number_must_be_less_than_7_digits": "సంఖ్య 7 అంకెల కన్నా తక్కువ ఉండాలి",
    "panel": "ప్యానెల్",
    "gauge": "గేజ్",
    "param": "పారామ్",
    "machine": "యంత్రం",
    "add_panel_to_dashboard": "డాష్‌బోర్డ్‌కు ప్యానెల్ జోడించండి",
    "edit_dashboard_panel": "డాష్‌బోర్డ్ ప్యానెల్‌ను సవరించండి",
    "param_type": "పారామ్ రకం",
    "workspace": "వర్క్‌స్పేస్",
    "device": "పరికరం",
    "name": "పేరు",
    "language": "భాష",
    "time_duration": "సమయ వ్యవధి",
    "submit": "సమర్పించండి",
    "cancel": "రద్దు చేయండి",
    "close": "దగ్గరగా",
    "usage": "ఉపయోగం",
    "peak_value": "గరిష్ట విలువ",
    "current_data": "ప్రస్తుత డేటా",
    "parameter": "పరామితి",
    "computed_param": "కంప్యూటెడ్ పారామ్",
    "derived_param": "ఉత్పన్నమైన పారామ్",
    "t4_days": "4 రోజులు",
    "last_7_days": "చివరి 7 రోజులు",
    "last_30_days": "చివరి 30 రోజులు",
    "last_60_days": "చివరి 60 రోజులు",
    "last_90_days": "చివరి 90 రోజులు",
    "last_180_days": "చివరి 180 రోజులు",
    "last_365_days": "లాట్ 365 రోజులు",
    "last_1_hour": "చివరి 1 గంట",
    "last_3_hours": "చివరి 3 గంటలు",
    "last_6_hours": "చివరి 6 గంటలు",
    "reorder_views": "క్రమాన్ని మార్చండి",
    "create_view": "సృష్టించండి",
    "clear_view_name_filter": "క్లియర్ ఫిల్టర్లు",
    "view_type": "వీక్షణ రకం",
    "description": "వివరణ",
    "table_view": "పట్టిక వీక్షణ",
    "widget_view": "విడ్జెట్ వీక్షణ",
    "dynamic_timeframed_widget_view": "డైనమిక్ టైమ్‌ఫ్రేమ్ విడ్జెట్ వీక్షణ",
    "diagram_view": "రేఖాచిత్రం వీక్షణ",
    "energy_usage_view": "శక్తి వినియోగ వీక్షణ",
    "machine_scroll_view": "మెషిన్ స్క్రోల్ వ్యూ",
    "param_scroll_view": "పారామ్ స్క్రోల్ వీక్షణ",
    "consolidated_view": "ఏకీకృత వీక్షణ",
    "air_quality_view": "గాలి నాణ్యత వీక్షణ",
    "view_auto_change": "ఆటో మార్పును చూడండి",
    "no_views_configured": "వీక్షణలు కాన్ఫిగర్ చేయబడలేదు",
    "add_to_dashboard": "డాష్‌బోర్డ్‌కు జోడించండి",
    "edit_view_name": "వీక్షణ పేరును సవరించండి",
    "edit_view": "వీక్షణను సవరించండి",
    "refresh": "రిఫ్రెష్",
    "create_machine": "యంత్రాన్ని సృష్టించండి",
    "add_machine": "యంత్రాన్ని జోడించండి",
    "sequence": "క్రమం",
    "machine_type": "యంత్ర రకం",
    "physical_machine": "భౌతిక యంత్రం",
    "virtual_machine": "వర్చువల్ మెషిన్",
    "positive_workspace": "పాజిటివ్ వర్క్‌స్పేస్",
    "positive_machine": "సానుకూల యంత్రం",
    "negative_workspace": "ప్రతికూల వర్క్‌స్పేస్",
    "negative_machine": "ప్రతికూల యంత్రం",
    "label": "లేబుల్",
    "positive_machine_Ids": "పాజిటివ్ మెషిన్ ఐడి",
    "negative_machine_Ids": "నెగటివ్ మెషిన్ ఐడి",
    "trend_analysis_tools": "ధోరణి విశ్లేషణ సాధనాలు",
    "energy_consumption_by_machines": "యంత్రాల ద్వారా శక్తి వినియోగం",
    "Create_report": "నివేదికను సృష్టించండి",
    "view_report": "నివేదిక నివేదిక",
    "tarrif_on": "సుంకం ఆన్",
    "active_energy": "క్రియాశీల శక్తి",
    "apparent_energy": "స్పష్టమైన శక్తి",
    "automate_report": "ఆటోమేట్ నివేదిక",
    "add_root_machine": "రూట్ మెషిన్ జోడించండి",
    "add_common_machine": "సాధారణ యంత్రాన్ని జోడించండి",
    "notification_groups": "నోటిఫికేషన్ సమూహాలు",
    "add_machines_to_list": "జాబితాకు యంత్రాలను జోడించండి",
    "lists": "జాబితాలు",
    "machine_list": "యంత్ర జాబితా",
    "data_historian": "డేటా చరిత్రకారుడు",
    "timeframed_trends": "కాలపరిమితి పోకడలు",
    "dual_axis_trend_comparison": "ద్వంద్వ అక్షసంబంధమైన పోలిక",
    "granular24_hrs_trend": "Ganalular24 hrs ధోరణి",
    "machinewise_trend": "యంత్ర వారీగా ధోరణి",
    "basic_statistical_trends": "ప్రాథమిక గణాంక పోకడలు",
    "log_analysis": "లాగ్ విశ్లేషణ",
    "aqi_comparison_panel": "AQI పోలిక ప్యానెల్",
    "time_of_day_trend": "రోజు ధోరణి సమయం",
    "param_historical_comparisons": "పారామ్ చారిత్రక పోలికలు",
    "energy_usage_trends": "శక్తి వినియోగ పోకడలు",
    "machine_name": "యంత్ర పేరు",
    "param_name": "పారామ్ పేరు",
    "trend_view": "ధోరణి వీక్షణ",
    "raw": "ముడి",
    "trend": "ధోరణి",
    "normalised": "సాధారణీకరించబడింది",
    "from_date": "తేదీ నుండి",
    "to_date": "ఈ రోజు వరకు",
    "from": "నుండి",
    "to": "To",
    "hour": "గంట",
    "minute": "నిమిషం",
    "generate_report": "నివేదికను రూపొందించండి",
    "time_range": "సమయ పరిధి",
    "no_data_available": "డేటా అందుబాటులో లేదు",
    "daily": "రోజువారీ",
    "monthly": "నెలవారీ",
    "weekly": "వీక్లీ",
    "week": "వారం",
    "yearly": "వార్షిక",
    "device_name": "పరికర పేరు",
    "machine_wise_comparison": "యంత్ర వారీగా పోలిక",
    "stats_detailed_report": "గణాంకాలు వివరణాత్మక నివేదిక",
    "log_type": "లాగ్ రకం",
    "choose_Log": "లాగ్ ఎంచుకోండి",
    "area": "ప్రాంతం",
    "aq_device": "AQ పరికరం",
    "nebeskie_labs_office": "నెబెస్కీ ల్యాబ్స్ కార్యాలయం",
    "date_range": "DATE_RANGE",
    "this_year": "ఈ సంవత్సరం",
    "last 6 months": "గత 6 నెలలు",
    "last quater": "చివరి త్రైమాసికం",
    "mtd": "Mtd",
    "ytd": "Ytd",
    "yesterday": "నిన్న",
    "custom": "ఆచారం",
    "run_report": "రన్ రిపోర్ట్",
    "create_report": "నివేదికను సృష్టించండి",
    "usage_report": "వినియోగ నివేదిక",
    "consolidated_report": "ఏకీకృత నివేదిక",
    "dynamic_excel_report": "డైనమిక్ ఎక్సెల్ రిపోర్ట్",
    "energy_usage_report": "శక్తి వినియోగ నివేదిక",
    "create_action": "చర్యను సృష్టించండి",
    "action_type": "చర్య రకం",
    "notification_group": "నోటిఫికేషన్ సమూహం",
    "address": "చిరునామా",
    "number_of_registers": "రిజిస్టర్ల సంఖ్య",
    "value": "విలువ",
    "send_notification": "నోటిఫికేషన్ పంపండి",
    "write_parameter": "పరామితి రాయండి",
    "relays": "రిలేలు",
    "relay_1": "రిలే 1",
    "relay_2": "రిలే 2",
    "relay_3": "రిలే 3",
    "relay_4": "రిలే 4",
    "email_must_be_valid": "ఇమెయిల్ చెల్లుబాటులో ఉండాలి",
    "it_should_be_a_number": "ఇది ఒక సంఖ్యగా ఉండాలి",
    "it_should_be_a_valid_number": "ఇది చెల్లుబాటు అయ్యే సంఖ్య అయి ఉండాలి",
    "operator": "ఆపరేటర్",
    "operand": "ఒపెరాండ్",
    "targets": "లక్ష్యాలు",
    "target_value": "లక్ష్య విలువ",
    "wait_time_seconds": "సమయం వేచి ఉండండి (సెకన్లు)",
    "greater_than": "కంటే ఎక్కువ",
    "modulus_greater_than": "కంటే ఎక్కువ మాడ్యులస్",
    "lesser_than": "కంటే తక్కువ",
    "modulus_lesser_than": "మాడ్యులస్ కంటే తక్కువ",
    "equal_to": "సమానం",
    "not_equal_to": "సమానం కాదు",
    "modulus_equal_to": "మాడ్యులస్ సమానం",
    "greater_than_equal_to": "సమానం కంటే ఎక్కువ",
    "modulus_greater_than_equal_to": "మాడ్యులస్ సమానం కంటే ఎక్కువ",
    "lesser_than_equal_to": "సమానం కంటే తక్కువ",
    "modulus_lesser_than_equal_to": "మాడ్యులస్ సమానం కంటే తక్కువ",
    "bit_high": "బిట్ ఎక్కువ",
    "bit_low": "బిట్ తక్కువ",
    "it_should_be_a_positive_whole_number": "ఇది సానుకూల మొత్తం సంఖ్యగా ఉండాలి",
    "it_should_be_a_positive": "ఇది సానుకూలంగా ఉండాలి",
    "create_process": "ప్రక్రియను సృష్టించండి",
    "edit_process": "సవరణ ప్రక్రియ",
    "status_rule": "స్థితి నియమం",
    "create_process_status_rule": "ప్రాసెస్ స్థితి నియమాన్ని సృష్టించండి",
    "tag_type": "ట్యాగ్ రకం",
    "process_status": "ప్రాసెస్ స్థితి",
    "machines": "యంత్రాలు",
    "running": "నడుస్తున్న",
    "idling": "పనిలేకుండా",
    "offline": "ఆఫ్‌లైన్",
    "no_machines_configured": "యంత్రాలు ఏవీ కాన్ఫిగర్ చేయబడ్డాయి",
    "delete_process": "ప్రక్రియను తొలగించండి",
    "add_machine_to_process": "ప్రాసెస్ చేయడానికి యంత్రాన్ని జోడించండి",
    "delete_premise_area": "ఆవరణ ప్రాంతాన్ని తొలగించండి",
    "add": "జోడించు",
    "add_to_list": "జాబితాకు జోడించండి",
    "search": "శోధన",
    "switch_to": "మారండి",
    "switch_to_base_org": "బేస్ ఆర్గ్‌కు మారండి",
    "switch": "స్విచ్",
    "create_new_organisation": "క్రొత్త సంస్థను సృష్టించండి",
    "organisation_name": "సంస్థ పేరు",
    "organisation_type": "సంస్థ రకం",
    "country_code": "దేశ కోడ్",
    "country": "దేశం",
    "state": "రాష్ట్రం",
    "clear": "క్లియర్",
    "change_organisation": "సంస్థను మార్చండి",
    "organisation_id": "సంస్థ ID",
    "update_your_oem_logo": "మీ OEM లోగోను నవీకరించండి",
    "select_a_logo_to_upload": "అప్‌లోడ్ చేయడానికి లోగోను ఎంచుకోండి",
    "submit_logo": "లోగోను సమర్పించండి",
    "create_workspace": "వర్క్‌స్పేస్‌ను సృష్టించండి",
    "hub_id": "హబ్ ఐడి",
    "name_must_be_less_than_45_characters": "పేరు 45 అక్షరాల కంటే తక్కువగా ఉండాలి",
    "id_is_required": "ID అవసరం",
    "no_space_allowed": "స్థలం అనుమతించబడదు",
    "id_must_be_less_than_6_characters": "ID తప్పనిసరిగా 6 అక్షరాల కంటే తక్కువగా ఉండాలి",
    "edit_workspace": "వర్క్‌స్పేస్‌ను సవరించండి",
    "delete_workspace": "వర్క్‌స్పేస్‌ను తొలగించండి",
    "add_modbus_device": "మోడ్‌బస్ పరికరాన్ని జోడించండి",
    "add_device": "పరికరాన్ని జోడించండి",
    "oops": "అయ్యో !!",
    "ok": "సరే",
    "slave_id": "బానిస ఐడి",
    "device_type": "పరికర రకం",
    "connection_type": "కనెక్షన్ రకం",
    "mGate": "Mgate",
    "make": "చేయండి",
    "model": "మోడల్",
    "name_must_be_less_than_33_characters": "పేరు 33 అక్షరాల కంటే తక్కువగా ఉండాలి",
    "name_must_be_less_than_11_characters": "పేరు 11 అక్షరాల కంటే తక్కువగా ఉండాలి",
    "it_should_be_a_valid_slave_id": "ఇది చెల్లుబాటు అయ్యే బానిస ఐడి అయి ఉండాలి",
    "name_must_be_less_than_4_digits": "పేరు 4 అంకెల కన్నా తక్కువ ఉండాలి",
    "modbus_tcp": "మోడ్‌బస్ TCP",
    "modbus_rtu": "మోడ్‌బస్ RTU",
    "add_virtual_device": "వర్చువల్ పరికరాన్ని జోడించండి",
    "add_mqtt_device": "MQTT పరికరాన్ని జోడించండి",
    "topic": "అంశం",
    "identifier": "ఐడెంటిఫైయర్",
    "identifier_value": "ఐడెంటిఫైయర్ విలువ",
    "name_must_be_less_than_20_characters": "పేరు 20 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "no_special_characters_or_space_allowed": "ప్రత్యేక అక్షరాలు లేదా స్థలం అనుమతించబడలేదు",
    "must_be_less_than_15_characters": "15 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "create_role": "పాత్రను సృష్టించండి",
    "role": "పాత్ర",
    "role_is_required": "పాత్ర అవసరం",
    "role_must_be_less_than_40_characters": "పాత్ర 40 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "role_should_not_contain_white_characters": "పాత్రలో తెలుపు అక్షరాలు ఉండకూడదు",
    "comparison": "పోలిక",
    "historical_time_of_day_comparison_parameter": "చరిత్ర రోజు పోలిక సమయం - పరామితి",
    "device_parameter": "పరికర పరామితి",
    "date_1": "తేదీ 1",
    "date_2": "తేదీ 2",
    "tod_comparison_report": "TOD పోలిక నివేదిక",
    "time_frame": "కాలపరిమితి",
    "download_table": "డౌన్‌లోడ్ పట్టిక",
    "table": "పట్టిక",
    "showMinMax_values": "మిన్ మరియు గరిష్ట విలువలను చూపించు",
    "usage_or_average": "ఉపయోగం లేదా సగటు",
    "min_value": "కనిష్ట విలువ",
    "last_12_weeks": "గత 12 వారాలు",
    "last_6_months": "గత 6 నెలలు",
    "last_12_months": "చివరి 12 నెలలు",
    "year_5": "5 సంవత్సరాలు",
    "machinewise_comparison": "యంత్ర వారీగా పోలిక",
    "timeFrame": "కాలపరిమితి",
    "it_should_be_a_date": "ఇది తేదీ అయి ఉండాలి",
    "time_of_day_parameter": "రోజు సమయం- పరామితి",
    "tod_report": "TOD - నివేదిక",
    "in_this_timeframe": "ఈ సమయ వ్యవధిలో",
    "average": "సగటు",
    "maximum": "గరిష్టంగా",
    "minimum": "కనిష్ట",
    "energy_usage_trend": "శక్తి వినియోగ ధోరణి",
    "standard_deviation": "ప్రామాణిక విచలనం",
    "statistical_mode": "గణాంక మోడ్",
    "production_logs": "ఉత్పత్తి లాగ్‌లు",
    "capacity_logs": "సామర్థ్య లాగ్‌లు",
    "dynamic_logs": "డైనమిక్ లాగ్‌లు",
    "pollutants": "కాలుష్య కారకాలు",
    "group_by": "గ్రూప్ బై",
    "generate_grouped_trends": "సమూహ పోకడలను రూపొందించండి",
    "tCo2": "TCO2",
    "scope_1": "పరిధి 1",
    "scope_2": "స్కోప్ 2",
    "scope_3": "స్కోప్ 3",
    "source": "మూలం",
    "type": "రకం",
    "user_access_log": "వినియోగదారు ప్రాప్యత లాగ్",
    "user_logs": "వినియోగదారు లాగ్‌లు",
    "fetch_logs": "లాగ్‌లు పొందండి",
    "update_profile": "ప్రొఫైల్‌ను నవీకరించండి",
    "lang": "లాంగ్",
    "theme": "థీమ్",
    "phone": "ఫోన్",
    "update": "నవీకరణ",
    "update_password": "పాస్వర్డ్ను నవీకరించండి",
    "change_password": "పాస్వర్డ్ మార్చండి",
    "old_password": "పాత పాస్‌వర్డ్",
    "new_password": "క్రొత్త పాస్‌వర్డ్",
    "confirm_password": "పాస్వర్డ్ను నిర్ధారించండి",
    "password_must_be_more_than_8_characters": "పాస్వర్డ్ 8 అక్షరాల కంటే ఎక్కువ ఉండాలి",
    "passwords_do_not_match": "పాస్వర్డ్లు సరిపోలవు",
    "add_hub": "హబ్ జోడించండి",
    "id_must_be_less_than_4_characters": "ఐడి 4 అక్షరాల కన్నా తక్కువ ఉండాలి",
    "add_maintainer": "నిర్వహణను జోడించండి",
    "add_parent_org": "పేరెంట్ ఆర్గ్ జోడించండి",
    "add_parent_to_organisation": "సంస్థకు తల్లిదండ్రులను జోడించండి",
    "parent_org_id": "పేరెంట్ ఆర్గ్ ఐడి",
    "add_maintainer_to_organization": "సంస్థకు నిర్వహణను జోడించండి",
    "maintainer_id": "నిర్వహణ ID",
    "update_organisation_details": "సంస్థ వివరాలను నవీకరించండి",
    "update_your_organization_details": "మీ సంస్థ వివరాలను నవీకరించండి",
    "default_email": "డిఫాల్ట్ ఇమెయిల్",
    "picker_in_menu": "మెనులో పికర్",
    "param_diagnostics": "పారామ్ డయాగ్నోస్టిక్స్",
    "show_logo": "లోగోను చూపించు",
    "show_oem_logo": "OEM లోగోను చూపించు",
    "organisation_alias": "ఆర్గనైజేషన్ అలియాస్",
    "update_organisation_alias": "సంస్థ అలియాస్‌ను నవీకరించండి",
    "update_org_alias": "ఆర్గ్ అలియాస్‌ను నవీకరించండి",
    "update_your_organization_logo": "మీ సంస్థ లోగోను నవీకరించండి",
    "select_a_logo": "లోగోను ఎంచుకోండి",
    "update_logo": "లోగోను నవీకరించండి",
    "create_uam_user": "UAM వినియోగదారుని సృష్టించండి",
    "overview": "అవలోకనం",
    "batches": "బ్యాచ్‌లు",
    "track_batch": "ట్రాక్ బ్యాచ్",
    "batch_movement": "బ్యాచ్ కదలిక",
    "production_planner": "ప్రొడక్షన్ ప్లానర్",
    "show_unshipped_only": "UN మాత్రమే రవాణా చేయబడి చూపించు",
    "view_logs": "లాగ్‌లను చూడండి",
    "params": "పారామ్‌లు",
    "add_filter": "ఫిల్టర్ జోడించండి",
    "filters": "ఫిల్టర్లు",
    "batch_id": "Batch_id",
    "show_details": "వివరాలను చూపించు",
    "tracing_logs": "లాగ్‌లను గుర్తించడం",
    "quantity": "పరిమాణం",
    "location_event_type": "స్థాన ఈవెంట్ రకం",
    "fetch_plans": "ప్రణాళికలను పొందండి",
    "sku": "స్కు",
    "skus": "స్టాక్ కీపింగ్ యూనిట్లు",
    "code": "కోడ్",
    "add_production_plans": "ఉత్పత్తి ప్రణాళికలను జోడించండి",
    "date": "తేదీ",
    "from_time": "సమయం నుండి",
    "to_time": "సమయం వరకు",
    "activities": "కార్యకలాపాలు",
    "task_name": "పని పేరు",
    "task_details": "పని వివరాలు",
    "start_date": "ప్రారంభ తేదీ",
    "end_date": "ముగింపు తేదీ",
    "assignee": "కేటాయించిన",
    "manager": "మేనేజర్",
    "activity": "కార్యాచరణ",
    "activity_type": "కార్యాచరణ రకం",
    "escalation_matrix": "ఎస్కలేషన్ మ్యాట్రిక్స్",
    "maintenance_activity": "నిర్వహణ కార్యాచరణ",
    "em_level": "EM స్థాయి",
    "delay": "ఆలస్యం",
    "user_group": "వినియోగదారు సమూహం",
    "mins_5": "5 నిమిషాలు",
    "mins_10": "10 నిమిషాలు",
    "mins_15": "15 నిమిషాలు",
    "mins_30": "30 నిమిషాలు",
    "hour_1": "1 గంట",
    "hour_6": "6 గంట",
    "day_1": "1 రోజు",
    "day": "రోజు",
    "day_3": "3 రోజు",
    "insights": "అంతర్దృష్టులు",
    "create_emailer_group": "ఇమెయిల్ సమూహాన్ని సృష్టించండి",
    "notification_type": "నోటిఫికేషన్ రకం",
    "email": "ఇమెయిల్",
    "sms": "SMS",
    "create_machine_group": "యంత్ర సమూహాన్ని సృష్టించండి",
    "create_param_group": "పారామిన్‌ను సృష్టించండి",
    "create_user_group": "వినియోగదారు సమూహాన్ని సృష్టించండి",
    "create_module": "మాడ్యూల్‌ను సృష్టించండి",
    "busbar_temp_module": "బస్‌బార్ టెంప్ మాడ్యూల్",
    "power_quality_module": "పవర్ క్వాలిటీ మాడ్యూల్",
    "esg_module": "ESG మాడ్యూల్",
    "edge_hub": "ఎడ్జ్ హబ్",
    "tolerance": "సహనం",
    "ambient_temp_input_type": "పరిసర తాత్కాలిక ఇన్పుట్ రకం",
    "ambient_temp": "పరిసర తాత్కాలిక",
    "workspace_ambient_temp": "వర్క్‌స్పేస్ యాంబియంట్ టెంప్",
    "device_ambient_temp": "పరికర పరిసర తాత్కాలిక",
    "r_temperature": "R ఉష్ణోగ్రత",
    "y_temperature": "Y ఉష్ణోగ్రత",
    "b_temperature": "బి ఉష్ణోగ్రత",
    "n_temperature": "N ఉష్ణోగ్రత",
    "rated_current": "రేటెడ్ కరెంట్",
    "no_load_current": "లోడ్ కరెంట్ లేదు",
    "r_current": "R కరెంట్",
    "y_current": "Y కరెంట్",
    "b_current": "బి కరెంట్",
    "n_current": "N కరెంట్",
    "delete_activity": "కార్యాచరణను తొలగించండి",
    "substation_voltage_kv": "సబ్‌స్టేషన్ వోల్టేజ్ (కెవి)",
    "transformer_rating_mva": "Transformషధము",
    "percentage_impedance_of_the_transformer": "ట్రాన్స్ఫార్మర్ యొక్క % ఇంపెడెన్స్",
    "voltage_rv_y": "Volషధము",
    "voltage_yb_v": "Volపిరి పీల్చుట",
    "voltage_br_v": "వోల్టేజ్ BR (V)",
    "current_r_a": "ప్రస్తుత r (ఎ)",
    "current_y_a": "ప్రస్తుత y (ఎ)",
    "current_b_a": "ప్రస్తుత బి (ఎ)",
    "thd_i": "Thd i",
    "thd_v": "Thd v",
    "thd_i1": "Thd i1",
    "active_power_kw": "క్రియాశీల శక్తి",
    "apparent_power_kva": "స్పష్టమైన శక్తి",
    "reactive_power_kvar": "రియాక్టివ్ శక్తి (kvar)",
    "active_energy_kwh": "క్రియాశీల శక్తి",
    "apparent_energy_kvah": "స్పష్టమైన శక్తి",
    "pf": "పిఎఫ్",
    "text": "వచనం",
    "energy_source": "శక్తి వనరు",
    "scope": "స్కోప్",
    "system_logs": "సిస్టమ్ లాగ్‌లు",
    "system_logbook": "సిస్టమ్ లాగ్ బుక్",
    "esg_data_log": "ESG డేటా లాగ్",
    "add_esg_data": "ESG డేటాను జోడించండి",
    "add_esg_data_log": "ESG డేటా లాగ్‌ను జోడించండి",
    "source_type": "మూల రకం",
    "unit_of_measurement": "కొలత యూనిట్",
    "from_date_optional": "తేదీ నుండి (ఐచ్ఛికం)",
    "emission_factor": "ఉద్గార కారకం",
    "create_dynamic_logger": "డైనమిక్ లాగర్ సృష్టించండి",
    "dynamic_log": "డైనమిక్ లాగ్",
    "primary_field_label": "ప్రాథమిక ఫీల్డ్ లేబుల్",
    "primary_field_type": "ప్రాథమిక క్షేత్ర రకం",
    "primary": "ప్రాథమిక",
    "additional_fields": "అదనపు ఫీల్డ్‌లు",
    "field_label": "ఫీల్డ్ లేబుల్",
    "field_type": "ఫీల్డ్ రకం",
    "choose_list": "జాబితాను ఎంచుకోండి",
    "list": "జాబితా",
    "create_capacity_logger": "సామర్థ్యం లాగర్ సృష్టించండి",
    "capacity_log": "సామర్థ్యం లాగ్",
    "create": "సృష్టించండి",
    "create_production_logger": "ఉత్పత్తి లాగర్ సృష్టించండి",
    "production_log": "ఉత్పత్తి లాగ్",
    "production_logger": "ఉత్పత్తి లాగర్",
    "input_type": "ఇన్పుట్ రకం",
    "input": "ఇన్పుట్",
    "input_name": "ఇన్పుట్ పేరు",
    "output_type": "అవుట్పుట్ రకం",
    "output": "అవుట్పుట్",
    "output_name": "అవుట్పుట్ పేరు",
    "output_params": "అవుట్పుట్ పారామ్‌లు",
    "compute_production": "కంప్యూట్ ఉత్పత్తి",
    "compute_energy": "కంప్యూట్ ఎనర్జీ",
    "energy_param_type": "శక్తి పారానికి",
    "energy_device": "శక్తి పరికరం",
    "energy_param": "శక్తి పారామ్",
    "production_operator": "ప్రొడక్షన్ ఆపరేటర్",
    "production_correction_factor": "ఉత్పత్తి దిద్దుబాటు కారకం",
    "output_operator": "అవుట్పుట్ ఆపరేటర్",
    "output_correction_factor": "అవుట్పుట్ దిద్దుబాటు కారకం",
    "input_operator": "ఇన్పుట్ ఆపరేటర్",
    "input_correction_factor": "అవుట్పుట్ దిద్దుబాటు కారకం",
    "energy_unit": "శక్తి యూనిట్",
    "production_unit": "ఉత్పత్తి యూనిట్",
    "production": "ఉత్పత్తి",
    "create_data_logger": "డేటా లాగర్ సృష్టించండి",
    "data_log": "డేటా లాగ్",
    "add_param": "పారామ్ జోడించండి",
    "param_id": "పారామ్ ఐడి",
    "create_batch_production_logger": "బ్యాచ్ ప్రొడక్షన్ లాగర్ సృష్టించండి",
    "batch_tracing_log": "బ్యాచ్ ట్రేసింగ్ లాగ్",
    "log_name": "లాగ్ పేరు",
    "location": "స్థానం",
    "info": "సమాచారం",
    "remarks": "వ్యాఖ్యలు",
    "code_will_be_available_to_log_by_default": "డిఫాల్ట్‌గా లాగిన్ అవ్వడానికి కోడ్ అందుబాటులో ఉంటుంది",
    "fields": "ఫీల్డ్స్",
    "additional_info": "అదనపు సమాచారం",
    "action": "చర్య",
    "report_type": "నివేదిక రకం",
    "create_parameter_report": "పారామితి నివేదికను సృష్టించండి",
    "data_processing": "డేటా ప్రాసెసింగ్",
    "addition": "అదనంగా",
    "singular": "ఏకవచనం",
    "report": "నివేదిక",
    "energy_parameters_kwh": "శక్తి పారామితులు (kWh)",
    "apparent_energy_KVAh": "స్పష్టమైన శక్తి kvah",
    "maximum_demand_MD": "గరిష్ట డిమాండ్ (MD)",
    "excel_frequency": "ఎక్సెల్ ఫ్రీక్వెన్సీ",
    "multiplier": "గుణకం",
    "single": "సింగిల్",
    "incomers": "ఆదాయాలు",
    "sections": "విభాగాలు",
    "energy_distribution_report": "శక్తి పంపిణీ నివేదిక",
    "root_machine": "రూట్ మెషిన్",
    "tariff_on": "సుంకం ఆన్",
    "add_root_common_machine": "రూట్ & కామన్ మెషీన్ను జోడించండి",
    "common_machine": "సాధారణ యంత్రం",
    "energy_bill_distribution_report": "ఇంధన బిల్లు పంపిణీ నివేదిక",
    "create_consolidated": "ఏకీకృత సృష్టించండి",
    "choose_report": "నివేదికను ఎంచుకోండి",
    "id": "ఐడి",
    "timeframed_sec": "సమయం ఫ్రేమ్డ్ సెక",
    "create_dynamic_excel_report": "డైనమిక్ ఎక్సెల్ నివేదికను సృష్టించండి",
    "update_dynamic_excel_report": "డైనమిక్ ఎక్సెల్ నివేదికను నవీకరించండి",
    "add_sheet": "షీట్ జోడించండి",
    "sheet_name": "షీట్ పేరు",
    "timestamp": "టైమ్‌స్టాంప్",
    "sheet": "షీట్",
    "sheets": "షీట్లు",
    "sheet_id": "షీట్ ఐడి",
    "delete_sheet": "షీట్ తొలగించు",
    "report_name": "నివేదిక పేరు",
    "update_details": "వివరాలను నవీకరించండి",
    "unit": "యూనిట్",
    "parameters": "పారామితులు",
    "send_excel_file": "ఎక్సెల్ ఫైల్ పంపండి",
    "update_excel_template_based_report": "ఎక్సెల్ టెంప్లేట్ ఆధారిత నివేదికను నవీకరించండి",
    "create_excel_template_based_report": "ఎక్సెల్ టెంప్లేట్ ఆధారిత నివేదికను సృష్టించండి",
    "upload_excel_template_xlsx": "ఎక్సెల్ టెంప్లేట్ (XLSX) ను అప్‌లోడ్ చేయండి",
    "param_equation": "పారాకార్ సమీకరణం",
    "param_equations": "పారామితి సమీకరణాలు",
    "edit_sheets": "షీట్లను సవరించండి",
    "row_no": "వరుస సంఖ్య",
    "column_no": "కాలమ్ నం",
    "row_number": "వరుస సంఖ్య",
    "column_number": "కాలమ్ సంఖ్య",
    "colummn_number": "కాలమ్ సంఖ్య",
    "no_sheets_added": "షీట్లు జోడించబడలేదు",
    "process_based_usage_sheet": "ప్రాసెస్ ఆధారిత వినియోగ షీట్",
    "process": "ప్రక్రియ",
    "process_label": "ప్రాసెస్ లేబుల్",
    "add_tag_types": "ట్యాగ్ రకాలను జోడించండి",
    "add_process_to_list": "జాబితాకు ప్రాసెస్‌ను జోడించండి",
    "machine_tag_based_daily_data_sheet": "మెషిన్ ట్యాగ్ ఆధారిత రోజువారీ డేటా షీట్",
    "machine_label": "మెషిన్ లేబుల్",
    "add_machine_to_list": "జాబితాకు యంత్రాన్ని జోడించండి",
    "add_tag_type_to_list": "జాబితాకు ట్యాగ్ రకాన్ని జోడించండి",
    "shift_based_usage_sheet": "షిఫ్ట్ ఆధారిత వినియోగ షీట్",
    "status_for_process": "ప్రక్రియ",
    "status_for_machine": "యంత్రం",
    "esg_summary_item": "సారాంశం",
    "esg_details_item": "వివరాలు",
    "esg_scope1_label": "పరిధి 1",
    "esg_scope2_label": "స్కోప్ 2",
    "esg_scope3_label": "స్కోప్ 3",
    "esg_scopes_list_label": "స్కోప్స్",
    "transpose": "ట్రాన్స్పోజ్",
    "process_based_timeframed_data_sheet": "ప్రాసెస్ బేస్డ్ టైమ్‌ఫ్రేమ్డ్ డేటా షీట్",
    "add_pocess_to_list": "జాబితాకు ప్రాసెస్‌ను జోడించండి",
    "tag_types": "ట్యాగ్ రకాలు",
    " consolidated_shift_based_usage_data_sheet": "కన్సాలిడేటెడ్ షిఫ్ట్ ఆధారిత వినియోగ డేటా షీట్",
    "date_format": "తేదీ ఆకృతి",
    "include_process_total": "ప్రక్రియ మొత్తాన్ని చేర్చండి",
    "machine_group_based_usage_sheet": "మెషిన్ గ్రూప్ ఆధారిత వినియోగ షీట్",
    "machine_group_label": "మెషిన్ గ్రూప్ లేబుల్",
    "machine_group": "యంత్ర సమూహం",
    "add_machine_group_to_list": "జాబితాకు యంత్ర సమూహాన్ని జోడించండి",
    "parameter_based_usage_data_sheet": "పారామితి ఆధారిత వినియోగ డేటా షీట్",
    "show_month_start&end_data": "నెల ప్రారంభం & ముగింపు డేటాను చూపించు",
    "statistics": "గణాంకాలు",
    "add_param_to_list": "జాబితాకు పారామ్ జోడించండి",
    "add_timestamp_to_list": "జాబితాకు టైమ్‌స్టాంప్ జోడించండి",
    "parameter_group_based_timeframed": "పారామితి సమూహం ఆధారిత కాలపరిమితి",
    "param_group_label": "పారామ్ గ్రూప్ లేబుల్",
    "param_group": "పారా గ్రూప్",
    "add_param_group_to_list": "జాబితాకు పారామ్ సమూహాన్ని జోడించండి",
    "dynamic_logger_based_data_sheet": "డైనమిక్ లాగర్ ఆధారిత డేటా షీట్",
    "dynamic_log_label": "డైనమిక్ లాగ్ లేబుల్",
    "parameter_equation_based_sheet": "పారామితి ఆధారిత షీట్",
    "data_source": "డేటా మూలం",
    "equation_label": "సమీకరణ లేబుల్",
    "add_equation_to_list": "జాబితాకు సమీకరణాన్ని జోడించండి",
    "absolute_values": "సంపూర్ణ విలువలు",
    "consumption_based_sheet": "వినియోగ ఆధారిత షీట్",
    "parameter_name": "పారామితి పేరు",
    "statistical_analysis_based_sheet": "గణాంక విశ్లేషణ ఆధారిత షీట్",
    "parameter_instantaneous_data_sheet": "పారామితి తక్షణ డేటా షీట్",
    "create_sheet_column": "షీట్ కాలమ్ సృష్టించండి",
    "column_label": "కాలమ్ లేబుల్",
    "machine_id": "మెషిన్ ఐడి",
    "create_machine_list": "యంత్ర జాబితాను సృష్టించండి",
    "create_param_list": "పారామితి జాబితాను సృష్టించండి",
    "create_sheets": "షీట్లను సృష్టించండి",
    "create_shift": "షిఫ్ట్ సృష్టించండి",
    "description_optional": "వివరణ (ఐచ్ఛికం)",
    "create_sku": "SKU ను సృష్టించండి",
    "create_location": "స్థానాన్ని సృష్టించండి",
    "location_type": "స్థాన రకం",
    "from_location": "స్థానం నుండి",
    "to_location": "స్థానానికి",
    "event_type": "ఈవెంట్ రకం",
    "create_location_event_type": "స్థాన ఈవెంట్ రకాన్ని సృష్టించండి",
    "create_list": "జాబితాను సృష్టించండి",
    "create_param_equation": "పారామ్ సమీకరణాన్ని సృష్టించండి",
    "positive_params": "సానుకూల పారామ్‌లు",
    "params_to_be_substracted": "తీసివేయవలసిన పారామ్‌లు",
    "create_combo_param": "కాంబో పారామ్ సృష్టించండి",
    "combo_params": "కంబైన్డ్ పారామితులు",
    "calculated_params": "లెక్కించిన పారామితులు",
    "inter_param_operator": "ఇంటర్ పారా ఆపరేటర్",
    "correction_operator": "దిద్దుబాటు ఆపరేటర్",
    "correction_operand": "దిద్దుబాటు ఆపరేషన్",
    "create_calculated_param": "లెక్కించిన పారామ్ సృష్టించండి",
    "calculated_param": "లెక్కించిన పారామ్",
    "correction_factor": "దిద్దుబాటు కారకం",
    "param1_type": "పారామ్ 1 రకం",
    "param1": "పారామ్ 1",
    "param1_operator": "పారామ్ 1 ఆపరేటర్",
    "param1_operand": "పారామ్ 1 ఒపెరాండ్",
    "param2_type": "పారామ్ 2 రకం",
    "param2": "పారామ్ 2",
    "param2_operator": "పారామ్ 2 ఆపరేటర్",
    "param2_operand": "పారామ్ 2 ఒపెరాండ్",
    "create_esg_scope_conversion": "ESG స్కోప్ మార్పిడిని సృష్టించండి",
    "category": "వర్గం",
    "esg_scope_logging": "ESG స్కోప్ లాగింగ్",
    "get_from_param": "పారామ్ నుండి పొందండి",
    "choose_param": "పారామ్ ఎంచుకోండి",
    "create_target": "లక్ష్యాన్ని సృష్టించండి",
    "create_word": "పదాన్ని సృష్టించండి",
    "words": "పదాలు",
    "choose_OEM": "OEM ఎంచుకోండి",
    "get_the_models": "మోడళ్లను పొందండి",
    "get_oem_devices": "OEM పరికరాలను పొందండి",
    "oem_devices": "OEM పరికరాలు",
    "model_number": "మోడల్ సంఖ్య",
    "register": "రిజిస్టర్",
    "add_modbus_gateway": "మోడ్‌బస్ గేట్‌వే జోడించండి",
    " add_modbus_TCP_gateway": "మోడ్‌బస్ టిసిపి గేట్‌వే జోడించండి",
    "IPAddress": "IP చిరునామా",
    "port_number": "పోర్ట్ సంఖ్య",
    "HubID": "హబ్ ఐడి",
    "baud_rate": "బాడ్ రేటు",
    "parity": "పారిటీ",
    "stop_bit": "బిట్ ఆపు",
    "update_wifi": "వైఫైని నవీకరించండి",
    "SSID": "Ssid",
    "password": "పాస్వర్డ్",
    "restart_enture": "ఎంటూర్ పున art ప్రారంభించండి",
    "force_update_configs": "ఫోర్స్ అప్‌డేట్ కాన్ఫిగస్",
    "activity_name": "కార్యాచరణ పేరు",
    "board": "బోర్డు",
    "calender": "క్యాలెండర్",
    "maintenance": "నిర్వహణ",
    "welcome": "స్వాగతం",
    "logout": "లాగ్అవుట్",
    "help": "సహాయం",
    "accounts": "ఖాతాలు",
    "organisation": "సంస్థ",
    "pointer_color_optional": "పాయింటర్ రంగు (ఐచ్ఛికం)",
    "user_id": "వినియోగదారు ID",
    "confirm_new_password": "క్రొత్త పాస్‌వర్డ్‌ను నిర్ధారించండి",
    "service": "సేవ",
    "send_grid": "Sendgrid",
    "default_enture_view": "డిఫాల్ట్ ఎన్‌టూర్ వ్యూ",
    "permissions": "అనుమతులు",
    "areas": "ప్రాంతాలు",
    "remark": "వ్యాఖ్య",
    "days": "రోజులు",
    "yesterdays_aqi": "నిన్నటి AQI",
    "aqi": "Aqi",
    "choose_air_quality_device": "గాలి నాణ్యత పరికరాన్ని ఎంచుకోండి",
    "delete_this_device": "ఈ పరికరాన్ని తొలగించండి",
    "air_quality_label": "గాలి నాణ్యత లేబుల్",
    "humidity": "తేమ",
    "temperature": "ఉష్ణోగ్రత",
    "watcher": "వాచర్",
    "area_reference": "ప్రాంత సూచన",
    "write_data": "డేటా రాయండి",
    "day_of_week": "వారం రోజు",
    "month": "నెల",
    "diagnostics_notification_groups": "డయాగ్నోస్టిక్స్ నోటిఫికేషన్ సమూహాలు",
    "hub_name": "హబ్ పేరు",
    "hub_model": "హబ్ మోడల్",
    "hub_type": "హబ్ రకం",
    "hub": "హబ్",
    "update_your_MQTT_bridge_config": "మీ MQTT బ్రిడ్జ్ కాన్ఫిగర్ను నవీకరించండి",
    "MQTT_bridge_mode": "MQTT బ్రిడ్జ్ మోడ్",
    "broker_address": "బ్రోకర్ చిరునామా",
    "update_your_OPC_config": "మీ OPC కాన్ఫిగరేషన్‌ను నవీకరించండి",
    "OPC_client_mode": "OPC క్లయింట్ మోడ్",
    "OPC_server_URL": "OPC సర్వర్ URL",
    "user_auth": "వినియోగదారు ప్రమాణం",
    "mode": "మోడ్",
    "security_policy": "భద్రతా విధానం",
    "Update_your_Edge_Hub_restart_Schedule": "మీ ఎడ్జ్ హబ్ పున art ప్రారంభ షెడ్యూల్‌ను నవీకరించండి",
    "auto_restart": "ఆటో పున art ప్రారంభం",
    "time_24Hrs": "సమయం (24 గంటలు)",
    "modbus_ping_latency": "మోడ్‌బస్ పింగ్ జాప్యం",
    "modbus_unhealthy_wait": "మోడ్‌బస్ అనారోగ్యకరమైన వేచి ఉంది",
    "edit_written_log_book": "వ్రాతపూర్వక లాగ్ పుస్తకాన్ని సవరించండి",
    "field_cant_change": "ఈ ఫీల్డ్ మారదు",
    "re_write": "తిరిగి వ్రాయడం",
    "write_in_logbook": "లాగ్‌బుక్‌లో రాయండి",
    "write": "వ్రాయండి",
    "download_template": "టెంప్లేట్‌ను డౌన్‌లోడ్ చేయండి",
    "write_in_logbook_csv": ".CSV ద్వారా లాగ్ పుస్తకంలో రాయండి",
    "upload_file": "ఫైల్‌ను అప్‌లోడ్ చేయండి",
    "batch": "బ్యాచ్",
    "move_batch": "బ్యాచ్ తరలించండి",
    "on": "ఆన్",
    "off": "ఆఫ్",
    "fast_mode": "ఫాస్ట్ మోడ్",
    "batch_shipping_details": "బ్యాచ్ షిప్పింగ్ వివరాలు",
    "vehicle_no": "వెహికల్ నం",
    "net_weight": "నికర బరువు",
    "actual_weight": "అసలు బరువు",
    "vendor": "విక్రేత",
    "loaded_by": "లోడ్ చేయబడింది",
    "verified_by": "ధృవీకరించబడింది",
    "document_no": "డాక్యుమెంట్ నం",
    "multiple_machine_param": "బహుళ యంత్రం & పారామ్‌లు",
    "map_paramid_paramequation_to_row": "మ్యాప్ పారామ్ ఐడి లేదా పారామ్ సమీకరణాలు వరుసకు",
    "add_current_date_to_sheet": "షీట్‌కు ప్రస్తుత తేదీని జోడించండి",
    "create_parameter_from_template": "టెంప్లేట్ నుండి పరామితిని సృష్టించండి",
    "selected_parameters_count": "ఎంచుకున్న పారామితులు లెక్కించబడ్డాయి",
    "restricted_user_access": "పరిమితం చేయబడిన వినియోగదారు ప్రాప్యత",
    "select_all_parameters": "అన్ని పారామితులను ఎంచుకోండి",
    "edit_angular_gauge_widget": "కోణీయ గేజ్ విడ్జెట్‌ను సవరించండి",
    "add_angular_gauge_widget": "కోణీయ గేజ్ విడ్జెట్ జోడించండి",
    "widget_label": "విడ్జెట్ లేబుల్",
    "widget_width": "విడ్జెట్ వెడల్పు",
    "widget_height": "విడ్జెట్ ఎత్తు",
    "param_label": "పారాము లేబుల్",
    "choose_parameter": "పరామితిని ఎంచుకోండి",
    "processing": "ప్రాసెసింగ్",
    "edit_banner_widget": "బ్యానర్ విడ్జెట్‌ను సవరించండి",
    "add_banner_widget": "బ్యానర్ విడ్జెట్ జోడించండి",
    "edit_bartd_widget": "బార్ టిడి విడ్జెట్‌ను సవరించండి",
    "add_bar_widget": "బార్ విడ్జెట్ జోడించండి",
    "yaxis_label": "Y - యాక్సిస్ లేబుల్",
    "xaxis_label": "X - అక్షం లేబుల్",
    "y1axis_label": "Y1 - యాక్సిస్ లేబుల్",
    "y2axis_label": "Y2 - యాక్సిస్ లేబుల్",
    "edit_dual_axis_bar_trend_widget": "డ్యూయల్ యాక్సిస్ బార్ ట్రెండ్ విడ్జెట్‌ను సవరించండి",
    "add_dual_axis_bar_trend_widget": "డ్యూయల్ యాక్సిస్ బార్ ట్రెండ్ విడ్జెట్ జోడించండి",
    "y1_graph_type": "Y1 యాక్సిస్ గ్రాఫ్ రకం",
    "y2_graph_type": "Y2 యాక్సిస్ గ్రాఫ్ రకం",
    "idealValue": "ఆదర్శ విలువ",
    "idealLabel": "ఆదర్శ లేబుల్",
    "add_parameters": "పారామితులను జోడించండి",
    "add_equation": "సమీకరణాన్ని జోడించండి",
    "add_params": "పారామ్‌లను జోడించండి",
    "enter_of_machines": "యంత్రాలను నమోదు చేయండి",
    "edit_bar_trend_td_widget": "బార్ ట్రెండ్ టిడి విడ్జెట్‌ను సవరించండి",
    "add_bar_trend_td_widget": "బార్ ట్రెండ్ టిడి విడ్జెట్ జోడించండి",
    "graph_type": "గ్రాఫ్ రకం",
    "aggregation": "అగ్రిగేషన్",
    "default_graph_type": "డిఫాల్ట్ గ్రాఫ్ రకం",
    "bar_mode": "బార్ మోడ్",
    "show_ideal_line": "ఆదర్శ రేఖను చూపించు",
    "equations": "సమీకరణాలు",
    "edit_bitwise_word_widget": "బిట్‌వైస్ వర్డ్ విడ్జెట్‌ను సవరించండి",
    "add_bitwise_word_widget": "బిట్‌వైస్ వర్డ్ విడ్జెట్ జోడించండి",
    "show_bit_status": "బిట్ స్థితిని చూపించు",
    "edit_bullet_gauge_widget": "బుల్లెట్ గేజ్ విడ్జెట్‌ను సవరించండి",
    "add_bullet_gauge_widget": "బుల్లెట్ గేజ్ విడ్జెట్ జోడించండి",
    "reference_value_for": "కోసం సూచన విలువ",
    "gauge_type": "గేజ్ రకం",
    "indicator_color": "సూచిక రంగు",
    "busbar_temperature_graph_widget": "బస్ బార్ ఉష్ణోగ్రత గ్రాఫ్ విడ్జెట్",
    "busbar_module": "బస్ బార్ మాడ్యూల్",
    "edit_consumption_gauge_widget": "వినియోగ గేజ్ విడ్జెట్‌ను సవరించండి",
    "add_consumption_gauge_widget": "వినియోగ గేజ్ విడ్జెట్‌ను జోడించండి",
    "linearGauge": "లీనియర్ గేజ్",
    "show_target": "లక్ష్యాన్ని చూపించు",
    "healthy_on_below_target": "దిగువ లక్ష్యంలో ఆరోగ్యకరమైనది",
    "edit_consumption_pie_widget": "వినియోగం పై విడ్జెట్ సవరించండి",
    "add_consumption_pie_widget": "వినియోగ పై విడ్జెట్ జోడించండి",
    "add_color_break_point": "కలర్ బ్రేక్ పాయింట్ జోడించండి",
    "custom_color": "అనుకూల రంగు",
    "color": "రంగు",
    "edit_heat_map_widget": "హీట్ మ్యాప్ విడ్జెట్‌ను సవరించండి",
    "add_heat_map__widget": "హీట్ మ్యాప్ విడ్జెట్ జోడించండి",
    "choose_equation": "సమీకరణాన్ని ఎంచుకోండి",
    "label_star": "లేబుల్*",
    "color_star": "రంగు*",
    "operator1_star": "ఆపరేటర్ 1*",
    "value1_star": "విలువ 1*",
    "operator2_star": "ఆపరేటర్ 2*",
    "actions": "చర్యలు",
    "condition": "షరతులు",
    "status_color": "స్థితి రంగు",
    "text_view": "టెక్స్ట్ వీక్షణ",
    "edit_process_Status_widget": "ప్రాసెస్ స్థితిని సవరించండి స్థితి విడ్జెట్",
    "add_process_Status_widget": "ప్రాసెస్ స్థితిని జోడించండి విడ్జెట్",
    "edit_machine_Status_widget": "మెషిన్ స్థితి విడ్జెట్ సవరించండి",
    "add_machine_Status_widget": "యంత్ర స్థితి విడ్జెట్ జోడించండి",
    "choose_process": "ప్రక్రియలను ఎంచుకోండి",
    "choose_machine": "యంత్రాలను ఎంచుకోండి",
    "table_header_label": "టేబుల్ హెడర్ లేబుల్",
    "edit_table_TD_widget": "టేబుల్ టిడి విడ్జెట్ సవరించండి",
    "add_table_TD_widget": "టేబుల్ టిడి విడ్జెట్ జోడించండి",
    "configure_table": "పట్టికను కాన్ఫిగర్ చేయండి",
    "edit_target_based_trend_widget": "లక్ష్య ఆధారిత ధోరణి విడ్జెట్‌ను సవరించండి",
    "add_target_based_trend_widget": "లక్ష్య ఆధారిత ధోరణి విడ్జెట్‌ను జోడించండి",
    "target": "లక్ష్యం",
    "target_line_label": "టార్గెట్ లైన్ లేబుల్",
    "edit_usage_score_card": "వినియోగ స్కోర్‌కార్డ్ విడ్జెట్‌ను సవరించండి",
    "add_usage_score_card": "వినియోగ స్కోర్‌కార్డ్ విడ్జెట్‌ను జోడించండి",
    "compare_to": "పోల్చండి",
    "assign_color": "రంగును కేటాయించండి",
    "enter": "నమోదు చేయండి",
    "gauge_to_dashboard": "డాష్‌బోర్డ్‌కు గేజ్",
    "widget_types": "విడ్జెట్ రకాలు",
    "from_value": "విలువ నుండి",
    "to_value": "విలువకు",
    "machine_status": "యంత్ర స్థితి",
    "deviceA_type": "పరికరం ఒక రకం",
    "templates": "టెంప్లేట్లు",
    "add_triggers_to_parameter": "పారామితికి ట్రిగ్గర్‌లను జోడించండి",
    "triggers": "ట్రిగ్గర్‌లు",
    "show_filter": "ఫిల్టర్ చూపించు",
    "view_id": "ID ని చూడండి",
    "move_up": "పైకి కదలండి",
    "move_down": "క్రిందికి కదలండి",
    "multiplier_types": "గుణకం_టైప్స్",
    "energy_report": "శక్తి నివేదిక",
    "add_machine_param": "మెషిన్ పారామ్ జోడించండి",
    "aliasing": "అలియాసింగ్",
    "no_zero_read": "సున్నా చదవలేదు",
    "remove_negative_entropy": "ప్రతికూల ఎంట్రోపీని తొలగించండి",
    "calculate_consumption": "వినియోగాన్ని లెక్కించండి",
    "disabled": "నిలిపివేయబడింది",
    "limiter_value": "పరిమిత విలువ",
    "machine_scroll_view_page": "మెషిన్ స్క్రోల్ వీక్షణ పేజీ",
    "create_mannual_parameter": "మాన్యువల్ పరామితిని సృష్టించండి",
    "restrict_user_access": "వినియోగదారు ప్రాప్యతను పరిమితం చేయండి",
    "add_device_parameter": "పరికర పరామితిని జోడించండి",
    "entropy": "ఎంట్రోపీ",
    "idle_latency": "నిష్క్రియ జాప్యం",
    "add_OPC_parameter": "OPC పరామితిని జోడించండి",
    "node_id": "నోడ్ ఐడి",
    "byte_format": "బైట్ ఫార్మాట్",
    "param_scroll_view_page": "పారామ్ స్క్రోల్ వీక్షణ పేజీ",
    "timeframed_sec_report": "సమయం ఫ్రేమ్డ్ SEC నివేదిక",
    "prod_unit": "ప్రోడ్ యూనిట్",
    "SEC_unit": "SEC యూనిట్",
    "energy_parameters": "శక్తి పారామితులు",
    "prod_param_type": "ప్రోడ్ పారానికి",
    "production_parameters": "ఉత్పత్తి పారామితులు",
    "no_of_intervals": "విరామాలు లేవు",
    "granular_data": "కణిక డేటా",
    "csv": "Csv",
    "delete_rule": "నియమాన్ని తొలగించండి",
    "enable_disable_all_parameters": "అన్ని పారామితులను ప్రారంభించండి/నిలిపివేయండి",
    "disable": "నిలిపివేయండి",
    "enable": "ఎనేబుల్",
    "edit_computed_parameter": "కంప్యూటెడ్ పరామితిని సవరించండి",
    "quantization": "పరిమాణీకరణ",
    "command": "కమాండ్",
    "register_size": "రిజిస్టర్ పరిమాణం",
    "consumer_ID": "వినియోగదారు ఐడి",
    "site_id": "సైట్ ఐడి",
    "get_data": "డేటాను పొందండి",
    "update_param_type": "పారామ్ రకాన్ని నవీకరించండి",
    "write_in_data_logbook": "డేటా లాగ్‌బుక్‌లో రాయండి",
    "daily_data": "రోజువారీ డేటా",
    "log_entry": "లాగ్ ఎంట్రీ",
    "enable_Scroll_mode": "స్క్రోల్ మోడ్‌ను ప్రారంభించండి",
    "multiple_areas": "బహుళ ప్రాంతాలు",
    "time_of_day": "రోజు సమయం",
    "capacity_logger": "సామర్థ్యం లాగర్",
    "provider": "ప్రొవైడర్",
    "total_sites_received_bills_MTD": "మొత్తం సైట్‌లకు బిల్లులు MTD అందుకున్నాయి",
    "sites_yet_to_receive_bills": "ఇంకా బిల్లులు స్వీకరించని సైట్లు",
    "total_sites": "మొత్తం సైట్లు",
    "sites_received_bills_MTD": "%సైట్‌లకు బిల్లులు MTD అందుకున్నాయి",
    "total": "మొత్తం",
    "total_bills_received_mtd": "మొత్తం బిల్లులు MTD",
    "arrear_1": "బకాయి 1",
    "arrear_2": "బకాయి 2",
    "no_reading": "చదవడం లేదు",
    "bills_received_in_5_days": "గత 5 రోజుల్లో అందుకున్న బిల్లులు",
    "site_wise_status": "సైట్ వైజ్ స్టేటస్ కాకుండా ఇతర స్థితి",
    "site_wise_final_summary": "సైట్ వారీగా తుది సారాంశం",
    "source_link": "మూల లింక్",
    "data_type": "డేటా రకం",
    "select_an_image_to_upload": "అప్‌లోడ్ చేయడానికి చిత్రాన్ని ఎంచుకోండి",
    "user_group_Id": "వినియోగదారు సమూహ ID",
    "user_group_name": "వినియోగదారు సమూహ పేరు",
    "delay_in_mins": "(నిమిషాలు) ఆలస్యం",
    "delete_task": "పనిని తొలగించండి",
    "watchers": "వాచర్స్",
    "comments": "వ్యాఖ్యలు",
    "asset_under_maintenance": "నిర్వహణలో ఆస్తి",
    "start_time": "ప్రారంభ సమయం",
    "end_time": "ముగింపు సమయం",
    "download_latest_usage_table": "తాజా వినియోగ పట్టికను డౌన్‌లోడ్ చేయండి",
    "tag": "ట్యాగ్",
    "add_target_data": "లక్ష్య డేటాను జోడించండి",
    "edit_target": "లక్ష్యాన్ని సవరించండి",
    "delete_target": "లక్ష్యాన్ని తొలగించండి",
    "prop": "ఆసరా",
    "excel_template_based_report": "ఎక్సెల్ టెంప్లేట్ ఆధారిత నివేదిక",
    "configured_hub": "కాన్ఫిగర్ హబ్",
    "configured_relay": "రిలే కాన్ఫిగర్ చేయబడింది",
    "desired_state": "కావలసిన రాష్ట్రం",
    "configured_notification_group": "కాన్ఫిగర్ చేసిన నోటిఫికేషన్ సమూహం",
    "email_ids": "ఇమెయిల్ ID లు",
    "logs": "లాగ్స్",
    "unit_id": "యూనిట్ ఐడి",
    "download_report": "డౌన్‌లోడ్ నివేదిక",
    "frequency": "ఫ్రీక్వెన్సీ",
    "ip": "Ip",
    "edit_wifi": "వైఫైని సవరించండి",
    "details": "వివరాలు",
    "connected_to": "కనెక్ట్ చేయబడింది",
    "software_version": "సాఫ్ట్‌వేర్ వెర్షన్",
    "mac_address": "MAC చిరునామా",
    "opc_client_enabled": "OPC క్లయింట్ ప్రారంభించబడింది",
    "MQTT_bridge_enabled": "MQTT వంతెన ప్రారంభించబడింది",
    "hub_specification": "హబ్ స్పెసిఫికేషన్",
    "hub_details": "హబ్ వివరాలు",
    "restart_schedule": "షెడ్యూల్ పున art ప్రారంభం",
    "restart_device": "పరికరాన్ని పున art ప్రారంభించండి",
    "set_up_restart_time": "పున art ప్రారంభ సమయాన్ని ఏర్పాటు చేయండి",
    "connectivity_logs": "కనెక్టివిటీ లాగ్‌లు",
    "internal_cache_size": "అంతర్గత కాష్ పరిమాణం",
    "default": "డిఫాల్ట్",
    "configs": "కాన్ఫిగస్",
    "processed": "ప్రాసెస్ చేయబడింది",
    "space_available": "స్థలం అందుబాటులో ఉంది",
    "kb": "Kb",
    "mb": "MB",
    "last_ping": "చివరి పింగ్",
    "toggle": "టోగుల్",
    "configured_creds": "కాన్ఫిగర్డ్ క్రెడిట్స్",
    "psk": "Psk",
    "set_value": "విలువను సెట్ చేయండి",
    "time_current_time": "సమయం (ప్రస్తావించకపోతే ప్రస్తుత సమయం తీసుకోబడుతుంది)",
    "Set": "సెట్",
    "health": "ఆరోగ్యం",
    "port": "పోర్ట్",
    "mqtt_params": "MQTT-PARAMS",
    "delete_device": "పరికరాన్ని తొలగించండి",
    "view_details": "వివరాలను చూడండి",
    "parameter_id": "పారామితి ఐడి",
    "email_address": "ఇమెయిల్ చిరునామా",
    "attached_triggers": "జతచేయబడిన ట్రిగ్గర్‌లు",
    "parameter_details": "పారామితి వివరాలు",
    "enable_write": "రాయండి",
    "disable_write": "వ్రాతను నిలిపివేయండి",
    "offset": "ఆఫ్‌సెట్",
    "no_of_parameters": "పారామితులు లేవు",
    "creation_time": "సృష్టి సమయం",
    "not_configured": "కాన్ఫిగర్ చేయబడలేదు",
    "add_rule": "నియమాన్ని జోడించండి",
    "sl_no": "Sl no",
    "targeted_by_value": "విలువ ద్వారా లక్ష్యంగా ఉంది",
    "configured_action": "కాన్ఫిగర్ చేయబడిన చర్య",
    "add_action": "చర్యను జోడించండి",
    "action_id": "యాక్షన్ ఐడి",
    "delete_user": "వినియోగదారుని తొలగించండి",
    "bit": "బిట్",
    "low": "తక్కువ",
    "high": "అధిక",
    "esg_scope_conversion": "ESG స్కోప్ మార్పిడి",
    "batch_production_logs": "బ్యాచ్ ఉత్పత్తి లాగ్‌లు",
    "esg_data_logs": "ESG డేటా లాగ్‌లు",
    "remove_from_dashboard": "డాష్‌బోర్డ్ నుండి తొలగించండి",
    "data_logs": "డేటా లాగ్‌లు",
    "select_device": "పరికరాన్ని ఎంచుకోండి",
    "fetch_connectivity_logs": "కనెక్టివిటీ లాగ్‌లను పొందండి",
    "hub_internet_connectivity_logs": "హబ్ ఇంటర్నెట్ కనెక్టివిటీ లాగ్స్",
    "modbus_gateways": "మోడ్‌బస్ గేట్‌వేలు",
    "device_switched_on": "పరికరం ఆన్ చేయబడింది",
    "device_switched_off": "పరికరం స్విచ్ ఆఫ్ చేయబడింది",
    "parameter_report": "పారామితి నివేదిక",
    "timeframed_specific_energy_consumption_report": "కాలపరిమితి నిర్దిష్ట శక్తి వినియోగ నివేదిక",
    "ebill_distribution_report": "ఇ-బిల్ పంపిణీ నివేదిక",
    "root_user_management": "రూట్ యూజర్ మేనేజ్‌మెంట్",
    "user_acces_management": "వినియోగదారు ప్రాప్యత నిర్వహణ",
    "inference": "అనుమితి",
    "turn_off": "ఆపివేయండి",
    "turn_on": "ఆన్ చేయండి",
    "diagram_pallette": "రేఖాచిత్రం పాలెట్",
    "add_component": "భాగాన్ని జోడించండి",
    "components": "భాగాలు",
    "add_text": "వచనాన్ని జోడించండి",
    "add_realtime_data": "రియల్ టైమ్ డేటాను జోడించండి",
    "shapes": "ఆకారాలు",
    "lines": "పంక్తులు",
    "text_realtime_component": "టెక్స్ట్ రియల్ టైమ్ కాంపోనెంట్",
    "shapes_pallette": "ఆకార పాలెట్",
    "lines_pallette": "పంక్తులు పాలెట్",
    "click_choose": "క్లిక్ చేసి, SLD కి జోడించడానికి ఆకృతులను ఎంచుకోండి",
    "choose_line": "SLD కి జోడించడానికి పంక్తిని ఎంచుకోండి",
    "choose_image": "చిత్రాన్ని ఎంచుకోండి",
    "upload_image_to_add": "SLD కి జోడించడానికి చిత్రాన్ని అప్‌లోడ్ చేయండి",
    "add_realtime_textdata": "SLD కి నిజ సమయం లేదా వచన డేటాను జోడించండి",
    "update_user": "వినియోగదారుని నవీకరించండి",
    "add_watcher": "వాచర్ జోడించండి",
    "premise_area": "ఆవరణ ప్రాంతం",
    "create_computed_param": "కంప్యూటెడ్ పారామ్ సృష్టించండి",
    "create_derived_param": "ఉత్పన్నమైన పారామ్ సృష్టించండి",
    "proceed_to_ship": "ఓడకు వెళ్లండి",
    "esg_data_collection": "ESG డేటా సేకరణ",
    "esg_scope_details": "ESG స్కోప్ వివరాలు",
    "select_custom_date_range": "అనుకూల తేదీ పరిధిని ఎంచుకోండి",
    "days_365": "365 రోజులు",
    "days_180": "180 రోజులు",
    "days_30": "30 రోజులు",
    "create_user_for_SSO": "SSO కోసం వినియోగదారుని సృష్టించండి",
    "add_widget": "విడ్జెట్ జోడించండి",
    "add_machine_status_rules": "యంత్ర స్థితి నియమాలను జోడించండి",
    "add_trigger": "ట్రిగ్గర్ జోడించండి",
    "add_logged_parameter": "లాగిన్ చేసిన పరామితిని జోడించండి",
    "edit_logged_parameter": "లాగిన్ చేసిన పరామితిని సవరించండి",
    "create_mannual_param": "మాన్యువల్ పారామ్ సృష్టించండి",
    "add_opc_param": "OPC పారామ్ జోడించండి",
    "create_write_block": "రైట్ బ్లాక్ సృష్టించండి",
    "all_parameters": "అన్ని పారామితులు",
    "edit_device": "పరికరాన్ని సవరించండి",
    "edit_modbus_device": "మోడ్‌బస్ పరికరాన్ని సవరించండి",
    "edit_mqtt_device": "MQTT పరికరాన్ని సవరించండి",
    "limit_entropy": "ఎంట్రోపీని పరిమితం చేయండి",
    "emails": "ఇమెయిళ్ళు",
    "power_factor": "పవర్ఫర్మ్టర్డ్",
    "tarrif_rate": "సుంకం రేటు",
    "apparent_power": "స్పష్టమైన శక్తి",
    "reactive_power": "రియాక్టివ్ పవర్",
    "active_power": "క్రియాశీల శక్తి",
    "energy_usage_view_page": "శక్తి వినియోగ వీక్షణ పేజీ",
    "switch_organisation": "స్విచ్ ఆర్గనైజేషన్",
    "wireless_creds": "వైర్‌లెస్ క్రెడిట్స్",
    "latest": "తాజాది",
    "value_is": "విలువ",
    "please_take_necessary_action": "దయచేసి అవసరమైన చర్య తీసుకోండి",
    "edit_role": "పాత్రను సవరించండి",
    "delete_role": "పాత్రను తొలగించండి",
    "today": "ఈ రోజు",
    "days_4": "4 రోజులు",
    "due": "గడువు",
    "move_to_done": "పూర్తయింది",
    "power_system_fault": "పవర్ సిస్టమ్ ఫాల్ట్ కౌంట్ (గత వారం)",
    "alarms": "అలారాలు",
    "electricity_consumption": "విద్యుత్ వినియోగం (తేదీ వరకు నెల)",
    "average_demand": "సగటు డిమాండ్",
    "water_use_intensity": "నీటి వినియోగ తీవ్రత (వుయి)",
    "average_water_cost": "సగటు నీటి వ్యయం (నెలవారీ)",
    "water_use_efficiency": "నీటి వినియోగ సామర్థ్యం (WUE)",
    "batch_logs": "బ్యాచ్ లాగ్‌లు",
    "edit_log": "లాగ్‌ను సవరించండి",
    "delete_log": "లాగ్‌ను తొలగించండి",
    "dates": "తేదీలు",
    "log_details": "లాగ్ వివరాలు",
    "view": "చూడండి",
    "scope1_emission": "స్కోప్ 1 ఉద్గారం",
    "scope2_emission": "స్కోప్ 2 ఉద్గారం",
    "lmtd": "Lmtd",
    "company_data": "కంపెనీ డేటా",
    "historian": "చరిత్రకారుడు",
    "from_optional": "నుండి (ఐచ్ఛికం)",
    "to_optional": "కు (ఐచ్ఛికం)",
    "trends": "పోకడలు",
    "generate_trend": "ధోరణిని ఉత్పత్తి చేస్తుంది",
    "by": "ద్వారా",
    "check_logs": "లాగ్‌లను తనిఖీ చేయండి",
    "workspaces": "వర్క్‌స్పేస్‌లు",
    "types_of_nodes_connected": "నోడ్ల రకాలు కనెక్ట్",
    "types_of_modbus_device_connected": "మోడ్‌బస్ పరికరాల రకాలు కనెక్ట్ చేయబడ్డాయి",
    "no_of_registers": "రిజిస్టర్లు లేవు",
    "write_register": "రిజిస్టర్ రాయండి",
    "setting_view": "సెట్టింగ్ వీక్షణ",
    "sign_up": "సైన్ అప్",
    "reset": "రీసెట్",
    "mobile_number": "మొబైల్ సంఖ్య",
    "nebeskie_labs": "నెబెస్కీ ల్యాబ్స్",
    "please_register_and_send_otp": "దయచేసి మీ రిజిస్టర్డ్ ఇమెయిల్ ఐడిని నమోదు చేసి, పంపండి OTP పై క్లిక్ చేయండి.",
    "reset_password": "పాస్వర్డ్ను రీసెట్ చేయండి",
    "back_to_login": "లాగిన్ అవ్వండి",
    "otp": "Otp",
    "send_otp": "OTP పంపండి",
    "org_alias": "ఆర్గ్ అలియాస్",
    "please_stand_by": "దయచేసి నిలబడండి",
    "total_usage_this_month": "ఈ నెలలో మొత్తం వాడకం",
    "total_usage_last_month": "గత నెలలో మొత్తం వినియోగం",
    "generate_report_manually": "నివేదికను మానవీయంగా రూపొందించండి",
    "energy_distribution_detailed_report": "శక్తి పంపిణీ వివరణాత్మక నివేదిక",
    "the_report_is_processing": "నివేదిక ప్రాసెసింగ్",
    "enter_active_energy_manually": "క్రియాశీల శక్తిని మానవీయంగా నమోదు చేయండి",
    "include_others": "ఇతరులను చేర్చండి",
    "bill_amount": "బిల్లు మొత్తం",
    "yes_delete_it": "అవును, దాన్ని తొలగించండి",
    "create_write_param": "వ్రాత పారామ్ సృష్టించండి",
    "report_list": "నివేదిక జాబితా",
    "plant_id": "ప్లాంట్ ఐడి",
    "plant_name": "మొక్క పేరు",
    "plant_address": "మొక్కల చిరునామా",
    "add_selected": "ఎంచుకున్నట్లు జోడించండి",
    "clear_selection": "స్పష్టమైన ఎంపిక",
    "real_time_value": "రియల్ టైమ్ విలువ",
    "default_status": "డిఫాల్ట్ స్థితి",
    "default_label": "డిఫాల్ట్ లేబుల్",
    "default_color_optional": "డిఫాల్ట్ కలర్ ఐచ్ఛికం",
    "rule_based_status": "నియమం ఆధారిత స్థితి",
    "show_label_only": "లేబుల్ మాత్రమే చూపించు",
    "show_unit": "యూనిట్ చూపించు",
    "status_rules": "స్థితి నియమాలు",
    "configure": "కాన్ఫిగర్",
    "monthly_usage": "నెలవారీ ఉపయోగం",
    "meter_consumption_kwh": "మీటర్ వినియోగం",
    "total_unit": "మొత్తం యూనిట్",
    "region": "ప్రాంతం",
    "average_energy_consumed": "సగటు శక్తి వినియోగించబడుతుంది",
    "average_production": "సగటు ఉత్పత్తి",
    "average_sec": "సగటు సెక",
    "total_production": "మొత్తం ఉత్పత్తి",
    "total_energy_consumed": "మొత్తం శక్తి వినియోగించబడుతుంది",
    "max_sec": "గరిష్టంగా సెక",
    "device_1": "పరికరం 1",
    "device_parameter_1": "పరికర పరామితి 1",
    "device_2": "పరికరం 2",
    "device_parameter_2": "పరికర పరామితి 2",
    "create_param": "పారామ్ సృష్టించండి",
    "update_tags": "ట్యాగ్‌లను నవీకరించండి",
    "remove_from_process": "ప్రక్రియ నుండి తొలగించండి",
    "machine_parameters": "యంత్ర పారామితులు",
    "energy": "శక్తి",
    "power": "శక్తి",
    "remove_from_featured": "ఫీచర్ నుండి తొలగించండి",
    "other_parameters": "ఇతర పారామితులు",
    "add_to_featured": "ఫీచర్ చేయడానికి జోడించండి",
    "logged_parameters": "లాగిన్ చేసిన పారామితులు",
    "tags": "టాగ్లు",
    "machine_status_rules": "యంత్ర స్థితి నియమాలు",
    "connected_communication_devices": "కనెక్ట్ చేయబడిన కమ్యూనికేషన్ పరికరాలు",
    "mqtt_devices": "MQTT పరికరాలు",
    "modbus_devices": "మోడ్‌బస్ పరికరాలు",
    "total_no_of_params": "మొత్తం పారామ్స్",
    "edge_devices": "ఎడ్జ్ పరికరాలు",
    "machines_configured": "యంత్రాలు కాన్ఫిగర్ చేయబడ్డాయి",
    "reports_configured": "నివేదికలు కాన్ఫిగర్ చేయబడ్డాయి",
    "views_configured": "వీక్షణలు కాన్ఫిగర్ చేయబడ్డాయి",
    "send_email_notification": "ఇమెయిల్ నోటిఫికేషన్ పంపండి",
    "enture_send_email_notification": "ఎంచుకున్న వినియోగదారు సమూహానికి ఇమెయిల్ నోటిఫికేషన్ పంపండి",
    "agree_and_send": "అంగీకరిస్తున్నారు & పంపండి",
    "ebill": "ఎబిల్",
    "monthly_trend": "నెలవారీ ధోరణి",
    "param_view": "పారాది వీక్షణ",
    "calories": "కేలరీలు",
    "param_count_view": "పారామ్ కౌంట్ వ్యూ",
    "created": "సృష్టించబడింది",
    "api_data_count_view": "API డేటా కౌంట్ వీక్షణ",
    "diesel_emissions": "డీజిల్ ఉద్గారాలు",
    "eb_emissions": "EB ఉద్గారాలు",
    "emissions_kg_co2": "ఉద్గారాలు KG-CO2",
    "kpis": "Kpis",
    "jan_23": "జనవరి -23",
    "feb_23": "ఫిబ్రవరి -23",
    "mar_23": "మార్చి -23",
    "apr_23": "ఏప్రిల్ -23",
    "may_23": "మే -23",
    "jun_23": "జూన్ -23",
    "lpg_emissions": "LPG ఉద్గారాలు",
    "petrol_emissions": "పెట్రోల్ ఉద్గారాలు",
    "bullet_gauge": "బుల్లెట్ గేజ్",
    "equipment": "పరికరాలు",
    "enter_no_of_machines": "యంత్రాలను నమోదు చేయండి",
    "shift": "షిఫ్ట్",
    "upload": "అప్‌లోడ్",
    "image_upload": "చిత్ర అప్‌లోడ్",
    "derived_parameter": "ఉత్పన్నమైన పరామితి",
    "value_type": "విలువ_టైప్",
    "enable_entropy": "ఎంట్రోపీని ప్రారంభించండి",
    "remove_decimal": "దశాంశాన్ని తొలగించండి",
    "limiter_entropy": "పరిమితి ఎంట్రోపీ",
    "show_sku_id": "SKU ID ని చూపించు",
    "production_start_date": "ఉత్పత్తి ప్రారంభ తేదీ (ఐచ్ఛికం)",
    "production_start_time": "ఉత్పత్తి ప్రారంభ సమయం (ఐచ్ఛికం)",
    "time": "సమయం",
    "temperature_c": "ఉష్ణోగ్రత (సి)",
    "set_temperature_c": "సెట్ ఉష్ణోగ్రత (సి)",
    "no_of_people": "ప్రజలు లేరు",
    "co2_level": "CO2 స్థాయి",
    "aqi_optional": "AQI ఐచ్ఛికం",
    "delete_sheets": "షీట్లను తొలగించండి",
    "root_login": "రూట్ లాగిన్",
    "uam_login": "UAM లాగిన్",
    "login": "లాగిన్",
    "add_oem_logo": "OEM లోగో జోడించండి",
    "devices": "పరికరాలు",
    "derived_data_units": "ఉత్పన్నమైన/డేటా యూనిట్లు",
    "roles": "పాత్రలు",
    "engineering": "ఇంజనీరింగ్",
    "control_panel": "నియంత్రణ ప్యానెల్",
    "views": "వీక్షణలు",
    "reports_analysis": "నివేదికలు & విశ్లేషణ",
    "actions_triggers": "చర్యలు & ట్రిగ్గర్‌లు",
    "modules": "గుణకాలు",
    "processes": "ప్రక్రియలు",
    "air_quality": "గాలి నాణ్యత",
    "manufacturing": "తయారీ",
    "esg": "Esg",
    "task_managment": "పని నిర్వహణ",
    "sustainability": "సుస్థిరత",
    "groups": "గుంపులు",
    "log_book": "లాగ్ బుక్",
    "monitor": "మానిటర్",
    "enture_settings": "సెట్టింగులను ఆకర్షించండి",
    "configuration": "కాన్ఫిగరేషన్",
    "bar": "బార్",
    "line": "లైన్",
    "s7_device": "S7 పరికరం",
    "rack": "రాక్",
    "slot": "స్లాట్",
    "db_number": "DB సంఖ్య",
    "byte_offset": "బైట్ ఆఫ్‌సెట్",
    "bit_offset": "బిట్ ఆఫ్‌సెట్",
    "data_length": "డేటా పొడవు",
    "val_type": "విలువ రకం",
    "create_s7_device": "S7 పరికరాన్ని జోడించండి",
    "scope_label": "స్కోప్స్",
    "client_safety": "క్లయింట్ భద్రత",
    "edit": "సవరణ",
    "delete": "తొలగించు",
    "choose_param_to_monitor": "పర్యవేక్షించడానికి పారామ్ ఎంచుకోండి",
    "rules": "నియమాలు",
    "add_to_row": "అడ్డు వరుసకు జోడించండి",
    "add_usage": "వాడకాన్ని జోడించండి",
    "notifications": "నోటిఫికేషన్‌లు",
    "organisation_details": "సంస్థ వివరాలు",
    "profile": "ప్రొఫైల్",
    "user_access": "వినియోగదారు ప్రాప్యత",
    "admin": "అడ్మిన్",
    "shifts": "షిఫ్టులు",
    "add_pie_TD_widget": "పై టిడి విడ్జెట్ జోడించండి",
    "edit_pie_TD_widget": "పై టిడి విడ్జెట్‌ను సవరించండి",
    "legend": "లెజెండ్",
    "pie": "పై",
    "edit_bar_trend_widget": "బార్ ట్రెండ్ విడ్జెట్‌ను సవరించండి",
    "add_bar_trend_widget": "బార్ ట్రెండ్ విడ్జెట్ జోడించండి",
    "edit_horizantal_bar_trend_widget": "క్షితిజ సమాంతర బార్ ధోరణి విడ్జెట్‌ను సవరించండి",
    "add_horizantal_bar_trend_widget": "క్షితిజ సమాంతర బార్ ట్రెండ్ విడ్జెట్ జోడించండి",
    "too_long": "చాలా పొడవుగా",
    "trigger_logs": "ట్రిగ్గర్ లాగ్‌లు",
    "create_trigger": "ట్రిగ్గర్ సృష్టించండి",
    "right_click_to_configure": "కాన్ఫిగర్ చేయడానికి కుడి క్లిక్",
    "width": "వెడల్పు",
    "height": "ఎత్తు",
    "hide_value": "విలువను దాచండి",
    "edit_machine": "యంత్రాన్ని సవరించండి",
    "wait_time": "సమయం వేచి ఉండండి",
    "production_date": "ఉత్పత్తి తేదీ",
    "connectivity_status": "కనెక్టివిటీ స్థితి",
    "production_plans": "ఉత్పత్తి ప్రణాళికలు",
    "production_pending": "ఉత్పత్తి పెండింగ్‌లో ఉంది",
    "pending_shipment": "రవాణా పెండింగ్‌లో ఉంది",
    "trace_logs": "ట్రేస్ లాగ్స్",
    "movement_events": "ఉద్యమ సంఘటనలు",
    "kgco2": "KGCO2",
    "entropy_threshold": "ఎంట్రోపీ థ్రెషోల్డ్",
    "threshold_value": "ప్రవేశ విలువ",
    "pending_dispatch": "పెండింగ్ డిస్పాచ్",
    "dispatched": "పంపబడింది",
    "dispatch_details": "వివరాలను పంపించండి",
    "dispatch": "పంపండి",
    "raw_materials": "ముడి పదార్థాలు",
    "pour_qty": "పరిమాణాన్ని పోయాలి",
    "summary": "సారాంశం",
    "optional": "ఐచ్ఛికం",
    "param_mqtt_map": "పారామ్ MQTT మ్యాప్",
    "machine_status_mqtt_map": "యంత్ర స్థితి MQTT మ్యాప్",
    "edit_trigger_count_table_widget": "ట్రిగ్గర్ కౌంట్ టేబుల్ విడ్జెట్‌ను సవరించండి",
    "add_trigger_count_table_widget": "ట్రిగ్గర్ కౌంట్ టేబుల్ విడ్జెట్ జోడించండి",
    "count": "లెక్కించండి",
    "real_time_consumption": "నిజ సమయ వినియోగం",
    "search_view": "శోధన వీక్షణ",
    "multiple_entries": "బహుళ ఎంట్రీలు",
    "combined_parameter": "కంబైన్డ్ పరామితి",
    "background_color": "నేపథ్య రంగు",
    "resize": "పరిమాణాన్ని మార్చండి",
    "remove": "తొలగించండి",
    "select_views": "వీక్షణలను ఎంచుకోండి",
    "difference": "తేడా",
    "sum": "మొత్తం",
    "set_param_type_for_machine": "యంత్రం కోసం పారామ్ రకాన్ని సెట్ చేయండి",
    "y1axis": "Y1 అక్షం",
    "y2axis": "Y2 అక్షం",
    "temperature_parameters": "ఉష్ణోగ్రత పారామితులు",
    "area_name": "ప్రాంత పేరు",
    "maintenance_due_on": "నిర్వహణ కారణంగా",
    "days_left": "రోజులు మిగిలి ఉన్నాయి",
    "enture_insights": "వెంచర్ అంతర్దృష్టులు",
    "reports": "నివేదికలు",
    "till_date_pie_chart": "తేదీ వరకు పై చార్ట్",
    "till_date_bar_chart": "డేట్ బార్ చార్ట్ వరకు",
    "tod_billing_widget": "టాడ్ బిల్లింగ్ విడ్జెట్",
    "bar_trend_widget": "బార్ ట్రెండ్ విడ్జెట్",
    "target_based_bar_trend_widget": "టార్గెట్ బేస్డ్ బార్ ట్రెండ్ విడ్జెట్",
    "gauge_widget": "గేజ్ విడ్జెట్",
    "consumption_pie_widget": "వినియోగ పై విడ్జెట్",
    "consumption_gauge_widget": "వినియోగ గేజ్ విడ్జెట్",
    "angular_gauge_widget": "కోణీయ గేజ్ విడ్జెట్",
    "table_widget": "టేబుల్ విడ్జెట్",
    "banner_widget": "బ్యానర్ విడ్జెట్",
    "param_equation_banner_widget": "పారాకార్ ఈక్వేషన్ బ్యానర్ విడ్జెట్",
    "shift_based_usage_table": "షిఫ్ట్ ఆధారిత వినియోగ పట్టిక",
    "bitwise_word_widget": "బిట్‌వైస్ వర్డ్ విడ్జెట్",
    "dynamic_log_widget": "డైనమిక్ లాగ్ విడ్జెట్",
    "machine_status_timeline_widget": "యంత్ర స్థితి టైమ్‌లైన్ విడ్జెట్",
    "process_or_machine_status_widget": "ప్రాసెస్/మెషిన్ స్థితి విడ్జెట్",
    "parameter_status_based_usage_status": "పారామిటర్‌స్టాటస్ బేస్డ్ విడ్జెట్",
    "parameter_insights_widget": "పారామితి అంతర్దృష్టులు విడ్జెట్",
    "heat_map": "హీట్ మ్యాప్",
    "usage_score_card_widget": "వినియోగ స్కోరు కార్డ్ విడ్జెట్",
    "busbar_live_monitoring": "బస్‌బార్ లైవ్ పర్యవేక్షణ",
    "bullet_or_angular_guage_widget": "బుల్లెట్/కోణీయ గేజ్ విడ్జెట్",
    "dual_axis_bar_trend_widget": "డ్యూయల్ యాక్సిస్ బార్ ట్రెండ్ విడ్జెట్",
    "trigger_count_table_widget": "ట్రిగ్గర్ కౌంట్ టేబుల్ విడ్జెట్",
    "bar_trend_td_widget": "బార్ ట్రెండ్ టిడి విడ్జెట్",
    "horizantal_bar_trend_widget": "క్షితిజ సమాంతర బార్ ట్రెండ్ విడ్జెట్",
    "choose_from_templates": "Select_from_templates",
    "shift_timeframed_trends": "షిఫ్ట్ బేస్డ్ టైమ్‌ఫ్రేమ్డ్ ట్రెండ్స్",
    "candlestick_trends": "క్యాండిల్‌స్టిక్ ట్రెండ్‌లు",
    "free_ram": "Free RAM",
    "item": "Item",
    "item_code": "Item Code",
    "department": "Department",
    "departments": "Departments",
    "issue_vouchers": "Issue Vouchers",
    "issue_voucher": "Issue Voucher",
    "to_be_approved": "To Be Approved",
    "to_be_issued": "To Be Issued",
    "intended": "Intended",
    "approved": "Approved",
    "received": "Received",
    "receiver": "Receiver",
    "material": "material",
    "material_group": "material group",
    "material_desc": "Material Description",
    "uom": "Unit Of Measurement",
    "create_item_code": "Create Item Code",
    "create_department": "Create Department",
    "users": "Users",
    "items": "Items",
    "users_in_department": "Users in Department",
    "authorisers_in_department": "Authorisers in Department",
    "issuers_in_department": "Issuers in Department",
    "item_issuer_list": "Item Issuer List",
    "add_item_issuer": "Add Item Issuer",
    "store": "Store",
    "create_issue_voucher": "Create Issue Voucher",
    "voucher_requests": "Voucher Requests",
    "intended_qty": "Intended Qty",
    "issued_qty": "Issued Qty",
    "issue_items": "Issue Items",
    "voucher_receipt": "Voucher Receipt",
    "add_stock": "Add Stock",
    "attached_targets": "Attached Targets",
    "attach_target": "Attach Target",
    "add_target_to_parameter": "Add Target to Parameter",
    "empty": "Empty",
    "add_day_wise_target": "Add Day wise Target",
    "day_wise_targets": "Day Wise Targets",
    "fetch_targets": "Fetch Targets",

    "day_wise_machine_status_widget": "రోజుకు యంత్ర స్థితి విజెట్",
    "add_day_wise_machine_status_widget": "రోజుకు యంత్ర స్థితి విజెట్ జోడించండి",
    "edit_day_wise_machine_status_widget": "రోజుకు యంత్ర స్థితి విజెట్ సవరించండి",
    "add_machines": "యంత్రాలను జోడించండి",
    "added_machines": "జోడించిన యంత్రాలు",
    "consumption_start_time":"Consumption Start time",



}
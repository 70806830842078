<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="widgetForm" v-model="widgetValid">
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">    
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_tod_billing_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().create_tod_billing_widget}}</v-card-title> 
            <v-row>
                <v-col align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                 <v-col>
                    <v-select
                        v-if="!loading"
                        v-model="widgetForm.height"
                        :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :rules="[rules.required]"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col align-self="center" >
                    <v-select
                        v-if="!loading"
                        v-model="widgetForm.background_color"
                        :items="$store.getters.getBackgroundColorOptions()"
                        :label="$store.getters.getTextMap().background_color"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        clearable
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    >
                        <template v-slot:selection="{ item }">
                            <div :style="{'background-color':item.value}">{{ item.label }}</div>
                        </template>
                        <template v-slot:item="{ item }">
                            <div :style="{'background-color':item.value}">{{ item.label }}</div>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="edit" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        v-if="!loading"
                        v-model="widgetForm.seq"
                        :counter="3"
                        :label="$store.getters.getTextMap().sequence"
                        dense
                        outlined
                        ></v-text-field>
                </v-col>
                <v-col v-if="!disableTimeframe" align-self="center">
                    <v-select  
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().timeFrame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"   
                    ></v-select>  
                </v-col>
            </v-row>
        </v-card>
    </v-form>
    <v-form ref="todBillingForm" v-model="valid">
        <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="mb-2">
            <v-row>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-card-title class="sub-heading">{{$store.getters.getTextMap().choose_machine}}</v-card-title>
                    <v-spacer/>
                    <v-switch
                        small
                        inset
                        style="transform: scale(0.8);"
                        color="success"
                        v-model="chooseFromMachineGroup"
                        :label="$store.getters.getTextMap().choose_from_machine_groups"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col v-if="chooseFromMachineGroup">
                    <v-autocomplete  
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="machineGroup"
                        :items="machineGroupsOptions"
                        :label="$store.getters.getTextMap().machine_groups"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"   
                    ></v-autocomplete>
                </v-col>
                <v-col v-else>
                    <WorkspaceMachineForm v-on:value="updateMachine" />
                </v-col>
            </v-row>
        </v-card>
    </v-form>
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
        <v-row v-if="!loading && machines.length>0" no-gutters>
            <v-col >
                <v-simple-table maxHeight="200px" :style="{background: $store.getters.getColorPalette().background2ColorCode}">                        
                    <template v-slot:default>
                        <thead >
                            <tr>
                                <th class="text-left"><strong>{{  $store.getters.getTextMap().machine_id }}</strong></th>
                                <th class="text-left"><strong>{{  $store.getters.getTextMap().name }}</strong></th>
                                <th class="text-left" ><strong>{{$store.getters.getTextMap().action}}</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="item.label" v-for="(item,index) in machines">
                                <td>{{ item.machine_id}}</td>
                                <td>{{ item.machine_name}}</td>
                                <td >
                                    <DeleteConfirmation v-on:confirm="deleteMachineFromList(item,index)"  title="Delete Confirmation"  description="Are you sure you want to delete this Machine?">
                                        <v-icon small color="red">mdi-delete</v-icon>
                                    </DeleteConfirmation>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card>
    <v-row no-gutters>
        <v-spacer/>
        <v-col cols="auto" class="d-flex mt-2" align="right">
            <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'AddTODBillingWidget',
    props:['widget','seq','disableTimeframe'],
    components:{
        InfoAlert,
        WorkspaceMachineForm,
        DeleteConfirmation
    },
    mounted(){
        this.init()
    },
    data(){
        return {
            loading:false,
            valid:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            edit:false,
            api:'createWidgetForView',
            timeframe:'mtd',
            chooseFromMachineGroup:false,
            machineGroup:null,
            form:{},
            machines:[],
            widgetForm:{
                meta:{}
            },
            machineOptions:[],
            timeframeOptions:[
                {label:'last 7 days', value:'7'},
                {label:'last 30 days', value:'30'},
                {label:'last 60 days', value:'60'},
                {label:'last 90 days', value:'90'},
                {label:'last 180 days', value:'180'},
                {label:'last 365 days', value:'365'},
                {label:'Yesterday', value:'yesterday'},
                {label:'MTD', value:'mtd'},
                {label:'YTD', value:'ytd'},
            ],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
            numberRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
            ]
        }
    },
    computed:{
        machineGroupsOptions(){
            let op=[]
            let g=this.$store.state.machineGroups
            for(let i of g){
                op.push( {value:i.machine_group_id, label: i.name })
            }
          return op
        },
    },
    methods:{
        init(){
            if(this.widget && this.widget.widget_id && this.widget.view_id){
                this.edit=true
                this.widgetForm=Object.assign({},this.widget)
                this.api='updateWidget'
                if(!(this.widgetForm.meta)){
                    this.widgetForm.meta={
                        meta:{}
                    }
                    this.form={}
                    this.machines=[]
                    this.timeframe='mtd'
                }else{
                    this.form=this.widgetForm.meta
                    this.timeframe=this.form.timeframe
                    this.machines=this.form.machines
                }
            }else{
                if(this.widget.view_id){
                    this.api='createWidgetForView'
                    this.widgetForm=Object.assign({},this.widget)
                    this.widgetForm.meta={}
                    this.widgetForm.seq=this.seq?this.seq:0
                    this.form={}
                    this.timeframe='mtd'
                    this.machines=[]
                }
            }
        },
        updateMachine(payload){
            if(payload && payload.machine && payload.machine.machine_id){
                this.loading=true
                let temp=this.form && this.form.machines && this.form.machines.length>0?this.form.machines:[]
                this.form.machines=[]
                if(!temp.some(obj => obj.machine_id === payload.machine.machine_id)){
                    temp.push({"machine_name":payload.machine.machine_name?payload.machine.machine_name:payload.machine.machine_id,"machine_id":payload.machine.machine_id})
                }
                this.form.machines=temp
                this.machines=temp
            }
            this.loading=false
        },
        addMachinesFromMachineGroup(){
            if(this.machineGroup){
                this.loading=true
                axios.post(this.$store.state.api+'getTuplesByMachineGroup',{"machine_group_id":this.machineGroup},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        let temp=response.data.data
                        let machineList=this.form && this.form.machines && this.form.machines.length>0?this.form.machines:[]
                        this.form.machines=[]
                        for(let i of temp){
                            if(!machineList.some(obj => obj.machine_id === i.machine_id)){
                                machineList.push({"machine_name":i.label,"machine_id":i.machine_id})
                            }
                        }
                        this.form.machines=machineList
                        this.machines=machineList
                        this.machineGroup=null
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        deleteMachineFromList(item){
            this.form.machines=this.form.machines.filter(x=>x.machine_id!=item.machine_id)
            this.machines=this.form.machines
        },
        onReset(){
            this.machines=[]
            this.machineGroup=null
            this.form={}
            this.widgetForm={meta:{}}
            this.$refs.widgetForm.reset()
            this.$refs.todBillingForm.reset()
            this.loading=false
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            this.$refs.todBillingForm.validate()
            if(this.widgetValid && this.valid){
                this.loading=true
                this.form['timeframe']=this.timeframe
                this.widgetForm.meta=this.form
                axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                }else{
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading=false
                }}).catch(err=>{
                    console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onClose(){
            this.onReset()
        },
    },
    watch:{
        widget(){
            this.init()
        },
        machineGroup(){
            this.addMachinesFromMachineGroup()
        }
    }
}
</script>
<template>
  <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}" >  
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
      <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-list-item @click="update=true">
            <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteWidget">
            <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
            <v-list-item-icon>
              
              <v-icon color="red" small>mdi-trash-can</v-icon>
    
            </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
          <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{widget.label}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <AddBulletAngularGaugeWidget :widget="widget" :disableTimeframe="disableTimeframe" v-on:close="update=false" v-on:success="$emit('update')" />
      </v-card>            <v-list-item-title><strong>{{$store.getters.getTextMap().bullet_gauge}}</strong></v-list-item-title>
 </v-dialog>
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
      <div v-if="widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'175px',}:{}]" ></div>
      <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
        <v-icon small>mdi-hail</v-icon>
        <h5>            <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
</h5>
      </div>
  </v-container>
</template>

<script>
// import {io} from 'socket.io-client'
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddBulletAngularGaugeWidget from '@/components/crud_components/widgets/AddBulletAngularGaugeWidget'
import ColorUtility from '@/utillities/ColorUtility'
export default {
  name:'BulletAngularGaugeWidget',
  props:['widget','filter','edit','disableTimeframe','timeframe'],
  components:{
      AddBulletAngularGaugeWidget,
      InfoAlert,
      // DeleteConfirmation
  },
  data(){
      return {
         showDialog:false,
         stream:null,
         val:null,
         colorCode:ColorUtility.colorTypesCodeArray,
          x:0,
          y:0,
          showMenu:false,
          loading:false,
          indicatorColorDecreasingActive:'',
          indicatorColorIncreasingActive:'',
          indicatorColorDecreasingApparent:'',
          indicatorColorIncreasingApparent:'',
          indicatorColorDecreasingPF:'',
          indicatorColorIncreasingPF:'',
          y_axis_active:[],
          y_axis_apparent:[],
          y_axis_pf:[],
          usage:0,
          avg:0,
          info:'',
          showDismissibleAlert:false,
          update:false,
          data_variable:[],
          detailsActive:{},
          detailsApparent:{},
          detailsPF:{},
          graphDataActive:{},
          graphDataApparent:{},
          graphDataPF:{},
          graphData:[],
          gauge_length: 0,
          layout : {
            autosize: true,
            margin: { t: 30, r:20, l: 0, b: 10 },
                  font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                  plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  }
      }
  },
  mounted(){
      this.init()
  },
computed:{
  getLayoutDimensions(){
        if(this.widget.width===12){
          return {'height':300,'width':1200}
        }
        if(this.widget.width===6){
          return {'height':250,'width':550}
        }
        if(this.widget.width===4){
          return {'height':190,'width':380}
        }
        if(this.widget.width===3){
          return {'height':190,'width':285}
        }
        return 0
      },
},
  methods:{
      show (e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
    },
    init(){
      if(this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.data[0]){
          this.data_variable = []
          this.gauge_length = 0
          for(let i of this.widget.meta.data[0].indicator){
            if(i.gauge_label){
              this.gauge_length = this.gauge_length +1
            }
            
          }
          let y_axis = this.yAxisDimension(this.gauge_length)
          let count = 0 
          for(let i of this.widget.meta.data[0].indicator){
            this.data_variable[i.gauge_label]={
              type: "indicator",
              mode: "number+gauge+delta",
              gauge: { 
                shape: i.gauge_type,
                axis:{
                  tickcolor:this.$store.getters.getColorPalette().accentCode
                },
                bar:{
                  color:this.$store.getters.getColorPalette().bulletGaugeBar,
                },
                // borderwidth:2,
                bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                bordercolor:this.$store.getters.getColorPalette().accentCode,
              },
              delta: { 
                reference: i.reference ,
                decreasing:{
                  color:null
                },
                increasing:{
                  color:null
                },
              },
              value: 0,
              domain: { x: [0.25, 1], y: y_axis[count] },
              title: { align:('left'),text: i.gauge_label }
            }
            count = count+1
          }          
          this.getData()
      }
    },
      createChart(){
          this.layout['title']= this.widget.label
          if(this.widget.meta && this.widget.meta.legend){
            this.layout['showlegend']=true
          }
          let dataValue = []
          let count = 0 
          // let y_axis = this.yAxisDimension(this.gauge_length)
          for(let i of this.graphData){
            if(this.data_variable[i.title]){
            this.data_variable[i.title]['delta']['decreasing']['color']=i['decreasing_indicator']
            this.data_variable[i.title]['delta']['increasing']['color']=i['increasing_indicator']
            
            // this.data_variable[i.title]['domain']['y']=y_axis[count]
            if(i['type']==='pf'){
              this.data_variable[i.title]['value']=i['value_avg']
            }else{
              this.data_variable[i.title]['value']=i['value_usage']
            }
            dataValue.push(this.data_variable[i.title])
            count = count+1
          }
          }
          Plotly.newPlot(this.$refs.vis, dataValue, this.layout, {displaylogo: false});
      },
      colorIndicator(color){
          if(color==='rrhv'){
            return {'indicatorColorIncreasing':'#FF4136','indicatorColorDecreasing':'#3D9970'}
          }else{
            return {'indicatorColorIncreasing':'#3D9970','indicatorColorDecreasing':'#FF4136'}
          }
      },
      yAxisDimension(no_of_gauge){
        switch(no_of_gauge){
          case 1:
            return [[0.08, 0.88]]
          case 2:
            return [[0.08, 0.25],[0.7, 0.88]]
          case 3:
            return [[0.08, 0.25],[0.4, 0.57],[0.7, 0.88]]
        }
      },
      async getData(){
        // if(this.widget.height==='fixed'){
          
        // }
          this.layout.width = this.getLayoutDimensions['width']
          this.layout.height = this.getLayoutDimensions['height']
          this.layout.colorway = this.colorCode
          let from_date=null
          if(this.timeframe && this.disableTimeframe){
          switch (this.timeframe) {
                
                case '7':
                  from_date = moment().subtract(7,'days').format('YYYYMMDD');
                  break;
                  
                case '30':
                  from_date = moment().subtract(30,'days').format('YYYYMMDD');
                  break;
                  
                  case '60':
                    from_date = moment().subtract(60,'days').format('YYYYMMDD');
                    break;
                    
                    case '90':
                      from_date = moment().subtract(90,'days').format('YYYYMMDD');
                      break;
                      
                      case '180':
                        from_date = moment().subtract(180,'days').format('YYYYMMDD');
                        break;
                        
                        case '365':
                          from_date = moment().subtract(365,'days').format('YYYYMMDD');
                      break;
              
                    case 'yesterday':
                    from_date = moment().subtract(1,'days').format('YYYYMMDD');
                      break;
                    case 'ytd':
                      from_date = moment().startOf('year').format('YYYYMMDD');
                      break;

                    default:
                      from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                      break;
                  }
        }else{
          from_date=moment().subtract(1,'days')
          if(this.widget.meta && this.widget.meta.time_frame=='ytd'){
              from_date=from_date.startOf('year').format('YYYYMMDD')
          }else{
              from_date=from_date.startOf('month').format('YYYYMMDD')
          }
        }
          if(this.widget && this.widget.meta && this.widget.meta.data){
            // let y_axis = this.yAxisDimension(this.widget.meta.data[0].indicator.length)
            for(let i of this.widget.meta.data[0].indicator){
                let indicator_color =this.colorIndicator(i.indicator_color)
                this.indicatorColorIncreasing = indicator_color['indicatorColorIncreasing']
                this.indicatorColorDecreasing = indicator_color['indicatorColorDecreasing']

                let payload={
                    machines:this.widget.meta.data[0].machine,
                    from_date:from_date,
                    tag_type:i.tag_type
                }

                try{
                  let temp_response= await axios.post(this.$store.state.api+'getUsageByTagType',payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                if(temp_response.data.status==='success'){
                    this.detailsPF = {}
                    this.detailsPF={'title':i.gauge_label,'reference':i.reference,'decreasing_indicator':this.indicatorColorDecreasing,'increasing_indicator':this.indicatorColorIncreasing,'value_usage':Number(temp_response.data.data['usage']),'value_avg':Number(temp_response.data.data['avg']),'type':i.tag_type}
                    this.graphData.push(this.detailsPF)
                }else{
                    this.info=temp_response.data.msg
                    this.showDismissibleAlert=true
                }
              }catch(err){
                console.error(err)
              }
                    this.loading=false
                // })
                // .catch(err=>{
                //     console.error(err)
                //     this.loading=false});
          }
          this.createChart()
          }
      },
      deleteWidget(){
          this.loading=true
          axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
              Authorization: 'Bearer '+ this.$store.state.jwt
              }}).then(response=>{
              if(response.data.status==='success'){
                  this.dialog=false
                      this.loading=false
                      this.$emit('update')
              }
              })
              .catch(err=>{
                  console.error(err)
              this.loading=false});
      },
      cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }  
  },
  watch:{
      widget:{
          handler(){
              this.init()
          },
          deep:true
      },
      timeframe:{
          handler(){
                this.init()
            },
            deep:true
        },
  }
}
</script>
<style scoped>
.limit_height {
  max-height:400px;
}
</style>
<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row>
          <v-col>
            <span :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">{{widget.widget_id?'Edit':'Add'}} Gauge Widget</span>
          </v-col>
        </v-row> -->
        <v-card  :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat>
            <!-- <v-card-title class="headline">
          {{widget.widget_id?'Edit':'Add'}} Gauge
        </v-card-title> -->
        
        <v-card-title class="sub-heading">{{widget.widget_id?'Edit':'Add'}} {{$store.getters.getTextMap().gauge_widget}}</v-card-title>
        <v-card-text>
          <v-form
          ref="panelForm"
          v-model="valid"           
          >
          <v-row>
          <v-col align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field v-if="!loading" v-model="widgetForm.label" :counter="30" dense outlined :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center">
                    <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.height"
                        :items="heightOptions"
                        :label="$store.getters.getTextMap().widget_height"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                <v-col v-if="edit" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                            ></v-text-field>
                </v-col>
        </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
        <v-row >
          <v-col>
              <v-text-field
              v-if="!loading"
              v-model="form.name"
              :counter="30"
              :rules="nameRules"
              :label="$store.getters.getTextMap().param_label"
              required
              dense 
              outlined
              ></v-text-field>
              <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="text"
              ></v-skeleton-loader>
          </v-col>
                  <v-col >
                    <v-select
                   :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                  </v-col>
                  
                    <v-col >
                                <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    dense
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col>
                        <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="form.param"
                            :items="paramOptions"
                            :label="$store.getters.getTextMap().param"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        ></v-select>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                    v-if="!loading"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                    <template align-self="center" v-slot:activator="{ on, attrs }">
                      
                        <v-text-field 
                        class="ma-1"
                            v-model="form.pointer_color"
                            :label="$store.getters.getTextMap().pointer_color_optional"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-text-field>
                    </template>
                 <v-color-picker v-model="pointer_color" dot-size="25" swatches-max-height="200"></v-color-picker>
                </v-menu>
              </v-col>
                </v-row>
            
                <v-row v-if="!loading && form.color_meta && form.color_meta.length>0 ">
                  <v-col cols="12">
                    <v-simple-table height="150px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().from_value}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().to_value}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().color}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :key="item.min" v-for="item in form.color_meta"
        >
          <td>{{ item.min }}</td>
          <td>{{ item.max }}</td>
          <td><span :style="'background-color:'+item.strokeStyle">{{item.strokeStyle}}</span></td>
          <td>
    <DeleteConfirmation v-on:confirm="deleteColorFromList(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Gauge widget?">
     <v-icon small color="red">mdi-delete</v-icon>
     </DeleteConfirmation>
         </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table >
                  </v-col>
                </v-row>
                </v-form>
                <v-form
            ref="colorForm"
            v-model="colorValid"           
        >
                <v-row>
                  <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                    v-model="min"
                    :counter="8"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().from"
                    dense
                    outlined
                    required>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                    v-model="max"
                    :counter="8"
                    dense
                    outlined
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().to"
                    required>
                    </v-text-field>
                  </v-col>
        <v-col>
            <v-row>
                <v-col v-if="showCustomColors===true">
                    <v-menu
                        v-if="!loading"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                    <template align-self="center" v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-model="col"
                            :label="$store.getters.getTextMap().custom_color"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="objectRules"
                        ></v-text-field>
                    </template>
                 <v-color-picker v-model="color" dot-size="25" swatches-max-height="200"></v-color-picker>
                </v-menu>
            </v-col>
                <v-col v-else>
                <v-select
              :dark="$store.getters.getColorPalette().isDark"       :color="$store.getters.getColorPalette().inputtextColor"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-if="!loading"
                    v-model="color"
                    :items="colorOptions"
                    :label="$store.getters.getTextMap().color"
                    item-text="label"
                    dense
                    outlined
                    item-value="value"
                    :rules="objectRules"
                ></v-select>
            </v-col>
            <v-col>
            <v-checkbox
         :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="selected"
                    :label="$store.getters.getTextMap().custom_color"
                    value="CustomColorCheck"
                    dense
                    outlined
                    ></v-checkbox>
            </v-col>
        </v-row>
    </v-col>
                  <v-col>
                    <v-btn
          v-if="!loading"
             :color="$store.getters.getColorPalette().accentCode"
            text
            small
            outlined
            @click="addColorPoint"
          >
           {{ $store.getters.getTextMap().add_color_break_point}}
          </v-btn>
                  </v-col>
                </v-row>
            </v-form>
                </v-card-text>
        <!-- <v-card-actions>
          <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
        </v-card-actions> -->
        </v-card>
        <v-row>
          <v-spacer/>
          <v-col cols="auto" class="d-flex mt-2" align="right" v-if="!loading" align-self="center">
          <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            small
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().submit}}
          </v-btn>
          <v-btn
          v-if="!loading"
           class="ml-2"
          small
          :color="$store.getters.getColorPalette().cancelbtnColor"
            @click="close"
          >
          {{ $store.getters.getTextMap().cancel}}
          </v-btn>
          </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import DeleteConfirmation from '@/components/DeleteConfirmation'

export default {
    name:'AddGaugeWidget',
    props:['widget','seq'],
    
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
      if(this.widget && this.widget.widget_id && this.widget.view_id){
        this.edit=true
        this.widgetForm=Object.assign({},this.widget) 
        this.api='updateWidget'       
                    if(!(this.widgetForm.meta)){
                this.widgetForm.meta={data:[]}
                this.tableData=[]
            }else{
                this.form.name=this.widgetForm.meta.data[0].label
                this.form.param=this.widgetForm.meta.data[0].params
                this.form.color_meta=this.widgetForm.meta.data[0].color_meta
                this.form.pointer_color=this.widgetForm.meta.data[0].pointer_color
                this.pointer_color={hexa:this.form.pointer_color}
                this.tableData=this.widgetForm.meta.data
            }
        let g=null
              g=this.$store.getters.getParameterById(this.form.param)
              this.dev=this.$store.getters.getDeviceById(g.device_id)
              this.workspace=this.$store.getters.getWorkspaceById(this.dev.workspace_id).name
        this.api='updateWidget'

      }else{
            if(this.widget.view_id){
              this.api='createWidgetForView'
              this.widgetForm=Object.assign({},this.widget)
              this.widgetForm.meta={data:[]}
              this.widgetForm.seq=this.seq?this.seq:0
                this.tableData=[]
            }
        }
      // else{
      //   console.log('add gauge')
      //   this.api='addGaugeToDashboard'
      // }
    },
    data(){
        return {
          showDialog:false,
          edit:false,
          // api:'addGaugeToDashboard',
          color:null,
          pointer_color:null,
             showDismissibleAlert:false,
            info:'',
            menu:null,
            menu2:null,
            loading:false,
            workspace:null,
            dev:null,
            valid:false,
            colorValid:false,
            // param_type:null,
            machine:null,
            min:null,
            max:null,
            col:null,
            selected:'',
            showCustomColors:false,
            tableData:[],
            machineOptions:[],
            paramOptions:[],
            api:'createWidgetForView',
            widgetForm:{},
            colorOptions:[
                {label:'red',value:'#F44336FE'},
                {label:'pink',value:'#E91E63FE'},
                {label:'purple',value:'#9C27B0FE'},
                {label:'deep-purple',value:'#673AB7FE'},
                {label:'indigo',value:'#3F51B5FE'},
                {label:'blue',value:'#2196F3FE'},
                {label:'light-blue',value:'#03A9F4FE'},
                {label:'cyan',value:'#00BCD4FE'},
                {label:'teal',value:'#009688FE'},
                {label:'green',value:'#4CAF50FE'},
                {label:'light-green',value:'#8BC34AFE'},
                {label:'lime',value:'#CDDC39FE'},
                {label:'yellow',value:'#FFEB3BFE'},
                {label:'amber',value:'#FFC107FE'},
                {label:'orange',value:'#FF9800FE'},
                {label:'deep-orange',value:'#FF5722FE'},
                {label:'blue-grey',value:'#607D8BFE'},
                {label:'grey',value:'#9E9E9EFE'},
                {label:'black',value:'#000000FE'},
                ],
                widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            form:{
                name:null,
                color_meta:[]
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => ( !isNaN(v))|| 'It should be a valid number',
                v => (!!v && v.length <= 8 && v.length > 0) || 'Number must be less than 8 characters',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            rules:FormRules.rules,
             countRules:[

                v => !!v || 'Required',

                v => (v && v>1) || 'Number of Machine should be greater than 1'

            ],
        }
    },
    computed:{
        
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
    },
    methods:{
        showCustomColor(){
            this.showCustomColors = ! this.showCustomColors
        },
        getMachineList(){

            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                this.loading = true;
      axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                  
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
      addColorPoint(){
        this.$refs.colorForm.validate()
        // console.log(this.form.color_meta,'this.form.color_meta')
        // console.log(this.color,'this.color')
            if(this.colorValid && this.color && this.color.hexa){
                // console.log('entered colorpoint'
                    this.form.color_meta.push({
                      strokeStyle: this.color.hexa, min: this.min, max: this.max
                    })
                }else{
                    this.form.color_meta.push({
                      strokeStyle: this.color, min: this.min, max: this.max
                    })
                }
      },
      deleteColorFromList(item){
        this.form.color_meta=this.form.color_meta.filter(x=>x.min!=item.min)
      },
        onSubmit(){
            this.$refs.panelForm.validate()
            // console.log(this.form,'this.form')
            if(this.valid){
              this.loading=true
            if(!this.tableData){
                this.tableData=[]
              }
            this.tableData=[
                {label:this.form.name, params:this.form.param, color_meta:this.form.color_meta, param_type:this.param_type, pointer_color:this.form.pointer_color}
            ]
            this.widgetForm.meta.data=this.tableData
            // console.log(this.widgetForm,'near submit')
            // this.widgetForm.meta.legend=this.legend
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt

    }
    })
    .then(response=>{
      if(response.data.status==='success'){
        //   this.$store.dispatch('refreshDashboardGauges')
          this.loading=false
          this.$emit('success')
            this.$emit('close')
      }else{
        console.log('failed')
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }else{
                console.log('validation failed')
            }

        },
        close(){
            //this.$refs.panelForm.reset()
            this.$refs.colorForm.reset()
            this.dialog=false
            this.loading=false
            //this.form.type='usage'
            this.$emit('close')
            
            
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }

    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        param_type:  function (v){
          if(!this.edit){
            this.form.param_type=v
           //this.form.type='usage'
           this.form.param=null
          }
         
        },
        color:  function (){
                if(this.color&&this.color.hexa){
                  this.col=this.color.hexa
                }else{
                  this.col=this.color
                }
        },
        pointer_color:  function (){
                if(this.pointer_color&&this.pointer_color.hexa){
                  this.form.pointer_color=this.pointer_color.hexa
                }else{
                  this.form.pointer_color=this.pointer_color
                }
        },
        selected(){
            this.showCustomColor()
        }
    }
}
</script>
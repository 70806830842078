<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().background2ColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="showGraphType" no-gutters>
            <v-col align="right">
                <v-btn-toggle
              :dark="$store.getters.getColorPalette().isDark"
        v-if="tableData.length>0"
          v-model="timeRangeModel"
          mandatory
          rounded
        >
        <v-btn small v-for="days in timeRangeOptions" :key="days">
          {{ days }} {{$store.getters.getTextMap().days}}
        </v-btn>
        
              </v-btn-toggle>
            </v-col>
            <v-col align="right">
                <v-btn-toggle
              :dark="$store.getters.getColorPalette().isDark"
        v-if="tableData.length>0"
          v-model="graphType"
          mandatory
          rounded
        >
          <v-btn small>
            {{$store.getters.getTextMap().bar}}
          </v-btn>
          <v-btn small>
            {{$store.getters.getTextMap().line}}
          </v-btn>
              </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
              
                 <div ref="graph"></div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import InfoAlert from '@/components/InfoAlert'
export default{
    name:'LatestUsagePanel',
    props:{
        'params':{
            type:Array,
            required:true
        },
        'dimensions':{
            type:Array,
        },
        timeframe:{
            type:String,
            default: 'day'
        },
        from_time:String,
        to_time:String,
        title:String,
        showGraphType:{
            type:Boolean,
            default:true
        }
    },
    components:{
        InfoAlert
    },
    mounted(){
        this.refresh()
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            parameters:[],
            tableData:[],
            graphData:[],
            graphType:'scatter',
            timeRangeModel:0,
            from_date:null,
            to_date:null,
            layout:{
                colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
              font:{
                            color:this.$store.getters.getColorPalette().accentCode
                        },
    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',
    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    
                    
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
  
            },
            timeframeOptions:[
                {label:'Daily',value:'day'},
                {label:'Monthly',value:'month'},
                {label:'Yearly',value:'year'},
            ],
            timeRangeOptions:[
                30,180,365
            ]
        }
    },
    computed:{},
    methods:{
        init(){
            this.parameters=[]
            let type=this.graphType==1?'scatter':'bar'
            this.graphData={}
            for(let i of this.params){
                //console.log(i)
                if(typeof i == 'string' || i instanceof String){
                    this.parameters.push(i)
                    let prm=this.$store.getters.getParameterById(i)
                    if(!prm){
                      prm=this.$store.getters.getParamById(i)
                    }
                    //TODO: check if prm is valid
                          
                            let name=this.$store.getters.getDeviceById(prm['device_id'])?this.$store.getters.getDeviceById(prm['device_id'])['name']+'-'+prm.name:prm.name
                            this.graphData[i]={
                                x: [],
                                y: [],
                                name:name,
                                type: type
                            }
                }else if('param_id' in i && 'name' in i){
                    //console.log('push')
                    this.parameters.push(i.param_id)
                    this.graphData[i.param_id]={
                                x: [],
                                y: [],
                                name:i.name,
                                type:type
                            }                    

                }
                
            }
        },
        createGraph(){
            if(this.dimensions){
                  this.layout.height=this.dimensions[0]['height'] 
                  // this.layout.width=this.dimensions[1]['width'] 
                //   this.layout.height=100
                //   this.layout.width=600
                  this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
                  this.layout.margin={
                    t:40,
                    b:40,
                    l:40,
                    r:80
                }
                }
        if(this.tableData && this.tableData.length>0){
            
            if(!this.processing){
              this.processing='usage'
            }
            if(this.title){
                this.layout['title']=this.title
            }
            let type=this.graphType==1?'scatter':'bar'
            for (let i of this.tableData){
                //console.log(i)
                        if(i.param_id && i.param_id in this.graphData){
                            this.graphData[i.param_id]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD HH:mm:ss'))
                            this.graphData[i.param_id]['y'].push(Number(i[this.processing]))
                        }else{
                            let prm=this.$store.getters.getParameterById(i.param_id)
                            //TODO: check if prm is valid
                            let name=this.$store.getters.getDeviceById(prm['device_id'])['name']+'-'+prm.name
                            this.graphData[i.param_id]={
                                x: [],
                                y: [],
                                name:name,
                                type: type
                            }
                            
                            this.graphData[i.param_id]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD HH:mm:ss'))
                            this.graphData[i.param_id]['y'].push(Number(i[this.processing]))
                        }
                    
                }
                //this.graphData.push(data)
                
            Plotly.newPlot(this.$refs.graph, Object.values(this.graphData), this.layout,{displaylogo: false, responsive: true}); //displayModeBar:false
        }

      },
        refresh(){
            if(this.params && this.params.length>0){
                this.init()
            
              this.loading=true
              let timeRange=this.timeRangeOptions[this.timeRangeModel]
                this.from_date = moment().subtract(timeRange,'days').format("YYYYMMDD")
                this.to_date = moment().format("YYYYMMDD")
                //d['parameters'] = this.form.parameters.map(a => a.param_id)
              
              
                //let name=this.$store.getters.getDeviceById(p['device_id'])['name'] +" - " +p['name']
                let payload={
                    timeframe:this.timeframe,
                    from_date:this.from_date,
                    to_date:this.to_date,
                    parameters:this.parameters
                }
                // console.log(payload)
                axios.post(this.$store.state.api+'getParamGroupedUsageDataByTimeframe',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                  // console.log("Param data received")
                  //console.log(response.data.monthlyData)
                  //context.state.monthlyData=response.data.monthlyData
            // console.log(response.data.data)
            //console.log('something')


            if(response.data.status==='success'){
                //console.log(response.data.data)
                this.tableData=response.data.data
                
                  this.createGraph()

                }else{
                  this.info = response.data.msg;
                  //this.info = d;
                        this.showDismissibleAlert = true;
                  //console.log(response.data.msg)
                  this.loading=false
                }
                  this.loading=false

                })
                .catch(err=>{
                this.showDismissibleAlert=true
                        this.info=err
                        this.loading=false
                        })

            }     
              
    
   
            
        }
    },
    watch:{
        params:function (){
            this.refresh()
        },
        graphType: function (){
            this.init()
            this.createGraph()
        },
        timeRangeModel: function(){
            this.refresh()
        }

    }
}
</script>
<template>
    <div class="py-5 px-auto">
      <span :style="{color:$store.getters.getColorPalette().accentCode}"><strong>{{label}}</strong></span>
      
        <div ref="graph"></div>
    </div>
</template>

<script>
// import {LineBar } from 'vue-chartjs'
import moment from 'moment'
import Plotly from 'plotly.js'
// import jsPDF from 'jspdf'
// import ColorUtility from '@/utillities/ColorUtility'

export default {
    name:'SingleLineBarGraphByEpoch',

    props:['label','graphData','dataKey','unit','timeUnit','background_color'],
    components:{
        
    },
    data(){
      return {
        layout : {
        // barmode: 'stack',
        font:{color:this.$store.getters.getColorPalette().accentCode},
        plot_bgcolor:this.background_color?this.background_color:this.$store.getters.getColorPalette().background2ColorCode,
        paper_bgcolor:this.background_color?this.background_color:this.$store.getters.getColorPalette().background2ColorCode,
        autosize: true,
        hoverlabel:{namelength:-1,},
        xaxis: {
          tickangle: -12,
          zeroline: false,
          showgrid: true,
          showticklabels: true,
          color:this.$store.getters.getColorPalette().accentCode,
        },       
        yaxis: {
          // range: [0, this.benchmarkYValue + 100],
          showticklabels: true,
          color:this.$store.getters.getColorPalette().accentCode,
        },
        uniformtext: {
          "mode": "hide",
          "minsize": 1
        },
      },
      }
    },
    computed:{
      plots(){
        let data=[]
        if(this.graphData){

          data=[this.graphData.reduce((accumulator, currentValue) => {
            accumulator['x'].push(moment.unix(currentValue.timestamp).format('YYYY-MM-DD HH:mm:ss'))
            accumulator['y'].push(Number(currentValue[this.dataKey]))
            // accumulator['usage']+=Number(currentValue.data)
            return accumulator
          },{
            x: [],
            y: [],
            name:this.yAxisLabel,
            type: 'scatter',
            // usage:0
          }),]

        }
        return data
      },
        dateLabels(){
            let l=[]

            if(this.timestamps){
              for(let i of this.timestamps){
                l.push(new Date(Number(i)*1000));
              }
            }else{
            for (let i of this.graphData){
                l.push(new Date(Number(i.timestamp)*1000));
            }
            }
            return l
            //return ['1','2','3','4']
        },
        consumed(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.dataKey])
            }
            
            return l
        },
        yAxisLabel(){
          if(this.unit && this.label){
            return this.label +" - "+ this.unit
          }else if(this.unit){
            return this.unit
          }else if(this.label){
            return this.label
          }else{
            return ''
          }
        }
    },
    // data(){
    //     return {
    //        colorCode:this.$store.getters.getColorPalette().colorTypesCodeArray,
    //         tickfontColor:this.$store.getters.getColorPalette().fontcolorTypescodes,
    //     }
    // },
    methods: {
      drawGraph(){
        // console.log(this.plots)
        Plotly.newPlot(this.$refs.graph, this.plots, this.layout,{displaylogo: false});
          
      },

      //   createChart(){
      //       this.renderChart({
      //     //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
      //     labels: this.dateLabels,
      //     datasets: [
      //       {
      //           barPercentage: 0.9,
      //   //barThickness: 6,
      //   type:'bar',
      //   maxBarThickness: 81,
      //           label:this.label,
      //          backgroundColor: this.colorCode[0],//'#90A4AE',
      //          borderColor: this.colorCode[0],
      //          pointBorderColor: this.colorCode[2],//'rgba(20, 25, 100,0.5)',
      //          pointBackgroundColor: 'rgba(255,255,255,1)',
      //           //pointRadius:1,
      //           borderWidth: 2,
      //           hoverBorderWidth: 4,
                
      //           //data: [119, 40,60,34,100,20,10],
      //           data: this.consumed,
      //           fill:true,
      //           lineTension:0.1
      //       },
      //       {
      //           barPercentage: 0.9,
      //   //barThickness: 6,
      //   type:'line',
        
      //           label:this.label,
      //          backgroundColor: this.colorCode[0],//'#90A4AE',
      //          borderColor: this.colorCode[0],
      //          pointBorderColor: this.colorCode[2],//'rgba(20, 25, 100,0.5)',
      //          pointBackgroundColor: 'rgba(255,255,255,1)',
      //           //pointRadius:1,
      //           borderWidth: 2,
      //           hoverBorderWidth: 4,
                
      //           //data: [119, 40,60,34,100,20,10],
      //           data: this.consumed,
      //           fill:true,
      //           lineTension:0.1
      //       }
      //     ]},{responsive: true, maintainAspectRatio: true, aspectRatio:3, legend:{display: false},
      //     scales: {
      //   xAxes: [ {
      //       offset: true,
      //     type: 'time',
      //     distribution: 'series', //series
      //     time: {
      //               unit: this.timeUnit?this.timeUnit:'hour',
      //               displayFormats: {
      //                   hour: 'hh a',
      //                   day_hour: 'DD/MM-hha'
      //               }
      //           },
      //     display: true,
      //     scaleLabel: {
      //       display: false,
      //       labelString: 'Date',
      //      fontColor:this.$store.getters.getColorPalette().tickfontColor,
      //     },
      //     ticks: {
      //       fontColor:this.$store.getters.getColorPalette().tickfontColor,
      //         scaleBeginAtZero: false,
      //       major: {
      //         fontStyle: 'bold',
      //         fontColor:this.$store.getters.getColorPalette().tickfontColor,
      //       },
      //       source:'auto'
      //     },
      //      gridLines: {
      //     display: true,
      //     color: "rgba(165, 165, 165,0.6)"
      //   },
      //   } ],
      //   yAxes: [ {
      //     display: true,
      //      gridLines: {
      //     display: true,
      //     color: "rgba(165, 165, 165,0.6)"
      //   },
      //     scaleLabel: {
      //       display: true,
      //       labelString: this.yAxisLabel,
      //       fontColor:this.$store.getters.getColorPalette().tickfontColor,
      //     }
      //   } ]
      // }})
      //   }
    },
    mounted() {

        this.drawGraph()
        
    },
    watch: {
        plots:function(){
      
      this.drawGraph()

        }
    },
}
</script>
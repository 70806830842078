import Vue from 'vue'
import Vuex from 'vuex'
import storeModules from './store-module'
Vue.use(Vuex)

export default new Vuex.Store({
  /*state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }*/
  state:storeModules.state,
    getters:storeModules.getters,
    actions:storeModules.actions,
    mutations:storeModules.mutations,
    
    
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
})

<template>
<span @click="show=!show" style="cursor:pointer">
<v-dialog
     v-model="show" class="overlay"
      max-width="350"
     :dark="$store.getters.getColorPalette().isDark"
    >

      <v-card>

        <v-card-title  v-text="title"></v-card-title>
       <v-spacer></v-spacer>
        <v-card-subtitle v-text="description"></v-card-subtitle>
        <v-card-actions  >
          <v-spacer></v-spacer>

          

          <v-btn
            color="grey" 
            @click="show=false"
             
          >
          {{$store.getters.getTextMap().cancel}}

          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().deletebtnColor"
           @click="confirm" 
          >
          {{$store.getters.getTextMap().yes_delete_it}}

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <slot ></slot>
</span>
</template>

<script>
export default {
   name:"DeleteConfirmation",
    props: [ 'title', 'description'],

    data(){
    return{
      dialog: false,
      show:false,
   }
},
methods:{
  confirm(){
    this.$emit('confirm')
    this.show=false
  }
}
}

</script>
<style scoped>
</style>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="tableData">
            <v-card-title><strong>{{$store.getters.getTextMap().word_state}}</strong></v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="d-flex" align="right" align-self=center v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
                <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="showUpdate=!showUpdate">{{$store.getters.getTextMap().edit}}
                    <v-icon right :color="$store.getters.getColorPalette().downloadbtnColor" small >mdi-pencil-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-form ref="usageForm" v-model="valid">
            <v-row no-gutters v-if="showUpdate">
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select
                        v-if="!loading"
                        v-model="form.meta"
                        :items="wordOptions"
                        label="Word"
                        outlined 
                        dense         
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :rules="objectRules"
                    ></v-select>
                </v-col>
                <v-col class="ml-1">
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().accentCode" small text outlined :dark="$store.getters.getColorPalette().isDark" @click="updateWord">Configure</v-btn>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-form>
        <v-row v-if="tableData">
            <v-col cols="12">
                <WordStatusTable :tuples="tuples" :value="latestVal" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import WordStatusTable from '@/components/lists/WordStatusTable'
export default {
    name:'WordParamDetails',
    props:['parameter','tableData'],
    mounted(){
        this.getWordTuples()
    },
    components:{
        InfoAlert,
        WordStatusTable
    },
    data(){
        return {
            showUpdate:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            valid:false,
            tuples:[],
            form:{
                meta:null,
                parameter:null,
            },
             objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        wordOptions(){
            let op=[]
            if(this.$store.state.words && this.$store.state.words.length>0){
                for(let i of this.$store.state.words){
                    op.push({
                        label:i.name,
                        value:i.word_id
                    })
                }
            }
            return op
        },
        latestVal(){
            if( this.tableData.length>0){
                //console.log("current data size accurate")
            return Number(this.tableData[this.tableData.length-1]['data'])

            }
            return 0
        }
    },
    methods:{
        //getBitState:BitwiseOperationUtillity.getBitState,
        getWordTuples(){
            if(this.parameter && this.parameter.data_type==8 && this.parameter.meta){
                this.loading=true
                
            axios.post(this.$store.state.api+'getWordTuplesByID',{word_id:this.parameter.meta},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.tuples=response.data.data
                            this.loading=false
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
            }else{
                this.tuples=[]
            }

        },
        updateWord(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                this.form['parameter']=this.parameter.parameter
                axios.post(this.$store.state.api+'updateParamMeta',this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
if(response.data.status==='success'){
    this.$store.dispatch('refreshParameters')
    this.info="Word Updated"
    this.showDismissibleAlert = true;
    this.showUpdate=false
}else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      
    }
    this.loading=false
    }).catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

            }

        }
    }
}
</script>
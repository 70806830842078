<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Target Based Trend Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Target Based Trend Widget</span>
            </v-col>
        </v-row> -->
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">   
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_target_based_trend_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_target_based_trend_widget}}</v-card-title>  
            <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="widgetForm" v-model="widgetValid">
                <v-row>
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="widgetForm.width"
                            :items="widthOptions"
                            :label="$store.getters.getTextMap().widget_width" 
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                    <v-col>
                        <v-select
                            v-if="!loading"
                            v-model="widgetForm.height"
                            :items="heightOptions"
                            :label="$store.getters.getTextMap().widget_height"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            ></v-select>
                    </v-col>
                    <v-col align-self="center" >
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                    <v-col v-if="!disableTimeframe" align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="timeFrame"
                            :items="tdOptions"
                            :label="$store.getters.getTextMap().timeFrame"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="graphType"
                            :items="graphTypeOptions"
                            :label="$store.getters.getTextMap().default_graph_type"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                    <v-col v-if="!loading && graphType==2" align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading && graphType==2"
                            v-model="barmode"
                            :items="barmodeOptions"
                            :rules="[rules.required]"
                            :label="$store.getters.getTextMap().bar_mode"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                        ></v-select>  
                    </v-col>
                    <v-col v-if="edit" align-self="center">
                        <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="target"
                            :items="targetOptions"
                            :label="$store.getters.getTextMap().target"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        ></v-select>
                    </v-col>
                    <!-- <v-col>
                        <v-checkbox v-model="showIdeal" label="Show Ideal line"></v-checkbox>
                    </v-col> -->
                    <v-col  align-self="center" v-if="!loading && showIdeal">
                        <v-text-field 
                            v-model="idealValue"   
                            :label="$store.getters.getTextMap().idealValue"
                            :rules="[rules.required,rules.num]"
                            type="number"  
                            dense
                            outlined
                        >
                        </v-text-field>
                    </v-col>
                    <v-col align-self="center" v-if="!loading">
                        <v-text-field 
                            v-model="targetLabel" 
                            type="text" 
                            :label="$store.getters.getTextMap().target_line_label"
                            :rules="[rules.required,rules.validString]"
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                    <!-- <v-col align-self="center" v-if="!loading && showIdeal">
                        <v-text-field 
                            v-model="idealLabel" 
                            type="text" 
                            label="idealLabel" 
                            :rules="[rules.required,rules.validString]"
                            dense
                            outlined>
                        </v-text-field>
                    </v-col> -->
                </v-row>
            </v-form>
        </v-card>
        <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
            <!-- <v-card  :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().background1ColorCode" flat outlined> -->
                <v-card-title class="sub-heading">{{$store.getters.getTextMap().add_parameters}}</v-card-title>
                <v-divider :dark="$store.getters.getColorPalette().isDark" />
                <v-form ref="usageForm" v-model="valid">
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field
                                    v-if="!loading"
                                    v-model="form.label"
                                    :counter="30"
                                    :rules="[rules.required]"
                                    :label="$store.getters.getTextMap().param_label"
                                    dense 
                                    outlined
                                    required
                                ></v-text-field>
                            </v-col>    
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    outlined         
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="objectRules"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    dense
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="objectRules"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="form.param"
                                    :items="paramOptions"
                                    :label="$store.getters.getTextMap().param"
                                    outlined
                                    dense
                                    multiple
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="[rules.required,rules.nonZeroLength]"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-divider :dark="$store.getters.getColorPalette().isDark" />
                        <v-row no-gutters>
                            <v-chip class="ma-2" close color="indigo" text-color="white" v-for="param in form.param" @click:close="removeParam(param)" :key="param">
                                {{getParamName(param)}}
                            </v-chip>
                            <v-spacer/>
                            <v-col v-if="!loading" align-self="center" cols="auto" class="d-flex mt-2" align="right">
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addSingleParamToList">{{ $store.getters.getTextMap().add_param_to_list }}</v-btn>
                                <v-btn class="ml-2" small text outlined :color="$store.getters.getColorPalette().cancelbtnColor" @click="onReset">{{ $store.getters.getTextMap().cancel }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            <!-- </v-card> -->
            <!-- <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background1ColorCode" class="mx-auto" flat outlined> -->
                <v-row  v-if="!loading && tableData " class="pa-2">
                    <v-col cols="12" >
                        <v-simple-table height="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
                            <template v-slot:default >
                                <thead>
                                    <tr>
                                        <th class="text-left"><strong>{{ $store.getters.getTextMap().label }}</strong></th>
                                        <th class="text-left"> <strong>{{ $store.getters.getTextMap().param_label }}</strong></th>
                                        <th class="text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="item in tableData">
                                        <td>{{ item.label }}</td>
                                        <td>{{getParamName(item.param)}}</td>
                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Bar Trend widget?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            <!-- </v-card>     -->
        </v-card>
        <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex mt-2" align="right">
                <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{ $store.getters.getTextMap().cancel }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'AddTargetBasedBarTrendWidget',
    props:['widget','seq','disableTimeframe'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
        this.getTargets()
        this.init()
    },
    data(){
        return {
            showDialog:false,
            showSingleParam:false,
            showMultiParam:false,
            counter:0,
            count:0,
            show:false,
            dialog:false,
            dialogss:false,
            loading:false,
            valid:false,
            ValidMulti:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            api:'createWidgetForView',
            edit:false,
            workspace:null,
            machine:null,
            legend:false,
            textInfo:null,
            graphType:2,
            barmode:'group',
            idealValue:0,
            showIdeal:false,
            idealLabel:'',
            targetLabel:'Target',
            tableData:[],
            paramTemp:null,
            operatorTemp:null,
            paramArray:[],
            paramMultiFinal:[],
            equation:'',
            form:{
            },
            widgetForm:{},
            machineOptions:[],
            paramOptions:[],
            targetOptions:[],
            target:null,
            barmodeOptions:[
                {label:'Stacked', value:'stack'},
                {label:'Grouped', value:'group'}
            ],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            graphTypeOptions:[
                {label:'Bar', value:2},
                {label:'Line', value:1},
            ],
            tdOptions:[
                // {label:'Real Time', value:'rtd'},
                {label:'MTD (Month Till Date)', value:'mtd'},
                {label:'YTD (Year Till Date)', value:'ytd'},
                {label:'last 7 days', value:'7'},
                {label:'last 30 days', value:'30'},
                {label:'last 60 days', value:'60'},
                {label:'last 90 days', value:'90'},
                {label:'last 180 days', value:'180'},
                {label:'last 365 days', value:'365'},
            ],
            textInfoOptions:[
                {label:'Label & percentage', value:'label+percent'},
                {label:'Value Only', value:'value'},
                {label:'Value & Percentage', value:'value+percent'},
                {label:'Percentage only', value:'percent'},
                {label:'None',value:'none'}
            ],
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
             countRules:[
                v => !!v || 'Required',
                v => (v && v>1) || 'Number of Machine should be greater than 1'
            ], 
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
            let g=this.$store.state.workspaces
            for(let i of g){
                op.push( {value:i.workspace_id, label: i.name })
            }
            return op
        },
        timeFrame:{
            get: function (){
                if(this.widgetForm && this.widgetForm.meta){
                    return this.widgetForm.meta.time_frame
                }else{
                    return null
                }
            },
            set: function (newValue){
                if(this.widgetForm && this.widgetForm.meta){
                    this.widgetForm.meta.time_frame=newValue
                }else{
                    this.widgetForm.meta={time_frame:newValue}
                }
            }
        }  
    },
    methods:{
        init(){
            if(this.widget && this.widget.widget_id && this.widget.view_id){
                this.edit=true
                this.widgetForm=Object.assign({},this.widget)
                this.api='updateWidget'
                if(!(this.widgetForm.meta)){
                    this.widgetForm.meta={data:[]}
                    this.tableData=[]
                }else{
                    this.tableData=this.widgetForm.meta.data
                    this.graphType=this.widgetForm.meta.graphType
                    this.barmode=this.widgetForm.meta.barmode
                    this.idealValue=this.widgetForm.meta.idealValue,
                    this.idealLabel=this.widgetForm.meta.idealLabel,
                    this.legend=this.widgetForm.meta.legend
                    this.textInfo=this.widgetForm.meta.textinfo
                    this.showIdeal=this.widgetForm.meta.showIdeal
                    this.target=this.widgetForm.meta.target
                    this.targetLabel=this.widgetForm.meta.targetLabel
                }
            }else{
                if(this.widget.view_id){
                    this.api='createWidgetForView'
                    this.widgetForm=Object.assign({},this.widget)
                    this.widgetForm.meta={data:[]}
                    this.widgetForm.seq=this.seq?this.seq:0
                    this.tableData=[]
                }
            }
        },
        getTargets(){
            let op=[]
            this.loading=true
            axios.get(this.$store.state.api+'targets',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    let targets=response.data.data
                    for(let i of targets){
                            if(i && i.name){
                                op.push({value:i.target_id, label: i.name })
                            }else{
                                op.push({value:i.target_id, label: i.target_id })
                            }
                        }
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true 
            })
            this.targetOptions=op
        },
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let machines=response.data.machines
                        for(let i of machines){
                            if(i && i.name){
                                op.push({value:i.machine_id, label: i.name })
                            }else{
                                op.push({value:i.machine_id, label: i.machine_id })
                            }
                        }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            if(this.machine ){
                this.loading=true
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.paramOptions=[]
                        for(let i of response.data.params){
                            this.paramOptions.push({label:i.name,value:i.param_id})
                        }
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        getParamName(param){
        let temp=[]
        if(param && Array.isArray(param)){
            for(let i of param){
                let p=this.$store.getters.getParameterById(i)
                if(p){
                  let param_name = this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
                  temp.push(param_name)
                }else{
                    return "Issue with getting Param Name"
                }
            }
            return temp
        }else{
          let p=this.$store.getters.getParameterById(param)
          if(p){
            return this.$store.getters.getDeviceById  (p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        }
        },
        addSingleParamToList(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              if(!this.tableData){
                this.tableData=[]
              }
              this.tableData.push({label:this.form.label, param:this.form.param})
              this.form={}
              this.loading=false
            }
        },
        onReset(){
            this.showSingleParam=false
            this.showMultiParam=false
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.paramTemp=null
            this.operatorTemp=null
            this.equation=''
            this.form.no_ofparams= null,
            this.paramNegFinal=[],
            this.paramPosFinal=[],
            this.paramArray=[],
            this.paramPos=[],
            this.paramNeg=[],
            this.operatorArray=[],
            this.paramMultiFinal=[],
            this.$refs.usageFormMulti.reset()
            this.loading=false
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
                this.loading=true
                this.widgetForm.meta.data=this.tableData
                this.widgetForm.meta.graphType = this.graphType
                this.widgetForm.meta.legend=this.legend
                this.widgetForm.meta.textinfo=this.textInfo
                this.widgetForm.meta.barmode=this.barmode
                this.widgetForm.meta.idealValue=this.idealValue
                this.widgetForm.meta.idealLabel=this.idealLabel
                this.widgetForm.meta.showIdeal=this.showIdeal
                this.widgetForm.meta.target=this.target
                this.widgetForm.meta.targetLabel=this.targetLabel
                axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.log(err)
                    this.info = err;    
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },

        onClose(){
            this.show=false,
            this.dialog=false,
            this.dialogss=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },

        removeParam(param){
            this.form.params=this.form.params.filter(x=>x!=param)
        },

        deleteFromTable(item){
            this.tableData=this.tableData.filter(x=>x.label!=item.label)
        },

        cancel(){
          this.showDialog=false
        },

        confirm(){
          this.showDialog=false
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        widget(){
            this.init()
        }
    }
}
</script>
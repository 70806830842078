<template>
    <v-container fluid :color="$store.getters.getColorPalette().backgroundColor">

        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info">
        </InfoAlert>


        <!-- Workspace details card and its form -->
        <v-card class="px-5" flat :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
            outlined>

            <v-card-title v-if="edit">{{ $store.getters.getTextMap().edit_dual_axis_bar_trend_widget }}</v-card-title>
            <v-card-title v-else>{{ $store.getters.getTextMap().add_dual_axis_bar_trend_widget }}</v-card-title>

            <v-skeleton-loader v-if="loading"></v-skeleton-loader>

            <v-form :dark="$store.getters.getColorPalette().isDark" ref="widgetForm" v-model="widgetValid">
                <v-row>

                    <!-- Widget Label -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-text-field outlined dense v-if="!loading" :counter="30"
                            :label="$store.getters.getTextMap().widget_label" v-model="widgetLabel"
                            :rules="[rules.required]" required :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-text-field>
                    </v-col>

                    <!-- widget width -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select outlined dense v-if="!loading" :label="$store.getters.getTextMap().widget_width"
                            :rules="[rules.required]" required :items="widthOptions" item-text="label"
                            item-value="value" v-model="widgetForm.width"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    </v-col>

                    <!-- Widget height -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select outlined dense v-if="!loading" :label="$store.getters.getTextMap().widget_height"
                            :rules="[rules.required]" :items="heightOptions" item-text="label" item-value="value"
                            v-model="widgetForm.height" required
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    </v-col>
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>

                    <!-- Widget Timeframe-->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-select outlined dense v-if="!loading" :label="$store.getters.getTextMap().timeFrame"
                            :rules="[rules.required]" required :items="tdOptions" item-text="label" item-value="value"
                            v-model="timeframe" :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    </v-col>


                    <!-- Widget timeRange -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            :rules="[rules.required]" required v-model="timeRange" :items="trOptions"
                            :label="$store.getters.getTextMap().aggregation" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>

                    <!-- Widget Y1 Graphtype -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            :rules="[rules.required]" required v-model="y1graphType" :items="graphTypeOptions"
                            :label="$store.getters.getTextMap().y1_graph_type" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>


                    <!-- Widget Y2 Graph Type -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            :rules="[rules.required]" required v-model="y2graphType" :items="graphTypeOptions"
                            :label="$store.getters.getTextMap().y2_graph_type" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>



                    <!-- y1-axis label -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="y1axisLabel" :label="$store.getters.getTextMap().y1axis_label" dense outlined
                            :rules="[rules.required]" required item-text="label" item-value="value"></v-text-field>
                    </v-col>

                    <!-- y2-axis label -->
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="y2axisLabel" :label="$store.getters.getTextMap().y2axis_label" dense outlined
                            :rules="[rules.required]" required item-text="label" item-value="value"></v-text-field>
                    </v-col>

                </v-row>


                <v-card-title class="sub-heading">{{ $store.getters.getTextMap().add_parameters }}</v-card-title>
                <v-row>
                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4" v-if="checkDate">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete v-if="!loading" v-model="datatype" :items="dataOptions"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" outlined dense
                            :rules="[rules.required]" required :label="$store.getters.getTextMap().data_type"
                            item-text="label" item-value="value"></v-autocomplete>
                    </v-col>



                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>

                        <v-autocomplete v-if="!loading" v-model="workspace" :items="workspaceOptions"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" outlined dense
                            :rules="[rules.required]" required :label="$store.getters.getTextMap().workspace"
                            item-text="label" item-value="value"></v-autocomplete>
                    </v-col>

                    <v-col align-self="center" cols="12" sm="12" md="4" lg="4" xl="">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete v-if="!loading" v-model="machine" :items="machineOptions"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" outlined dense small-chips
                            :rules="[rules.required]" required :label="$store.getters.getTextMap().machine_name"
                            item-text="label" item-value="value"></v-autocomplete>
                    </v-col>
                    <v-col align-self="center" ccols="12" sm="12" md="4" lg="4" xl="4">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>

                        <v-autocomplete v-if="!loading" v-model="parameter" :items="paramOptions"
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" outlined dense small-chips
                            multiple :label="$store.getters.getTextMap().param_name" item-text="label"
                            item-value="value" :rules="objectRules"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>




            <v-row>
                <v-col>

                    <v-chip class="ma-2" close small color="teal" text-color="white" @click:close="remove(param)"
                        v-bind:key="param.parameter" v-for="param in parameter">
                        {{ param.name }}
                    </v-chip>
                </v-col>
            </v-row>

            <v-skeleton-loader v-if="loading"></v-skeleton-loader>
        </v-card>




        <v-card flat class="py-5 " :dark="$store.getters.getColorPalette().isDark"
            :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
            outlined>

            <v-row class="px-5" v-if="parameter != null">
                <v-col cols="5" sm="12" md="5" lg="5" xl="5">
                    <v-card flat :dark="$store.getters.getColorPalette().isDark"
                        :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode, 'background-color': $store.getters.getColorPalette().background2ColorCode }"
                        outlined>
                        <v-row>
                            <v-col>
                                <v-card min-height="220px"
                                    :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().accentCode }"
                                    align="center" class="pa-3">
                                    <h3>Parameters</h3>
                                    <v-row no-gutters v-bind:key="param.param_id" v-for="param in parameter">
                                        <v-col>
                                            <v-checkbox dense :dark="$store.getters.getColorPalette().isDark"
                                                v-model="selected" :value="param" :label="param.name"
                                                multiple></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="2" sm="12" md="2" lg="2" xl="2" class="d-flex flex-colum align-center justify-center">
                    <div align="center">
                        <v-btn v-if="!loading" :elevation="0" @click="addToY1" class="mb-3" outlined
                            :color="$store.getters.getColorPalette().accentCode"> Add to Y1
                            <v-icon>mdi-greater-than</v-icon>
                        </v-btn>
                        <v-btn v-if="!loading" :elevation="0" @click="addToY2" outlined
                            :color="$store.getters.getColorPalette().accentCode" class="mb-3"> Add to Y2
                            <v-icon>mdi-greater-than</v-icon>
                        </v-btn>

                        <v-btn v-if="!loading" :elevation="0" @click="addBack" outlined
                            :color="$store.getters.getColorPalette().accentCode" class="mb-3">
                            <v-icon>mdi-less-than </v-icon>
                            <v-icon>mdi-less-than </v-icon>
                        </v-btn>
                        <div>
                            <v-btn v-if="!loading" :elevation="0" @click="removeSelectedParam" outlined
                                :color="$store.getters.getColorPalette().cancelbtnColor" class="mb-3">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>

                <v-col cols="5" sm="12" md="5" lg="5" xl="5">
                    <v-row>
                        <v-col>
                            <v-card :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().accentCode }"
                                align="center" class="pa-3" min-height="100px">
                                <h3>Y1 Axis</h3>
                                <v-row no-gutters v-bind:key="param.param_id" v-for="param in y1axisdata">
                                    <v-col>
                                        <v-checkbox dense :dark="$store.getters.getColorPalette().isDark"
                                            v-model="selectedY1Data" :value="param" :label="param.name"
                                            multiple></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card :style="{ 'border': '1px solid ' + $store.getters.getColorPalette().accentCode }"
                                align="center" class="pa-3" min-height="100px">
                                <h3>Y2 Axis</h3>
                                <v-row no-gutters v-bind:key="param.param_id" v-for="param in y2axisdata">
                                    <v-col>
                                        <v-checkbox dense :dark="$store.getters.getColorPalette().isDark"
                                            v-model="selectedY2Data" :value="param" :label="param.name"
                                            multiple></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>




            <!-- Add params button -->
            <v-col align="center">
                <v-btn class="mx-2" :color="$store.getters.getColorPalette().accentCode" small text outlined
                    @click="addParamToList">{{ $store.getters.getTextMap().add }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().btnborderColorCode"
                        small>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn class="mx-2" small text outlined :color="$store.getters.getColorPalette().cancelbtnColor"
                    @click="onReset">{{ $store.getters.getTextMap().cancel }}
                </v-btn>
            </v-col>

            <!-- Added parameters will appear in this table -->
            <v-row class="pa-2" v-if="!loading && tableData">
                <v-col>
                    <v-simple-table height="200px"
                        :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left"><strong>{{ $store.getters.getTextMap().label }}</strong></th>
                                    <th class="text-left"> <strong>x axis data</strong></th>
                                    <th class="text-left"> <strong>y1 axis data</strong></th>
                                    <th class="text-left"> <strong>y2 axis data</strong></th>
                                    <th class="text-left">{{ $store.getters.getTextMap().delete }}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <!-- Deleting parameter from the list -->
                                <tr>
                                    <td>{{ tableData.label }}</td>
                                    <td>{{ tableData.xaxis_data }}</td>
                                    <td>
                                        [
                                        <span v-for="(item, index) in tableData.y1_axis_data" :key="index">
                                            {{ item.name }}<span v-if="index < tableData.y1_axis_data.length - 1">,
                                            </span>
                                        </span>
                                        ]
                                    </td>
                                    <td>
                                        [
                                        <span v-for="(item, index) in tableData.y2_axis_data" :key="index">
                                            {{ item.name }}<span v-if="index < tableData.y2_axis_data.length - 1">,
                                            </span>
                                        </span>
                                        ]
                                    </td>

                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteFromTable(item)"
                                            title="Delete Confirmation"
                                            description="Are you sure you want to delete this?">
                                            <v-icon small color="red">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>


            <v-row no-gutters class="pa-3">
                <v-col align="center" class="py-5">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit"
                        class="mx-5">{{ $store.getters.getTextMap().submit }}</v-btn>
                    <v-btn class="mx-3" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClear">{{
                            $store.getters.getTextMap().clear }}</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation'


export default {
    name: "AddDualAxisBarTrendWidget.vue",
    components: {
        InfoAlert,
        DeleteConfirmation
    },
    props: ['widget'],
    data() {
        return {
            info: "",
            showDismissibleAlert: false,
            api: "createWidgetForView",
            loading: false,
            edit: false,
            widgetValid: false,
            rules: FormRules.rules,
            objectRules: [
                v => !!v || 'Required',
            ],
            widgetForm: {},
            widgetLabel: null,
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                { label: '3/4 screen', value: 9 },
                { label: 'Auto', value: null }
            ],

            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            graphTypeOptions: [
                { label: 'Bar', value: 2 },
                { label: 'Line', value: 1 },
            ],
            tdOptions: [
                { label: 'Real Time', value: 'rtd' },
                { label: 'Yesteday', value: 'yesterday' },
                { label: 'MTD (Month Till Date)', value: 'mtd' },
                { label: 'YTD (Year Till Date)', value: 'ytd' },
                { label: 'last 7 days', value: '7' },
                { label: 'last 30 days', value: '30' },
                { label: 'last 60 days', value: '60' },
                { label: 'last 90 days', value: '90' },
                { label: 'last 180 days', value: '180' },
                { label: 'last 365 days', value: '365' },
            ],

            trOptions: [
                { label: 'Daily', value: 'day' },
                { label: 'Weekly', value: 'week' },
                { label: 'Monthly', value: 'month' },
                { label: 'Yearly', value: 'year' },
            ],
            dataOptions: [
                { label: 'Raw', value: 'raw' },
                { label: 'Normalised', value: 'normalised' },
                { label: 'Trend', value: 'trend' },
            ],
            barmode: 'group',
            y1axisLabel: null,
            y2axisLabel: null,
            valid: false,
            form: {},
            timeRange: null,
            y1graphType: null,
            y2graphType: null,
            timeframe: null,
            workspace: null,
            datatype: null,
            machine: null,
            machineOptions: [],
            paramOptions: [],
            parameter: null,
            selected: null,
            y1axisdata: [],
            y2axisdata: [],
            selectedY1Data: null,
            selectedY2Data: null,
            paramValid: false,
            tableData: null,
            payloadTableData: [],
            legend: false,
            checkDate: false,
            message: "",
            // mode: null
        }
    },
    computed: {
        workspaceOptions() {
            let op = []
            let g = this.$store.state.workspaces
            for (let i of g) {
                op.push({ value: { workspace_id: i.workspace_id, workspace_name: i.name }, label: i.name })
            }
            return op
        }
    },
    mounted() {

        this.init()
    },
    methods: {
        init() {
            // If widget created already and widget data is there.
            if (this.widget && this.widget.widget_id && this.widget.view_id) {
                this.edit = true
                this.widgetForm = Object.assign({}, this.widget)
                this.api = 'updateWidget'
                this.widgetLabel = this.widget.label
                this.tableData = this.widgetForm.meta.data
                this.legend = this.widgetForm.meta.legend
                this.y1axisLabel = this.widget.meta.y1axis_label
                this.y2axisLabel = this.widget.meta.y2axis_label
                this.timeframe = this.widget.meta.time_frame
                this.timeRange = this.widget.meta.time_range
                this.workspace = this.widget.meta.workspace
                this.machine = this.widget.meta.machine
                this.y1graphType = this.widgetForm.meta.y1graph_type
                this.y2graphType = this.widgetForm.meta.y2graph_type
                this.message = "Widget Updated"
                this.parameter = []
                for (let i of this.widget.meta.data.y1_axis_data) {
                    this.parameter.push(i)
                }
                for (let j of this.widget.meta.data.y2_axis_data) {
                    this.parameter.push(j)
                }
                if (this.widget.meta.datatype){
                    this.datatype = this.widget.meta.datatype
                }
            } else {
                // There is no widget for view then this will initiate
                if (this.widget.view_id) {
                    this.api = 'createWidgetForView'
                    this.widgetForm = Object.assign({}, this.widget)
                    this.widgetForm.meta = { data: [] }
                    this.widgetForm.seq = this.seq ? this.seq : 0
                    this.message = "Widget Created"
                    // this.mode = "create"
                }
            }
        },
        addParamToList() {
            this.$refs.widgetForm.validate()
            if (this.widgetValid) {
                this.tableData = {
                    label: this.widgetLabel,
                    xaxis_data: "date",
                    y1_axis_data: [],
                    y2_axis_data: [],
                }
                this.y1axisdata.forEach(item => {
                    this.tableData.y1_axis_data.push(item);
                });
                // Append new items to y2_axis_data
                this.y2axisdata.forEach(item => {
                    this.tableData.y2_axis_data.push(item);
                });
                this.y1axisdata = []
                this.y2axisdata = []
            }
        },
        deleteFromTable(item) {
            this.tableData = this.tableData.filter(x => x.label != item.label)
        },
        remove(item) {
            this.parameter = this.parameter.filter(x => x.name != item.name)
        },
        onReset() {
            this.y1axisdata = null
            this.y2axisdata = null
        },
        addToY1() {
            if (this.selected != null && this.selected.length > 0) {
                this.y1axisdata = this.y1axisdata || [];
                for (let i = 0; i < this.selected.length; i++) {
                    this.y1axisdata.push(this.selected[i]);
                    let index = this.parameter.findIndex(param => param.name === this.selected[i].name);
                    if (index !== -1) {
                        this.parameter.splice(index, 1);
                    }
                }
                this.selected = [];
            }
        },
        addToY2() {
            if (this.selected != null && this.selected.length > 0) {
                this.y2axisdata = this.y2axisdata || [];
                for (let i = 0; i < this.selected.length; i++) {
                    this.y2axisdata.push(this.selected[i]);
                    let index = this.parameter.findIndex(param => param.name === this.selected[i].name);
                    if (index !== -1) {
                        this.parameter.splice(index, 1);
                    }
                }
                this.selected = [];
            }
        },
        addBack() {
            if (this.selectedY1Data != null && this.selectedY1Data.length > 0) {
                for (let i = 0; i < this.selectedY1Data.length; i++) {
                    this.parameter.push(this.selectedY1Data[i]);
                    let index = this.y1axisdata.findIndex(param => param.name === this.selectedY1Data[i].name);
                    if (index !== -1) {
                        this.y1axisdata.splice(index, 1);
                    }
                }
                this.selectedY1Data = [];
            }
            if (this.selectedY2Data != null && this.selectedY2Data.length > 0) {
                for (let i = 0; i < this.selectedY2Data.length; i++) {
                    this.parameter.push(this.selectedY2Data[i]);
                    let index = this.y2axisdata.findIndex(param => param.name === this.selectedY2Data[i].name);
                    if (index !== -1) {
                        this.y2axisdata.splice(index, 1);
                    }
                }
                this.selectedY2Data = [];
            }
        },
        removeSelectedParam() {
            const removeFromList = (list, selectedList) => {
                if (selectedList != null && selectedList.length > 0) {
                    for (let i = 0; i < selectedList.length; i++) {
                        const index = list.findIndex(param => param.name === selectedList[i].name);
                        if (index !== -1) {
                            list.splice(index, 1);
                        }
                    }
                }
            };
            removeFromList(this.parameter, this.selected);
            removeFromList(this.y1axisdata, this.selectedY1Data);
            removeFromList(this.y2axisdata, this.selectedY2Data);
            this.selected = [];
            this.selectedY1Data = [];
            this.selectedY2Data = [];
        },
        getMachineList() {
            let op = []
            if (this.workspace) {
                let payload = {
                    workspace_id: this.workspace.workspace_id
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status == "success") {
                            let machines = response.data.machines
                            for (let i of machines) {
                                if (i && i.name) {
                                    op.push({ value: i, label: i.name })
                                } else {
                                    op.push({ value: i, label: i.machine_id })
                                }
                            }
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        this.info = error;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    });
            }
            this.machineOptions = op
        },
        getParams() {
            if (this.machine) {
                this.loading = true
                axios.post(this.$store.state.api + 'getParamsByMachine', { machine_id: this.machine.machine_id }, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        if (response.data.status == 'success') {
                            this.loading = false
                            this.paramOptions = []
                            for (let i of response.data.params) {
                                this.paramOptions.push({ label: i.name, value: { machine_name: this.machine.name, param_id: i.param_id, name: this.machine.name + ' - ' + i.name, processing: i.processing, meta: i.meta } })
                            }
                        } else {
                            this.loading = false
                            this.info = response.data.msg
                            this.showDismissibleAlert = true
                        }
                    }).catch(err => {
                        this.loading = false
                        this.info = err
                        this.showDismissibleAlert = true
                    })
            }
        },
        onSubmit() {
            this.$refs.widgetForm.validate()
         
            if (this.widgetValid) {
                if (this.y1axisdata && this.y2axisdata) {
                    this.widgetForm.label = this.widgetLabel
                    this.widgetForm.meta.data = this.tableData
                    this.widgetForm.meta['workspace'] = this.workspace
                    this.widgetForm.meta['machine'] = this.machine
                    this.widgetForm.meta.y1axis_label = this.y1axisLabel
                    this.widgetForm.meta.y2axis_label = this.y2axisLabel
                    this.widgetForm.meta.legend = this.legend
                    this.widgetForm.meta.time_frame = this.timeframe
                    this.widgetForm.meta.time_range = this.timeRange
                    this.widgetForm.meta.y1graph_type = this.y1graphType
                    this.widgetForm.meta.y2graph_type = this.y2graphType
                    this.widgetForm.meta.datatype = this.datatype

                    console.log(this.widgetForm);
                    console.log(this.api);
                    
                    

                    axios.post(this.$store.state.api + this.api, this.widgetForm, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                        .then(response => {
                            console.log(response);
                            if (response.data.status === 'success') {
                                this.info = this.message
                                this.tableData = []
                                this.loading = false
                                this.$emit('success')
                                this.$store.dispatch('refreshViews')
                                this.$emit('close')
                            } else {
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                                this.loading = false
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.info = err;
                            this.showDismissibleAlert = true;
                            this.loading = false
                        });
                } else {
                    window.alert("Please add params in y1 and y2 axis boxes.")
                    this.info = "Please add another axis data"
                    this.loading = false
                    this.showDismissibleAlert = false
                }
            } else {
                window.alert("Please enter all the details.")
            }
        },

        onClear() {
            this.$refs.widgetForm.reset()
            this.$refs.paramForm.reset()
            this.$refs.refForm.onClear()
            this.parameter = null
            this.y1axisdata = null
            this.y2axisdata = null
        }
    },
    watch: {
        workspace() {
            this.getMachineList()
        },
        machine() {
            this.getParams()
        },
        timeframe() {
            if (this.timeframe == 'rtd' || this.timeframe == 'yesterday') {
                this.checkDate = true;
                this.widgetForm.meta.datatype = this.datatype
            } else {
                this.checkDate = false
                this.widgetForm.meta.datatype = null
            }
        },
        
    }
}
</script>

<style></style>
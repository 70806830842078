<template>
<v-container>
    
    <v-dialog
      v-model="showDismissibleAlert"
      max-width="295"
    >
      <v-card>
        <v-card-title class="headline">{{$store.getters.getTextMap().oops}}
</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
             color="#26A69A"
            text
            @click="showDismissibleAlert = false"
          >
          <!-- <v-btn
             :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          > -->
          {{$store.getters.getTextMap().ok}}

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    <v-form ref="signupForm" v-model="valid">
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-email"
            v-model="form.email"
            :rules="emailRules"
            :label="$store.getters.getTextMap().email"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-human-greeting"
            v-model="form.name"
            :rules="nameRules"
            :counter="25"
            :label="$store.getters.getTextMap().name"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-phone"
            v-model="form.contact"
            :rules="mobileNumberRules"
            :counter="10"
            :label="$store.getters.getTextMap().mobile_number"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
            v-model="form.password"
            :rules="passwordRules"
            :label="$store.getters.getTextMap().password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text :$store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          
          <v-text-field
            v-model="form.confirmpassword"
            :rules="confirmPasswordRules"
            :label="$store.getters.getTextMap().confirm_password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text :$store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row align="center" justify="space-around">
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
              <v-btn v-if="!loading" :dark="true" class="px-10"  @click="signUp" >{{$store.getters.getTextMap().sign_up}}</v-btn>
              </v-col>
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      max-width="300"
      type="text"
    ></v-skeleton-loader>
              <v-btn  v-if="!loading" class="px-10"  @click="onReset" >{{$store.getters.getTextMap().reset}}</v-btn>
          </v-col>
      </v-row>
    </v-form>
</v-container>
    
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = false;

export default {
    name:'SignUpComponent',
    mounted() {
         if (this.$store.state.loggedIn) {
      this.$router.push("/dashboard");
    }
    },
    data(){
        return {
            form: {
        email: "",
        password: "",
        contact:null,
        name:null,
        confirmpassword:null
      },
      showPass:false,
      valid: false,
      info:null,
      showDismissibleAlert: false,
      loading: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
      ],
      mobileNumberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid',
          v => (v && v.length == 10 ) || 'Phone must be 10 digits',
      ],
      passwordRules:[
          v=> !!v || 'Required',
          v => (v && v.length >= 8 ) || 'Password must be more than 8 characters',
    ],
      confirmPasswordRules:[
          v=> !!v || 'Required',
          v => (v == this.form.password ) || 'Passwords do not match',
    ],
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        }
        };
    },
    methods: {
        signUp(){
            this.$refs.signupForm.validate()
            if(this.valid){


this.loading = true;
      axios.post(this.$store.state.api + "signup", this.form)
        .then(response => {
          if (response.data.status == "success") {            
            console.log("redirecting to login")
            //this.$router.push("/");
            this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.errorList;
            this.showDismissibleAlert = true;
          }
          
          this.loading = false;
        })
        .catch(error => {
          
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });


            }
        },
        onReset () {
        this.$refs.signupForm.reset()
        //this.$refs.uam_form.reset()
      },
    },
}
</script>
<template>
    <v-container fluid  @contextmenu="show" :style="{'background-color':widget.background_color}" > 
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-list-item @click="update=true">
                <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                <v-list-item-icon>
                    <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="deleteWidget">
                <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                <v-list-item-icon>
                    <v-icon color="red" small>mdi-trash-can</v-icon>
                </v-list-item-icon>
            </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}
</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddBitwiseWordWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-card v-if="widget && widget.widget_id && widget.meta && widget.meta.data" class="px-4" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" flat outlined>
            <v-card-title>{{ widget&& widget.widget_id && widget.label?widget.label:'' }}</v-card-title>
            <v-simple-table  :height="height" :dark="$store.getters.getColorPalette().isDark" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th v-if="widget.meta.data.showWordStatus" class="text-left">
                                <strong>{{$store.getters.getTextMap().bit}}
</strong>
                            </th>
                            <th v-if="widget.meta.data.showWordStatus" class="text-left">
                                <strong>{{$store.getters.getTextMap().status}}
</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().inference}}
</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in tuples" :key="item.bit" >
                            <td v-if="widget.meta.data.showWordStatus">{{ item.bit }}</td>
                            <td v-if="widget.meta.data.showWordStatus" class="text-left">{{ getBitState(item.bit,latestVal)?'High':'Low' }}</td>
                            <td :class="'  pa-1 text-left text-subtitle-2'"><span :style="[{'color':getColorClass(getBitState(item.bit,latestVal)?'High':'Low')}]">{{ getBitState(item.bit,latestVal)?item.high:item.low }}</span></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <div v-else>
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import AddBitwiseWordWidget from '@/components/crud_components/widgets/AddBitwiseWordWidget'
import BitwiseOperationUtillity from '@/utillities/BitwiseOperationUtillity'
import {io} from 'socket.io-client'
export default {
    name:"BitwiseWordWidget",
    props:['widget','edit'],
    components:{
        InfoAlert,
        AddBitwiseWordWidget
    },
    data(){
      return {
        x:0,
        y:0,
        height:400,
        showMenu:false,
        loading:false,
        info:'',
        dialog:false,
        showDismissibleAlert:false,
        tuples:[],
        streamData:[],
        update:false,
      }
    },
    created(){
        this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
    },
    beforeDestroy() {
        this.stream.close()
    },
    mounted(){
        this.stream.on("connect_error", (err) => {
            console.log(err.message);
        });
        this.stream.on('error',data=>{
            console.log(data) 
        })
        this.stream.on('parameter',data=>{
            if(data.constructor==String){
                data=JSON.parse(data)
            }
            this.val=data.data
            this.streamData.push(data)
            if(this.streamData.length>50){
                this.streamData.shift()
            }
        })
        this.stream.emit('getParam',this.widget.meta.data.parameter.param_id)
        if(this.widget.height==='fixed'){
              this.height=170
        }
        this.getWordTuples()
    },
    computed:{
        latestVal(){
            if( this.streamData.length>0){
                return Number(this.streamData[this.streamData.length-1]['data'])
            }
            return 0
        }
    },
    methods:{
        getBitState:BitwiseOperationUtillity.getBitState,
        show (e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
        },
        getWordTuples(){
            this.loading=true
            axios.post(this.$store.state.api+'getWordTuplesByID',{word_id:this.widget.meta.data.word},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.tuples=response.data.data
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        getColorClass(status) {
            switch(status){
                case "High":
                    if(this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data && this.widget.meta.data.highStatusColor){
                        return this.widget.meta.data.highStatusColor
                    }
                break
                case "Low":
                    if(this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data && this.widget.meta.data.lowStatusColor){
                        return this.widget.meta.data.lowStatusColor
                    }
                break
            }
      },
        deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.dialog=false
                    this.loading=false
                    this.$emit('update')
                }
            })
            .catch(err=>{
                console.error(err)
                this.loading=false
            });
        },
    }
}
</script>
<template>
    <div>
        <div v-if="diff>1" class="px-4 py-2 red lighten-1 white--text text-center font-weight-medium">
            <span class="">
                Payment due on {{ showString() }}. Your access is Limited, Please contact your accounts team to clear it to avoid account suspension.
            </span> 
        </div>
        <div v-if="diff<0 && diff>-15" class="px-4 py-2 indigo lighten-1 white--text text-center font-weight-medium">
            <span class="">
                Payment due {{ showTimeLeft() }}. Please clear dues in advance to avoid any issues.
            </span> 
        </div>

    </div>

</template>
<script>
import moment from 'moment'
export default{
    name:'PaymentDueReminder',
    data(){
        return {

        }
    },
    computed:{
        
        diff(){
            if(this.$store.state.organisation && this.$store.state.organisation.payment_due_date){
                let diff=moment().diff(moment(this.$store.state.organisation.payment_due_date),'days')
                return diff
                
            }
            return null
        }
    },
    methods:{
        showString(){
            if(this.$store.state.organisation && this.$store.state.organisation.payment_due_date){
                // return moment().from(moment(this.$store.state.organisation.payment_due_date))
                // return moment(this.$store.state.organisation.payment_due_date).toNow()
                return moment(this.$store.state.organisation.payment_due_date).format("LL")
                
            }
            return null
        },
        showTimeLeft(){
            if(this.$store.state.organisation && this.$store.state.organisation.payment_due_date){
                // return moment().from(moment(this.$store.state.organisation.payment_due_date))
                // return moment(this.$store.state.organisation.payment_due_date).toNow()
                return moment().to(moment(this.$store.state.organisation.payment_due_date))
                
            }
            return null

        }
    }
}
</script>
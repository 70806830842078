<template>
  
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        
      <v-btn
      v-bind="attrs"
          v-on="on"
                rounded
             :dark="$store.getters.getColorPalette().isDark"
                
                small
                color="teal accent-3">
                    
                     
                     <v-icon>
                        mdi-plus
                    </v-icon> 
                </v-btn>
      </template>
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-toolbar
        flat outlined
        :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
          :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.getters.getTextMap().add_widget}}
</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
             :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}

            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <AddDynamicTimeframedWidget :view_id="view_id" :seq="seq" v-on:close="onClose" v-on:success="$emit('success')" />
      </v-card>
      
    </v-dialog>
     
</template>

<script>
import AddDynamicTimeframedWidget from '@/components/crud_components/AddDynamicTimeframedWidget'
  export default {
      name:'AddDynamicWidgetModal',
      props:['view_id','seq'],
      components:{
        AddDynamicTimeframedWidget
      },
    data () {
      return {
        dialog: false
      }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
            }
        }

    }
</script>
<template>
    <v-container fluid  :style="{background: $store.getters.getColorPalette().background2ColorCode}">  
        <div>
            <v-row v-if="this.eq_id">
                <v-col>
                    <v-btn-toggle :dark="$store.getters.getColorPalette().isDark"  v-model="days_toggle" mandatory>
                        <v-btn small>365 Days</v-btn>
                        <v-btn small>180 Days</v-btn>
                        <v-btn small>30 Days</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
        </div>
        <div ref="vis"></div>
    </v-container>
</template>
<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'

export default {
    name:'ParamEquationBasedHeatMap',
    props:['eq_id','label','dimensions','height','width'],
    components:{
    },
    data(){
        return {
            graphData:{},
            week:{},
            date_week:{},
            days_toggle:2,
            positive_usage:{},
            negative_usage:{},
            usage:{},
            z_axis_data:null,
            positive_params:[],
            negative_params:[],
            week_x_axis:[],
            dayMap:{
                0:"Sunday",
                1:"Monday",
                2:"Tuesday",
                3:"Wednesday",
                4:"Thursday",
                5:"Friday",
                6:"Saturday",
            },
            daysOption:[
                {label:'364 Days',value:364},
                {label:'182 Days',value:182},
                {label:'35 Days',value:35},
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            layout : {
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:false,
                autosize: true,
                xaxis: {
                    tickangle: -60,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                    uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted(){
        if(this.eq_id){
            this.handleDaysToggle('getParamsBasedOnEquation')
        }
    },
    methods:{
        show (e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },

        handleDaysToggle(functionName){
            let callFunction={'getParamsBasedOnEquation':this.getParamsBasedOnEquation(),'getPositiveAndNegativeParamUsage':this.getPositiveAndNegativeParamUsage()}
            switch(this.days_toggle){
                    case 0:
                    this.days=364
                    break;
                case 1:
                    this.days=182
                    break;
                case 2:
                    this.days=35
                    break;
                }
            callFunction[functionName]
        },

        async getParamsBasedOnEquation(){
            try{
                this.positive_params=[]
                this.negative_params=[]
                let eq_response= await axios.post(this.$store.state.api+'getParamEquationsById',{eq_id:this.eq_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                if(eq_response.data.status==='success'){
                    this.positive_params=eq_response.data.param_equation.positive
                    this.negative_params=eq_response.data.param_equation.negative
                }else{
                    this.info=eq_response.data.msg
                    this.showDismissibleAlert=true
                }
                this.getPositiveAndNegativeParamUsage()
            }catch(error){
                console.error(error)
            }
        },

        async getPositiveAndNegativeParamUsage(){
            let date = moment().subtract(this.days, 'days').format('YYYYMMDD');
            let data_for_day = new Date(moment(date, "YYYYMMDD").format('YYYY-MM-DD'));
            let day = data_for_day.getDay();
            this.days = this.days + (day === 0 ? 6 : day - 1);
            date = moment().subtract(this.days, 'days').format('YYYYMMDD');

            let positive_payload={
                parameters:this.positive_params,
                from_date:date,
            }
            let negative_payload={
                parameters:this.negative_params,
                from_date:date,
            }
            this.positive_usage={}
            this.negative_usage={}
            try{
                if(this.negative_params && this.negative_params.length>0){
                    let negative_response= await axios.post(this.$store.state.api+'getTotalParamUsageDataBetweenDate',negative_payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(negative_response.data.status==='success'){
                        for(let i of negative_response.data.data){
                            this.negative_usage[i.date]=i.usage
                        }
                    }else{
                        this.info=negative_response.data.msg
                        this.showDismissibleAlert=true
                    }
                }
                if(this.positive_params && this.positive_params.length>0){
                    let positive_response= await axios.post(this.$store.state.api+'getTotalParamUsageDataBetweenDate',positive_payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(positive_response.data.status==='success'){
                        for(let i of positive_response.data.data){
                            this.positive_usage[i.date]=i.usage
                        }
                    }else{
                        this.info=positive_response.data.msg
                        this.showDismissibleAlert=true
                    }
                }
                this.getEQSolution()
            }catch(err){
                console.error(err)
            }  
        },

        async getEQSolution(){
            for (let i of Object.keys(this.positive_usage)){
                this.usage[i]=(this.positive_usage[i]?this.positive_usage[i]:0-this.negative_usage[i]?this.negative_usage[i]:0)
            }
            this.getData()
        },

        async getData(){
            if(this.height==='fixed'){
            this.layout.height=145
            this.layout.margin={
                t:40,
                b:60,
                l:80,
                r:40
                }
            }
            if(this.dimensions){
                this.layout.height=this.dimensions[0]['height'] 
                this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
                this.layout.margin={
                    t:40,
                    b:40,
                    l:80,
                    r:40
                }
            }
            let weeks = 0
            switch(this.days_toggle){
                case 0:
                    weeks = Math.round(364/7)
                    break;
                case 1:
                    weeks = Math.round(182/7)
                    break;
                case 2:
                    weeks = Math.round(35/7)
                    break;
            }
            let date = moment().subtract(this.days,'days').format('YYYYMMDD')
            let count = 1
            this.week_x_axis = []
            this.week= {'Monday':[],'Tuesday':[],'Wednesday':[],'Thursday':[],'Friday':[],'Saturday':[],'Sunday':[],}
            this.date_week= {'Monday':[],'Tuesday':[],'Wednesday':[],'Thursday':[],'Friday':[],'Saturday':[],'Sunday':[],}
            for(let i=0; i<weeks; i++){
                for(let j=0; j<7; j++){
                    let data_for_day = new Date(moment(date,"YYYYMMDD").format('YYYY-MM-DD'))
                    let day = data_for_day.getDay()
                    this.week[this.dayMap[day]].push(this.usage[date]!= undefined || Object.values(this.usage).length!=0 ?this.usage[date]:null)
                    this.date_week[this.dayMap[day]].push(moment(date,"YYYYMMDD").format('YYYY-MM-DD'))
                    date = moment().subtract((this.days-(count)),'days').format('YYYYMMDD') 
                    count = count+1        
                }
                this.week_x_axis.push('W'+(i+1))
                }
            this.createHeatMap()
        },

        createHeatMap(){
            this.layout['title']= this.label?this.label:''
            let data = [{
                z:Object.values(this.week),
                y: Object.keys(this.week),
                x: this.week_x_axis,
                type: 'heatmap',
                colorscale:this.$store.getters.getColorPalette().colorTypesCodeArrayHeatMap,
                text: Object.values(this.date_week),
                textfont:{
                    color:this.$store.getters.getColorPalette().accentName
                }
            }];
            if(this.$refs.vis){
                Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
            }
        },
    },
    watch:{
        widget:{
            handler(){
                this.handleDaysToggle('getParamsBasedOnEquation')
            },
            deep:true
        },
        days_toggle:{
            handler(){
                this.handleDaysToggle('getPositiveAndNegativeParamUsage')
            },
            deep:true
        }
    }
}
</script>
<style scoped>
    .limit_height {
        max-height:400px;
    }
</style>
<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">  
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
        <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-list-item @click="update=true">
              <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="deleteWidget">
              <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
              <v-list-item-icon>
                <v-icon color="red" small>mdi-trash-can</v-icon>
              </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{widget.label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <AddTargetBasedBarTrendWidget :widget="widget" :disableTimeframe="disableTimeframe" :idealValue="idealValue" :idealLabel="idealLabel" v-on:close="update=false" v-on:success="$emit('update')" />
        </v-card>
      </v-dialog>
      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
      <v-row v-if="widget.meta.data" no-gutters>
        <v-col  align="right">
            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
              <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn>
              <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
            </v-btn-toggle>
        </v-col>
      </v-row>
      <div v-if="widget.meta && widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'150px',}:{}]" ></div>
      <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
        <v-icon small>mdi-hail</v-icon>
        <h5>{{$store.getters.getTextMap().not_configured}}</h5>
      </div>
    </v-container>
  </template>
<script>
import Plotly from 'plotly.js'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import AddTargetBasedBarTrendWidget from '@/components/crud_components/widgets/AddTargetBasedBarTrendWidget'
import {io} from 'socket.io-client'
import axios from 'axios'
export default {
name:"TargetBasedBarTrendWidget",
components:{
    AddTargetBasedBarTrendWidget,
        InfoAlert,
},
created(){
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
},
beforeDestroy() {
    this.stream.close()
},
props:{
    widget:{
    type: Object,
    required:true
    },
    timeframe:{
    type: String,
    },
    edit:{
    type:Boolean,
    required:true
    },
    disableTimeframe:{
    type:Boolean,
    required:true
    },
    idealValue:Number,
    idealLabel:String,
},
data(){
    return {
    showDialog:false,
    x:0,
    y:0,
    showMenu:false,
    loading:false,
    info:'',
    showDismissibleAlert:false,
    update:false,
    graphDataTemp:[],
    tempDataFinale:[],
    targetDetails:[],
    multiParamUsage:0,
    getDataFlag:false,
    graphData:{},
    multiParamGraphData:{},
    targetLineData:{},
    benchmarkLine:{},
    graphType:'bar',
    multiPgraphType:'bar',
    barMode:'stack',
    layout : {
        barmode: 'stack',
        font:{
            color:this.$store.getters.getColorPalette().accentCode,
            },
        plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
        paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
        autosize: true,
        hoverlabel:{
        namelength:-1,
        },
        xaxis: {
            tickangle: -12,
            zeroline: false,
            showgrid: true,
            showticklabels: true,
            color:this.$store.getters.getColorPalette().accentCode,
        },       
        yaxis: {
            range: [0, this.benchmarkYValue + 100],
        showticklabels: true,
        color:this.$store.getters.getColorPalette().accentCode,
        },
        uniformtext: {
        "mode": "hide",
        "minsize": 1
        },
    }
    }
},
mounted(){
    this.getTargetDetails()
    this.getData()
    this.stream.on("connect_error", (err) => {console.error(err.message)});
    this.stream.on('error',data=>{console.error(data)})
    this.stream.on('param',data=>{
    if(data.constructor==String){
        data=JSON.parse(data)
    }
    for(let i of this.widget.meta.data){
        if(Array.isArray(i.param) && i.param.length>1){
        this.multiParamsStream(i,data)
        }else{
        this.singleParamStream(data)
        }
    }
    }) 
},
methods:{
    show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
    },
    singleParamStream(data){
    if(!this.graphData[data['param_id']]){
        this.graphData[data.param_id]={
        'x':[],
        'y':[],
        type: this.graphType==1?'line':'bar',
        opacity:0.9,
        marker: {
            line: {
            color: this.$store.getters.getColorPalette().accentCode,
            width: 0.3
            },
        }
        }
    }
    if(moment().unix()-Number(data.timestamp)<80000){
        this.graphData[data['param_id']]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'))
        this.graphData[data['param_id']]['y'].push(Number(data.data))
    }
    if(this.graphData[data['param_id']]['x'].length>1400){
        this.graphData[data['param_id']]['x'].shift()
        this.graphData[data['param_id']]['y'].shift()
    }
    this.addIdealValueToGraph()
    },
    multiParamsStream(i,data){
    if (!this.multiParamGraphData[i.label]) {
        this.multiParamGraphData[i.label] = {
        x: [],
        y: [],
        type: this.graphType == 1 ? 'line' : 'bar',
        opacity: 0.9,
        showlegend: true,
        name:i.label,
        marker: {
            line: {
            color: this.$store.getters.getColorPalette().accentCode,
            width: 0.3
            }
        }
        };
    }
    for (let j of i.param) {
        if (data.param_id === j) {
        this.multiParamUsage = this.multiParamUsage + Number(data.data);
        }
    }
    if (moment().unix() - Number(data.timestamp) < 80000) {
        this.multiParamGraphData[i.label]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'));
        this.multiParamGraphData[i.label]['y'].push(Number(this.multiParamUsage));
    }
    if (this.multiParamGraphData[i.label]['x'].length > 1400) {
        this.multiParamGraphData[i.label]['x'].shift();
        this.multiParamGraphData[i.label]['y'].shift();
    }
    this.addIdealValueToGraph();
    },
    singleParamInit(){
    if(this.widget && this.widget.meta && this.widget.meta.data){
        let type=this.graphType==1?'line':'bar'
        this.graphData={}
        for(let i of this.widget.meta.data ){
        this.graphData[i.param]={
            x: [],
            y: [],
            name:i.label,
            type: type,
            opacity:0.9,
            showlegend: true,
            marker: {
            line: {
                color: this.$store.getters.getColorPalette().accentCode,
                width: 0.3
                },
            }
        }
        if(this.widget.meta && this.widget.meta.time_frame=='rtd'){
            if(Array.isArray(i.param)){
            for(let p of i.param){
                this.stream.emit('getParam',p)
            }
            }else{
                this.stream.emit('getParam',i.param)
            }
        }else{
            if(Array.isArray(i.param)){
            for(let p of i.param){
                this.unsubscribeParam(p)
            }
            }else{
            this.unsubscribeParam(i.param)
            }
        }
        }
    }
    },
    multiParamsinit(){
    if(this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame!='rtd'){
        let type=this.graphType==1?'line':'bar'
        this.multiParamGraphData={}
        for(let i of this.widget.meta.data ){
        this.multiParamGraphData[i.label]={
            x: [],
            y: [],
            name:i.label,
            type: type,
            opacity:0.9,
            showlegend: true,
            marker: {
            line: {
                color: this.$store.getters.getColorPalette().accentCode,
                width: 0.3
                },
            }
        }
        if(this.widget.meta && this.widget.meta.time_frame=='rtd'){
            if(Array.isArray(i.param)){
            for(let p of i.param){
                this.stream.emit('getParam',p)
            }
            }else{
                this.stream.emit('getParam',i.param)
            }
        }else{
            if(Array.isArray(i.param)){
            for(let p of i.param){
                this.unsubscribeParam(p)
            }
            }else{
            this.unsubscribeParam(i.param)
            }
        }
        }
    }else{
        this.addIdealValueToGraph()
    }
    },
    addIdealValueToGraph(){
        this.layout['title']= this.widget.label
        if(this.widget.meta && this.widget.meta.legend){
            this.layout['showlegend']=true
        }
        if(this.widget.meta && this.widget.meta.barmode){
            this.layout['barmode']=this.widget.meta.barmode
        }else{
            this.layout['barmode']='group'
        }
        let type=this.graphType==1?'line':'bar'
        for(let i in this.graphData){
            if(i!=='benchmarkLine'){

                this.graphData[i]['type']=type
            }
        }
        if (this.widget.meta.showIdeal ) {
            // let from_date;
            // if(this.timeframe && this.disableTimeframe){
            // switch (this.timeframe) {
            //     case '7':
            //         from_date = moment().subtract(7,'days');
            //         break;
            //     case '30':
            //         from_date = moment().subtract(30,'days');
            //         break;
            //     case '60':
            //         from_date = moment().subtract(60,'days');
            //         break;    
            //     case '90':
            //         from_date = moment().subtract(90,'days');
            //         break;
            //     case '180':
            //         from_date = moment().subtract(180,'days');
            //         break;
            //     case '365':
            //         from_date = moment().subtract(365,'days');
            //         break;
            //     case 'yesterday':
            //         from_date = moment().subtract(1,'days');
            //         break;
            //     case 'ytd':
            //         from_date = moment().startOf('year');
            //         break;
            //     default:
            //         from_date = moment().subtract(1, 'days').startOf('month');
            //         break;
            // }
            // }else{
            // switch (this.widget.meta.time_frame) {
            //     case 'ytd':
            //         from_date = moment().startOf('year');
            //         break;
            //     case 'mtd':
            //         from_date = moment().startOf('month');
            //         break;
            //     case 'yesterday':
            //         from_date = moment().subtract(1, 'days');
            //         break;
            //     case 'rtd':
            //         from_date=moment().startOf('day');
            //         break;
            //     default:
            //         from_date = moment().subtract(parseInt(this.widget.meta.time_frame), 'days');
            // }
            // }
            // this.graphData['benchmarkLine'] = {
            // type: 'line',
            // x: [from_date.format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
            // y: [this.widget.meta.idealValue ? this.widget.meta.idealValue : 0, this.widget.meta.idealValue ? this.widget.meta.idealValue : 0],
            // line: {
            //     color: 'red',
            //     width: 2,
            //     dash: 'dash',
            // },
            // showlegend: true,
            // name: this.widget.meta.idealLabel ? this.widget.meta.idealLabel : '',
            // };
        }
        for(let k of Object.keys(this.multiParamGraphData)){
            this.graphData[k]=this.multiParamGraphData[k]
        }
        Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
    },
    createSingleParamChart(){
        this.singleParamInit()
        if(this.widget.meta){
            for(let i of this.tempDataFinale){
                if(!(i.param_id in this.graphData)){
                let type=this.graphType==1?'line':'bar'
                this.graphData[i.param_id]={
                x: [],
                y: [],
                name:i.label,
                type:type,
                opacity:0.9,
                marker: {
                    line: {
                    color: this.$store.getters.getColorPalette().accentCode,
                    width: 1
                    },
                    }
                }
                }
                this.graphData[i.param_id]['x'].push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                this.graphData[i.param_id]['y'].push(i.data)
            }
        }
        // this.addIdealValueToGraph()
        this.createTargetLine()
    },
    createMultipleParamChart(){
        this.multiParamsinit()
        if(this.widget && this.widget.meta){
            for(let i of this.tempDataFinale){
                if(!(i.param in this.multiParamGraphData)){
                let type=this.graphType==1?'line':'bar'
                    this.multiParamGraphData[i.param]={
                    x: [],
                    y: [],
                    name:i.label,
                    type:type,
                    opacity:0.9,
                    marker: {
                    line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                    }
                }
                }
                if(this.widget.meta.time_frame!='rtd'){
                for(let j of i.data){
                    this.multiParamGraphData[i.param]['x'].push(moment(j.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.multiParamGraphData[i.param]['y'].push(j.usage)
                }
                }
            }
            // this.addIdealValueToGraph()
            this.createTargetLine()
        }
    },
    unsubscribeParam(param){
        this.stream.emit('removeParam',param)
    },
    createTargetLine(){
        // console.log(this.multiParamGraphData)
        let dates=[]
        let unique_dates=new Set([])
        for(let v of Object.values(this.multiParamGraphData)){
            for(let date of v['x']){
                unique_dates.add(date)
            }
        }
        dates=[...unique_dates];
        const array3 = [];

        for(let date of dates){
            let momentDate = moment(date);
            for(let j of this.targetDetails){
                if(momentDate.day() + 1 === j.day_of_week && momentDate.month() + 1 === j.month){
                    const formattedDate = moment(date).format('YYYY-MM-DD');
                    let newObj = {};
                    newObj={'date':formattedDate,'target_val':j.target_val}
                    // newObj[formattedDate] = target_val;
                    array3.push(newObj);
                }
            }
        }
        // this.targetDetails.forEach(obj => {
        //     const { day_of_week, month, target_val } = obj;
        //     const date = dates.find(date => {
        //         const momentDate = moment(date);
        //         return momentDate.day() + 1 === day_of_week && momentDate.month() + 1 === month;
        //     });
        //     if (date) {
        //         const formattedDate = moment(date).format('YYYY-MM-DD');
        //         let newObj = {};
        //         newObj={'date':formattedDate,'target_val':target_val}
        //         // newObj[formattedDate] = target_val;
        //         array3.push(newObj);
        //     }
        // });
        this.graphData['benchmarkLine'] = {
            type: 'line',
            x: [],
            y: [],
            line: {
                color: 'red',
                width: 2,
                dash: 'dash',
            },
            showlegend: true,
            name:  this.widget&& this.widget.meta && this.widget.meta.targetLabel?this.widget.meta.targetLabel:'Target',
            };
            for(let i of array3){
                this.graphData['benchmarkLine']['x'].push(i.date)
                this.graphData['benchmarkLine']['y'].push(i.target_val)
            }
            this.addIdealValueToGraph()
    },
    async getTargetDetails(){
        try{
            if(this.widget&&this.widget.meta && this.widget.meta.target){
                this.loading=true
                let response= await axios.post(this.$store.state.api+'getTargetDataByID',{target_id:this.widget.meta.target},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(response.data.status=='success'){
                        this.targetDetails=response.data.data
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
            }
        }catch(err){
            this.info=err
            this.showDismissibleAlert=true
        } 
    },
    async getData(){
        if(this.getDataFlag){
            return
        }
        this.getDataFlag=true
        try{
            if(this.widget.meta.graphType){
                this.graphType=this.widget.meta.graphType
            }
            if(this.widget.height==='fixed'){
                this.layout.height=160
                this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
                this.layout.margin={t:40,b:40,l:40,r:40}
            }
            this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
            this.graphData={}
            this.multiParamGraphData={}
            let from_date=moment().subtract(1, 'days');
            if(this.widget.meta && this.widget.meta.time_frame!='rtd'){
                if(this.timeframe && this.disableTimeframe){
                    switch (this.timeframe) {
                        case '7':
                            from_date = moment().subtract(7,'days').format('YYYYMMDD');
                            break;
                        case '30':
                            from_date = moment().subtract(30,'days').format('YYYYMMDD');
                            break;
                        case '60':
                            from_date = moment().subtract(60,'days').format('YYYYMMDD');
                            break;
                        case '90':
                            from_date = moment().subtract(90,'days').format('YYYYMMDD');
                            break;
                        case '180':
                            from_date = moment().subtract(180,'days').format('YYYYMMDD');
                            break;
                        case '365':
                            from_date = moment().subtract(365,'days').format('YYYYMMDD');
                            break;
                        case 'yesterday':
                            from_date = moment().subtract(1,'days').format('YYYYMMDD');
                            break;
                        case 'ytd':
                            from_date = moment().startOf('year').format('YYYYMMDD');
                            break;
                        default:
                            from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                            break;
                    }
                }else{
                    if(this.widget.meta && this.widget.meta.time_frame){
                        switch(this.widget.meta.time_frame){
                        case 'ytd':
                            from_date=from_date.startOf('year').format('YYYYMMDD')
                            break;
                        case 'yesterday':
                            from_date = moment().subtract(1, 'days');
                            break;
                        case 'mtd':
                            from_date=from_date.subtract(1, 'days').startOf('month').format('YYYYMMDD')
                            break;
                        default:
                            from_date=moment().subtract(parseInt(this.widget.meta.time_frame),'days').format('YYYYMMDD')
                        }
                    }else{
                        from_date=moment().subtract(30,'days').format('YYYYMMDD')
                    }
                }
                let to_date=moment().format('YYYYMMDD')
                if(this.widget && this.widget.meta && this.widget.meta.data){
                    this.tempDataFinale=[]
                    for(let i of this.widget.meta.data){
                    let payload={
                        from_date:from_date,
                        to_date:to_date
                        }
                    if(Array.isArray(i.param)){
                        payload['parameters']=i.param
                    }else{
                        payload['parameters']=[i.param]
                    }
                    let response= await axios.post(this.$store.state.api+'getTotalParamUsageDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(response.data.status==='success'){
                        let temp={}
                        temp['param']=i.label 
                        temp['data']=response.data.data
                        this.tempDataFinale.push(temp)
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    }
                    this.createMultipleParamChart()
                    this.loading=false
                }  
            }else{
                if(this.widget && this.widget.meta && this.widget.meta.data){
                    this.tempDataFinale=[]
                    let payload={
                        params:[],
                        from_timestamp:moment().startOf('day').unix(),
                        }
                    for(let i of this.widget.meta.data){
                    if(Array.isArray(i.param) && i.param.length>1){
                        this.addIdealValueToGraph()
                    }else{
                        payload.params.push(i.param)
                        let response= await axios.post(this.$store.state.api+'getRawDataByParamsBetweenTimestamp',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                        if(response.data.status==='success'){
                        this.tempDataFinale=response.data.data
                        this.createSingleParamChart()
                        }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                        }
                    }
                }
                this.loading=false
                }
            }
        }catch(err){
            this.info=err
            this.showDismissibleAlert=true
        }
        this.getDataFlag=false
    },

    deleteWidget(){
        this.loading=true
        axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status==='success'){
                this.dialog=false
                    this.loading=false
                    this.$emit('update')
            }
        }).catch(err=>{
            console.error(err)
            this.loading=false
        });
    },
    cancel(){
        this.showDialog=false
    },
    confirm(){
        this.showDialog=false
    },
},
watch:{
    widget:{
        handler(){
            this.getData()
        },
        deep:true
    },
    timeframe:{
        handler(){
                this.getData()
            },
            deep:true
        },
    graphType: function (){
        this.createMultipleParamChart()
    },
    // idealValue: function(newIdealValue) {
    //     this.benchmarkLine.y = [newIdealValue ? newIdealValue : 0];
    //     this.createMultipleParamChart();
    // },
    multiParamGraphData(){
        this.createTargetLine()
    }
}
}
</script>
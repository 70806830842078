<template>
    <span>
        <v-menu :max-width="300"  bottom left :max-height="550">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon small :color="$store.getters.getColorPalette().accentCode" v-bind="attrs" v-on="on"><v-icon small  :color="$store.getters.getColorPalette().topBarAccent">mdi-cog-outline</v-icon></v-btn>
            </template>
            <v-toolbar style="width:300px;" dense flat :dark="$store.getters.getColorPalette().isDarkTheme" :color="$store.getters.getColorPalette().background2ColorCode">
                <!-- <span :style="{color:$store.getters.getColorPalette().accentCode}"><v-avatar :size="18" :dark="$store.getters.getColorPalette().isDarkTheme" :color="$store.getters.getColorPalette().backgroundColor"><v-icon small>mdi-account</v-icon></v-avatar>{{ $store.state.user?$store.state.user.name:'' }}</span> -->
                <!-- <v-avatar :size="20" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="$store.getters.getColorPalette().backgroundColorName +' mx-1 px-0'"><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-account</v-icon></v-avatar><span :style="{color:$store.getters.getColorPalette().accentCode}"><h3>Welcome {{ $store.state.user?$store.state.user.name:'' }}</h3></span> -->
                <span :style="{color:$store.getters.getColorPalette().accentCode}"><h3>{{$store.getters.getTextMap().enture_settings}}</h3></span>
            </v-toolbar>
            <v-list :color="$store.getters.getColorPalette().backgroundColorCode"
               :dark="$store.getters.getColorPalette().isDark">
                <!-- <v-list-item @click="toggleUser">
                    <v-spacer></v-spacer>Turn {{$store.state.settingMode?'Off':'On'}} Setting View  <v-icon class="pl-1 pb-1" :color="$store.state.settingMode?'red':'teal'" small :dark="$store.getters.getColorPalette().isDark">
                        mdi-{{$store.state.settingMode?'pencil-off':'pencil'}}</v-icon>
                </v-list-item> -->
                <!-- <span><v-subheader>Settings</v-subheader></span>
                <v-divider></v-divider> -->
                <v-list-item v-for="item in settingsMenuLinks" :key="item.link" router :to="item.link" :active-class="$store.getters.getColorPalette().foregroundColorName " >
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <span><v-subheader>{{$store.getters.getTextMap().configuration}}</v-subheader></span>
                <v-list-item v-for="item in configuartionMenuLinks" :key="item.link" router :to="item.link" :active-class="$store.getters.getColorPalette().foregroundColorName " >
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode">mdi-{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="$store.state.user  && $store.getters.getAccessByLevels(['device']) "  @click="$store.dispatch('refresh')" link>
                    <v-list-item-action>
                        <v-icon :color="$store.getters.getColorPalette().accentCode" >mdi-atom-variant
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content >
                        <v-list-item-title :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().refresh}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    
                    <v-list-item-content>
                        <v-btn 
                        
                    :dark="$store.getters.getColorPalette().isDark"
                    text
                    small
                    
                    :color="$store.state.clientSafety?'green':'red'"
                    @click="$store.commit('toggleClientSafety')"  
                >
                    {{$store.state.clientSafety?$store.getters.getTextMap().turn_off:$store.getters.getTextMap().turn_on}} {{$store.getters.getTextMap().client_safety}}</v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </span>
</template>

<script>
//import ToggleUserView from '@/components/ToggleUserView'
export default {
 name: 'SettingList',
 data () {
      return {
        dialog: false
      }
    },
 components: {
    //ToggleUserView,
    // InfoComponent 
  },
 methods: {
    onClose(){
            this.dialog=false
        }

},
computed:{
    settingsMenuLinks(){
      let op=[]
      if (this.$store.state.loggedIn){
        // for(let i of this.$store.state.settingsMenuLinks){
        //   if(this.$store.getters.getAccessByLevels(i.levels)){
        //     op.push(i)
        //   }
        // }
        return this.$store.getters.getSettingsMenuLinks()
      }

        return op
      
    },
    configuartionMenuLinks(){
      let op=[]
      if (this.$store.state.loggedIn){
        // for(let i of this.$store.state.configurationMenuLinks){
        //   if(this.$store.getters.getAccessByLevels(i.levels)){
        //     op.push(i)
        //   }
        // }
        return this.$store.getters.getConfigurationMenuLinks()
      }

        return op
      
    },
   
  },
}
</script>
<style>
.settings{
    border-color: black;
}
</style>
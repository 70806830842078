<template>
    <v-container fluid @contextmenu="show"  :style="{'background-color':widget.background_color}" >  
         <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />

         <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true">
         <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" v-if="$store.state.settingMode && edit">
             <v-list-item @click="update=true">
                 <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                 <v-list-item-icon>
                 <v-icon small>mdi-pencil</v-icon>
                 </v-list-item-icon>
             </v-list-item>
             <v-list-item @click="deleteWidget">
                 <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                 <v-list-item-icon>
                 <v-icon color="red" small>mdi-trash-can</v-icon>
                 </v-list-item-icon>
             </v-list-item>
         </v-list>
         </v-menu>
         <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
             <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
             <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                 <v-icon>mdi-close</v-icon>
             </v-btn>
             <v-toolbar-title>{{widget.label}}</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-toolbar-items>
                 <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}</v-btn>
             </v-toolbar-items>
             </v-toolbar>
                   <AddConsumptionGaugeWidget :widget="widget" :disableTimeframe="disableTimeframe" v-on:close="update=false" v-on:success="$emit('update')" />
         </v-card>
         </v-dialog>
     <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
 <span><strong>{{ widget.label }}</strong></span>
 <div v-if="widget.meta.params && widget.meta.params.length>0"  ref="vis" :style="[widget.height === 'fixed' ? {'height':'175px','text-align':'center'}:{}]">
 <!-- {{widget.label}}
 {{ widget.meta.label }}
 {{ widget.meta.unit }}
 {{val}} -->
 </div>
         <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
             <v-icon small>mdi-hail</v-icon>
             <h5>{{$store.getters.getTextMap().not_configured}}</h5>
         </div>
     </v-container>
 </template>
 
 <script>
   import Plotly from 'plotly.js'
   import moment from 'moment'
   import InfoAlert from '@/components/InfoAlert'
   import AddConsumptionGaugeWidget from '@/components/crud_components/widgets/AddConsumptionGaugeWidget'
   import DateUtils from '@/utillities/DateUtils'
   import axios from 'axios'
   import {io} from 'socket.io-client'
   export default {
   name:"ConsumptionGaugeWidget",
 
   components:{
 
         AddConsumptionGaugeWidget,
         InfoAlert,
         // DeleteConfirmation
     },
   props:['widget','edit','disableTimeframe','timeframe'],
   data(){
         return {
           showDialog:false,
             x:0,
             y:0,
             showMenu:false,
             loading:false,
             info:'',
             showDismissibleAlert:false,
             update:false,
             tdData:'-',
             stream:null,
             streamData:{},
             payload:{},
             dateUtils:DateUtils,
              layout : {
             autosize: true,
             margin: { t: 30, r:20, l: 0, b: 10 },
                   font:{
                         color:this.$store.getters.getColorPalette().accentCode,
                       },
                   plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                   paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                   showLegend:false,
                   }
         }
     },
     created(){
     //this.socket=io('https://localhost:80');
     this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
   },
   beforeDestroy() {
     //this.socket.close()
     //console.log('before destroy')
     this.stream.close()
   },
 
 
     mounted(){
          this.init()
     },
 
 
 
     computed:{
        
         val(){
             let dt='-'
             if(this.widget.meta && this.widget.meta.time_frame && this.widget.meta.time_frame=='rtd'){
                 dt=0
                 for(let i in this.streamData){
                    dt+=Number(this.streamData[i]['data'])
                    // console.log(i,this.streamData[i]['data'])
                 }
             }else if(this.widget.meta && this.widget.meta.time_frame && this.widget.meta.time_frame=='yesterday' && this.tdData){
              dt=Number(this.tdData)
             }else{
                dt=0
                for(let i in this.streamData){
                    dt+=Number(this.streamData[i]['data'])
                    // console.log(i,this.streamData[i]['data'])
                 }
                 dt+=Number(this.tdData)
                // console.log(this.tdData,"dt2222")
             }
                 //console.log("Computed property 'val' - dt:", dt);
                 //console.log(this.widget.meta.multiplier,"multi")
                 //console.log(Math.round(dt * Number(this.widget.meta.multiplier)*100)/100,"math")
         return isNaN(dt) ? '-' : Math.round(dt * 100) / 100;
        }
     },
 
     methods:{
         show (e) {
             e.preventDefault()
             this.showMenu = false
             this.x = e.clientX
             this.y = e.clientY
             if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
       },
 
       init(){
         this.stream.on("connect_error", (err) => {
                   console.log(err.message); // prints the message associated with the error
            });
       this.stream.on('error',data=>{
       console.log(data)
       
     })
     this.stream.on('ack',data=>{
       console.log(data)
       
     })
     this.stream.on('paramConsumption',data=>{
       if(data.constructor==String){
         data=JSON.parse(data)
       }
       //this.val=data.data
    //  console.log(data)
     this.streamData[data['param_id']]=data
     this.streamData=Object.assign({},this.streamData)
       
       //this.calculateVal(); 
     })
             if(this.widget && this.widget.meta && this.widget.meta.params){ 
               //  console.log(this.widget,"l")
               for(let param of this.widget.meta.params){
                this.stream.emit('getParamConsumption',param)
               }
                 if(this.widget.meta.time_frame && this.widget.meta.time_frame!='rtd'){
                     this.getData()
                    }else if(this.timeframe && this.disableTimeframe){
                     this.getData()

                 }
         }
       },
       createChart(){
  const data = [
                     {
                  domain: { x: [0, 1], y: [0.1, 1] },
     value:this.val,
    //  title: { text: this.widget.label },
     type: "indicator",
     mode: "number+gauge+delta",
     delta: { 
                 decreasing:{
                   color:this.widget.meta.negativeHealthy?'green':'red'
                 },
                 increasing:{
                    color:this.widget.meta.negativeHealthy?'red':'green'
                 },
                 reference:this.widget.meta.showTarget?this.widget.meta.targetValue:null
               },
                  gauge: { 
                    shape:this.widget.meta.linearGauge?"bullet":null,
                 axis:{
                   tickcolor:this.$store.getters.getColorPalette().accentCode,
                   range: [null, this.val<this.widget.meta.targetValue?Number(this.widget.meta.targetValue)*1.2:null]
                 },
                 bar:{
                   color:'blue',//this.$store.getters.getColorPalette().bulletGaugeBar,
                 },
                 // borderwidth:2,
                 bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                 bordercolor:this.$store.getters.getColorPalette().accentCode,
                 threshold: this.widget.meta.showTarget?{
        line: { color: "red", width: 4 },
        thickness: 0.75,
        value: this.widget.meta.targetValue
      }:null
               },
 
   }
 ];
 data[0].hoverinfo = "none";
  this.layout.margin = {
       t: 50,
       r: 20, 
       l: 0,  
       b: 10,
     };
 Plotly.newPlot(this.$refs.vis, data, this.layout,{displaylogo: false, responsive: true});
 //console.log(data,"data")
       },
         getData(){
              this.tdData=0
             let processing='usage'
             let from_date;
             if(this.timeframe && this.disableTimeframe){
          switch (this.timeframe) {
                
                case '7':
                  from_date = moment().subtract(7,'days').format('YYYYMMDD');
                  break;
                  
                case '30':
                  from_date = moment().subtract(30,'days').format('YYYYMMDD');
                  break;
                  
                  case '60':
                    from_date = moment().subtract(60,'days').format('YYYYMMDD');
                    break;
                    
                    case '90':
                      from_date = moment().subtract(90,'days').format('YYYYMMDD');
                      break;
                      
                      case '180':
                        from_date = moment().subtract(180,'days').format('YYYYMMDD');
                        break;
                        
                        case '365':
                          from_date = moment().subtract(365,'days').format('YYYYMMDD');
                      break;
              
                    case 'yesterday':
                    from_date = moment().subtract(1,'days').format('YYYYMMDD');
                      break;
                    case 'ytd':
                      from_date = moment().startOf('year').format('YYYYMMDD');
                      break;

                    default:
                      from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                      break;
                  }
        }else{
            if(this.widget.meta && this.widget.meta.time_frame && this.widget.meta.time_frame!='rtd'){

               //  from_date=moment().subtract(parseInt(this.widget.meta.time_frame),'days').format('YYYYMMDD')
                from_date=this.dateUtils.getFromDate(this.widget.meta.time_frame).format('YYYYMMDD')
            }else{
                from_date=moment().subtract(30,'days').format('YYYYMMDD')
            }
        }
             let to_date=moment().format('YYYYMMDD')
             if(this.widget && this.widget.meta ){
                 // let param=this.$store.getters.getParamById(this.widget.meta.param)
                 // console.log(param)
                 if(this.widget && this.widget.meta && this.widget.meta.processing ){
                     switch(this.widget.meta.processing){
                         case 'usage':
                             processing='usage'
                             break;
                         case 'avg':
                             processing='avg'
                             break;
                         case 'min':
                             processing='min'
                             break;
                         case 'max':
                             processing='max'
                             break;
                         default:
                             processing='usage'
                     }
                 }
               if(this.widget && this.widget.meta && this.widget.meta.params){
               this.createChart()  
                         }
                // console.log(this.widget.meta.params,"this.widget.meta.params")
                // console.log(from_date)
                 let payload={
                         parameters:this.widget.meta.params,
                         from_date:from_date,
                         to_date:to_date
                        
                     }
                    //  this.payload=payload
                    //  console.log(payload,"payload")
                 axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                 .then(response=>{
                      //console.log("Response from the API:", response.data);
                     if(response.data.status==='success'){
                        
                         this.tdData=Number(response.data.data[processing]).toFixed(2)
                            // console.log( this.tdData,"tdData")
                            // console.log("this.val after updating:", this.val,this.tdData);
                            this.createChart()  
                     }else{
                         this.info=response.data.msg
                         this.showDismissibleAlert=true
                     }
                         this.loading=false
                     })
                 .catch(err=>{
                     console.error(err)
                     this.loading=false});
                 }
         },
 
 
 
         deleteWidget(){
             this.loading=true
             axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
             .then(response=>{
                 if(response.data.status==='success'){
                     this.dialog=false
                     this.loading=false
                     this.$emit('update')
                 }
             })
             .catch(err=>{
                 console.error(err)
                 this.loading=false
             });
         },
         cancel(){
             this.showDialog=false
         },
         confirm(){
             this.showDialog=false
         },
 
     },
     watch:{
         widget:{
             handler(){
                 this.init()
                 
             },
             
             deep:true
         },
         timeframe:{
              handler(){
                    this.init()
                },
                deep:true
            },
         val: {
     handler: function () {
         this.createChart()
     },
    //  deep: true
   }
     },
     tdData:{
      handler:function(){
        this.createChart()
      }
     }
   }
 </script>
 
 <style>
 
 </style>
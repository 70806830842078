<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row>
          <v-col>
            <span :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">{{widget.widget_id?'Edit':'Add'}} Bus Bar Temprature Graph Widget</span>
          </v-col>
        </v-row> -->
        <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat>
            <!-- <v-card-title class="headline">{{widget?'Edit':'Add'}} Bus Bar Temprature Graph</v-card-title> -->
            <v-card-title class="sub-heading">{{widget.widget_id?'Edit':'Add'}} {{$store.getters.getTextMap().busbar_temperature_graph_widget}}</v-card-title>
            <v-card-text>
                <v-form ref="panelForm" v-model="valid">
                    <v-row>
                        <v-col align-self="center">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field v-if="!loading" dense outlined v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                        </v-col>
                        <v-col align-self="center">
                            <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                               :color="$store.getters.getColorPalette().inputtextColor"
                              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                v-if="!loading"
                                v-model="widgetForm.width"
                                :items="widthOptions"
                                :label="$store.getters.getTextMap().widget_width"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                                ></v-select>  
                        </v-col>
                        <v-col align-self="center">
                            <v-select
                                :dark="$store.getters.getColorPalette().isDark" 
                              :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                v-if="!loading"
                                v-model="widgetForm.height"
                                :items="heightOptions"
                                :label="$store.getters.getTextMap().widget_height"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                                ></v-select>  
                        </v-col>
                        <v-col align-self="center">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                        <v-col v-if="edit" align-self="center">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field
                                    v-if="!loading"
                                    v-model="widgetForm.seq"
                                    :counter="3"
                                    :label="$store.getters.getTextMap().sequence"
                                    dense
                                    outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                    </v-row>
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                    <v-row>
                        <v-col >
                            <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                               :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                v-if="!loading"
                                v-model="entureModules"
                                :items="entureModulesOptions"
                                :label="$store.getters.getTextMap().busbar_module"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                            ></v-select>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        </v-col>
                        <v-col>
                            <v-select
                               :dark="$store.getters.getColorPalette().isDark" 
                           :color="$store.getters.getColorPalette().inputtextColor"
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                v-if="!loading"
                                v-model="form.parameters"
                                :items="param_lists"
                                :label="$store.getters.getTextMap().param"
                                outlined
                                dense
                                item-text="label"
                                item-value="value"
                                multiple
                                required
                                :rules="[rules.required,rules.nonZeroLength]"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!-- <v-card-actions>
          

                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
            </v-card-actions> -->
        </v-card>
        <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex mt-2" align="right">
                <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn class="ml-2" v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'

export default {
    name:'AddBusBarLiveMonitoringWidget',
    props:['widget','seq'],
    components:{
        InfoAlert
    },
    mounted(){
      if(this.widget && this.widget.widget_id && this.widget.view_id){
        this.edit=true
        this.widgetForm=Object.assign({},this.widget)  
        this.api='updateWidget'    
        if(!(this.widgetForm.meta)){
            this.widgetForm.meta={data:[]}
            this.tableData=[]
        }else{
            this.tableData=this.widgetForm.meta.data
            let entureModuleIdForForm = []
            entureModuleIdForForm = this.entureModulesOptions
            this.form.parameters = this.widgetForm.meta.data[0].parameters
            for(let i of entureModuleIdForForm){
                if (i.value===this.widgetForm.meta.data[0].busbar_module_id){
                    this.entureModules = i.value
                }
            }
        }
      }else{
            if(this.widget.view_id){
                this.api='createWidgetForView'
                this.widgetForm=Object.assign({},this.widget)
                this.widgetForm.meta={data:[]}
                this.widgetForm.seq=this.seq?this.seq:0
                this.tableData=[]
            }
        }
    },
    data(){
        return {
            entureModules:null,
            edit:false,
            color:null,
            showDismissibleAlert:false,
            info:'',
            menu:null,
            loading:false,
            dev:null,
            valid:false,
            tableData:[],
            api:'createWidgetForView',
            form:{
            },
            param_lists:[
            'current_r',
                'current_y',
                'current_b',
                'current_n',
                'ambient_temp',
                'temp_r',
                'temp_y',
                'temp_b',
                'temp_n', 
                'allowable_temp_r',
                'allowable_temp_y',
                'allowable_temp_b',
                'allowable_temp_n',
                
            ],
            widgetForm:{},
                widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => ( !isNaN(v))|| 'It should be a valid number',
                v => (!!v && v.length <= 8 && v.length > 0) || 'Number must be less than 8 characters',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            rules:FormRules.rules,
             countRules:[
                v => !!v || 'Required',
                v => (v && v>1) || 'Number of Machine should be greater than 1'
            ],
        }
    },
    computed:{
        entureModulesOptions(){
            let op=[]
            let g=this.$store.state.entureModules
            for(let i of g){
                if(i.module_type==='busbar_temp'){
                    op.push( {value:i.module_id, label: i.name })
                }
            }
            return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.panelForm.validate()
            if(this.valid){
              this.loading=true
            if(!this.tableData){
                this.tableData=[]
              }
            this.tableData=[
                {label:this.form.name,busbar_module_id:this.entureModules,parameters:this.form.parameters}
            ]
            this.widgetForm.meta.data=this.tableData
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
             }}).then(response=>{
                    if(response.data.status==='success'){
                    this.loading=false
                    this.$emit('success')
                    this.$emit('close')
                    }else{
                        console.log('failed')
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
            }}).catch(err=>{
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
            })
            }else{
                console.log('validation failed')
            }
        },
        close(){
            this.$refs.panelForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close') 
        }
    },
    watch:{
    }
}
</script>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card class="my-1" v-if="showDelete" :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroun2ColorCode}" flat outlined> 
            <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                <v-card-title>{{'Delete Rules for '+deleteDetails.status}}</v-card-title>
                <v-row class="mx-1">
                    <v-col align="right">
                        <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showDelete = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-row>
                <v-col>
                    <DeleteMachineStatusRules :ruleDetails="deleteDetails" :machine="machine" v-on:delete="onDelete"/>
                </v-col>
            </v-row>
        </v-card>   
        <v-card v-if="!showDelete" :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroun2ColorCode}" flat outlined>    
            <v-row>
                <v-col>
                    <v-simple-table class="pb-5" :style="{background: 'transparent'}" >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        <strong>{{$store.getters.getTextMap().status}}</strong>
                                    </th>
                                    <th class="text-center">
                                        <strong>{{$store.getters.getTextMap().rules}} ({{$store.getters.getTextMap().wait_time}})</strong>
                                    </th>
                                    <!-- <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().wait_time}} ({{$store.getters.getTextMap().minute}})</strong>
                                    </th> -->
                                    <th class="text-center">
                                        <strong>{{$store.getters.getTextMap().action}}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.machine_status" v-for="item in tableData">
                                    <td class="text-center">{{ item.machine_status }}</td>
                                    <td class="text-center">{{ item.rules }}</td>
                                    <!-- <td>{{ item.wait_time }}</td> -->
                                    <td class="text-center"> <v-icon @click="deleteRule(item)" small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteMachineStatusRules from '@/components/crud_components/DeleteMachineStatusRules'
export default {
    name:'MachineStatusRulesList',
    props:['machine','success'],
    components:{
        InfoAlert,
        DeleteMachineStatusRules
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            rawData:[],
            tableData:[],
            showDelete:false,
            deleteDetails:{}
        }
    },
    mounted(){
        this.fetchMachineStatusRules()
    },
    methods:{
        createTable(){
            let temp={}
            this.tableData=[]
            for (let i of this.rawData) {
                if (!temp[i.machine_status]) {
                    temp[i.machine_status] = `${i.tag_type}${i.operator}${i.operand} (${i.wait_time})`;
                } else {
                    temp[i.machine_status] += ` and ${i.tag_type}${i.operator}${i.operand} (${i.wait_time})`;
                }
            }
            for (let status in temp) {
                this.tableData.push({
                    machine_status: status,
                    rules: temp[status]
                });
            }
        },
        fetchMachineStatusRules(){
            axios.post(this.$store.state.api + "getMachineStatusRulesByMachineId", {machine_id:this.machine.machine_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    this.loading = false;
                    this.rawData=response.data.rules
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        deleteRule(item){
            this.deleteDetails['status']=item.machine_status
            this.deleteDetails['rules']=this.rawData
            this.showDelete=true
        },
        onDelete(){
            this.showDelete=false
            this.fetchMachineStatusRules()
        }
    },
    watch:{
        rawData(){
            this.createTable()
        },
        success(){
            this.fetchMachineStatusRules()
        }
    }
}
</script>
<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>
{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon color="red" small>mdi-trash-can</v-icon></v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text  @click="update = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddParameterInsightsWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <div v-if="widget && widget.meta && Object.keys(widget.meta.insights).length>0">
            <h3 :style="{color: $store.getters.getColorPalette().accentCode}">
                    {{ widget.label }}
            </h3>
            <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                <template v-slot:default>
                    <thead :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                        <tr>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">Remark</th>
                            <th v-for="(item,ind) in widget.meta.param_list"  :key="ind" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{item.label}}</th>
                        </tr>
                    </thead>
                    <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                        <tr  v-for="(item,ind) in Object.keys(widget.meta.insights)"  :key="ind"   :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                            <td :style="{'background-color':widget.meta.insights[item]['color']}"  :class="' black--text  pa-1 text-center text-subtitle-2'">
                                <span >{{item}}</span>
                            </td>
                            <td v-for="(param,ind) in params"  :key="ind" class="text-center">{{tableData[item][param]+' Days'}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div v-else
            :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import DateUtils from '@/utillities/DateUtils';
import axios from 'axios';
import moment from 'moment'
import RuleProcessingUtillity from '@/utillities/RuleProcessingUtillity'
import AddParameterInsightsWidget from '@/components/crud_components/widgets/AddParameterInsightsWidget';
export default
    {
    name: 'ParameterInsightsWidget',
    props: ['widget','edit'],
    components: {
        AddParameterInsightsWidget,
            InfoAlert
        },
        data() {
            return {
                title: 'Process Based Widget',
                dateUtils: DateUtils,
                loading: false,
                status:'Loading',
                showMenu: false,
                x:0,
                y:0,
                info: '',
                dialog: false,
                dbData:[],
                tableData:{},
                params:[],
                val:0,
                color:this.$store.getters.getColorPalette().accentCode,
                showDismissibleAlert: false,
                update: false,
                evaluateRule:RuleProcessingUtillity.evaluateRule
            }
        },
        mounted() {
            if (this.widget && this.widget.meta){
                this.fetchData()
            }
        },
        methods: {
            show(e) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
            },
            checkCondition(param_val,operator1,operand1,operator2,operand2){
                let result1=this.evaluateRule(param_val,[{"operator":operator1, "operand":operand1}])
                let result2=false
                if(operator2){
                    result2=this.evaluateRule(param_val, [{"operator":operator2, "operand":operand2}]);
                    if(result1 && result2){
                        return true
                    }
                }else{
                    if(result1){
                        return true
                    }
                }
                return false
            },
            calculateInsights(){
                this.tableData={}
                let temp={}
                let params=new Set([])
                for(let i of Object.keys(this.widget.meta.insights)){
                    if(!temp[i]){
                        temp[i]={}
                    }
                    for(let j of this.dbData){
                        if(j.param_id !==null){
                            params.add(j.param_id)
                        }
                        if(!temp[i][j.param_id]){
                            temp[i][j.param_id]=0
                        }
                        if(this.checkCondition(j.avg,this.widget.meta.insights[i]["operator1"],this.widget.meta.insights[i]["operand1"],this.widget.meta.insights[i]["operator2"],this.widget.meta.insights[i]["operand2"])){
                            temp[i][j.param_id]=temp[i][j.param_id]+1
                        }
                    }
                }
                this.tableData=temp
                this.params=[...params]
            },
            fetchData(){
                this.dbData=[]
                let from_date=moment().subtract(parseFloat(this.widget.meta.timeFrame),'days').format("YYYYMMDD")
                let payload={
                    from_date:from_date,
                    groupByDate:true
                }
                let parameters=[]
                for(let j of this.widget.meta.param_list){
                    parameters.push(j.parameter)
                }
                payload['parameters']=parameters
                axios.post(this.$store.state.api+'getParamDailyDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                    let tempData=response.data.data
                    this.dbData=tempData
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            },
            deleteWidget() {
                this.loading = true
                axios.post(this.$store.state.api + 'deleteWidget', this.widget, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.info = "Widget Deleted"
                        this.$emit('update')
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                });
            },
        },
        watch:{
            dbData(){
                this.calculateInsights()
            },
            widget(){
                this.fetchData()
            }
        }
    }
</script>
<style scoped>
.count{
    font-size:30px;
    margin:30px;
    font-weight:bolder;
}

    /* text-align: left; */
    /* display: flex; */
    /* align-self:center; */
    /* align-items: center; */
    /* font-weight:bolder; */

</style>

<template>
    <v-simple-table  height="450px" :dark="$store.getters.getColorPalette().isDark">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().bit}}</strong>
          </th>
          <th class="text-center">
            <strong>{{$store.getters.getTextMap().status}}</strong>
          </th>
          <th class="text-center">
            <strong>{{$store.getters.getTextMap().inference}}</strong>
          </th>
         
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tuples"
          :key="item.bit"
        >
          <td>{{ item.bit }}</td>
          <td class="text-center">{{ getBitState(item.bit,value)?$store.getters.getTextMap().high
:$store.getters.getTextMap().low }}</td>
          <td class="text-center">{{ getBitState(item.bit,value)?item.high:item.low }}</td>
          
          
          
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import BitwiseOperationUtillity from '@/utillities/BitwiseOperationUtillity'
export default {
    name:'WordStatusTable',
    props:{
        tuples: {
            type: Array,
            required: true
        },
        height:{
            type:String,
            default:'450px'
        },
        value:{
            type:Number,
            required:true
        }
        
    },
    methods:{
        getBitState:BitwiseOperationUtillity.getBitState,
    }

}
</script>
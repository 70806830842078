<template>
 
    <v-dialog
      v-model="dialog"      
      
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn  small icon v-bind="attrs"
          v-on="on" color="blue" >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      </template>
<v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <CreateMachineParam :param="param" v-on:close="close" v-on:update="$emit('update')"/>                      
                    </v-col>
                </v-row>
    </v-container>
    </v-dialog>
      
</template>

<script>
// import axios from 'axios';
// import FormRules from '@/utillities/FormRules'
import CreateMachineParam from "@/components/crud_components/CreateMachineParam"
  export default {
      name:'EditParamModal',
      components:{
        CreateMachineParam,
      },
      props:{
        param:{
            required:true
        }
      },
    data () {
      return {
        dialog: false,
        valid:false,
        info:'',
        showDismissibleAlert:false,
        loading:false,
        
      }
    },
    // mounted(){
    //     this.form=Object.assign({}, this.parameter)

    // },
    computed:{

    },
    methods:{
        close(){
            this.dialog=false
            this.$emit('close')

        }
    }
  }
</script>
<template>
  <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode,}">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info"/>
    <v-row v-if="!hideDelete">
      <v-col  cols="auto" class="d-flex" align="right">
        <v-btn v-if="!hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addParamDialog = !addParamDialog">
          {{$store.getters.getTextMap().add_parameters}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus</v-icon>
        </v-btn>
        <v-btn class="mx-1"  v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="createParamDialog = !createParamDialog">
          {{$store.getters.getTextMap().create_param}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus</v-icon>
        </v-btn>
        <CreateLoggedParameterBottomSheet  :machine="machine" v-if="!hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"/>
        <AddMachineStatusRuleModal class="mx-1"  v-on:close="onClose" :machine="machine" v-if="!hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"/>
        <v-btn class="mr-1" v-if=" $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])  && machine && machine.data_source!=='virtual'" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="setParamTypeDialog = !setParamTypeDialog">
          {{$store.getters.getTextMap().update_tags}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-alpha-u-circle</v-icon>
        </v-btn>
        <v-menu align="right" offset-y v-if="!hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined v-bind="attrs" v-on="on">
              {{$store.getters.getTextMap().actions}}
<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
            <v-list-item>
              <v-list-item-title>
                <v-btn v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) && machine.process_id" :color="$store.getters.getColorPalette().accentCode"  small  text  outlined @click="removeFromProcess">
                  {{$store.getters.getTextMap().remove_from_process}}
<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn  v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="showEdit = !showEdit">
                  {{$store.getters.getTextMap().edit}}
<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <DeleteConfirmation v-on:confirm="deleteMachine()" title="Delete Confirmation" description="Are you sure you want to delete this machine?">
                  <v-btn v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :color="$store.getters.getColorPalette().accentCode"  small  text  outlined @click="deleteMachine()">
                    {{$store.getters.getTextMap().delete}}
<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                  </v-btn>
              </DeleteConfirmation>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-col>
        </v-row>
      <!-- <v-col cols="auto" class="d-flex ml-auto" align="right">
        <v-btn
          v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
          :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
          @click="showEdit = !showEdit"
          >Edit Machine
            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="ml-1" v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) && machine.process_id"
            :color="$store.getters.getColorPalette().accentCode" 
            small 
            text 
            outlined
            @click="removeFromProcess"
          >Remove from Process
            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
          </v-btn>
          <DeleteConfirmation v-on:confirm="deleteMachine()" title="Delete Confirmation" description="Are you sure you want to delete this machine?">
            <v-btn 
              class="ml-1"
              v-if=" !hideDelete && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
              :color="$store.getters.getColorPalette().accentCode" 
              small 
              text 
              outlined>Delete Machine
              <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
            </v-btn>
        </DeleteConfirmation>
      </v-col> -->
    <v-row>
      <v-col>
        <v-chip
            v-if=" $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
            >
              <v-avatar :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode,}" :class=" $store.getters.getColorPalette().foregroundColorName + 'mx-1 px-0' ">
                <v-icon :color="$store.getters.getColorPalette().accentCode" small >mdi-city-variant-outline</v-icon>
              </v-avatar
            >{{ machine.machine_id}}</v-chip
          >
      </v-col>
    </v-row>
    <v-row v-if="showEdit">
      <v-col>
        <CreateMachine :machine="machine" v-on:close="showEdit = false" v-on:machineUpdate="$emit('machineUpdate')"/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-if="addParamDialog">
        <AddParamToMachine v-if="addParamDialog" :machine="machine" :eliminate="allParamIDs" v-on:close="addParamDialog = false" v-on:success="getParams()"/>
      </v-col>
      <v-col v-if="createParamDialog">
        <CreateMachineParam v-if="createParamDialog" :machine="machine" v-on:close="createParamDialog = false" v-on:update="getParams()"/>
      </v-col>
      <v-col v-if="setParamTypeDialog">
        <SetTagTypeInMachine :machine="machine" :params="allParams" v-on:close="setParamTypeDialog = false" v-on:success="getParams()"/>
      </v-col>
    </v-row>
    <v-row v-if="energyParams && energyParams.length > 0">
      <v-col>
        <LatestUsagePanel :params="energyParams" :title="energyUsageTitle" :showGraphType="true"/>
      </v-col>
    </v-row>
    <v-row v-if="energyParam && energyParam.param_id">
      <v-col>
        <v-card :dark="$store.getters.getColorPalette().isDark" flat>
          <SingleParamUsageHeatMap :param="energyParam.param_id" label="Energy Consumption Heatmap"/>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels multiple hover > 
          <v-expansion-panel  :style="{border:'1px solid ' + $store.getters.getColorPalette().cardBorderColor,'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
            <v-expansion-panel-header><h3 :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().machine_parameters}}
</h3></v-expansion-panel-header>
            <v-expansion-panel-content class="pb-10">
              <v-row>
                <v-col v-if="energyParam && energyParam.param_id">
                  <strong>{{$store.getters.getTextMap().energy}}
</strong>
                  <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                  <Param :param="energyParam" v-on:deleteParam="removeParam(energyParam.param_id)"/>
                </v-col>
                <v-col v-if="powerParam && powerParam.param_id">
                  <strong class="">{{$store.getters.getTextMap().power}}
</strong>
                  <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                  <Param :param="powerParam" v-on:deleteParam="removeParam(powerParam.param_id)"/>
                </v-col>
                <v-col v-if="apparentEnergyParam && apparentEnergyParam.param_id">
                  <strong class="">{{$store.getters.getTextMap().apparent_energy}}
</strong>
                  <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                  <Param :param="apparentEnergyParam" v-on:deleteParam="removeParam(apparentEnergyParam.param_id)"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <strong :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().featured_params}}
</strong>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="right" >
                  <InfoComponent hint="Params added to featured will be shown on the Machine Display"/>
                </v-col>
              </v-row>
              <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
              <v-row>
                <v-col cols="4" v-for="param in favParams" :key="param.param_id">
                  <Param :param="param" v-on:deleteParam="removeParam(param.param_id)" v-on:update="getParams">
                    <template v-slot:hover_button v-if=" $store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
                      <v-btn small color="primary" @click="removeParamFromFeatured(param.param_id)">
                        {{$store.getters.getTextMap().remove_from_featured}}

                      </v-btn>
                    </template>
                  </Param>
                </v-col>
              </v-row>
              <v-row>
              <v-col><strong>{{$store.getters.getTextMap().other_parameters}}
</strong></v-col>
              </v-row>
              <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
              <v-row>
                <v-col cols="4" v-for="param in params" :key="param.param_id">
                  <Param :param="param" v-on:deleteParam="removeParam(param.param_id)" v-on:update="getParams">
                    <template v-slot:hover_button v-if=" $store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
                      <v-btn small color="primary" @click="addParamToFeatured(param.param_id)" >{{$store.getters.getTextMap().add_to_featured}}
</v-btn>
                    </template>
                  </Param>
                </v-col>
              </v-row>
              <v-row v-if="loading">
                <v-col align="center">
                  <v-progress-circular large indeterminate v-if="loading" color="primary"></v-progress-circular>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :style="{border:'1px solid ' + $store.getters.getColorPalette().cardBorderColor,'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
            <v-expansion-panel-header><h3 :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().logged_parameters}}
</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <LoggedParamsTable :machine="machine" v-on:close="$emit('machineUpdate')"/>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :style="{border:'1px solid' + $store.getters.getColorPalette().cardBorderColor,'background-color': $store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
            <v-expansion-panel-header><h3 :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().tags}}
</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <MachineParamTable :machine="machine"/>
                  </v-col>
                </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel  v-if=" $store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])" :style="{border:'1px solid' + $store.getters.getColorPalette().cardBorderColor,'background-color': $store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
            <v-expansion-panel-header><h3 :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().machine_status_rules}}
</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <MachineStatusRulesList :machine="machine" :success="machineStatusRuleAdded" v-on:close="onClose"/>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          <v-expansion-panel  v-if=" $store.state.user  && $store.getters.getAccessByLevels(['engineeraccess'])" :style="{border:'1px solid' + $store.getters.getColorPalette().cardBorderColor,'background-color': $store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
            <v-expansion-panel-header><h3 :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().machine_status_mqtt_map}}
</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <MachineStatusMQTTMap :machine_id="machine.machine_id"  />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
import AddParamToMachine from "@/components/crud_components/AddParamToMachine";
import CreateMachineParam from "@/components/crud_components/CreateMachineParam";
import CreateMachine from "@/components/crud_components/CreateMachine";
import SetTagTypeInMachine from "@/components/crud_components/SetTagTypeInMachine";
import Param from "@/components/items/Param";
import InfoComponent from "@/components/InfoComponent";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import CreateLoggedParameterBottomSheet from "@/components/modals/CreateLoggedParameterBottomSheet";
import LoggedParamsTable from "@/components/tables/LoggedParamsTable";
import SingleParamUsageHeatMap from "@/components/graphs/SingleParamUsageHeatMap";
import LatestUsagePanel from "@/components/panels/LatestUsagePanel";
import MachineParamTable from "@/components/tables/MachineParamTable"
import AddMachineStatusRuleModal from "@/components/modals/AddMachineStatusRuleModal";
import MachineStatusRulesList from '@/components/lists/MachineStatusRulesList'
import MachineStatusMQTTMap from "@/components/crud_components/MachineStatusMQTTMap.vue";
export default {
  name: "MachineDetails",
  props: ["machine", "hideDelete"],
  components: {
    AddParamToMachine,
    CreateMachineParam,
    SetTagTypeInMachine,
    InfoAlert,
    Param,
    InfoComponent,
    DeleteConfirmation,
    CreateMachine,
    CreateLoggedParameterBottomSheet,
    AddMachineStatusRuleModal,
    LoggedParamsTable,
    SingleParamUsageHeatMap,
    LatestUsagePanel,
    MachineParamTable,
    MachineStatusRulesList,
    MachineStatusMQTTMap
  },
  mounted() {
    this.getParams();

  },
  data() {
    return {
      showDialog: false,
      loading: false,
      info: "",
      showDismissibleAlert: false,
      favParams: [],
      params: [],
      allParams: [],
      machineStatusRuleAdded:false,
      addParamDialog: false,
      createParamDialog: false,
      setParamTypeDialog: false,
      showEdit: false,
      energyUsageTitle: "",
    };
  },
  computed: {
    energyParam() {
      return this.allParams.find((d) => {
        return d.tag_type == "active_energy";
      });
    },
    powerParam() {
      return this.allParams.find((d) => {
        return d.tag_type == "active_power";
      });
    },
    apparentEnergyParam() {
      return this.allParams.find((d) => {
        return d.tag_type == "apparent_energy";
      });
    },
    energyParams() {
      let op = [];

      if (this.energyParam && this.energyParam.param_id) {
        op.push(this.energyParam.param_id);
      }
      if (this.apparentEnergyParam && this.apparentEnergyParam.param_id) {
        op.push(this.apparentEnergyParam.param_id);
      }
      return op;
    },
    allParamIDs() {
      let l = [];
      for (let i of this.allParams) {
        l.push(i.param_id);
      }
      return l;
    },
  },
  methods: {
    deleteMachine() {
      if (this.machine && this.machine.machine_id) {
        axios.post(this.$store.state.api + "deleteMachine",{ machine_id: this.machine.machine_id },{headers: {Authorization: "Bearer " + this.$store.state.jwt,},})
        .then((response) => {
          if (response.data.status == "success") {
            this.loading = false;
            this.$emit("machineUpdate");
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
      }
    },
    addParamToFeatured(param_id) {
      if (this.machine && this.machine.machine_id) {
        this.loading = true;
        axios.post(this.$store.state.api + "featureParamInMachine",{ machine_id: this.machine.machine_id, param_id: param_id },{headers: {Authorization: "Bearer " + this.$store.state.jwt,},})
        .then((response) => {
          if (response.data.status == "success") {
            this.loading = false;
            this.getParams();
            this.$emit("paramUpdate");
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
      }
    },
    removeParamFromFeatured(param_id) {
      if (this.machine && this.machine.machine_id) {
        this.loading = true;
        axios.post(this.$store.state.api + "featureParamInMachine",{machine_id: this.machine.machine_id,param_id: param_id,remove: true,},{headers: {Authorization: "Bearer " + this.$store.state.jwt,},})
        .then((response) => {
          if (response.data.status == "success") {
            this.loading = false;
            this.getParams();
            this.$emit("paramUpdate");
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
      }
    },
    getParams() {
      if (this.machine && this.machine.machine_id) {
        this.loading = true;
        axios.post(this.$store.state.api + "getParamsByMachine",{ machine_id: this.machine.machine_id },{headers: {Authorization: "Bearer " + this.$store.state.jwt,},})
        .then((response) => {
          if (response.data.status == "success") {
            this.loading = false;
            this.allParams = response.data.params
            this.favParams = response.data.params.filter((d) => {
              return d.featured;
            }).sort((a,b)=> {
              return a.name.localeCompare(b.name)
            }); 
            this.params = response.data.params.filter((d) => {
              return !d.featured;
            }).sort((a,b)=>{
              return a.name.localeCompare(b.name)
            });
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
      }
    },
    removeParam(param_id) {
      if (this.machine && this.machine.machine_id && param_id) {
        this.loading = true;
        axios.post(this.$store.state.api + "removeParamFromMachine",{ machine_id: this.machine.machine_id, param_id: param_id },{ headers: {Authorization: "Bearer " + this.$store.state.jwt,},})
        .then((response) => {
          if (response.data.status == "success") {
            this.loading = false;
            this.getParams();
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
      }
    },
    removeFromProcess() {
      this.loading = true;
      axios.post(this.$store.state.api + "removeMachineFromProcess",this.machine,{headers: {Authorization: "Bearer " + this.$store.state.jwt,},})
      .then((response) => {
        if (response.data.status == "success") {
          this.$store.dispatch("refreshProcesses");
          this.$emit("machineUpdate");
        } else {
          this.info = response.data.msg;
          this.showDismissibleAlert = true;
        }
        this.loading = false;
      })
      .catch((error) => {
        this.info = error;
        this.showDismissibleAlert = true;
        this.loading = false;
      });
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
    onClose(){
      this.machineStatusRuleAdded=true;
    }
  },
  watch: {
    machine() {
      this.getParams();
    },
    energyParams() {
      this.energyUsageTitle = "";
      if (this.energyParam && this.energyParam.param_id) {
        this.energyUsageTitle = this.energyParam.unit;
      }
      if (this.apparentEnergyParam && this.apparentEnergyParam.param_id) {
        if (this.energyUsageTitle != "") {
          this.energyUsageTitle += " Vs ";
        }
        this.energyUsageTitle += this.apparentEnergyParam.unit;
      }
    },
  },
};
</script>

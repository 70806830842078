        <template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-row>
            <v-col>
                <span :color="$store.getters.getColorPalette().accentCode">Add Param</span>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>        
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          outlined          
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.device"
          :items="deviceOptions"
          :label="$store.getters.getTextMap().device"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.parameter"
          :items="paramOptions"
          :label="$store.getters.getTextMap().device_parameter"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
            <v-col v-if="!loading" align-self="center">
                <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            outlined
            @click="onSubmit"
          >
         {{   $store.getters.getTextMap().add_param}}
          </v-btn>
          
            </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'AddParamToDashboard',
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            form:{
            },
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        
        deviceOptions(){
             
            let op=[]
          
          if(this.form.workspace && this.form.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.form.device && this.form.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
              for(let i of g){
                  
                  if(i && i.name){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
                  
              
          }
          }
          return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.param_id=this.form.parameter
            axios.post(this.$store.state.api+'addParamToDashboard',this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        
        this.$store.dispatch('refreshDashboardParams')
        
            this.loading=false
            //this.dialog=false
            //this.$emit('close')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.usageForm.reset()
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        }
    }
}
</script>
<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                        <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                        <v-list-item-icon>
                            
                            <v-icon color="red" small>mdi-trash-can</v-icon>
                        
                        </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition" >
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"> 
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddTriggerCountTableWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <span><strong>{{ widget.label }}</strong></span>
        <div v-if="tableData && tableData.length>0" :style="[widget.height==='fixed' ? {'background-color':widget.background_color?widget.background_color:$store.getters.getColorPalette().background2ColorCode,'height':'175px' ,'padding-top':'0px', 'padding-bottom':'0px'}: {'background-color':widget.background_color?widget.background_color:$store.getters.getColorPalette().background2ColorCode,'padding-top':'0px','padding-bottom':'0px'}]">
            <v-simple-table dense :height="tableHeight" :style="{background: widget.background_color?widget.background_color:$store.getters.getColorPalette().background2ColorCode}" >
                <template v-slot:default>
                    <thead>
        <tr>
        
            <th 
            :class="$store.getters.getColorPalette().foregroundColorName" :color="$store.getters.getColorPalette().accentCode">
                <strong>{{$store.getters.getTextMap().triggers}}</strong>
                </th>


           <th :class="$store.getters.getColorPalette().foregroundColorName" :color="$store.getters.getColorPalette().accentCode" >
          <strong> {{$store.getters.getTextMap().count}}</strong>
           </th>
        </tr>
      </thead>
                    <tbody >
                        <tr v-for="item in tableData" :key="item.label" :style="[{'background-color': widget.background_color?widget.background_color:$store.getters.getColorPalette().background2ColorCode, 'padding-bottom':'-5px'}]">
                            <td><strong>{{getTriggerName(item.trigger_id)}}</strong></td>
                            <td><strong>{{ item.count }}</strong></td>
                            
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            
        </div>
        <div v-else :style="[widget.height==='fixed' ? {'height':'175px'}:{}]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import DateUtils from '@/utillities/DateUtils'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddTriggerCountTableWidget from '@/components/crud_components/widgets/AddTriggerCountTableWidget'
export default {
    name:'UsageScoreCardWidget',
    props:['widget','filter','edit'],
    components:{
        AddTriggerCountTableWidget,
        InfoAlert,
        // DeleteConfirmation
    },
    mounted(){
        if(this.widget && this.widget.meta){
            // console.log('data present usage')
            this.getTriggerCount()
            this.configured=true
        }else{
            console.log('Widget meta absent')
        }
    },
    data(){
        return {
            showDialog:false,
        
          tableHeight:null,
          percentage:0,
          label:[],
          values:[],
          count:0,
            x:0,
            y:0,
            showMenu:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            update:false,
            data_temp_yesterday:null,
            data_temp_compare:null,
            data:{} ,
            cellValues:[],
            configured:false,
            layout : {
        showlegend:false,
        autosize: true,
        },
        tableData:[],
        shiftData:[],
        headers: [
          {
            text: 'Trigger',
            align: 'start',
            filterable: false,
            value: 'trigger_id',
            width:'10%',
            class: "blue-grey darken-4"
          },
          { text: 'Current Value', value: 'count' ,width:'5%',class: "blue-grey darken-4"},
        //   { text: '%', value: 'percentage',width:'85%',class: "blue-grey darken-4" },
        ],
        }
    },
    computed:{
        
    },
    methods:{
        getTriggerName(trigger_id){
            let trigger=this.$store.getters.getTriggerById(trigger_id)
            if(trigger && trigger.name){
                return trigger.name
            }
            return trigger_id
        },

        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
      },
      async getTriggerCount(){
        try{
                this.loading=true
                let timeFrame=this.widget.meta.time_frame?this.widget.meta.time_frame:0
            let dt=DateUtils.getFromDate(timeFrame)
                let payload={
                    from_timestamp:dt.unix(),
                    to_timestamp:moment().unix()
                }
                
                if(this.widget.meta.triggers){
                    payload['triggers']=this.widget.meta.triggers
                }
                let response=await axios.post(this.$store.state.api+'getTriggeredCountBetweenTimestamp',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                if(response.data.status==='success'){
                    this.tableData= response.data.data
                    // return true
                }else{
                    this.info="Unable to load Trigger Count Data"
                    
                }
                this.loading=false
            }catch(err){
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        }

      },

        deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }  
    },
    watch:{
        widget:{
            handler(){
                this.getTriggerCount()
            },
            deep:true
            
        }
    }

}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
.percentageHighlight{
    padding:2.5px;
    border-radius:3px;
    display:inline-block;
}

</style>
<template>
    <span class="text-center">
      <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="dialog=!dialog" >{{ $store.getters.getTextMap().edit_view_name }}
     <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
        </v-btn>
           
  <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
      <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-toolbar
         :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().foregroundColorCode"
          >
            <v-btn
              icon
              :dark="$store.getters.getColorPalette().isDark" 
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{$store.getters.getTextMap().create_view}}
</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
               :dark="$store.getters.getColorPalette().isDark" 
                text
                @click="dialog = false"
              >
              {{$store.getters.getTextMap().close}}

              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-container fluid>
              <v-row>
                  <v-col cols="12">
                      <EditView :view="view" v-on:close="dialog=false" />
                  </v-col>
              </v-row>
          </v-container>
      </v-card>
  </v-dialog>
    </span>
    
  </template>
  
  <script>
  import EditView from '@/components/crud_components/EditView'
   export default {
       name:'EditViewBottomSheet',
       props:['view'],
       components:{
        EditView
       },
       data(){
           return {
               dialog:false
           }
       }
   }
   </script>
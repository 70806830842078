<template>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
       :dark="$store.getters.getColorPalette().isDark"
      >
        <v-card-text>
          {{$store.getters.getTextMap().please_stand_by}}

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:'LoadingComponent',
    props:['loading'],
    data(){
        return {

        }
    }
}
</script>
<template>
<span>
    <v-btn v-if="!loading && $store.state.user"
      @click="toggleUser"
    :dark="$store.getters.getColorPalette().isDark"
      icon
      small
      :color="$store.state.settingMode?'red lighten-2':'green darken-1'"
      
    >
      <v-icon small :dark="$store.getters.getColorPalette().isDark">
        mdi-{{$store.state.settingMode?'pencil-off-outline':'pencil-outline'}}
      </v-icon>
    </v-btn>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    </span>
</template>

<script>
export default {
    name:'ToggleUserView',
    data(){
        return {
            loading:false
        }
    },
    methods:{
        toggleUser(){
            this.loading=true
            //this.$store.commit("toggleEngineeringAccess")
            this.$store.commit("toggleSettingMode")
            this.loading=false
        }
    }
}
</script>


<template>
    <v-form
    ref="groupForm"
    v-model="valid"
    
  >
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card  :dark="$store.getters.getColorPalette().isDark"  :class="$store.getters.getColorPalette().backgroundColorName">
        <v-card-title class="headline">
        {{ $store.getters.getTextMap().add_triggers_to_parameter}}
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
    <v-select
    v-if="!loading"
          v-model="form.trigger_id"
          :items="triggers"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().triggers"
          
         :rules="objectRules"
         required
          filled
          clearable
          small-chips
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().add}}
          </v-btn>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'AttachTriggerToParam',
    props:['parameter'],
    components:{
      InfoAlert
    },
    mounted() {
        if(this.parameter && this.parameter.triggers){
        this.form.triggers=Object.assign([],this.parameter.triggers)
        }
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            form:{
                triggers:[]
            },
            objectRules:[
                v=> !!v || 'Required',
                //v => (v && v.length>0) || 'You have to select something'
            ],
        }
    },
    computed:{
        triggers(){
            let trig=[]
            //let t=this.$store.state.paramTriggerMapping.filter(x=>x.parmeter!=this.parameter.parameter)
            for(let i of this.$store.state.triggers){
                trig.push({
                    label:i.name,
                    value:i.trigger_id
                })
            }
            
            return trig
        },
    },
    methods: {
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            //let items=Object.assign({}, this.form.id)
            d['parameter']=this.parameter.param_id
            //console.log(d)
            axios.post(this.$store.state.api+'attachTriggerToParam',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        this.$store.dispatch('refreshParamTriggerMappings')
        //console.log("Parameter added "+d)
        
        this.loading=false
        this.info="Trigger added"
        this.showDismissibleAlert=true
            //this.dialog=false
            this.$refs.groupForm.reset()
            this.$emit('close')
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.groupForm.reset()
            this.$emit('close')
        }
    },
}
</script>
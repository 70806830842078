<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}" >  
         <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
         <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true">
         <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" v-if="$store.state.settingMode && edit">
             <v-list-item @click="update=true">
                 <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                 <v-list-item-icon>
                 <v-icon small>mdi-pencil</v-icon>
                 </v-list-item-icon>
             </v-list-item>
             <v-list-item @click="deleteWidget">
                 <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                 <v-list-item-icon>
                 <v-icon color="red" small>mdi-trash-can</v-icon>
                 </v-list-item-icon>
             </v-list-item>
         </v-list>
         </v-menu>
         <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
             <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
             <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                 <v-icon>mdi-close</v-icon>
             </v-btn>
             <v-toolbar-title>{{widget.label}}</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-toolbar-items>
                 <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}
</v-btn>
             </v-toolbar-items>
             </v-toolbar>
                   <AddConsumptionPieWidget :widget="widget" :disableTimeframe="disableTimeframe" v-on:close="update=false" v-on:success="$emit('update')" />
         </v-card>
         </v-dialog>
     <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
 <div v-if="widget.meta.data && widget.meta.data.length>0"  ref="vis" :style="[widget.height === 'fixed' ? {'height':'175px','text-align':'center'}:{}]">
 <!-- {{widget.label}}
 {{ widget.meta.label }}
 {{ widget.meta.unit }}
 {{val}} -->
 </div>
         <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
             <v-icon small>mdi-hail</v-icon>
             <h5>{{$store.getters.getTextMap().not_configured}}</h5>
         </div>
     </v-container>
 </template>
 
 <script>
   import Plotly from 'plotly.js'
   import moment from 'moment'
   import InfoAlert from '@/components/InfoAlert'
   import AddConsumptionPieWidget from '@/components/crud_components/widgets/AddConsumptionPieWidget'
   import DateUtils from '@/utillities/DateUtils'
   import axios from 'axios'
   import {io} from 'socket.io-client'
   export default {
   name:"ConsumptionPieWidget",
 
   components:{
 
         AddConsumptionPieWidget,
         InfoAlert,
         // DeleteConfirmation
     },
   props:['widget','edit','disableTimeframe','timeframe'],
   data(){
         return {
           showDialog:false,
             x:0,
             y:0,
             showMenu:false,
             loading:false,
             info:'',
             showDismissibleAlert:false,
             update:false,
             tdData:{},
             stream:null,
             streamData:{},
             dateUtils:DateUtils,
             colorCode:this.$store.getters.getColorPalette().colorTypesCodes,
             layout : {
                colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
             autosize: true,
            //  margin: { t: 30, r:20, l: 20, b: 30 },
             automargin:true,
            //  padding: {
            //     t: 25, 
            //     b: 40, 
            //     r: 10, 
            //     l: 60,
            //     },
                   font:{
                         color:this.$store.getters.getColorPalette().accentCode,
                       },
                   plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                   paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                   showLegend:false,
                   }
         }
     },
     created(){
     //this.socket=io('https://localhost:80');
     this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
   },
   beforeDestroy() {
     //this.socket.close()
     //console.log('before destroy')
     this.stream.close()
   },
 
 
     mounted(){
          this.init()
     },
 
 
 
     computed:{
        
        //  val(){
        //      let dt='-'
        //      if(this.widget.meta && this.widget.meta.time_frame && this.widget.meta.time_frame=='rtd'){
        //          dt=0
        //          for(let i in this.streamData){
        //             dt+=Number(this.streamData[i]['data'])
        //             // console.log(i,this.streamData[i]['data'])
        //          }
        //      }else{
        //         dt=0
        //         for(let i in this.streamData){
        //             dt+=Number(this.streamData[i]['data'])
        //             // console.log(i,this.streamData[i]['data'])
        //          }
        //          dt+=Number(this.tdData)
        //         // console.log(this.tdData,"dt2222")
        //      }
        //          //console.log("Computed property 'val' - dt:", dt);
        //          //console.log(this.widget.meta.multiplier,"multi")
        //          //console.log(Math.round(dt * Number(this.widget.meta.multiplier)*100)/100,"math")
        //  return isNaN(dt) ? '-' : Math.round(dt * 100) / 100;
        // }
     },
 
     methods:{
         show (e) {
             e.preventDefault()
             this.showMenu = false
             this.x = e.clientX
             this.y = e.clientY
             if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
       },
 
       init(){
        // console.log('init called')
         this.stream.on("connect_error", (err) => {
                   console.error(err.message); // prints the message associated with the error
            });
       this.stream.on('error',data=>{
       console.error(data)
       
     })
     this.stream.on('ack',data=>{
       console.log(data)
       
     })
     this.stream.on('paramConsumption',data=>{
       if(data.constructor==String){
         data=JSON.parse(data)
       }
       //this.val=data.data
    //  console.log(data)
     this.streamData[data['param_id']]=data
     this.streamData=Object.assign({},this.streamData)
       
       //this.calculateVal(); 
     })
             if(this.widget && this.widget.meta && this.widget.meta.data){ 
                // console.log(this.widget,"l")
               for(let dt of this.widget.meta.data){
                for(let param of dt.params){

                    this.stream.emit('getParamConsumption',param)
                    // console.log(param)
                }
               }
                 if(this.widget.meta.time_frame && this.widget.meta.time_frame!='rtd'){
                     this.getData()
                 }else if(this.timeframe && this.disableTimeframe){
                     this.getData()

                 }
         }
       },
       calculateConsumption(){
        let cons={}
        if(this.widget.meta && this.widget.meta.data){
            for(let data of this.widget.meta.data){
                cons[data['label']]=0
                if(this.tdData[data['label']] && this.widget.meta.time_frame!='rtd' && !isNaN(this.tdData[data['label']])){
                    cons[data['label']]=Number(this.tdData[data['label']])
                }
                for(let param of data['params']){
                    if(this.streamData[param] && !isNaN(this.streamData[param]['data'])){
                        cons[data['label']]+=Number(this.streamData[param]['data'])
                    }
                }
            }

        }
        return cons
       },
       createChart(){
        this.layout['title']= this.widget.label
        //   console.log('createChart')
            let textInfo='label+percent'
            if(this.widget.meta && this.widget.meta.legend){
              
              
              textInfo='percent'
            }
            this.layout['showlegend']=this.widget.meta.legend
            this.layout['colorway']=this.$store.getters.getColorPalette().colorTypesCodeArray
            if(this.widget.meta && this.widget.meta.textinfo){
              textInfo=this.widget.meta.textinfo
            }
            
            this.layout['annotations']=[{
                    font: {
                        size: 22
                    },
                    showarrow: false,
                    text: this.widget.meta.pie?'':(this.widget.meta.time_frame?this.widget.meta.time_frame.toUpperCase():''),
                    x: 0.5,
                    y: 0.5
                    }]
                    let consumption=this.calculateConsumption()
            var data = [{
            values: Object.values(consumption),
            labels: Object.keys(consumption),
            type: 'pie',
            text:this.widget.meta.pie?null:'mtd',
            textinfo: textInfo,
            textposition: "outside",
            opacity:1,
            hole:this.widget.meta.pie?0:0.7,
            marker: {

              line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                },
            }
            //automargin: true
            }];
  
            if(this.$refs.vis){
              Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
            }
           
       },
         getData(){
              this.tdData={}
             let processing='usage'
             let from_date;
             if(this.timeframe && this.disableTimeframe){
          switch (this.timeframe) {
                
                case '7':
                  from_date = moment().subtract(7,'days').format('YYYYMMDD');
                  break;
                  
                case '30':
                  from_date = moment().subtract(30,'days').format('YYYYMMDD');
                  break;
                  
                  case '60':
                    from_date = moment().subtract(60,'days').format('YYYYMMDD');
                    break;
                    
                    case '90':
                      from_date = moment().subtract(90,'days').format('YYYYMMDD');
                      break;
                      
                      case '180':
                        from_date = moment().subtract(180,'days').format('YYYYMMDD');
                        break;
                        
                        case '365':
                          from_date = moment().subtract(365,'days').format('YYYYMMDD');
                      break;
              
                    case 'yesterday':
                    from_date = moment().subtract(1,'days').format('YYYYMMDD');
                      break;
                    case 'ytd':
                      from_date = moment().startOf('year').format('YYYYMMDD');
                      break;

                    default:
                      from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                      break;
                  }
        }else{
            if(this.widget.meta && this.widget.meta.time_frame && this.widget.meta.time_frame!='rtd'){

               //  from_date=moment().subtract(parseInt(this.widget.meta.time_frame),'days').format('YYYYMMDD')
                from_date=this.dateUtils.getFromDate(this.widget.meta.time_frame).format('YYYYMMDD')
            }else{
                from_date=moment().subtract(30,'days').format('YYYYMMDD')
            }
        }
             let to_date=moment().format('YYYYMMDD')
             if(this.widget && this.widget.meta ){
                 // let param=this.$store.getters.getParamById(this.widget.meta.param)
                 // console.log(param)
                 if(this.widget && this.widget.meta && this.widget.meta.processing ){
                     switch(this.widget.meta.processing){
                         case 'usage':
                             processing='usage'
                             break;
                         case 'avg':
                             processing='avg'
                             break;
                         case 'min':
                             processing='min'
                             break;
                         case 'max':
                             processing='max'
                             break;
                         default:
                             processing='usage'
                     }
                 }
               if(this.widget && this.widget.meta && this.widget.meta.data){
               this.createChart()  
                         }
                // console.log(this.widget.meta.params,"this.widget.meta.params")
                // console.log(from_date)
                this.tdData={}
                for(let p of this.widget.meta.data){
                    // console.log(p)
                    let payload={
                         parameters:p.params,
                         from_date:from_date,
                         to_date:to_date
                        
                     }
                     // console.log(payload,"payload")
                 axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                 .then(response=>{
                      //console.log("Response from the API:", response.data);
                     if(response.data.status==='success'){
                        
                         this.tdData[p.label]=Number(response.data.data[processing]).toFixed(2)
                            // console.log( this.tdData,"tdData")
                            //console.log("this.val after updating:", this.val);
                            this.createChart()  
                     }else{
                         this.info=response.data.msg
                         this.showDismissibleAlert=true
                     }
                         this.loading=false
                     })
                 .catch(err=>{
                     console.error(err)
                     this.loading=false});

                }
                 
                     
                 }
         },
 
 
 
         deleteWidget(){
             this.loading=true
             axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
             .then(response=>{
                 if(response.data.status==='success'){
                     this.dialog=false
                     this.loading=false
                     this.$emit('update')
                 }
             })
             .catch(err=>{
                 console.error(err)
                 this.loading=false
             });
         },
         cancel(){
             this.showDialog=false
         },
         confirm(){
             this.showDialog=false
         },
 
     },
     watch:{
         widget:{
             handler(){
                 this.init()
                 
             },
             
             deep:true
         },
         timeframe:{
              handler(){
                    this.init()
                },
                deep:true
            },
         streamData:{
             handler(){
                 this.createChart()
                 
             },
             
             deep:true
         },
     },
   }
 </script>
 
 <style>
 
 </style>
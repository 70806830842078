<template>
  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
    <v-card  dense outlined   :dark="$store.getters.getColorPalette().isDark" elevation="0" class="px-1 py-0 mx-1 my-1  text-capitalize  lighten-1" :color="color" >
      <v-card-title class="px-1 py-0 mx-0 my-0 ">
        <v-row>
          <v-col align="center">
            <span class="text-subtitle-2 mr-2">{{param.name ?param.name : param.param_id}}</span>
            <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
              <EditParamModal :param="param" v-on:update="$emit('update')" />
              <DeleteConfirmation  v-on:confirm="deleteParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">          
                <v-icon  small>mdi-delete</v-icon>
              </DeleteConfirmation>
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-hover v-slot="{ hover }">
        <v-card-subtitle class="px-1 py-0 mx-0 my-0 " @click="overlay=!overlay">
          <v-row>
            <v-col>
              <strong class="text-h6 ">{{latestVal}}</strong><span class=" "> {{param.unit}}</span>
            </v-col>   
          </v-row>
          <a hidden ref="csvDownload"></a>
          <v-overlay absolute :value="hover">
            <v-row justify="center" align="center" align-content="center">
              <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn class="mx-1" :color="$store.getters.getColorPalette().submitbtnColor" small @click="dialog = true">
                  {{$store.getters.getTextMap().view_details}}<v-icon right>mdi-chart-line</v-icon>
                </v-btn>
                <slot name="hover_button"></slot>
              </v-col>
            </v-row>
          </v-overlay>
          <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card  :dark="$store.getters.getColorPalette().isDark" :class="$store.getters.getColorPalette().backgroundColorName" class="pa-0 ma-0">
              <v-toolbar :dark="$store.getters.getColorPalette().isDark" :class="$store.getters.getColorPalette().foregroundColorName">
                <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{param.name ?param.name : param.param_id}} Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">close</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <ParamDetails :param_id="param.param_id" :val="latestVal" :streamData="streamData"/>
            </v-card>
          </v-dialog>
        </v-card-subtitle>
      </v-hover>     
    </v-card>
  </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import EditParamModal from '@/components/modals/EditParamModal'
import moment from 'moment'
import axios from 'axios'
import {io} from 'socket.io-client'
import ParamDetails from '@/components/items/ParamDetails'
export default {
    name:'Param',
    props:['param'],
    components:{
      InfoAlert,
      DeleteConfirmation,
      ParamDetails,
      EditParamModal
    },
    data(){
      return {
        showDialog:false,
        overlay:false,
        dialog:false,
        info:'Error',
        showDismissibleAlert:false,
        isActive:false,
        loading:false,
        stream:null,
        val:'-',
        streamData:[]
      }
    },
    created(){
      this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
    },
    beforeDestroy() {
      this.stream.close()
    },
    mounted(){
      this.stream.on("connect_error", (err) => {
        console.log(err.message);
      });
      this.stream.on('error',data=>{
        console.log(data)
      })
      this.stream.on('ack',data=>{
        console.log(data)  
      })
      this.stream.on('param',data=>{
        if(data.constructor==String){
          data=JSON.parse(data)
        }
        this.val=data.data
        this.streamData.push(data)
        if(this.streamData.length>50){
          this.streamData.shift()
        }
      })
      this.stream.emit('getParam',this.param.param_id)
    },
    computed: {
      color(){
        if(this.coil){
          return this.latestVal=='On' ?this.$store.getters.getColorPalette().successColor:this.$store.getters.getColorPalette().alertColor
        }else{
          return this.timeDelta < 1.5 ?this.$store.getters.getColorPalette().successColor:this.$store.getters.getColorPalette().alertColor
        }
      },
      currentData(){
        return this.$store.getters.getCurrentParameterDataById(this.param.param_id)
      },
      latestVal(){
        if( this.streamData.length>0){
          if(this.coil){
            return this.streamData[this.streamData.length-1]['data']==1?'On':'Off'
          }
          return Math.round(Number(this.streamData[this.streamData.length-1]['data'])*100)/100
        }
        return '-'
      },
      timeDelta(){
        if( this.streamData.length>0){
          return  (Number(moment().format("X")) - Number(this.streamData[this.streamData.length-1]['timestamp']))/120
        }
        return 2
      },
      name(){
        return this.param.name ? this.param.name : this.param.unit
      },
      timeUnit(){
        if( this.currentData.length>0 && (Number(this.currentData[this.currentData.length-1]['timestamp']) - Number(this.currentData[0]['timestamp']))>(60*60*2)){
          return 'hour'
        }
        return 'minute'
      },
      unit(){
        if(this.param.unit){
          return this.param.unit
        }else{
          return ''
        }
      },
      attachedTriggers(){
        let triggers=this.$store.getters.getTriggerIDsByParameter(this.param.param_id)
        if(triggers){
          return triggers
        }else{
          return []
        }
      },
      dataType(){
        if(!isNaN(this.param.data_type)){
          return this.$store.getters.getDataTypeById(this.param.data_type)?.type
        }
        return null
      },
      coil(){
        if(this.param && this.param.command==1) return true
        return false
      }
    },
    methods: {
      deleteParam(){
        // console.log('deleteParam')
        this.$emit('deleteParam',this.param)
        // if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
        //   this.$emit('deleteParam',this.param)
        // }else{
        //   this.info='You do not have permission to do this'
        //   this.showDismissibleAlert=true
        // }  
      },
      writeCoil(state){
        let payload={
            value:state,
            param_id:this.param.param_id
          } 
        this.loading=true
        axios.post(this.$store.state.api+'writeParam',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
        if(response.data.status==='success'){
          this.loading=false
          this.info="Command Sent"
          this.showDismissibleAlert=true
        }else{
          this.info = response.data.msg;
          this.showDismissibleAlert = true;
          this.loading=false
        }})
        .catch(err=>{console.log(err)
          this.info=err
          this.loading=false
          this.showDismissibleAlert=true
        });
      },
      cancel(){
        this.showDialog=false
      },
      confirm(){
        this.showDialog=false
      }
  },
}
</script>
<template>
    <v-container >
        
    <v-dialog
      v-model="showDismissibleAlert"
      max-width="295"
    >
      <v-card>
        <v-card-title class="headline">{{$store.getters.getTextMap().info}}</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
             :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          >
          {{$store.getters.getTextMap().ok}}

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
<h3 class="text-center">{{$store.getters.getTextMap().nebeskie_labs}}
</h3>
<div class="column justify-between content-center">
    <div class="textBelow text-center">
      {{$store.getters.getTextMap().please_register_and_send_otp}}
  
          </div>
<v-form
       ref="sendOtp"
      
      v-if="!otpSent"
      v-model="validOTPForm"
    >
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-email"
            v-model="email"
            :rules="emailRules"
            :label="$store.getters.getTextMap().email"
            required
            v-if="!loading"
          ></v-text-field>

      <v-row v-if="!loading">
          <v-col align="right">
        <v-btn :label="$store.getters.getTextMap().send_otp" @click="sendOtp" color="primary">Send OTP</v-btn>
          </v-col>
          <v-col align="left">
        <v-btn :label="$store.getters.getTextMap().back_to_login" @click="otpSent=true"  outlined >Already have an OTP</v-btn>
          </v-col>
      </v-row>
</v-form>
<v-form v-else 
ref="resetPassword"
v-model="valid"
>
<v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-email"
            v-model="email"
            :rules="emailRules"
            :label="$store.getters.getTextMap().email"
            required
            v-if="!loading"
          ></v-text-field> 
      <v-text-field
            v-model="otp"
            :rules="otpRules"
            :label="$store.getters.getTextMap().otp"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>

<v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            :label="$store.getters.getTextMap().new_password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          
          <v-text-field
            v-model="confirmpassword"
            :rules="confirmPasswordRules"
            :label="$store.getters.getTextMap().confirm_password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
      <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>

      <v-row v-if="!loading">
          <v-col align="right">
        <v-btn :label="$store.getters.getTextMap().reset_password" @click="resetPassword" color="primary">Reset Password</v-btn>
          </v-col>
          <v-col align="left">
        <v-btn :label="$store.getters.getTextMap().back_To_login"  @click="exit" outlined >back to Login</v-btn>
          </v-col>
      </v-row>
</v-form>
</div>

    </v-container>
</template>

<script>

import axios from "axios";
axios.defaults.withCredentials = false;

export default {
    name:'ResetComponent',
    data() {
    return {
        validOTPForm:false,
        valid:false,
      info: null,
      otpSent: false,
      email: "",
      otp: "",
      password: "",
      confirmpassword: "",
      showDismissibleAlert: false,
      loading: false,
      showPass:false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules:[
          v=> !!v || 'Required',
          v => (v && v.length >= 8 ) || 'Phone must be more than 8 characters',
    ],
      confirmPasswordRules:[
          v=> !!v || 'Required',
          v => (v == this.password ) || 'Passwords do not match',
    ],
    otpRules: [
        v => !!v || 'OTP is required',
        v => /\S+/.test(v) || 'OTP is required',
        v => (v && v.length <= 20 && v.length > 0) || 'OTP must be less than 20 characters',
      ],
    };
  },
  mounted() {
    /*axios
      .get(this.$store.state.api+'login',{withCredentials: true})
      .then(response => (this.info = response)).catch(error=>(this.info=error))*/
    if (this.$store.state.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    sendOtp() {
      this.$refs.sendOtp.validate()
            if(this.validOTPForm){
      this.loading = true;
      axios
        .post(this.$store.state.api + "sendOtp", {
          email: this.email
        })
        .then(response => {
          if (response.data.status == "success") {
            this.otpSent = response.data.otpSent;
            this.showDismissibleAlert = false;
            //this.$refs.sendOtp.reset();
          } else {
            console.log(response.data.error)
            this.info = response.data.error;
            this.showDismissibleAlert = true;
            this.email = "";
          }
          this.loading = false;
        })
        .catch(error => {
          this.email = ""
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    
            }
    },
    resetPassword() {
        this.$refs.resetPassword.validate()
            if(this.valid){
        this.loading = true;
        axios
          .post(this.$store.state.api + "confirmPwd", {
            email: this.email,
            otp: this.otp,
            password: this.password,
            confirmpassword: this.confirmpassword
          })
          .then(response => {
            if (response.data.status == "success") {
              this.otpSent = false;
              this.info = "Password Reset Successfully. Please proceed to login";
              this.showDismissibleAlert = true;
              this.$refs.resetPassword.reset();
              //this.$refs.sendOtp.reset();
            } else {
              this.info = response.data.error;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch(error => {
            this.otpSent = false;
            this.showDismissibleAlert = true;
            this.email = ""
            this.info = error;
            this.loading = false;
          });
            }
    },
    exit() {
      //this.$router.push("/")
      this.otpSent = false;
      //this.$refs.sendOtp.reset();
      this.$refs.resetPassword.reset();
      this.$emit('close')
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    loggedIn: function(val) {
      if (val) {
        this.$router.push("/");
      }
    }
  }
}
</script>

<style scoped>
.textBelow {
  color: #4d4f5c90;
}
label {
  font-size: 15px;
}
</style>
<!-- <template>
    <div class="time-picker">
        <div class="time-section">
        <v-menu
          v-model="showHourSection"
          :close-on-content-click="false"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              label="Hour"
              @click="showHourSection = !showHourSection"
              :value="selectedHour.toString().padStart(2, '0')"
              readonly
            ></v-text-field>
          </template>
          <v-list>
            <v-list-item
              v-for="hour in hours"
              :key="hour"
              @click="selectHour(hour)"
            >
              <v-list-item-title>{{ hour.toString().padStart(2, '0') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
  
      <div class="time-section">

        <v-menu
          v-model="showMinuteSection"
          :close-on-content-click="false"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              label="Minute"
              @click="showMinuteSection = !showMinuteSection"
              :value="selectedMinute.toString().padStart(2, '0')"
              readonly
            ></v-text-field>
          </template>
          <v-list>
            <v-list-item
              v-for="minute in minutes"
              :key="minute"
              @click="selectMinute(minute)"
            >
              <v-list-item-title>{{ minute.toString().padStart(2, '0') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
  
      <div class="time-section">
        <v-menu
          v-model="showAMPMSection"
          :close-on-content-click="false"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              label="AM/PM"
              @click="showAMPMSection = !showAMPMSection"
              :value="isAM ? 'AM' : 'PM'"
              readonly
            ></v-text-field>
          </template>
          <v-list>
            <v-list-item @click="toggleAMPM">AM</v-list-item>
            <v-list-item @click="toggleAMPM">PM</v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        selectedHour: 0,
        selectedMinute: 0,
        isAM: true,
        showHourSection: false,
        showMinuteSection: false,
        showAMPMSection: false,
        hours: Array.from({ length: 12 }, (_, i) => i + 1),
        minutes: Array.from({ length: 60 }, (_, i) => i),
      };
    },
    watch: {
      value: {
        immediate: true,
        handler(newValue) {
          if (newValue && newValue.match(/^\d{2}:\d{2}$/)) {
            const [hour, minute] = newValue.split(":");
            this.selectedHour = parseInt(hour, 10);
            this.selectedMinute = parseInt(minute, 10);
            this.isAM = this.selectedHour < 12;
          } else {
            this.selectedHour = 0;
            this.selectedMinute = 0;
            this.isAM = true;
          }
        },
      },
    },
    methods: {
      selectHour(hour) {
        this.selectedHour = hour;
        this.updateModel();
        this.showHourSection = false;
      },
      selectMinute(minute) {
        this.selectedMinute = minute;
        this.updateModel();
        this.showMinuteSection = false;
      },
      toggleAMPM() {
        this.isAM = !this.isAM;
        this.updateModel();
        this.showAMPMSection = false;
      },
      updateModel() {
        const hours = this.isAM ? this.selectedHour % 12 : this.selectedHour % 12 + 12;
        const formattedHour = hours.toString().padStart(2, '0');
        const formattedMinute = this.selectedMinute.toString().padStart(2, '0');
        const formattedTime = `${formattedHour}:${formattedMinute}`;
        this.$emit('input', formattedTime);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Styles for the rectangular layout */
/* Styles for the rectangular layout */
.time-picker {
  display: flex;
}

.time-section {
  flex: 1;
}

.v-menu__content {
  position: absolute;
  top: 40px;
  width: 80px;
  max-height: 120px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  background-color: #fff;
}

.v-text-field {
  width: 100%;
  margin-right: 10px;
}
  </style>
   -->


   <template>
    <div class="time-picker">
      <div class="time-section">
          <v-menu
            v-model="showHourSection"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
            <!-- <template > -->
              <!-- <v-text-field
                v-bind="attrs"
                v-on="on"
                label="Hour"

                @click="showHourSection = !showHourSection"
                :value="selectedHour.toString().padStart(2, '0')"
              ></v-text-field> -->
              <v-autocomplete
                  v-bind="attrs"
                  v-on="on"
                  :items="hours"
                  :dark="$store.getters.getColorPalette().isDark" 
                  :color="$store.getters.getColorPalette().inputtextColor"
                  :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                  outlined
                  dense
                  small-chips
                  :label="$store.getters.getTextMap().hour"
                  v-model="selectedHour"
              ></v-autocomplete>
            </template>
            <!-- <v-list>
              <v-list-item
                v-for="hour in hours"
                :key="hour"
                @click="selectHour(hour)"
              >
                <v-list-item-title>{{ hour.toString().padStart(2, '0') }}</v-list-item-title>
              </v-list-item>
</v-list> -->

          </v-menu>
      </div>
  
      <div class="time-section">
        <v-menu
          v-model="showMinuteSection"
          :close-on-content-click="false"
          offset-y
          min-width="auto"
        >
          <!-- <template> -->
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-text-field
              v-bind="attrs"
              v-on="on"
              label="Minute"

              @click="showMinuteSection = !showMinuteSection"
              :value="selectedMinute.toString().padStart(2, '0')"
            ></v-text-field> -->
            <v-autocomplete

            v-bind="attrs"
              v-on="on"
            :items="minutes"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().minute"
            v-model="selectedMinute"
          ></v-autocomplete>
          </template>
          <!-- <v-list>
            <v-list-item
              v-for="minute in minutes"
              :key="minute"
              @click="selectMinute(minute)"
            >
              <v-list-item-title>{{ minute.toString().padStart(2, '0') }}</v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>
        <!-- <v-autocomplete
            v-model="selectedMinute"
            :items="minutes"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            label="HH"
            
          ></v-autocomplete> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: String,
        default: null,
      },
      initiate:{
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        selectedHour: 0,
        selectedMinute: 0,
        showHourSection: false,
        showMinuteSection: false,
        hours: Array.from({ length: 24 }, (_, i) => i),
        minutes: Array.from({ length: 60 }, (_, i) => i),
      };
    },
    mount(){
      if(this.initiate){
              this.selectedHour = 0;
              this.selectedMinute = 0;

            }else{
              this.selectedHour = null;
              this.selectedMinute = null;
            }
    },
    watch: {
      value: {
        immediate: true,
        handler(newValue) {
          if (newValue && newValue.match(/^\d{2}:\d{2}$/)) {
            const [hour, minute] = newValue.split(":");
            this.selectedHour = parseInt(hour, 10);
            this.selectedMinute = parseInt(minute, 10);
          } else {
            if(this.initiate){
              this.selectedHour = 0;
              this.selectedMinute = 0;

            }else{
              this.selectedHour = null;
              this.selectedMinute = null;
            }
          }
        },
      },
      selectedHour:{
        handler(){
            this.showHourSection = !this.showHourSection
            this.selectHour()
        },
        deep:true
    },
      selectedMinute:{
        handler(){
            this.showMinuteSection = !this.showMinuteSection
            this.selectMinute()
        },
        deep:true
    }
    },
    methods: {
      selectHour() {
        this.updateModel();
        this.showHourSection = false;
      },
      // selectHour(hour) {
      //   this.selectedHour = hour;
      //   this.updateModel();
      //   this.showHourSection = false;
      // },
      selectMinute() {
        this.updateModel();
        this.showMinuteSection = false;
      },
      // selectMinute(minute) {
      //   this.selectedMinute = minute;
      //   this.updateModel();
      //   this.showMinuteSection = false;
      // },
      updateModel() {
        const formattedHour = this.selectedHour?this.selectedHour.toString().padStart(2, '0'):'00';
        const formattedMinute = this.selectedMinute?this.selectedMinute.toString().padStart(2, '0'):'00';
        const formattedTime = `${formattedHour}:${formattedMinute}`;
        this.$emit('input', formattedTime);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Styles for the rectangular layout */
  .time-picker {
    display: flex;
  }
  
  .time-section {
    flex: 1;
    margin:5px;
  }
  
  .v-menu__content {
    position: absolute;
    top: 40px;
    width: 80px;
    max-height: 120px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .v-text-field {
    width: 100%;
    margin-right: 10px;
  }
  </style>
  
<template>
<v-container>

  <v-form ref="form" v-model="valid">
    <v-row>
    <v-col>
      <v-select v-model="selectedLine" :items="items" label="Choose Line type" outlined :dark="$store.getters.getColorPalette().isDark" :menu-props="{dark: $store.getters.getColorPalette().isDark}">
   <template v-slot:selection="{ item }"><strong>{{ item.name }}</strong> &nbsp;
      <svg width="98%">
        <defs>
    <marker
      id="arrowHead"
      markerUnits="strokeWidth"
      markerWidth="12"
      markerHeight="12"
      viewBox="0 0 12 12"
      refX="6"
      refY="6"
      orient="auto">
      <path d="M2,2 L10,6 L2,10 L6,6 L2,2" :style="{'fill': $store.getters.getColorPalette().accentCode}"></path>
    </marker>
  </defs>
        <line x1="2" y1="50%" x2="90%" y2="50%" :stroke="$store.getters.getColorPalette().accentCode" :style="{'stroke-dasharray':item['stroke-dasharray']}"   :marker-end="item['arrow']?'url(#arrowHead)':''" />

      </svg>
   </template>
   <template v-slot:item="{ item }">
    <strong>{{ item.name }}</strong>&nbsp;
    <svg width="98%">
        <defs>
    <marker
      id="arrowHead"
      markerUnits="strokeWidth"
      markerWidth="12"
      markerHeight="12"
      viewBox="0 0 12 12"
      refX="6"
      refY="6"
      orient="auto">
      <path d="M2,2 L10,6 L2,10 L6,6 L2,2" :style="{'fill': $store.getters.getColorPalette().accentCode}"></path>
    </marker>
  </defs>
        <line x1="2" y1="50%" x2="90%" y2="50%" :stroke="$store.getters.getColorPalette().accentCode" :style="{'stroke-dasharray':item['stroke-dasharray']}"   :marker-end="item['arrow']?'url(#arrowHead)':''" />

      </svg>
   </template>
</v-select>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col>
      <v-btn small  @click="onSubmit" :color="$store.getters.getColorPalette().foregroundColorCode" :dark="$store.getters.getColorPalette().isDark">Add Selected</v-btn>
    </v-col>
  </v-row>

  </v-form>
  <!--
  <v-row>
    <v-col cols="10">
      <div class="scrollable-content">
  <div  class="svg-container" ref="svgContainer" >
    </div>
    </div>
    </v-col>
<v-col cols="2">
    <div>
         <div class="selected-svg-container">
          <div v-for="selectedIndex in selectedLineIndices"  :key="selectedIndex" class="selected-svg-wrapper">
            <svg :key="selectedIndex" class="selected-svg" >
          
             <line
                  v-if="lineData[selectedIndex].arrowType === 'none'"
                  :x1="lineData[selectedIndex].x1"
                  :y1="lineData[selectedIndex].y1"
                  :x2="lineData[selectedIndex].x2"
                  :y2="lineData[selectedIndex].y2"
                  :stroke="lineData[selectedIndex].color"
                  :stroke-width="lineData[selectedIndex].strokeWidth"
                />
                 <line
                  v-else-if="lineData[selectedIndex].arrowType === 'single'"
                  :x1="lineData[selectedIndex].x1"
                  :y1="lineData[selectedIndex].y1"
                  :x2="lineData[selectedIndex].x2"
                  :y2="lineData[selectedIndex].y2"
                  :stroke="lineData[selectedIndex].color"
                  :stroke-width="lineData[selectedIndex].strokeWidth"
                  marker-end="url(#arrowhead)"
                />
                 <line
                  v-else-if="lineData[selectedIndex].arrowType === 'both'"
                  :x1="lineData[selectedIndex].x1"
                  :y1="lineData[selectedIndex].y1"
                  :x2="lineData[selectedIndex].x2"
                  :y2="lineData[selectedIndex].y2"
                  :stroke="lineData[selectedIndex].color"
                  :stroke-width="lineData[selectedIndex].strokeWidth"
                  marker-end="url(#double-arrowhead)"
  marker-start="url(#arrowhead)"
                />
            </svg>
          </div>
        </div>
          <v-btn small  @click="addSelectedSVGs" :color="$store.getters.getColorPalette().foregroundColorCode" :dark="$store.getters.getColorPalette().isDark">Add Selected</v-btn>
    </div>
      </v-col>
  </v-row>
   <svg height="0" width="0">
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="black" />
        </marker>
         <marker
      id="double-arrowhead"
      markerWidth="10"
      markerHeight="7"
      refX="10"
      refY="3.5"
      orient="auto"
    >
      <polygon points="0 0, 10 3.5, 0 7" fill="black" />
      <polygon points="0 0, 10 3.5, 0 7" fill="red" transform="translate(10, 0)"/>
    </marker>
        
      </defs>
    </svg>
  -->
</v-container>
</template>

<script>
import * as d3 from 'd3'
export default {
  Name: "LinesPallette",
  components:{
      
  },

 mounted() {
   this.drawLines();
    
  },
  data(){
    return{
             selectedLineIndices: [], 
             imageList:[],
             valid:null,
             selectedLine:null,
             dialog:false, 
             items:[
              { name:'line','type':'line','stroke-dasharray':'0,0',x1:20,x2:100,y1:20,y2:20,strokeWidth:2},
              { name:'Dotted','type':'dotted','stroke-dasharray':'5,5',x1:20,x2:100,y1:20,y2:20,strokeWidth:2},
              { name:'Arrow line','type':'line',arrow:true,'stroke-dasharray':'0,0',x1:20,x2:100,y1:20,y2:20,strokeWidth:2},
              { name:'Arrow Dotted','type':'dotted',arrow:true,'stroke-dasharray':'5,5',x1:20,x2:100,y1:20,y2:20,strokeWidth:2},
             ],
            lineData: [
        {
          "name":"straightline",
          x1: 20,
          y1: 20,
          x2: 150,
          y2: 20,
          color: 'black',
          strokeWidth: 2,
          // width: 120,
          // height: 40,
          arrowType: 'none',
        },
        {
          "name":"straightline-one arrowhead",
          x1: 20,
          y1: 20,
          x2: 150,
          y2: 20,
          color: 'black',
          strokeWidth: 2,
          // width: 120,
          // height: 40,
            arrowType: 'single', 
        },
        {
          "name":"straightline-two arrowhead",
          x1: 20,
          y1: 20,
          x2: 150,
          y2: 20,
          color: 'black',
          strokeWidth: 2,
          // width: 120,
          // height: 40,
           arrowType: 'both', 
        },
      ],
    }
   

  },
  methods:{
    onSubmit(){
      this.$refs.form.validate()
            if(this.valid){

              this.$emit('lineSelect', [this.selectedLine])
              this.$emit('closeDialog');
            }
    },
     drawLines() {
      const svgContainer = d3.select(this.$refs.svgContainer);

      this.lineData.forEach((line,index) => {
        const svgElement = svgContainer.append('svg');
       

        const lineElement = svgElement
          lineElement.append('line')
          .attr('x1', line.x1)
          .attr('y1', line.y1)
          .attr('x2', line.x2)
          .attr('y2', line.y2)
          .attr('stroke', line.color)
          .attr('stroke-width', line.strokeWidth)
         .attr('marker-end', line.arrowType === 'both' ? 'url(#double-arrowhead)' : (line.arrowType === 'single' ? 'url(#arrowhead)' : null))
          lineElement.on('click', () => {
          this.toggleLineSelection(index);
        });
      });
      console.log(" Line:", this.line);
        console.log(this.selectedLineIndices,"linecheck")
    },

    toggleLineSelection(index) {
      if (this.selectedLineIndices.includes(index)) {
        this.selectedLineIndices = this.selectedLineIndices.filter(i => i !== index); 
      } else {
        this.selectedLineIndices.push(index);
          
      }
    },
    
   addSelectedSVGs() {
        const addedIndices = this.selectedLineIndices.slice();
        // this.selectedLineIndices = [];
        const selectedLines = addedIndices.map(index => this.lineData[index]);
         this.$emit('selectedLines', selectedLines);
             this.$emit('closeDialog');
    },

},
}
</script>

<style scoped>
.svg-container{
  height: 100%;

}
.scrollable-content {
  max-height: 520px; 
  overflow-y: auto; 
}
.scrollable-content::-webkit-scrollbar {
  width: 10px; 
  height:5px;
}
.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 5px;
  height: 10px;
}
/* .svg-name-text {
 font-size: 10px;
    text-anchor: middle;

    transform: translateX(-50%); 
    margin-top: 50px;

} */
.selected-svg-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  height: 100%;
  margin-bottom: 45px;
  margin-left: 15px;
}

.selected-svg-wrapper {
  margin-bottom: 10px; 
}
.svg-name {
    text-align: center;
    margin-top: 20px;
}
</style>

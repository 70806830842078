<template>
    <v-card class="mt-3" flat :isDark="$store.getters.getColorPalette().isDark">
        <component v-if="view" v-bind:is="viewHash[view.view_type]" :view="view" :showEdit="false"></component>
    </v-card>
</template>
<script>
import TableViewPage from '@/pages/views/TableViewPage'
import WidgetViewPage from '@/pages/views/WidgetViewPage'
import EnergyUsageViewPage from '@/pages/views/EnergyUsageViewPage'
import MachineScrollViewPage from '@/pages/views/MachineScrollViewPage'
import ParamScrollViewPage from '@/pages/views/ParamScrollViewPage'
import SLDViewPage from '@/pages/views/SLDViewPage'
import ConsolidatedViewPage from '@/pages/views/ConsolidatedViewPage'
import DynamicTimeframedWidgetView from '@/pages/views/DynamicTimeframedWidgetView'
export default {
    name:'DashboardView',
    components:{},
    data(){
        return {
            viewHash:{
                'tableView':TableViewPage,
                'widgetView':WidgetViewPage,
                'energyUsageView':EnergyUsageViewPage,
                'machineScrollView':MachineScrollViewPage,
                'paramScrollView':ParamScrollViewPage,
                'sldView':SLDViewPage,
                'consolidatedView':ConsolidatedViewPage,
                'dynamicTimeframedWidgetView':DynamicTimeframedWidgetView,
            }
        }
    },
    computed:{
        view(){
            let v=null
            if(this.$store.state.dashboardView){
                v=this.$store.getters.getViewById(this.$store.state.dashboardView)
            }
        return v
        }   
    }
}
</script>
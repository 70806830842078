<template>
    <v-container fluid @contextmenu="show" :dark="$store.getters.getColorPalette().isDark"
        :style="{ 'background-color': widget.background_color }">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog = true"
            v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update = true">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().delete }}
                        </strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false">{{
                            $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddShiftwiseMachineStatusWidget :widget="widget" v-on:close="update = false"
                    v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>



        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta && widget.meta.data" ref="vis"
            :style="[widget.height === 'fixed' ? { 'background-color': widget.background_color ? widget.background_color : $store.getters.getColorPalette().background2ColorCode, 'height': '150px', } : {}]">
        </div>
        <div v-else
            :style="[widget.height === 'fixed' ? { 'height': '175px', 'text-align': 'center' } : { 'text-align': 'center' }]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{ $store.getters.getTextMap().not_configured }}</h5>
        </div>



    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
import AddShiftwiseMachineStatusWidget from '@/components/crud_components/widgets/AddShiftwiseMachineStatusWidget'
import moment from 'moment';
import Plotly from 'plotly.js'



export default {
    name: "ShiftwiseMachineStatusWidget",
    components: {
        InfoAlert,
        AddShiftwiseMachineStatusWidget
    },
    props: {
        widget: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        },

    },
    mounted() {
        console.log(this.widget);
        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data.length > 0) {
            this.createInputData()
        }

    },
    data() {
        return {
            machines: new Set(),
            showMenu: false,
            showDismissibleAlert: false,
            info: false,
            update: false,
            dialog: false,
            x: 0,
            y: 0,
            loading: false,
            filteredMachineData: [],
            selectedShift: "",
            shiftwiseStructuredData: null,
            selectedShiftMachines: null,
            payload: null,
            inputData: [],
            graphData: {},
            multiParamGraphData: {},
            tempDataFinale: [],
            shifts: null,
            tableData: null,
            layout: {
                barmode: 'stack',
                font: { color: this.$store.getters.getColorPalette().accentCode },
                plot_bgcolor: this.widget.background_color ? this.widget.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor: this.widget.background_color ? this.widget.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                hoverlabel: { namelength: -1, },
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                yaxis: {
                    range: [0, this.benchmarkYValue + 100],
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                },
                timeframe: null,

            },
        }
    },
    methods: {
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if (this.$store.state.settingMode) {
                this.$nextTick(() => {
                    this.showMenu = true
                })
            }
        },
        createInputData() {
            let temp = []
            for (let i of this.widget.meta.data) {
                temp.push({
                    machine_id: i.machine_id,
                    shift_id: i.shift_id
                })
            }
            this.inputData = temp
        },
        init() {
            this.getData()

        },

        deleteWidget() {
            this.loading = true
            axios.post(this.$store.state.api + 'deleteWidget', this.widget, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.$emit('update')
                    }
                }).catch(err => {
                    console.error(err)
                    this.loading = false
                });
        },

        getFromDate(date_format) {
            let from_date = moment();

            // console.log(this.widget, "timeframe");


            if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.time_frame) {

                switch (this.widget.meta.time_frame) {
                    case '7':
                        from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                        break;
                    case '30':
                        from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                        break;
                    case '60':
                        from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                        break;
                    case '90':
                        from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                        break;
                    case '180':
                        from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                        break;
                    case '365':
                        from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                        break;
                    case '1':
                        from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                        break;
                    case 'ytd':
                        from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                        break;
                    default:
                        from_date = date_format ? moment().subtract(30, 'days').startOf('month').format(date_format) : moment().startOf('month');
                        break;
                }
                // console.log(this.timframe);
                console.log(from_date);
            }
            return from_date
        },





        addIdealValueToGraph(traces) {
            this.layout['title'] = this.widget.label
            this.layout['barcornerradius'] = 15
            if (this.widget.meta && this.widget.meta.legend) {
                this.layout['showlegend'] = true
            }
            if (this.widget.meta && this.widget.meta.barmode) {
                this.layout['barmode'] = this.widget.meta.barmode
            } else {
                this.layout['barmode'] = 'group'
            }

            console.log(traces);

            Plotly.newPlot(this.$refs.vis, Object.values(traces), this.layout, { displaylogo: false })
        },


        downloadJSON(data, filename = 'data.json') {
            const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        },






        createChart() {


            let color = {
                "loading": '#FFFF00',  // Yellow
                "offline": '#FF0000',  // Red
                "idling": '#808080',   // Gray
                "running": '#008000'   // Green
            }

            const dateRange = [...new Set(this.tempDataFinale.map((entry) => moment(entry.date).format('YYYY-MM-DD')))]
            const machines = [...new Set(this.tempDataFinale.map((entry) => entry.machine_id))];
            const chartData = [];

            machines.forEach((machineId) => {
                const machineData = this.tempDataFinale.filter((entry) => entry.machine_id === machineId);

                // Group data by status
                const statuses = [...new Set(machineData.map((entry) => entry.status))];

                statuses.forEach((status) => {
                    const statusData = machineData.filter((entry) => entry.status === status);

                    // Extract x and y values for this status
                    const x = [];
                    const y = [];
                    const text = [];

                    statusData.forEach((entry) => {
                        const formattedDate = new Date(entry.date).toISOString().split("T")[0]; // Format date as YYYY-MM-DD
                        if (dateRange.includes(formattedDate)) {
                            x.push(formattedDate);
                            y.push(entry.duration/3600);
                            text.push(`${this.$store.getters.getShiftById(entry.shift_id).name}`);
                        }
                    });

                    // Push data to chartData if there is relevant information
                    if (x.length > 0) {
                        chartData.push({
                            x,
                            y,
                            name: `${this.$store.getters.getMachineById(machineId).name} - ${status.charAt(0).toUpperCase() + status.slice(1)}`,
                            type: "bar",
                            marker: { color: color[status] },
                            text,
                            barmode: "group",
                        });
                    }
                });
            });

            console.log(
                chartData, "testim..."
            );

            // this.downloadJSON(this.tempDataFinale)
            // if (this.tempDataFinale && this.tempDataFinale.length > 0) {




            //     let graph = this.tempDataFinale.reduce((accumulator, current) => {
            //         // console.log(moment(current.date).toISOString(),current.total_duration/3600)


            //         if (current.status && current.status in accumulator) {
            //             accumulator[current.status]['x'].push(moment(current.date).toISOString())
            //             accumulator[current.status]['y'].push(current.duration / 3600)
            //         } else {
            //             accumulator[current.status] = {
            //                 x: [moment(current.date).toISOString(),],
            //                 y: [current.duration / 3600],
            //                 name: current.status,
            //                 type: 'bar',
            //                 text: this.$store.getters.getShiftById(current.shift_id).name,
            //                 marker: {
            //                     color: color[current.status],
            //                     line: {
            //                         color: this.$store.getters.getColorPalette().accentCode,
            //                         width: 1
            //                     }
            //                 },
            //             }
            //         }
            //         return accumulator
            //     }, {})


            //     console.log(graph);

            //     this.addIdealValueToGraph(graph)
            // }
                this.addIdealValueToGraph(chartData)
        },


        async getData() {

            try {
                if (this.widget.height === 'fixed') {
                    this.layout.height = 160;
                    this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode };
                    this.layout.margin = { t: 40, b: 40, l: 40, r: 40 };
                }
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray;
                this.graphData = {};
                this.multiParamGraphData = {};

                let from_date = moment().subtract(1, 'days');
                from_date = this.getFromDate('YYYYMMDD');
                let to_date = moment().format('YYYYMMDD');

                if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData) {
                    this.tempDataFinale = [];

                    // console.log(this.inputData, "inputdata");
                    let payload = {
                        from_date: from_date,
                        to_date: to_date,
                        shift_and_machines: []
                    }

                    // console.log(payload);

                    for (let i of this.inputData) {
                        payload['shift_and_machines'].push(
                            {
                                "machine_id": i.machine_id,
                                "shift_id": i.shift_id
                            }
                        )
                    }
                    console.log(payload, "payload");


                    if (payload) {
                        let response = await axios.post(this.$store.state.api + 'getShiftwiseMachineUsageDataBetweenTimestamp', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                        console.log(response, "response");
                        this.payload = payload
                        if (response.data.status == 'success') {
                            this.loading = false;
                            let data = response.data.data
                            console.log(data);
                            this.tempDataFinale = [...data]
                            console.log(this.tempDataFinale, "tempdata_finale");
                            // this.createChart()
                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    }

                    this.createChart();
                    this.loading = false;
                }

            } catch (err) {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            }
        }
    },
    watch: {
        widget() {
            this.createInputData()
        },

        inputData() {
            this.init()
        },
        selectedShift() {
            this.getShiftWiseMachineData()
        }

    }
}
</script>

<style></style>
<template>
<v-card>
    <v-container fluid>
        <v-card-title><v-row>
            <v-col><span class="text-h6 white--text">{{ $store.getters.getTextMap().change_password }}</span></v-col>
        </v-row></v-card-title>
        
        <v-divider></v-divider>
        <v-form ref="passUpdateForm" v-model="valid">
        <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
         :dark="$store.getters.getColorPalette().isDark"
         :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.oldpassword"
            :rules="passwordRules"
            :label="$store.getters.getTextMap().old_password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
        <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
         :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.password"
            :rules="passwordRules"
            :label="$store.getters.getTextMap().new_password "
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          
          <v-text-field
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.confirmpassword"
            :rules="confirmPasswordRules"
            :label="$store.getters.getTextMap().confirm_password "
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col>{{info}}</v-col>
    </v-row>
    <v-row align="center" justify="space-around">
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
              <v-btn v-if="!loading"  :dark="$store.getters.getColorPalette().isDark" class="px-10"  @click="updatePassword" >{{ $store.getters.getTextMap().update  }}</v-btn>
              </v-col>
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      max-width="300"
      type="text"
    ></v-skeleton-loader>
              <v-btn  v-if="!loading" class="px-10"  @click="onReset" >{{ $store.getters.getTextMap().cancel }}</v-btn>
          </v-col>
    </v-row>
        </v-form>
    </v-container>
</v-card>
</template>

<script>
import axios from 'axios'
export default {
    name:'ChangePassword',
    data(){
        return {
            form: {
        oldpassword: "",
        password: "",
        confirmpassword:null
      },
      showPass:false,
      valid: false,
      info:null,
      showDismissibleAlert: false,
      loading: false,
      passwordRules:[
          v=> !!v || this.$store.getters.getTextMap().required,
          v => (v && v.length >= 8 ) || this.$store.getters.getTextMap().password_must_be_more_than_8_characters,
    ],
      confirmPasswordRules:[
          v=> !!v || this.$store.getters.getTextMap().required,
          v => (v == this.form.password ) || this.$store.getters.getTextMap().passwords_do_not_match,
    ],
        }
    },
    methods: {
        updatePassword(){
            this.$refs.passUpdateForm.validate()
            if(this.valid){
                this.loading = true;
                axios.post(this.$store.state.api + "updatePassword", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {            
            //console.log("redirecting to login")
            //this.$router.push("/");
            this.info="Password successfully updated"
            this.$refs.passUpdateForm.reset()
            this.$emit('close')
          } else {
            this.info = response.data.errorList;
            this.showDismissibleAlert = true;
          }
          
          this.loading = false;
        })
        .catch(error => {
          
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
        }
        },
        onReset(){
            this.$refs.passUpdateForm.reset()
            this.$emit('close')
        }
    },
}
</script>
<style scoped>

</style>
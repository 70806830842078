<template>
    <v-container fluid  :style="{background: $store.getters.getColorPalette().background2ColorCode}">  
        <div>
            <v-row v-if="this.param">
                <v-col>
                    <v-btn-toggle
                      :dark="$store.getters.getColorPalette().isDark" 
                        v-model="days_toggle"
                        mandatory
                    >
          <v-btn small>
            365 Days
          </v-btn>
          <v-btn small>
            180 Days
          </v-btn>
          <v-btn small>
            30 Days
          </v-btn>
              </v-btn-toggle>
                </v-col>
                <v-col>
                    <h3><span :style="{color: $store.getters.getColorPalette().accentCode}">{{ label?label:'' }}</span></h3>
                </v-col>
            </v-row>
        </div>
        <div ref="vis"></div>
    </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'

export default {
    name:'SingleParamUsageHeatMap',
    props:['param','label','dimensions','height','width'],
    components:{
    },
    data(){
        return {
            graphData:{},
            week:{},
            date_week:{},
            days_toggle:2,
            usage:{},
            z_axis_data:null,
            week_x_axis:[],
            daysOption:[
                {label:'364 Days',value:364},
                {label:'182 Days',value:182},
                {label:'35 Days',value:35},
            ],
            dayMap:{
                0:"Sunday",
                1:"Monday",
                2:"Tuesday",
                3:"Wednesday",
                4:"Thursday",
                5:"Friday",
                6:"Saturday",
            },
            objectRules:[
                v => !!v || 'Required',
            ],
            layout : {
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -60,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
        }
    },
    mounted(){
        if(this.param){
            // console.log(this.dimensions)
            this.getData()
        }
    },
    computed:{
        getParamUnit(){
        let p=this.$store.getters.getParamById(this.param)
          if(p){
          return p.unit
          }else{
            return "Issue with Param"
          }
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      createHeatMap(){
            this.layout['title']= this.getParamUnit
            let data = [{
            z:Object.values(this.week),
            y: Object.keys(this.week),
            // y: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            x: this.week_x_axis,
            type: 'heatmap',
            colorscale:this.$store.getters.getColorPalette().colorTypesCodeArrayHeatMap,
            // colorscale:colorscaleCodeHeatMap,
            text: Object.values(this.date_week),
            textfont:{
                color:this.$store.getters.getColorPalette().accentName
            }
            }];
            if(this.$refs.vis){
                Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
            }
            
        },
        async getData(){
          if(this.height==='fixed'){
            this.layout.height=145
            // this.layout.width=this.width*90
            this.layout.margin={
                t:40,
                b:60,
                l:80,
                r:40
              }
          }
        if(this.dimensions){
            // this.layout.height=170
            // this.layout.width=600
            this.layout.height=this.dimensions[0]['height'] 
            // this.layout.width=this.dimensions[1]['width'] 
            this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
            this.layout.margin={
                    t:40,
                    b:40,
                    l:80,
                    r:40
            }
        }
        let weeks = 0
        switch(this.days_toggle){
            case 0:
                this.days=366
                weeks = Math.round(364/7)
                break;
            case 1:
                this.days=182
                weeks = Math.round(182/7)
                break;
            case 2:
                this.days=35
                weeks = Math.round(35/7)
                break;
        }
        let count = 1
        let date = moment().subtract(this.days, 'days').format('YYYYMMDD');
        let data_for_day = new Date(moment(date, "YYYYMMDD").format('YYYY-MM-DD'));
        let day = data_for_day.getDay();
        this.days = this.days + (day === 0 ? 6 : day - 1);
        date = moment().subtract(this.days, 'days').format('YYYYMMDD');
        this.usage = {} 
        let payload={
                parameter:this.param,
                from_date:date,
                }
        try{
            let temp_response= await axios.post(this.$store.state.api+'getParameterUsageDataBetweenDate',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            if(temp_response.data.status==='success'){
                // console.log(temp_response.data.data)
                for(let i of temp_response.data.data){
                    this.usage[i.date]=i.usage
                }
            }else{
                this.info=temp_response.data.msg
                this.showDismissibleAlert=true
            }
            }catch(err){
                console.error(err)
            }  
        this.week_x_axis = []
        // console.log(this.usage)
        this.week= {'Monday':[],'Tuesday':[],'Wednesday':[],'Thursday':[],'Friday':[],'Saturday':[],'Sunday':[],}
        this.date_week= {'Monday':[],'Tuesday':[],'Wednesday':[],'Thursday':[],'Friday':[],'Saturday':[],'Sunday':[],}
        for(let i=0; i<weeks; i++){

            for(let j=0; j<7; j++){

                let data_for_day = new Date(moment(date,"YYYYMMDD").format('YYYY-MM-DD'))
                let day = data_for_day.getDay()
                this.week[this.dayMap[day]].push(this.usage[date]!= undefined || Object.values(this.usage).length!=0 ?this.usage[date]:null)
                this.date_week[this.dayMap[day]].push(moment(date,"YYYYMMDD").format('YYYY-MM-DD'))

                date = moment().subtract((this.days-(count)),'days').format('YYYYMMDD') 
                count = count+1        
            }
            this.week_x_axis.push('W'+(i+1))
            }
            this.createHeatMap()
        },
    },
    watch:{
        widget:{
            handler(){
                this.getData()
            },
            deep:true
        },
        param:{
            handler(){
                this.getData()
            },
            deep:true
        },
        days_toggle(){
            this.getData()
        }
        // colorbar: {
        //         bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
        //         bordercolor:this.$store.getters.getColorPalette().accentCode,
        //         tickcolor:this.$store.getters.getColorPalette().accentCode,
        //         tickfont:{color:this.$store.getters.getColorPalette().accentCode}
        //     },
        //     colorscale:this.$store.getters.getColorPalette().colorTypesCodeArray
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
</style>
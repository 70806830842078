<template>
<v-container fluid class="px-0 py-0">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-row no-gutters :dense="true" v-if="$store.state.dashboardMachines && $store.state.dashboardMachines.length>0">
        <v-col align-self="start" :cols="machine.default_width?machine.default_width:4" dense  class="pb-1 px-1 my-0"   v-bind:key="machine.machine_id" v-for="machine in machines">
            <Machine :hideDelete="true" class="mx-0" :machine="machine" >
                <template v-slot:top>
                    <br>
                    <v-btn v-if="!loading && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " small :color="$store.getters.getColorPalette().alertColor" @click="removeMachine(machine)">{{$store.getters.getTextMap().remove_from_dashboard}}</v-btn>
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    </template>
            </Machine>
        </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
import Machine from '@/components/items/Machine'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name: 'DashboardParamList',
    components:{
        Machine,
        InfoAlert
    },
    data(){
        return {
            machines:[],
            loading:false,
            showDismissibleAlert:false,
            info:''
            
        }
    },
    mounted(){
        this.getMachines()
    },
    computed:{
        
    },
    methods:{
        getMachines(){
            if(this.$store.state.dashboardMachines && this.$store.state.dashboardMachines.length>0){
                let machines=[]
                for(let i of this.$store.state.dashboardMachines){
                    machines.push(i['machine_id'])
                }
                let payload={
                    machines:machines
                }
          axios.post(this.$store.state.api+'getMachinesByID',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //this.$store.dispatch('refreshParameters')
        this.machines=response.data.machines
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      
    }
    this.loading=false
    })
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    }); 
            } 
        },
        removeMachine(machine){
          if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
               //this.$store.dispatch('deleteParameter',this.parameter)
               //this.$emit('deleteParameter',parameter)
               this.loading=true
               let payload={machine_id:machine.machine_id}
          axios.post(this.$store.state.api+'deleteMachineFromDashboard',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //this.$store.dispatch('refreshParameters')
        this.$store.dispatch('refreshDashboardMachines')
        //console.log("Parameter added "+d)
        
        this.loading=false
        this.info="Machine removed"
        this.showDismissibleAlert=true
            //this.dialog=false
            this.getMachines()
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });

           }else{
               this.info='You do not have permission to do this'
               this.showDismissibleAlert=true

           }
          
        },
    },
    watch:{
        '$store.state.dashboardMachines':{
            handler() {
        // Do something when $store.state.organisation changes
        this.getMachines();
      },
      deep: true, 
    //   immediate: true 
        }
    }
}
</script>

<style>

</style>
<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="workspace"
            :items="workspaceOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            
            
            :label="$store.getters.getTextMap().workspace"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-if="multiple">
                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                    v-if="!loading"
            v-model="machine"
            :items="machineOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            multiple
            :label="$store.getters.getTextMap().machines"
            item-text="label"
                    item-value="value"
                    :rules=[rules.required]
          ></v-autocomplete>
            </v-col>
            <v-col v-else>
                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <!-- <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-if="!loading"
                    v-model="machine"
                    :items="machineOptions"
                    label="Machine"
                    outlined
                    item-text="label"
                    item-value="value"
                    ></v-select> -->
                    <v-autocomplete
                    v-if="!loading"
            v-model="machine"
            :items="machineOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().machine"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
                    
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
name:'WorkspaceMachineForm',
props:['multiple'],
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        workspace:null,
        machine:null,
        form:{},
        machineOptions:[],
        rules:FormRules.rules,
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
computed:{
    workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
            for(let i of g){
                op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
            }
        return op
    },
},
methods:{
    getMachineList(){
        let op=[]
        if(this.workspace){
            let payload={
                workspace_id:this.workspace.workspace_id
            }
            this.loading = true;
        axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
                }else{
                op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.machineOptions=op
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.machineOptions=op
    },
    send(){
        this.form.machine=this.machine
        this.$emit('value',this.form)
    }
},
watch:{
    workspace(){
        this.getMachineList()
    },
    machine:{
        handler(){
            this.send()
        },
        deep:true
    }
}
}
</script>
export default {
  toggleClientSafety(state){
    state.clientSafety=!state.clientSafety
  },
  toggleSettingMode(state){
    state.settingMode=!state.settingMode
  },
  setJWT(state,jwt){
    state.jwt=jwt
  },
  setViewsLoaded(state,viewsLoaded){
    state.viewsLoaded=viewsLoaded
  },
  setMachinesLoaded(state,machinesLoaded){
    state.machinesLoaded=machinesLoaded
  },
  setTheme(state,theme){
    state.theme=theme
  },
  setLang(state,lang){
    state.lang=lang
  },
  setUserData(state,userData){
    state.userData=userData
  },
  setLoggedIn(state,loggedIn){
    state.loggedIn=loggedIn
  },
  setUser(state,user){
    state.user=user
  },
  setChecklistsMakersList(state, checklistMakersList) {
    state.checklistMakersList=checklistMakersList
  },
  setChecklistsApproversList(state,checklistsApproverLists){
    state.checklistsApproverLists=checklistsApproverLists
  },
  setChecklistsCheckersList(state,checklistsCheckersList){
    state.checklistsCheckersList=checklistsCheckersList
  },
  setDashboardPanels(state,dashboardPanels){
    state.dashboardPanels=dashboardPanels
  },
  setDashboardGauges(state,dashboardGauges){
    state.dashboardGauges=dashboardGauges
  },
  setDashboardParams(state,dashboardParams){
    state.dashboardParams=dashboardParams
  },
  setDashboardmachines(state,dashboardMachines){
    state.dashboardMachines=dashboardMachines
  },
  setViews(state,views){
    state.views=views
  },
  // updateViews(state, views) {
  //   state.views = views;
  // },
  setDepartments(state,departments){
    state.departments=departments
  },
  setItemIssuerList(state,itemIssuers){
    state.itemIssuers=itemIssuers
  },
  setModules(state,modules){
    state.entureModules=modules
  },
  setAreas(state,areas){
    state.areas=areas
  },
  setActivities(state,activities){
    state.activities=activities
  },
  setTasks(state,tasks){
    state.tasks=tasks
  },
  setDashboardView(state,view){
    state.dashboardView=view
  },
  // setGroups(state,groups){
  //   state.groups=groups
  // },
  setHubs(state,hubs){
    state.hubs=hubs
  },
  setParamTriggerMapping(state,paramTriggerMapping){
    state.paramTriggerMapping=paramTriggerMapping
  },
  setParamTargetMapping(state,paramTargetMapping){
    state.paramTargetMapping=paramTargetMapping
  },
  setActionMapping(state,actionMapping){
    state.actionMapping=actionMapping
  },
  setParameters(state,parameters){
    state.parameters=parameters
  },
  setParams(state,params){
    state.params=params
  },
  setOPCParams(state,opcParams){
    state.opcParams=opcParams
  },
  setS7Params(state,s7Params){
    state.s7Params=s7Params
  },
  setMQTTParameters(state,mqttParameters){
    state.mqttParameters=mqttParameters
  },
  setWriteParams(state,writeParams){
    state.writeParams=writeParams
  },
  
  setCalculatedParams(state,calculatedParams){
    state.calculatedParams=calculatedParams
  },

  setComboParams(state,comboParams){
    state.comboParams=comboParams
  },
  
  setDeviceTypes(state,deviceTypes){
    state.deviceTypes=deviceTypes
  },
  setRoles(state,roles){
    state.roles=roles
  },
  setDataTypes(state,dataTypes){
    state.dataTypes=dataTypes
  },
  setTagTypes(state,tagTypes){
    state.tagTypes=tagTypes
  },
  setWords(state,words){
    state.words=words
  },
  setMachineGroups(state,groups){
    state.machineGroups=groups
  },
  setParamGroups(state,groups){
    state.paramGroups=groups
  },
  setUserGroups(state,groups){
    state.userGroups=groups
  },
  setNotificationGroups(state,groups){
    state.notificationGroups=groups
  },
  setMDeviceTypes(state,mDeviceTypes){
    state.mDeviceTypes=mDeviceTypes
  },
  setOrgTypes(state,orgTypes){
    state.orgTypes=orgTypes
  },
  setcountryCodes(state,countryCodes){
    state.countryCodes=countryCodes
  },
  setOrganisation(state,organisation){
    state.organisation=organisation
  },
  // setNodeStates(state,nodeStates){
  //   state.nodeStates=nodeStates
  // },
  setCurrentSnodeUsage(state,currentSnodeUsage){
    state.currentSnodeUsage=currentSnodeUsage
  },
  setCurrentComputedParamUsage(state,currentComputedParamUsage){
    state.currentComputedParamUsage=currentComputedParamUsage
  },
  setCurrentComputedParamUsageByID(state, payload){
    //console.log(data)
    state.currentComputedParamUsage[payload['id']]=payload['data']
    //console.log(state.currentComputedParamUsage)
  },
  setNotifications(state,notifications){
    state.notifications=notifications
  },
  addNotifications(state,notification){
    state.notifications.unshift(notification)
  },  
  removeNotifications(state,notification){
    state.notifications=state.groups.filter(x=>!(x===notification))
  },  
  setProductionLogs(state,logs){
    state.productionLogs=logs
  },
  setDataLogs(state,logs){
    state.dataLogs=logs
  },
  setCapacityLogs(state,logs){
    state.capacityLogs=logs
  },
  setDynamicLogs(state,logs){
    state.dynamicLogs=logs
  },
  setBatchProductionLogs(state,logs){
    state.batchProductionLogs=logs
  },
  setShiftMachineUsageLogs(state,logs){
    state.shiftMachineUsageLogs=logs
  },
  setActions(state,actions){
    state.actions=actions
  },
  setSheets(state,sheets){
    state.sheets=sheets
  },
  setTargets(state,targets){
    state.targets=targets
  },
  setTariffs(state,tariffs){
    state.tariffs=tariffs
  },
  setSKUs(state,skus){
    state.skus=skus
  },
  setProducts(state,products){
    state.products=products
  },
  setLocations(state,locations){
    state.locations=locations
  },
  setLocationEventTypes(state,locationEventTypes){
    state.locationEventTypes=locationEventTypes
  },
  setTriggers(state,triggers){
    state.triggers=triggers
  },
  setDevices(state,devices){
    state.devices=devices
  },
  setVDevices(state,vdevices){
    state.vdevices=vdevices
  },
  setS7Devices(state,s7Devices){
    state.s7Devices=s7Devices
  },
  setMQTTDevices(state,mqttDevices){
    state.mqttDevices=mqttDevices
  },
  // setNodes(state,nodes){
  //   state.nodes=nodes
  // },
  setLists(state,lists){
    state.lists=lists
  },
  setListData(state,listData){
    state.listData=listData
  },
  setShifts(state,shifts){
    state.shifts=shifts
  },
  setShiftData(state,shiftData){
    state.shiftData=shiftData
  },
  setTargetData(state,targetData){
    state.targetData=targetData
  },
  setTariffData(state,tariffData){
    state.tariffData=tariffData
  },
  setComputedParameter(state,computedParam){
    state.computedParameters=computedParam
  },
  setDerivedParameters(state,derivedParams){
    state.derivedParameters=derivedParams
  },
  setManualParameters(state,manualParameters){
    state.manualParameters=manualParameters
  },
  // setSNodes(state,snodes){
  //   state.snodes=snodes
  // },
  setMgates(state,mGates){
    state.mGates=mGates
  },
  setReports(state,reports){
    state.reports=reports
  },
  setSECReports(state,reports){
    state.secReports=reports
  },
  // setGranularSECReports(state,reports){
  //   state.granularSECReports=reports
  // },
  setSECTimeFrameReports(state,reports){
    state.secTimeFrameReports=reports
  },
  setConsolidatedReports(state,reports){
    state.consolidatedReports=reports
  },
  setDynamicReports(state,reports){
    state.dynamicExcelReports=reports
  },
  setExcelTemplateBasedReports(state,reports){
    state.excelTemplateBasedReports=reports
  },
  setUsageReports(state,reports){
    state.usageReports=reports
  },
  setEnergyUsageReports(state,reports){
    state.energyUsageReports=reports
  },
  setEnergyReports(state,reports){
    state.energyReports=reports
  },
  setEnergyDistributionReports(state,reports){
    state.energyDistributionReports=reports
  },
  setEnergyBillDistReports(state,reports){
    state.energyBillDistReports=reports
  },
  setWorkspaces(state,workspaces){
    state.workspaces=workspaces
  },
  setProcesses(state,processes){
    state.processes=processes
  },
  setMachines(state,machines){
    state.machines=machines
  },
  setSchedules(state,schedules){
    state.schedules=schedules
  },
  setmaintainedOrgs(state,maintainedOrgs){
    state.maintainedOrgs=maintainedOrgs
  },
  // setLast7Days(state,last7Days){
  //   state.last7Days=last7Days
  // },
  // setLast30DaysSNodeData(state,last30daydata){
  //   state.last30DaysSNodeData=last30daydata
  // },
  // setMonthlyData(state,monthlyData){
  //   state.monthlyData=monthlyData
  // },
  setUAMUsers(state,UAMUsers){
    state.UAMUsers=UAMUsers
  },
  setCurrentSNodeData(state,snodeData){
    if(snodeData.length>0){
      state.currentSNodeData={}
      //console.log("something")
      for(let i of snodeData){
        if(i['snode_id'] in state.currentSNodeData){
          state.currentSNodeData[i['snode_id']].push(i)
        }else{
          state.currentSNodeData[i['snode_id']]=[]
          state.currentSNodeData[i['snode_id']].push(i)
        }
      }
      //console.log(state.currentSNodeData)
    }
  },
  setCurrentParameterData(state,parameterData){
    if(parameterData && parameterData.length>0){
      state.currentParameterData={}
      //console.log("something")
      for(let i of parameterData){
        if(i['parameter'] in state.currentParameterData){
          state.currentParameterData[i['parameter']].push(i)
        }else{
          state.currentParameterData[i['parameter']]=[]
          state.currentParameterData[i['parameter']].push(i)
        }
      }
      //console.log(state.currentParameterData)
    }
  },
  setCurrentDerivedParameterData(state,parameterData){
    if(parameterData && parameterData.length>0){
      state.currentDerivedParameterData={}
      //console.log("something")
      for(let i of parameterData){
        if(i['param_id'] in state.currentDerivedParameterData){
          state.currentDerivedParameterData[i['param_id']].push(i)
        }else{
          state.currentDerivedParameterData[i['param_id']]=[]
          state.currentDerivedParameterData[i['param_id']].push(i)
        }
      }
      //console.log(state.currentParameterData)
    }
  },
  updateError (state, item){
    state.error=item
  },
  addListData(state,listData){
    if(listData && listData.list_id){
      
      
        if(listData['list_id'] in state.listData){
          state.listData[listData['list_id']].push(listData)
        }else{
          state.listData[listData['list_id']]=[]
          state.listData[listData['list_id']].push(listData)
        }
      
      //console.log(state.currentParameterData)
    }
  },
  // addGroup (state, item) {
  //   state.groups.push(item)
  // },
  addNotificationGroup (state, item) {
    state.notificationGroups.push(item)
  },
  addMachineGroup (state, item) {
    state.machineGroups.push(item)
  },
  addParamGroup (state, item) {
    state.paramGroups.push(item)
  },
  addUserGroup (state, item) {
    state.userGroups.push(item)
  },
  // addSchedule(state,item){
  //   state.schedules.push(item)
  // },
  addWorkspace (state, item) {
    state.workspaces.push(item)
  },
  addDevice (state, item) {
    state.devices.push(item)
  },
  // addNode (state, item) {
  //   state.nodes.push(item)
  // },
  addMGate (state, item) {
    state.mGates.push(item)
  },
  addParameter (state, item) {
    state.parameters.push(item)
  },
  addParam (state, item) {
    state.params.push(item)
  },
  addList (state, item) {
    state.lists.push(item)
  },
  addCaculatedParam (state, item) {
    state.calculatedParams.push(item)
  },
  // updateGroup(state, group){
  //   let d=state.groups.filter(x=>!(x.id===group.id))
  //   d.push(group)
  //   state.groups=d
  // },
  updateDevice(state, device){
      let d=state.devices.filter(x=>!(x.id===device.id))
      d.push(device)
      state.devices=d
  },
  // updateNode(state, node){
  //     /*let d=state.nodes.filter(x=>!(x.node_id===node.node_id))
  //     d.push(node)
  //     state.nodes=d*/

  //     let index=state.nodes.findIndex(x=> (x.node_id===node.node_id))
  //     state.nodes[index]=node
  //     //console.log(index)
  //     //console.log(state.nodes[index])
  // },
  // updateNodeState(state,node){
  //   let index=state.nodeStates.findIndex(x=> (x.node_id===node.node_id))
  //   state.nodeStates[index].state=node.state
  // },
  toggleEngineeringAccess(state){
    if(state.engineerAccessLevel >= 20){
      state.engineerAccessLevel=10
    }else{
      state.engineerAccessLevel=21
    }
  }
}
<template>
  <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">  
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
      <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-list-item @click="update=true">
            <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteWidget">
            <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}
</strong></v-list-item-title>
            <v-list-item-icon>
              <v-icon color="red" small>mdi-trash-can</v-icon>
            </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
          <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{widget.label}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}
</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <AddBarTrendWidget :widget="widget" :disableTimeframe="disableTimeframe" :idealValue="idealValue" :idealLabel="idealLabel" v-on:close="update=false" v-on:success="$emit('update')" />
      </v-card>
    </v-dialog>
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
    <v-row v-if="widget.meta.data" no-gutters>
      <v-col  align="right">
          <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
            <v-btn small>{{$store.getters.getTextMap().bar}}
</v-btn>
            <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
          </v-btn-toggle>
      </v-col>
    </v-row>
    <div v-if="widget.meta && widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'background-color':widget.background_color?widget.background_color:$store.getters.getColorPalette().background2ColorCode,'height':'150px',}:{}]" ></div>
    <div v-else  :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
      <v-icon small>mdi-hail</v-icon>
      <h5>{{$store.getters.getTextMap().not_configured}}</h5>
    </div>
  </v-container>
</template>
<script>
import Plotly from 'plotly.js'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import AddBarTrendWidget from '@/components/crud_components/widgets/AddBarTrendWidget'
import {io} from 'socket.io-client'
import axios from 'axios'

export default {
  name:"TrendBarWidget",
  components:{
    AddBarTrendWidget,
    InfoAlert,
  },
  created(){
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    this.stream.close()
  },
  props:{
    widget:{
      type: Object,
      required:true
    },
    timeframe:{
      type: String,
    },
    edit:{
      type:Boolean,
      required:true
    },
    disableTimeframe:{
      type:Boolean,
      required:true
    },
    idealValue:Number,
    idealLabel:String,
  },
  data(){
    return {
      showDialog:false,
      x:0,
      y:0,
      showMenu:false,
      loading:false,
      info:'',
      showDismissibleAlert:false,
      update:false,
      graphDataTemp:[],
      tempDataFinale:[],
      multiParamUsage:0,
      getDataFlag:false,
      graphData:{},
      eqautionParamMap:{},
      eqautionSolnData:{},
      multiParamGraphData:{},
      inputData:[],
      benchmarkLine:{},
      graphType:'bar',
      multiPgraphType:'bar',
      containsEquation:false,
      barMode:'stack',
      layout : {
        barmode: 'stack',
        font:{color:this.$store.getters.getColorPalette().accentCode},
        plot_bgcolor:this.widget.background_color?this.widget.background_color:this.$store.getters.getColorPalette().background2ColorCode,
        paper_bgcolor:this.widget.background_color?this.widget.background_color:this.$store.getters.getColorPalette().background2ColorCode,
        autosize: true,
        hoverlabel:{namelength:-1,},
        xaxis: {
          tickangle: -12,
          zeroline: false,
          showgrid: true,
          showticklabels: true,
          color:this.$store.getters.getColorPalette().accentCode,
        },       
        yaxis: {
          range: [0, this.benchmarkYValue + 100],
          showticklabels: true,
          color:this.$store.getters.getColorPalette().accentCode,
        },
        uniformtext: {
          "mode": "hide",
          "minsize": 1
        },
      }
    }
  },
  mounted(){
    if(this.widget && this.widget.widget_id&&this.widget.meta && this.widget.meta.data.length>0){
        this.createInputData()
    }
  },
  methods:{
    show (e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
    },
    createInputData(){
      let temp=[]
      for(let i of this.widget.meta.data){
        if(i.idType && i.idType==='equation'){
          if(!this.containsEquation){
            this.containsEquation=true
          }
          temp.push({id:i.eq,label:i.label,idType:"equation"})
        }else{
          temp.push({id:i.param,label:i.label,idType:"parameter"})
        }
      }
      this.inputData=temp
    },
    init(){
      if(this.containsEquation){
        this.getParamsBasedOnEquation()
      }else{
        this.getData()
        this.stream.on("connect_error", (err) => {console.error(err.message)});
        this.stream.on('error',data=>{console.error(data)})
        this.stream.on('param',data=>{
          if(data.constructor==String){
            data=JSON.parse(data)
          }
          for(let i of this.inputData){
            if(Array.isArray(i.id) && i.id.length>1){
              this.multiParamsStream(i,data)
            }else{
              this.singleParamStream(data)
            }
          }
        }) 
      }
    },
    getFromDate(date_format){
      let from_date=moment();
      if(this.timeframe && this.disableTimeframe){
        if(this.timeframe!=='rtd'){
          switch (this.timeframe) {
            case '7':
              from_date = date_format?moment().subtract(7,'days').format(date_format):moment().subtract(7,'days');
              break;
            case '30':
              from_date = date_format?moment().subtract(30,'days').format(date_format):moment().subtract(30,'days');
              break;
            case '60':
              from_date = date_format?moment().subtract(60,'days').format(date_format):moment().subtract(60,'days');
              break;
            case '90':
              from_date = date_format?moment().subtract(90,'days').format(date_format):moment().subtract(90,'days');
              break;
            case '180':
              from_date = date_format?moment().subtract(180,'days').format(date_format):moment().subtract(180,'days');
              break;
            case '365':
              from_date = date_format?moment().subtract(365,'days').format(date_format):moment().subtract(365,'days');
              break;
            case 'yesterday':
              from_date = date_format?moment().subtract(1,'days').format(date_format):moment().subtract(1,'days');
              break;
            case 'ytd':
              from_date = date_format?moment().startOf('year').format(date_format):moment().startOf('year');
              break;
            default:
              from_date = date_format?moment().subtract(1, 'days').startOf('month').format(date_format):moment().startOf('month');
              break;
          }
        }else{
          return from_date
        }
        }else{
          if(this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.time_frame){
            if(this.widget.meta.time_frame!=='rtd'){
            switch (this.widget.meta.time_frame) {
              case '7':
              from_date = date_format?moment().subtract(7,'days').format(date_format):moment().subtract(7,'days');
              break;
            case '30':
              from_date = date_format?moment().subtract(30,'days').format(date_format):moment().subtract(30,'days');
              break;
            case '60':
              from_date = date_format?moment().subtract(60,'days').format(date_format):moment().subtract(60,'days');
              break;
            case '90':
              from_date = date_format?moment().subtract(90,'days').format(date_format):moment().subtract(90,'days');
              break;
            case '180':
              from_date = date_format?moment().subtract(180,'days').format(date_format):moment().subtract(180,'days');
              break;
            case '365':
              from_date = date_format?moment().subtract(365,'days').format(date_format):moment().subtract(365,'days');
              break;
            case 'yesterday':
              from_date = date_format?moment().subtract(1,'days').format(date_format):moment().subtract(1,'days');
              break;
            case 'ytd':
              from_date = date_format?moment().startOf('year').format(date_format):moment().startOf('year');
              break;
            default:
              from_date = date_format?moment().subtract(1, 'days').startOf('month').format(date_format):moment().startOf('month');
              break;
            }
          }else{
            return from_date
          }
          }
        }
        return from_date
    },
    addIdealValueToGraph(){
      this.layout['title']= this.widget.label
      if(this.widget.meta && this.widget.meta.legend){
        this.layout['showlegend']=true
      }
      if(this.widget.meta && this.widget.meta.barmode){
        this.layout['barmode']=this.widget.meta.barmode
      }else{
        this.layout['barmode']='group'
      }
      let type=this.graphType==1?'line':'bar'
      for(let i in this.graphData){
        this.graphData[i]['type']=type
      }
      if (this.widget.meta.showIdeal ) {
        let from_date;
        if(this.timeframe && this.disableTimeframe){
          switch (this.timeframe) {
                
                case '7':
                  from_date = moment().subtract(7,'days');
                  break;
                  
                case '30':
                  from_date = moment().subtract(30,'days');
                  break;
                  
                  case '60':
                    from_date = moment().subtract(60,'days');
                    break;
                    
                    case '90':
                      from_date = moment().subtract(90,'days');
                      break;
                      
                      case '180':
                        from_date = moment().subtract(180,'days');
                        break;
                        
                        case '365':
                          from_date = moment().subtract(365,'days');
                      break;
              
                    case 'yesterday':
                    from_date = moment().subtract(1,'days');
                      break;
                    case 'ytd':
                      from_date = moment().startOf('year');
                      break;

                    default:
                      from_date = moment().subtract(1, 'days').startOf('month');
                      break;
                  }
        }else{
          switch (this.widget.meta.time_frame) {
            case 'ytd':
              from_date = moment().startOf('year');
              break;
            case 'mtd':
              from_date = moment().subtract(1, 'days').startOf('month');
              break;
            case 'yesterday':
            from_date = moment().subtract(1, 'days');
              break;
            case 'rtd':
              from_date=moment().startOf('day');
              break;
            default:
              from_date = moment().subtract(parseInt(this.widget.meta.time_frame), 'days');
          }
        }
        this.graphData['benchmarkLine'] = {
          type: 'line',
          x: [from_date.format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
          y: [this.widget.meta.idealValue ? this.widget.meta.idealValue : 0, this.widget.meta.idealValue ? this.widget.meta.idealValue : 0],
          line: {
            color: 'red',
            width: 2,
            dash: 'dash',
          },
          showlegend: true,
          name: this.widget.meta.idealLabel ? this.widget.meta.idealLabel : '',
        };
      }
      for(let k of Object.keys(this.multiParamGraphData)){
        this.graphData[k]=this.multiParamGraphData[k]
      }
      Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
    },
    singleParamStream(data){
      if(!this.graphData[data['param_id']]){
        this.graphData[data.param_id]={
          'x':[],
          'y':[],
          type: this.graphType==1?'line':'bar',
          opacity:0.9,
          marker: {
            line: {
              color: this.$store.getters.getColorPalette().accentCode,
              width: 0.3
            },
          }
        }
      }
      if(moment().unix()-Number(data.timestamp)<80000){
        this.graphData[data['param_id']]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'))
        this.graphData[data['param_id']]['y'].push(Number(data.data))
      }
      if(this.graphData[data['param_id']]['x'].length>1400){
        this.graphData[data['param_id']]['x'].shift()
        this.graphData[data['param_id']]['y'].shift()
      }
      this.addIdealValueToGraph()
    },
    multiParamsStream(i,data){
      if (!this.multiParamGraphData[i.label]) {
        this.multiParamGraphData[i.label] = {
          x: [],
          y: [],
          type: this.graphType == 1 ? 'line' : 'bar',
          opacity: 0.9,
          showlegend: true,
          name:i.label,
          marker: {
            line: {
              color: this.$store.getters.getColorPalette().accentCode,
              width: 0.3
            }
          }
        };
      }
      for (let j of i.id) {
        if (data.param_id === j) {
          this.multiParamUsage = this.multiParamUsage + Number(data.data);
        }
      }
      if (moment().unix() - Number(data.timestamp) < 80000) {
        this.multiParamGraphData[i.label]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'));
        this.multiParamGraphData[i.label]['y'].push(Number(this.multiParamUsage));
      }
      if (this.multiParamGraphData[i.label]['x'].length > 1400) {
        this.multiParamGraphData[i.label]['x'].shift();
        this.multiParamGraphData[i.label]['y'].shift();
      }
      this.addIdealValueToGraph();
    },
    singleParamInit(){
      if(this.widget && this.widget.meta && this.widget.meta.data && this.inputData){
        let type=this.graphType==1?'line':'bar'
        this.graphData={}
        for(let i of this.inputData){
          this.graphData[i.id]={
            x: [],
            y: [],
            name:i.label,
            type: type,
            opacity:0.9,
            showlegend: true,
            marker: {
              line: {
                color: this.$store.getters.getColorPalette().accentCode,
                width: 0.3
                },
              }
          }
          if(this.widget.meta && this.widget.meta.time_frame=='rtd'){
            if(Array.isArray(i.id)){
              for(let p of i.id){
                this.stream.emit('getParam',p)
              }
            }else{
                this.stream.emit('getParam',i.id)
            }
          }else{
            if(Array.isArray(i.id)){
              for(let p of i.id){
                this.unsubscribeParam(p)
              }
            }else{
              this.unsubscribeParam(i.id)
            }
          }
        }
      }
    },
    multiParamsinit(){
      if(this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame!='rtd' && this.inputData){
        let type=this.graphType==1?'line':'bar'
        this.multiParamGraphData={}
        for(let i of this.inputData ){
          this.multiParamGraphData[i.label]={
            x: [],
            y: [],
            name:i.label,
            type: type,
            opacity:0.9,
            showlegend: true,
            marker: {
              line: {
                color: this.$store.getters.getColorPalette().accentCode,
                width: 0.3
                },
              }
          }
          if(this.widget.meta && this.widget.meta.time_frame=='rtd'){
            if(Array.isArray(i.id)){
              for(let p of i.id){
                this.stream.emit('getParam',p)
              }
            }else{
                this.stream.emit('getParam',i.id)
            }
          }else{
            if(Array.isArray(i.id)){
              for(let p of i.id){
                this.unsubscribeParam(p)
              }
            }else{
              this.unsubscribeParam(i.id)
            }
          }
        }
      }else{
        this.addIdealValueToGraph()
      }
    },
    eqInit(){
      if(this.widget && this.widget.meta && this.widget.meta.data){
        let type=this.graphType==1?'line':'bar'
        this.multiParamGraphData={}
        for(let i of this.widget.meta.data){
          this.multiParamGraphData[i.label]={
            x: [],
            y: [],
            name:i.label,
            type: type,
            opacity:0.9,
            showlegend: true,
            marker: {
              line: {
                color: this.$store.getters.getColorPalette().accentCode,
                width: 0.3
                },
              }
          }
        }
      }else{
        this.addIdealValueToGraph();
      }
    },
    createSingleParamChart(){
       this.singleParamInit()
      if(this.widget.meta){
          for(let i of this.tempDataFinale){
            if(!(i.param_id in this.graphData)){
            let type=this.graphType==1?'line':'bar'
              this.graphData[i.param_id]={
              x: [],
              y: [],
              name:i.label,
              type:type,
              opacity:0.9,
              marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
            }
            }
            this.graphData[i.param_id]['x'].push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
            this.graphData[i.param_id]['y'].push(i.data)
          }
        }
        this.addIdealValueToGraph()
    },
    createMultipleParamChart(){
       this.multiParamsinit()
       if(this.widget && this.widget.meta){
         this.layout.yaxis['title'] = this.widget.meta.yaxis_label
          for(let i of this.tempDataFinale){
            if(!(i.param in this.multiParamGraphData)){
                let type=this.graphType==1?'line':'bar'
                  this.multiParamGraphData[i.param]={
                  x: [],
                  y: [],
                  name:i.label,
                  type:type,
                  opacity:0.9,
                  marker: {
                    line: {
                      color: this.$store.getters.getColorPalette().accentCode,
                      width: 1
                    },
                  },
                }
              }
              if(this.widget.meta.time_frame!='rtd'){
                for(let j of i.data){
                  this.multiParamGraphData[i.param]['x'].push(moment(Number(j.date),'YYYYMMDD').format('YYYY-MM-DD'))
                  this.multiParamGraphData[i.param]['y'].push(j.usage)
                }
              }
          }
          if(this.eqautionSolnData && Object.keys(this.eqautionSolnData) && Object.keys(this.eqautionSolnData).length>0 && this.containsEquation){
            for(let i of Object.keys(this.eqautionSolnData)){
            if(!(i in this.multiParamGraphData)){
              let type=this.graphType==1?'line':'bar'
                this.multiParamGraphData[i]={
                x: [],
                y: [],
                name:this.eqautionSolnData[i]['label'],
                type:type,
                opacity:0.9,
                marker: {
                  line: {
                    color: this.$store.getters.getColorPalette().accentCode,
                    width: 1
                    },
                  },
                }
              }
              if(this.widget.meta.time_frame!='rtd'){
                for(let j of this.eqautionSolnData[i]['solution']){
                  this.multiParamGraphData[i]['x'].push(moment(j.date,'YYYYMMDD').format('YYYY-MM-DD'))
                  this.multiParamGraphData[i]['y'].push(j.usage)
                }
              }
          }
        }
          this.addIdealValueToGraph()
        }
    },
    createEquationChart(){
      this.eqInit()
      if(this.widget.meta){
        if(this.widget && this.widget.meta){
          this.layout.yaxis['title'] = this.widget.meta.yaxis_label
          for(let i of Object.keys(this.eqautionSolnData)){
            if(!(i in this.multiParamGraphData)){
              let type=this.graphType==1?'line':'bar'
                this.multiParamGraphData[i]={
                x: [],
                y: [],
                name:this.eqautionSolnData[i]['label'],
                type:type,
                opacity:0.9,
                marker: {
                  line: {
                    color: this.$store.getters.getColorPalette().accentCode,
                    width: 1
                    },
                  },
              }
            }
            if(this.widget.meta.time_frame!='rtd'){
              for(let j of this.eqautionSolnData[i]['solution']){
                this.multiParamGraphData[i]['x'].push(moment(j.date,'YYYYMMDD').format('YYYY-MM-DD'))
                this.multiParamGraphData[i]['y'].push(j.usage)
              }
            }
          }
          this.addIdealValueToGraph()
        }
      }
      this.addIdealValueToGraph()
    },
    unsubscribeParam(param){
      this.stream.emit('removeParam',param)
    },
    async getParamsBasedOnEquation(){
      try{
        let temp={}
        this.eqautionParamMap={}
        for(let i of this.inputData){
          let payload={}
          if(i.idType && i.idType=="equation"){
            payload={
              eq_id:i.id
            }
          }
          if(payload && payload['eq_id']){
            let response=await axios.post(this.$store.state.api+'getParamEquationsById',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            if(response.data.status==='success'){
              this.loading=false
              if(!temp[i.id]){
                temp[i.id]={
                  positive_params:[],
                  negative_params:[],
                  label:i.label
                }
              }
              temp[i.id]['positive_params']=response.data.param_equation.positive
              temp[i.id]['negative_params']=response.data.param_equation.negative
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
          }
        }
        this.eqautionParamMap=temp
      }catch(error){
        this.loading=false
        this.info=error
        this.showDismissibleAlert=true
      }
    },
    async getData() {
  if (this.getDataFlag) {
    return;
  }
  this.getDataFlag = true;
  try {
    if (this.widget.meta.graphType) {
      this.graphType = this.widget.meta.graphType;
    }
    if (this.widget.height === 'fixed') {
      this.layout.height = 160;
      this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode };
      this.layout.margin = { t: 40, b: 40, l: 40, r: 40 };
    }
    this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray;
    this.graphData = {};
    this.multiParamGraphData = {};
    let from_date = moment().subtract(1, 'days');
    if (this.widget.meta && this.widget.meta.time_frame != 'rtd') {
      from_date = this.getFromDate('YYYYMMDD');
      let to_date = moment().format('YYYYMMDD');
      if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData && this.inputData.length > 0) {
        this.tempDataFinale = [];
        for (let i of this.inputData) {
          let payload = {
            from_date: from_date,
            to_date: to_date,
            timeframe: this.widget.meta.time_range ? this.widget.meta.time_range : 'day'
          };
          if (Array.isArray(i.id)) {
            payload['parameters'] = i.id;
          } else {
            payload['parameters'] = [i.id];
          }

          if (payload.parameters.length > 1) {
            let response = await axios.post(this.$store.state.api + 'getParamGroupedUsageDataByTimeframe', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
            if (response.data.status == 'success') {
              this.loading = false;
              let temp = {};
              let responseData = response.data.data;

              temp['param'] = i.label;
              let usageDataMap = new Map();

              // Combine usage for duplicate dates
              for (let data of responseData) {
                if (usageDataMap.has(data.date)) {
                  usageDataMap.get(data.date).usage += data.usage;
                } else {
                  usageDataMap.set(data.date, { ...data });
                }
              }

              temp['data'] = Array.from(usageDataMap.values());
              this.tempDataFinale.push(temp);
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          } else {
            let response = await axios.post(this.$store.state.api + 'getParamGroupedUsageDataByTimeframe', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
            if (response.data.status === 'success') {
              this.loading = false;
              let temp = {};
              temp['param'] = i.label;
              temp['data'] = response.data.data;
              this.tempDataFinale.push(temp);
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          }
        }

        this.createMultipleParamChart();
        this.loading = false;
      }
    } else {
      if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData) {
        this.tempDataFinale = [];
        let payload = {
          params: [],
          from_timestamp: moment().startOf('day').unix(),
        };
        for (let i of this.inputData) {
          if (Array.isArray(i.id) && i.id.length > 1) {
            this.addIdealValueToGraph();
          } else {
            payload.params.push(i.id);
            let response = await axios.post(this.$store.state.api + 'getRawDataByParamsBetweenTimestamp', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
            if (response.data.status === 'success') {
              this.loading = false;
              this.tempDataFinale = response.data.data;
              this.createSingleParamChart();
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          }
        }
        this.loading = false;
      }
    }
  } catch (err) {
    this.loading = false;
    this.info = err;
    this.showDismissibleAlert = true;
  }
  this.getDataFlag = false;
},
    async getEqAndParamData(){
      await this.getData()
      await this.getEqData()
    },
    async getEqData(){
      try{
        let temp={}
        this.eqautionSolnData={}
        let from_date=this.getFromDate('YYYYMMDD');
        for(let i of Object.keys(this.eqautionParamMap)){
          let positiveData=[]
          let negativeData=[]
          if(this.eqautionParamMap && this.eqautionParamMap[i] && this.eqautionParamMap[i]['positive_params'] && this.eqautionParamMap[i]['positive_params'].length>0){
            let positive_payload={
              parameters:this.eqautionParamMap[i]['positive_params'],
              from_date
            }
            let positive_param_response=await axios.post(this.$store.state.api+'getTotalParamUsageDataBetweenDate',positive_payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            if(positive_param_response.data.status==='success'){
              positiveData=positive_param_response.data.data
              // positiveVal=Number(positive_param_response.data.data['usage']).toFixed(3)
            }else{
              this.info=positive_param_response.data.msg
              this.showDismissibleAlert=true
              this.loading=false
            }
          }
          if(this.eqautionParamMap && this.eqautionParamMap[i] && this.eqautionParamMap[i]['negative_params'] && this.eqautionParamMap[i]['negative_params'].length>0){
            let negative_payload={
              parameters:this.eqautionParamMap[i]['negative_params'],
              from_date
            }
            let negative_param_response=await axios.post(this.$store.state.api+'getTotalParamUsageDataBetweenDate',negative_payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            if(negative_param_response.data.status==='success'){
              negativeData=negative_param_response.data.data
              // negativeVal=Number(negative_param_response.data.data['usage']).toFixed(3)
            }else{
              this.info=negative_param_response.data.msg
              this.showDismissibleAlert=true
              this.loading=false
            }
          }
          if(!temp[i]){
            temp[i]={'solution':[],'label':this.eqautionParamMap&&this.eqautionParamMap[i] &&this.eqautionParamMap[i]['label']?this.eqautionParamMap[i]['label']:""};
          }
          positiveData.forEach(posItem => {
            const negItem = negativeData.find(neg => neg.date === posItem.date);
            if (negItem) {
              temp[i]['solution'].push({
                usage: posItem.usage - negItem.usage,
                date: posItem.date
              });
            }
          });
        }
       this.eqautionSolnData=temp
      }catch(error){
        this.loading=false
        this.info=error
        this.showDismissibleAlert=true
      }
    },
    deleteWidget(){
      this.loading=true
      axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
      .then(response=>{
        if(response.data.status==='success'){
            this.dialog=false
            this.loading=false
            this.$emit('update')
        }}).catch(err=>{
          console.error(err)
          this.loading=false
        });
    },
    cancel(){
        this.showDialog=false
    },
    confirm(){
      this.showDialog=false
    },
  },
  watch:{
    // widget:{
    //   handler(){
    //     this.createInputData()
    //     // if(this.widget && this.widget.widget_id&&this.widget.meta && this.widget.meta.data.length>0){
    //     //   if(this.widget.meta.equationBased){
    //     //     this.getParamsBasedOnEquation()
    //     //   }else{
    //     //     this.getData()
    //     //   }
    //     // }
    //   },
    //     deep:true
    // },
    // timeframe:{
    //   handler(){
    //     if(this.widget && this.widget.widget_id&&this.widget.meta && this.widget.meta.data.length>0){
    //       if(this.widget.meta.equationBased){
    //         this.getParamsBasedOnEquation()
    //       }else{
    //         this.getData()
    //       }
    //     }
    //   },
    //   deep:true
      timeframe(){
        this.createInputData()
      },
      widget(){
        this.createInputData()
      },
    inputData:{
      handler(){
        this.init()
        // if(this.widget && this.widget.widget_id&&this.widget.meta && this.widget.meta.data.length>0){
        //   if(this.widget.meta.equationBased){
        //     this.getParamsBasedOnEquation()
        //   }else{
        //     this.getData()
        //   }
        // }
      },
      deep:true
    },
    graphType: function (){
        this.createMultipleParamChart()
    },
    eqautionParamMap: function(){
      if(Object.keys(this.eqautionParamMap).length>0){
        this.getEqAndParamData()
      }
    },
    eqautionSolnData: function(){
      if(Object.keys(this.eqautionSolnData).length>0){
        this.createMultipleParamChart()
      }
    },
    idealValue: function(newIdealValue) {
      this.benchmarkLine.y = [newIdealValue ? newIdealValue : 0];
      this.createMultipleParamChart();
    },
  }
}
</script>

<style scoped>

</style>